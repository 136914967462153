import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tree-structure',
  templateUrl: './tree-structure.component.html',
  styleUrls: ['./tree-structure.component.scss']
})
export class TreeStructureComponent implements OnChanges {

  @Input() structure: string;
  @Input() editable: boolean = false;
  @Output() updateStructure = new EventEmitter<string>();
  root: FolderStructure;

  folderName:string = "";
  lastFolder:FolderStructure;

  constructor(private localizationService: LocalizationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.structure) {
      this.root = {
        id: "root",
        FolderName: "Root",
        parentId: null,
        Child: [],
        addingFolder: false,
        displayChildren: false
      };
      this.emitStructureStatus();
      return;
    }
    
    this.root = JSON.parse(this.structure) as FolderStructure;
    this.closeFolderRecursive(this.root);
  }

  addFolder(parentFolder:FolderStructure, folderName:string = "No name") {
    const newFolder:FolderStructure = {
      id: new Date().getTime().toString(),
      FolderName: folderName,
      parentId: parentFolder.id,
      Child: [],
      addingFolder: false,
      displayChildren: false
    }
    parentFolder.Child.push(newFolder);
    this.closeInput(parentFolder);
    this.folderName = "";
    this.emitStructureStatus();
  }

  deleteFolder(deleteFolder:FolderStructure, parentFolder?:FolderStructure) {
    if(deleteFolder.id == "root") {
      deleteFolder.Child = [];
      this.emitStructureStatus();
      return;
    }
    
    const index = parentFolder.Child.findIndex(folder => folder.id == deleteFolder.id);
    parentFolder.Child.splice(index, 1);
    this.emitStructureStatus();
  }

  openInput(folder:FolderStructure) {
    if(this.lastFolder){
      this.lastFolder.addingFolder = false;
    }

    folder.addingFolder = true;
    folder.displayChildren = true;
    this.lastFolder = folder;
  }

  closeInput(folder: FolderStructure) {
    folder.addingFolder = false;
  }

  toggleFolder(folder:FolderStructure) {
    folder.displayChildren = !folder.displayChildren;
    if(!folder.displayChildren) this.closeFolderRecursive(folder);
  }

  closeFolderRecursive(folder:FolderStructure) {
    folder.displayChildren = false;
    folder.Child.forEach(subFolder => this.closeFolderRecursive(subFolder));
  }

  emitStructureStatus() {
    const newRoot:FolderStructure = JSON.parse(JSON.stringify(this.root)); // This is just for broke object reference
    this.deleteUnusedProperties(newRoot);
    this.updateStructure.emit(JSON.stringify(newRoot));
  }

  deleteUnusedProperties(folder:FolderStructure) {
    delete folder.displayChildren;
    delete folder.addingFolder;
    folder.Child.forEach(subFolder => this.deleteUnusedProperties(subFolder));
  }
}

export interface FolderStructure {
  id: string,
  FolderName: string,
  Child: FolderStructure[],
  parentId?: string,
  addingFolder: boolean,
  displayChildren: boolean
}