import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { ModuleWithProviders, NgModule, NgModuleFactory } from '@angular/core';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';
import { PortafoliosComponent } from './components/portafolios.component';
import { PortafoliosRoutingModule } from './portafolios-routing.module';
import { ColumnsModule } from 'projects/flyguys/src/app/components/columns/columns.module';
import { TablePageModule } from 'projects/flyguys/src/app/components/table-page/table-page.module';
import { PortfolioDetailComponent } from './components/portfolio-detail.component';
import { MaterialSharedModule } from 'projects/flyguys/src/app/material-shared/material-shared.module';
import { SharedModule } from 'projects/flyguys/src/app/shared/shared.module';
import { PilotSourcingModule } from 'projects/flyguys/src/app/pilot-sourcing/pilot-sourcing.module';
import { PortfolioMissionsComponent } from './components/portfolio-missions.component';
import { AgGridModule } from 'ag-grid-angular';
import { OrdersModule } from '../orders/orders.module';
import { ListService } from '@abp/ng.core';
import { FlyguysMapModule } from '@flyguys/map';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PortfolioDashboardComponent } from './components/portfolio-dashboard.component';

@NgModule({
  declarations: [
    PortafoliosComponent,
    PortfolioDetailComponent,
    PortfolioMissionsComponent,
    PortfolioDashboardComponent
  ],
  imports: [
    AgGridModule,
    PortafoliosRoutingModule,
    MaterialSharedModule,
    SharedModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    PageModule,
    ColumnsModule,
    TablePageModule,
    PilotSourcingModule,
    OrdersModule,
    FlyguysMapModule,
    NgxChartsModule
  ],
  exports: [
    PortafoliosComponent,
    PortfolioDetailComponent  ],
  providers: [ ListService ]
})
export class PortafoliosModule {
  static forChild(): ModuleWithProviders<PortafoliosModule> {
    return {
      ngModule: PortafoliosModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<PortafoliosModule> {
    return new LazyModuleFactory(PortafoliosModule.forChild());
  }
}
