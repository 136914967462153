import { OrderFormDeliverableModel } from './order-form-deliverable.model';
export class OrderFormPackageModel {
  packageId: string = '';
  packageName: string = '';
  quantity: number = 1;
  packagePrice: String = '';
  packageSubtotal: number = null;
  deliverables: OrderFormDeliverableModel[] = [];
  selected: boolean = false;
  industries?: Array<string> = [];
  genericPackage?: boolean;
  incompletePackage?: boolean;
}
