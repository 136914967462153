import { Injectable } from '@angular/core';
import { EnvironmentService } from '@abp/ng.core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { WebNotificationModel } from '../models/notifications/web-notification.model';
import { WebNotificationArgumentModel } from '../models/notifications/web-notification-argument.model';
import { NotificationBroadcastService } from './NotificationBroadcast.service';
import { BackgroundNotificationModel } from '../models/notifications/background-notification.model';
import { BackgroundNotificationType } from '../models/notifications/background-notification-type.enum';
import { WebNotificationType } from '../models/notifications/web-notification-type.enum';

@Injectable({
  providedIn: 'root',
})
export class WebNotificationSocketService {
  public notification$ = new Subject<WebNotificationModel>();

  private hubConnection: HubConnection;
  private readonly API_NAME = 'notificationsService';
  private readonly HUB_NAME = 'webMessaging';
  private readonly HUB_EVENT_NAME = 'WebNotificationMessage';
  private readonly HUB_BACKGROUND_EVENT_NAME = 'BackgroundWebNotification';

  constructor(
    private environmentService: EnvironmentService,
    private oAuthService: OAuthService,
    private broadCastService: NotificationBroadcastService
  ) {}

  public startConnection = () => {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.getWebNotificationUrl(), {
        accessTokenFactory: () => {
          return this.oAuthService.getAccessToken();
        },
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.hubConnection.on(this.HUB_EVENT_NAME, (event, argument) => {
          let notification: WebNotificationModel = {
            message: event,
            arguments: <WebNotificationArgumentModel>argument,
          };

          this.notification$.next(notification);
        });

        this.hubConnection.on(
          this.HUB_BACKGROUND_EVENT_NAME,
          (argument: BackgroundNotificationModel) => {
            if (argument.notificationType == BackgroundNotificationType.AddToNotificationList) {
              let arg = new WebNotificationArgumentModel();
              arg.type = WebNotificationType.ForNotificationList;
              arg.title = argument.extraArgument?.title;
              arg.typeToast = argument.extraArgument?.typeToast;

              let notification: WebNotificationModel = {
                message: argument.message,
                arguments: arg
              };

              this.notification$.next(notification);
            }

            if (
              argument.notificationType == BackgroundNotificationType.toastMessage ||
              argument.notificationType == BackgroundNotificationType.toastAndBrackgroundInformation
            ) {
              let arg = new WebNotificationArgumentModel();
              arg.type = WebNotificationType.ToastNotification;
              arg.title = argument.extraArgument?.title;
              arg.typeToast = argument.extraArgument?.typeToast;

              let notification: WebNotificationModel = {
                message: argument.message,
                arguments: arg
              };

              this.notification$.next(notification);
            }

            if (
              argument.notificationType == BackgroundNotificationType.backgroundInformation ||
              argument.notificationType == BackgroundNotificationType.toastAndBrackgroundInformation
            ) {
              this.broadCastService.backgroundNotification$.next(argument);
            }
          }
        );
      })
      .catch(err => console.error('WebNotificationSocketService error {erro}', err));
  };

  private getWebNotificationUrl(): string {
    const { apis } = this.environmentService.getEnvironment();
    return apis[this.API_NAME]?.signalRUrl + '/' + this.HUB_NAME;
  }
}
