import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  MissionContactsPortfolioDataDto,
  MissionWithCustomersAndContactsDto,
  PortfolioSubclientDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import {
  ABP,
  ConfigStateService,
  ListService,
  LocalizationService,
  PagedResultDto,
} from '@abp/ng.core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import {
  type CustomerContactsCreateDto,
  CustomerContactsDto,
  CustomersDto,
  GetCustomerInput,
  GetCustomerContactInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';

import {
  ContactsCreateDto,
  ContactsDto,
  ContactsUpdateDto,
  GetContactInput,
  MissionLocationContactDto,
  PortafolioContactsDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { MissionClientsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/relationals';
import {
  CustomerContactsService,
  CustomersService,
} from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import {
  ContactsService,
  PortafolioContactsService,
  PortafoliosService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';

import {
  ActionOrderService,
  ContactMethodsService,
  ContactTypesService,
  LevelCommunicationsService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { ColumnAction } from '../../../components/columns/components/column-actions/column-actions.component';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { contactTypeEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/contact-type.enum';
import {
  ContactMethodsDto,
  ContactTypesDto,
  GetContactMethodInput,
  type GetContactTypeInput,
  GetLevelCommunicationInput,
  LevelCommunicationsDto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { ActionOrderConfiguredDto } from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import { contactMethodEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/contact-method.enum';
import { levelCoordinationEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/level-coordination.enum';
import { contactTypeRules } from '../../../components/columns/rules/lookup-rules';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  from,
  map,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { ConfirmDialogComponent } from '../../../components/common/confirm-dialog/confirm.dialog.component';
import { enumState } from '../../../../../../missions-service/src/lib/proxy/missions-service/shared';
import { PilotSourcingCommunicationService } from '../../pilot-sourcing-communication-service';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { ToasterService } from '@abp/ng.theme.shared';
import { Subject } from 'rxjs/internal/Subject';
import { MissionLocationContactService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals/mission-location-contact.service';
import { getPendingControls } from '@flyguys/components';

const requiredControlsNames = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  state: 'State',
};

@Component({
  selector: 'app-client-information',
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss'],
})
export class ClientInformationComponent implements OnInit, OnDestroy {
  @Input() missionStatusId: string = '';
  @Input() customerId: string = '';

  @Input() missionId: string;
  
  missionClientData: MissionWithCustomersAndContactsDto;
  requestingCustomerDto: CustomersDto;
  contactRequestingDto?: ContactsDto;
  contactBillingDto?: ContactsDto;
  subclientCustomerDto?: CustomersDto;

  data: PagedResultDto<MissionContactsPortfolioDataDto> = {
    items: [],
    totalCount: 0,
  };

  form: FormGroup;

  @Input() formRequesting: FormGroup;

  formBilling: FormGroup;

  isModalBusy = false;

  selected?: MissionContactsPortfolioDataDto;

  // contactfroms = ContactTypeEnumEnumOptions;

  contactFromRules = contactTypeRules;

  @ViewChild('requestingModal') requestingModal: TemplateRef<any>;
  @ViewChild('billingModal') billingModal: TemplateRef<any>;
  @ViewChild('contactModal') contactModal: TemplateRef<any>;

  levelCommunication: PagedResultDto<LevelCommunicationsDto> = {
    items: [],
    totalCount: 0,
  };

  contactMethod: PagedResultDto<ContactMethodsDto> = {
    items: [],
    totalCount: 0,
  };

  contactType: PagedResultDto<ContactTypesDto> = {
    items: [],
    totalCount: 0,
  };

  companyNameControl = new FormControl();
  filterCustomers = {} as GetCustomerInput;
  filteredCustomers: Observable<string[]>;
  latestFilteredCustomers: string[] = [];
  loaders: Map<string, boolean> = new Map();

  isBillingContactLoaded: boolean = false;

  private subs: Subscription;

  // Actions visibility variables - START
  currentActionConfiguration: Array<ActionOrderConfiguredDto>;
  currentUserRoles: Array<string>;
  public readonly CLIENT_INFO_EDIT_REQUESTING_CUSTOMER = 'client_info_edit_requesting_customer';
  public readonly CLIENT_INFO_EDIT_BILLING_CUSTOMER = 'client_info_edit_billing_customer';
  public readonly CLIENT_INFO_ADD_NEW_CONTACT = 'client_info_add_new_contact';
  public readonly CLIENT_INFO_TABLE_EDIT = 'client_info_table_edit';
  public readonly CLIENT_INFO_TABLE_DELETE = 'client_info_table_delete';
  public readonly ORDER_REQUEST_EDIT_SITE_CONTACT_INFORMATION =
    'order_request_edit_site_contact_information';
  public readonly EMPTY_UUID = '00000000-0000-0000-0000-000000000000';
  allowClientInfoEditBillingCustomer: boolean = false;
  allowClientInfoEditRequestingCustomer: boolean = false;
  allowClientInfoAddNewContact: boolean = false;
  allowClientInfoTableEdit: boolean = false;
  allowClientInfoTableDelete: boolean = false;
  // Actions visibility variables - END

  thereIsSubclient: boolean = false;
  billingContactCode = 'BILLING';

  filteredCustomerContacts: Observable<string[]> = of([]);
  filterCustomerContacts = {} as GetCustomerContactInput;
  filterContacts = {} as GetContactInput;
  dataContacts: ContactsDto[];
  contactsFound: ContactsDto[] = [];
  contactselected: ContactsDto;

  filterCustomerContactsC = {} as GetCustomerContactInput;
  filterContactsC = {} as GetContactInput;
  dataContactsC: ContactsDto[];
  contactsFoundC: ContactsDto[] = [];
  contactselectedC: ContactsDto;

  searchTerm: string = '';

  showHintEditContactEmail: boolean = false;

  constructor(
    public readonly list: ListService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private missionClientService: MissionClientsService,
    private customerService: CustomersService,
    private customerContactsService: CustomerContactsService,
    private contactsService: ContactsService,
    public readonly levelCommunicationService: LevelCommunicationsService,
    public readonly contactMethodService: ContactMethodsService,
    public readonly contactTypeService: ContactTypesService,
    public readonly portfolioContactsService: PortafolioContactsService,
    public readonly portfolioService: PortafoliosService,
    public readonly missionLocationContactService: MissionLocationContactService,
    private fb: FormBuilder,
    private stateService: ConfigStateService,
    private readonly actionOrderService: ActionOrderService,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
    private localizationService: LocalizationService,
    private toaster: ToasterService
  ) {
    this.subs = new Subscription();
  }

  ngOnInit() {

    this.getContactsData();
    this.getActionConfiguration();
    this.getLevelCommunicationValues();
    this.getContactMethodValues();
    this.getContactTypeValues();

    this.setupFilteredCustomers();
    this.getAdditionalContacts(this.customerId);
  }

  private generateSuggestionsContacts(
    val: string,
    fieldContactSelected: string,
    fieldtoGet: string,
    fielCustomerContactid: string,
    fielCustomerContactDescription: string,
    fieldEmailEnabled: string,
    fieldNumberEnable: string
  ): Observable<string[]> {
    let suggestions: string[] = [];

    const selectedContact = this[fieldContactSelected];

    let searchTerm = val.toLowerCase();

    this.formRequesting.controls[fieldEmailEnabled].enable();
    this.formRequesting.controls[fieldNumberEnable].enable();

    this.filterCustomerContacts.customerId = this.requestingCustomerDto.id;

    if (!this.filterCustomerContacts.customerId) {
      this.toaster.warn(this.localizationService.instant('missionsService::ContactSearch'));
      return new Observable<string[]>();
    }

    this.filterContacts.filterText = searchTerm;
    this.filterContacts.isPaginated = false;

    return this.contactsService.getList(this.filterContacts).pipe(
      switchMap((data: PagedResultDto<ContactsDto>) => {
        if (data?.totalCount !== 0) {
          this.contactsFound = [];
          this.contactsFound = data.items;

          return from(this.contactsFound).pipe(
            switchMap(async s => {
              this.filterCustomerContacts.contactId = s.id;

              const customerContact = await this.customerContactsService
                .getList(this.filterCustomerContacts)
                .toPromise();

              if (customerContact?.totalCount > 0) {
                this.contactsFound.push(s);
                suggestions.push(s.firstName + ' ' + s.lastName);
              }

              return suggestions;
            })
          );
        } else {
          return [];
        }
      }),
      finalize(() => {
        this.dataContacts = this.contactsFound;
      }),
      catchError(error => {
        console.log('Error on getting contacts: ' + JSON.stringify(error));
        return of([]);
      })
    );
  }

  private generateSuggestionsContactsC(
    val: string,
    fieldContactSelected: string,
    fieldtoGet: string,
    fielCustomerContactid: string,
    fielCustomerContactDescription: string,
    fieldEmailEnabled: string,
    fieldNumberEnable: string
  ): Observable<string[]> {
    let suggestions: string[] = [];

    const selectedContact = this[fieldContactSelected];

    let searchTerm = val.toLowerCase();

    this.form.controls[fieldEmailEnabled].enable();
    this.form.controls[fieldNumberEnable].enable();

    this.filterCustomerContacts.customerId = this.requestingCustomerDto.id;

    if (!this.filterCustomerContacts.customerId) {
      this.toaster.warn(this.localizationService.instant('missionsService::ContactSearch'));
      return new Observable<string[]>();
    }

    this.filterContacts.filterText = searchTerm;
    this.filterContacts.isPaginated = false;

    return this.contactsService.getList(this.filterContacts).pipe(
      switchMap((data: PagedResultDto<ContactsDto>) => {
        if (data?.totalCount !== 0) {
          this.contactsFoundC = [];
          this.contactsFoundC = data.items;

          return from(this.contactsFoundC).pipe(
            switchMap(async s => {
              this.filterCustomerContactsC.contactId = s.id;

              const customerContact = await this.customerContactsService
                .getList(this.filterCustomerContactsC)
                .toPromise();

              if (customerContact?.totalCount > 0) {
                this.contactsFoundC.push(s);
                suggestions.push(s.firstName + ' ' + s.lastName);
              }

              return suggestions;
            })
          );
        } else {
          return [];
        }
      }),
      finalize(() => {
        this.dataContactsC = this.contactsFoundC;
      }),
      catchError(error => {
        console.log('Error on getting contacts: ' + JSON.stringify(error));
        return of([]);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setupFilteredCustomers(): void {
    this.filteredCustomers = this.companyNameControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.loaders.set('customerDescription', true)),
      tap(() => this.validateCompanyName()),
      switchMap(val =>
        this.generateSuggestionsCustomers(val).pipe(
          finalize(() => this.loaders.set('customerDescription', false))
        )
      )
    );
  }

  private generateSuggestionsCustomers(val: string): Observable<string[]> {
    this.filterCustomers.name = val;

    return this.customerService.getList(this.filterCustomers).pipe(
      map(data => {
        this.latestFilteredCustomers = data.items.map(customer => customer.name.trim());
        return this.latestFilteredCustomers;
      }),
      catchError(error => {
        console.log('Error on getting customers: ' + JSON.stringify(error));
        return of([]);
      })
    );
  }

  validateCompanyName(): void {
    if (
      this.companyNameControl.value &&
      !this.latestFilteredCustomers.includes(this.companyNameControl.value)
    ) {
      this.companyNameControl.setErrors({ invalidAutocompleteObject: true });
    } else {
      this.companyNameControl.setErrors(null);
    }
  }

  getClientsData(result: MissionWithCustomersAndContactsDto): void {
    this.missionClientData = result;

    if (
      this.missionClientData.requestingCustomerId &&
      this.missionClientData.requestingCustomerId !== this.EMPTY_UUID
    ) {
      this.customerService
        .get(this.missionClientData.requestingCustomerId)
        .subscribe(result => (this.requestingCustomerDto = result));
    }

    // TODO hidden for ticket 9723
    // this.getContactBillingData(result.requestingCustomerId);

    if (
      this.missionClientData.requestingContactId &&
      this.missionClientData.requestingContactId !== this.EMPTY_UUID
    ) {
      this.contactsService.get(this.missionClientData.requestingContactId).subscribe({
        next: (contact: ContactsDto) => {
          this.contactRequestingDto = contact;
        },
        error: _ => {
          // Fallback in case the contact is not found, so the forms won't break
          this.contactRequestingDto = {
            firstName: '',
            lastName: '',
            contactMethod: '',
            state: enumState.Disabled,
            email: '',
            phone: '',
          };
        },
      });
    }

    // TODO hidden for ticket 9723
    // if (
    //   this.missionClientData.billingContactId &&
    //   this.missionClientData.billingContactId !== this.EMPTY_UUID
    // ) {
    //   this.contactsService.get(this.missionClientData.billingContactId).subscribe({
    //     next: (contact: ContactsDto) => (this.contactBillingDto = contact),
    //     error: _ => {
    //       // Fallback in case the contact is not found, so the forms won't break
    //       this.contactBillingDto = {
    //         firstName: '',
    //         lastName: '',
    //         contactMethod: '',
    //         state: enumState.Disabled,
    //         email: '',
    //         phone: '',
    //       };
    //     },
    //   });
    // }

    if (
      this.missionClientData.subClientCustomerId &&
      this.missionClientData.subClientCustomerId !== this.EMPTY_UUID
    ) {
      this.thereIsSubclient = true;
      this.customerService
        .get(this.missionClientData.subClientCustomerId)
        .subscribe(result => (this.subclientCustomerDto = result));
    } else {
      this.thereIsSubclient = false;
      this.subclientCustomerDto = { ...this.requestingCustomerDto };
      this.subclientCustomerDto.name = '';
    }

    const getData = (query: ABP.PageQueryParams) =>
      this.missionClientService.getContacts(this.missionId);

    const setData = (list: PagedResultDto<MissionContactsPortfolioDataDto>) => (this.data = list);

    this.list.hookToQuery(getData).subscribe(res => {
      setData(res);
    });
  }

  openRequestingModal(): void {
    this.companyNameControl.setValue(this.requestingCustomerDto?.name ?? null);

    // It is dangerous to allow the user to change the customer at this point. It could break the mission.
    // In case the user wants to change the customer, it is better to create a new mission from scratch.
    this.companyNameControl.disable();

    this.formRequesting = this.fb.group({
      projectManagerId: [this.contactRequestingDto?.id ?? null],
      projectManagerSearch: [''],
      projectManagerFirstName: [
        this.contactRequestingDto?.firstName ?? null,
        [Validators.required],
      ],
      projectManagerLastName: [this.contactRequestingDto?.lastName ?? null, [Validators.required]],
      projectManagerEmail: [this.contactRequestingDto?.email ?? null],
      projectManagerNumber: [this.contactRequestingDto?.phone ?? null],
      subclientName: [this.subclientCustomerDto?.name ?? null],
      subclientId: [this.subclientCustomerDto?.id ?? null],
    });

    this.getContacts(this.customerId);
    this.initSubClientSearch();

    this.dialog.open(this.requestingModal, { width: '600px', disableClose: true });
  }

  openBillingModal(): void {
    this.formBilling = this.fb.group({
      firstName: [this.contactBillingDto?.firstName ?? null, [Validators.required]],
      lastName: [this.contactBillingDto?.lastName ?? null, [Validators.required]],
      email: [this.contactBillingDto?.email ?? null, [Validators.required, Validators.email]],
      phone: [this.contactBillingDto?.phone ?? null],
    });
    this.dialog.open(this.billingModal, { width: '600px', disableClose: true });
  }

  closeModal(): void {
    this.dialog.closeAll();
  }

  saveRequestingCustomer(): void {
    if (this.formRequesting.invalid || this.companyNameControl.invalid) return;

    let formValue = this.formRequesting.getRawValue();

    if (this.contactRequestingDto?.id !== formValue.projectManagerId) {
      this.portfolioContactsService
        .updateProjectManager(this.missionId, formValue.projectManagerId)
        .subscribe({
          next: () => {
            this.getContactsData();
            this.toaster.success('Project Manager successfully updated');
          },
          error: err => console.error(err),
        });
    }

    if (this.subclientCustomerDto?.id !== formValue.subclientId) {
      this.portfolioService.updateSubClient(this.missionId, formValue.subclientId).subscribe({
        next: () => {
          this.getContactsData();
          this.toaster.success('Subclient successfully updated');
        },
        error: err => console.error(err),
      });
    }

    this.closeModal();
  }

  updateSubClient(customer: CustomersDto) {
    this.subclientCustomerDto = customer;
    let updateSubclientPortfolio: PortfolioSubclientDto = {
      subclientId: customer.id,
      portfolioId: this.missionClientData.portfolioId,
    };

    this.missionClientService
      .updateSubclientPortfolio(updateSubclientPortfolio)
      .subscribe(result => this.cdr.detectChanges());

    this.thereIsSubclient = true;
  }

  saveBillingCustomer(): void {
    this.formBilling.controls['firstName'].markAsDirty();
    this.formBilling.controls['lastName'].markAsDirty();
    this.formBilling.controls['email'].markAsDirty();
    this.formBilling.controls['phone'].markAsDirty();

    if (this.formBilling.invalid) return;

    const firstName = this.formBilling.controls['firstName'].value;
    const lastName = this.formBilling.controls['lastName'].value;
    const email = this.formBilling.controls['email'].value;
    const phone = this.formBilling.controls['phone'].value;

    if (this.contactBillingDto && this.contactBillingDto.email) {
      this.updateBillingCustomer(firstName, lastName, email, phone);
    } else {
      this.createBillingCustomer(firstName, lastName, email, phone);
    }

    this.closeModal();
  }

  private updateBillingCustomer(
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  ): void {
    let updateContact: ContactsUpdateDto = {
      contactMethodId: this.contactBillingDto.contactMethodId,
      contactTypeId: this.contactBillingDto.contactTypeId,
      firstName: firstName,
      lastName: lastName,
      levelCommunicationId: this.contactBillingDto.levelCommunicationId,
      state: enumState.Enabled,
      email: email,
      phone: phone,
    };

    this.contactsService
      .update(this.contactBillingDto.id, updateContact)
      .subscribe(result => (this.contactBillingDto = result));
  }

  private createBillingCustomer(
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  ): void {
    const contactTypeInput = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetContactTypeInput;

    this.contactTypeService.getList(contactTypeInput).subscribe({
      next: (res: PagedResultDto<ContactTypesDto>) =>
        this.createContact(res, firstName, lastName, email, phone),
      error: err => console.log(err),
    });
  }

  private createContact(
    contactTypesList: any,
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  ): void {
    const billingContactType = contactTypesList.items.find(
      item => item.code === this.billingContactCode
    );
    let billingContactTypeId = '';

    if (billingContactType) {
      billingContactTypeId = billingContactType.id;
    }

    let createContact: ContactsCreateDto = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      state: enumState.Enabled,
      shareData: false,
      contactTypeId: billingContactTypeId,
      contactTypeDescription: billingContactType.description,
    };

    this.contactsService.create(createContact).subscribe({
      next: createdContact => this.createCustomerContact(createdContact),
      error: err => console.log(err),
    });
  }

  private createCustomerContact(createdContact: ContactsDto): void {
    const contactId = createdContact.id;

    let createCustomerContact: CustomerContactsCreateDto = {
      contactId: contactId,
      customerId: this.missionClientData.requestingCustomerId,
      state: enumState.Enabled,
    };

    this.customerContactsService.create(createCustomerContact).subscribe({
      next: createdCustomerContact => (this.contactBillingDto = createdContact),
      error: err => console.log(err),
    });
  }

  // TODO can this be converted to plain html to be able to add the actions visibility logic easily?
  columnActions(record: MissionContactsPortfolioDataDto) {
    let columnActions: ColumnAction[] = [
      {
        actionIcon: 'bi bi-pencil',
        abpPermission: 'missionsService.Contact.Edit && General.Common.Edit',
        action: { callAction: () => this.updateContact(record) },
        visible: this.allowClientInfoTableEdit,
      },
      {
        actionIcon: 'bi bi-trash',
        abpPermission: 'missionsService.Contact.Delete && General.Common.Delete',
        action: { callAction: () => this.deleteContact(record) },
        visible: this.allowClientInfoTableDelete,
      },
    ];

    return columnActions;
  }

  buildForm() {
    const defLevelCommunicationId = this.levelCommunication.items.find(
      levelCommunication => levelCommunication.code == levelCoordinationEnum.Coordinate
    )?.id;
    const defContactMethodId = this.contactMethod.items.find(
      contactMethod => contactMethod.code == contactMethodEnum.Email
    )?.id;
    const defContactTypeId = this.contactType.items.find(
      contactType => contactType.code == contactTypeEnum.Subclient
    )?.id;

    const {
      firstName,
      phone,
      email,
      contactMethod,
      shareData,
      levelCommunication,
      lastName,
      contactType,
    } = this.selected || {};

    this.form = this.fb.group({
      firstName: [firstName ?? null, [Validators.required, Validators.maxLength(100)]],
      lastName: [lastName ?? null, [Validators.required, Validators.maxLength(100)]],
      phone: [phone ?? null],
      email: [
        email ?? null,
        [Validators.email],
        [this.emailExistValidator()],
        { updateOn: 'blur' },
      ],
      levelCommunicationId: [levelCommunication ?? defLevelCommunicationId],
      contactMethodId: [contactMethod ?? defContactMethodId],
      contactTypeId: [contactType ?? defContactTypeId],
      state: ['1', [Validators.required]],
      shareData: [shareData ?? false],
      customerContactDescription: [],
    });

    if (this.selected?.userId) {
      this.form.get('email').disable();
    } else {
      this.form.get('email').enable();
    }
  }

  showForm() {
    this.buildForm();

    this.dialog.open(this.contactModal, { width: '600px', disableClose: true });
  }

  submitForm() {
    if (this.form.invalid) return;

    const formValue = this.form.getRawValue();

    formValue.levelCommunicationDescription = this.levelCommunication.items.find(
      levelCommunication => levelCommunication.id == formValue.levelCommunicationId
    )?.description;

    formValue.contactMethodDescription = this.contactMethod.items.find(
      contactMethod => contactMethod.id == formValue.contactMethodId
    )?.description;

    formValue.contactTypeDescription = this.contactType.items.find(
      contactType => contactType.id == formValue.contactTypeId
    )?.description;

    const request = this.selected
      ? this.contactsService.update(this.selected.contactId, formValue)
      : this.contactsService.create(formValue);

    this.isModalBusy = true;

    request.pipe(tap(contact => this.finishContactOperation(contact))).subscribe({
      next: _ => {
        this.list.get();
        this.dialog.closeAll();
      },
    });
  }

  finishContactOperation(contact: ContactsDto) {
    this.form.reset();
    if (!this.selected) {
      let portfolioContactDto: PortafolioContactsDto = {
        contactId: contact.id,
        state: 1,
        portafolioId: this.missionClientData.portfolioId,
        projectManager: false,
      };

      const request = this.portfolioContactsService.create(portfolioContactDto);

      request.pipe(finalize(() => (this.isModalBusy = false))).subscribe(this.list.get);
    } else {
      this.isModalBusy = false;
    }
  }

  create() {
    this.selected = undefined;
    this.showHintEditContactEmail = false;
    this.showForm();
  }

  updateContact(record: MissionContactsPortfolioDataDto) {
    this.selected = record;
    this.showHintEditContactEmail = true;
    this.showForm();
  }

  deleteContact(record: MissionContactsPortfolioDataDto) {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete this contact?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      width: '400px',
    });

    this.subs.add(
      ref.afterClosed().subscribe({
        next: (state: boolean) => {
          if (state) {
            this.portfolioContactsService.delete(record.portfolioContactId).subscribe({
              next: () => this.list.get(),
              error: err => console.error(err),
            });
          }
        },
      })
    );
  }

  private getActionConfiguration(): void {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    this.actionOrderService.filterByRoles(this.currentUserRoles).subscribe(res => {
      this.currentActionConfiguration = res;
      this.configureActionsVisibility();
    });
  }

  private getLevelCommunicationValues(): void {
    const query = {} as ABP.PageQueryParams;
    const levelCommunicationFilter = { state: enumState.Enabled } as GetLevelCommunicationInput;

    this.levelCommunicationService
      .getList({
        ...query,
        ...levelCommunicationFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.levelCommunication = res;
      });
  }

  private getContactTypeValues(): void {
    const query = {} as ABP.PageQueryParams;
    const contactTypeFilter = { state: enumState.Enabled } as GetContactMethodInput;

    this.contactTypeService
      .getList({
        ...query,
        ...contactTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contactType = res;
      });
  }

  private configureActionsVisibility(): void {
    if (!this.missionStatusId) return;

    this.allowClientInfoAddNewContact = !!this.currentActionConfiguration.find(
      t => t.code == this.CLIENT_INFO_ADD_NEW_CONTACT && t.statusId == this.missionStatusId
    );

    this.allowClientInfoEditBillingCustomer = !!this.currentActionConfiguration.find(
      t => t.code == this.CLIENT_INFO_EDIT_BILLING_CUSTOMER && t.statusId == this.missionStatusId
    );

    this.allowClientInfoEditRequestingCustomer = !!this.currentActionConfiguration.find(
      t => t.code == this.CLIENT_INFO_EDIT_REQUESTING_CUSTOMER && t.statusId == this.missionStatusId
    );

    this.allowClientInfoTableEdit = !!this.currentActionConfiguration.find(
      t => t.code == this.CLIENT_INFO_TABLE_EDIT && t.statusId == this.missionStatusId
    );

    this.allowClientInfoTableDelete = !!this.currentActionConfiguration.find(
      t => t.code == this.CLIENT_INFO_TABLE_DELETE && t.statusId == this.missionStatusId
    );
  }

  private getContactMethodValues(): void {
    const query = {} as ABP.PageQueryParams;
    const contactMethodFilter = { state: enumState.Enabled } as GetContactMethodInput;

    this.contactMethodService
      .getList({
        ...query,
        ...contactMethodFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contactMethod = res;
      });
  }

  // TODO hidden for ticket 9723
  // private getContactBillingData(requestingCustomerId: string) {
  //   const contactTypeInput = {
  //     sorting: '',
  //     skipCount: 0,
  //     maxResultCount: 1000,
  //   } as GetContactTypeInput;
  //
  //   // 1. We get the contact types list from the CORE service, to get the 'BILLING' code.
  //   this.contactTypeService.getList(contactTypeInput).subscribe({
  //     next: res => {
  //       const billingContactType = res.items.find(item => item.code === this.billingContactCode);
  //       let billingContactTypeId = '';
  //
  //       if (billingContactType) {
  //         billingContactTypeId = billingContactType.id;
  //       }
  //
  //       // 2. We get the contacts associated to this specific user from the CUSTOMERS service.
  //       this.customerContactsService.getCustomerContacts(requestingCustomerId).subscribe({
  //         next: (customerContacts: PagedResultDto<CustomerContactsDto>) => {
  //           const customerContactsList = customerContacts.items;
  //
  //           const contactIds = customerContactsList.map(contact => contact.contactId);
  //
  //           // 3. With the information gathered in 1. and 2. we finally get the billing contact from the MISSIONS service.
  //           this.missionClientService
  //             .getBillingContact(contactIds, billingContactTypeId)
  //             .subscribe({
  //               next: (billingContact: ContactsDto) => {
  //                 this.contactBillingDto = billingContact;
  //                 this.isBillingContactLoaded = true;
  //               },
  //               error: err => {
  //                 console.log(err);
  //                 this.isBillingContactLoaded = true;
  //               },
  //             });
  //         },
  //         error: err => {
  //           console.log(err);
  //           this.isBillingContactLoaded = true;
  //         },
  //       });
  //     },
  //     error: err => {
  //       console.log(err);
  //       this.isBillingContactLoaded = true;
  //     },
  //   });
  // }

  customerContactsList: ContactsDto[] = [];
  contacts: ContactsDto[] = [];

  private getContacts(customerId) {
    this.customerContactsService.getCustomerContacts(customerId).subscribe({
      next: (response: PagedResultDto<CustomerContactsDto>) => {
        const customerContactsList = response.items;

        const contactIds = customerContactsList.map(contact => contact.contactId);

        this.contactsService.getContactsByListOfIds(contactIds).subscribe({
          next: (data: ContactsDto[]) => (this.contacts = data),
          error: err => console.error('Error fetching contacts:', err),
        });
      },
      error: err => console.log(err),
    });

    this.customerContactsList = this.contacts;

    this.formRequesting.get('projectManagerSearch').valueChanges.subscribe(val => {
      if (typeof val === 'string') {
        this.customerContactsList = this.contacts.filter(
          contact =>
            (contact.firstName && contact.firstName.toLowerCase().includes(val.toLowerCase())) ||
            (contact.lastName && contact.lastName.toLowerCase().includes(val.toLowerCase())) ||
            (contact.phone && contact.phone.toLowerCase().includes(val.toLowerCase())) ||
            (contact.email && contact.email.toLowerCase().includes(val.toLowerCase())) ||
            (((contact.firstName === null ? '' : contact.firstName).toLowerCase() + ' ' + (contact.lastName === null ? '' : contact.lastName).toLowerCase()).includes(val.toLowerCase()))
        );
      }
    });

    this.formRequesting.get('projectManagerFirstName').disable();
    this.formRequesting.get('projectManagerLastName').disable();
    this.formRequesting.get('projectManagerEmail').disable();
    this.formRequesting.get('projectManagerNumber').disable();
  }

  displayFn(contact: any): string {
    return contact ? `${contact.firstName} ${contact.lastName}` : '';
  }

  openAutocompletePanel(autocompleteTrigger: MatAutocompleteTrigger) {
    if (!autocompleteTrigger.panelOpen) {
      // TODO can be improved ... for now it is a way to achieve opening the panel with suggestions on the first focus
      setTimeout(() => {
        this.customerContactsList = this.contacts;
        autocompleteTrigger.openPanel();
      }, 500);
    }
  }

  onContactSelected(event: MatAutocompleteSelectedEvent) {
    const selectedContact: ContactsDto = event.option.value;

    this.formRequesting.get('projectManagerId').setValue(selectedContact.id);
    this.formRequesting.get('projectManagerFirstName').setValue(selectedContact.firstName);
    this.formRequesting.get('projectManagerLastName').setValue(selectedContact.lastName);
    this.formRequesting.get('projectManagerEmail').setValue(selectedContact.email);
    this.formRequesting.get('projectManagerNumber').setValue(selectedContact.phone);
  }

  private getContactsData() {
    this.missionClientService.get(this.missionId).subscribe(result => this.getClientsData(result));
  }

  additionalContactsList: ContactsDto[] = [];
  additionalContacts: ContactsDto[] = [];

  private getAdditionalContacts(customerId: string) {
    this.customerContactsService.getCustomerContacts(customerId).subscribe({
      next: (response: PagedResultDto<CustomerContactsDto>) => {
        const customerContactsList = response.items;

        const contactIds = customerContactsList.map(contact => contact.contactId);

        this.contactsService.getContactsByListOfIds(contactIds).subscribe({
          next: (data: ContactsDto[]) => (this.additionalContacts = data),
          error: err => console.error('Error fetching contacts:', err),
        });
      },
      error: err => console.log(err),
    });

    this.additionalContactsList = [...this.additionalContacts];
  }

  filterAdditionalContacts(searchValue: string): void {
    if (typeof searchValue === 'string') {
      this.additionalContactsList = this.additionalContacts.filter(
        contact =>
          (contact.firstName &&
            contact.firstName.toLowerCase().includes(searchValue.toLowerCase())) ||
          (contact.lastName &&
            contact.lastName.toLowerCase().includes(searchValue.toLowerCase())) ||
          (contact.phone && contact.phone.toLowerCase().includes(searchValue.toLowerCase())) ||
          (contact.email && contact.email.toLowerCase().includes(searchValue.toLowerCase())) ||
          (((contact.firstName === null ? '' : contact.firstName).toLowerCase() + ' ' + (contact.lastName === null ? '' : contact.lastName).toLowerCase()).includes(searchValue.toLowerCase()))
      );
    }
  }

  onAdditionalContactSelected(event: MatAutocompleteSelectedEvent) {
    const selectedContact: ContactsDto = event.option.value;

    // We do this in order to force the UI to refresh and clear the selected value
    setTimeout(() => {
      this.searchTerm = '';
    });

    // Check if the contact already exists in the portfolioContacts
    if (this.data.items.some(contact => contact.contactId === selectedContact.id)) {
      this.toaster.warn('This contact is already in the list');
      return;
    }

    let portfolioContactDto: PortafolioContactsDto = {
      contactId: selectedContact.id,
      state: 1,
      portafolioId: this.missionClientData.portfolioId,
      projectManager: false,
    };

    this.portfolioContactsService.create(portfolioContactDto).subscribe({
      next: () => {
        this.getContactsData();
        this.toaster.success(
          `Contact: '${selectedContact.firstName} ${selectedContact.lastName}' added successfully.`
        );
      },
      error: err => console.error(err),
    });
  }

  openContactsAutocompletePanel(autocompleteTrigger: MatAutocompleteTrigger) {
    if (!autocompleteTrigger.panelOpen) {
      setTimeout(() => {
        this.additionalContactsList = [...this.additionalContacts];
        autocompleteTrigger.openPanel();
      }, 500);
    }
  }

  subClientsList: CustomersDto[] = [];
  customers: CustomersDto[] = [];
  searchTerms = new Subject<string>();

  initSubClientSearch() {
    this.searchTerms
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.searchSubClients(term)),
        catchError(error => {
          console.error(error);
          return of([]);
        })
      )
      .subscribe((data: PagedResultDto<CustomersDto>) => {
        // Filter out the selected customer to avoid subclient to be the same as the customer
        this.subClientsList = data.items.filter(customer => customer.id !== this.customerId);
      });
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  searchSubClients(filterText: string): Observable<PagedResultDto<CustomersDto>> {
    const input: GetCustomerInput = {
      filterText: filterText,
      name: filterText,
      skipCount: 0,
      maxResultCount: 10,
      isPaginated: true,
      parentId: '',
      state: enumState.Enabled,
    };

    return this.customerService.getList(input);
  }

  subClientDisplayFn(customer: CustomersDto): string {
    return customer.name ?? '';
  }

  onSubClientSelected(event: MatAutocompleteSelectedEvent) {
    const selectedSubClient: CustomersDto = event.option.value;

    this.formRequesting.get('subclientId').setValue(selectedSubClient.id);
    this.formRequesting.get('subclientName').setValue(selectedSubClient.name);
  }

  onInputBlur(inputValue: string): void {
    const matchingCustomer = this.subClientsList.find(
      customer => customer.name.trim().toLowerCase() === inputValue.trim().toLowerCase()
    );

    if (matchingCustomer) {
      this.formRequesting.get('subclientId').setValue(matchingCustomer.id);
      this.formRequesting.get('subclientName').setValue(matchingCustomer.name);
    }
  }

  emailExistValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(email => {
          if (!email || !email.trim()) {
            return of(null);
          }
          return this.customerService
            .checkEmailExists(email.trim())
            .pipe(map(exists => (exists ? { emailExists: true } : null)));
        }),
        first()
      );
    };
  }

  /**
   * Gets pending controls for Edit Contact/New
   */
  getPendingControls() {
    return getPendingControls(this.form, requiredControlsNames);
  }
}
