import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { ePilotsServiceRouteNames } from '../enums/route-names';

export const BASICS_PILOTS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/pilots-service/pilot',
        parentName: ePilotsServiceRouteNames.PilotsService,
        name: 'pilotsService::Menu:Pilot',
        layout: eLayoutType.application,
        requiredPolicy: 'pilotsService.Pilots.ShowMenu',
        iconClass: 'fas fa-user-tie',
      },
    ]);
  };
}
