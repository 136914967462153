import { Environment } from '@abp/ng.core';
const baseUrl = 'https://flyguys-spa-dev-cus.azurewebsites.net';
const oAuthConfig = {
  issuer: 'https://flyguys-authserver-dev-cus.azurewebsites.net/',
  redirectUri: baseUrl,
  clientId: 'Angular',
  scope:
    'offline_access openid profile email phone AccountService IdentityService AdministrationService SaasService ProductService CoreService FileManagementService FGChatService NotificationService missionsService customersService pilotsService qAQCService invoicingService',
  responseType: 'code',
};
export const environment = {
  production: true,
  allowedFileExtensions:
    '.pdf,.jpg,.png,.doc,.docx,.rtf,.txt,.xls,.xlsx,.csv,.ppt,.pptx,.jpeg,.mp4,.avi,.mov,.wmv,.heic,.las,.laz,.tif,.tiff,.dwg,.dng',
  application: {
    baseUrl,
    name: 'Flyguys',
  },
  oAuthConfig,
  apis: {
    default: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net',
      rootNamespace: 'NVC.Flyguys',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    },
    ProductService: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/productservice',
    },
    CoreService: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/coreservice',
    },
    Chat: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/chatservice',
      signalRUrl: 'https://flyguys-chatservice-dev-cus.azurewebsites.net',
    },
    FileManagement: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/mediastorage',
      uploadChunkSizeInBytes: '25000000',
    },
    FileManagementAPI: {
      url: 'https://flyguys-mediastorage-dev-cus.azurewebsites.net',
    },
    missionsService: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/missionsservice',
    },
    customersService: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/customersservice',
    },
    qAQCService: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/qaqcservice',
    },
    pilotsService: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/pilotsservice',
    },
    notificationsService: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/notificationservice',
      signalRUrl: 'https://flyguys-notificationsservice-dev-cus.azurewebsites.net',
    },
    elsa: {
      url: 'https://flyguys-elsaweb-qa-cus.azurewebsites.net/',
    },
    ActionFramework: {
      url: 'https://nvc-actionframework-prod-eastus.azurewebsites.net/api/',
    },
    invoicingService: {
      url: 'https://flyguys-apim-dev-eus.azure-api.net/invoicingservice',
    },
    PilotsApp: {
      url: 'https://flyguys-pilots-dev-cus.azurewebsites.net',
    },
  },
  appInsights: {
    connectionString:
      'InstrumentationKey=a613bedf-fd07-4bb0-a42f-f94ffea55244;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
  },
  googleMaps: {
    apiKey: 'AIzaSyDfedpGtGPGJ9_G7PLaYKZ4en2TfZnGM2Y',
  },
  clarity: {
    apiKey: 'm8z9cyr9n3',
  },
  missionActionsCacheUpdateIntervalInMinutes: '60',
  missionActionsMaxCacheExpirationInMinutes: '60',
  remoteEnv: {
    url: '/getEnvConfig',
    mergeStrategy: 'overwrite',
  },
} as Environment;
