import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { FolderInfo } from '../../models/common-types';
import { DirectoryDescriptorService, FilterMission, RootFolderDetailsDto } from '@volo/abp.ng.file-management/proxy';
import { DecimalPipe } from '@angular/common';
import { NavigatorService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'abp-file-management-breadcrumb',
  templateUrl: './file-management-breadcrumb.component.html',
  styleUrls: ['./file-management-breadcrumb.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe]
})
export class FileManagementBreadcrumbComponent implements OnInit, OnDestroy {
  @Input() rootBreadcrumbName = 'FileManagement::AllFiles';
  @Input() navigatedFolders: FolderInfo[];
  @Output() breadcrumbClick = new EventEmitter<FolderInfo>();
  @Input() missionFilter: FilterMission;

  public currentRootDetails: RootFolderDetailsDto;

  constructor(private directoryService: DirectoryDescriptorService,
    private decimalPipe: DecimalPipe, private changeDetector: ChangeDetectorRef, public navigator: NavigatorService) {
    
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private subscriptions = new Subscription();

  ngOnInit(): void {
    
    this.getRootDetailInformation();

    this.subscriptions.add(this.navigator.navigatedFolderPath$.subscribe(navigation => this.getRootDetailInformation()));
  }

  trackByIdAndName = (_, item: FolderInfo) => item.id + '-' + item.name;

  onClick(item: FolderInfo) {
    this.breadcrumbClick.emit(item);
  }

  public getItemSize(size: number): string {

    if (size < 100000)
      return this.decimalPipe.transform(size / 1000, '1.0-1') + ' KB';

    if (size < 1000000000)
      return this.decimalPipe.transform(size / 1000000, '1.1-1') + ' MB';

    return this.decimalPipe.transform(size / 1000000, '1.1-1') + ' GB';
  }

  private getRootDetailInformation(): void {

    if(!this.missionFilter)
      return;

    this.directoryService.getRootFolderDetails(this.missionFilter.missionId,
      this.missionFilter.deliverableId,
      this.missionFilter.captureId,
      this.missionFilter.orderDetailId).subscribe(res => {

        this.currentRootDetails = res;
        this.changeDetector.detectChanges();
      });
  }
}
