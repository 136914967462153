import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeliverableAspectsFormModel } from '../models/deliverable-aspects-form.model';
import { DatePipe } from '@angular/common';
import { MissionStatus } from '../../../models/mission-status.enum';

@Component({
  selector: 'app-deliverable-aspects-modal',
  templateUrl: './deliverable-aspects-modal.component.html',
  styleUrls: ['./deliverable-aspects-modal.component.scss'],
})
export class DeliverableAspectsModalComponent implements OnInit {
  formDeliverableAspects: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DeliverableAspectsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeliverableAspectsFormModel,
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    let formDate = new Date();

    if (typeof this.data.dueDate === 'string' || typeof this.data.dueDate === 'number') {
      formDate = new Date(this.data.dueDate);
    }

    const dueDateRequired = this.data.missionStatusCode != MissionStatus.CustomerRequest;

    this.formDeliverableAspects = this.fb.group({
      dueDate: [
        this.getDateFromString(this.data.fixedDueDate) || formDate,
        dueDateRequired ? Validators.required : null,
      ],
      allAtSameTime: [this.data.allAtSameTime || false],
      deliverableNotes: [this.data.deliverableNotes],
      additionalNotes: [this.data.additionalNotes],
      uploadingInstructions: [this.data.uploadingInstructions],
    });
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  saveDeliverableAspects(): void {
    if (this.formDeliverableAspects.valid) {
      const updatedDeliverableAspects: DeliverableAspectsFormModel =
        this.formDeliverableAspects.getRawValue();
      updatedDeliverableAspects.missionId = this.data.missionId;
      updatedDeliverableAspects.deliverableAspectsId = this.data.deliverableAspectsId;

      updatedDeliverableAspects.fixedDueDate = this.datePipe.transform(
        this.formDeliverableAspects.value.dueDate,
        'MM/dd/yyyy',
      );

      this.dialogRef.close(updatedDeliverableAspects);
    }
  }

  IsValid(): boolean {
    return this.formDeliverableAspects.valid;
  }

  private getDateFromString(formatedDate: string): Date {
    if (!formatedDate) return undefined;

    const segments = formatedDate.split('/');

    const year = parseInt(segments[2], 10);
    const day = parseInt(segments[1], 10);
    const month = parseInt(segments[0], 10) - 1;

    return new Date(year, month, day);
  }
}
