import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { LocationForm } from '@flyguys/forms';
import { CaptureMustBeEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum';

export class FormHelpers {
  public static ValidationStatus = {
    VALID: 'VALID',
    INVALID: 'INVALID',
  } as const;

  public static CustomerRoles = {
    ADMIN: 'Customer Account Create Mission',
    USER: 'Customer Account Read Only',
  } as const;

  public static buildValidators(validated) {
    var keyNames = Object.keys(validated);
    var form = new FormBuilder().group({});
    for (var i in keyNames) {
      form.addControl(
        keyNames[i],
        new FormControl('', {
          validators: Validators.compose([Validators.required, FormHelpers.noWhitespace]),
          asyncValidators: [],
        }),
      );
    }
    return form;
  }

  public static buildValidatorsOrderStep1() {
    var form: FormGroup = new FormBuilder().group({});
    form.addControl('customerId', new FormControl(''));
    form.addControl(
      'customerDescription',
      new FormControl('', [Validators.required, FormHelpers.noWhitespace]),
    );
    form.addControl('projectManagerSearch', new FormControl(''));
    form.addControl('customerContactId', new FormControl(''));
    form.addControl(
      'customerContactFirstName',
      new FormControl('', [Validators.required, FormHelpers.noWhitespace]),
    );
    form.addControl(
      'customerContactLastName',
      new FormControl('', [Validators.required, FormHelpers.noWhitespace]),
    );
    form.addControl(
      'customerContactEmail',
      new FormControl('', [Validators.required, Validators.email]),
    );
    form.addControl('customerContactNumber', new FormControl(''));
    form.addControl('subcustomerId', new FormControl(''));
    form.addControl('subclientName', new FormControl(''));
    form.addControl('additionalContactsSearch', new FormControl(''));
    form.addControl('additionalContacts', new FormControl([]));

    form.addValidators(FormHelpers.customerSubclientValidator());

    form.addValidators(FormHelpers.customerSubclientValidator());

    return form;
  }

  public static buildValidatorSite() {
    const form: FormGroup = new FormBuilder().group({});
    form.addControl(
      'missionName',
      new FormControl('', [
        Validators.required,
        FormHelpers.noWhitespace,
        Validators.maxLength(385),
      ]),
    );
    form.addControl(
      'missionSummary',
      new FormControl('', [Validators.required, FormHelpers.noWhitespace]),
    );

    form.addControl(
      'captureDate',
      new FormControl('', [Validators.required, FormHelpers.noWhitespace]),
    );

    form.addControl('captureTime', new FormControl(''));

    // Set NoRepeat as default
    form.addControl(
      'frequencyId',
      new FormControl({ value: '0', disabled: true }, [
        Validators.required,
        FormHelpers.noWhitespace,
      ]),
    );

    // Set captureDateMust as AnytimeBefore
    form.addControl('captureDateMustId', new FormControl(CaptureMustBeEnum.AnytimeBefore));

    form.addControl('flexibleCaptureId', new FormControl(''));

    form.addControl('deliveryAllSameTime', new FormControl(''));

    form.addControl('deliveryDate', new FormControl('', [Validators.required]));

    form.addControl('deliveryNotes', new FormControl(''));

    form.addControl('uploadingDataInstruction', new FormControl(''));

    form.addControl('siteContactSearch', new FormControl(''));

    form.addControl('manualAirspaceWaiverRequired', new FormControl(''));

    form.addControl('kmlFiles', new FormControl(''));

    form.addControl(
      'locSiteName',
      new FormControl('', [Validators.required, FormHelpers.noWhitespace]),
    );

    form.addControl('location', new LocationForm());

    form.addControl(
      'locAirSpaceClasfId',
      new FormControl('', [Validators.required, FormHelpers.noWhitespace]),
    );

    form.addControl('aditionalNotes', new FormControl(''));

    form.addControl('projectName', new FormControl(''));
    return form;
  }

  public static buildValidatorsOrderStep2() {
    var form: FormGroup = new FormBuilder().group({});
    form.addControl(
      'priorityId',
      new FormControl('', [Validators.required, FormHelpers.noWhitespace]),
    );
    form.addControl('portfolioName', new FormControl(''));
    form.addControl('missionAssetOrder', new FormControl(''));
    return form;
  }

  public static buildValidatorsOrderStep3() {
    var form: FormGroup = new FormBuilder().group({});
    form.addControl(
      'industryid',
      new FormControl('', [Validators.required, FormHelpers.noWhitespace]),
    );
    return form;
  }

  public static buildValidatorsPackage() {
    var form: FormGroup = new FormBuilder().group({});
    form.addControl('packageId', new FormControl(''));
    form.addControl('price', new FormControl(''));
    form.addControl('quantity', new FormControl({ value: '1', disabled: false }, []));
    return form;
  }
  public static buildValidatorsDeliverables(newDeliverable: Boolean = false) {
    var form: FormGroup = new FormBuilder().group({});
    if (newDeliverable) {
      form.addControl(
        'deliverableId',
        new FormControl('', {
          validators: Validators.compose([Validators.required, FormHelpers.noWhitespace]),
        }),
      );
    }
    form.addControl('deliverableName', new FormControl('', { validators: Validators.compose([]) }));

    return form;
  }

  public static customerSubclientValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const formCustomerName = group.get('customerDescription')?.value;
      const formSubclientName = group.get('subclientName')?.value;

      if (
        typeof formCustomerName === 'string' &&
        formCustomerName.trim() !== '' &&
        typeof formSubclientName === 'string' &&
        formSubclientName.trim() !== ''
      ) {
        const customerDescription = formCustomerName.trim().toLowerCase();
        const subclientName = formSubclientName.trim().toLowerCase();

        if (subclientName && customerDescription === subclientName) {
          return { customerSubclientSame: true };
        }
      }

      // If no error, return null
      return null;
    };
  }

  // This code is duplicated on @forms because of a typing error. If we use the same from
  // Forms TS throws a warning, somehow.
  static noWhitespace(control: AbstractControl): ValidationErrors | null {
    const isControl = typeof control.value === 'string';

    if (isControl) {
      return (control.value || '').trim().length ? null : { whitespace: true };
    } else {
      return null;
    }
  }
}
