<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-11 col-md-11">
      <h3 class="mt-4 mb-4">Confirmation</h3>
      <h4 class="mt-4">Client Details</h4>
      <mat-divider></mat-divider>

      <h4 class="mt-3 mb-3">Requesting Customer</h4>
      <hgroup class="mb-3">
        <h5>Company Name</h5>
        <p>
          {{ model.customerDescription }}
        </p>
      </hgroup>

      <hgroup class="mb-3">
        <h5>Project Manager</h5>
        <p>{{ model.customerContactFirstName }}&nbsp;{{ model.customerContactLastName }}</p>
      </hgroup>

      <div class="input-group justify-content-between mb-3">
        <hgroup class="pe-2 flex-grow-1">
          <h5>Project Manager Email</h5>
          <p>
            {{ model.customerContactEmail }}
          </p>
        </hgroup>

        <hgroup class="pe-2 flex-grow-1">
          <h5>Project Manager Phone</h5>
          <p>{{ model.customerContactNumber || 'N/A' }}</p>
        </hgroup>
      </div>

      <hgroup class="mb-3">
        <h5>Sub Client Name</h5>
        <p>{{ model.subclientName || 'N/A' }}</p>
      </hgroup>

      <h4 class="mt-3 mb-3">Additional Contacts</h4>

      <app-order-contact-table
        class="d-block mb-4"
        [contacts]="model.additionalContacts"
        [columns]="columns"
        [showLevelOfCoordinationColumn]="false"
        [showContactMethodColumn]="false"
        [disableActions]="true"
        (removecontact)="handleRemoveContact($event)"
        (editcontact)="handleEditContact($event)"
      ></app-order-contact-table>

      <h4 class="mt-3">Order Request</h4>
      <mat-divider></mat-divider>

      <div class="input-group justify-content-between mt-3 mb-3">
        <hgroup class="pe-2 flex-grow-1">
          <h4>{{ 'missionsService::SLA' | abpLocalization }}</h4>
          <p>{{ model.priorityDescription }}</p>
        </hgroup>

        <hgroup class="pe-2 flex-grow-1">
          <h4>Order Industry</h4>
          <p>{{ model.industryDescription }}</p>
        </hgroup>
      </div>

      <h4 class="mt-3 mb-3">{{ model.isBulkImport ? ('missionsService::Missions' | abpLocalization ) : 'Sites' }}</h4>
      
      <ngx-datatable *ngIf="model.isBulkImport; else default"
        [rows]="model.bulkData.bulkUploadDetails"
        [columns]="columns"
        [scrollbarH]="true"
        [limit]="10"
        [headerHeight]="55"
        [rowHeight]="50"
        [footerHeight]="50"
        class="material flg-simple-table"
        default
      >
        <ngx-datatable-column
          name="{{ 'missionsService::MissionName' | abpLocalization }}"
          prop="missionName"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.missionName }}
          </ng-template>
        
        
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'missionsService::BulkUploadStatus' | abpLocalization }}"
          prop="bulkUploadStatus"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <app-column-stylizer [columnRules]="orderBulkStates" [typeColumn]="'text'" [columnValue]="row.bulkUploadStatus"></app-column-stylizer>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'missionsService::MissionSummary' | abpLocalization }}"
          prop="missionSummary"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.missionSummary }}
          </ng-template>
        </ngx-datatable-column>     
        
        <ngx-datatable-column
          name="{{ 'missionsService::ProjectName' | abpLocalization }}"
          prop="projectName"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.projectName }}
          </ng-template>
        </ngx-datatable-column>       
                
        <ngx-datatable-column
          name="{{ 'missionsService::CaptureDateTime' | abpLocalization }}"
          prop="beginDate"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.beginDate + (row.time ? ', ' + row.time : '' ) }}
          </ng-template>
        </ngx-datatable-column> 
 
        <ngx-datatable-column
          name="{{ 'missionsService::FailureReason' | abpLocalization }}"
          prop="uploadFailDescription"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.uploadFailDescription }}
          </ng-template>
        </ngx-datatable-column>          

        <ngx-datatable-column
          name="{{ 'missionsService::Preview' | abpLocalization }}"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button (click)="viewBulkDetail(row)">
              <mat-icon>visibility_outline</mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>        
      </ngx-datatable> 

      <ng-template #default>
        <app-mission-overview
          *ngFor="let st of model.orderSites"
          [mission]="st"
        ></app-mission-overview>
      </ng-template>
  
      <h4 class="mt-3">{{ 'missionsService::Summary' | abpLocalization }}</h4>
      <mat-divider class="mb-3"></mat-divider>

      <app-orders-detail-table
        [orderDeliverableDetail]="model.orderDeliverableDetail"
        [orderTotal]="model.orderDetail.total"
        [model]="model"
        [showSummaryTable]="true"
      ></app-orders-detail-table>

      <div class="d-flex justify-content-end mt-3">
        <button
          mat-flat-button
          class="outline-button mb-3 me-auto"
          matStepperPrevious
          type="button"
        >
          Back
        </button>
        <button mat-flat-button class="plain-button me-2" type="button" (click)="discard()">
          Discard
        </button>
        <button
          mat-flat-button
          color="primary"
          class="common-button"
          matStepperNext
          type="button"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
