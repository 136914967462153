import { ABP, ConfigStateService, downloadBlob, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import type {
  GetMissionFinancialRevenuesInput,
  MissionFinancialRevenueWithNavigationPropertiesDto,
  OrderSummary,
  OrderSummaryDetail,
} from '../../../proxy/missions-service/relationals/models';
import { MissionFinancialRevenueService } from '../../../proxy/missions-service/controllers/relationals/mission-financial-revenue.service';
import { RevenueTypeService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/revenue-type.service';
import { ColumnAction } from 'projects/flyguys/src/app/components/columns/components/column-actions/column-actions.component';
import { ColumnStatus } from 'projects/flyguys/src/app/components/columns/components/column-displayer/column-displayer.component';
import { ActivatedRoute } from '@angular/router';
import { ServiceTypeService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/service-type.service';
import { Subscription } from 'rxjs';
import { PilotSourcingCommunicationService } from 'projects/flyguys/src/app/pilot-sourcing/pilot-sourcing-communication-service';
import { MissionFlowService } from 'projects/flyguys/src/app/services/mission-flow.service';
import { ActionOrdered } from 'projects/flyguys/src/app/pilot-sourcing/models/action-ordered-dto';
import { MissionFlowDto } from 'projects/flyguys/src/app/pilot-sourcing/models/mission-flow-dto';
import { OrdersService } from '../../../proxy/missions-service/controllers/basics';
@Component({
  selector: 'lib-mission-financial-revenue',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './mission-financial-revenue.component.html',
  styleUrls: ['./mission-financial-revenue.component.scss'],
})
export class MissionFinancialRevenueComponent implements OnInit, OnDestroy {
  
  @Input() mission: MissionFlowDto;
  @Input() missionId: string;

  data: PagedResultDto<MissionFinancialRevenueWithNavigationPropertiesDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetMissionFinancialRevenuesInput;

  form: FormGroup;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: OrderSummaryDetail;

  revenueTypes: { [id: string]: string } = {};
  serviceTypes: { [id: string]: string } = {};
  action: any;
  clientMaxResultCount = 10;
  pageNumber = 0;

  actions:ActionOrdered[];

  pilotSourcingCommunicationSubscription: Subscription;

  showedColumns = {
    actions: true,
    revenueType: true,
    units: true,
    itemPrice: true,
    totalPrice: true,
    deliverables: true
  };

  private readonly ALLOWED_ORDER_ACTIONS = [
    'modify_revenue_order_details'
  ];

  orderSummary: OrderSummary;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: MissionFinancialRevenueService,
    private confirmation: ConfirmationService,
    private revenueService: RevenueTypeService,
    private serviceTypesService: ServiceTypeService,
    private fb: FormBuilder,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
    private missionFlowService: MissionFlowService,
    private orderService: OrdersService
  ) {}

  ngOnInit() {
    
    this.loadData();

    this.revenueService.getList({ maxResultCount: 100 }).subscribe(r => {
      this.revenueTypes = r.items?.reduce((result, dto) => {
        result[dto.id] = dto.description;
        return result;
      }, {} as { [id: string]: string });
    });

    this.serviceTypesService.getList({ maxResultCount: 100 }).subscribe(r => {
      this.serviceTypes = r.items?.reduce((result, dto) => {
        result[dto.id] = dto.description;
        return result;
      }, {} as { [id: string]: string });
    });

    this.pilotSourcingCommunicationSubscription =
      this.pilotSourcingCommunicationService.reloadFunctionCalled$.subscribe(() => {
        this.loadData();
      });

    this.loadActionInformation();
  }

  ngOnDestroy(): void {
    this.pilotSourcingCommunicationSubscription.unsubscribe();
  }

  reloadPilotSourcing(){
    this.pilotSourcingCommunicationService.callReloadFunction();
  }

  loadData() {
    
    this.filters.missionId = this.missionId;

    this.orderService.getOrderSummaryByMission(this.missionId).subscribe(data => {
      
      this.orderSummary = data;
   });
  }

  setAction(act: any) {
    this.action = act;
  }

  clearFilters() {
    this.filters = {} as GetMissionFinancialRevenuesInput;
  }

  delete(record: OrderSummaryDetail) {
    this.confirmation
      .warn('missionsService::DeleteConfirmationMessage', 'missionsService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.revenueId))
      )
      .subscribe(r => {
        this.pilotSourcingCommunicationService.callReloadFunction();
      });
  }

  private loadActionInformation(): void {
    this.missionFlowService.getActionsPermissions(this.mission.missionStatusCode).subscribe({
      next: response => {
        const actionsOrder = response.filter(r => this.ALLOWED_ORDER_ACTIONS.includes(r.code))
          .sort((a, b) => a.order - b.order);

        const unique = (actionsOrder, track = new Set()) => actionsOrder.filter(({ description }) => (track.has(description) ? false : track.add(description)));

        this.actions = unique(actionsOrder);

      },
      error: err => console.log(err),
    });
  }

  exportToExcel() {
    this.isExportToExcelBusy = true;
    this.service
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter })
        ),
        finalize(() => (this.isExportToExcelBusy = false))
      )
      .subscribe(result => {
        downloadBlob(result, 'MissionFinancialRevenue.xlsx');
      });
  }

  toggler = (column:string, checked:boolean) => this.showedColumns[column] = checked;

  columnsStatus() {
    const columnsStatus:ColumnStatus[] = [
      {
        columnName: 'actions',
        softColumnName: 'Actions',
        defaultChecked: this.showedColumns.actions,
        abpPermission: 'missionsService.MissionFinancialRevenues.ShowColumn.Actions && General.Common.ShowColumn.Actions'
      },
      {
        columnName: 'revenueType',
        softColumnName: 'Revenue Type',
        defaultChecked: this.showedColumns.revenueType,
        abpPermission: 'missionsService.MissionFinancialRevenues.ShowColumn.RevenueType'
      },
      {
        columnName: 'units',
        softColumnName: 'Units',
        defaultChecked: this.showedColumns.units,
        abpPermission: 'missionsService.MissionFinancialRevenues.ShowColumn.Units'
      },
      {
        columnName: 'itemPrice',
        softColumnName: 'Item Price',
        defaultChecked: this.showedColumns.itemPrice,
        abpPermission: 'missionsService.MissionFinancialRevenues.ShowColumn.ItemPrice'
      },
      {
        columnName: 'totalPrice',
        softColumnName: 'Total Price',
        defaultChecked: this.showedColumns.totalPrice,
        abpPermission: 'missionsService.MissionFinancialRevenues.ShowColumn.TotalPrice'
      },
      {
        columnName: 'deliverables',
        softColumnName: 'Deliverables',
        defaultChecked: this.showedColumns.deliverables,
        abpPermission: 'missionsService.MissionFinancialRevenues.ShowColumn.Deliverables'
      },
    ];

    return columnsStatus;
  }
}
