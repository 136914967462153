import { ABP, ListService, PagedResultDto, TrackByService, downloadBlob } from '@abp/ng.core';
import { Component, OnInit, Input } from '@angular/core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { PilotDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import { PilotSourcingService } from 'projects/flyguys/src/app/services/pilot-sourcing.service';
import { PilotsBadges } from '../../../models/pilot-sourcing-dto';
import { PilotRequest } from '../../../models/pilot-for-sorucing-request';


@Component({
  selector: 'app-records',
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class RecordsComponent implements OnInit {
  @Input() pilot: PilotDto;
  badges: PagedResultDto<PilotsBadges> = {
    items: [],
    totalCount: 0,
  };
  
  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: PilotSourcingService
  ) {}

  ngOnInit() {
    var filters = { pilotId: this.pilot.id } as PilotRequest;
    const getData = (query: ABP.PageQueryParams) =>
    this.service.getBadges({
      ...query,
      ...filters,
    });

    const setData = (list: PagedResultDto<PilotsBadges>) => (this.badges = list);

    this.list.hookToQuery(getData).subscribe(setData);
    
  }
  

}
