<abp-page [hidden]="hideMainView()">
  <div class="d-flex mb-2 align-items-center sticky-header">
    <h1 class="mb-0">Order List</h1>
    <abp-page-toolbar-container class="col">
      <div class="text-lg-end">
        <button
          mat-button
          class="common-button"
          type="button"
          color="primary"
          (click)="createNewOrder()"
          *abpPermission="'missionsService.Mission.Create && General.Common.Create'"
        >
          <mat-icon fontIcon="add"></mat-icon>
          New Order
        </button>
      </div>
    </abp-page-toolbar-container>
  </div>
  <mat-tab-group [(selectedIndex)]="tabSelected" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Missions">
      <br />
      <orders-missions (onOpenMission)="onOpenMissions($event)"></orders-missions>
    </mat-tab>

    <!--<mat-tab label="Projects"
          ><br />
          <orders-projects></orders-projects
        ></mat-tab>-->
    <mat-tab label="Portfolios">
      <br />
      <ng-template matTabContent>
        <orders-portfolio></orders-portfolio>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</abp-page>

<ng-container *ngIf="missionOpened && !createMission">
  <app-mission-flow
    [missionId]="missionOpened"
    [goToPilotSourcingGrid]="goToPilotSourcingGrid"
    [displayFromOrders]="true"
    [currentCaptureId]="currentCaptureId"
    (onGoBack)="handleOnGoBack()"
  >
  </app-mission-flow>
</ng-container>

<ng-container *ngIf="createMission">
  <app-new-order-form (onGoBack)="handleOnGoBack($event)"> </app-new-order-form>
</ng-container>
