import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NewOrderFormComponent } from '../components/orders/new-order-form/new-order-form.component';
import { OrderEditingService } from '../components/orders/services/order-editing.service';

@Injectable({
  providedIn: 'root',
})
export class OrderEditGuard  {
  constructor(private router: Router, private orderEditingService: OrderEditingService) {}

  async canDeactivate(
    component: NewOrderFormComponent,
    currentRoute: ActivatedRouteSnapshot
  ): Promise<boolean> {
    if (component.isEditing) {
      const confirmation = await component.discard();
      return confirmation;
    } else {
      return true;
    }
  }
}
