import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { ePilotsServiceRouteNames } from '../enums/route-names';

export const RELATIONALS_REPORT_PARAMETERS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/pilots-service/report-parameter',
        parentName: ePilotsServiceRouteNames.PilotsService,
        name: 'pilotsService::Menu:ReportParameter',
        layout: eLayoutType.application,
        requiredPolicy: 'pilotsService.ReportParameter.ShowMenu',
        iconClass: 'fas fa-list-alt'
      },
    ]);
  };
}
