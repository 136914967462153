import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderFormPackageModel } from 'projects/flyguys/src/app/components/orders/model/order-form-package-model';
import { FormHelpers } from 'projects/flyguys/src/app/form-helpers';

import { OrderFormModel } from '../../components/orders/model/order-form.model';
import { OrderFormDeliverableModel } from '../../components/orders/model/order-form-deliverable.model';
import { OrderFormDeliverableFieldModel } from '../../components/orders/model/order-form-deliverable-field.model';
import { MatDialog } from '@angular/material/dialog';
import { DeliverableAttributesListComponent } from '../deliverable-attributes-list/deliverable-attributes-list.component';

import { Subscription } from 'rxjs';
import { DeliverableAttribute } from '../deliverable-attributes-list/deliverable-attribute.interface';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageComponent implements OnInit, OnDestroy {
  private readonly quantityControl = 'quantity';
  private readonly defaultQuantity = 1;

  @Input() package: OrderFormPackageModel;
  @Input() disableChildren = false;

  @Input() genericPackage = false;

  @Input() model: OrderFormModel;
  originalQuantityDeliverable: number;

  formPackage: FormGroup;

  incompleteConfiguration: boolean;
  missingRequiredConfiguration: boolean;
  editingPrice: boolean;

  private subscriptions = new Subscription();

  constructor(
    private dialogService: MatDialog,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.formPackage = FormHelpers.buildValidatorsPackage();

    const existingPackageIndex = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );

    if (existingPackageIndex !== -1) {
      // Package already selected, set its selected property and update form control
      this.package.selected = true;
      this.formPackage
        .get('quantity')
        .setValue(this.model.orderDetail.packagesSelected[existingPackageIndex].quantity);

      this.package.deliverables =
        this.model.orderDetail.packagesSelected[existingPackageIndex].deliverables;
    } else {
      // Package not selected, set default quantity
      this.package.quantity = 1;
    }
  }

  selectPackage() {
    const existingPackageIndex = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );

    if (existingPackageIndex !== -1) {
      // Package already selected, update its quantity
      this.model.orderDetail.packagesSelected[existingPackageIndex].quantity =
        this.package.quantity;
    } else {
      // Package not selected, add it to the array
      this.package.selected = true;
      this.package.genericPackage = this.genericPackage;
      this.model.orderDetail.packagesSelected.push(this.package);
    }

    this.updatePackageTotals();
    this.model.buildDetails();
    this.editAttributes(false);
    this.validateRequiredConfigurations();
  }

  updatePrice() {
    const indexEdit = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );

    if (indexEdit !== -1) {
      const packageselected = this.model.orderDetail.packagesSelected[indexEdit];
      packageselected.packagePrice = String(this.formPackage.get('price').value);
      this.package.packagePrice = packageselected.packagePrice;
      this.editingPrice = false;
      this.onQuantityChange(null);
    }
  }

  unselectPackage() {
    this.package.selected = false;
    this.package.incompletePackage = false;
    this.clearAttributes();
    const indexRemove = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );
    if (indexRemove !== -1) {
      this.model.orderDetail.packagesSelected.splice(indexRemove, 1);
    }
    this.updatePackageTotals();
    this.model.buildDetails();
    this.model.orderDetail.packagesSelected = [...this.model.orderDetail.packagesSelected];
  }

  onQuantityChange(event: any) {
    this.updatePackageTotals();
    this.model.buildDetails();
    this.validateConfigurations();
    this.validateRequiredConfigurations();
  }

  onQuantityBlur() {
    const quantityControl = this.formPackage.get('quantity');
    if (!quantityControl.value) {
      quantityControl.setValue(1);
    }
  }

  editPrice() {
    this.editingPrice;
  }

  updatePackageTotals() {
    const quantityControl = this.formPackage.get('quantity');
    const indexEdit = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );

    if (indexEdit !== -1) {
      const packageselected = this.model.orderDetail.packagesSelected[indexEdit];

      this.formPackage.get('price').setValue(Number(packageselected.packagePrice));

      packageselected.quantity = quantityControl.value;
      packageselected.packageSubtotal =
        packageselected.quantity * Number(packageselected.packagePrice);
      packageselected.packageSubtotal = Math.abs(
        Number(packageselected.packageSubtotal.toFixed(2)),
      );
      packageselected.deliverables.forEach((d: OrderFormDeliverableModel) => {
        const quantityToAdd = packageselected.quantity;
        const numericQuantityToAdd = Number(quantityToAdd);
        const originalQuantity = d.originalquantity ?? 1;
        let total = Number(originalQuantity * numericQuantityToAdd);
        d.quantity = total;
        d.subTotal = d.quantity * d.actualPrice;
        d.subTotal = Math.abs(Number(d.subTotal.toFixed(2)));
      });
    }
  }

  editAttributes(isEdition: boolean) {
    this.incompleteConfiguration = false;

    if (!isEdition) {
      this.package.deliverables.forEach(x => {
        x.sameConfiguration = true;
      });
    }

    const dialogData = {
      title: 'Add Deliverables Attributes',
      deliverableList: this.package.deliverables,
      quantity: this.getCurrentQuantity(),
      isEdition: isEdition,
    };

    const dialogRef = this.dialogService.open(DeliverableAttributesListComponent, {
      width: '1150px',
      data: dialogData,
      disableClose: true,
    });
    const dialogSub = dialogRef
      .afterClosed()
      .subscribe((attributesData: Array<DeliverableAttribute>) => {
        if (!attributesData) {
          this.validateRequiredConfigurations();
          return;
        }

        let packageUpdated = this.model.orderDetail.packagesSelected.find(
          item => item.packageId === this.package.packageId,
        );

        for (let deliverable of packageUpdated.deliverables) {
          let attributeValues = attributesData.find(x => x.productId == deliverable.productId);

          if (!attributeValues) continue;

          deliverable.sameConfiguration = attributeValues.sameConfiguration;
          deliverable.fields = [...attributeValues.fields];

          let detailedAttributes = attributeValues.detailedAttributes.map(x => [...x]);

          deliverable.detailedAttributes = [...detailedAttributes];
        }

        this.validateRequiredConfigurations();
      });
    this.subscriptions.add(dialogSub);
  }

  clearAttributes() {
    this.package.deliverables.forEach((d: OrderFormDeliverableModel) => {
      d.fields.forEach((f: OrderFormDeliverableFieldModel) => {
        f.numericValue = 0;
        f.stringValue = '';
        f.boolValue = false;
      });
    });

    this.setQuantity(this.defaultQuantity);
    this.package.quantity = this.defaultQuantity;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private getCurrentQuantity(): number {
    return this.formPackage.get(this.quantityControl).value;
  }

  private setQuantity(value: number): void {
    this.formPackage.get(this.quantityControl).setValue(value);
  }

  private validateConfigurations(): void {
    this.incompleteConfiguration = false;

    for (let deliverable of this.package.deliverables) {
      if (deliverable.sameConfiguration) continue;

      this.incompleteConfiguration = deliverable.quantity > deliverable.detailedAttributes?.length;

      if (this.incompleteConfiguration) break;
    }
  }

  private validateRequiredConfigurations(): void {
    this.missingRequiredConfiguration = false;

    for (let deliverable of this.package.deliverables) {
      if (deliverable.sameConfiguration) {
        let requiredAttributes = deliverable.fields?.filter(x => x.isRequired) || [];

        this.missingRequiredConfiguration = !!requiredAttributes.find(
          x => x.stringValue == undefined || x.stringValue == '',
        );

        if (this.missingRequiredConfiguration) break;
      } else {
        if (!deliverable.detailedAttributes) deliverable.detailedAttributes = [];

        let hasRequiredAttr = !!deliverable.fields?.find(x => x.isRequired);

        if (hasRequiredAttr && deliverable.quantity > deliverable.detailedAttributes.length) {
          this.missingRequiredConfiguration = true;
          break;
        }

        for (let listOfAttributes of deliverable.detailedAttributes) {
          let requiredAttributes = listOfAttributes?.filter(x => x.isRequired) || [];

          this.missingRequiredConfiguration = !!requiredAttributes.find(
            x => x.stringValue == undefined || x.stringValue == '',
          );

          if (this.missingRequiredConfiguration) break;
        }

        if (this.missingRequiredConfiguration) break;
      }

      if (this.missingRequiredConfiguration) break;
    }

    this.package.incompletePackage = this.missingRequiredConfiguration;

    this.changeDetector.detectChanges();
  }
}
