<div class="w-100 h-100 d-flex flex-column">
  <div class="header d-flex align-items-center justify-content-between p-2">
    <h3 class="fs-14 bold p-0 m-0">
      Uploaded {{ _parallelFileUploadService.uploadedFileCount }} /
      {{ _parallelFileUploadService.QueuedFilesPreview.length }} files
    </h3>
    <div class="d-flex gap-1">
      <button
        *ngIf="expanded"
        mat-icon-button
        color="accent"
        class="small-icon-button"
        (click)="toogleExpand()"
      >
        <mat-icon>expand_more</mat-icon>
      </button>
      <button
        *ngIf="!expanded"
        mat-icon-button
        color="accent"
        class="small-icon-button"
        (click)="toogleExpand()"
      >
        <mat-icon>expand_less</mat-icon>
      </button>
      <button
        mat-icon-button
        color="accent"
        class="small-icon-button"
        (click)="hideProgressViewer()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="container d-flex flex-grow-1 flex-column gap-2">
    <ng-container *ngIf="_parallelFileUploadService.PaginatedQueuedFilesPreview.totalPages > 0">
      <div
        *ngFor="
          let file of _parallelFileUploadService.PaginatedQueuedFilesPreview.pages[
            _parallelFileUploadService.PaginationIndex
          ].files
        "
      >
        <div class="d-flex justify-content-between align-items-center file-preview gap-1">
          <div class="d-flex flex-column gap-1 flex-grow-1">
            <p class="p-0 m-0 fs-14">{{ file.name }}</p>
            <p class="p-0 m-0 path" *ngIf="file.webkitRelativePath">
              {{ file.webkitRelativePath }}
            </p>
          </div>
          <div class="d-flex gap-1 align-items-center">
            <p *ngIf="file.status == 0 && file.progress == 0" class="p-0 m-0 fs-14 bold">
              ...Waiting
            </p>

            <p class="path p-0 m-0 text-nowrap" *ngIf="file.status == 1 && file.progress > 0">
              {{ file.uploadNetworkRate }}
            </p>

            <p *ngIf="file.status == 6" class="p-0 m-0 fs-14 bold">
              ...Retrying in {{ file.minutesUntilRetry }} minutes
            </p>

            <p *ngIf="file.status == 7" class="p-0 m-0 fs-14 bold">...Exceeded retry attempts</p>

            <div class="blue-spinner" *ngIf="file.status == 1 && file.progress > 0">
              <mat-progress-spinner diameter="20" mode="determinate" [value]="file.progress">
              </mat-progress-spinner>
            </div>

            <div class="blue-spinner" *ngIf="file.status == 2">
              <mat-progress-spinner diameter="20" mode="indeterminate"> </mat-progress-spinner>
            </div>

            <div class="green-spinner" *ngIf="file.status == 3">
              <mat-progress-spinner diameter="20" mode="determinate" [value]="file.progress">
              </mat-progress-spinner>
            </div>

            <div
              class="red-spinner"
              *ngIf="file.status == 5 || file.status == 6 || file.status == 7"
            >
              <mat-progress-spinner diameter="20" mode="determinate" [value]="file.progress">
              </mat-progress-spinner>
            </div>

            <button
              *ngIf="file.status == 0 || file.status == 1"
              mat-icon-button
              color="accent"
              class="small-icon-button"
              (click)="cancelUpload(file)"
            >
              <mat-icon>close</mat-icon>
            </button>

            <button
              *ngIf="file.status == 5 || file.status == 6 || file.status == 7"
              mat-icon-button
              color="accent"
              class="small-icon-button"
              (click)="retryUpload(file)"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="footer d-flex justify-content-between align-items-center">
    <button mat-button class="flex-grow-1" (click)="retryAll()">
      <p class="fs-12 m-0 p-0">Retry failed</p>
    </button>

    <button mat-button class="flex-grow-1" (click)="clearCompleted()">
      <p class="fs-12 m-0 p-0">Clear completed</p>
    </button>
  </div>
  <div class="footer d-flex justify-content-between align-items-center">
    <button mat-icon-button color="accent" class="small-icon-button" (click)="previousPage()">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <p class="m-0 p-0">
      {{ _parallelFileUploadService.PaginationIndex + 1 }} /
      {{ _parallelFileUploadService.PaginatedQueuedFilesPreview.totalPages }}
    </p>

    <button mat-icon-button color="accent" class="small-icon-button" (click)="nextPage()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
