import { Component, TrackByFunction } from '@angular/core';

import { ABP, RoutesService, TreeNode } from '@abp/ng.core';

import { ToggleService } from '../header/toggle.service';
import { CustomizerSettingsService } from '../../tagus/customizer-settings/customizer-settings.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  isToggled = false;

  constructor(
    private toggleService: ToggleService,
    public themeService: CustomizerSettingsService,
    public routesService: RoutesService
  ) {
    this.toggleService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
  }

  trackByFn: TrackByFunction<TreeNode<ABP.Route>> = (_, item) => item.name;

  toggle() {
    this.toggleService.toggle();
  }

  toggleSidebarTheme() {
    this.themeService.toggleSidebarTheme();
  }

  toggleHideSidebarTheme() {
    this.themeService.toggleHideSidebarTheme();
  }

  isExpandable(node: TreeNode<ABP.Route>) {
    return !node?.isLeaf || this.routesService.hasChildren(node.name);
  }

  /**
   * Handles a clickable nav link to we can collapse the sidebar menu when an item
   * is interacted with
   */
  handleNavClick() {
    if (this.toggleService.state === false) {
      this.toggleService.hide();
    }
  }
}
