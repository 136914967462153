import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Observable, forkJoin, from, of, switchMap, tap } from 'rxjs';
import { HttpCacheUpdaterService } from '../services/http-cache-updater.service';

@Injectable()
export class ActionFrameworkInterceptor implements HttpInterceptor {
  constructor(private readonly _httpCacheUpdaterService: HttpCacheUpdaterService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('api/Grid/get-actions-grid/Missions')) {
      this._httpCacheUpdaterService.addRequestToCacheUpdater(req);

      const headerFromCache = req.headers.get('FROM_CACHE');

      return forkJoin([
        from(this._httpCacheUpdaterService.getFromCacheDB(req.url)),
        from(this._httpCacheUpdaterService.isExpired(req.url)),
      ]).pipe(
        switchMap(results => {
          if (results[0] && !headerFromCache && !(results[1] as boolean)) {
            return of(new HttpResponse({ status: 200, body: results[0] }));
          } else {
            return next.handle(req).pipe(
              tap(event => {
                if (event instanceof HttpResponse) {
                  const timestamp = new Date().getTime();
                  this._httpCacheUpdaterService.saveToCacheDB(`${req.url}-updateOn`, {
                    expiredIn: timestamp.toString(),
                  });
                  this._httpCacheUpdaterService.saveToCacheDB(req.url, event.body);
                }
              })
            );
          }
        }),
        tap({
          error: error => {
            console.error('An error occurred:', error);
          },
        })
      );
    }

    return next.handle(req);
  }
}
