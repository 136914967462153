import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eCoreServiceRouteNames } from '../enums/route-names';

export const LOOKUPS_ACTION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/core-service/actions',
        parentName: eCoreServiceRouteNames.CoreService,
        name: 'CoreService::Menu:Actions',
        layout: eLayoutType.application,
        requiredPolicy: 'CoreService.Actions.ShowMenu',
        iconClass: 'far fa-hand-point-down'
      },
    ]);
  };
}
