import type { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { enumState } from '../shared/enum-state.enum';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import {
  BillingInformation,
  CompanyCustomer,
  CustomerType,
  PersonalCustomer,
} from 'projects/flyguys/src/app/components/orders/model/order-form-create-customer.model';
import { ContactsDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';

export interface CustomerContactsCreateDto {
  customerId: string;
  contactId: string;
  state: enumState;
}

export interface CustomerContactsDto extends FullAuditedEntityDto<string> {
  customerId: string;
  contactId: string;
  state: enumState;
}

export interface CustomerContactsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CustomerContactsUpdateDto {
  customerId: string;
  contactId: string;
  state: enumState;
}

export interface CustomersCreateDto {
  name: string;
  parentId: string;
  levelId: string;
  customerStatusId: string;
  language: string;
  state: enumState;
  email?: string;
  industryId?: string;
  industryName?: string;
  mainContactPhoneNumber?: string;
  customerVerticalId?: string;
}

export interface RequestingCustomerDto {
  companyName: string;
  projectManager: string;
  projectManagerEmail: string;
  projectManagerPhone: string;
  subclientName: string;
}

export interface BillingCustomerDto {
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  sameAsSubclient: boolean;
}

export interface CustomersDto extends FullAuditedEntityDto<string> {
  name: string;
  parentId: string;
  levelId: string;
  levelDescription: string;
  customerStatusId: string;
  customerStatusDescription: string;
  language: string;
  languageDescription: string;
  state: enumState;
  industryId?: string;
  industryName?: string;
  customerVerticalId?: string;
  legalName: string;
  ein: string;
  website: string;
  isCompany: boolean;
  msa: boolean;
  idHubspot?: string;
}

export interface CustomersExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CustomersUpdateDto {
  name: string;
  parentId?: string;
  levelId: string;
  customerStatusId: string;
  language: string;
  state: enumState;
  industryId?: string;
  industryName?: string;
  email?: string;
  mainContactPhoneNumber?: string;
  customerVerticalId?: string;
  isCompany?: boolean;
  legalName?: string;
  ein?: string;
  website?: string;
  msa?: boolean;
  idHubspot?: string;
}

export class UpdateCustomer {
  customerId: string;
  customerType: CustomerType;
  personal?: PersonalCustomer;
  company?: CompanyCustomer;
  mainContactId?: string;
  mainContactUserId?: string;
  mainContactUserName?: string;
  mainContactTypeId?: string;
  billingContactId?: string;
  billingInformation: BillingInformation;
  billingContactTypeId?: string;
  addressId?: string;

  constructor(
    customerId: string,
    mainContactId: string,
    mainContactUserId: string,
    mainContactUserName: string,
    mainContactTypeId: string,
    billingContactId: string,
    billingContactTypeId: string,
    customerType: CustomerType,
    personal: PersonalCustomer | undefined,
    company: CompanyCustomer | undefined,
    billingInformation: BillingInformation,
    addressId: string
  ) {
    this.mainContactId = mainContactId;
    this.billingContactId = billingContactId;
    this.customerId = customerId;
    this.customerType = customerType;
    this.personal = personal;
    this.company = company;
    this.billingInformation = billingInformation;
    this.mainContactUserId = mainContactUserId;
    this.mainContactUserName = mainContactUserName;
    this.addressId = addressId;
    this.mainContactTypeId = mainContactTypeId;
    this.billingContactTypeId = billingContactTypeId;
  }
}

export interface GetCustomerContactInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  customerId?: string;
  contactId?: string;
  state: enumState;
}

export interface GetCustomerInput extends PagedAndSortedResultRequestDto {
  filter?: string;
  filterText?: string;
  name?: string;
  parentId: string;
  levelId?: string;
  customerStatusId?: string;
  language?: string;
  state: enumState;
  idHubspot?: string;
  isPaginated?: boolean;
}

export interface CustomerWithNavigationPropertiesDto extends PagedAndSortedResultRequestDto {
  customer: CustomersDto;
  user: IdentityUserDto;
  mainContact?: ContactsDto;
}
