<div class="container-milestones">
  <span class="title">Define Milestone Due Dates for Custom SLA</span>
  <div class="grid-container">
    <ng-container *ngFor="let milestone of slas; let index = index">
      <div class="d-flex flex-column gap-2">
        <span class="title-due-date">{{ +index + 1 }}. {{ milestone.name }}</span>
        <mat-form-field class="pe-2 flex-grow-1">
          <mat-label>Due Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [value]="milestone.dueDate"
            (dateInput)="handleSelectDate($event, milestone, index)"
            readonly
          />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</div>
