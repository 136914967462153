import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageSucessComponent } from './components/common/message/message.success.component';
import { EventEmitter } from '@angular/core';

export class Helpers {
  public static openDialog(
    dialog: MatDialog,
    msgtitle: string,
    msg: String,
    route: Router,
    routerUrl?: string,
    onGoBack?: EventEmitter<boolean>
  ): void {
    const dialogRef = dialog.open(MessageSucessComponent, {
      data: { title: msgtitle, message: msg },
      disableClose: true,
      width: '475px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (onGoBack.observers.length > 0)
      {
        onGoBack.emit(true);
      }
      else
      {
        route.navigate([routerUrl]);
      }
    });
  }
}
