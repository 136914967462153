import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { eFileManagementPolicyNames } from '@volo/abp.ng.file-management/config';
import { UPPY_OPTIONS } from '@volo/abp.ng.file-management/common';
import { UploadService } from '../../services/upload.service';
import { LocalizationService, PermissionService } from '@abp/ng.core';
import { take } from 'rxjs/operators';
import { NavigatorService } from '../../services/navigator.service';
import { FolderInfo } from '../../models';
import { DirectoryTreeService, ROOT_NODE } from '../../services';
import { UppyOptions } from '@uppy/core';
import { DirectoryDescriptorInfoDto, FilterMission } from '@volo/abp.ng.file-management/proxy';
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

const TITLE_CLASS_LIST = ['upload-folder-title', 'text-center', 'mb-1', 'mt-1', 'order-first'];

@Component({
  selector: 'abp-file-management-buttons',
  templateUrl: './file-management-buttons.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FileManagementButtonsComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  private readonly modalTitle = 'FileManagement::UploadFileModalTitle';

  folderCreateModal = false;
  fileCreatePermission = eFileManagementPolicyNames.FileDescriptorCreate;
  directoryCreatePermission = eFileManagementPolicyNames.DirectoryDescriptorCreate;

  buttonId = 'upload-files-btn';
  uppyWrapperParentComponent: Element;
  uploadFolderTitle: Element;
  currentFolder: FolderInfo = ROOT_NODE;
  @Input() rootId: string;
  @Input() missionFilter: FilterMission;
  @Input() onlyViewDownload: boolean;
  isComplete$: Observable<boolean>;

  subscription = new Subscription();
  currentSelectedNode: DirectoryDescriptorInfoDto;

  ROOT_FOLDER_NAME = 'Root';
  ROOT_FOLDER_ID = 'ROOT_ID';

  private get titleElement(): Element {
    return this.document.querySelector('h3.upload-folder-title');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rootId) {
      this.uploadService.rootId = this.rootId;
    }
  }

  private initTitle(): void {
    this.navigatorService.currentFolder$.subscribe(({ name }) => {
      this.createUploadFolderTitle(name);
      const folderName = this.localizationService.instant(this.modalTitle, name);
      this.renderer.setProperty(this.uploadFolderTitle, 'innerText', folderName);
    });
  }

  //TODO: @Masum Find a way much better
  private createUploadFolderTitle(folderName: string) {
    this.uppyWrapperParentComponent = this.document.querySelector('div.uppy-Dashboard-innerWrap');

    if (!this.titleElement) {
      const title = this.renderer.createElement('h3');
      TITLE_CLASS_LIST.map(className => this.renderer.addClass(title, className));

      folderName = this.localizationService.instant(this.modalTitle, folderName);
      this.renderer.setProperty(title, 'innerText', folderName);
      this.renderer.appendChild(this.uppyWrapperParentComponent, title);
    }
    this.uploadFolderTitle = this.titleElement;
  }

  constructor(
    private uploadService: UploadService,
    private permissionService: PermissionService,
    private navigatorService: NavigatorService,
    private localizationService: LocalizationService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    public service: DirectoryTreeService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(UPPY_OPTIONS) private readonly uppyOptions: UppyOptions<any>,
    private readonly _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.service.currentSelectedNode$.subscribe(selectedNode => {
        this.currentSelectedNode = selectedNode;
        this.cdr.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.permissionService
      .getGrantedPolicy$(this.fileCreatePermission)
      .pipe(take(1))
      .subscribe(() => {
        this.isComplete$ = this.uploadService.initUppy(
          this.elementRef.nativeElement.querySelector(`#${this.buttonId}`),
          this.uppyOptions
        );
        this.initTitle();
      });
    this.isComplete$.subscribe({
      next: isComplete => {
        const message = isComplete
          ? 'FileManagement::UploadComplete'
          : 'FileManagement::UploadFailed';
        this._snackBar.open(message, 'OK', { duration: 3000 });
      },
      error: error => {
        console.error('Unable to init Uppy:\n', error);
      },
    });
  }

  handleOnCreate(): void {
    if (this.canCreate()) {
      this.folderCreateModal = true;
    }
  }

  canCreate(): boolean {
    if (!this.currentSelectedNode) return true;

    if (this.currentSelectedNode.isRoot) return true;

    if (this.currentSelectedNode.canWrite) return true;

    if (this.currentSelectedNode.name  == this.ROOT_FOLDER_NAME || this.currentSelectedNode.id == this.ROOT_FOLDER_ID) return true;

    return true;
  }

  canUpload(): boolean {
    if (!this.currentSelectedNode) return true;

    if (this.currentSelectedNode.isRoot) return true;

    if (this.currentSelectedNode.canWrite) return true;

    return false;
  }
}
