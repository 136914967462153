<div class="p-4">
    <h2 *ngIf="slaName">{{slaName}}</h2>
    <ngx-datatable class="material" [rows]="slas" [scrollbarH]="true" [footerHeight]="false"
    [headerHeight]="55" [rowHeight]="50" class="material flg-simple-table" default>

    <ngx-datatable-column name="Mission Milestones" [headerClass]="'flg-simple-table__header'"
        [cellClass]="'flg-simple-table__cell'" [resizeable]="true">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <span [ngClass]="{'bold-text': rowIndex === slas.length - 1}">{{ row.name }}</span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Duration" [headerClass]="'flg-simple-table__header'"
        [cellClass]="'flg-simple-table__cell'" [resizeable]="true">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <span [ngClass]="{'bold-text': rowIndex === slas.length - 1}">{{ row.days }} days</span>
        </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column name="Due Date" [headerClass]="'flg-simple-table__header'"
        [cellClass]="'flg-simple-table__cell'" [resizeable]="true">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <span [ngClass]="{'bold-text': rowIndex === slas.length - 1}">{{ row.dueDate | date:'MM/dd/yyyy' }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="fromMetrics" name="Milestone Achieved On" [headerClass]="'flg-simple-table__header'"
        [cellClass]="'flg-simple-table__cell'" [resizeable]="true">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <span *ngIf="row.achievementDate"  [ngClass]="{'bold-text': rowIndex === slas.length - 1}">{{ row.achievementDate | date:'MM/dd/yyyy' }}</span>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

</div>