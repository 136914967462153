import { Component, HostBinding, OnInit } from '@angular/core';
import { ParallelFileUploadService } from '../../services/parallel-file-upload.service';
import {
  QueuedFilePreviewData,
  QueuedFilePreviewStatus,
} from '../../models/parallel-upload/queued-file-preview-data';

@Component({
  selector: 'abp-file-upload-progress-viewer',
  templateUrl: './file-upload-progress-viewer.component.html',
  styleUrls: ['./file-upload-progress-viewer.component.scss'],
})
export class FileUploadProgressViewerComponent implements OnInit {
  constructor(public _parallelFileUploadService: ParallelFileUploadService) {}

  actualItems: QueuedFilePreviewData[] = [];
  actualIndex = 0;

  ngOnInit(): void {
    this._parallelFileUploadService.ShowProgressViewer.subscribe(
      isVisible => (this.visible = isVisible),
    );
  }

  @HostBinding('class.visible') visible: boolean = false;
  @HostBinding('class.expanded') expanded: boolean = true;

  toogleExpand() {
    this.expanded = !this.expanded;
  }

  hideProgressViewer() {
    this.visible = false;
  }

  getReadableStatus(value: number) {
    return QueuedFilePreviewStatus[value];
  }

  cancelUpload(file) {
    this._parallelFileUploadService.cancelUpload(file);
  }

  retryUpload(file) {
    this._parallelFileUploadService.retryUpload(file, true);
  }

  nextPage() {
    this._parallelFileUploadService.nextPage();
  }

  previousPage() {
    this._parallelFileUploadService.previousPage();
  }

  retryAll() {
    this._parallelFileUploadService.retryAll();
  }

  clearCompleted() {
    this._parallelFileUploadService.cleanFinishedFiles();
  }
}
