import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent {

    @Input()
    public imageUrl: string;

    @Input()
    public id: string;

    @Input()
    public pictureTitle: string;

    @Input()
    public customClass: string;

    @Input()
    public allowClick: boolean = false;

    constructor() { }
}