import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PagedResultDto } from '@abp/ng.core';
import {
  AirSpaceClassificationsDto,
  CountriesDto,
  GetAirSpaceClassificationInput,
  StatesDto,
} from '../../../../../../../core-service/src/lib/proxy/core-service/lookups';
import {
  AirSpaceClassificationsService,
  CountriesService,
  StatesService,
} from '../../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FlyguysMapComponent, FlyguysMapMarker } from '@flyguys/map';
import { FileDescriptorService } from '@volo/abp.ng.file-management/proxy';
import { LoadingOverlayService } from '../../../../services/loading/loading.service';
import { LocationForm as LocationDto } from '../models/location-form.model';
import { OrderFormSiteFileModel } from 'projects/flyguys/src/app/components/orders/model/order-form-site-file.model';
import { ToasterService } from '@abp/ng.theme.shared';
import { MessageSucessComponent } from 'projects/flyguys/src/app/components/common/message/message.success.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  CustomerLocationDto,
  GetCustomerLocationsInput,
  LocationWithAddressDto,
  MissionLocationInformationDto,
} from '../../../../../../../missions-service/src/lib/proxy/missions-service/basics';
import { LocationsService } from '../../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics';
import { CustomerLocationService } from '../../../../../../../customers-service/src/lib/proxy/customers-service/controllers/basics/customer-location.service';
import { Location, LocationForm, LocationSource } from '@flyguys/forms';
import { FlyguysLocationFormComponent } from '@flyguys/forms';
import { emptyGuidValidator } from 'projects/flyguys/src/app/utils/validation-empty-guid';

@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.scss'],
})
export class LocationModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FlyguysMapComponent) map: FlyguysMapComponent;
  @ViewChild(FlyguysLocationFormComponent) locationForm: FlyguysLocationFormComponent;
  formLocationModal: FormGroup;
  private subscriptions: Subscription[] = [];
  public mapMarkers: FlyguysMapMarker[] = [];
  kmlFiles: OrderFormSiteFileModel[] = [];

  dataAirspace: PagedResultDto<AirSpaceClassificationsDto> = {
    items: [],
    totalCount: 0,
  };

  countries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };

  states: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };

  maxFileSizeFiles: number = 200 * 1024 * 1024;

  hasKml: boolean = false;

  // We initiate this on true because we are receiving the locationId from the dialog caller
  isLocationSelectedFromAutocomplete: boolean = true;
  locationId: string = '';

  skipSubcription = false;
  emptyGuid: string = '00000000-0000-0000-0000-000000000000';

  constructor(
    public dialogRef: MatDialogRef<LocationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public readonly airspaceService: AirSpaceClassificationsService,
    public readonly countriesService: CountriesService,
    public readonly statesService: StatesService,
    public readonly fileDescriptorService: FileDescriptorService,
    public loadingService: LoadingOverlayService,
    private toaster: ToasterService,
    private cd: ChangeDetectorRef,
    public dialogService: MatDialog,
    public locationService: LocationsService,
    public customerLocationService: CustomerLocationService
  ) {
    this.countries = this.data.countries;
    this.states = this.data.states;

    this.formLocationModal = this.buildForm();

    this.kmlFiles = [];
    if (this.data.kmlFilePath) {
      const newKmlModel: OrderFormSiteFileModel = {
        fileId: this.data.kmlFileId,
        path: this.data.kmlFilePath,
        name: this.data.kmlFileName,
      };
      this.kmlFiles.push(newKmlModel);
      setTimeout(() => {
        this.map.loadKMLLayer(this.data.kmlFilePath);
        this.updateMapMarker();
        this.hasKml = true;
      }, 500);
    } else {
      this.hasKml = false;
      this.removeKmlFile();
    }
  }

  ngOnInit() {
    this.getAirSpaceClasifications();
    this.setupFormValueChanges();

    this.getLocationsWithAddress(this.data.customerId);
  }

  ngAfterViewInit() {
    // Set the initial value for the siteName form control after the view is initialized
    // This is done here and not in the constructor because otherwise it conflicts with mat-autocomplete
    if (this.data) {
      const hasSiteName = this.data.siteName?.length > 0;

      this.locationForm.setData(
        { ...this.data.location, name: this.data.location.address },
        hasSiteName ? LocationSource.SITE : LocationSource.INPUT
      );
      this.locationId = this.data.locationId;

      this.addMarker({
        lat: this.formLocationModal?.get('location.lat')?.value,
        lng: this.formLocationModal?.get('location.lng')?.value,
      });

      // In theory this should always be true, and the user should first select a different site name or create a new one first in order for the fields to be enabled.
      // But in the case where the siteName is empty, we will leave all fields enabled
      if (hasSiteName) {
        this.formLocationModal.get('siteName').setValue(this.data.siteName);
        this.formLocationModal
          .get('airspaceClassificationId')
          .setValue(this.data.airspaceClassificationId);

        this.disableAddressFields();

        this.formLocationModal.get('siteName').enable();
        this.formLocationModal.get('airspaceClassificationId').enable();
      }
    }
  }

  buildForm(): FormGroup {
    return new FormBuilder().group({
      siteName: new FormControl({ value: '', disabled: false }, [Validators.required]),
      location: new LocationForm(),
      airspaceClassificationId: new FormControl('', [Validators.required, emptyGuidValidator]),
    });
  }

  setupFormValueChanges() {
    // Listen for changes in latitude and update map marker
    this.formLocationModal.get('location.lat').valueChanges.subscribe(value => {
      this.updateMapMarker();
    });

    // Listen for changes in longitude and update map marker
    this.formLocationModal.get('location.lng').valueChanges.subscribe(value => {
      this.updateMapMarker();
    });
  }

  updateMapMarker() {
    const lat = this.formLocationModal.get('location.lat').value || 0;
    const lng = this.formLocationModal.get('location.lng').value || 0;

    this.map.addMarker({ id: 1, lat, lng });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getAirSpaceClasifications() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetAirSpaceClassificationInput;
    const subsairspace = this.airspaceService.getList(request).subscribe(
      (data: PagedResultDto<AirSpaceClassificationsDto>) => {
        if (data?.totalCount !== 0) {
          this.dataAirspace = data;
        }
      },
      error => {
        console.log('Error on getting Air Space classifications: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.push(subsairspace);
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  saveUpdatedLocation(): void {
    const locationForm: LocationDto = {
      missionId: this.data.missionId,
      locationId: this.locationId,
      siteName: this.formLocationModal.get('siteName').value,
      city: this.formLocationModal.get('location.city').value,
      stateId: this.formLocationModal.get('location.stateId').value,
      countryId: this.formLocationModal.get('location.countryId').value,
      address: this.formLocationModal.get('location.address').value,
      zipCode: this.formLocationModal.get('location.zip').value,
      longitude: this.formLocationModal.get('location.lng').value,
      latitude: this.formLocationModal.get('location.lat').value,
      airspaceClassificationId: this.formLocationModal.get('airspaceClassificationId').value,
    };

    // Find the corresponding country and state names
    const selectedCountryId = this.formLocationModal.get('location.countryId').value;
    const selectedStateId = this.formLocationModal.get('location.stateId').value;
    const selectedAirSpaceId = this.formLocationModal.get('airspaceClassificationId').value;

    const selectedCountry = this.countries.items.find(country => country.id === selectedCountryId);
    const selectedState = this.states.items.find(state => state.id === selectedStateId);
    const selectedAirSpace = this.dataAirspace.items.find(
      airspace => airspace.id === selectedAirSpaceId
    );

    if (selectedCountry) {
      locationForm.country = selectedCountry.description;
    }

    if (selectedState) {
      locationForm.state = selectedState.description;
    }

    if (selectedAirSpace) {
      locationForm.airspaceClassification = selectedAirSpace.description;
    }

    if (this.kmlFiles?.length <= 0) {
      locationForm.kmlFileId = null;
      locationForm.kmlFilePath = '';
    } else {
      locationForm.kmlFileId = this.kmlFiles[0].fileId;
      locationForm.kmlFilePath = this.kmlFiles[0].path;
    }

    if (!this.isLocationSelectedFromAutocomplete) {
      locationForm.locationId = '';
    }

    this.dialogRef.close(locationForm);
  }

  handleMarkerClick(marker: FlyguysMapMarker): void {
    this.formLocationModal.patchValue({
      latitude: marker.lat.toString(),
      longitude: marker.lng.toString(),
    });
  }

  onkmlSelected(files: File[]) {
    if (files[0].size > this.maxFileSizeFiles) {
      this.dialogService.open(MessageSucessComponent, {
        data: {
          title: 'Maximum File Size exceeded',
          message: `File ${files[0].name} exceeds the maximum allowed size (${this.formatFileSize(
            this.maxFileSizeFiles
          )}).`,
        },
        disableClose: true,
        width: '475px',
      });
      const indexFile = files.indexOf(files[0]);
      if (indexFile >= 0) {
        files.splice(indexFile, 1);
      }
      this.hasKml = false;
    } else {
      this.uploadKmlFile(files[0]);
    }
  }

  private formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }

  onkmlRemoved(file: File) {
    this.removeKmlFile();
  }

  uploadKmlFile(file: any): void {
    if (!file) {
      console.error('Error Uploading file.No file selected');
      return;
    }

    this.loadingService.showOverlay();
    const formData = new FormData();
    formData.append('fileList', file);
    const request = this.fileDescriptorService.uploadAttachMentsFolder(
      'Order_Missions',
      'Mission_Files_' + this.data.missionId.toLowerCase(),
      formData
    );

    request.subscribe({
      next: res => {
        if (res.length <= 0) return;

        this.kmlFiles = [];
        const newKmlModel: OrderFormSiteFileModel = {
          fileId: res[0].id,
          path: res[0].fileAttachmentUrl,
          name: res[0].name,
        };
        setTimeout(() => {
          this.loadingService.hideOverlay();
          this.kmlFiles.push(newKmlModel);
          this.renderKML(res[0].fileAttachmentUrl.trim());
          this.map.centerMap();
          this.cd.detectChanges();
        }, 500);
      },
      error: error => {
        this.loadingService.hideOverlay();
        console.error('Error Uploading file', error);
      },
    });
  }

  removeKmlFile(): void {
    setTimeout(() => {
      this.map.removeKMLLayer();
      if (!this.data.locAddress) {
        this.data.latitude = '4.626137636370072';
        this.data.longitude = '-74.06811384764599';
      }
      this.hasKml = false;
      this.map.kmlLayerToggle;
      this.updateMapMarker();
      this.kmlFiles = [];
      this.cd.detectChanges();
    }, 500);
  }

  renderKML(path: string) {
    setTimeout(() => {
      this.map.loadKMLLayer(path);
      this.updateMapMarker();
      this.hasKml = true;
      this.map.kmlLayerToggle;
      this.cd.detectChanges();
    }, 500);
  }

  IsValid(): boolean {
    return this.formLocationModal.valid;
  }

  protected readonly parseFloat = parseFloat;

  /**
   * Adds a marker
   * @param Location
   * @returns void
   */
  private addMarker(location: Location): void {
    const siteMarker: FlyguysMapMarker = {
      id: 1,
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng),
    };

    this.map.clearMarkers();
    this.map.addMarker(siteMarker);
  }

  // Site map related logic
  locations: LocationWithAddressDto[] = [];
  filteredLocations: LocationWithAddressDto[] = [];
  selectedLocation: LocationWithAddressDto;

  onLocationSelected(event: MatAutocompleteSelectedEvent) {
    this.skipSubcription = true;
    const selectedLocation: LocationWithAddressDto = event.option.value;

    this.locationForm.setData(
      {
        name: selectedLocation.streetAddress,
        lat: selectedLocation.latitude,
        lng: selectedLocation.longitude,
        zip: selectedLocation.zipCode,
        city: selectedLocation.city,
        stateId: selectedLocation.stateId,
        countryId: selectedLocation.countryId,
      },
      LocationSource.SITE
    );

    this.locationId = selectedLocation.locationId;
    this.formLocationModal.get('siteName').patchValue(selectedLocation.siteName);

    this.map.options = {
      center: { lat: Number(selectedLocation.latitude), lng: Number(selectedLocation.longitude) },
    };
    this.map.centerMap();

    this.isLocationSelectedFromAutocomplete = true;

    this.disableAddressFields();
  }

  private getLocationsWithAddress(customerId: string) {
    const getCustomerLocationsInput: GetCustomerLocationsInput = {
      customerId: customerId,
      maxResultCount: 1000,
    };
    this.customerLocationService.getList(getCustomerLocationsInput).subscribe({
      next: (response: PagedResultDto<CustomerLocationDto>) => {
        const customerLocations: string[] = response.items.map(item => item.locationId);

        this.locationService.getLocationsWithAddress(customerLocations, customerId).subscribe({
          next: data => {
            this.locations = data;
            this.createValueChangeSubscription();
          },
          error: err => console.error('Error fetching locations:', err),
        });
      },
      error: err => console.log(err),
    });
  }

  private createValueChangeSubscription() {
    this.filteredLocations = this.locations;

    this.formLocationModal.get('siteName').valueChanges.subscribe(val => {
      if (typeof val === 'string') {
        if (this.skipSubcription) {
          this.skipSubcription = false;
          return;
        }

        this.isLocationSelectedFromAutocomplete = false;

        this.filteredLocations = this.locations.filter(
          location =>
            location.siteName.toLowerCase().includes(val.toLowerCase()) ||
            location.streetAddress.toLowerCase().includes(val.toLowerCase())
        );

        if (this.filteredLocations.length === 0 || val.trim() === '') {
          this.locationForm.clear();
          this.filteredLocations = [];
        }

        // Clear the locationId if the typed site name does not match any location
        if (
          !this.locations.some(
            location => location.siteName.toLowerCase().trim() === val.toLowerCase().trim()
          )
        ) {
          this.locationId = '';
        }
      } else {
        this.selectedLocation = val;
      }
    });
  }

  /**
   * Handles a change in the location
   * @param location Location | null
   */
  handleLocationChange(location: Location | null) {
    if (location) {
      this.updateMapMarker();
      this.addMarker(location);
    } else {
      this.locationId = '';

      this.map.clearMarkers();
      this.map.centerMap();
    }

    this.cd.detectChanges();
  }

  disableAddressFields() {
    this.formLocationModal.get('location.city').disable();
    this.formLocationModal.get('location.stateId').disable();
    this.formLocationModal.get('location.countryId').disable();
    this.formLocationModal.get('location.address').disable();
    this.formLocationModal.get('location.zip').disable();
    this.formLocationModal.get('location.lng').disable();
    this.formLocationModal.get('location.lat').disable();
    this.formLocationModal.get('location.origin').disable();
  }
}
