import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'pdf-preview-modal',
  templateUrl: './pdf-preview-modal.component.html',
})
export class PdfPreviewModalComponent extends BaseModalComponent implements OnInit {

  @Input() 
  fileUrl: SafeResourceUrl;

  @Input() 
  showSaveButton: boolean;

  @Input() 
  pdfId: string;

  @Input() 
  fileName: string;
  

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  public handleOnPictureClicked()
  {
    let element = document.getElementById(this.pdfId);

    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
  }
}
