import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import {
  CustomerLocationCreateDto,
  CustomerLocationDto,
  CustomerLocationUpdateDto,
  GetCustomerLocationsInput,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';

@Injectable({
  providedIn: 'root',
})
export class CustomerLocationService {
  apiName = 'customersService';

  create = (input: CustomerLocationCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerLocationDto>(
      {
        method: 'POST',
        url: '/api/customers-service/customer-locations',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/customers-service/customer-locations/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerLocationDto>(
      {
        method: 'GET',
        url: `/api/customers-service/customer-locations/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetCustomerLocationsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerLocationDto>>(
      {
        method: 'GET',
        url: '/api/customers-service/customer-locations',
        params: {
          filterText: input.filterText,
          customerId: input.customerId,
          locationId: input.locationId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: CustomerLocationUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerLocationDto>(
      {
        method: 'PUT',
        url: `/api/customers-service/customer-locations/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
