<h4 mat-dialog-title class="flg-modal flg-modal__title">New Site</h4>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container">
    <div class="row justify-content-center">
      <app-order-site
        *ngIf="site"
        [site]="site"
        #orderMission
        [hideHeader]="true"
        [customerId]="customerId"
        (removesite)="handleRemoveSite(site)"
        [contactsUpdated]="contactsUpdated"
        (contactsUpdatedChange)="onContactsUpdatedChange()"
        [slaId]="priorityId"
      ></app-order-site>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      {{ 'missionsService::Cancel' | abpLocalization }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      [disabled]="!isSiteValid()"
      (click)="saveAddCustomer()"
    >
      {{ 'missionsService::Save' | abpLocalization }}
    </button>
  </div>
</mat-dialog-actions>
