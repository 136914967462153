import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eCoreServiceRouteNames } from '../enums/route-names';

export const LOOKUPS_COUNTRIES_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/core-service/country',
        parentName: eCoreServiceRouteNames.CoreService,
        name: 'CoreService::Menu:Country',
        layout: eLayoutType.application,
        requiredPolicy: 'CoreService.Country.ShowMenu',
        iconClass: 'fas fa-globe-americas',
      },
    ]);
  };
}
