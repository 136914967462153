import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { StatusMissionOrderCreateDto, StatusMissionOrderDto, StatusMissionOrderExcelDownloadDto, StatusMissionOrderUpdateDto, GetStatusMissionOrderInput } from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class StatusMissionOrderService {
  apiName = 'missionsService';

  create = (input: StatusMissionOrderCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, StatusMissionOrderDto>({
      method: 'POST',
      url: '/api/missions-service/status-mission-orders',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/status-mission-orders/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, StatusMissionOrderDto>({
      method: 'GET',
      url: `/api/missions-service/status-mission-orders/${id}`,
    },
    { apiName: this.apiName,...config });
  
  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/status-mission-orders/download-token',
    },
    { apiName: this.apiName,...config });

  getListAsExcelFile = (input: StatusMissionOrderExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/status-mission-orders/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, statusId: input.statusId, statusDescription: input.statusDescription, statusEnum: input.statusEnum, code: input.code, order: input.order, state: input.state },
    },
    { apiName: this.apiName,...config });


  getList = (input: GetStatusMissionOrderInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<StatusMissionOrderDto>>({
      method: 'GET',
      url: '/api/missions-service/status-mission-orders',
      params: { filterText: input.filterText, statusId: input.statusId, statusDescription: input.statusDescription, statusEnum: input.statusEnum, code: input.code, order: input.order, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: StatusMissionOrderUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, StatusMissionOrderDto>({
      method: 'PUT',
      url: `/api/missions-service/status-mission-orders/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
