<div class="d-flex justify-content-between mb-2">
  <h3>{{ 'pilotsService::Badges' | abpLocalization }}</h3>
  <button mat-flat-button class="common-button" type="button" (click)="openAssignModal()">
    <mat-icon aria-hidden="false" fontIcon="add"></mat-icon>
    {{ 'pilotsService::AssignBadge' | abpLocalization }}
  </button>
</div>

<div class="badge-options">
  <mat-spinner *ngIf="loading" [diameter]="25"></mat-spinner>
  <div *ngIf="!loading">
    <div *ngIf="displayedBadges.length > 0" class="badge-list">
      <app-grid-badge
        *ngFor="let badge of displayedBadges"
        [badge]="badge"
        [removable]="false"
      ></app-grid-badge>
    </div>
    <div *ngIf="displayedBadges.length === 0">
      <h4>{{ 'pilotsService::NoBadgesAssignedMessage' | abpLocalization }}</h4>
    </div>
  </div>
</div>
