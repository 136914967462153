import { recurrenceEnum } from '../../../../proxy/missions-service/shared/recurrency.enum';
import { CaptureMustBeEnum } from '../../../../proxy/missions-service/shared/capture-mustbe.enum';

export class CopyMissionForm {
  missionId: string = '';
  jobId?: string = '';
  missionName: string = '';
  captureDate?: string = '';
  captureTime?: string = '';
  repeats?: recurrenceEnum = recurrenceEnum.NoRepeat;
  captureDateMust?: CaptureMustBeEnum = CaptureMustBeEnum.OnSelectedDate;
  client?: boolean = false;
  additionalContacts?: boolean = false;
  orderRequest?: boolean = false;
  orderPriority?: boolean = false;
  assetOrderNumber?: boolean = false;
  missionSummary?: boolean = false;
  siteInformation?: boolean = false;
  deliverableDueDate?: boolean = false;
  productsPackages?: boolean = false;
}
