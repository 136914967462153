<h5 mat-dialog-title class="flg-modal flg-modal__title">Add Deliverable</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container p-0">
    <div class="row">
      <form [formGroup]="formNewDelirable">
        <mat-form-field class="w-100">
          <mat-label>Deliverable*</mat-label>
          <mat-select
            name="deliverableId"
            [(ngModel)]="this.newdeliverable.deliverableId"
            (ngModelChange)="
              setDescriptionFromId(
                this.newdeliverable.deliverableId,
                dataDeliverable.items,
                'deliverableName',
                'name'
              )
            "
            formControlName="deliverableId"
          >
            <mat-option
              *ngFor="let data of dataDeliverable.items"
              [value]="data.id"
              title="{{ data.name }}"
            >
              {{ data.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="this.deliveryAttributes.length > 0">
          <div class="d-flex justify-content-between">
            <ng-container
              *ngFor="let field of deliveryAttributes; let i = index; let isLast = last"
            >
              <div *ngIf="(i + 1) % 3 === 0 || i + 1 === deliveryAttributes.length">
                <div class="d-flex w-100"></div>
              </div>
              <div class="d-flex flex-grow-1" [class.pe-2]="!isLast">
                <mat-form-field class="w-100">
                  <mat-label>{{ field.placeholder }}*</mat-label>
                  <input
                    matInput
                    [placeholder]="field.placeholder"
                    [(ngModel)]="field.stringValue"
                    pattern="[0-9]*"
                    [formControlName]="field.id"
                    digitOnly
                  />
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end mt-3">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="saveDeliverable()"
      [disabled]="formNewDelirable.invalid"
    >
      Add
    </button>
  </div>
</mat-dialog-actions>
