import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'image-viewer',
  templateUrl: './image-viewer.component.html'
})
export class ImageViewerComponent {

    @Input()
    public allowReproduce = false;

    @Input()
    public imageUrl: string;

    @Input()
    public isThumbnail = false;

    @Input()
    public id: string;

    @Input()
    public pictureTitle: string;

    @Input()
    public customClass: string;

    @Input()
    public allowClick: boolean = false;

    @Output() 
    public onPictureClicked = new EventEmitter<void>();

    constructor() { }

    public handleClick() {

        this.onPictureClicked.emit();
    }
}