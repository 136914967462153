<ngx-spinner
  bdColor="rgba(245,245,245,0.7)"
  size="default"
  color="#bc5a2e"
  type="ball-square-clockwise-spin"
  [fullScreen]="true"
  [zIndex]="111"
>
  <p style="color: #bc5a2e">{{ 'missionsService::LoadingSourcePilots' | abpLocalization }}</p>
</ngx-spinner>
<div *ngIf="loading">
  <h5 mat-dialog-title class="modalTitle">Mission Information</h5>

  <mat-dialog-content>
    <div class="modalBody" modalBody>
      <div class="row justify-content-center">
        <p><b>Job Id:</b> {{ data.jobId || 'N/A' }}</p>
        <p><b>Mission Name:</b> {{ data.missionName || 'N/A' }}</p>
        <p><b>Mission Status:</b> {{ data.missionStatus || 'N/A' }}</p>
        <p><b>Customer Name:</b> {{ data.customerName || 'N/A' }}</p>
        <p><b>Address:</b> {{ data.missionAddress || 'N/A' }}</p>
        <p *ngIf="data.missionLat"><b>Latitude:</b> {{ data.missionLat }}</p>
        <p *ngIf="data.missionLng"><b>Longitude:</b> {{ data.missionLng }}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        cdkFocusInitial
        matDialogClose
      >
        Ok
      </button>
    </div>
  </mat-dialog-actions>
</div>
