<abp-page [title]="'missionsService::Order' | abpLocalization">
  <abp-page-toolbar-container class="col">
    <div class="text-lg-end pt-2">
      <app-column-displayer [columnsStatus]="columnsStatus()" [toggler]="toggler" [showColumnPermission]="'missionsService.Order.ShowColumn && General.Common.ShowColumn'"></app-column-displayer>

      <button
        class="btn btn-primary btn-sm ms-2"
        type="button"
        [disabled]="isExportToExcelBusy"
        (click)="exportToExcel()"
        *abpPermission="'missionsService.Order.Export && General.Common.Export'"
      >
        {{ 'missionsService::ExportToExcel' | abpLocalization }}
      </button>
      <button
        *abpPermission="'missionsService.Order.Create && General.Common.Create'"
        class="btn btn-primary btn-sm ms-2"
        type="button"
        (click)="create()"
      >
        <i class="fa fa-plus me-1" aria-hidden="true"></i>
        {{ 'missionsService::NewOrders' | abpLocalization }}
      </button>
    </div>
  </abp-page-toolbar-container>

  <abp-advanced-entity-filters localizationSourceName="missionsService" [list]="list">
    <abp-advanced-entity-filters-form *abpPermission="'missionsService.Order.Filters && General.Common.Filters'">
      <form #filterForm (keyup.enter)="list.get()">
        <div class="row">
          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Order.Filters.Portfolio'">
            <div class="mb-3">
              <label class="form-label" for="portfolioIdFilter">
                {{ 'missionsService::PortfolioId' | abpLocalization }}
              </label>

              <select
                class="form-control"
                id="portfolioIdFilter"
                [(ngModel)]="filters.portfolioId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]=""></option>
                <option [ngValue]="portafolio.id" *ngFor="let portafolio of portafolios.items">
                  {{ portafolio.name | abpLocalization }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Order.Filters.RequestingCustomer'">
            <div class="mb-3">
              <label class="form-label" for="requestingCustomerIdFilter">
                {{ 'missionsService::RequestingCustomerId' | abpLocalization }}
              </label>

              <select
                class="form-control"
                id="requestingCustomerIdFilter"
                [(ngModel)]="filters.requestingCustomerId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]=""></option>
                <option [ngValue]="customer.id" *ngFor="let customer of customers.items">
                  {{ customer.name | abpLocalization }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Order.Filters.BillingCustomer'">
            <div class="mb-3">
              <label class="form-label" for="billingCustomerIdFilter">
                {{ 'missionsService::BillingCustomerId' | abpLocalization }}
              </label>

              <select
                class="form-control"
                id="billingCustomerIdFilter"
                [(ngModel)]="filters.billingCustomerId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]=""></option>
                <option [ngValue]="customer.id" *ngFor="let customer of customers.items">
                  {{ customer.name | abpLocalization }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Order.Filters.EndCustomer'">
            <div class="mb-3">
              <label class="form-label" for="endCustomerIdFilter">
                {{ 'missionsService::EndCustomerId' | abpLocalization }}
              </label>

              <select
                class="form-control"
                id="endCustomerIdFilter"
                [(ngModel)]="filters.endCustomerId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]=""></option>
                <option [ngValue]="customer.id" *ngFor="let customer of customers.items">
                  {{ customer.name | abpLocalization }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Order.Filters.TotalPrice'">
            <div class="mb-3">
              <label class="form-label" for="minTotalPrice">
                {{ 'missionsService::MinTotalPrice' | abpLocalization }}
              </label>

              <input
                id="minTotalPrice"
                type="number"
                class="form-control"
                [(ngModel)]="filters.totalPriceMin"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>

          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Order.Filters.TotalPrice'">
            <div class="mb-3">
              <label class="form-label" for="maxTotalPrice">
                {{ 'missionsService::MaxTotalPrice' | abpLocalization }}
              </label>

              <input
                id="maxTotalPrice"
                type="number"
                class="form-control"
                [(ngModel)]="filters.totalPriceMax"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Order.Filters.Industry'">
            <div class="mb-3">
              <label class="form-label" for="industryIdFilter">
                {{ 'missionsService::IndustryId' | abpLocalization }}
              </label>

              <select
                class="form-control"
                id="industryIdFilter"
                [(ngModel)]="filters.industryId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]=""></option>
                <option [ngValue]="industry.id" *ngFor="let industry of industries.items">
                  {{ industry.description | abpLocalization }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-auto" *abpPermission="'missionsService.Order.Filters.State && General.Common.Filters.State'">
            <div class="mb-3">
              <label class="form-label" for="state">
                {{ 'missionsService::State' | abpLocalization }}
              </label>

              <select
                id="state"
                class="form-select form-control"
                [(ngModel)]="filters.state"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]=""></option>
                <option [ngValue]="state.value" *ngFor="let state of states">
                  {{ state.key | abpLocalization }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-sm-auto align-self-end mb-3">
            <div class="row">
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                  <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                </button>
              </div>
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-primary" (click)="list.get()">
                  <span>{{ 'Flyguys::ApplyFilter' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </abp-advanced-entity-filters-form>
  </abp-advanced-entity-filters>

  <div class="card">
    <div class="card-body">
      <ngx-datatable class="flg-grid-table" [rowHeight]="45" [headerHeight]="50" [footerHeight]="55" [rows]="data.items" [count]="data.totalCount" [list]="list" default [externalPaging]="true" [offset]="pageNumber" [limit]="clientMaxResultCount" #dataTable>
        <ngx-datatable-column
          name="{{ 'missionsService::PortfolioId' | abpLocalization }}"
          prop="portfolioId"
          *ngIf="showedColumns.portfolio"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ portafolioDict[row.portfolioId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::RequestingCustomerId' | abpLocalization }}"
          prop="requestingCustomerId"
          *ngIf="showedColumns.requestingCustomer"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ customerDict[row.requestingCustomerId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::BillingCustomerId' | abpLocalization }}"
          prop="billingCustomerId"
          *ngIf="showedColumns.billingCustomer"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ customerDict[row.billingCustomerId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::EndCustomerId' | abpLocalization }}"
          prop="endCustomerId"
          *ngIf="showedColumns.endCustomer"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ customerDict[row.endCustomerId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::TotalPrice' | abpLocalization }}"
          prop="totalPrice"
          *ngIf="showedColumns.totalPrice"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.totalPrice }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::IndustryId' | abpLocalization }}"
          prop="industryId"
          *ngIf="showedColumns.industry"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.industryDescription }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'missionsService::State' | abpLocalization }}" prop="state" *ngIf="showedColumns.state">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <app-column-stylizer [columnValue]="row.state" [typeColumn]="'text'" [columnRules]="stateRules"></app-column-stylizer>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="'AbpUi::Actions' | abpLocalization"
          [maxWidth]="150"
          [width]="150"
          [sortable]="false"
          *ngIf="showedColumns.actions"
        >
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <app-column-actions [columnActions]="columnActions(row)"></app-column-actions>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage">
            <app-table-paginator [(maxResultCount)]="list.maxResultCount" [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount" [pageSize]="pageSize" [(pageNumber)]="pageNumber" [curPage]="curPage" [dataTable]="dataTable"
              style="width: 100%;">
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</abp-page>

<abp-modal [busy]="isModalBusy" [options]="{ size: 'lg' }" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>
      {{ (selected ? 'AbpUi::Edit' : 'missionsService::NewOrders') | abpLocalization }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="form" id="orders" (ngSubmit)="submitForm()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <mat-label class="form-label" for="orders-portfolio-id">
            {{ 'missionsService::PortfolioId' | abpLocalization }} *
          </mat-label>

          <mat-select class="form-control" id="orders-portfolio-id" formControlName="portfolioId">
            <input placeholder="Search" matInput type="text" (keyup)="updatePortafolio($event.target.value)">
            <mat-option [value]="portafolio.id" *ngFor="let portafolio of portafolios.items">
              {{ portafolio.name | abpLocalization }}
            </mat-option>
          </mat-select>
        </div>
        <div class="mb-3">
          <label class="form-label" for="orders-requesting-customer-id">
            {{ 'missionsService::RequestingCustomerId' | abpLocalization }} *
          </label>

          <select
            class="form-control"
            id="orders-requesting-customer-id"
            formControlName="requestingCustomerId"
          >
            <option [ngValue]="customer.id" *ngFor="let customer of customers.items">
              {{ customer.name | abpLocalization }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" for="orders-billing-customer-id">
            {{ 'missionsService::BillingCustomerId' | abpLocalization }} *
          </label>

          <select
            class="form-control"
            id="orders-billing-customer-id"
            formControlName="billingCustomerId"
          >
            <option [ngValue]="customer.id" *ngFor="let customer of customers.items">
              {{ customer.name | abpLocalization }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" for="orders-end-customer-id">
            {{ 'missionsService::EndCustomerId' | abpLocalization }} *
          </label>

          <select class="form-control" id="orders-end-customer-id" formControlName="endCustomerId">
            <option [ngValue]="customer.id" *ngFor="let customer of customers.items">
              {{ customer.name | abpLocalization }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" for="orders-total-price">
            {{ 'missionsService::TotalPrice' | abpLocalization }} *
          </label>

          <input
            type="number"
            id="orders-total-price"
            formControlName="totalPrice"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="orders-industry-id">
            {{ 'missionsService::IndustryId' | abpLocalization }} *
          </label>

          <select class="form-control" id="orders-industry-id" formControlName="industryId">
            <option [ngValue]="industry.id" *ngFor="let industry of industries.items">
              {{ industry.description | abpLocalization }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" for="orders-state">
            {{ 'missionsService::State' | abpLocalization }} *
          </label>

          <select class="form-control" id="orders-state" formControlName="state">
            <option [ngValue]="state.value" *ngFor="let state of states">
              {{ state.key | abpLocalization }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="orders">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
