import { ABP, ConfigStateService, downloadBlob, ListService, PagedResultDto, PermissionService, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { ColDef } from 'ag-grid-community';
import { CustomersDto } from 'projects/customers-service/src/lib/proxy/customers-service/basics/models';
import {
  GetProjectInput,
  MissionsDto,
  PilotSourcingMissionsDto,
  PortafoliosDto,
  ProjectsDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  MissionsService,
  PortafoliosService,
  ProjectsService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { GridComponent } from '../../../components/grid/grid.component';
import { PrioritiesDto, StatesDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { Router } from '@angular/router';
import { PilotSourcingProjectDto } from '../../../../../../missions-service/src/lib/proxy/missions-service/basics/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pilot-sourcing-projects',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './pilot-sourcing-projects.component.html',
  styles: [],
})
export class PilotSourcingProjectsComponent implements OnInit {
  data: PagedResultDto<ProjectsDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetProjectInput;

  form: FormGroup;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: ProjectsDto;

  public columnDefs: ColDef[] = [
    { field: 'projectId' },
    { field: 'name' },
    { field: 'customer' },
    { field: 'location' },
    { field: 'recurence' },
    { field: 'percentagecomplete', headerName: '% complete' },
    { field: 'portfolio' },
    { field: 'priority' },
  ];

  public rowData: any[] = [];
  private subscriptions: Subscription[] = [];

  @ViewChild(GridComponent) private gridComponent!: GridComponent;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: MissionsService,
    public readonly projectService: ProjectsService,
    public readonly portFolioService: PortafoliosService,
    private stateService: ConfigStateService,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {

    this.filters.assignedToAnyone = false;

    let showAssignedToAnyone= this.permissionService.getGrantedPolicy('missionsService.Project.AssignedToAnyone');

    if (showAssignedToAnyone)
    {
      this.filters.assignedToAnyone = showAssignedToAnyone;
    }

    this.filters.assignUserId = this.stateService.getDeep('currentUser.id');

    const getData = (query: ABP.PageQueryParams) =>
      this.projectService.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<ProjectsDto>) => (this.data = list);

    this.rowData = [];

    const subsPilotsouringProjects = this.list.hookToQuery(getData).subscribe({
      next: async (data: PagedResultDto<ProjectsDto>) => {
        this.rowData = [];
        for (const item of data.items) {
          const dataItem: PilotSourcingProjectDto = {
            projectId: item.id,
            name: item.name,
            customer: 'test customer',
            location: 'Austin Texas',
            recurence: item.recurence.toString(),
            percentagecomplete: '20%',
            portfolio: await this.getPortFolio(item.id),
            priority: 'HIGH',
          };
          this.rowData.push(dataItem);
        }
        this.gridComponent.updateGridData(this.rowData);
      },
      error: (error: any) => {
        console.error('Error fetching data:', error);
      },
    });
    this.subscriptions.push(subsPilotsouringProjects);
  }

  async getPortFolio(projectId: string): Promise<string> {
    try {
      // Call the get method and use async/await to get the data synchronously
      const dataProj: ProjectsDto = await this.projectService.get(projectId).toPromise();
      const dataPortfolio: PortafoliosDto = await this.portFolioService
        .get(dataProj.portafolioId)
        .toPromise();
      return dataPortfolio.name;
    } catch (error) {
      console.error('Error fetching potfolio data:', error);
      throw error; // Rethrow the error to handle it in the calling component if needed
    }
  }

  clearFilters() {
    // this.filters = {} as GetMissionInput;
  }

  exportToExcel() {
    this.isExportToExcelBusy = true;
    this.service
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter })
        ),
        finalize(() => (this.isExportToExcelBusy = false))
      )
      .subscribe(result => {
        downloadBlob(result, 'Missions.xlsx');
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
