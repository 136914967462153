import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Badge } from '../../../components/common/badge/display/grid-badge/grid-badge.component';
import { BulkAssignBadgeModalData } from '../assign-badge-modal/models/bulk-assign-badge-modal-data';
import { PilotBadgeDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/relationals';

@Component({
  selector: 'app-bulk-revoke-badge-modal',
  templateUrl: './bulk-revoke-badge-modal.component.html',
  styleUrls: ['./bulk-revoke-badge-modal.component.scss'],
})
export class BulkRevokeBadgeModalComponent implements OnInit {
  modalData: BulkAssignBadgeModalData;
  form: FormGroup;
  loading = false;
  displayedBadges: Badge[] = [];
  removedBadgeIds: string[] = [];
  allowToSave: boolean;

  constructor(
    public dialogRef: MatDialogRef<BulkRevokeBadgeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkAssignBadgeModalData,
    private formBuilder: FormBuilder
  ) {
    this.modalData = data;
    this.form = this.formBuilder.group({
      searchInput: [''],
    });
  }

  ngOnInit() {

    this.loadBadgesInCommon();

    this.allowToSave = this.displayedBadges.length > 0;
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  onClickSave(): void {
    if (this.removedBadgeIds.length > 0) {
      this.dialogRef.close(this.removedBadgeIds);
    } else {
      this.dialogRef.close();
    }
  }

  removeBadge(badgeId: string): void {
    this.removedBadgeIds.push(badgeId);
    this.displayedBadges = this.displayedBadges.filter(badge => badge.id !== badgeId);
  }

  private loadBadgesInCommon(): void {

    this.displayedBadges = [];
    
    let distinctBadges = Array.from(new Set(this.modalData.currentBadges.map((pilotBadge: PilotBadgeDto) => pilotBadge.badgeId)));

    if (!distinctBadges || distinctBadges.length == 0) {
      this.displayedBadges = [];
      return;
    }

    for (let badgeId of distinctBadges) {

      let pilotsInCommon = this.modalData.currentBadges.filter(x => x.badgeId == badgeId).length;

      if (pilotsInCommon != this.modalData.selectedPilots.length)
        continue;

      this.displayedBadges.push({
        text: this.modalData.currentBadges.find(x => x.badgeId == badgeId).descriptionBadge,
        color: '#0252ED',
        background: '#0252ED1A',
        id: badgeId,
      });
    }
  }
}
