import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eCoreServiceRouteNames } from '../enums/route-names';

export const LOOKUPS_CAPTURE_TYPES_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/core-service/capture-type',
        parentName: eCoreServiceRouteNames.CoreService,
        name: 'CoreService::Menu:CaptureType',
        layout: eLayoutType.application,
        requiredPolicy: 'CoreService.CaptureType.ShowMenu',
        iconClass: 'fas fa-images',
      },
    ]);
  };
}
