<h4 mat-dialog-title class="flg-modal flg-modal__title">New Customer</h4>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container">
    <div class="row justify-content-center">
      <form [formGroup]="form" class="p-0" skipValidation>
        <mat-radio-group aria-label="Select Customer Type" formControlName="customerType">
          <mat-radio-button class="pe-2" [value]="CustomerType.Personal">
            <span class="checkbox-label">Individual</span>
          </mat-radio-button>
          <mat-radio-button class="pe-2" [value]="CustomerType.Company">
            <span class="checkbox-label">Company</span>
          </mat-radio-button>
        </mat-radio-group>

        <div
          formGroupName="personal"
          class="mt-4"
          *ngIf="form.get('customerType').value === CustomerType.Personal"
        >
          <div class="row">
            <!-- Customer Level -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>Customer Level</mat-label>
                <mat-select id="customers-level-id" formControlName="customerLevelId">
                  <mat-option [value]="level.id" *ngFor="let level of levels.items">
                    {{ level.description | abpLocalization }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Industry -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>Customer Vertical</mat-label>
                <mat-select
                  id="customers-customer-vertical-id"
                  formControlName="customerVerticalId"
                >
                  <mat-option [value]="vertical.id" *ngFor="let vertical of customerVerticals">
                    {{ vertical.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- First Name -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName" placeholder="First Name" />
                <mat-error *ngIf="form.get('personal').get('firstName').hasError('required')">
                  Please enter a First Name
                </mat-error>
                <mat-error *ngIf="form.get('personal').get('firstName').hasError('nameExists')">
                  This first name and last name combination already exists
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Last Name -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" placeholder="Last Name" />
                <mat-error *ngIf="form.get('personal').get('lastName').hasError('required')">
                  Please enter a Last Name
                </mat-error>
                <mat-error *ngIf="form.get('personal').get('lastName').hasError('nameExists')">
                  This first name and last name combination already exists
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Email -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" type="email" placeholder="Email" />
                <mat-error *ngIf="form.get('personal').get('email').hasError('required')">
                  Please enter a valid Email
                </mat-error>
                <mat-error *ngIf="form.get('personal').get('email').hasError('emailExists')">
                  This email already exists
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Primary Phone Number -->
            <div class="col-6">
              <flg-phone-input
                [widthClass]="'w-100'"
                [control]="form.get('personal.primaryPhoneNumber')"
                [label]="'Primary Phone Number'"
              ></flg-phone-input>
            </div>

            <!-- Id Hubspot -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>{{ 'customersService::IdHubspot' | abpLocalization }}</mat-label>
                <input
                  matInput
                  formControlName="idHubspot"
                  [placeholder]="'customersService::IdHubspot' | abpLocalization"
                />
                <mat-error *ngIf="form.get('personal').get('idHubspot').hasError('maxlength')">
                  Must be less than to 100 characters
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div
          formGroupName="company"
          class="mt-4"
          *ngIf="form.get('customerType').value === CustomerType.Company"
        >
          <div class="row">
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>Customer Level</mat-label>
                <mat-select formControlName="customerLevelId">
                  <mat-option [value]="level.id" *ngFor="let level of levels.items">
                    {{ level.description | abpLocalization }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Industry -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>Customer Vertical</mat-label>
                <mat-select
                  id="customers-customer-vertical-id"
                  formControlName="customerVerticalId"
                >
                  <mat-option [value]="vertical.id" *ngFor="let vertical of customerVerticals">
                    {{ vertical.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="w-100 mb-2">
                <mat-label>Company Name</mat-label>
                <input matInput formControlName="companyName" placeholder="Company Name" />
                <mat-error *ngIf="form.get('company').get('companyName').hasError('required')">
                  Please enter a Company Name
                </mat-error>
                <mat-error *ngIf="form.get('company.companyName').hasError('nameExists')">
                  Company name already exists
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Legal Name -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>Legal Name</mat-label>
                <input matInput formControlName="legalName" placeholder="Legal Name" />
                <mat-error *ngIf="form.get('company').get('legalName').hasError('required')">
                  Please enter a Legal Name
                </mat-error>
              </mat-form-field>
            </div>

            <!-- EIN -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>EIN</mat-label>
                <input matInput formControlName="ein" placeholder="EIN" />
                <mat-error *ngIf="form.get('company').get('ein').hasError('required')">
                  Please enter an EIN
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Website -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>Website</mat-label>
                <input matInput formControlName="website" placeholder="Website" />
                <mat-error *ngIf="form.get('company').get('website').hasError('required')">
                  Please enter a Website
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Id Hubspot -->
            <div class="col-6">
              <mat-form-field class="w-100 mb-2">
                <mat-label>{{ 'customersService::IdHubspot' | abpLocalization }}</mat-label>
                <input
                  matInput
                  formControlName="idHubspot"
                  [placeholder]="'customersService::IdHubspot' | abpLocalization"
                />
                <mat-error *ngIf="form.get('company').get('idHubspot').hasError('maxlength')">
                  Must be less than to 100 characters
                </mat-error>
              </mat-form-field>
            </div>

            <!-- MSA Customer -->
            <div class="col-12">
              <mat-slide-toggle formControlName="msaCustomer"></mat-slide-toggle>
              <mat-label>MSA Customer</mat-label>
            </div>
          </div>

          <div class="mt-4" formGroupName="mainContact">
            <div class="row">
              <h6 class="mb-3 me-auto">Main Company Contact</h6>
              <!-- Main Contact First Name -->
              <div class="col-6">
                <mat-form-field class="w-100 mb-2">
                  <mat-label>Main Contact First Name</mat-label>
                  <input matInput formControlName="firstName" placeholder="First Name" />
                  <mat-error
                    *ngIf="
                      form.get('company').get('mainContact').get('firstName').hasError('required')
                    "
                  >
                    Please enter a First Name
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Main Contact Last Name -->
              <div class="col-6">
                <mat-form-field class="w-100 mb-2">
                  <mat-label>Main Contact Last Name</mat-label>
                  <input matInput formControlName="lastName" placeholder="Last Name" />
                  <mat-error
                    *ngIf="
                      form.get('company').get('mainContact').get('lastName').hasError('required')
                    "
                  >
                    Please enter a Last Name
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Main Contact Email -->
              <div class="col-6">
                <mat-form-field class="w-100 mb-2">
                  <mat-label>Main Contact Email</mat-label>
                  <input matInput formControlName="email" type="email" placeholder="Email" />
                  <mat-error
                    *ngIf="form.get('company').get('mainContact').get('email').hasError('required')"
                  >
                    Please enter a valid Email
                  </mat-error>
                  <mat-error
                    *ngIf="form.get('company').get('mainContact').get('email').hasError('email')"
                  >
                    Please enter a valid Email format
                  </mat-error>
                  <mat-error
                    *ngIf="
                      form.get('company').get('mainContact').get('email').hasError('emailExists')
                    "
                  >
                    This email already exists
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Main Contact Phone Number -->
              <div class="col-6">
                <flg-phone-input
                  [widthClass]="'w-100'"
                  [control]="form.get('company.mainContact.primaryPhoneNumber')"
                  [label]="'Phone Number'"
                ></flg-phone-input>
              </div>
            </div>
          </div>
        </div>

        <div formGroupName="billingInformation">
          <div class="row">
            <!--            <h6 class="mb-3 me-auto">Billing Information</h6>-->

            <!--            &lt;!&ndash; Billing First Name &ndash;&gt;-->
            <!--            <div class="col-6">-->
            <!--              <mat-form-field class="w-100 mb-2">-->
            <!--                <mat-label>Billing First Name</mat-label>-->
            <!--                <input-->
            <!--                  matInput-->
            <!--                  formControlName="billingFirstName"-->
            <!--                  placeholder="Billing First Name"-->
            <!--                />-->
            <!--                <mat-error-->
            <!--                  *ngIf="-->
            <!--                    form.get('billingInformation').get('billingFirstName').hasError('required')-->
            <!--                  "-->
            <!--                >-->
            <!--                  Please enter a Billing First Name-->
            <!--                </mat-error>-->
            <!--              </mat-form-field>-->
            <!--            </div>-->

            <!--            &lt;!&ndash; Billing Last Name &ndash;&gt;-->
            <!--            <div class="col-6">-->
            <!--              <mat-form-field class="w-100 mb-2">-->
            <!--                <mat-label>Billing Last Name</mat-label>-->
            <!--                <input matInput formControlName="billingLastName" placeholder="Billing Last Name" />-->
            <!--                <mat-error-->
            <!--                  *ngIf="form.get('billingInformation').get('billingLastName').hasError('required')"-->
            <!--                >-->
            <!--                  Please enter a Billing Last Name-->
            <!--                </mat-error>-->
            <!--              </mat-form-field>-->
            <!--            </div>-->

            <!--            &lt;!&ndash; Billing Email &ndash;&gt;-->
            <!--            <div class="col-6">-->
            <!--              <mat-form-field class="w-100 mb-2">-->
            <!--                <mat-label>Billing Email</mat-label>-->
            <!--                <input matInput formControlName="email" type="email" placeholder="Email" />-->
            <!--                <mat-error *ngIf="form.get('billingInformation').get('email').hasError('required')">-->
            <!--                  Please enter a valid Email-->
            <!--                </mat-error>-->
            <!--                <mat-error *ngIf="form.get('billingInformation').get('email').hasError('email')">-->
            <!--                  Please enter a valid Email format-->
            <!--                </mat-error>-->
            <!--              </mat-form-field>-->
            <!--            </div>-->

            <!--            &lt;!&ndash; Billing Phone Number &ndash;&gt;-->
            <!--            <div class="col-6">-->
            <!--              <flg-phone-input-->
            <!--                [widthClass]="'w-100'"-->
            <!--                formControlName="phoneNumber"-->
            <!--                [label]="'Phone Number'"-->
            <!--              ></flg-phone-input>-->
            <!--            </div>-->

            <flg-location-form
              [countries]="dataCountries?.items"
              [states]="dataStates?.items"
              [form]="form.get('location')"
              [useLatLng]="false"
              (locationChange)="handleLocationChange($event)"
              [required]="false"
            ></flg-location-form>
          </div>
        </div>

        <flg-pending-controls
          [touched]="form.touched"
          [controls]="getPendingControls()"
        ></flg-pending-controls>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      {{ 'missionsService::Cancel' | abpLocalization }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="saveAddCustomer()"
      [disabled]="!form.valid"
      [class.disabled-button]="!form.valid"
    >
      {{ 'missionsService::Save' | abpLocalization }}
    </button>
  </div>
</mat-dialog-actions>
