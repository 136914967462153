import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  OrderMissionEventsCreateDto,
  OrderMissionEventsDto,
  OrderMissionEventsExcelDownloadDto,
  OrderMissionEventsUpdateDto,
  GetOrderMissionEventsInput,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class OrderMissionEventsService {
  apiName = 'missionsService';

  create = (input: OrderMissionEventsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderMissionEventsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/order-mission-events',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/order-mission-events/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderMissionEventsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/order-mission-events/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/order-mission-events/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: OrderMissionEventsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/order-mission-events/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          description: input.description,
          code: input.code,
          order: input.order,
          state: input.state,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetOrderMissionEventsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<OrderMissionEventsDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/order-mission-events',
        params: {
          filterText: input.filterText,
          description: input.description,
          code: input.code,
          order: input.order,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: OrderMissionEventsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderMissionEventsDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/order-mission-events/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
