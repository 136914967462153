import { EventEmitter, Injectable } from '@angular/core';
import { FilePreviewData } from '../models/parallel-upload/file-preview-data';
import { ChunkFileModel } from '../models/parallel-upload/chunk-file-model';
import { ChunkModel, ChunkModelStatus } from '../models/parallel-upload/chunk-model';
import { QueuedFile, QueuedFileStatus } from '../models/parallel-upload/queued-file';
import {
  QueuedFilePreviewData,
  QueuedFilePreviewStatus,
} from '../models/parallel-upload/queued-file-preview-data';
import { Observable, finalize, forkJoin, tap } from 'rxjs';
import { ChunkUploadResultModel } from '../models/file-upload/chunk-upload-result.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChunkCompleteModel } from '../models/file-upload/chunk-complete.model';
import { ChunkTimingModel } from '../models/parallel-upload/chunk-timing-model';
import {
  DirectoryDescriptorService,
  FileDescriptorService,
  FileUploadPreInfoDto,
} from '@volo/abp.ng.file-management/proxy';
import { ToasterService } from '@abp/ng.theme.shared';
import { ConfigStateService, CurrentUserDto, EnvironmentService } from '@abp/ng.core';
import {
  PaginatedQueuedFilePreviewData,
  QueuedFilePreviewDataPage,
} from '../models/parallel-upload/paginated-queued-file-preview-data';
import { eParallelFileUploadWorkerStatus } from '../enums';
import { FolderModel, PreinfoData } from '../models/parallel-upload/folder-model';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'projects/flyguys/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ParallelFileUploadService {
  constructor(
    private http: HttpClient,
    private fileService: FileDescriptorService,
    private toaster: ToasterService,
    private environmentService: EnvironmentService,
    private service: DirectoryDescriptorService,
    private configState: ConfigStateService,
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsights.connectionString,
        enableAutoRouteTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
  }

  private apiName = 'FileManagementAPI';

  get apiUrl() {
    return this.environmentService.getApiUrl(this.apiName);
  }

  private _showProgressViewer = new EventEmitter<boolean>();
  private _updateFiles = new EventEmitter<boolean>();
  private _folderCreated = new EventEmitter<string>();
  private _preInfoDone = new EventEmitter<boolean>();

  UpdateFiles = this._updateFiles.asObservable();
  FolderCreated = this._folderCreated.asObservable();
  ShowProgressViewer = this._showProgressViewer.asObservable();
  PreInfoDone = this._preInfoDone.asObservable();

  private maxChunkSize = 10000000;
  private maxFileUploadCount = 6;
  private idealChunkUploadTime = 20; //seconds
  private idealChunkUploadTimeThreshold = 2; //seconds
  private idealChunkCountPerFile = 1;
  appInsights: ApplicationInsights;

  private maxChunkUploadCount = 6;
  private dynamicMaxChunkUploadCount = 3;
  private dynamicMaxFileUploadCount = Math.max(
    1,
    Math.trunc(this.dynamicMaxChunkUploadCount / this.idealChunkCountPerFile),
  );
  private chunksPerFile = Math.round(
    this.dynamicMaxChunkUploadCount / this.dynamicMaxFileUploadCount,
  );

  private maxChunkingFileCount = 5;
  private actualChunkingFileCount = 0;

  uploadedFileCount = 0;

  private maxChunkRetryCount = 5;
  private maxRetryCount = 5;
  private minutesUntilNextRetry = 5;
  private currentUser = this.configState.getOne('currentUser') as CurrentUserDto;
  public missionId: string;

  private CreatedDirectories: FolderModel[] = [];

  private Files: File[] = [];
  LoadedFiles: FilePreviewData[] = [];

  private QueuedFiles: QueuedFile[] = [];
  QueuedFilesPreview: QueuedFilePreviewData[] = [];
  PaginatedQueuedFilesPreview: PaginatedQueuedFilePreviewData = { totalPages: 0, pages: [] };
  PaginationIndex = -1;

  private ChunkTimingData: ChunkTimingModel[] = [];
  private maxChunkTimingDataCount = 10;

  private noCacheheader = new HttpHeaders({
    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    Pragma: 'no-cache',
    Expires: '0',
  });

  private fileUploadProgressWorker: Worker;

  addFile(file: File) {
    if (
      !this.Files.find(x => x.name == file.name && x.webkitRelativePath == file.webkitRelativePath)
    ) {
      this.Files.push(file);
      this.LoadedFiles.push({
        name: file.webkitRelativePath ? file.webkitRelativePath : file.name,
      });
    }
  }

  public getIsRunning(): boolean {
    return this.running;
  }

  removeFile(filePreview: FilePreviewData) {
    const file = this.Files.find(x => x.name == filePreview.name);

    this.Files.splice(this.Files.indexOf(file), 1);
    this.LoadedFiles.splice(this.LoadedFiles.indexOf(filePreview), 1);
  }

  removeFiles() {
    this.Files = [];
    this.LoadedFiles = [];
  }

  //directoryId -> base directory
  uploadFilesV3(directoryId) {
    let directories = this.getVirtualDirectories();
    this.createDirectories(directories, directoryId);
  }

  createFiles(directoryId) {
    let results: PreinfoData[] = [];
    let subscriptions = [];

    for (let folder of this.CreatedDirectories) {
      let files = this.Files.filter(x => x.webkitRelativePath == folder.fullPath + `/${x.name}`);

      this.makeLogs(
        this.currentUser.email,
        null,
        null,
        files.map(x => x.name).join(','),
        'Pre info',
        this.missionId,
        files.reduce((a, file) => a + file.size, 0),
        null,
        null,
        'Pre-info',
      );

      subscriptions.push(
        this.fileService
          .getPreInfo(
            files.map(f => ({
              fileName: f.name,
              size: f.size,
              folderId: directoryId,
            })),
          )
          .pipe(
            tap(res => {
              results.push({ data: res, directoryId: folder.id });
            }),
          ),
      );
    }

    const rootFiles = this.Files.filter(x => x.webkitRelativePath == '');

    this.makeLogs(
      this.currentUser.email,
      null,
      null,
      rootFiles.map(x => x.name).join(','),
      'Pre info',
      this.missionId,
      rootFiles.reduce((a, file) => a + file.size, 0),
      null,
      null,
      'Pre-Info',
    );

    subscriptions.push(
      this.fileService
        .getPreInfo(
          this.Files.filter(x => x.webkitRelativePath == '').map(f => ({
            fileName: f.name,
            size: f.size,
            folderId: directoryId,
          })),
        )
        .pipe(
          tap(res => {
            results.push({ data: res, directoryId: directoryId });
          }),
        ),
    );

    forkJoin(subscriptions).subscribe(_ => {
      for (let folder of this.CreatedDirectories) {
        this.processFiles(
          results.find(x => x.directoryId == folder.id).data,
          folder.id,
          this.Files.filter(x => x.webkitRelativePath == folder.fullPath + `/${x.name}`),
        );
      }

      this.CreatedDirectories = [];

      this.processFiles(
        results.find(x => x.directoryId == directoryId).data,
        directoryId,
        this.Files.filter(x => x.webkitRelativePath == ''),
      );
    });
  }

  createDirectories(folders: FolderModel[], directoryId) {
    if (folders.length <= 0) {
      this.createFiles(directoryId);
      return;
    }

    let directory = folders[0];
    let parentId = directory.level == 0 ? directoryId : this.searchParentId(directory);

    //directories need to be created in order
    this.service
      .createIfNotExists({
        name: directory.name,
        parentId: parentId,
        extraProperties: {},
      })
      .subscribe(res => {
        this._folderCreated.next(parentId);

        directory.id = res.id;
        this.CreatedDirectories.push(directory);

        folders.shift();
        this.createDirectories(folders, directoryId);
      });
  }

  searchParentId(folder: FolderModel) {
    let parentPath = folder.fullPath.slice(0, folder.fullPath.length - (folder.name.length + 1));
    let parent = this.CreatedDirectories.find(x => x.fullPath == parentPath);
    return parent.id;
  }

  getVirtualDirectories() {
    let virtualFolders: FolderModel[] = [];

    for (let file of this.Files) {
      if (file.webkitRelativePath !== '') {
        let folders = file.webkitRelativePath.split('/');
        folders.pop();

        for (let i = 0; i < folders.length; i++) {
          let index = i;
          let foldersToPath = [];

          while (index >= 0) {
            foldersToPath.push(folders[index]);
            index--;
          }

          let fullPath = foldersToPath.reverse().join('/');

          if (!virtualFolders.find(x => x.fullPath == fullPath)) {
            virtualFolders.push({
              name: folders[i],
              fullPath: fullPath,
              level: i,
            });
          }
        }
      }
    }

    return virtualFolders.sort((a, b) => {
      return a.level - b.level;
    });
  }

  openProgressViewer() {
    this._showProgressViewer.emit(true);
  }

  private processFiles(fileInfoDto: FileUploadPreInfoDto[], directoryId, files: File[]) {
    this._showProgressViewer.emit(true);
    this._preInfoDone.emit(true);

    let existingFiles = fileInfoDto.filter(x => x.doesExist);
    let invalidFileNames = fileInfoDto.filter(x => !x.hasValidName);

    let alreadyEnqueuedCounter = 0;

    for (let file of files) {
      const alreadyEnqueued = this.QueuedFiles.find(
        x => x.chunkFileModel.fileName == file.name && x.directoryId == directoryId,
      );

      if (alreadyEnqueued) {
        alreadyEnqueuedCounter++;
        continue;
      }

      if (existingFiles.find(x => x.fileName == file.name)) continue;

      if (invalidFileNames.find(x => x.fileName == file.name)) continue;

      this.EnqueueFileV2(file, directoryId);
    }

    if (existingFiles.length > 0) {
      const warnMessage =
        existingFiles.length > 1
          ? `${existingFiles.length} files already exist in the folder. They will be skipped.`
          : `${existingFiles.length} file already exists in the folder. It will be skipped.`;

      this.toaster.warn(warnMessage, '', { life: 1000 });
    }

    if (invalidFileNames.length > 0) {
      const invalidMessage =
        invalidFileNames.length > 1
          ? `${invalidFileNames.length} files have invalid names. They will be skipped.`
          : `${existingFiles.length} file has an invalid name. It will be skipped.`;

      this.toaster.error(invalidMessage, '', { life: 1000 });
    }

    if (alreadyEnqueuedCounter > 0) {
      let infoMessage =
        alreadyEnqueuedCounter > 1
          ? `${alreadyEnqueuedCounter} files already are in the queue. They will be skipped.`
          : `${alreadyEnqueuedCounter} file already is in the queue. It will be skipped.`;

      this.toaster.error(infoMessage, '', { life: 1000 });
    }

    this.generatePagination();
  }

  private EnqueueFileV2(file: File, directoryId: string) {
    const chunkFileModel: ChunkFileModel = {
      totalChunks: 0,
      blobName: '',
      fileName: file.name,
      chunks: [],
      thumbnail: '',
    };

    var queuedFile: QueuedFile = {
      chunkFileModel: chunkFileModel,
      fileData: file,
      progress: 0,
      status: QueuedFileStatus.AwaitingChunking,
      activeRequests: 0,
      directoryId: directoryId,
      chunkRetryCount: 0,
      retryCount: 0,
      waitRetryUntil: null,
      uploadNetworkRate: 0,
      activeSubscriptions: [],
      accumulatedFailedTime: 0,
    };

    this.QueuedFiles.push(queuedFile);

    let path = file.webkitRelativePath.split('/');
    path.pop();

    this.QueuedFilesPreview.push({
      name: queuedFile.chunkFileModel.fileName,
      webkitRelativePath: path.length > 0 ? path.join('/') : null,
      progress: queuedFile.progress,
      status: QueuedFilePreviewStatus.Awaiting,
      directoryId: directoryId,
      minutesUntilRetry: 0,
      size: queuedFile.fileData.size,
      uploadNetworkRate: '0 Kbit/s',
    });

    this.LoadedFiles = this.LoadedFiles.filter(x =>
      file.webkitRelativePath ? x.name != file.webkitRelativePath : x.name != file.name,
    );
    this.Files = this.Files.filter(x =>
      x.webkitRelativePath ? x.webkitRelativePath != file.webkitRelativePath : x.name != file.name,
    );

    this.startSmartFileUpload();
  }

  cancelUpload(file: QueuedFilePreviewData) {
    const queuedFile = this.QueuedFiles.find(
      x => x.chunkFileModel.fileName == file.name && x.directoryId == file.directoryId,
    );
    const preview = this.findQueuedFilePreview(queuedFile);

    //cancel all pending requests
    while (queuedFile.activeSubscriptions.length != 0) {
      const sub = queuedFile.activeSubscriptions[0];
      sub.unsubscribe();
    }

    if (
      queuedFile.status == QueuedFileStatus.Chunking ||
      queuedFile.status == QueuedFileStatus.AwaitingUpload
    ) {
      this.actualChunkingFileCount--;
    }

    this.QueuedFiles.splice(this.QueuedFiles.indexOf(queuedFile), 1);
    this.QueuedFilesPreview.splice(this.QueuedFilesPreview.indexOf(preview), 1);
    this.generatePagination();
  }

  public retryUpload(file: QueuedFilePreviewData, resetRetryCount: boolean) {
    const queuedFile = this.QueuedFiles.find(
      x => x.chunkFileModel.fileName == file.name && x.directoryId == file.directoryId,
    );

    if (!queuedFile) {
      console.log(`Cannot retry file ${file.name}, not found`);
      return;
    }

    this.retryFileUpload(queuedFile, resetRetryCount);
  }

  private retryFileUpload(file: QueuedFile, resetRetryCount: boolean) {
    if (resetRetryCount) {
      file.retryCount = 0;
    }

    file.chunkRetryCount = 0;
    file.chunkFileModel.chunks.forEach(chunk => {
      if (chunk.status == ChunkModelStatus.Failed) {
        chunk.status = ChunkModelStatus.Waiting;
      }
    });

    const preview = this.findQueuedFilePreview(file);
    this.ensureUniqueBlobName(file);

    if (file.status == QueuedFileStatus.ChunkingFailed) {
      file.status = QueuedFileStatus.AwaitingChunking;
      preview.status = QueuedFilePreviewStatus.Awaiting;
    }

    if (file.status == QueuedFileStatus.UploadFailed) {
      file.status = QueuedFileStatus.AwaitingUpload;
      preview.status = QueuedFilePreviewStatus.Awaiting;
    }

    if (file.status == QueuedFileStatus.FinishingFailed) {
      file.status = QueuedFileStatus.Uploading;
      preview.status = QueuedFilePreviewStatus.Uploading;
    }

    this.startSmartFileUpload();
  }

  retryAll() {
    for (const file of this.QueuedFilesPreview) {
      if (
        file.status == QueuedFilePreviewStatus.Failed ||
        file.status == QueuedFilePreviewStatus.Retrying
      ) {
        this.retryUpload(file, true);
      }
    }
  }

  private generateChunksV3(queuedFile: QueuedFile) {
    queuedFile.status = QueuedFileStatus.Chunking;

    const preview = this.findQueuedFilePreview(queuedFile);
    preview.status = QueuedFilePreviewStatus.Awaiting;

    const totalChunks = Math.ceil(queuedFile.fileData.size / this.maxChunkSize);

    // if (!this._uploadSpeed) {
    //   this.updateFileUploadSpeed(null, 0);
    // }

    this.http
      .get(`${this.apiUrl}/api/file-management/file-descriptor/file/id`, {
        headers: this.noCacheheader,
      })
      .subscribe({
        next: (res: any) => {
          queuedFile.chunkFileModel.totalChunks = totalChunks;
          queuedFile.chunkFileModel.blobName = res.id;
          queuedFile.chunkFileModel.startDateTime = new Date();

          for (let i = 0; i < totalChunks; i++) {
            const start = i * this.maxChunkSize;
            const end = Math.min(start + this.maxChunkSize, queuedFile.fileData.size);

            const chunkModel: ChunkModel = {
              start: start,
              end: end,
              chunkNumber: i,
              identifier: '',
              webAppChunkId: crypto.randomUUID(),
              status: ChunkModelStatus.Waiting,
            };

            queuedFile.chunkFileModel.chunks.push(chunkModel);
          }

          queuedFile.status = QueuedFileStatus.AwaitingUpload;
          preview.status = QueuedFilePreviewStatus.Awaiting;

          // if (!this._uploadSpeed) {
          //   this.updateFileUploadSpeed(null, 0);
          // }

          this.makeLogs(
            this.currentUser.email,
            queuedFile.chunkFileModel.startDateTime,
            null,
            queuedFile.fileData.name,
            'Chunking succeeded',
            this.missionId,
            queuedFile.fileData.size,
            null,
            null,
            'Chunking process completed successfully',
          );
        },
        error: () => {
          this.actualChunkingFileCount--;
          queuedFile.status = QueuedFileStatus.ChunkingFailed;
          preview.status = QueuedFilePreviewStatus.Failed;

          this.makeLogs(
            this.currentUser.email,
            queuedFile.chunkFileModel.startDateTime,
            new Date(),
            queuedFile.fileData.name,
            'Chunking failed',
            this.missionId,
            queuedFile.fileData.size,
            null,
            null,
            'Chunking process failed during API request',
          );

          this.setFileRetry(queuedFile);
        },
      });
  }

  private running = false;
  private startSmartFileUpload() {
    if (!this.running) {
      this.running = true;
      console.log('Starting smart file upload');
      this.smartFileUploadV4();
    }
  }

  private setFileRetry(file: QueuedFile, retryAllFile: boolean = false) {
    file.retryCount++;
    const currentDate = new Date();
    const minutesUntilNextRetry = this.minutesUntilNextRetry * file.retryCount;
    currentDate.setMinutes(currentDate.getMinutes() + minutesUntilNextRetry);
    file.waitRetryUntil = currentDate;

    const preview = this.findQueuedFilePreview(file);
    preview.minutesUntilRetry = minutesUntilNextRetry;
    preview.status = QueuedFilePreviewStatus.Retrying;

    if (retryAllFile) {
      file.status = QueuedFileStatus.ChunkingFailed;
      file.chunkFileModel.chunks = [];
      file.chunkFileModel.blobName = null;
      file.chunkFileModel.totalChunks = 0;
    }
  }

  private smartFileUploadV4() {
    //console.log(`Current chunkingFileCount: ${this.actualChunkingFileCount}`);

    //@TODO : Just to debug on birdbug records. Delete after FIx 18624
    //console.log(`Start smartFileUploadV4 Time : ${new Date().toLocaleTimeString()}`);

    const filesAwaitingChunking = this.QueuedFiles.filter(
      x => x.status == QueuedFileStatus.AwaitingChunking,
    );

    for (const queuedFile of filesAwaitingChunking) {
      if (this.actualChunkingFileCount >= this.maxChunkingFileCount) {
        break;
      }

      this.generateChunksV3(queuedFile);
      this.actualChunkingFileCount++;
    }

    const activeUploadsCount = this.QueuedFiles.filter(
      x => x.status == QueuedFileStatus.Uploading,
    ).length;

    if (activeUploadsCount < this.dynamicMaxFileUploadCount) {
      const queuedFile = this.QueuedFiles.find(x => x.status == QueuedFileStatus.AwaitingUpload);
      if (queuedFile) {
        this.actualChunkingFileCount--;
        queuedFile.status = QueuedFileStatus.Uploading;
        const preview = this.findQueuedFilePreview(queuedFile);
        preview!.status = QueuedFilePreviewStatus.Uploading;
      }
    }

    const failedUploads = this.QueuedFiles.filter(
      x =>
        x.status == QueuedFileStatus.ChunkingFailed ||
        x.status == QueuedFileStatus.UploadFailed ||
        x.status == QueuedFileStatus.FinishingFailed,
    );

    const currentTime = new Date().getTime();

    for (const failedUpload of failedUploads) {
      if (failedUpload.retryCount > this.maxRetryCount) {
        const preview = this.findQueuedFilePreview(failedUpload);
        preview.status = QueuedFilePreviewStatus.RetryingFailed;
      }

      if (currentTime > failedUpload.waitRetryUntil.getTime()) {
        this.retryFileUpload(failedUpload, false);
      }
    }

    const activeUploads = this.QueuedFiles.filter(x => x.status == QueuedFileStatus.Uploading);

    for (const activeUpload of activeUploads) {
      while (activeUpload.activeRequests < this.chunksPerFile) {
        const nextChunk = activeUpload.chunkFileModel.chunks.find(
          x => x.status == ChunkModelStatus.Waiting || x.status == ChunkModelStatus.Failed,
        );
        if (!nextChunk) {
          //No more chunks to process
          //console.log(`No more chunks for file ${activeUpload.chunkFileModel.fileName}`);
          break;
        }

        if (nextChunk.status == ChunkModelStatus.Failed) {
          if (activeUpload.chunkRetryCount > this.maxChunkRetryCount) {
            activeUpload.status = QueuedFileStatus.UploadFailed;
            const preview = this.findQueuedFilePreview(activeUpload);
            preview!.status = QueuedFilePreviewStatus.Failed;

            this.setFileRetry(activeUpload);

            break;
          }
          activeUpload.chunkRetryCount += 1;
        }

        const chunkData = activeUpload.fileData.slice(nextChunk.start, nextChunk.end);
        const formData = new FormData();

        formData.append('file', chunkData, activeUpload.chunkFileModel.fileName);

        const headers = new HttpHeaders()
          .set('Content-Range', `${nextChunk.start}-${nextChunk.end - 1}`)
          .set('Chunk-Number', `${nextChunk.chunkNumber}`)
          .set('File-Size', `${activeUpload.fileData.size}`)
          .set(
            'Blob-Name',
            `${activeUpload.chunkFileModel.blobName ? activeUpload.chunkFileModel.blobName : ''}`,
          )
          .set('WebAppChunkId', nextChunk.webAppChunkId);

        activeUpload.chunkFileModel.chunksUploadStartDateTime ??= new Date();

        // if (!this._uploadSpeed) {
        //   this.updateFileUploadSpeed(activeUpload);
        // }

        const req = (
          this.http.post(
            `${this.apiUrl}/api/file-management/file-descriptor/chunkUpload`,
            formData,
            {
              headers,
            },
          ) as Observable<ChunkUploadResultModel>
        ).pipe(
          finalize(() => {
            while (activeUpload.activeSubscriptions.find(x => x.closed == true)) {
              const sub = activeUpload.activeSubscriptions.find(x => x.closed == true);
              activeUpload.activeSubscriptions.splice(
                activeUpload.activeSubscriptions.indexOf(sub),
                1,
              );
            }

            nextChunk.endTime = new Date().getTime();
            activeUpload.activeRequests -= 1;
            activeUpload.progress =
              (activeUpload.chunkFileModel.chunks.filter(
                x => x.status == ChunkModelStatus.Succeeded,
              ).length /
                activeUpload.chunkFileModel.totalChunks) *
              100.0;
            const preview = this.findQueuedFilePreview(activeUpload);
            preview!.progress = activeUpload.progress;

            if (!preview.startDate) preview.startDate = new Date();

            if (nextChunk.status != ChunkModelStatus.Failed) {
              if (activeUpload.lastFailedUploadTime) {
                activeUpload.accumulatedFailedTime +=
                  (new Date().getTime() - activeUpload.lastFailedUploadTime.getTime()) / 1000;
                activeUpload.lastFailedUploadTime = null;
              }

              var duration = (nextChunk.endTime! - nextChunk.startTime!) / 1000;

              //console.log(`Chunk uploaded, Elapsed time: ${duration}`);

              this.UpdateChunkTimingData({
                avgTimeElapsed: duration,
              });
            } else {
              activeUpload.lastFailedUploadTime = new Date();
            }

            this.updateFileUploadSpeed(activeUpload);
            //console.log(`Request finalized for file ${activeUpload.file.fileName}, Active requests: ${activeUpload.activeRequests}`);
          }),
        );

        activeUpload.activeRequests += 1;
        nextChunk.status = ChunkModelStatus.Uploading;
        nextChunk.startTime = new Date().getTime();

        const sub = req.subscribe({
          next: value => {
            if (!value.chunkIdentifier || !value.chunkNumber) {
              //console.error('Chunk identifier not found:\n', value);
              nextChunk.status = ChunkModelStatus.Failed;
              return;
            }

            nextChunk.identifier = value.chunkIdentifier;
            nextChunk.status = ChunkModelStatus.Succeeded;

            if (!activeUpload.chunkFileModel.blobName) {
              activeUpload.chunkFileModel.blobName = value.blobName;
            }

            if (value.thumbnail) {
              activeUpload.chunkFileModel.thumbnail = value.thumbnail;
            }
            this.makeLogs(
              this.currentUser.email,
              new Date(nextChunk.startTime),
              activeUpload.chunkFileModel.chunksUploadEndDateTime,
              activeUpload.chunkFileModel.fileName,
              'Chunk upload succeeded',
              this.missionId,
              activeUpload.fileData.size,
              (nextChunk.endTime! - nextChunk.startTime!) / 1000,
              activeUpload.uploadNetworkRate,
              `Chunk ${nextChunk.chunkNumber} uploaded successfully`,
            );
          },
          error: () => {
            nextChunk.status = ChunkModelStatus.Failed;

            this.makeLogs(
              this.currentUser.email,
              new Date(nextChunk.startTime),
              activeUpload.chunkFileModel.chunksUploadEndDateTime,
              activeUpload.chunkFileModel.fileName,
              'Chunk upload failed',
              this.missionId,
              activeUpload.fileData.size,
              (new Date().getTime() - nextChunk.startTime!) / 1000,
              activeUpload.uploadNetworkRate,
              `Chunk ${nextChunk.chunkNumber} failed to upload`,
            );
          },
        });

        activeUpload.activeSubscriptions.push(sub);
      }

      if (
        activeUpload.activeRequests == 0 &&
        activeUpload.chunkFileModel.chunks.every(x => x.status == ChunkModelStatus.Succeeded)
      ) {
        if (activeUpload.status != QueuedFileStatus.Finishing) {
          //console.log(`Finalizing file ${activeUpload.chunkFileModel.fileName}`);

          activeUpload.status = QueuedFileStatus.Finishing;
          const preview = this.findQueuedFilePreview(activeUpload);
          preview.status = QueuedFilePreviewStatus.Finishing;
          activeUpload.chunkFileModel.chunksUploadEndDateTime = new Date();

          const completeUpload: ChunkCompleteModel = {
            fileName: activeUpload.chunkFileModel.fileName,
            chunkIds: activeUpload.chunkFileModel.chunks.map(x => x.identifier),
            contentLenght: activeUpload.fileData.size,
            thumbnailUrl: activeUpload.chunkFileModel.thumbnail,
            directoryId: activeUpload.directoryId,
            blobName: activeUpload.chunkFileModel.blobName,
          };

          this.http
            .post(
              `${this.apiUrl}/api/file-management/file-descriptor/completeUpload`,
              completeUpload,
              {
                headers: this.noCacheheader,
              },
            )
            .pipe(
              finalize(() => {
                //activeUpload.waitingComplete = false;
              }),
            )
            .subscribe({
              next: () => {
                //console.info(`File ${activeUpload.chunkFileModel.fileName} succesfully uploaded`);
                activeUpload.status = QueuedFileStatus.Succeeded;
                activeUpload.chunkFileModel.completeUploadDateTime = new Date();
                //this._updateFiles.emit(true);
                this.updateFileUploadSpeed(activeUpload);

                //Improve release memory
                //this.printFileLogTrack(activeUpload);
                activeUpload.chunkFileModel.chunks = [];
                activeUpload.fileData = null;

                const preview = this.findQueuedFilePreview(activeUpload);
                preview!.status = QueuedFilePreviewStatus.Succeeded;

                this.uploadedFileCount += 1;

                const startTime = activeUpload.chunkFileModel.chunksUploadStartDateTime.getTime();
                const endTime = activeUpload.chunkFileModel.completeUploadDateTime.getTime();
                const durationOfUpload = (endTime - startTime) / 1000;
                const fileSizeInMB = activeUpload.fileData?.size / (1024 * 1024);
                const dataTransferRate = fileSizeInMB / durationOfUpload;

                this.makeLogs(
                  this.currentUser.email,
                  activeUpload.chunkFileModel.chunksUploadStartDateTime,
                  activeUpload.chunkFileModel.completeUploadDateTime,
                  activeUpload.chunkFileModel.fileName,
                  'Complete upload succeeded',
                  this.missionId,
                  activeUpload.fileData?.size || 0,
                  durationOfUpload,
                  dataTransferRate,
                  `File ${activeUpload.chunkFileModel.fileName} uploaded successfully`,
                );

                this._updateFiles.emit(true);
              },
              error: err => {
                //console.error('Unable to complete upload:\n', err);
                activeUpload.status = QueuedFileStatus.FinishingFailed;
                const preview = this.findQueuedFilePreview(activeUpload);
                preview!.status = QueuedFilePreviewStatus.Failed;
                this.setFileRetry(activeUpload);

                this.makeLogs(
                  this.currentUser.email,
                  activeUpload.chunkFileModel.chunksUploadStartDateTime,
                  new Date(),
                  activeUpload.chunkFileModel.fileName,
                  'Complete upload failed',
                  this.missionId,
                  activeUpload.fileData?.size || 0,
                  null,
                  null,
                  `Failed to upload file ${activeUpload.chunkFileModel.fileName}`,
                );
              },
            });
        }
      } else {
        //console.log(`File ${activeUpload.chunkFileModel.fileName} is not ready yet`);
        //console.log(activeUpload);
      }

      this.ensureUniqueBlobName(activeUpload);
    }

    if (
      this.QueuedFiles.find(
        x =>
          x.status == QueuedFileStatus.AwaitingChunking ||
          x.status == QueuedFileStatus.Chunking ||
          x.status == QueuedFileStatus.AwaitingUpload ||
          x.status == QueuedFileStatus.Uploading ||
          x.status == QueuedFileStatus.Finishing ||
          (x.status == QueuedFileStatus.ChunkingFailed && x.retryCount <= this.maxRetryCount) ||
          (x.status == QueuedFileStatus.UploadFailed && x.retryCount <= this.maxRetryCount) ||
          (x.status == QueuedFileStatus.FinishingFailed && x.retryCount <= this.maxRetryCount),
      )
    ) {
      //There are still files to upload, re-run until all files succeed
      this.runFileUploadProgressWorker();
    } else {
      this.running = false;
      this.finishFileUploadProgressWorker();
      console.log('Stopping smart file upload');
    }
  }

  private ensureUniqueBlobName(fileActive: QueuedFile) {
    if (!fileActive.chunkFileModel.blobName) {
      return;
    }

    let blobNameCount = 0;

    const activeFiles = this.QueuedFiles.filter(
      x => x?.status != QueuedFileStatus.AwaitingChunking && x != fileActive,
    );

    let duplicatedBlobNameFiles: QueuedFile[] = [];

    for (const fileCompare of activeFiles) {
      if (fileActive.chunkFileModel.blobName == fileCompare.chunkFileModel.blobName) {
        blobNameCount++;

        if (fileActive.status != QueuedFileStatus.Succeeded)
          duplicatedBlobNameFiles.push(fileActive);

        if (fileCompare.status != QueuedFileStatus.Succeeded)
          duplicatedBlobNameFiles.push(fileCompare);
      }
    }

    if (blobNameCount > 0) {
      for (const duplicatdFile of duplicatedBlobNameFiles) {
        console.log(
          `Something bad happened.
          FileName : '${duplicatdFile.chunkFileModel.fileName}' , 
          BlobName : '${duplicatdFile.chunkFileModel.blobName}' ,  
          Total Count : ${blobNameCount}`,
        );

        this.setFileRetry(duplicatdFile, true);
      }
    }
  }

  private UpdateChunkTimingData(data: ChunkTimingModel) {
    this.ChunkTimingData.push(data);

    if (this.ChunkTimingData.length > this.maxChunkTimingDataCount) {
      this.ChunkTimingData.shift();
    }

    var timeElapsed = 0;

    this.ChunkTimingData.map(x => {
      timeElapsed += x.avgTimeElapsed;
    });

    timeElapsed /= this.ChunkTimingData.length;

    //console.log(`Timing data count: ${this.ChunkTimingData.length}`);
    //console.log(`Chunk upload avg elapsed time: ${timeElapsed}`);

    const pendingAndActiveUploads = this.QueuedFiles.filter(
      x => x.status == QueuedFileStatus.AwaitingUpload || x.status == QueuedFileStatus.Uploading,
    ).length;

    if (timeElapsed > this.idealChunkUploadTime + this.idealChunkUploadTimeThreshold) {
      //console.log(`should reduce chunkCount by ${Math.round(timeElapsed / this.idealChunkUploadTime)}`);
      this.dynamicMaxChunkUploadCount -= Math.round(timeElapsed / this.idealChunkUploadTime);

      if (this.dynamicMaxChunkUploadCount < 1) {
        this.dynamicMaxChunkUploadCount = 1;
      }
    } else if (timeElapsed < this.idealChunkUploadTime - this.idealChunkUploadTimeThreshold) {
      //console.log(`should increment chunkCount by 1`);
      this.dynamicMaxChunkUploadCount += 1;

      if (this.dynamicMaxChunkUploadCount > this.maxChunkUploadCount) {
        this.dynamicMaxChunkUploadCount = this.maxChunkUploadCount;
      }
    }
    //else{
    //  console.log(`Maintaining current chunk size`);
    //}

    this.dynamicMaxFileUploadCount = Math.max(
      1,
      Math.trunc(this.dynamicMaxChunkUploadCount / this.idealChunkCountPerFile),
    );

    if (this.dynamicMaxFileUploadCount > pendingAndActiveUploads && pendingAndActiveUploads > 0) {
      this.dynamicMaxFileUploadCount = pendingAndActiveUploads;
    }

    if (this.dynamicMaxFileUploadCount > this.maxFileUploadCount) {
      this.dynamicMaxFileUploadCount = this.maxFileUploadCount;
    }

    this.chunksPerFile = Math.trunc(
      this.dynamicMaxChunkUploadCount / this.dynamicMaxFileUploadCount,
    );

    //console.log(`Current dynamicMaxFileUploadCount: ${this.dynamicMaxFileUploadCount}`);
    //console.log(`Current dynamicMaxChunkUploadCount: ${this.dynamicMaxChunkUploadCount}`);
    //console.log(`Current chunk count: ${this.chunksPerFile}`);
  }

  cleanFinishedFiles() {
    this.uploadedFileCount = 0;
    while (this.QueuedFiles.find(x => x.status == QueuedFileStatus.Succeeded)) {
      const queuedFile = this.QueuedFiles.find(x => x.status == QueuedFileStatus.Succeeded);
      const preview = this.findQueuedFilePreview(queuedFile);

      this.QueuedFiles.splice(this.QueuedFiles.indexOf(queuedFile), 1);
      this.QueuedFilesPreview.splice(this.QueuedFilesPreview.indexOf(preview), 1);
      this.generatePagination();
    }
  }

  private findQueuedFilePreview(queuedFile: QueuedFile) {
    return this.QueuedFilesPreview.find(
      x => x.name == queuedFile.chunkFileModel.fileName && x.directoryId == queuedFile.directoryId,
    );
  }

  private generatePagination() {
    let pages: QueuedFilePreviewDataPage[] = [];

    const resultsPerPage = 100;
    let count = 0;
    let actualPage = 0;
    let files: QueuedFilePreviewData[] = [];

    for (const file of this.QueuedFilesPreview) {
      files.push(file);
      count++;

      if (count >= resultsPerPage) {
        pages.push({
          page: actualPage,
          files: files,
        });

        actualPage++;
        count = 0;
        files = [];
      }
    }

    if (files.length > 0) {
      pages.push({
        page: actualPage,
        files: files,
      });
    }

    this.PaginationIndex = this.clamp(this.PaginationIndex, 0, pages.length - 1);

    this.PaginatedQueuedFilesPreview = {
      totalPages: pages.length,
      pages: pages,
    };
  }

  nextPage() {
    this.PaginationIndex =
      this.PaginatedQueuedFilesPreview.totalPages > 0
        ? this.clamp(this.PaginationIndex + 1, 0, this.PaginatedQueuedFilesPreview.totalPages - 1)
        : -1;
  }

  previousPage() {
    this.PaginationIndex =
      this.PaginatedQueuedFilesPreview.totalPages > 0
        ? this.clamp(this.PaginationIndex - 1, 0, this.PaginatedQueuedFilesPreview.totalPages - 1)
        : -1;
  }

  clamp(value, min, max) {
    return value < min ? min : value > max ? max : value;
  }

  private runFileUploadProgressWorker() {
    if (typeof Worker !== 'undefined') {
      if (!this.fileUploadProgressWorker && this.running) {
        this.fileUploadProgressWorker = new Worker(
          new URL('../workers/file-upload/parallel-files-upload.worker.ts', import.meta.url),
          { name: 'parallel-files-upload', type: 'module' },
        );

        this.fileUploadProgressWorker.onmessage = ({ data: { status } }) => {
          const statusCode = status as eParallelFileUploadWorkerStatus;
          if (statusCode === eParallelFileUploadWorkerStatus.On) {
            this.smartFileUploadV4();
          }
        };

        console.log('Starting fileUploadProgressWorker');
      }

      if (this.running) {
        this.fileUploadProgressWorker.postMessage({
          status: eParallelFileUploadWorkerStatus.On,
        });
      }
    } else {
      this.toaster.error('Worker unsupported.', '', { life: 1000 });
      console.log('Cannot Start fileUploadProgressWorker : Worker unsupported.');
    }
  }

  private finishFileUploadProgressWorker() {
    if (this.fileUploadProgressWorker && !this.running) {
      this.fileUploadProgressWorker.postMessage({
        status: eParallelFileUploadWorkerStatus.Off,
      });
      this.fileUploadProgressWorker = null;
      console.log('Stopping fileUploadProgressWorker');
    }
  }

  private printFileLogTrack(activeUpload: QueuedFile) {
    console.log(`File : ${activeUpload.chunkFileModel.fileName} , 
      Size : ${((activeUpload.fileData?.size ?? 0) / 1000000).toFixed(2)} MB, 
      Start    Date: ${activeUpload.chunkFileModel.startDateTime?.toLocaleTimeString()}, 
      Chunks   Start Date: ${activeUpload.chunkFileModel.chunksUploadStartDateTime?.toLocaleTimeString()} , 
      Chunks   End   Date: ${activeUpload.chunkFileModel.chunksUploadEndDateTime?.toLocaleTimeString()} , 
      Complete Date: ${activeUpload.chunkFileModel.completeUploadDateTime?.toLocaleTimeString()}`);
  }

  public updateFileUploadSpeed(file: QueuedFile /*, rateValue: number = null*/) {
    const fileTimeElapsedSeconds =
      Math.abs(
        new Date().getTime() - file.chunkFileModel?.chunksUploadStartDateTime?.getTime() ?? 0,
      ) /
        1000 -
      file.accumulatedFailedTime;

    const fileSizeProgress = (file.fileData.size ?? 0) * ((file.progress ?? 0) / 100) ?? 0;
    const fileSpeedBytesXSec = (fileSizeProgress ?? 0) / (fileTimeElapsedSeconds ?? 0);

    let _uploadSpeed = fileSpeedBytesXSec ?? 0;

    if (isNaN(_uploadSpeed)) {
      _uploadSpeed = 0;
    }

    file.uploadNetworkRate = _uploadSpeed;

    let preview = this.findQueuedFilePreview(file);
    if (preview) {
      const speedMbitsxSecond = file.uploadNetworkRate / 125000;
      const speedKbitsxSecond = file.uploadNetworkRate / 125;

      if (speedMbitsxSecond > 1) {
        preview.uploadNetworkRate = `${speedMbitsxSecond.toFixed(2)} Mbit/s`;
      } else {
        preview.uploadNetworkRate = `${speedKbitsxSecond.toFixed(2)} Kbit/s`;
      }
    }

    if (isNaN(_uploadSpeed)) {
      _uploadSpeed = 0;
    }
  }

  makeLogs(
    userEmail: string,
    startDateTime: Date,
    endDateTime: Date,
    fileName: string,
    uploadStatus: string,
    missionId: string | undefined,
    fileSize: number,
    durationOfUpload: number,
    dataTransferRate: number,
    message: string,
  ) {
    const log = {
      User: userEmail,
      StartDateTime: startDateTime,
      EndDateTime: endDateTime,
      FileName: fileName,
      UploadStatus: uploadStatus,
      MissionID: missionId,
      FileSize: `${fileSize / (1024 * 1024 * 1024)} GB`,
      DurationOfUpload: `${durationOfUpload} seconds`,
      DataTransferRate: `${dataTransferRate} Mb/s`,
      Message: message,
      Type: 'Front',
    };

    this.appInsights.trackTrace({ message: JSON.stringify(log) });
  }
}
