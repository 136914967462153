import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ABP, ListService, TrackByService, PagedResultDto } from '@abp/ng.core';
import {
  AvailabilityService,
  CountriesService,
  StatesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  PilotDto,
  PilotUpdateDto,
} from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import {
  AvailabilityDto,
  GetAvailabilitiesInput,
  enumState,
  CountriesDto,
  StatesDto,
  GetCountryInput,
  GetStateInput,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { ColumnAction } from 'projects/flyguys/src/app/components/columns/components/column-actions/column-actions.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthServerService } from 'projects/flyguys/src/app/services/authServer.service';
import {
  FlyguysLocationFormComponent,
  Location,
  LocationForm,
  LocationSource,
} from '@flyguys/forms';
import {
  IdentityUserDto,
  IdentityUserService,
  IdentityUserUpdateDto,
  UserLoginInfomation,
} from 'projects/identity/proxy/src/lib';
import { Observable, combineLatest, of } from 'rxjs';
import { ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-pilot-info-card',
  templateUrl: './pilot-info-card.component.html',
  styleUrls: ['./pilot-info-card.component.scss'],
})
export class PilotInfoCardComponent implements OnInit {
  @ViewChild('modal') modal: TemplateRef<any>;
  @ViewChild(FlyguysLocationFormComponent) formlocation: FlyguysLocationFormComponent;

  @Input() pilot: PilotDto;

  @Input() userId?: string;

  availabilities: PagedResultDto<AvailabilityDto> = {
    items: [],
    totalCount: 0,
  };

  dataCountries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };

  dataStates: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };

  isModalBusy = false;

  isModalOpen = false;

  form: FormGroup;

  availabilitiesDict: { [id: string]: string } = {};
  pictureProfile: any;

  userLoginInformation: UserLoginInfomation;

  constructor(
    public readonly availabilityService: AvailabilityService,
    public readonly pilotService: PilotService,
    public readonly countriesService: CountriesService,
    public readonly statesService: StatesService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private authServerService: AuthServerService,
    private cd: ChangeDetectorRef,
    private identityService: IdentityUserService,
    private toaster: ToasterService,
  ) {}

  ngOnInit() {
    this.getPilotAvailabilityValues();
    this.buildForm();
    this.getProfilePicture();
    this.getLoginInformation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pilot) {
      this.getProfilePicture();
    }
    this.getCountries();
    this.getStates();
  }

  hideForm() {
    this.dialog.closeAll();
    this.form.reset();
  }

  showForm() {
    this.buildForm();

    this.dialog.open(this.modal, {
      width: '650px',
      disableClose: true,
      autoFocus: false,
    });

    this.validateForm(this.form);
  }

  buildForm() {
    this.form = this.fb.group({
      firstName: [this.pilot?.firstName ?? null, [Validators.required, Validators.maxLength(100)]],
      lastName: [this.pilot?.lastName ?? null, [Validators.required, Validators.maxLength(100)]],

      location: new LocationForm(),
      address: [this.pilot?.address ?? null, [Validators.maxLength(500)]],
      addressCity: [this.pilot?.addressCity ?? null],
      addressCountry: [this.pilot?.addressCountry ?? null],
      addressState: [this.pilot?.addressState ?? null],
      addressStreet: [this.pilot?.addressStreet ?? null],
      addressZipCode: [this.pilot?.addressZipCode ?? null],
      addressLat: [this.pilot?.addressLat ?? null],
      addressLng: [this.pilot?.addressLng ?? null],

      infoChecked: [this.pilot?.infoChecked ?? null],

      phone: [this.pilot?.phone ?? null, [Validators.required, Validators.maxLength(50)]],
      secondaryPhone: [this.pilot?.secondaryPhone ?? null, [Validators.maxLength(50)]],
      email: [
        this.pilot?.email ?? null,
        [Validators.required, Validators.maxLength(100), Validators.email],
        [this.emailExistValidator()],
        { updateOn: 'blur' },
      ],
      part107Number: [
        this.pilot?.part107Number ?? null,
        [Validators.required, Validators.maxLength(7), Validators.pattern('^[0-9]*$')],
      ],
      availabilityId: [this.pilot?.availabilityId ?? null, []],
      secondaryAddress: [this.pilot?.secondaryAddress ?? null, [Validators.maxLength(500)]],

      pilotExperienceLevelId: [this.pilot?.pilotExperienceLevelId ?? null, []],
      pilotStateId: [this.pilot?.pilotStateId ?? null, []],
      state: [this.pilot?.state ?? null, []],
      rating: [this.pilot?.rating ?? null, []],
      upcomingMissions: [this.pilot?.upcomingMissions ?? null, []],
      missionCount: [this.pilot?.missionCount ?? null, []],
      perfectMissionPercentage: [this.pilot?.perfectMissionPercentage ?? null, []],

      userId: [this.pilot?.userId ?? null, []],
      descriptionUser: [this.pilot?.descriptionUser ?? null, []],
      isPreferred: [this.pilot?.isPreferred ?? null, []],
      enableSMSReception: [this.pilot.enableSMSReception ?? false, []],
    });

    const countryId = this.dataCountries.items.find(
      c => c.description === this.pilot?.addressCountry,
    )?.id;
    const stateId = this.dataStates.items.find(
      c => c.countryId === countryId && c.description === this.pilot?.addressState,
    )?.id;

    if (this.pilot?.address) {
      //Control reference exists after dialog is open
      setTimeout(() => {
        this.formlocation.setData(
          {
            name: this.pilot?.address ?? null,
            city: this.pilot?.addressCity ?? null,
            zip: this.pilot?.addressZipCode ?? null,
            countryId: countryId ?? null,
            stateId: stateId ?? null,
          },
          LocationSource.INPUT,
        );
      }, 1000);

      this.validateForm(this.form);
    }
  }

  submitForm() {
    if (this.form.invalid) return;

    const pilotInput = this.form.value as any;
    const location = pilotInput.location;
    const pilotUserEmailInit = this.pilot.email;

    const input = { id: this.pilot.id, faa: pilotInput.part107Number };
    this.pilotService.validateExistingFaa(input).subscribe({
      next: response => {
        if (response) {
          this.form.controls['part107Number'].setErrors({ faaExists: true });
          return;
        }

        pilotInput.email = this.form.get('email').value;
        pilotInput.addressStreet = this.form.get('addressStreet').value;
        pilotInput.addressCity = this.form.get('addressCity').value;
        pilotInput.addressState = this.form.get('addressState').value;
        pilotInput.addressCountry = this.form.get('addressCountry').value;
        pilotInput.addressZipCode = this.form.get('addressZipCode').value;
        pilotInput.addressLat = this.form.get('addressLat').value;
        pilotInput.addressLng = this.form.get('addressLng').value;
        this.pilot = Object.assign(this.pilot, pilotInput);

        if (location?.countryId) {
          const country = this.dataCountries.items.find(
            c => c.id.toLowerCase().trim() === location?.countryId?.toLowerCase().trim(),
          );
          this.pilot.addressCountryId = location?.countryId;
          this.pilot.addressCountry = country?.description;
        }

        if (location?.stateId) {
          const state = this.dataStates.items.find(
            c => c.id.toLowerCase().trim() === location?.stateId.toLowerCase().trim(),
          );
          this.pilot.addressStateId = location?.stateId;
          this.pilot.addressState = state?.description;
        }
        if (location?.city) {
          this.pilot.addressCity = location?.city;
        }

        if (location?.zip) {
          this.pilot.addressZipCode = location?.zip;
        }

        this.isModalBusy = true;

        if (pilotUserEmailInit != pilotInput.email) {
          this.updatePilotWithLoginInfo(pilotUserEmailInit, pilotInput);
          return;
        }

        this.updatePilotInfo();
      },
    });
  }

  create() {
    this.showForm();
  }

  private updatePilotWithLoginInfo(pilotUserEmailInit: string, pilotInput) {
    if (pilotUserEmailInit != pilotInput.email) {
      combineLatest([
        this.identityService.findByEmail(pilotUserEmailInit),
        this.identityService.findByEmail(pilotInput.email),
      ]).subscribe({
        next: ([currenUserResult, newUserResult]) => {
          if (currenUserResult && !newUserResult) {
            const updateUserDTO: IdentityUserUpdateDto = this.mapIdentityUserUpdateDto(
              pilotInput,
              currenUserResult,
            );

            updateUserDTO.extraProperties.enableSMSReception = this.pilot.enableSMSReception;

            this.identityService.update(currenUserResult.id, updateUserDTO).subscribe({
              next: result => {
                if (result.email !== pilotInput.email) {
                  return;
                }

                this.updatePilotInfo();
              },
            });
          }
        },
      });
    }
  }

  private updatePilotInfo(): void {
    const request = this.pilotService.update(this.pilot.id, this.pilot);

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => {
          this.hideForm();
        }),
      )
      .subscribe({
        next: res => {
          this.getPilot();
        },
        error: err => {
          this.toaster.error(err);
        },
      });
  }

  private mapIdentityUserUpdateDto(
    pilotInput,
    currentLoginUserInfo: IdentityUserDto,
  ): IdentityUserUpdateDto {
    return {
      userName: pilotInput.email,
      name: currentLoginUserInfo.name,
      surname: currentLoginUserInfo.surname,
      email: pilotInput.email,
      phoneNumber: currentLoginUserInfo.phoneNumber,
      isActive: currentLoginUserInfo.isActive,
      shouldChangePasswordOnNextLogin: currentLoginUserInfo.shouldChangePasswordOnNextLogin,
      lockoutEnabled: currentLoginUserInfo.lockoutEnabled,
      roleNames: currentLoginUserInfo.roleNames,
      organizationUnitIds: [],
    } as IdentityUserUpdateDto;
  }

  private getPilotAvailabilityValues(): void {
    const pilotAvailabilityFilter = { state: enumState.Enabled } as GetAvailabilitiesInput;
    this.availabilityService
      .getList({
        ...pilotAvailabilityFilter,
      })
      .subscribe(res => {
        this.availabilities = res;
        for (let avb of this.availabilities.items) {
          this.availabilitiesDict[avb.id] = avb.description;
        }
      });
  }

  private getPilot(): void {
    this.pilotService.get(this.pilot.id).subscribe(result => {
      this.pilot = result;

      if (this.pilot.userId) {
        this.identityService.get(this.pilot.userId).subscribe(pilot => {
          if (pilot.extraProperties) {
            if (pilot.extraProperties.enableSMSReception != null) {
              this.pilot.enableSMSReception = pilot.extraProperties.enableSMSReception;
            }
          }
        });
      }
    });
  }

  private getProfilePicture() {
    if (this.pilot?.userId) {
      this.authServerService.GetProfilePicture(this.pilot?.userId).subscribe(picture => {
        this.pictureProfile = 'data:image/png;base64,' + picture.fileContent;
      });
    }
  }

  private getLoginInformation() {
    if (!this.userId) return;

    this.identityService.getUserLoginInformation(this.userId).subscribe(info => {
      this.userLoginInformation = info;
    });
  }

  columnActions() {
    let columnActions: ColumnAction[] = [
      {
        actionIcon: 'bi bi-pencil',
        abpPermission: 'pilotsService.Pilots.Edit && General.Common.Edit',
        action: { callAction: () => this.create() },
      },
    ];
    return columnActions;
  }

  getCountries() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetCountryInput;

    this.countriesService.getList(request).subscribe({
      next: (data: PagedResultDto<CountriesDto>) => {
        if (data?.totalCount !== 0) {
          this.dataCountries = data;
        }
      },
      error: err => console.log('Error on getting Countries: ' + JSON.stringify(err)),
    });
  }

  getStates() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetStateInput;

    this.statesService.getList(request).subscribe({
      next: (data: PagedResultDto<StatesDto>) => {
        if (data?.totalCount !== 0) {
          this.dataStates = data;
        }
      },
      error: err => console.log('Error on getting States: ' + JSON.stringify(err)),
    });
  }

  handleLocationChange(location: Location | null) {
    if (!location) return;

    const countryName =
      this.dataCountries.items.find(c => c.id === location.countryId)?.description ?? '';
    const stateName = this.dataStates.items.find(s => s.id === location.stateId)?.description ?? '';

    this.form.patchValue({
      address: location.name,
      addressCity: location.city,
      addressCountry: countryName,
      addressState: stateName,
      addressStreet: location.name,
      addressZipCode: location.zip,
      addressLat: location.lat,
      addressLng: location.lng,
    });
  }

  validateForm(form: FormGroup): boolean {
    if (!form.valid) {
      for (let i in form.controls) {
        if (form.controls[i].invalid) {
          this.markControlAsError(form.controls[i]);
        }
      }
      return false;
    }
    return true;
  }

  private markControlAsError(control) {
    control.markAsTouched();
    control.markAsDirty();
    control.setErrors({ incorrect: true });
  }

  emailExistValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(email => {
          if (
            email?.trim() == '' ||
            this.form.get('email').value?.toLowerCase()?.trim() ===
              this.pilot?.email?.toLowerCase()?.trim()
          ) {
            return of(null);
          }
          return this.identityService
            .findByEmail(email.trim())
            .pipe(map(exists => (exists ? { emailExists: true } : null)));
        }),
        first(),
      );
    };
  }
}
