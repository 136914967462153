import { Component, Input, OnInit } from '@angular/core';
import { ColumnAction } from '../../../components/columns/components/column-actions/column-actions.component';

@Component({
  selector: 'app-pilot-profile-questionnaire',
  templateUrl: './pilot-profile-questionnaire.component.html',
  styleUrls: ['./pilot-profile-questionnaire.component.scss']
})
export class PilotProfileQuestionnaireComponent implements OnInit {
  @Input() pilotId: string;

  questionnaire: questions[] = [];

  ngOnInit(): void {
    this.questionnaire = [
      { question: "1. How many total drone flight hours have you flown?", response: "500+"},
      { question: "2. Are you willing to share your digital flight logs with FlyGuys to verify flight hours?", response: "Yes"},
      { question: "3. Have you ever flown your drone commercially or for compensation", response: "Yes"},
      { question: "4. Do you have a flight experience in any of the following areas? Select all that apply.", response: "Photogrammetric Mapping"},
      { question: "5. Are you comfortable flying drones without GPS assistance?", response: "Yes"},
      { question: "6. Do you have a current FAA Part 107 Certification?", response: "Yes"},
      { question: "6a. If yes, what is your FAA Part 107 license number?", response: "4047459"},
      { question: "7. How many years have you been flying drones?", response: "5+"},
      { question: "8. Hoy many years have you held a commercial drone license?", response: "1-2"},
      { question: "9. Do you currently have any additional FAA Part 107 waivers?", response: "Yes"},
      { question: "10. What types of drone systems do you have experience using?", response: "Multi Rotor"},
      { question: "11. Have you ever served in the armed forces?", response: "No"},
    ];
  } 

  edit() {
    alert('work in progress!');
  }

  columnActions() {
    let columnActions:ColumnAction[] = [
      {
        actionIcon: 'bi bi-pencil',
        abpPermission: 'pilotsService.Pilots.Edit && General.Common.Edit',
        action: {callAction: () => this.edit()}
      }
    ];
    return columnActions;
  }
}

interface questions {
  question: string;
  response: string;
}