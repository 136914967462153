<h5 mat-dialog-title class="flg-modal flg-modal__title">Create a copy of Job ID: {{ jobId }}</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container p-0">
    <div class="row justify-content-center">
      <form [formGroup]="copyMissionForm" (ngSubmit)="onClickCopy()">
        <mat-form-field class="w-100">
          <mat-label>Mission Name</mat-label>
          <input matInput formControlName="missionName" />
          <mat-error *ngIf="copyMissionForm.get('missionName').hasError('required')">
            Please enter a mission name
          </mat-error>
        </mat-form-field>

        <div class="input-group justify-content-between">
          <mat-form-field class="flex-grow-1 pe-2">
            <mat-label>Capture Date</mat-label>
            <input matInput [matDatepicker]="datePicker" formControlName="captureDate" />
            <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="copyMissionForm.get('captureDate').hasError('required')">
              Please select a capture date
            </mat-error>
          </mat-form-field>

          <mat-form-field class="flex-grow-1 pe-2">
            <mat-label>Capture Time</mat-label>
            <input matInput type="time" formControlName="captureTime" />
            <mat-error *ngIf="copyMissionForm.get('captureTime').hasError('required')">
              Please select a capture time
            </mat-error>
          </mat-form-field>

          <mat-form-field class="flex-grow-1">
            <mat-label>Repeats</mat-label>
            <mat-select formControlName="repeats" [disabled]="true">
              <mat-option *ngFor="let data of datafrequency" [value]="data.value">
                {{ data.description }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="copyMissionForm.get('repeats').hasError('required')">
              Please select a repeat option
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field [floatLabel]="'always'" class="w-100 mb-2">
          <mat-label>Capture Date must be</mat-label>
          <mat-select formControlName="captureDateMust">
            <mat-option [value]=""> </mat-option>
            <mat-option *ngFor="let data of dataCaptureMust" [value]="data.value">
              {{ data.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-label class="copy-modal-subtitle">Items to Copy</mat-label>

        <div class="row mt-2">
          <div class="col-12">
            <mat-checkbox formControlName="client" [disabled]="true"
              >Client Information</mat-checkbox
            >
            <div class="row">
              <div class="col-1 max-width-0"></div>
              <div class="col-11">
                <mat-checkbox class="mb-2" formControlName="additionalContacts"
                  >Additional Contacts</mat-checkbox
                >
              </div>
            </div>
          </div>

          <div class="col-12">
            <mat-checkbox
              class="mb-2"
              formControlName="orderRequest"
              (change)="onOrderRequestChange()"
              >Order Request</mat-checkbox
            >
            <div class="row">
              <div class="col-1 max-width-0"></div>
              <div class="col-11">
                <mat-checkbox
                  class="mb-2"
                  formControlName="orderPriority"
                  (change)="onOrderRequestChildChange()"
                  >SLA</mat-checkbox
                >
              </div>
            </div>

            <div class="row">
              <div class="col-1 max-width-0"></div>
              <div class="col-11">
                <mat-checkbox
                  class="mb-2"
                  formControlName="assetOrderNumber"
                  (change)="onOrderRequestChildChange()"
                  >Asset / Order Number</mat-checkbox
                >
              </div>
            </div>

            <div class="row">
              <div class="col-1 max-width-0"></div>
              <div class="col-11">
                <mat-checkbox
                  class="mb-2"
                  formControlName="missionSummary"
                  (change)="onOrderRequestChildChange()"
                  >Mission Summary</mat-checkbox
                >
              </div>
            </div>

            <div class="row">
              <div class="col-1 max-width-0"></div>
              <div class="col-11">
                <mat-checkbox
                  class="mb-2"
                  formControlName="siteInformation"
                  (change)="onOrderRequestChildChange()"
                  >Site Information</mat-checkbox
                >
              </div>
            </div>

            <div class="row">
              <div class="col-1 max-width-0"></div>
              <div class="col-11">
                <mat-checkbox
                  class="mb-2"
                  formControlName="deliverableDueDate"
                  (change)="onOrderRequestChildChange()"
                  >Deliverable Due Date, Deliverable Notes, Data Uploading Instructions & Additional
                  Notes</mat-checkbox
                >
              </div>
            </div>
          </div>

          <div class="col-12">
            <mat-checkbox class="mb-2" formControlName="productsPackages"
              >Products & Packages</mat-checkbox
            >
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button
            mat-flat-button
            class="outline-button me-2"
            type="button"
            (click)="onClickClose()"
          >
            Cancel
          </button>
          <button
            mat-flat-button
            color="primary"
            class="common-button"
            type="submit"
            [disabled]="!IsValid()"
            [class.disabled-button]="!IsValid()"
          >
            {{ 'missionsService::Copy' | abpLocalization }}
          </button>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
