<div class="container">
  <div class="days">
    {{
      dayDueDates !== null && dayDueDates !== undefined && showDays ? dayDueDates + ' days' : '--'
    }}
  </div>
  <div class="triangle">
    <i
      *ngIf="showTriangle && dayDueDates !== null && dayDueDates !== undefined && showDays"
      class="fa fa-exclamation-triangle"
      aria-hidden="true"
      [ngClass]="getIconClass()"
    ></i>
  </div>
</div>
