import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OrderFormDeliverableModel } from '../../components/orders/model/order-form-deliverable.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FileDescriptorService, FileInfoDto } from '@volo/abp.ng.file-management/proxy';
import { LoadingOverlayService } from 'projects/flyguys/src/app/services/loading/loading.service';
import { finalize, switchMap } from 'rxjs';
import { downloadBlob } from '@abp/ng.core';
import { MessageSucessComponent } from 'projects/flyguys/src/app/components/common/message/message.success.component';
import { OrderFormDeliverableFieldModel } from '../../components/orders/model/order-form-deliverable-field.model';
import { DeliverableAttribute } from './deliverable-attribute.interface';
import { AttributeTypesEnum } from '../../pilot-sourcing/models/attribute-types.enum';

@Component({
  selector: 'app-deliverable-attributes-list',
  templateUrl: './deliverable-attributes-list.component.html',
  styleUrls: ['./deliverable-attributes-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliverableAttributesListComponent implements OnInit {
  public readonlyMode: boolean;
  maxFileSizeFiles: number = 200 * 1024 * 1024;

  deliverableAttributes: Array<DeliverableAttribute> = [];
  readonly DEFAULT_MAX_LENGTH = 100;

  public get attributeTypes(): typeof AttributeTypesEnum {
    return AttributeTypesEnum;
  }

  constructor(
    public dialogRef: MatDialogRef<DeliverableAttributesListComponent>,
    public loadingService: LoadingOverlayService,
    public readonly fileDescriptorService: FileDescriptorService,
    public dialogService: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      deliverableList: OrderFormDeliverableModel[];
      quatity: number;
      isEdition: boolean;
      readonly: boolean;
    }
  ) {
    this.readonlyMode = data.readonly;
  }

  ngOnInit() {
    this.createInitialModel();
  }

  private createInitialModel(): void {
    this.data.deliverableList.forEach((deliverable, index) => {
      let deliverableAttribute = <DeliverableAttribute>{
        productId: deliverable.productId,
        deliverableName: deliverable.deliverableName,
        quantity: deliverable.quantity,
        sameConfiguration: this.data.isEdition ? deliverable.sameConfiguration : true,
        fields: deliverable.fields.map(x => {
          let attr = { ...x };
          attr.fieldControlName = `ctrl-${attr.id}-${deliverable.productId}`;

          if (this.data.isEdition) {
            attr.stringValue = x.stringValue;

            if (attr.typeCode == AttributeTypesEnum.Bool)
              attr.boolValue = attr.stringValue == true.toString();

            if (attr.typeCode == AttributeTypesEnum.Number)
              attr.numericValue = new Number(attr.stringValue);
          } else {
            if (attr.defaultValue) {
              if (attr.typeCode == AttributeTypesEnum.Bool)
                attr.boolValue = attr.defaultValue == true.toString();
              else if (attr.typeCode == AttributeTypesEnum.Number)
                attr.numericValue = new Number(attr.defaultValue);
              else attr.stringValue = x.defaultValue;
            }
          }

          return attr;
        }),
      };

      deliverableAttribute.detailedAttributes = [];

      for (let i = 0; i < deliverableAttribute.quantity; i++) {
        if (this.data.isEdition) {
          if (deliverable.detailedAttributes && deliverable.detailedAttributes.length > i) {
            let fields = [...deliverable.detailedAttributes[i]];
            let fieldsCopy = fields.map((field, subIndex) => ({
              ...field,
              fieldControlName: `ctrl-${index}-${i}-${field.id}-${deliverable.productId}`,
            }));
            deliverableAttribute.detailedAttributes.push(fieldsCopy);
          } else {
            let newArray = deliverableAttribute.fields.map((x, subIndex) => {
              let attr = { ...x };
              attr.fieldControlName = `ctrl-${index}-${i}-${x.id}-${deliverable.productId}`;

              if (attr.defaultValue) {
                if (attr.typeCode == AttributeTypesEnum.Bool)
                  attr.boolValue = attr.defaultValue == true.toString();
                else if (attr.typeCode == AttributeTypesEnum.Number)
                  attr.numericValue = new Number(attr.defaultValue);
                else attr.stringValue = x.defaultValue;
              }

              return attr;
            });

            deliverableAttribute.detailedAttributes.push(newArray);
          }
        } else {
          let newArray = deliverableAttribute.fields.map((x, subIndex) => {
            let attr = { ...x };
            attr.fieldControlName = `ctrl-${index}-${i}-${x.id}-${deliverable.productId}`;
            attr.stringValue = '';

            if (attr.defaultValue) {
              if (attr.typeCode == AttributeTypesEnum.Bool)
                attr.boolValue = attr.defaultValue == true.toString();
              else if (attr.typeCode == AttributeTypesEnum.Number)
                attr.numericValue = new Number(attr.defaultValue);
              else attr.stringValue = x.defaultValue;
            }
            return attr;
          });

          deliverableAttribute.detailedAttributes.push(newArray);
        }
      }

      this.deliverableAttributes.push(deliverableAttribute);
    });
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  saveDeliverableList(): void {
    this.deliverableAttributes.forEach(deliverable => {
      let boolAttribute = deliverable.fields.filter(x => x.typeCode == AttributeTypesEnum.Bool);
      boolAttribute.forEach(attr => {
        attr.stringValue = attr.boolValue.toString();
      });

      let numericAttributes = deliverable.fields.filter(
        x => x.typeCode == AttributeTypesEnum.Number
      );
      numericAttributes.forEach(attr => {
        if (attr.numericValue !== null && attr.numericValue !== undefined)
          attr.stringValue = attr.numericValue.toString();
        else attr.stringValue = '0';
      });

      deliverable.detailedAttributes.forEach(fields => {
        let boolAttribute = fields.filter(x => x.typeCode == AttributeTypesEnum.Bool);
        boolAttribute.forEach(attr => {
          attr.stringValue = attr.boolValue.toString();
        });

        let numericAttribute = fields.filter(x => x.typeCode == AttributeTypesEnum.Number);
        numericAttribute.forEach(attr => {
          if (attr.numericValue !== null && attr.numericValue !== undefined)
            attr.stringValue = attr.numericValue.toString();
          else attr.stringValue = '0';
        });
      });
    });

    this.dialogRef.close(this.deliverableAttributes);
  }

  public isControlInvalid(form: NgForm, controlName: string): boolean {
    return form?.controls[controlName]?.invalid ?? false;
  }

  public thereAreMissingFiles(): boolean {
    let missingValues = false;
    for (let dv of this.deliverableAttributes) {
      if (dv.sameConfiguration) {
        missingValues = !!dv.fields.find(
          x =>
            (x.typeCode == AttributeTypesEnum.File || x.typeCode == AttributeTypesEnum.Kml) &&
            x.isRequired &&
            !x.stringValue
        );

        if (missingValues) break;
      } else {
        for (let fields of dv.detailedAttributes) {
          missingValues = !!fields.find(
            x =>
              (x.typeCode == AttributeTypesEnum.File || x.typeCode == AttributeTypesEnum.Kml) &&
              x.isRequired &&
              !x.stringValue
          );

          if (missingValues) break;
        }

        if (missingValues) break;
      }
    }

    return missingValues;
  }

  onFileSelected(files: File[], field: OrderFormDeliverableFieldModel) {
    if (files[0].size > this.maxFileSizeFiles) {
      this.dialogService.open(MessageSucessComponent, {
        data: {
          title: 'Maximum File Size exceeded',
          message: `File ${files[0].name} exceeds the maximum allowed size (${this.formatFileSize(
            this.maxFileSizeFiles
          )}).`,
        },
        disableClose: true,
        width: '475px',
      });
      const indexFile = files.indexOf(files[0]);
      if (indexFile >= 0) {
        files.splice(indexFile, 1);
      }
    } else {
      this.uploadFile(files[0], field);
    }
  }

  uploadFile(file: File, field: OrderFormDeliverableFieldModel): void {
    if (!file) {
      console.error('Error Uploading file.No file selected');
      return;
    }

    field.loadingFile = true;
    const formData = new FormData();
    formData.append('fileList', file);

    this.fileDescriptorService
      .uploadAttachMentsFolder(
        'Deliverables_Attributes',
        'Attribute_' + field.fieldControlName,
        formData
      )
      .subscribe(
        (res: FileInfoDto[]) => {
          if (res[0]?.id && res[0]?.fileAttachmentUrl) {
            field.stringValue = res[0].id;
            field.fileValue = res[0].name;
          }

          field.loadingFile = false;
        },
        error => {
          field.loadingFile = false;
        }
      );
  }

  downloadDocument(field: OrderFormDeliverableFieldModel) {
    this.loadingService.showOverlay();

    this.fileDescriptorService
      .getDownloadToken(field.stringValue)
      .pipe(
        switchMap(({ token }) => this.fileDescriptorService.downloadFile(field.stringValue, token)),
        finalize(() => this.loadingService.hideOverlay())
      )
      .subscribe(result => {
        downloadBlob(result, field.fileValue);
      });
  }

  onFileRemoved(field: OrderFormDeliverableFieldModel): void {
    field.stringValue = '';
    field.fileValue = '';
  }

  private formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }
}
