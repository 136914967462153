import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AddressesDto,
  ContactWithNavigationPropertiesDto,
  ContactsCreateDto,
  ContactsDto,
  ContactsExcelDownloadDto,
  ContactsUpdateDto,
  GetContactInput,
} from '../../basics/models';
import type { DownloadTokenResultDto, LookupRequestDto, LookupUserDto } from '../../shared/models';
import { CustomerContactsUpdateDto } from 'projects/customers-service/src/lib/proxy/customers-service/basics/models';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  apiName = 'missionsService';

  create = (input: ContactsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/contact',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/contact/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/contact/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/contact/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: ContactsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/contact/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetContactInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ContactsDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/contact',
        params: {
          filterText: input.filterText ?? input.filter,
          firstName: input.firstName,
          lastName: input.lastName,
          phone: input.phone,
          email: input.email,
          contactTypeId: input.contactTypeId,
          levelCommunicationId: input.levelCommunicationId,
          contactMethodId: input.contactMethodId,
          state: input.state,
          contactTypeDescription: input.contactTypeDescription,
          levelCommunicationDescription: input.levelCommunicationDescription,
          contactMethodDescription: input.contactMethodDescription,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: ContactsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactsDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/contact/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  updateState = (id: string, input: CustomerContactsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactsDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/contact/update-state/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getUserLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupUserDto<string>>>(
      {
        method: 'GET',
        url: '/api/missions-service/contact/user-lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getContactByUserId = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/contact/user-contact/${userId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getWithNavigationProperties = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactWithNavigationPropertiesDto>(
      {
        method: 'GET',
        url: `/api/missions-service/contact/contact-user/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getListWithNavigationProperties = (input: GetContactInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ContactWithNavigationPropertiesDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/contact/contacts-user',
        params: {
          filterText: input.filterText,
          firstName: input.firstName,
          lastName: input.lastName,
          phone: input.phone,
          email: input.email,
          contactTypeId: input.contactTypeId,
          levelCommunicationId: input.levelCommunicationId,
          contactMethodId: input.contactMethodId,
          state: input.state,
          contactTypeDescription: input.contactTypeDescription,
          levelCommunicationDescription: input.levelCommunicationDescription,
          contactMethodDescription: input.contactMethodDescription,
          userId: input.userId,
          descriptionUser: input.descriptionUser,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getContactsByListOfIds = (contactIds: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContactsDto[]>(
      {
        method: 'POST',
        url: `/api/missions-service/contact/get-by-list-of-ids`,
        body: { contactIds },
      },
      { apiName: this.apiName, ...config },
    );

  getCustomerBillingAddress = (customerId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AddressesDto>(
      {
        method: 'GET',
        url: `/api/missions-service/contact/get-customer-address/${customerId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getCustomerContactByType = (
    customerId: string,
    contactTypeId: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, ContactsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/contact/get-customer-contact-by-type/${contactTypeId}/${customerId}`,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
