import { ListService } from '@abp/ng.core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DirectoryContentDto, FileSelected, FilterMission, SelectedAllDto } from '@volo/abp.ng.file-management/proxy';
import { NavigatorService } from '../../services/navigator.service';
import { OptionsFilter } from '../../models/folder-permissions/optionsFilter.model';

@Component({
  selector: 'abp-file-management-folder-panel',
  templateUrl: './file-management-folder-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListService],
})
export class FileManagementFolderPanelComponent {
  @Output() contentUpdate = new EventEmitter<DirectoryContentDto[]>();
  @Output() filesSelectedChange = new EventEmitter<FileSelected[]>();
  @Output() selectAllChange = new EventEmitter<SelectedAllDto>();

  @Input() filesSelected: FileSelected[];
  @Input() hideDirectoryView: boolean;
  @Input() showOptions = false;
  @Input() missionFilter: FilterMission = undefined;
  @Input() optionsFilter: OptionsFilter = null;
  @Input() onlyViewDownload: boolean;

  constructor(public navigator: NavigatorService) { }
}
