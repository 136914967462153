import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eCoreServiceRouteNames } from '../enums/route-names';

export const LOOKUPS_LEVEL_COMMUNICATIONS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/core-service/level-communication',
        parentName: eCoreServiceRouteNames.CoreService,
        name: 'CoreService::Menu:LevelCommunication',
        layout: eLayoutType.application,
        requiredPolicy: 'CoreService.LevelCommunication.ShowMenu',
        iconClass: 'fas fa-tty',
      },
    ]);
  };
}
