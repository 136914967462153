import { AfterViewInit, Component, Input, ViewChild, OnDestroy } from '@angular/core';
import { OrderFormSiteModel } from '../model/order-form-site.model';
import { FlyguysMapComponent, FlyguysMapMarker } from '@flyguys/map';
import { formatTimeToAmPm } from '../../../utils/time';
import { Subscription } from 'rxjs';
import { MapReloadService } from '../services/map-reload.service';

@Component({
  selector: 'app-mission-overview',
  templateUrl: './mission-overview.component.html',
  styleUrls: ['./mission-overview.component.scss'],
})
export class MissionOverviewComponent implements AfterViewInit, OnDestroy {
  @ViewChild(FlyguysMapComponent) map: FlyguysMapComponent;
  @Input() mission!: OrderFormSiteModel;

  private subscriptions = new Subscription();

  columns = [
    { prop: 'customerDescription', name: 'Contact from' },
    { prop: 'name', name: 'Name' },
    { prop: 'lastname', name: 'Last Name' },
    { prop: 'email', name: 'Email' },
    { prop: 'number', name: 'Number' },
    { prop: 'shareData', name: 'Share Data' },
    { name: 'Actions' },
  ];

  constructor(private mapReloadService: MapReloadService) {
    const subsMapsReload = this.mapReloadService.reloadObservable.subscribe(() => {
      this.updateMap();
    });

    this.subscriptions.add(subsMapsReload);
  }

  get captureTime(): string {
    return formatTimeToAmPm(this.mission.captureTime);
  }

  private updateMap(): void {
    if (this.mission.kmlFiles?.length > 0) {
      setTimeout(() => {
        this.map.loadKMLLayer(this.mission.kmlFiles[0]?.path);
        this.updateMapMarker();
      }, 500);
    } else {
      this.removeKmlFile();
    }
  }

  ngAfterViewInit(): void {
    this.updateMap();
  }

  removeKmlFile(): void {
    setTimeout(() => {
      this.map.removeKMLLayer();
      this.updateMapMarker();
    }, 500);
  }

  updateMapMarker() {
    const currentLat = this.mission.loclatitude;
    const currentLng = this.mission.loclongitude;
    if (currentLat && currentLng) {
      this.map.clearMarkers();
      const newMarker: FlyguysMapMarker = {
        id: 1,
        lat: parseFloat(currentLat),
        lng: parseFloat(currentLng),
      };
      this.map.addMarker(newMarker);
    }
  }

  hasKmlFile(): boolean {
    return this.mission.kmlFiles?.length > 0;
  }

  hasAttachmentsFiles(): boolean {
    return this.mission.filesAttachments?.length > 0;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
