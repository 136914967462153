import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetQuestionInput,
  MissionSLADto,
  QuestionsCreateDto,
  QuestionsDto,
  QuestionsExcelDownloadDto,
  QuestionsUpdateDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import {
  GetSLASInput,
  SLADto,
  SlaCalculationInput,
  SlaMilestoneNames,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SlaService {
  apiName = 'missionsService';
  recalculateSLASubject = new Subject<string>();
  customSlaChange = new Subject<MissionSLADto[]>();

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<SLADto>>(
      {
        method: 'GET',
        url: `/api/missions-service/sla/get-by-customer-id/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  calculationSLA = (slaCalculationInput: SlaCalculationInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionSLADto>>(
      {
        method: 'POST',
        url: '/api/missions-service/sla/calculate-sla',
        body: slaCalculationInput,
      },
      { apiName: this.apiName, ...config },
    );

  getSLAInformation = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/missions-service/sla/get-sla-information?missionId=${missionId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetSLASInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<SLADto>>(
      {
        method: 'GET',
        url: '/api/missions-service/sla',
        params: {
          name: input.filterText,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: SLADto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SLADto>(
      {
        method: 'PUT',
        url: `/api/missions-service/sla/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );
  create = (input: SLADto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SLADto>(
      {
        method: 'POST',
        url: '/api/missions-service/sla',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getMissionSLAByMissionId = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionSLADto>>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-sla/by-mission-id/${missionId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getSlaMilestoneNames = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<SlaMilestoneNames>>(
      {
        method: 'GET',
        url: `/api/missions-service/sla-milestone-names`,
        params: {
          maxResultCount: 100,
          isPaginated: false,
        },
      },
      { apiName: this.apiName, ...config },
    );

  calculateSLAByMissionId = (missionId: string, slaId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionSLADto>>(
      {
        method: 'GET',
        url: `/api/missions-service/sla/calculate-sla-by-mission/${missionId}/${slaId}`,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
