<abp-page [title]="'missionsService::Revenue' | abpLocalization">
  <abp-page-toolbar-container class="col">
    <div class="text-lg-end mb-2">
      <button class="btn btn-primary btn-sm" type="button" *ngIf="action">
        <lib-action-form
          [row]="{ title: 'Add Revenue', missionId: missionId }"
          [gridAction]="action"
          [isMobile]="false"
          [config]="{ modalWidth: '900px', showCloseOnModal: true }"
          (parentEvent)="reloadPilotSourcing()"
        >
          <span style="color: white !important" class="d-flex">
            <mat-icon>add</mat-icon>
            Add New
          </span>
        </lib-action-form>
      </button>
    </div>
  </abp-page-toolbar-container>

  <div class="card">
    <div class="card-body">
      <ngx-datatable *ngIf="orderSummary"
        [rows]="orderSummary.details"
        [columns]="columns"
        [scrollbarH]="true"
        [footerHeight]="false"
        [headerHeight]="55"
        [rowHeight]="50"
        [footerHeight]="50"
        class="material flg-simple-table"
        default
      >
        <ngx-datatable-column
          name="Revenue Type"
          prop="itemType"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.itemType || 'N/A' }}

          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Description"
          prop="itemDescription"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.revenueId"> {{ revenueTypes[row.revenueTypeId] }} </span>
            <span *ngIf="!row.revenueId">{{ row.itemDescription }} </span>
          </ng-template>
        
        
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Quantity"
          prop="quantity"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.quantity }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Unit Price"
          prop="price"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'editing-row'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            ${{ row.unitPrice | number : '1.2-2' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="Total"
          prop="total"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            $ {{ row.total | number : '1.2-2' }}
          </ng-template>
          
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'AbpUi::Actions' | abpLocalization"
          [maxWidth]="150"
          [width]="150"
          [sortable]="false"
        >
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <div class="flg-grid-table-actions">
              <div ngbDropdown container="body" class="d-inline-block"></div>

              <lib-action-form
                *ngIf="action && row.revenueId"
                [row]="{
                  id: row.revenueId,
                  defItemPrice: row.unitPrice,
                  title: 'Edit Revenue',
                  defUnits: row.quantity,
                  defTotalPrice: row.total,
                  defDeliverable: row.deliverablesId ?? '',
                  defRevenueId: row.revenueTypeId,
                  missionId: missionId,
                  parentId: row.revenueId,
                  serviceTypeId: row.serviceTypeId
                }"
                [gridAction]="action"
                [isMobile]="false"
                [config]="{ modalWidth: '900px', showCloseOnModal: true }"
                (parentEvent)="reloadPilotSourcing()"
              >
                <mat-icon class="material-symbols-rounded pointer">edit</mat-icon>
              </lib-action-form>
              <ng-container *ngIf="!row.revenueId">
                  <lib-management-actions
                    *ngFor="let action of actions"
                    class="pointer single-action"
                    gridName="Revenues"
                    [rowData]="{
                      title: 'Edit Revenue',
                      defItemPrice: row.unitPrice,
                      defUnits: row.quantity,
                      packageId: row.packageId,
                      productId: row.productId,
                      missionId: missionId,
                      unitPrice: row.unitPrice
                    }"
                    uniqueAction="{{ action.code }}"
                    (parentEvent)="reloadPilotSourcing()"
                    matTooltip="{{ action.helperText }}"
                  >
                </lib-management-actions>
              </ng-container>              
              
              <ng-container *ngIf="row.revenueId">
                <a class="ng-star-inserted"
                *abpPermission="'missionsService.MissionFinancialRevenues.Delete && General.Common.Delete'"
                matTooltipPosition="left" role="button"
                (click)="delete(row)" matTooltip="{{ 'AbpUi::Delete' | abpLocalization }}">
                  <span class="material-symbols-rounded mr-8px">delete</span>
                </a>
              </ng-container>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer *ngIf="false">
          <ng-template ngx-datatable-footer-template>
            <div class="row-total">
              <div class="cell-total">
                <div class="box">
                  <div class="typography-total">
                    <div class="body-2">Total</div>
                  </div>
                </div>
              </div>
              <div class="cell-total-3">
                <div class="box">
                  <div class="typography">
                    <div class="subtitle-2">${{ orderSummary.total | number : '1.2-2' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</abp-page>

<app-responsive-grid
  class="d-none"
  gridName="Revenues"
  (action)="setAction($event)"
></app-responsive-grid>
