<div [ngSwitch]="typeColumn">
    <div *ngSwitchCase="'text'" [ngClass]="actualRules?.containerClass" [ngStyle]="actualRules?.containerStyle">
        <span [ngClass]="actualRules?.valueClass" [ngStyle]="actualRules?.valueStyle">{{actualRules?.softValue || actualRules?.columnValue}}</span>
    </div>

    <div *ngSwitchCase="'icon'" [ngClass]="actualRules?.containerClass" [ngStyle]="actualRules?.containerStyle">
        <i [ngClass]="actualRules?.valueClass" [ngStyle]="actualRules?.valueStyle" aria-hidden="true"></i>
    </div>

    <div *ngSwitchCase="'rating'">
        <ng-container *ngFor="let starType of getStars(columnValue)">
            <span *ngIf="starType === 'filled'" class="material-symbols-rounded filled">star</span>
            <span *ngIf="starType === 'half'" class="material-symbols-rounded filled">star_half</span>
            <span *ngIf="starType === 'empty'" class="material-symbols-rounded empty">star</span>
        </ng-container>
    </div>

    <div *ngSwitchDefault [ngClass]="actualRules?.containerClass" [ngStyle]="actualRules?.containerStyle">
        <span [ngClass]="actualRules?.valueClass" [ngStyle]="actualRules?.valueStyle">{{actualRules?.softValue || actualRules?.columnValue}}</span>
    </div>
</div>