<ng-container *ngIf="!onlyViewDownload">
  <!-- <abp-button
    class="d-grid"
    [buttonId]="buttonId"
    iconClass="fa fa-upload me-2"
    buttonClass="btn btn-primary mb-2"
    [disabled]="!canUpload()"
    >{{ 'FileManagement::UploadFiles' | abpLocalization }}</abp-button
  > -->
  <!-- <abp-button
    class="d-grid"
    *abpPermission="directoryCreatePermission"
    iconClass="fa fa-folder-plus me-2"
    buttonClass="btn btn-light btn-outline-primary mb-4"
    (click)="handleOnCreate(currentSelectedNode)"
    [disabled]="!canCreate()"
    >{{ 'FileManagement::CreateFolder' | abpLocalization }}
  </abp-button> -->
  <div class="d-flex justify-content-center align-items-center h-100">
    <abp-button
      *abpPermission="directoryCreatePermission"
      buttonClass="d-flex justify-content-center align-items-center attribute-btn"
      (click)="handleOnCreate(currentSelectedNode)"
      [disabled]="!canCreate()"
      ><span class="material-symbols-rounded me-2"> add </span>
      {{ 'FileManagement::CreateFolder' | abpLocalization }}
    </abp-button>
  </div>
</ng-container>

<abp-create-folder-modal
  [(visible)]="folderCreateModal"
  [missionId]="missionFilter?.missionId"
  [deliverableId]="missionFilter?.deliverableId"
  [captureId]="missionFilter?.captureId"
  [orderDetailId]="missionFilter?.orderDetailId"
  [rootId]="rootId"
>
</abp-create-folder-modal>