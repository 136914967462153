import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { timeout, timer } from 'rxjs';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  logoutTimeout:number = 3;

  constructor(private oAuthService: OAuthService) {}

  ngOnInit(): void {
    timer(1000, 1000).subscribe(() => {
      this.updateTimeout();
    });
  }

  logout() {
    this.oAuthService.logOut();
  }

  updateTimeout() {
    if(this.logoutTimeout > 0) this.logoutTimeout -= 1;
    if (this.logoutTimeout == 0) this.logout();

  }
}
