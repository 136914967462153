export class ChunkModel {

    start: number;
    end: number;
    chunkNumber: number;
    identifier: string;
    success: boolean;

    constructor() {
        this.start = 0;
        this.end = 0;
        this.chunkNumber = 0;
    }
}
