import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class ImpersonateService {
    apiName = 'AbpAccountPublic';
    action ='Impersonation'
    client = 'Angular'
    getToken(userToImpersonate:string) {
        const url = '/connect/token';
        const accesToken = localStorage.getItem('access_token');
        if (accesToken) {
            const body = new URLSearchParams({
                'grant_type': this.action,
                'client_id': this.client,
                'access_token': accesToken,
                'UserId': userToImpersonate
            });

            const headers = {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
            };
            return this.restService.request<any, any>({
                method: 'POST',
                url,
                headers,
                body,
                responseType: 'json',

            }, { apiName: 'AbpAccountPublic' });
        }


    }

    constructor(private restService: RestService) { }
}
