import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetPilotInventorieInput, PilotInventoriesCreateDto, PilotInventoriesDto, PilotInventoriesExcelDownloadDto, PilotInventoriesUpdateDto } from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class PilotInventoriesService {
  apiName = 'pilotsService';
  

  create = (input: PilotInventoriesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotInventoriesDto>({
      method: 'POST',
      url: '/api/pilots-service/pilot-inventorie',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/pilots-service/pilot-inventorie/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotInventoriesDto>({
      method: 'GET',
      url: `/api/pilots-service/pilot-inventorie/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/pilots-service/pilot-inventorie/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetPilotInventorieInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotInventoriesDto>>({
      method: 'GET',
      url: '/api/pilots-service/pilot-inventorie',
      params: { filterText: input.filterText, equimentId: input.equimentId, pilotId: input.pilotId, fAARegistration: input.fAARegistration, state: input.state, status: input.status, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: PilotInventoriesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/pilots-service/pilot-inventorie/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });

  getPilotLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/pilots-service/pilot-inventorie/pilot-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: PilotInventoriesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotInventoriesDto>({
      method: 'PUT',
      url: `/api/pilots-service/pilot-inventorie/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
