import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { missionData } from '@flyguys/map';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-infoMissionModal-modal',
  templateUrl: './info-mission-modal.component.html',
  styleUrls: ['./info-mission-modal.component.scss'],
})
export class InfoMissionModalComponent implements OnInit {
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: missionData,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.spinner.show();
    if (this.data) {
      this.spinner.hide();
      this.loading = true;
    }
  }
}
