<mat-card class="card">
    <div class="card-body">
        <h3>{{ 'pilotsService::OverallRating' | abpLocalization }}</h3>
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 col">
                <label>{{ 'pilotsService::Communication' | abpLocalization }}</label>   
                <app-column-stylizer [columnValue]="4" [typeColumn]="'rating'" [columnRules]=""></app-column-stylizer>
            </div>
            <div class="col-12 col-md-4 col-lg-3 col">
                <label>{{ 'pilotsService::Timeless' | abpLocalization }}</label>   
                <app-column-stylizer [columnValue]="3.7" [typeColumn]="'rating'" [columnRules]=""></app-column-stylizer>
            </div>
            <div class="col-12 col-md-4 col-lg-3 col">
                <label>{{ 'pilotsService::ScopeOfWork' | abpLocalization }}</label>   
                <app-column-stylizer [columnValue]="5" [typeColumn]="'rating'" [columnRules]=""></app-column-stylizer>
            </div>
            <div class="col-12 col-md-4 col-lg-3 col">
                <label>{{ 'pilotsService::QualityOfData' | abpLocalization }}</label>   
                <app-column-stylizer [columnValue]="3" [typeColumn]="'rating'" [columnRules]=""></app-column-stylizer>
            </div>
        </div>
    </div>
</mat-card>