import { mapEnumToOptions } from '@abp/ng.core';

export enum assignedFilterEnum {
  MyMission = 0,
  MyTeam = 1,
  All = 2
}

export const assignedFilterDisplayNames: Record<assignedFilterEnum, string> = {
  [assignedFilterEnum.MyMission]: 'My current assignments',
  [assignedFilterEnum.MyTeam]: 'Previously worked',
  [assignedFilterEnum.All]: 'Everything'
};

export const EnumRecurreceOptions = mapEnumToOptions(assignedFilterEnum);