<abp-file-management-modal title="Preview" [fileName]="fileName" [parent]="self" [static]="true">
  <image-viewer
    [id]="'preview'"
    [imageUrl]="imageUrl"
    allowClick="true"
    (onPictureClicked)="handleOnPictureClicked()"
    customClass="mt-15"
  >
  </image-viewer>
</abp-file-management-modal>
