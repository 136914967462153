import { ModuleWithProviders, NgModule } from '@angular/core';
import { NOTIFICATIONS_SERVICE_ROUTE_PROVIDERS } from './providers/route.provider';
import { BASICS_DEVICES_ROUTE_PROVIDER } from './providers/devices-route.provider';
import { BASICS_EMAIL_NOTIFICATION_CONTENTS_ROUTE_PROVIDER } from './providers/email-notification-contents-route.provider';
import { BASICS_HUB_NOTIFICATION_CONTENTS_ROUTE_PROVIDER } from './providers/hub-notification-contents-route.provider';
import { BASICS_SMS_NOTIFICATIONS_ROUTE_PROVIDER } from './providers/sms-notifications-route.provider';
import { BASICS_WEB_NOTIFICATION_CONTENTS_ROUTE_PROVIDER } from './providers/web-notification-contents-route.provider';

@NgModule()
export class NotificationsServiceConfigModule {
  static forRoot(): ModuleWithProviders<NotificationsServiceConfigModule> {
    return {
      ngModule: NotificationsServiceConfigModule,
      providers: [
        NOTIFICATIONS_SERVICE_ROUTE_PROVIDERS,
        BASICS_DEVICES_ROUTE_PROVIDER,
        BASICS_EMAIL_NOTIFICATION_CONTENTS_ROUTE_PROVIDER,
        BASICS_HUB_NOTIFICATION_CONTENTS_ROUTE_PROVIDER,
        BASICS_SMS_NOTIFICATIONS_ROUTE_PROVIDER,
        BASICS_WEB_NOTIFICATION_CONTENTS_ROUTE_PROVIDER
      ],
    };
  }
}
