import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  BulkAssignBadgesDto,
  BulkRevokeBadgesDto,
  GetPilotBadgesInput,
  PilotBadgeCreateDto,
  PilotBadgeDto,
  PilotBadgeExcelDownloadDto,
  PilotBadgeUpdateDto,
  PilotBadgeWithNavigationPropertiesDto,
} from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';
import { BadgeSelectionDto } from '../../../../../../../flyguys/src/app/pilot-sourcing/components/assign-badge-modal/models/badge-selection-dto';

@Injectable({
  providedIn: 'root',
})
export class PilotBadgeService {
  apiName = 'pilotsService';

  create = (input: PilotBadgeCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotBadgeDto>(
      {
        method: 'POST',
        url: '/api/pilots-service/pilot-badges',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/pilots-service/pilot-badges/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotBadgeDto>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilot-badges/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/pilots-service/pilot-badges/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetPilotBadgesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotBadgeWithNavigationPropertiesDto>>(
      {
        method: 'GET',
        url: '/api/pilots-service/pilot-badges',
        params: {
          filterText: input.filterText,
          descriptionPilot: input.descriptionPilot,
          badgeId: input.badgeId,
          descriptionBadge: input.descriptionBadge,
          state: input.state,
          pilotId: input.pilotId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: PilotBadgeExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/pilots-service/pilot-badges/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getPilotLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/pilots-service/pilot-badges/pilot-lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getWithNavigationProperties = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotBadgeWithNavigationPropertiesDto>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilot-badges/with-navigation-properties/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: PilotBadgeUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotBadgeDto>(
      {
        method: 'PUT',
        url: `/api/pilots-service/pilot-badges/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  assignMultipleBadges = (input: BadgeSelectionDto[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotBadgeDto[]>(
      {
        method: 'POST',
        url: '/api/pilots-service/pilot-badges/assign-multiple-badges',
        body: {
          badges: input,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getSelectedBadgesForPilot = (pilotId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BadgeSelectionDto[]>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilot-badges/selected-badges/${pilotId}`,
      },
      { apiName: this.apiName, ...config },
    );

  revokeBadges = (pilotId: string, badgeIds: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: '/api/pilots-service/pilot-badges/revoke-badges',
        body: {
          pilotId: pilotId,
          badgeIds: badgeIds,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getCurrentBadgesForPilots = (pilotIds: Array<string>, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotBadgeDto[]>(
      {
        method: 'POST',
        url: `/api/pilots-service/pilot-badges/current`,
        body: pilotIds,
      },
      { apiName: this.apiName, ...config },
    );

  bulkAssignation = (bulkAssignation: BulkAssignBadgesDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/pilots-service/pilot-badges/bulk/assign`,
        body: bulkAssignation,
      },
      { apiName: this.apiName, ...config },
    );

  bulkRevokation = (bulkRevokation: BulkRevokeBadgesDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/pilots-service/pilot-badges/bulk/revoke`,
        body: bulkRevokation,
      },
      { apiName: this.apiName, ...config },
    );

  removeBadgeFromAllPilots = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilot-badges/remove-badge-from-all-pilots/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  updateBadgeForAllPilots = (badgeId: string, updatedName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/pilots-service/pilot-badges/update-assigned-badges-name`,
        body: {
          badgeId: badgeId,
          updatedName: updatedName,
        },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
