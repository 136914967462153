import { mapEnumToOptions } from '@abp/ng.core';

export enum FilesDumpingSessionStatus {
  None = 0,
  Started = 1,
  Inprogres = 2,
  Failed = 3,
  Successful = 4,
  Created = 5,
}

export const fileDumpingSessionStatus = mapEnumToOptions(FilesDumpingSessionStatus);
