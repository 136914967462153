import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  recurrenceEnum,
  recurrenceEnumDisplayNames,
} from '../../../../proxy/missions-service/shared/recurrency.enum';
import {
  CaptureMustBeEnum,
  captureMustBeEnumDisplayNames,
} from '../../../../proxy/missions-service/shared/capture-mustbe.enum';
import { Router } from '@angular/router';
import { ABP, ConfigStateService, LocalizationService } from '@abp/ng.core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MissionsViewGridFilterModel } from '../models/missions-view-grid-filter.model';
import { CustomViewDataModel } from '../models/custom-view-data.model';
import { FilterType } from 'projects/flyguys/src/app/shared/grid-filters/models/filter-type.enum';
import { MissionsFilterColumns } from '../models/missions-filter-columns';
import {
  GetPriorityInput,
  enumState,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { PrioritiesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { StatusMissionOrderService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/status-mission-order.service';
import {
  TimeframeFilterEnum,
  TimeframeFilterEnumDisplayNames,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/timeframes-filter.enum';
import { StatusAndAssignedPermissions } from 'projects/flyguys/src/app/shared/grid-filters/models/status-and-assigned-permissions';
import {
  assignedFilterDisplayNames,
  assignedFilterEnum,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/assignedFilter.enum';
import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { map } from 'rxjs';

@Component({
  selector: 'app-custom-view-modal',
  templateUrl: './custom-view-modal.component.html',
  styleUrls: ['./custom-view-modal.component.scss'],
})
export class CustomViewModalComponent implements OnInit {
  newViewForm: FormGroup;

  currentFilters: MissionsViewGridFilterModel;
  availableColumns: string[];
  selectedColumns: string[];

  isEdition: boolean;

  allMissionFilters = MissionsFilterColumns;

  datafrequency = Object.keys(recurrenceEnum)
    .filter(key => typeof recurrenceEnum[key] === 'number')
    .map(key => ({
      value: recurrenceEnum[key],
      description: recurrenceEnumDisplayNames[recurrenceEnum[key]],
    }));

  dataCaptureMust = Object.keys(CaptureMustBeEnum)
    .filter(key => typeof CaptureMustBeEnum[key] === 'number')
    .map(key => ({
      value: CaptureMustBeEnum[key],
      description: captureMustBeEnumDisplayNames[CaptureMustBeEnum[key]],
    }));

  currentUserId: string = '';
  publicView: boolean;
  isAdminUser: boolean;

  constructor(
    public dialogRef: MatDialogRef<CustomViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomViewDataModel,
    private formBuilder: FormBuilder,
    private router: Router,
    private stateService: ConfigStateService,
    public readonly localizationService: LocalizationService,
    public readonly slaService: SlaService,
    private readonly statusMissionOrderService: StatusMissionOrderService,
  ) {
    this.currentFilters = { ...data.data };

    if (this.currentFilters.statusAndAssignedPermissions)
      this.currentFilters.statusAndAssignedPermissions = {
        ...data.data.statusAndAssignedPermissions,
      };

    this.createForm();

    this.isEdition = data.isEdition ?? false;
  }

  ngOnInit() {
    this.currentUserId = this.stateService.getDeep('currentUser.id');

    const currentRoles = this.stateService.getDeep('currentUser.roles') as string[];
    this.isAdminUser = currentRoles.includes('admin');

    this.loadAvailableColumns();

    this.loadPreselectedColumns();

    this.updatePriorityOptions();

    this.updateMissionStatusOptions();

    this.updateTimeframeOptions();
  }

  areEmptyFilters(): boolean {
    return (
      !this.currentFilters ||
      (!this.currentFilters.captureDate?.length &&
        !this.currentFilters.creationDate?.length &&
        !this.currentFilters.customerName?.length &&
        !this.currentFilters.filter?.length &&
        !this.currentFilters.jobId?.length &&
        !this.currentFilters.locationName?.length &&
        !this.currentFilters.missionName?.length &&
        !this.currentFilters.missionStatusId?.length &&
        !this.currentFilters.noMissionStatusId?.length &&
        !this.currentFilters.pilotName?.length &&
        !this.currentFilters.priorityId?.length &&
        !this.currentFilters.projectName?.length &&
        !this.currentFilters.deliverableDueDate?.length)
    );
  }

  getBaseViewSelected(permissions: StatusAndAssignedPermissions): string {
    if (permissions.allRecords) return assignedFilterDisplayNames[assignedFilterEnum.All];

    if (permissions.inTheTeam) return assignedFilterDisplayNames[assignedFilterEnum.MyTeam];

    return assignedFilterDisplayNames[assignedFilterEnum.MyMission];
  }

  removeBaseViewSelected(permissions: StatusAndAssignedPermissions) {
    permissions.allRecords = true;
    permissions.inTheTeam = false;
    permissions.assignedToMe = false;
  }

  isArray(property: any): boolean {
    return property instanceof Array;
  }

  isCondition(propertyName: string): boolean {
    let condition = this.allMissionFilters.conditions.find(x => x.column == propertyName);

    return propertyName == 'filter' || !!condition;
  }

  getDisplayValue(propertyName: string, value: string): string {
    let condition = this.allMissionFilters.conditions.find(x => x.column == propertyName);

    if (!condition) return '-';

    // Return the description of the option if it's a dropdown
    if (condition.type === FilterType.Dropdown || condition.type === FilterType.DateWithOptions) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    }

    // Return the value itself for other types
    return value;
  }

  getDisplayName(propertyName: string): string {
    if (propertyName == 'filter') return 'Search Text';

    if (propertyName == 'sorting') return 'Search Text';

    let condition = this.allMissionFilters.conditions.find(x => x.column == propertyName);

    if (!condition) return propertyName;

    return condition.columnDisplayName;
  }

  getSortingDisplayName(sortingValue: string): string {
    if (!sortingValue) return '';

    let values = sortingValue.split(' ');

    return this.getDisplayName(values[0].trim());
  }

  getSortingCriteria(sortingValue: string): string {
    if (!sortingValue) return '';

    let values = sortingValue.split(' ');

    return values[1].trim();
  }

  removeCondition(propertyName: string, index: number): void {
    this.currentFilters[propertyName].splice(index, 1);
  }

  removeSingleCondition(propertyName: string): void {
    this.currentFilters[propertyName] = '';
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  createForm() {
    this.newViewForm = this.formBuilder.group({
      viewName: [this.currentFilters.viewName, Validators.required],
      general: [!this.currentFilters.privateView],
    });
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  handleOnSave(): void {
    if (!this.isValid()) return;

    const formValue = this.newViewForm.getRawValue();

    this.currentFilters.columns = this.selectedColumns;
    this.currentFilters.viewName = formValue.viewName;
    this.currentFilters.privateView = !formValue.general;

    this.dialogRef.close(this.currentFilters);
  }

  isValid(): boolean {
    return this.newViewForm.valid && this.selectedColumns.length > 0;
  }

  private updatePriorityOptions(): void {
    const condition = this.allMissionFilters.conditions.find(c => c.column === 'priorityId');
    if (condition) {
      this.slaService
        .getList({
          maxResultCount: 100,
          isPaginated: false,
        })
        .subscribe({
          next: res => {
            condition.options = res.items.map(item => ({ id: item.id, description: item.name }));
          },
          error: err => console.error('Error getting priorities'),
        });
    }
  }

  private updateTimeframeOptions(): void {
    const dataTimeframesFilter = Object.keys(TimeframeFilterEnum)
      .filter(key => typeof TimeframeFilterEnum[key] === 'number')
      .map(key => ({
        id: TimeframeFilterEnum[key],
        description: this.localizationService.instant(
          TimeframeFilterEnumDisplayNames[TimeframeFilterEnum[key]],
        ),
      }));

    const conditionCaptureTime = this.allMissionFilters.conditions.find(
      c => c.column === 'captureDate',
    );
    if (conditionCaptureTime) {
      conditionCaptureTime.options = dataTimeframesFilter.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }

    const condition = this.allMissionFilters.conditions.find(c => c.column === 'creationDate');
    if (condition) {
      condition.options = dataTimeframesFilter.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }

    const dueDateCondition = this.allMissionFilters.conditions.find(
      c => c.column === 'deliverableDueDate',
    );
    if (dueDateCondition) {
      dueDateCondition.options = dataTimeframesFilter.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }
  }

  private updateMissionStatusOptions(): void {
    const condition = this.allMissionFilters.conditions.find(c => c.column === 'missionStatusId');
    const conditionNoStatus = this.allMissionFilters.conditions.find(
      c => c.column === 'noMissionStatusId',
    );

    if (condition) {
      this.statusMissionOrderService
        .getList({ maxResultCount: 1000, sorting: 'order' })
        .subscribe(res => {
          condition.options = res.items.map(status => {
            return { id: status.statusEnum.toString(), description: status.statusDescription };
          });

          if (conditionNoStatus) conditionNoStatus.options = condition.options;
        });
    }
  }

  private loadAvailableColumns(): void {
    this.availableColumns = [
      'CustomerId',
      'LocationId',
      'CreationTime',
      'PilotId',
      'Team',
      'ProjectId',
      'Portafolio',
      'Summary',
      'StartData',
      'AdditionalNotes',
      'pilotSourcingMode',
      'qaqcMode',
      'invoiceMode',
      'DeliverableDueDate',
      'PilotSourcingResults',
      'NextMilestoneDue',
      'DaysToDelivery',
      'AirspaceWaiverRequired',
    ];
  }

  private loadPreselectedColumns(): void {
    this.selectedColumns = [
      'JobId',
      'SLAProfile',
      'Status',
      'MissionName',
      'CaptureDate',
      'CustomerId',
    ];
  }
}
