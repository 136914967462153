import { Component } from '@angular/core';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  constructor(private localizationService: LocalizationService) {} 
}
