import { PermissionService } from '@abp/ng.core';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-column-displayer',
  templateUrl: './column-displayer.component.html',
  styleUrls: ['./column-displayer.component.scss']
})
export class ColumnDisplayerComponent implements OnInit {
  @Input() columnsStatus:ColumnStatus[];
  @Input() toggler:Function;
  @Input() showColumnPermission:string;

  showList:boolean = false;
  isCheckClicked:boolean = false;
  checkOptions:any = {};

  @ViewChild('toggleMenu') toggleMenu: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  constructor(private renderer:Renderer2, private permissionService: PermissionService) {
    this.createListener();
  }

  ngOnInit(): void {
    this.verifyPermissions();
  }

  toggleShowList() {
    this.showList = !this.showList;
  }

  checkClicked() {
    this.isCheckClicked = true;
  }

  executeToggler(column:ColumnStatus, checked:boolean) {
    const actualColumn = this.columnsStatus.find(col => col.columnName == column.columnName);
    this.toggler(actualColumn.columnName, checked);
  }

  createListener() {
    this.renderer.listen('window', 'click', (e:Event) => {
      if(!this.toggleMenu.nativeElement.contains(e.target) && !this.menu?.nativeElement.contains(e.target) && !this.isCheckClicked){
        this.showList = false;
      }
      this.isCheckClicked = false;
    });
  }

  verifyPermissions() {
    this.columnsStatus.forEach(actualColumn => {
      if(actualColumn.abpPermission) {
        const canSeeColumn = this.permissionService.getGrantedPolicy(actualColumn.abpPermission);
        this.checkOptions[actualColumn.columnName] = canSeeColumn;
        this.toggler(actualColumn.columnName, canSeeColumn);
      } else {
        this.checkOptions[actualColumn.columnName] = true;
      }
    });
  }
}

export interface ColumnStatus {
  columnName:string,
  softColumnName:string,
  defaultChecked:boolean,
  abpPermission?:string
}