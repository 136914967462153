import { FilterConfig } from '../../../../../../../flyguys/src/app/shared/grid-filters/models/filter-config.model';
import { FilterType } from '../../../../../../../flyguys/src/app/shared/grid-filters/models/filter-type.enum';

export const PortfolioMissionsFilterColumns: FilterConfig = {
  conditions: [
    {
      column: 'missionStatusId',
      columnDisplayName: 'Status',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'missionName',
      columnDisplayName: 'Mission Name',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'projectName',
      columnDisplayName: 'Project',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'locationName',
      columnDisplayName: 'Location',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'captureDate',
      columnDisplayName: 'Capture Date',
      type: FilterType.Date,
      existingValues: [],
    },
    {
      column: 'pilotName',
      columnDisplayName: 'Pilot',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'jobId',
      columnDisplayName: 'Job Id',
      type: FilterType.Numeric,
      existingValues: [],
    }
  ],
};
