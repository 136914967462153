export enum AttributeTypesEnum {
  File = 'INPUT_FILE',
  Bool = 'CHECKBOX',
  Number = 'NUMBER',
  Text = 'TEXT',
  Date = 'CALENDAR',
  List = 'DROPDOWNLIST',
  LongText = 'TEXTAREA',
  Kml = 'KML',
}
