<div *ngIf="orderRequest">
  <ngx-spinner
    bdColor="rgba(245,245,245,0.7)"
    size="default"
    color="#bc5a2e"
    type="ball-square-clockwise-spin"
    [fullScreen]="true"
    [zIndex]="111"
  >
    <p style="color: #bc5a2e">Loading Order...</p>
  </ngx-spinner>
  <h3>{{ 'missionsService::OrderRequest' | abpLocalization }}</h3>
  <div class="row mx-0">
    <div class="col-12 p-0">
      <app-info-card-with-action
        [icon]="'receipt_long'"
        [title]="'missionsService::OrderDetails' | abpLocalization"
        [actionIcon]="'edit'"
        [actionIconVisible]="allowOrderRequestEditDetails"
        (actionClicked)="onEditOrderDetailsClick()"
      >
        <div class="subtitle">{{ 'missionsService::MissionSummary' | abpLocalization }}</div>
        <div
          class="description flg-editor-output"
          [innerHTML]="orderRequest.orderDetails.descriptionToShow"
        ></div>
        <div>
          <app-subtitle-description
            [subtitle]="'missionsService::MissionPublicJobId' | abpLocalization"
            [description]="orderRequest.orderDetails.correlationId"
          ></app-subtitle-description>
          <div class="d-flex flex-row align-content-center flex-wrap">
            <app-subtitle-description
              [subtitle]="'missionsService::SLAProfile' | abpLocalization"
              [description]="orderRequest.orderDetails.orderPriority"
            ></app-subtitle-description>
            <a
              class="ps-2 cursor-pointer"
              (click)="
                openPopupSlaMilestones(
                  orderRequest.missionId,
                  orderRequest.orderDetails.orderPriority
                )
              "
            >
              <span matTooltip="See details" class="material-symbols-outlined">info</span>
            </a>
          </div>

          <app-subtitle-description
            [subtitle]="'missionsService::ProjectId' | abpLocalization"
            [description]="orderRequest.orderDetails.projectName"
          ></app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'missionsService::PortafolioId' | abpLocalization"
            [description]="orderRequest.orderDetails.portfolio"
          ></app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'missionsService::OrderIndustry' | abpLocalization"
            [description]="orderRequest.orderDetails.orderIndustry"
          ></app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'missionsService::CustomerRequestedCaptureDateAndTime' | abpLocalization"
            [description]="
              getFixedCustomerCaptureDateTime() + ' ' + (orderRequest?.location?.timeZone ?? '')
            "
          ></app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'missionsService::CustomerRequestedCaptureMustBe' | abpLocalization"
            [description]="getCaptureMustBeDescription(missionData?.customerRequestedCaptureMustBe)"
          ></app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'missionsService::AssetOrder' | abpLocalization"
            [description]="orderRequest.orderDetails.missionAssetOrder"
          ></app-subtitle-description>
          <app-subtitle-description
            [subtitle]="'missionsService::Airspace Waiver Required' | abpLocalization"
            [description]="
              orderRequest.orderDetails.manualAirspaceWaiverRequired ? 'Required' : 'Not Required'
            "
          ></app-subtitle-description>
        </div>
      </app-info-card-with-action>
    </div>

    <div class="col-6 p-0">
      <app-info-card-with-action
        [icon]="'distance'"
        [title]="'missionsService::Location' | abpLocalization"
        [actionIcon]="'edit'"
        [actionIconVisible]="allowOrderRequestEditLocation"
        [height]="'500px'"
        (actionClicked)="onEditLocationClick()"
      >
        <app-subtitle-description
          [subtitle]="'missionsService::SiteName' | abpLocalization"
          [description]="orderRequest.location.siteName"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::City' | abpLocalization"
          [description]="orderRequest.location.city"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::State' | abpLocalization"
          [description]="orderRequest.location.state"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::Country' | abpLocalization"
          [description]="orderRequest.location.country"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::Address' | abpLocalization"
          [description]="orderRequest.location.address"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::ZipCode' | abpLocalization"
          [description]="orderRequest.location.zipCode"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::Longitude' | abpLocalization"
          [description]="orderRequest.location.longitude"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::Latitude' | abpLocalization"
          [description]="orderRequest.location.latitude"
        ></app-subtitle-description>
        <app-subtitle-description
          [subtitle]="'missionsService::AirspaceClassification' | abpLocalization"
          [description]="orderRequest.location.airspaceClassification"
        ></app-subtitle-description>
        <div class="d-flex">
          <div *ngIf="this.orderRequest.location.kmlFilePath">
            <div class="btn btn-gray" [ngClass]="{ 't-ml-8': i > 0 }" (click)="downloadKml()">
              {{ this.orderRequest.location.kmlFileName }}
            </div>
          </div>
        </div>

        <div
          *ngIf="allowOrderRequestEditSiteContactInformation"
          class="btn btn-outline-primary"
          (click)="openSiteLocation()"
        >
          {{ 'missionsService::SiteContactInformation' | abpLocalization }}
        </div>
      </app-info-card-with-action>
    </div>
    <div class="col-6 ps-3 p-0">
      <div class="w-100">
        <flg-map
          [markers]="mapMarkers"
          [usesKML]="hasKml"
          [options]="{
            disableDefaultUI: true,
            mapTypeId: 'satellite',
          }"
          [dimensions]="{ height: '500px', width: '100%' }"
          (markerData)="handleMarkerClick($event)"
        ></flg-map>
      </div>
    </div>
    <div class="col-12 mt-3 p-0">
      <app-info-card-with-action
        [icon]="'markunread_mailbox'"
        [title]="'missionsService::DeliverableAspects' | abpLocalization"
        [actionIcon]="'edit'"
        [actionIconVisible]="allowOrderRequestEditDeliverableAspects"
        (actionClicked)="onEditDeliverableAspectsClick()"
      >
        <app-subtitle-description
          [subtitle]="'missionsService::DeliverableDueDate' | abpLocalization"
          [description]="getDate()"
        ></app-subtitle-description>
        <div class="subtitle t-mb-8">
          {{ 'missionsService::deliverableNotes' | abpLocalization }}
        </div>
        <div
          class="description flg-editor-output t-mb-8"
          [innerHTML]="orderRequest.deliverableAspects.deliverableNotes"
        ></div>
        <div class="subtitle t-mb-8">
          {{ 'missionsService::UploadingDataInstructions' | abpLocalization }}
        </div>
        <div
          class="description t-mb-8 flg-editor-output"
          [innerHTML]="orderRequest.deliverableAspects.uploadingDataInstructionsToShow"
        ></div>
        <div class="subtitle t-mb-8">
          {{ 'missionsService::AdditionalNotes' | abpLocalization }}
        </div>
        <div
          class="description t-mb-8 flg-editor-output"
          [innerHTML]="orderRequest.attachmentsAndNotes.additionalNotes"
        ></div>
      </app-info-card-with-action>
    </div>
    <div class="col-12 p-0">
      <app-info-card-with-action
        [icon]="'add_notes'"
        [title]="'missionsService::AttachmentsAndNotes' | abpLocalization"
        [actionIcon]="'edit'"
        [actionIconVisible]="allowOrderRequestEditAttachmentsAndNotes"
        (actionClicked)="onEditAttachmentsAndNotesClick()"
      >
        <ng-container
          *ngFor="let doc of orderRequest.attachmentsAndNotes.referenceDocumentation; let i = index"
        >
          <div
            *ngIf="
              (i + 1) % 6 === 0 ||
              i + 1 === orderRequest.attachmentsAndNotes.referenceDocumentation.length
            "
          >
            <div class="d-flex w-100"></div>
          </div>
          <div class="btn btn-gray me-2 mt-2" (click)="downloadDocument(doc)">
            {{ doc.fileName }}
          </div>
          <div
            *ngIf="
              (i + 1) % 6 === 0 ||
              i + 1 === orderRequest.attachmentsAndNotes.referenceDocumentation.length
            "
            class="w-100"
          ></div>
        </ng-container>
      </app-info-card-with-action>
    </div>
  </div>
</div>
