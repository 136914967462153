import { ABP, PagedResultDto } from '@abp/ng.core';
import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  first,
  forkJoin,
  map,
  of,
  switchMap,
} from 'rxjs';
import { ToasterService } from '@abp/ng.theme.shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationForm } from '@flyguys/forms';
import { CreateCustomer, CustomerType } from 'projects/flyguys/src/app/components/orders/model/order-form-create-customer.model';
import { CountriesDto, CustomerVerticalDto, GetContactTypeInput, GetCountryInput, GetLevelInput, GetStateInput, LevelesDto, StatesDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { AddressesDto, ContactsDto, enumState } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { CustomersDto, UpdateCustomer } from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import { ContactTypesService, CountriesService, LevelesService, StatesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { CustomerVerticalService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/customer-vertical.service';
import { ContactsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'lib-edit-customer-details-component',
  templateUrl: './edit-customer-details-component.component.html',
  styleUrls: ['./edit-customer-details-component.component.scss']
})
export class EditCustomerDetailsComponent {
  form: FormGroup;
  private subscriptions: Subscription[] = [];
  CustomerType = CustomerType;

  levels: PagedResultDto<LevelesDto> = {
    items: [],
    totalCount: 0,
  };

  dataCountries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };

  dataStates: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };

  customerVerticals: Array<CustomerVerticalDto>;

  billingContactCode = 'BILLING';
  mainContactCode = 'MAIN';

  billingContactTypeId: string;
  mainContactTypeId: string;

  billingContact: ContactsDto;
  mainContact: ContactsDto;
  address: AddressesDto;

  data: CustomersDto;

  readonly loadingMessage: string = 'Loading Company Information...';

  constructor(
    public dialogRef: MatDialogRef<EditCustomerDetailsComponent>,
    public readonly levelService: LevelesService,
    private customerVerticalService: CustomerVerticalService,
    public readonly countriesService: CountriesService,
    public readonly statesService: StatesService,
    public readonly customerService: CustomersService,
    private fb: FormBuilder,
    public readonly contactTypeService: ContactTypesService,
    private readonly contactsService: ContactsService,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dataMat: CustomersDto
  ) {
    this.data = dataMat;
    this.spinner.show();

    this.form = this.fb.group({
      customerType: [this.data.isCompany ? CustomerType.Company : CustomerType.Personal],
      personal: this.fb.group({
        customerLevelId: [null],
        customerVerticalId: [null, Validators.required],
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        primaryPhoneNumber: [null, Validators.required],
        idHubspot: [null, Validators.maxLength(100)]
      }),
      company: this.fb.group({
        customerLevelId: [null],
        customerVerticalId: [null, Validators.required],
        companyName: [null, [Validators.required]],
        legalName: [null],
        ein: [null],
        website: [null],
        msaCustomer: [false],
        mainContact: this.fb.group({
          firstName: [null, Validators.required],
          lastName: [null, Validators.required],
          email: [null, [Validators.required, Validators.email]],
          primaryPhoneNumber: [null, Validators.required],
        }),
        idHubspot: [null, Validators.maxLength(100)]
      }),      
      location: new LocationForm(),
    });

    this.setupFormStateBasedOnCustomerType(
      this.data.isCompany ? CustomerType.Company : CustomerType.Personal
    );
  }

  private setupFormStateBasedOnCustomerType(type: CustomerType): void {
    if (type === CustomerType.Personal) {
      this.form.get('personal')?.enable();
      this.form.get('company')?.disable();
    } else if (type === CustomerType.Company) {
      this.form.get('company')?.enable();
      this.form.get('personal')?.disable();
    }
  }

  ngOnInit(): void {
    this.getCustomerLevelValues();
    this.getCountries();
    this.getStates();
    this.getCustomerVerticalValues();
    this.getBillingMainContactsAndAddress();
  }

    buildForm() {
      this.form.patchValue({
        customerType: this.data.isCompany ? CustomerType.Company : CustomerType.Personal,
        personal: {
          customerLevelId: this.data.levelId,
          customerVerticalId: this.data.customerVerticalId,
          firstName: this.mainContact?.firstName,
          lastName: this.mainContact?.lastName,
          email: this.mainContact?.email,
          primaryPhoneNumber: this.mainContact?.phone,
          idHubspot: this.data.idHubspot,
        },
        company: {
          customerLevelId: this.data.levelId,
          customerVerticalId: this.data.customerVerticalId,
          companyName: this.data.name,
          legalName: this.data.legalName,
          ein: this.data.ein,
          website: this.data.website,
          msaCustomer: this.data.msa,
          mainContact: {
            firstName: this.mainContact?.firstName,
            lastName: this.mainContact?.lastName,
            email: this.mainContact?.email,
            primaryPhoneNumber: this.mainContact?.phone,
          },
          idHubspot: this.data.idHubspot,
        },
        location: {
          address: this.address?.streetAddress ?? '',
          zip: this.address?.zipCode ?? '',
          city: this.address?.city ?? '',
          countryId: this.address?.countryId ?? '',
          stateId: this.address?.stateId ?? '',
        },
      });

    this.setupFormStateBasedOnCustomerType(
      this.data.isCompany ? CustomerType.Company : CustomerType.Personal
    );
  }

  getBillingMainContactsAndAddress() {
    const contactTypeInput = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
      state: enumState.Enabled,
    } as GetContactTypeInput;

    this.contactTypeService.getList(contactTypeInput).subscribe({
      next: res => {
        const billingContactType = res.items.find(item => item.code === this.billingContactCode);
        const mainContactType = res.items.find(item => item.code === this.mainContactCode);

        if (billingContactType) {
          this.billingContactTypeId = billingContactType.id;
        }

        if (mainContactType) {
          this.mainContactTypeId = mainContactType.id;
        }

        const mainContact$ = this.contactsService.getCustomerContactByType(
          this.data.id,
          this.mainContactTypeId
        );
        const billingContact$ = this.contactsService.getCustomerContactByType(
          this.data.id,
          this.billingContactTypeId
        );
        const addressContact$ = this.contactsService.getCustomerBillingAddress(this.data.id);

        forkJoin([mainContact$, billingContact$, addressContact$]).subscribe({
          next: ([mainContactResponse, billingContactResponse, addressResponse]) => {
            this.mainContact = mainContactResponse;
            this.billingContact = billingContactResponse;
            this.address = addressResponse;
            this.buildForm();
            this.handleCustomerTypeChange();
            this.spinner.hide();
          },
          error: err => {
            console.error(err);
            this.spinner.hide();
            this.snackBar.open('Cannot load related info', 'OK', {
              duration: 3000,
            });
            this.dialogRef.close();
          },
        });
      },
      error: err => {
        console.log(err);
        this.spinner.hide();
        this.snackBar.open('Cannot load related info', 'OK', {
          duration: 3000,
        });
        this.dialogRef.close();
      },
    });
  }

  private getCustomerLevelValues(): void {
    const query = {} as ABP.PageQueryParams;
    const levelFilter = { state: enumState.Enabled } as GetLevelInput;

    this.levelService
      .getList({
        ...query,
        ...levelFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.levels = res;
      });
  }

  getCountries() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetCountryInput;
    const subsCountries = this.countriesService.getList(request).subscribe({
      next: (data: PagedResultDto<CountriesDto>) => {
        if (data?.totalCount !== 0) {
          this.dataCountries = data;
        }
      },
      error: err => console.log('Error on getting Countries: ' + JSON.stringify(err)),
    });
    this.subscriptions.push(subsCountries);
  }

  getStates() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetStateInput;
    const subsStates = this.statesService.getList(request).subscribe({
      next: (data: PagedResultDto<StatesDto>) => {
        if (data?.totalCount !== 0) {
          this.dataStates = data;
        }
      },
      error: err => console.log('Error on getting States: ' + JSON.stringify(err)),
    });

    this.subscriptions.push(subsStates);
  }

  getCustomerVerticalValues() {
    this.customerVerticalService.getAll().subscribe(res => {
      this.customerVerticals = res;
    });
  }

  saveAddCustomer() {
    if (this.form.invalid) return;

    const formValue = this.form.getRawValue();

    let newCustomerDto: CreateCustomer;

    if (formValue.customerType === CustomerType.Personal) {
      newCustomerDto = new UpdateCustomer(
        this.data.id,
        this.mainContact?.id,
        this.mainContact?.userId,
        this.mainContact?.descriptionUser,
        this.mainContactTypeId,
        this.billingContact?.id,
        this.billingContactTypeId,
        CustomerType.Personal,
        {
          ...formValue.personal,
        },
        undefined, // No company information
        {
          undefined,
          ...formValue.location,
          countryName: this.getAddressName(this.dataCountries.items, formValue.location?.countryId),
          stateName: this.getAddressName(this.dataStates.items, formValue.location?.stateId),
          zipCode: formValue.location?.zip,
        },
        this.address?.id
      );
    } else if (formValue.customerType === CustomerType.Company) {
      newCustomerDto = new UpdateCustomer(
        this.data.id,
        this.mainContact?.id,
        this.mainContact?.userId,
        this.mainContact?.descriptionUser,
        this.mainContactTypeId,
        this.billingContact?.id,
        this.billingContactTypeId,
        CustomerType.Company,
        undefined, // No personal customer information
        {
          ...formValue.company,
        },
        {
          undefined,
          ...formValue.location,
          countryName: this.getAddressName(this.dataCountries.items, formValue.location?.countryId),
          stateName: this.getAddressName(this.dataStates.items, formValue.location?.stateId),
          zipCode: formValue.location?.zip,
        },
        this.address?.id
      );
    }

    this.dialogRef.close(newCustomerDto);
  }

  getAddressName = (items: any[], id: string): string => {
    const item = items.find(i => i.id === id);
    return item ? item.description : '';
  };

  onClickClose(): void {
    this.dialogRef.close();
  }

  handleLocationChange(location: Location | null) {}

  private handleCustomerTypeChange(): void {
    const customerTypeControl = this.form.get('customerType');
    customerTypeControl?.valueChanges.subscribe(type => {
      if (type === CustomerType.Company) {
        this.form.get('company.companyName').updateValueAndValidity();
      }
      this.setupFormStateBasedOnCustomerType(type);
    });
  }

  checkNameWithIdExistsValidator(
    id: string,
    companyName: boolean,
    lastName: boolean
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(name => {
          if (!name || !name.trim()) {
            return of(null);
          }

          const fullName = lastName
            ? `${this.form.get('personal.firstName')?.value || ''} ${name.trim()}`.trim()
            : `${name.trim()} ${this.form.get('personal.lastName')?.value || ''}`.trim();

          return this.customerService
            .checkNameWithIdExists(companyName ? name.trim() : fullName.trim(), id)
            .pipe(map(exists => (exists ? { nameExists: true } : null)));
        }),
        first()
      );
    };
  }

  emailExistValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(email => {
          if (!email || !email.trim()) {
            return of(null);
          }
          return this.customerService
            .checkEmailExists(email.trim())
            .pipe(map(exists => (exists ? { emailExists: true } : null)));
        }),
        first()
      );
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

