import { ListService } from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NavigateToMissionDetailsDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';

@Component({
  selector: 'orders-missions',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './orders-missions.component.html',
  styles: [],
})
export class OrdersMissionsComponent {
  @Output() onOpenMission = new EventEmitter<NavigateToMissionDetailsDto>();

  constructor() {}

  onOpenMissions(missionNavigationDetails: NavigateToMissionDetailsDto): void {
    this.onOpenMission.emit(missionNavigationDetails);
  }
}
