import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MissionChangeLogService } from '../../../services/mission-change-log.service';
import { MissionChangeLogDto } from '../../models/mission-change-log-dto';
import { FilterConfig } from '../../../shared/grid-filters/models/filter-config.model';
import { FilterCondition } from '../../../shared/grid-filters/models/filter-condition.model';
import { MissionTimelineFilterColumns } from '../../models/mission-timeline-filter-columns';
import { LocalizationService } from '@abp/ng.core';
import { FilterType } from '../../../shared/grid-filters/models/filter-type.enum';
import { Subscription } from 'rxjs';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { OrderMissionEventsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/order-mission-events.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnDestroy {
  @Input()
  missionId: string;

  logs: Array<MissionChangeLogDto> = [];
  originalLogs: Array<MissionChangeLogDto>;
  logSelected: MissionChangeLogDto;
  logSelectedDetails: any = {};
  filterConfig: FilterConfig = MissionTimelineFilterColumns;

  subscription = new Subscription();

  constructor(
    private service: MissionChangeLogService,
    private localizationService: LocalizationService,
    private notificationBroadcastService: NotificationBroadcastService,
    private readonly orderMissionEventsService: OrderMissionEventsService,
  ) {}

  ngOnInit(): void {
    this.service.getChangesByMissionAsync(this.missionId).subscribe(res => {
      this.logs = res;

      this.originalLogs = [...this.logs];

      if (this.logs.length > 0) this.showDetails(this.logs[0]);

      this.updateEventFilter(this.logs);
    });

    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionStatus) {
          if (
            notif.itemId &&
            notif.extraArgument.missionStatus &&
            notif.extraArgument.missionStatusCode &&
            this.missionId == notif.itemId
          ) {
            this.reloadChanges();
          }
        }
      }),
    );
  }

  getFilterDisplayValue(condition: FilterCondition): string {
    return condition.existingValues.map(value => this.getDescription(condition, value)).join(', ');
  }

  getDescription(condition: FilterCondition, value: string): string {
    if (condition.type === FilterType.Dropdown) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    }

    return value;
  }

  showDetails(log: MissionChangeLogDto) {
    this.logSelected = log;

    if (!log.displayEventName.toLowerCase().includes('recalculate'))
      this.logSelectedDetails = JSON.parse(this.logSelected.displayEventInformation);
    else this.logSelectedDetails = null;
  }

  onFiltersApplied(updatedFilters: FilterConfig) {
    updatedFilters.conditions.forEach(updatedCondition => {
      this.logs = this.originalLogs.filter(x =>
        updatedCondition.existingValues.includes(x[updatedCondition.column]),
      );
    });
  }

  removeAllFilters() {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => ({
        ...condition,
        existingValues: [],
      })),
    };

    this.logs = this.originalLogs.filter(x => true);
  }

  removeFilter(column: string) {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => {
        if (condition.column === column) {
          return { ...condition, existingValues: [] };
        }
        return condition;
      }),
    };

    this.logs = this.originalLogs.filter(x => true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private updateEventFilter(source: Array<MissionChangeLogDto>): void {
    this.orderMissionEventsService
      .getList({ maxResultCount: 1000, sorting: 'order' })
      .subscribe(res => {
        const condition = this.filterConfig.conditions.find(c => c.column === 'displayEventName');
        if (condition) {
          let currentList = source.map(item => item.displayEventName);
          let filteredValues = [...new Set(currentList.map(item => item))];

          var tempfilteredValues: string[] = [];
          res.items.forEach(status => {
            const tempValue = filteredValues.find(x => x == status.code);
            if (tempValue) tempfilteredValues.push(tempValue);
          });
          filteredValues = [...new Set([...tempfilteredValues, ...filteredValues])];

          condition.options = filteredValues.map(item => ({
            id: item,
            description: this.localizationService.instant(`missionsService::${item}`),
          }));
        }
      });
  }

  private reloadChanges(): void {
    this.service.getChangesByMissionAsync(this.missionId).subscribe(res => {
      this.originalLogs = [...res];

      let applyFilters = false;
      for (let condition of this.filterConfig.conditions) {
        if (condition.existingValues.length > 0) {
          applyFilters = true;
          break;
        }
      }

      if (!applyFilters) {
        this.logs = res;
      } else {
        this.filterConfig.conditions.forEach(updatedCondition => {
          this.logs = this.originalLogs.filter(x =>
            updatedCondition.existingValues.includes(x[updatedCondition.column]),
          );
        });
      }

      this.updateEventFilter(this.originalLogs);
    });
  }
}
