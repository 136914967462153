<div
  class="sidebar-area bg-white"
  [ngClass]="{ active: isToggled }"
  [class.dark-sidebar]="themeService.isSidebarDark()"
  [class.right-sidebar]="themeService.isRightSidebar()"
  [class.hide-sidebar]="themeService.isHideSidebar()"
>
  <div class="logo bg-white">
    <a routerLink="/" class="d-flex align-items-center position-relative">
      <img src="assets/images/FGLight.svg" alt="logo" class="full-size" />
      <img src="assets/images/FGicon.svg" alt="logo" class="compact-size" />
      <flg-environment-tag></flg-environment-tag>
    </a>
  </div>
  <div class="burger-menu" (click)="toggle()" [ngClass]="{ active: isToggled }">
    <span class="top-bar"></span>
    <span class="middle-bar"></span>
    <span class="bottom-bar"></span>
  </div>
  <ng-scrollbar visibility="hover" style="height: 100vh">
    <div class="sidebar-inner">
      <div class="sidebar-menu">
        <mat-accordion>
          <ng-container
            *ngFor="let route of routesService.visible$ | async; trackBy: trackByFn"
            [ngTemplateOutlet]="isExpandable(route) ? expandableMenu : defaultLink"
            [ngTemplateOutletContext]="{ $implicit: route }"
          >
          </ng-container>
        </mat-accordion>
      </div>
    </div>
  </ng-scrollbar>
</div>

<!-- Top level menu item with no children -->
<ng-template #defaultLink let-route>
  <div
    class="default-link mat-expansion-panel"
    *abpPermission="route.requiredPolicy"
    (click)="handleNavClick()"
    [routerLink]="route.path"
    routerLinkActive="active-link"
    [routerLinkActiveOptions]="{ exact: route.path === '/' ? true : false }"
  >
    <div class="mat-expansion-panel-header">
      <span class="mat-content">
        <div class="mat-expansion-panel-header-title">
          <i *ngIf="route.iconClass" [ngClass]="route.iconClass"></i>
          <span class="title"> {{ route.name | abpLocalization }}</span>
        </div>
      </span>
    </div>
  </div>
</ng-template>

<!-- Top level menu with nested children -->
<ng-template #expandableMenu let-route>
  <ng-container *ngIf="route.children?.length">
    <mat-expansion-panel *abpPermission="route.requiredPolicy">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i *ngIf="route.iconClass" [ngClass]="route.iconClass"></i>
          <span class="title">{{ route.name | abpLocalization }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="listTemplate; context: { $implicit: route }"></ng-container>
    </mat-expansion-panel>
  </ng-container>
</ng-template>

<!-- This switches which template to use in case of rendering children -->
<ng-template #listTemplate let-route>
  <ng-container *ngFor="let child of route.children">
    <ng-template
      [ngTemplateOutlet]="child.children?.length ? expandableChild : defaultChild"
      [ngTemplateOutletContext]="{ $implicit: child }"
    ></ng-template>
  </ng-container>
</ng-template>

<!-- Nested child that doesn't have children -->
<ng-template #defaultChild let-child>
  <ng-container *ngIf="child.path">
    <a
      (click)="handleNavClick()"
      [routerLink]="child.path"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      class="default-child"
      *abpPermission="child.requiredPolicy"
    >
      {{ child.name | abpLocalization }}</a
    >
  </ng-container>
</ng-template>

<!-- Nested child that nests other children -->
<ng-template #expandableChild let-child>
  <mat-expansion-panel class="expandable-child" *abpPermission="child.requiredPolicy">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="title"> {{ child.name | abpLocalization }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-container *ngTemplateOutlet="listTemplate; context: { $implicit: child }"></ng-container>
  </mat-expansion-panel>
</ng-template>
