import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { UomCreateDto, UomDto, UomUpdateDto, GetUomInput, UomExcelDownloadDto } from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class UomService {
  apiName = 'CoreService';
  

  create = (input: UomCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UomDto>({
      method: 'POST',
      url: '/api/core-service/uom',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/core-service/uom/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UomDto>({
      method: 'GET',
      url: `/api/core-service/uom/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetUomInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<UomDto>>({
      method: 'GET',
      url: '/api/core-service/uom',
      params: { filterText: input.filterText, description: input.description, state: input.state, migrated: input.migrated, idOld: input.idOld, code: input.code, sorting: input.sorting, isPaginated: input.isPaginated, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: UomUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UomDto>({
      method: 'PUT',
      url: `/api/core-service/uom/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

    getListAsExcelFile = (input: UomExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/core-service/uom/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });

    getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/core-service/uom/download-token',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
