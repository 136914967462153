import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ReplaceableComponentsService } from '@abp/ng.core';

import { eThemeLeptonXComponents } from '@volosoft/abp.ng.theme.lepton-x';
import { eAccountComponents } from '@volo/abp.ng.account/public';

// Layout
import { ApplicationLayoutComponent } from './components/layout/application-layout/application-layout.component';

// Account
import { AccountLayoutComponent } from './account/account-layout/account-layout.component';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';

// Application Insights
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { ParallelFileUploadService } from 'projects/file-management/src/lib/services/parallel-file-upload.service';
import { ClarityService } from './services/clarity-service';
import { HttpCacheUpdaterService } from './services/http-cache-updater.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    protected router: Router,
    private oAuthService: OAuthService,
    public _parallelFileUploadService: ParallelFileUploadService,
    public _clarityService: ClarityService,
    private readonly _httpCacheUpdaterService: HttpCacheUpdaterService
  ) {
    // Replace main layout
    this.replaceableComponents.add({
      component: ApplicationLayoutComponent,
      key: eThemeLeptonXComponents.ApplicationLayout,
    });

    // Replace account layout
    this.replaceableComponents.add({
      component: AccountLayoutComponent,
      key: eThemeLeptonXComponents.AccountLayout,
    });

    // Replace login layout
    this.replaceableComponents.add({
      component: LoginComponent,
      key: eAccountComponents.Login,
    });

    // Replace register layout
    this.replaceableComponents.add({
      component: RegisterComponent,
      key: eAccountComponents.Register,
    });

    // Replace forgot password layout
    this.replaceableComponents.add({
      component: ForgotPasswordComponent,
      key: eAccountComponents.ForgotPassword,
    });

    const angularPlugin = new AngularPlugin();

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsights.connectionString,
        extensions: [angularPlugin as any],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();

    this.start();
  }

  ngOnInit(){
    this.loadClarityScript()
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
    window.addEventListener('beforeunload', this.canLeavePage.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        this.oAuthService.logOut();
      }
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    window.removeEventListener('beforeunload', this.canLeavePage.bind(this));
    this._httpCacheUpdaterService.stop();
  }

  ngOnDestroy() {
    this.stop();
  }

  canLeavePage($event: any): boolean {
    $event.returnValue = this._parallelFileUploadService.getIsRunning()
      ? 'leaving this page you will lose the data that is not uploaded, do you want to continue?'
      : '';
    return false;
  }

  loadClarityScript(): void {
    this._clarityService.load();
  }
}
