<div class="container h-100 d-flex">
    <div class="row">
        <div class="col-sm-12 col-xs-12 col-lg-6 col-12 col-md-12 col-xl-6 my-auto">
            <img src="assets/images/errors/Error403.png" class="mb-25" alt="acces-forbidden">
        </div>
        <div class="col-sm-12 col-xs-12 col-lg-6 col-12 col-md-12 col-xl-6 my-auto">
            <h2 class="giant-title text-center">403</h2>
            <h2 class="text-center">{{ 'CoreService::CustomErrorsPage:Mistake' | abpLocalization }}</h2>
            <p class="description text-center">{{ 'CoreService::CustomErrorsPage:ForbiddenErrorMessage' | abpLocalization }}</p>
            <div class="text-center mt-3">
                <a mat-raised-button class="tagus mt-8 text-center" routerLink="/">{{ 'CoreService::CustomErrorsPage:BackToHome' | abpLocalization }}</a>
            </div>
        </div>
    </div>
</div>