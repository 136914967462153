import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BulkUploadDetailsCreateDto, BulkUploadDetailsDto, BulkUploadDetailsExcelDownloadDto, BulkUploadDetailsUpdateDto, GetBulkUploadDetailsInput} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class BulkUploadDetailsService {
  apiName = 'missionsService';
  

  create = (input: BulkUploadDetailsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BulkUploadDetailsDto>({
      method: 'POST',
      url: '/api/missions-service/bulkUploadDetails',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/bulkUploadDetails/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BulkUploadDetailsDto>({
      method: 'GET',
      url: `/api/missions-service/bulkUploadDetails/${id}`,
    },
    { apiName: this.apiName,...config });

    getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/bulkUploadDetails/download-token',
    },
    { apiName: this.apiName,...config });

  getList = (input: GetBulkUploadDetailsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<BulkUploadDetailsDto>>({
      method: 'GET',
      url: '/api/missions-service/bulkUploadDetails',
      params: { filterText: input.filterText, bulkUploadId: input.bulkUploadId, bulkUploadStatus: input.bulkUploadStatus, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });

    getListAsExcelFile = (input: BulkUploadDetailsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/bulkUploadDetails/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, bulkUploadId: input.bulkUploadId, bulkUploadStatus: input.bulkUploadStatus},
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: BulkUploadDetailsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BulkUploadDetailsDto>({
      method: 'PUT',
      url: `/api/missions-service/bulkUploadDetails/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
