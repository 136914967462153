import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { DownloadTokenResultDto } from '../../shared/models';
import {
  GetMissionLocationContactsInput,
  MissionLocationContactCreateDto,
  MissionLocationContactDto,
  MissionLocationContactExcelDownloadDto,
  MissionLocationContactUpdateDto,
} from '../../basics';

@Injectable({
  providedIn: 'root',
})
export class MissionLocationContactService {
  apiName = 'missionsService';

  create = (input: MissionLocationContactCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionLocationContactDto>(
      {
        method: 'POST',
        url: '/api/missions-service/mission-location-contacts',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/mission-location-contacts/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  deleteSitesFromMission = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/mission-location-contacts/delete-sites-from-mission/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionLocationContactDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-location-contacts/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/mission-location-contacts/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetMissionLocationContactsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionLocationContactDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/mission-location-contacts',
        params: {
          filterText: input.filterText,
          missionId: input.missionId,
          locationId: input.locationId,
          contactId: input.contactId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (
    input: MissionLocationContactExcelDownloadDto,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/mission-location-contacts/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: MissionLocationContactUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionLocationContactDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/mission-location-contacts/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getByMissionAndContact = (missionId: string, contactId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionLocationContactDto>(
      {
        method: 'GET',
        url: '/api/missions-service/mission-location-contacts/get-by-mission-and-contact',
        params: {
          missionId: missionId,
          contactId: contactId,
        },
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
