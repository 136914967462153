<h5 mat-dialog-title class="flg-modal flg-modal__title flg-modal__title--no-separator">
  {{ data.localizableEntityName | abpLocalization }} {{ data.name }} distance to mission.
</h5>

<mat-dialog-content>
  <ng-container>
    <div class="container">
      <div class="row justify-content-center">
        <p><strong>Driving distance:</strong> {{ data.distance.text }}.</p>
        <p><strong>Estimated time:</strong> {{ data.duration.text }}.</p>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <button mat-flat-button mat-dialog-close cdkFocusInitial class="close">Close</button>
</mat-dialog-actions>