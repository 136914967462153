<mat-spinner *ngIf="buttonIsLoading" [diameter]="20"></mat-spinner>

<ng-container *ngIf="!buttonIsLoading && !errorOnLoad">
  <ng-container *ngIf="gridView; else orderView">
    <button class="notes-button" (click)="openNotes()" matTooltip="Add/Review Notes">
      <span class="material-symbols-outlined">news</span>
      <span *ngIf="notes.length > 0 && notes.length < 5" class="count">({{ notes.length }})</span>
      <span *ngIf="notes.length >= 5" class="count">(5+)</span>
    </button>
  </ng-container>
</ng-container>

<ng-container *ngIf="errorOnLoad">
  <span
    class="material-symbols-rounded error"
    matTooltip="Error while loading Notes, please try again later"
    >error</span
  >
</ng-container>

<ng-template #orderView>
  <button
    class="plain-button plain-button--with-button"
    mat-button
    type="button"
    (click)="openNotes()"
    matTooltip="Add/Review Notes"
  >
    <span class="material-symbols-outlined"> news </span>
    Mission Notes
  </button>
</ng-template>

<ng-template #notesModal>
  <h4 class="subtitle">Mission Name</h4>
  <h5 mat-dialog-title class="notes-modal-header">
    <span class="title">
      {{ missionName }}
    </span>
    <span class="material-symbols-outlined close" [mat-dialog-close]="true" cdkFocusInitial>
      close
    </span>
  </h5>

  <mat-dialog-content class="flg-modal flg-modal__body">
    <div class="container p-0" modalBody>
      <ngx-spinner
        bdColor="rgba(245,245,245,0.7)"
        size="default"
        color="#bc5a2e"
        type="ball-square-clockwise-spin"
        [fullScreen]="false"
      >
        <p style="color: #bc5a2e">Please wait...</p>
      </ngx-spinner>

      <mat-expansion-panel (afterCollapse)="handlePanelClose()">
        <mat-expansion-panel-header>
          <mat-panel-title> Add a New Note </mat-panel-title>
        </mat-expansion-panel-header>

        <form skipValidation [formGroup]="form" #formDirective="ngForm">
          <mat-form-field class="w-100 mb-2">
            <mat-label> Subject </mat-label>
            <input matInput formControlName="subject" />
            <mat-error *ngIf="form.get('subject').hasError('required')">
              Subject is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="w-50 pe-2">
            <mat-label>Category</mat-label>
            <mat-select name="categories" formControlName="categoryId">
              <mat-option *ngFor="let c of categories" [value]="c.id" title="{{ c.description }}">
                {{ c.description }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="form.get('categoryId')?.hasError('required')">
              Please select a value
            </mat-error>
          </mat-form-field>

          <mat-form-field class="w-50">
            <mat-label>Attention to</mat-label>
            <mat-select name="departments" formControlName="departmentId">
              <mat-option *ngFor="let d of departments" [value]="d.id" title="{{ d.description }}">
                {{ d.description }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="form.get('departmentId')?.hasError('required')">
              Please select a value
            </mat-error>
          </mat-form-field>

          <div class="w-100 mb-2">
            <p class="d-inline me-2">Who can view</p>
            <mat-checkbox formControlName="allowCustomer">Customer</mat-checkbox>
            <mat-checkbox formControlName="allowPilot">Pilot</mat-checkbox>
          </div>

          <flg-rich-text-input
            [control]="form.get('body')"
            placeholder="Enter a text"
            label="Notes"
            class="mb-3"
          ></flg-rich-text-input>

          <div class="w-100 mb-2 d-flex justify-content-end">
            <button
              mat-button
              class="outline-button me-2"
              (click)="handleCancel(formDirective)"
              type="button"
            >
              Cancel
            </button>
            <button
              mat-button
              class="common-button"
              [disabled]="form.invalid"
              type="button"
              (click)="handleSave(formDirective)"
            >
              Save
            </button>
          </div>
        </form>
      </mat-expansion-panel>

      <mat-divider class="my-4"></mat-divider>

      <div class="previous-notes">
        <h5>Previous Notes</h5>

        <ng-container *ngIf="notes.length">
          <div class="note" *ngFor="let n of notes">
            <p class="note-subject">{{ n.subject }}</p>
            <p>
              Created by <span class="note-highlight">{{ n.creatorName }}</span> at
              <span class="note-highlight">{{ n.creationTime | date: 'short' }}</span>
            </p>
            <p>
              Attention to <span class="note-highlight">{{ getDepartment(n.departamentId) }}</span>
            </p>
            <p *ngIf="getVisibility(n.extraProperties)">
              Visible to <span class="note-highlight">{{ getVisibility(n.extraProperties) }}</span>
            </p>
            <p class="note-body flg-editor-output" [innerHTML]="n.body"></p>
          </div>
        </ng-container>

        <ng-container *ngIf="notes.length === 0">
          <p class="no-notes">No notes to show</p>
        </ng-container>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
