import { Injectable } from '@angular/core';
import {
  CapturePilotCreateDto,
  CapturePilotDto,
  CapturePilotExcelDownloadDto,
  CapturePilotUpdateDto,
  GetCapturePilotsInput,
  GetPilotByMissionInput,
  GetPilotByMissionResponse,
} from '../../relationals';
import { PagedResultDto, Rest, RestService } from '@abp/ng.core';
import { DownloadTokenResultDto } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class CapturePilotService {
  apiName = 'missionsService';

  create = (input: CapturePilotCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CapturePilotDto>(
      {
        method: 'POST',
        url: '/api/missions-service/capture-pilots',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/capture-pilots/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CapturePilotDto>(
      {
        method: 'GET',
        url: `/api/missions-service/capture-pilots/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/capture-pilots/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetCapturePilotsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CapturePilotDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/capture-pilots',
        params: {
          filterText: input.filterText,
          captureId: input.captureId,
          pilotId: input.pilotId,
          pilotName: input.pilotName,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: CapturePilotExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/capture-pilots/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: CapturePilotUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CapturePilotDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/capture-pilots/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getPilotsByMissionId = (
    getPilotByMissionInput: GetPilotByMissionInput,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, GetPilotByMissionResponse[]>(
      {
        method: 'POST',
        url: '/api/missions-service/capture-pilots/get-pilots-by-mission',
        body: getPilotByMissionInput,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
