<h3>Client Information</h3>

<div class="d-flex">
  <app-info-card-with-action
    [icon]="'person_raised_hand'"
    [title]="'Requesting Customer'"
    [actionIcon]="'edit'"
    [actionIconVisible]="allowClientInfoEditRequestingCustomer"
    (actionClicked)="openRequestingModal()"
    class="w-50 me-2 fixed-height"
  >
    <div>
      <app-subtitle-description
        [subtitle]="'Company Name'"
        [description]="requestingCustomerDto?.name"
      >
      </app-subtitle-description>
      <app-subtitle-description
        *ngIf="contactRequestingDto"
        [subtitle]="'Company Project Manager'"
        [description]="contactRequestingDto?.firstName + ' ' + contactRequestingDto?.lastName"
      >
      </app-subtitle-description>
      <app-subtitle-description
        *ngIf="contactRequestingDto"
        [subtitle]="'Project Manager Email'"
        [description]="contactRequestingDto?.email"
      >
      </app-subtitle-description>
      <app-subtitle-description
        *ngIf="contactRequestingDto"
        [subtitle]="'Project Manager Phone'"
        [description]="contactRequestingDto?.phone"
      >
      </app-subtitle-description>
      <app-subtitle-description
        [subtitle]="'Subclient Name'"
        [description]="subclientCustomerDto?.name"
      >
      </app-subtitle-description>
    </div>
  </app-info-card-with-action>

  <!--    TODO hidden for ticket 9723 -->
  <!--  <app-info-card-with-action-->
  <!--    [icon]="'person_raised_hand'"-->
  <!--    [title]="'Billing Customer'"-->
  <!--    [actionIcon]="'edit'"-->
  <!--    [actionIconVisible]="allowClientInfoEditBillingCustomer"-->
  <!--    (actionClicked)="openBillingModal()"-->
  <!--    class="w-50 fixed-height"-->
  <!--  >-->
  <!--    <div *ngIf="isBillingContactLoaded">-->
  <!--      <app-subtitle-description-->
  <!--        [subtitle]="'Billing Contact First Name'"-->
  <!--        [description]="contactBillingDto?.firstName"-->
  <!--      >-->
  <!--      </app-subtitle-description>-->
  <!--      <app-subtitle-description-->
  <!--        [subtitle]="'Billing Contact Last Name'"-->
  <!--        [description]="contactBillingDto?.lastName"-->
  <!--      >-->
  <!--      </app-subtitle-description>-->
  <!--      <app-subtitle-description-->
  <!--        *ngIf="contactBillingDto"-->
  <!--        [subtitle]="'Billing Contact Email'"-->
  <!--        [description]="contactBillingDto?.email"-->
  <!--      >-->
  <!--      </app-subtitle-description>-->
  <!--      <app-subtitle-description-->
  <!--        *ngIf="contactBillingDto"-->
  <!--        [subtitle]="'Billing Contact Phone'"-->
  <!--        [description]="contactBillingDto?.phone"-->
  <!--      >-->
  <!--      </app-subtitle-description>-->
  <!--    </div>-->
  <!--  </app-info-card-with-action>-->
</div>

<div class="row">
  <div class="col-12">
    <h3 class="mb-3">Additional Contacts</h3>
  </div>
</div>

<div class="header d-flex justify-content-between align-items-center">
  <div class="row w-100">
    <div class="col">
      <mat-form-field class="w-100 me-2">
        <input
          #autoTrigger="matAutocompleteTrigger"
          type="text"
          placeholder="Search Contact"
          matInput
          [matAutocomplete]="autoContact"
          (focus)="openContactsAutocompletePanel(autoTrigger)"
          [(ngModel)]="searchTerm"
          (ngModelChange)="filterAdditionalContacts($event)"
        />
        <mat-autocomplete
          #autoContact="matAutocomplete"
          (optionSelected)="onAdditionalContactSelected($event)"
          class="site-autocomplete"
        >
          <mat-option *ngFor="let contact of additionalContactsList" [value]="contact">
            <div class="contact-info-container">
              <span class="location-name">{{contact.firstName.trim() + ' ' + contact.lastName.trim()}}</span>
              <div class="contact-details">
                <div class="location-address">{{ contact.email }}</div>
                <div class="location-address">{{ contact.phone }}</div>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-auto">
      <button
        *ngIf="allowClientInfoAddNewContact"
        class="btn btn-primary button-text d-flex justify-content-end mt-2"
        (click)="create()"
      >
        <mat-icon aria-hidden="false" aria-label="Create New Contact" fontIcon="add"></mat-icon>
        {{ 'missionsService::NewContacts' | abpLocalization }}
      </button>
    </div>
  </div>
</div>

<ngx-datatable
  class="flg-grid-table"
  [headerHeight]="45"
  [footerHeight]="55"
  [rowHeight]="45"
  [rows]="data.items"
  [count]="data.totalCount"
  default
  #dataTable
>
  <ngx-datatable-column name="Contact From" prop="contactType">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.contactTypeDescription }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{ 'missionsService::Name' | abpLocalization }}">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.firstName }} {{ row.lastName }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'missionsService::Email' | abpLocalization }}" prop="email">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.email }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'missionsService::Phone' | abpLocalization }}" prop="phone">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.phone }}
    </ng-template>
  </ngx-datatable-column>

  <!-- TODO: hidden this field for ticket 10071 - Share Data feature is missing, hide it if it's not going to be built for Mid Dec -->
  <!-- <ngx-datatable-column
    name="{{ 'missionsService::ShareData' | abpLocalization }}"
    prop="shareData"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.shareData ? 'Yes' : 'No' }}
    </ng-template>
  </ngx-datatable-column> -->

  <ngx-datatable-column
    [name]="'AbpUi::Actions' | abpLocalization"
    [maxWidth]="150"
    [width]="150"
    [sortable]="false"
  >
    <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
      <app-column-actions [columnActions]="columnActions(row)"></app-column-actions>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<ng-template #contactModal>
  <flg-generic-modal>
    <ng-container modalTitle>{{
      (selected ? 'AbpUi::EditContact' : 'missionsService::NewContacts') | abpLocalization
    }}</ng-container>

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form [formGroup]="form" id="contacts" skipValidation>
          <mat-form-field class="w-100">
            <mat-label>Contact From</mat-label>
            <mat-select name="contactTypeId" formControlName="contactTypeId">
              <mat-option *ngFor="let contactfrom of contactType.items" [value]="contactfrom.id">
                {{ contactfrom.description | abpLocalization }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100 mb-2">
            <mat-label>{{ 'missionsService::Email' | abpLocalization }}</mat-label>
            <input matInput formControlName="email" class="form-control" maxlength="50" />
            <mat-hint *ngIf="showHintEditContactEmail">{{
              'customersService::OnlyEditContactsEmail' | abpLocalization
            }}</mat-hint>
            <mat-error *ngIf="form.get('email').hasError('email')">
              Please enter a proper email address
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('emailExists')">
              This email already exists
            </mat-error>
          </mat-form-field>

          <div class="d-flex">
            <mat-form-field class="w-100 pe-2">
              <mat-label> {{ 'missionsService::FirstName' | abpLocalization }}</mat-label>
              <input matInput formControlName="firstName" class="form-control" maxlength="100" />
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>{{ 'missionsService::LastName' | abpLocalization }}</mat-label>
              <input matInput formControlName="lastName" class="form-control" maxlength="100" />
            </mat-form-field>
          </div>
          <div class="w-100">
            <flg-phone-input
              [control]="form.get('phone')"
              [label]="'Phone'"
              class="phone"
              [widthClass]="'w-100'"
            ></flg-phone-input>
          </div>
          <!-- TODO: hidden this field for ticket 10071 - Share Data feature is missing, hide it if it's not going to be built for Mid Dec -->
          <!-- <input
            name="shareData"
            type="checkbox"
            formControlName="shareData"
            [ngModelOptions]="{ standalone: true }"
            style="margin: 10px 10px 20px 0"
          />
          <label class="form-label mt-2" for="contacts-shareData">
            Share data with this contact
          </label> -->
          <flg-pending-controls
            [touched]="form.touched"
            [controls]="getPendingControls()"
          ></flg-pending-controls>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        class="outline-button me-2"
        type="button"
        (click)="closeModal()"
        [disabled]="isModalBusy"
      >
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        (click)="submitForm()"
        [disabled]="form.invalid || isModalBusy"
      >
        {{ 'AbpUi::Save' | abpLocalization }}
      </button>
    </div>
  </flg-generic-modal>
</ng-template>

<ng-template #requestingModal>
  <flg-generic-modal>
    <ng-container modalTitle>Edit Requesting Customer</ng-container>

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form id="contact-types" [formGroup]="formRequesting" skipValidation>
          <mat-form-field class="w-100">
            <mat-label>Company Name</mat-label>
            <input
              matInput
              [matAutocomplete]="auto"
              [formControl]="companyNameControl"
              cdkFocusInitial
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let company of filteredCustomers | async" [value]="company">
                {{ company }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="companyNameControl.hasError('required')">
              This field is required
            </mat-error>
            <mat-error *ngIf="companyNameControl.hasError('invalidAutocompleteObject')">
              Please select a valid company
            </mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <input
              #autoTrigger="matAutocompleteTrigger"
              type="text"
              placeholder="Search Contact"
              matInput
              formControlName="projectManagerSearch"
              [matAutocomplete]="autoContact"
              (focus)="openAutocompletePanel(autoTrigger)"
            />

            <mat-autocomplete
              #autoContact="matAutocomplete"
              [displayWith]="displayFn"
              (optionSelected)="onContactSelected($event)"
              class="site-autocomplete"
            >
              <mat-option *ngFor="let contact of customerContactsList" [value]="contact">
                <div class="contact-info-container">
                  <span class="location-name">
                    {{contact.firstName.trim() + ' ' + contact.lastName.trim()}}
                  </span>
                  <div class="contact-details">
                    <div class="location-address">{{ contact.email }}</div>
                    <div class="location-address">{{ contact.phone }}</div>
                  </div>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="w-50 pe-2">
            <mat-label>Project Manager First Name</mat-label>
            <input matInput placeholder="First Name" formControlName="projectManagerFirstName" />
          </mat-form-field>

          <mat-form-field class="w-50">
            <mat-label>Project Manager Last Name</mat-label>
            <input matInput placeholder="Last Name" formControlName="projectManagerLastName" />
          </mat-form-field>

          <div class="d-flex">
            <mat-form-field class="w-100 pe-2">
              <mat-label>Project Manager Email</mat-label>
              <input
                matInput
                formControlName="projectManagerEmail"
                placeholder="Project Manager Email"
              />
            </mat-form-field>

            <flg-phone-input
              [control]="formRequesting.get('projectManagerNumber')"
              [label]="'Project Manager Phone'"
              class="phone"
              [widthClass]="'w-100'"
            ></flg-phone-input>
          </div>

          <mat-form-field class="w-100">
            <mat-label>Sub Client Name</mat-label>
            <input
              #autoTrigger="matAutocompleteTrigger"
              type="text"
              [placeholder]="'missionsService::EditOrder:SubClient' | abpLocalization"
              matInput
              formControlName="subclientName"
              [matAutocomplete]="autSubscostumer"
              (input)="search($event.target.value)"
              (blur)="onInputBlur($event.target.value)"
            />

            <mat-autocomplete
              #autSubscostumer="matAutocomplete"
              (optionSelected)="onSubClientSelected($event)"
              class="site-autocomplete"
            >
              <mat-option *ngFor="let customer of subClientsList" [value]="customer">
                <div class="contact-info-container">
                  {{ customer.name }}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-end" modalActions>
      <button mat-flat-button class="outline-button me-2" type="button" (click)="closeModal()">
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        [disabled]="formRequesting.invalid || companyNameControl.invalid"
        type="button"
        (click)="saveRequestingCustomer()"
      >
        {{ 'AbpUi::Save' | abpLocalization }}
      </button>
    </div>
  </flg-generic-modal>
</ng-template>

<ng-template #billingModal>
  <flg-generic-modal>
    <ng-container modalTitle>Edit Billing Requesting Customer</ng-container>

    <div class="container p-0">
      <div class="row justify-content-center" modalBody>
        <form [formGroup]="formBilling">
          <div class="mt-2">
            <mat-form-field class="w-100">
              <mat-label>Billing Contact First Name</mat-label>
              <input matInput formControlName="firstName" maxlength="100" />
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Billing Contact Last Name</mat-label>
              <input matInput formControlName="lastName" maxlength="100" />
            </mat-form-field>

            <div class="d-flex">
              <mat-form-field class="w-100 pe-2">
                <mat-label>Billing Contact Email</mat-label>
                <input matInput formControlName="email" type="email" />
              </mat-form-field>

              <flg-phone-input
                [control]="formBilling.get('phone')"
                [label]="'Billing Customer Phone Number'"
                class="phone"
                [widthClass]="'w-100'"
              ></flg-phone-input>
            </div>
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-end mt-3" modalActions>
        <button mat-flat-button class="outline-button me-2" type="button" (click)="closeModal()">
          {{ 'AbpUi::Cancel' | abpLocalization }}
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="formBilling.invalid"
          class="common-button"
          type="button"
          (click)="saveBillingCustomer()"
        >
          {{ 'AbpUi::Save' | abpLocalization }}
        </button>
      </div>
    </div>
  </flg-generic-modal>
</ng-template>
