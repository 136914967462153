import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BadgeCategoryCreateDto, BadgeCategoryDto, BadgeCategoryUpdateDto, GetBadgeCategoriesInput } from '../../relationals/models';

@Injectable({
  providedIn: 'root',
})
export class BadgeCategoryService {
  apiName = 'missionsService';
  

  create = (input: BadgeCategoryCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BadgeCategoryDto>({
      method: 'POST',
      url: '/api/missions-service/badge-categories',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/badge-categories/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BadgeCategoryDto>({
      method: 'GET',
      url: `/api/missions-service/badge-categories/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetBadgeCategoriesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<BadgeCategoryDto>>({
      method: 'GET',
      url: '/api/missions-service/badge-categories',
      params: { filterText: input.filterText, name: input.name, orderMin: input.orderMin, orderMax: input.orderMax, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: BadgeCategoryUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BadgeCategoryDto>({
      method: 'PUT',
      url: `/api/missions-service/badge-categories/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
