import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { EnvironmentService } from '@abp/ng.core';

export const canAccessLower: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const env = inject(EnvironmentService).getEnvironment();

  if (env.production) {
    return inject(Router).createUrlTree(['/']);
  }

  return true;
};
