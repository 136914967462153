import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ParallelFileUploadService } from '../../services/parallel-file-upload.service';
import {
  DirectoryTreeService,
  NavigatorService,
  UpdateStreamService,
  UploadService,
} from '../../services';
import { eFileManagementPolicyNames } from '@volo/abp.ng.file-management/config';
import { DirectoryDescriptorInfoDto } from '@volo/abp.ng.file-management/proxy';
import { Subscription } from 'rxjs';
import { EnvironmentService } from '@abp/ng.core';

@Component({
  selector: 'abp-file-management-file-upload',
  templateUrl: './file-management-file-upload.component.html',
  styleUrls: ['./file-management-file-upload.component.scss'],
})
export class FileManagementFileUploadComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private uploadService: UploadService,
    private navigatorService: NavigatorService,
    private updateStream: UpdateStreamService,
    public _parallelFileUploadService: ParallelFileUploadService,
    public service: DirectoryTreeService,
    private cdr: ChangeDetectorRef,
    private environment: EnvironmentService,
  ) {}

  @Input() rootId: string;

  @ViewChild('dropzoneInput') fileInput: ElementRef;

  fileCreatePermission = eFileManagementPolicyNames.FileDescriptorCreate;

  subscription = new Subscription();
  currentSelectedNode: DirectoryDescriptorInfoDto;

  folderName = '';
  ROOT_FOLDER_NAME = 'Root';
  ROOT_FOLDER_ID = 'ROOT_ID';
  isModalOpen = false;
  isBusy = false;

  allowedFileExtensions = this.environment.getEnvironment().allowedFileExtensions;

  ngOnInit(): void {
    this.initTitle();

    this._parallelFileUploadService.PreInfoDone.subscribe(res => {
      this.isBusy = false;
      this.isModalOpen = !res;
    });

    this.subscription.add(
      this.service.currentSelectedNode$.subscribe(selectedNode => {
        if (!this.currentSelectedNode || this.currentSelectedNode?.id !== selectedNode?.id) {
          this.currentSelectedNode = selectedNode;
          this.cdr.detectChanges();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rootId) {
      this.uploadService.rootId = this.rootId;
    }
  }

  private initTitle(): void {
    this.navigatorService.currentFolder$.subscribe(({ name }) => {
      this.folderName = name;
    });
  }

  InputChangeHandle(event: any) {
    let files: File[] = event.target.files;

    for (var file of files) {
      this._parallelFileUploadService.addFile(file);
    }
  }

  removeFile(file) {
    this._parallelFileUploadService.removeFile(file);
  }

  uploadFiles() {
    this.isBusy = true;
    const directoryId = this.updateStream.currentDirectory || this.rootId;
    this._parallelFileUploadService.uploadFilesV3(directoryId);
  }

  closeModal() {
    this.isModalOpen = false;
  }

  openModal() {
    this.isModalOpen = true;
  }

  openProgressViewer() {
    this._parallelFileUploadService.openProgressViewer();
  }

  OnModalClose() {
    this._parallelFileUploadService.removeFiles();
  }

  canUpload(): boolean {
    if (!this.currentSelectedNode) return true;

    if (this.currentSelectedNode.isRoot) return true;

    if (this.currentSelectedNode.canWrite) return true;

    if (
      this.currentSelectedNode.name == this.ROOT_FOLDER_NAME ||
      this.currentSelectedNode.id == this.ROOT_FOLDER_ID
    )
      return true;

    return false;
  }
}
