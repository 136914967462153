import { Component, Input, OnInit } from '@angular/core';
import { OrderFormModel } from '../../model/order-form.model';
import { OrderFormDetailsModel } from '../../model/order-form-detail.model';

@Component({
  selector: 'app-orders-detail',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent {
  @Input() orderDetails: OrderFormDetailsModel;

  constructor() {}
}