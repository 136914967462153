import { EnvironmentService, RestService } from '@abp/ng.core';
import type { ListResultDto, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IRemoteStreamContent } from '../../abp/content/models';
import { FileDumpingSessionDto } from './models';
import { FilesDumpingSessionStatus } from './files-dumping-session-status.enum';

@Injectable({
  providedIn: 'root',
})
export class FileDumpingSessionService {
  apiName = 'FileManagement';

  create = (input: FileDumpingSessionDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileDumpingSessionDto>(
      {
        method: 'POST',
        url: '/api/file-management-service/file-dumping-sessions',
        body: input,
      },
      { apiName: this.apiName }
    );

  getByCorrelationalId = (correlationalId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileDumpingSessionDto>(
      {
        method: 'GET',
        url: `/api/file-management-service/file-dumping-sessions/correlationId/${correlationalId}`,
      },
      { apiName: this.apiName }
    );

  getFileDumpingStatusDescription(status: FilesDumpingSessionStatus): string {
    const statusMap: Map<FilesDumpingSessionStatus, string> = new Map([
      [FilesDumpingSessionStatus.Created, 'Created'],
      [FilesDumpingSessionStatus.Inprogres, 'In Progress'],
      [FilesDumpingSessionStatus.Failed, 'Failed'],
      [FilesDumpingSessionStatus.Successful, 'Succesfully Done'],
      [FilesDumpingSessionStatus.None, 'New Session'],
    ]);

    return statusMap.get(status);
  }

  constructor(private restService: RestService, private readonly environment: EnvironmentService) {}
}
