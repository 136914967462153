import { ErrorHandler, NgModule, Injector, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

import { httpInterceptorProviders } from './http-Interceptors';

import { CoreModule } from '@abp/ng.core';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { HTTP_ERROR_HANDLER, ThemeSharedModule, ToasterService } from '@abp/ng.theme.shared';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { AccountConfigModule } from '@abp/ng.account/config';
import { AccountLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/account';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { ProductServiceConfigModule } from 'product-service/config';
import { CoreServiceConfigModule } from 'core-service/config';
import { SharedModule } from './shared/shared.module';
import { AccountModule } from './account/account.module';

import { TextTemplateManagementConfigModule } from 'text-template-management/config';
import { FileManagementConfigModule } from 'projects/file-management/config/src/file-management-config.module';

import { ChatConfigModule } from 'projects/chat/config/src/chat-config.module';
import { MissionsServiceConfigModule } from 'missions-service/config';
import { PilotsServiceConfigModule } from 'pilots-service/config';
import { CustomersServiceConfigModule } from 'customers-service/config';
import { NotificationsServiceConfigModule } from 'notifications-service/config';
import { OrdersModule } from './components/orders/orders.module';
import { RedirectGuardComponent } from './guards/redirect.component';

import { NgxActionFrameworkModule } from '@newtonvision/ngx-action-framework';

import { MessageSucessComponent } from './components/common/message/message.success.component';

import { NotFoundComponent } from './components/tagus/not-found/not-found.component';

import { ConfirmDialogComponent } from './components/common/confirm-dialog/confirm.dialog.component';

import { ActionsCellRendererComponent } from './components/table/renderers/actions-cell-renderer/actions-cell-renderer.component';
import { ButtonCellRendererComponent } from './components/table/renderers/button-cell-renderer/button-cell-renderer.component';
import { ForbiddenComponent } from './components/tagus/forbidden/forbidden.component';
import { InternalServerErrorComponent } from './components/tagus/internal-server-error/internal-server-error.component';
import { Router } from '@angular/router';

import { APP_INITIALIZER } from '@angular/core';
import { ReplaceableComponentsService } from '@abp/ng.core';
import { eThemeLeptonXComponents } from '@volosoft/abp.ng.theme.lepton-x';
import { AccessDeniedComponent } from './components/tagus/access-denied/access-denied.component';
import { FLGErrorHandler } from './flg-error-handler/flg-error-handler';

import { ToastrModule } from 'ngx-toastr';

import { FlyguysToastService } from '@flyguys/toast';
import { FlyguysToastComponent } from '@flyguys/toast';
import { FileSizePipe } from './pipes/file-size.pipe';
import { AuditLoggingConfigModule } from 'projects/audit-logging/config/src/audit-logging-config.module';
import { NoInternetComponent } from './components/tagus/no-internet/no-internet/no-internet.component';

import { CommercialUiModule, AdvancedEntityFiltersModule } from '@volo/abp.commercial.ng.ui';
import { actionFrameworkdbConfig } from './shared/indexDb-config/action-framework.db.config';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { ApplicationLayoutComponent } from './components/layout/application-layout/application-layout.component';

export function handleHttpErrors(injector: Injector, httpError: HttpErrorResponse) {
  if (
    httpError.status === 403 &&
    httpError.error?.error?.code?.toLocaleLowerCase() !== 'volo.abp.identity:invalidusername' &&
    httpError.error?.error?.code?.toLocaleLowerCase() !==
    'volo.abp.identity:passwordrequiresnonalphanumeric' &&
    httpError.error?.error?.code?.toLocaleLowerCase() !== 'volo.abp.identity:duplicateusername'
  ) {
    const router = injector.get(Router);
    router.navigateByUrl('/forbidden');
    return;
  }

  if (httpError.status === 500) {
    const router = injector.get(Router);
    router.navigateByUrl('/500');
    return;
  }

  if (
    httpError.status === 400 ||
    httpError.error?.error?.code?.toLocaleLowerCase() === 'volo.abp.identity:invalidusername' ||
    httpError.error?.error?.code?.toLocaleLowerCase() ===
    'volo.abp.identity:passwordrequiresnonalphanumeric' ||
    httpError.error?.error?.code?.toLocaleLowerCase() === 'volo.abp.identity:duplicateusername'
  ) {
    const toaster = injector.get(ToasterService);
    toaster.error(httpError.error?.error?.message || 'Unknown error ocurred.');
    return;
  }

  if (httpError.status === 404) {
    const url = httpError.url;
    if (url && (
        url.includes('/api/core-service/pilot-experience-level/') ||
        url.includes('/api/core-service/availabilities/')
    )) {
      // For these specific 404 errors, we'll just log and return without throwing.
      // In some cases pilots' experience level/availability might have been deleted from the CORE service, but it's not a critical error.
      console.warn(`Resource not found at ${url}. This error is being omitted.`);
      return;
    }
  }

  if (httpError.status === 0 && httpError.statusText?.toLowerCase() === 'unknown error') {
    const router = injector.get(Router);
    router.navigateByUrl('/no-internet');
    return;
  }

  return throwError(httpError);
}

function initEmptyLayout() {
  const replaceableComponents = inject(ReplaceableComponentsService);
  return function () {
    replaceableComponents.add({
      key: eThemeLeptonXComponents.EmptyLayout,
      component: ApplicationLayoutComponent,
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ActionsCellRendererComponent,
    ButtonCellRendererComponent,
    MessageSucessComponent,
    NotFoundComponent,
    RedirectGuardComponent,
    ConfirmDialogComponent,
    InternalServerErrorComponent,
    ForbiddenComponent,
    AccessDeniedComponent,
    NoInternetComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot(),
    SharedModule,
    AccountLayoutModule.forRoot(),
    AccountConfigModule.forRoot(),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    ProductServiceConfigModule.forRoot(),
    CoreServiceConfigModule.forRoot(),
    FileManagementConfigModule.forRoot(),
    AccountModule,
    ChatConfigModule.forRoot(),
    MissionsServiceConfigModule.forRoot(),
    PilotsServiceConfigModule.forRoot(),
    CustomersServiceConfigModule.forRoot(),
    NotificationsServiceConfigModule.forRoot(),
    OrdersModule,
    NgxActionFrameworkModule.forRootDynamicEnv({
      api: environment.apis.ActionFramework.url,
    }),
    ToastrModule.forRoot({
      toastComponent: FlyguysToastComponent,
      toastClass: 'flyguys-toast',
      titleClass: 'flyguys-toast__title',
      messageClass: 'flyguys-toast__message',
    }),
    CommercialUiModule,
    AdvancedEntityFiltersModule,
    NgxIndexedDBModule.forRoot(actionFrameworkdbConfig)
  ],
  providers: [
    { provide: ErrorHandler, useClass: FLGErrorHandler },
    { provide: HTTP_ERROR_HANDLER, useValue: handleHttpErrors },
    {
      provide: APP_INITIALIZER,
      useFactory: initEmptyLayout,
      multi: true,
    },
    {
      provide: ToasterService,
      useClass: FlyguysToastService,
    },
    APP_ROUTE_PROVIDER,
    // RedirectGuard,
    httpInterceptorProviders,
    FileSizePipe,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }
