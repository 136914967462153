import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  recurrenceEnum,
  recurrenceEnumDisplayNames,
} from '../../../../proxy/missions-service/shared/recurrency.enum';
import {
  CaptureMustBeEnum,
  captureMustBeEnumDisplayNames,
} from '../../../../proxy/missions-service/shared/capture-mustbe.enum';
import { Router } from '@angular/router';
import { CopyMissionForm } from '../models/copy-mission-form';
import { ConfigStateService } from '@abp/ng.core';

@Component({
  selector: 'lib-copy-mission-modal',
  templateUrl: './copy-mission-modal.component.html',
  styleUrls: ['./copy-mission-modal.component.scss'],
})
export class CopyMissionModalComponent implements OnInit {
  copyMissionForm: FormGroup;

  datafrequency = Object.keys(recurrenceEnum)
    .filter(key => typeof recurrenceEnum[key] === 'number')
    .map(key => ({
      value: recurrenceEnum[key],
      description: recurrenceEnumDisplayNames[recurrenceEnum[key]],
    }));

  dataCaptureMust = Object.keys(CaptureMustBeEnum)
    .filter(key => typeof CaptureMustBeEnum[key] === 'number')
    .map(key => ({
      value: CaptureMustBeEnum[key],
      description: captureMustBeEnumDisplayNames[CaptureMustBeEnum[key]],
    }));

  currentUserId: string = '';
  jobId: string = '';

  constructor(
    public dialogRef: MatDialogRef<CopyMissionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CopyMissionForm,
    private formBuilder: FormBuilder,
    private router: Router,
    private stateService: ConfigStateService
  ) {
    this.createForm();
    this.prefillForm();
    this.jobId = data.jobId;
  }

  ngOnInit() {
    this.currentUserId = this.stateService.getDeep('currentUser.id');
  }

  createForm() {
    this.copyMissionForm = this.formBuilder.group({
      missionId: ['', Validators.required],
      missionName: ['', Validators.required],
      captureDate: ['', Validators.required],
      captureTime: [''],
      repeats: [recurrenceEnum.NoRepeat, Validators.required],
      captureDateMust: [CaptureMustBeEnum.AnytimeBefore],
      client: [true, Validators.required],
      additionalContacts: [false],
      orderRequest: [false],
      orderPriority: [false],
      assetOrderNumber: [false],
      missionSummary: [false],
      siteInformation: [false],
      deliverableDueDate: [false, Validators.required],
      productsPackages: [false],
    });

    this.copyMissionForm.get('repeats').disable();
    this.copyMissionForm.get('client').disable();
  }

  prefillForm() {
    this.copyMissionForm.patchValue({
      missionId: this.data.missionId,
      missionName: this.data.missionName,
      captureDate: this.data.captureDate,
      captureTime: this.data.captureTime,
      captureDateMust: this.data.captureDateMust,
    });
  }

  onOrderRequestChange(): void {
    const orderRequestChecked = this.copyMissionForm.get('orderRequest').value;
    this.setOrderRequestChildControls(orderRequestChecked);
  }

  onOrderRequestChildChange(): void {
    const orderRequestChildControls = [
      'orderPriority',
      'assetOrderNumber',
      'missionSummary',
      'siteInformation',
      'deliverableDueDate',
    ];

    const anyChildChecked = orderRequestChildControls.some(
      control => this.copyMissionForm.get(control).value
    );

    this.copyMissionForm.patchValue({ orderRequest: anyChildChecked });
  }

  setOrderRequestChildControls(checked: boolean): void {
    const orderRequestChildControls = [
      'orderPriority',
      'assetOrderNumber',
      'missionSummary',
      'siteInformation',
      'deliverableDueDate',
    ];

    orderRequestChildControls.forEach(control => {
      this.copyMissionForm.patchValue({ [control]: checked });
    });
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  async onClickCopy(): Promise<void> {
    if (this.copyMissionForm.valid) {
      const formValue = this.copyMissionForm.getRawValue();
      const copyMissionForm: CopyMissionForm = {
        missionId: formValue.missionId,
        jobId: this.jobId,
        missionName: formValue.missionName,
        captureDate: formValue.captureDate,
        captureTime: formValue.captureTime,
        repeats: formValue.repeats,
        captureDateMust: formValue.captureDateMust,
        client: formValue.client,
        additionalContacts: formValue.additionalContacts,
        orderRequest: formValue.orderRequest,
        orderPriority: formValue.orderPriority,
        assetOrderNumber: formValue.assetOrderNumber,
        missionSummary: formValue.missionSummary,
        siteInformation: formValue.siteInformation,
        deliverableDueDate: formValue.deliverableDueDate,
        productsPackages: formValue.productsPackages,
      };

      this.storeFormDataInLocalStorage(copyMissionForm);
      await this.router.navigate(['/orders/new'], {
        queryParams: { missionId: formValue.missionId },
      });
      this.dialogRef.close(null);
    }
  }

  storeFormDataInLocalStorage(formData: CopyMissionForm): void {
    const key = `copyMissionForm_${formData.missionId}_${this.currentUserId}`;
    localStorage.setItem(key, JSON.stringify(formData));
  }

  IsValid(): boolean {
    return this.copyMissionForm.valid;
  }
}
