import { ModuleWithProviders, NgModule } from '@angular/core';
import { MISSIONS_SERVICE_ROUTE_PROVIDERS } from './providers/route.provider';
import { BASICS_NOTES_ROUTE_PROVIDER } from './providers/notes-route.provider';
import { BASICS_LOCATIONS_ROUTE_PROVIDER } from './providers/locations-route.provider';
import { BASICS_ADDRESSES_ROUTE_PROVIDER } from './providers/addresses-route.provider';
import { BASICS_CONTACTS_ROUTE_PROVIDER } from './providers/contacts-route.provider';
import { BASICS_BADGES_ROUTE_PROVIDER } from './providers/badges-route.provider';
import { BASICS_CREDENTIALS_ROUTE_PROVIDER } from './providers/credentials-route.provider';
import { BASICS_DELIVERABLES_ROUTE_PROVIDER } from './providers/deliverables-route.provider';
import { BASICS_EQUIPMENTS_ROUTE_PROVIDER } from './providers/equipments-route.provider';
import { BASICS_SERVICES_ROUTE_PROVIDER } from './providers/services-route.provider';
import { BASICS_MISSION_QUALITY_QUESTIONNARIES_ROUTE_PROVIDER } from './providers/mission-quality-questionnaries-route.provider';
import { BASICS_QUESTIONS_ROUTE_PROVIDER } from './providers/questions-route.provider';
import { BASICS_RESPONSES_ROUTE_PROVIDER } from './providers/responses-route.provider';
import { BASICS_LOCATION_CONTACTS_ROUTE_PROVIDER } from './providers/location-contacts-route.provider';
import { BASICS_MISSIONS_ROUTE_PROVIDER } from './providers/missions-route.provider';
import { BASICS_PORTAFOLIO_CONTACTS_ROUTE_PROVIDER } from './providers/portafolio-contacts-route.provider';
import { BASICS_PORTAFOLIOS_ROUTE_PROVIDER } from './providers/portafolios-route.provider';
import { BASICS_PROJECTS_ROUTE_PROVIDER } from './providers/projects-route.provider';
import { BASICS_CAPTURES_ROUTE_PROVIDER } from './providers/captures-route.provider';
import { BASICS_FLIGHT_DELIVERABLES_ROUTE_PROVIDER } from './providers/flight-deliverables-route.provider';
import { BASICS_MISSION_DELIVERABLES_ROUTE_PROVIDER } from './providers/mission-deliverables-route.provider';
import { BASICS_ORDER_DETAILS_ROUTE_PROVIDER } from './providers/order-details-route.provider';
import { BASICS_ORDERS_ROUTE_PROVIDER } from './providers/orders-route.provider';
import { BASICS_MISSION_PILOT_NOTIFICATIONS_ROUTE_PROVIDER } from './providers/mission-pilot-notifications-route.provider';
import { BASICS_MISSION_PILOT_RATING_NOTES_ROUTE_PROVIDER } from './providers/mission-pilot-rating-notes-route.provider';
import { BASICS_MISSION_PILOT_RATINGS_ROUTE_PROVIDER } from './providers/mission-pilot-ratings-route.provider';
import { RELATIONALS_DELIVERABLE_SERVICES_ROUTE_PROVIDER } from './providers/deliverable-services-route.provider';
import { RELATIONALS_PRODUCT_DELIVERABLES_ROUTE_PROVIDER } from './providers/product-deliverables-route.provider';
import { RELATIONALS_SERVICE_BADGES_ROUTE_PROVIDER } from './providers/service-badges-route.provider';
import { RELATIONALS_SERVICE_CREDENTIALS_ROUTE_PROVIDER } from './providers/service-credentials-route.provider';
import { RELATIONALS_SERVICE_EQUIPMENTS_ROUTE_PROVIDER } from './providers/service-equipments-route.provider';
import { RELATIONALS_MISSION_PILOTS_ROUTE_PROVIDER } from './providers/mission-pilots-route.provider';
import { RELATIONALS_MISSION_NOTE_ROUTE_PROVIDER } from './providers/mission-note-route.provider';
import { BASICS_TEMPLATE_ROUTE_PROVIDER } from './providers/template-route.provider';
import { RELATIONALS_MISSION_FINANCIAL_REVENUE_ROUTE_PROVIDER } from './providers/mission-financial-revenue-route.provider';
import { RELATIONAL_MISSION_FINANCIAL_EXPENSE_ROUTE_PROVIDER } from './providers/mission-financial-expense-route.provider';
import { RELATIONALS_PACKAGE_ROUTE_PROVIDER } from './providers/package-route.provider';
import { RELATIONALS_PACKAGE_DETAIL_ROUTE_PROVIDER } from './providers/package-detail-route.provider';
import { RELATIONALS_CAPTURE_PILOT_ROUTE_PROVIDER } from './providers/capture-pilot-route.provider';
import { BASICS_ATTRIBUTES_ROUTE_PROVIDER } from './providers/attributes-route.provider';
import { BASICS_DELIVERABLE_ATTRIBUTES_ROUTE_PROVIDER } from './providers/deliverable-attributes-route.provider';
import { BASICS_PRODUCTS_ROUTE_PROVIDER } from './providers/products-route.provider';
import { BASICS_TEAM_ORDERS_ROUTE_PROVIDER } from './providers/team-orders-route.provider';
import { BASICS_STATUS_MISSION_ORDER_ROUTE_PROVIDER } from './providers/status-mission-order-route.provider';
import { RELATIONALS_BADGES_CATEGORIES_ROUTE_PROVIDER } from './providers/badges-categories-route.provider';
import { RELATIONALS_CUSTOM_GRID_VIEWS_ROUTE_PROVIDER } from './providers/custom-grid-views-route.provider';
import { BASICS_ORDER_MISSION_EVENTS_ROUTE_PROVIDER } from './providers/order-mission-events.provider';
import { RELATIONALS_ATTRIBUTE_VALIDATION_DEFINITION_ROUTE_PROVIDER } from './providers/attribute-validation-definition-route.provider';

@NgModule()
export class MissionsServiceConfigModule {
  static forRoot(): ModuleWithProviders<MissionsServiceConfigModule> {
    return {
      ngModule: MissionsServiceConfigModule,
      providers: [
        MISSIONS_SERVICE_ROUTE_PROVIDERS,
        BASICS_NOTES_ROUTE_PROVIDER,
        BASICS_LOCATIONS_ROUTE_PROVIDER,
        BASICS_ADDRESSES_ROUTE_PROVIDER,
        BASICS_ATTRIBUTES_ROUTE_PROVIDER,
        BASICS_DELIVERABLE_ATTRIBUTES_ROUTE_PROVIDER,
        BASICS_PRODUCTS_ROUTE_PROVIDER,
        BASICS_CONTACTS_ROUTE_PROVIDER,
        BASICS_BADGES_ROUTE_PROVIDER,
        BASICS_CREDENTIALS_ROUTE_PROVIDER,
        BASICS_DELIVERABLES_ROUTE_PROVIDER,
        BASICS_EQUIPMENTS_ROUTE_PROVIDER,
        BASICS_SERVICES_ROUTE_PROVIDER,
        BASICS_MISSION_QUALITY_QUESTIONNARIES_ROUTE_PROVIDER,
        BASICS_QUESTIONS_ROUTE_PROVIDER,
        BASICS_RESPONSES_ROUTE_PROVIDER,
        BASICS_LOCATION_CONTACTS_ROUTE_PROVIDER,
        BASICS_MISSIONS_ROUTE_PROVIDER,
        BASICS_PORTAFOLIO_CONTACTS_ROUTE_PROVIDER,
        BASICS_PORTAFOLIOS_ROUTE_PROVIDER,
        BASICS_PROJECTS_ROUTE_PROVIDER,
        BASICS_CAPTURES_ROUTE_PROVIDER,
        BASICS_FLIGHT_DELIVERABLES_ROUTE_PROVIDER,
        BASICS_MISSION_DELIVERABLES_ROUTE_PROVIDER,
        BASICS_ORDER_DETAILS_ROUTE_PROVIDER,
        BASICS_ORDERS_ROUTE_PROVIDER,
        BASICS_MISSION_PILOT_NOTIFICATIONS_ROUTE_PROVIDER,
        BASICS_MISSION_PILOT_RATING_NOTES_ROUTE_PROVIDER,
        BASICS_MISSION_PILOT_RATINGS_ROUTE_PROVIDER,
        RELATIONALS_DELIVERABLE_SERVICES_ROUTE_PROVIDER,
        RELATIONALS_PRODUCT_DELIVERABLES_ROUTE_PROVIDER,
        RELATIONALS_SERVICE_BADGES_ROUTE_PROVIDER,
        RELATIONALS_SERVICE_CREDENTIALS_ROUTE_PROVIDER,
        RELATIONALS_SERVICE_EQUIPMENTS_ROUTE_PROVIDER,
        RELATIONALS_MISSION_PILOTS_ROUTE_PROVIDER,
        RELATIONALS_MISSION_NOTE_ROUTE_PROVIDER,
        BASICS_TEMPLATE_ROUTE_PROVIDER,
        RELATIONALS_MISSION_FINANCIAL_REVENUE_ROUTE_PROVIDER,
        RELATIONAL_MISSION_FINANCIAL_EXPENSE_ROUTE_PROVIDER,
        RELATIONALS_PACKAGE_ROUTE_PROVIDER,
        RELATIONALS_PACKAGE_DETAIL_ROUTE_PROVIDER,
        RELATIONALS_CAPTURE_PILOT_ROUTE_PROVIDER,
        BASICS_TEAM_ORDERS_ROUTE_PROVIDER,
        BASICS_STATUS_MISSION_ORDER_ROUTE_PROVIDER,
        RELATIONALS_BADGES_CATEGORIES_ROUTE_PROVIDER,
        RELATIONALS_CUSTOM_GRID_VIEWS_ROUTE_PROVIDER,
        BASICS_ORDER_MISSION_EVENTS_ROUTE_PROVIDER,
        RELATIONALS_ATTRIBUTE_VALIDATION_DEFINITION_ROUTE_PROVIDER,
      ],
    };
  }
}
