import { Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../tagus/customizer-settings/customizer-settings.service';
import { ABP, ListService, PagedResultDto, mapEnumToOptions } from '@abp/ng.core';
import { BulkUploadDetailsService, BulkUploadsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { BulkUploadsDto, GetBulkUploadsInput, enumState } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { ColumnAction } from '../../columns/components/column-actions/column-actions.component';
import { ColumnStatus } from '../../columns/components/column-displayer/column-displayer.component';
import { enumBulkUpload } from 'projects/missions-service/src/lib/proxy/missions-service/basics/enum-bulkupload.enum';
import { OrderBulkStates } from '../../columns/rules/lookup-rules';
import { MatDialog } from '@angular/material/dialog';
import { MissionBulkUploadDetailComponent } from '../mission-bulk-upload-detail/mission-bulk-upload-detail.component';
import { GetIndustryInput, IndustriesDto, PrioritiesDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { IndustriesService, PrioritiesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { CustomersDto, GetCustomerInput } from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';

@Component({
  selector: 'app-mission-bulk-upload',
  templateUrl: './mission-bulk-upload.component.html',
  styleUrls: ['./mission-bulk-upload.component.scss'],
  providers: [ListService]
})
export class MissionBulkUploadComponent implements OnInit {
  
  data: PagedResultDto<BulkUploadsDto> = {
    items: [],
    totalCount: 0,
  };

  customers: PagedResultDto<CustomersDto> = {
    items: [],
    totalCount: 0,
  }

  priorities: PagedResultDto<PrioritiesDto> = {
    items: [],
    totalCount: 0,
  }

  industries: PagedResultDto<IndustriesDto> = {
    items: [],
    totalCount: 0,
  }
  
  filters = { isPaginated: true } as GetBulkUploadsInput;
  
  isFiltersHidden = true;
  
  isModalBusy = false;
  
  isModalOpen = false;
  
  isExportToExcelBusy = false;
  
  statuses = mapEnumToOptions(enumBulkUpload);

  orderBulkStates = OrderBulkStates;

  showedColumns = {
    fileName: true,
    customerName: true,
    priorityName:true,
    industryName:true,
    bulkUploadStatus: true
  };

  clientMaxResultCount = 10;
  pageNumber = 0;

  constructor(
    public themeService: CustomizerSettingsService,
    public readonly list: ListService,
    public readonly service: BulkUploadsService,
    public readonly customerService: CustomersService,
    public readonly prioritiesService: PrioritiesService,
    public readonly industriesService: IndustriesService,
    private dialogService: MatDialog,
  ) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<BulkUploadsDto>) => (this.data = list);

    this.list.hookToQuery(getData).subscribe(setData);
    this.getPriorities();
    this.getIndustries();
  }

  clearFilters() {
    this.filters = { isPaginated: true } as GetBulkUploadsInput;
  }

  viewDetail(record: BulkUploadsDto) {
    const dialogData = {
      title: 'missionsService::MissionDetail',
      bulkUpload: record,
      readonly: true,
    };
    const dialogRef = this.dialogService.open(MissionBulkUploadDetailComponent, {
      data: dialogData,
      disableClose: true,
      width: '1150px',
    });
  }

  private getPriorities(): void {
    const query = { } as ABP.PageQueryParams;
    const industryFilter = { state : enumState.Enabled, isPaginated: false } as GetIndustryInput;

    this.prioritiesService.getList({
        ...query,
        ...industryFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.priorities = res;
      });
  }

  private getIndustries(): void {
    const query = { } as ABP.PageQueryParams;
    const industryFilter = { state : enumState.Enabled, isPaginated: false } as GetIndustryInput;

    this.industriesService.getList({
        ...query,
        ...industryFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.industries = res;
      });
  }

  columnActions(record: BulkUploadsDto) {
    let columnActions:ColumnAction[] = [
      {
        actionIcon: 'bi bi-eye',
        abpPermission: '',
        action: {callAction: () => this.viewDetail(record)}
      },
    ];

    return columnActions;
  }

  toggler = (column:string, checked:boolean) => this.showedColumns[column] = checked;

  columnsStatus() {
    const columnsStatus:ColumnStatus[] = [
      {
        columnName: 'fileName',
        softColumnName: 'File Name',
        defaultChecked: this.showedColumns.fileName
      },
      {
        columnName: 'customerName',
        softColumnName: 'Customer Name',
        defaultChecked: this.showedColumns.customerName
      },
      {
        columnName: 'priorityName',
        softColumnName: 'Priority Name',
        defaultChecked: this.showedColumns.priorityName
      },      
      {
        columnName: 'industryName',
        softColumnName: 'Industry Name',
        defaultChecked: this.showedColumns.industryName
      },         
      {
        columnName: 'bulkUploadStatus',
        softColumnName: 'Bulk Upload Status',
        defaultChecked: this.showedColumns.bulkUploadStatus
      },
    ];

    return columnsStatus;
  }
}