import {
  AnswerV2Dto,
  QuestionTypeV2Dto,
  QuestionV2Dto,
  QuestionnaireAnswersDto,
  QuestionnaireV2Dto,
} from '../../proxy/core-service/lookups';
import { QuestionViewModel } from './models/question-view-model';

export class QuestionnaireLogicManager {
  private questionnaire: QuestionnaireV2Dto;
  private questions: QuestionV2Dto[];
  private questionTypes: QuestionTypeV2Dto[];
  private questionsViewModel: QuestionViewModel[];
  private answers: AnswerV2Dto[] = [];

  selectedQuestion: QuestionViewModel;
  selectedQuestionIndex: number = 0;

  primaryQuestions: QuestionViewModel[];
  PrimaryQuestionsCount: number;

  alreadyAnswered = false;
  noAnsweredText = 'No answered';

  setData(
    questionnaire: QuestionnaireV2Dto,
    questionTypes: QuestionTypeV2Dto[],
    questions: QuestionV2Dto[],
    answers: AnswerV2Dto[]
  ) {
    this.questionnaire = questionnaire;
    this.questionTypes = questionTypes;
    this.questions = questions;
    this.answers = answers;
    this.alreadyAnswered = answers.length > 0;
    this.createViewModel();
  }

  private createViewModel() {
    this.seedViewModel();
    this.resetAnswers();
  }

  private seedViewModel() {
    this.questionsViewModel = [];
    this.questions.forEach(question => {
      this.questionsViewModel.push({
        title: question.title,
        description: question.description,
        options: question.options.split(','),
        triggers: question.triggers.split(','),
        isOptional: question.isOptional,
        questionType: this.questionTypes.find(x => x.id == question.questionTypeV2Id)?.name,
        answer: '',
        questionId: question.id,
        parentId: question.parentId,
      });
    });

    this.primaryQuestions = this.questionsViewModel.filter(x => x.parentId == null);
    this.PrimaryQuestionsCount = this.primaryQuestions.length;
    this.selectedQuestion = this.primaryQuestions[this.selectedQuestionIndex];
  }

  getQuestionChilds(questionId) {
    return this.questionsViewModel.filter(x => x.parentId == questionId);
  }

  getQuestionIndex(question) {
    return this.primaryQuestions.indexOf(question);
  }

  resetAnswers() {
    this.questionsViewModel.forEach(item => {
      item.answer = this.answers.find(x => x.questionV2Id == item.questionId)?.answer ?? '';
    });
  }

  canNavigateToNext() {
    return this.IsValidQuestion(this.selectedQuestion);
  }

  validateChilds(question: QuestionViewModel) {
    var childs = this.questionsViewModel.filter(x => x.parentId == question.questionId);

    for (var child of childs) {
      if (child.triggers.includes(question.answer) && !child.isOptional && child.answer == '') {
        return false;
      }

      if (!this.validateChilds(child)) {
        return false;
      }
    }

    return true;
  }

  IsValidQuestion(question: QuestionViewModel) {
    if (question.parentId) {
      var parent = this.questionsViewModel.find(x => x.questionId == question.parentId);

      if (
        question.triggers.includes(parent.answer) &&
        !question.isOptional &&
        question.answer == ''
      ) {
        return false;
      }
    } else if (!question.isOptional && question.answer == '') {
      return false;
    }

    return this.validateChilds(question);
  }

  IsValid() {
    for (var question of this.questionsViewModel) {
      if (!this.IsValidQuestion(question)) {
        return false;
      }
    }

    return true;
  }

  nextQuestion() {
    this.selectedQuestionIndex = this.clamp(
      0,
      this.PrimaryQuestionsCount - 1,
      ++this.selectedQuestionIndex
    );
    this.selectedQuestion = this.primaryQuestions[this.selectedQuestionIndex];
  }

  previousQuestion() {
    this.selectedQuestionIndex = this.clamp(
      0,
      this.PrimaryQuestionsCount - 1,
      --this.selectedQuestionIndex
    );
    this.selectedQuestion = this.primaryQuestions[this.selectedQuestionIndex];
  }

  clamp(min: number, max: number, value: number) {
    return value < min ? min : value > max ? max : value;
  }

  public getQuestions() {
    return this.questionsViewModel;
  }

  public getAnswers(userId, dataLink) {
    let answersDto: QuestionnaireAnswersDto = {
      userId: userId,
      dataLink: dataLink,
      questionnaireId: this.questionnaire.id,
      answers: [],
    };

    this.questionsViewModel.forEach(question => {
      answersDto.answers.push({
        questionId: question.questionId,
        answer: question.answer?.toString() ?? '',
      });
    });

    return answersDto;
  }
}
