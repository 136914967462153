import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';
import { FolderSelectedModel } from '../../models/folder-permissions/folder-selected.model';
import { FolderPermissionResultModel } from '@volo/abp.ng.file-management/proxy';
import { RoleListInputModel } from '../../models/folder-permissions/role-list-input.model';

@Component({
  selector: 'folder-permissions-modal',
  templateUrl: './folder-permissions-modal.component.html',
})
export class FolderPermissionsModalComponent extends BaseModalComponent implements OnInit {
  
  @Input() 
  roles: Array<RoleListInputModel>;

  @Input() 
  folder: FolderSelectedModel;

  @Output()
  public onCloseModal = new EventEmitter<void>;

  @Output()
  public onSaveModal = new EventEmitter<FolderPermissionResultModel>;


  public permissions: FolderPermissionResultModel;

  constructor() {
    super();
  }

  ngOnInit(): void {

    this.resetPermissionsValues();
  }

  public closeModal(): void {
    this.onCloseModal.emit();
  }

  public save(): void {

    this.getPermissionValues();
    this.onSaveModal.emit({...this.permissions});
    this.resetPermissionsValues();
  }

  private getPermissionValues(): void {

    this.roles.forEach(x => {
      Object.entries(x).forEach(([key, value]) => {

        if (key == 'name' || !value) return;

        this.permissions[key].push(x.name);
      });
    }); 
  }

  private resetPermissionsValues(): void {

    this.permissions = new FolderPermissionResultModel();
    this.permissions.write = [];
    this.permissions.read = [];
    this.permissions.delete = [];
  }
}
