import { Injectable } from '@angular/core';
import { Subscription, timer, switchMap, tap, firstValueFrom } from 'rxjs';
import { HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { EnvironmentService } from '@abp/ng.core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ActionsModelDb } from '../shared/indexDb-config/actions-model.dto';

@Injectable({
  providedIn: 'root',
})
export class HttpCacheUpdaterService {
  private updateInterval;
  private maxCacheExpiration;

  private subscription: Subscription;

  private storeName = 'cacheStore';

  constructor(
    private httpHandler: HttpHandler,
    private readonly _environmentService: EnvironmentService,
    private dbService: NgxIndexedDBService
  ) {
    const environment = this._environmentService.getEnvironment();
    this.updateInterval =
      parseInt(environment?.missionActionsCacheUpdateIntervalInMinutes ?? '60') * 60000;
    this.maxCacheExpiration =
      parseInt(environment?.missionActionsMaxCacheExpirationInMinutes ?? '60') * 60000;
  }

  async getFromCacheDB(key: string): Promise<any> {
    try {
      const observable = this.dbService.getByKey<ActionsModelDb>(this.storeName, key);
      const result = await firstValueFrom(observable);
      return result ? JSON.parse(result.value) : null;
    } catch (error) {
      console.error('Error fetching from cache', error);
      return null;
    }
  }

  saveToCacheDB(key: string, data: any): void {
    this.getFromCacheDB(key).then(cached => {
      const storedData: ActionsModelDb = {
        key: key,
        value: JSON.stringify(data),
      };
      if (!cached) {
        this.dbService.add(this.storeName, storedData).subscribe(_ => {});
        return;
      }

      this.dbService.update<ActionsModelDb>(this.storeName, storedData).subscribe(_ => {});
    });
  }

  addRequestToCacheUpdater(request: HttpRequest<any>): void {
    if (!this.subscription) {
      const headers = request.headers.append('FROM_CACHE', 'false');
      const newRequest = request.clone({ headers: headers });
      this.subscription = timer(0, this.updateInterval)
        .pipe(
          switchMap(() =>
            this.httpHandler.handle(newRequest).pipe(
              tap(event => {
                if (event instanceof HttpResponse) {
                  this.saveToCacheDB(newRequest.url, event.body);
                }
              })
            )
          )
        )
        .subscribe();
    }
  }

  stopCacheUpdater(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getTimestamp(url: string): Promise<number | null> {
    return this.getFromCacheDB(`${url}-updateOn`).then(timestamp => {
      return timestamp ? parseInt(timestamp.expiredIn, 10) : null;
    });
  }

  isExpired(url: string): Promise<boolean> {
    return this.getTimestamp(url).then(timestamp => {
      if (timestamp === null) {
        return true;
      }
      const currentTime = new Date().getTime();
      return currentTime - timestamp >= this.maxCacheExpiration;
    });
  }

  clearCache() {
    this.dbService.clear(this.storeName).subscribe(_ => {});
  }

  stop() {
    this.stopCacheUpdater();
    this.clearCache();
  }
}
