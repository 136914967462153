import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';
import { MissionFinancialRevenueComponent } from './components/mission-financial-revenue.component';
import { MissionFinancialRevenueRoutingModule } from './mission-financial-revenue-routing.module';
import { NgxActionFrameworkModule } from '@newtonvision/ngx-action-framework';
import { environment } from 'projects/flyguys/src/environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { TablePageModule } from 'projects/flyguys/src/app/components/table-page/table-page.module';
import { ColumnsModule } from 'projects/flyguys/src/app/components/columns/columns.module';
import { MaterialSharedModule } from 'projects/flyguys/src/app/material-shared/material-shared.module';

@NgModule({
  declarations: [MissionFinancialRevenueComponent],
  imports: [
    MissionFinancialRevenueRoutingModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    PageModule,
    NgxActionFrameworkModule.forRootDynamicEnv({
      api: environment.apis.ActionFramework.url,
    }),
    ColumnsModule,
    TablePageModule,
    MaterialSharedModule
  ],
  exports:[
    MissionFinancialRevenueComponent
  ]
})
export class MissionFinancialRevenueModule {}
