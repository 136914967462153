<abp-page>
  <div class="col">
    <!-- <div class="p-lg-1 p-md-0 mb-4">
      <app-overall-rating [pilotId]="pilotId"></app-overall-rating>
    </div> -->
    <div class="p-lg-1 p-md-0 mb-4">
      <div class="card border rounded">
        <div class="card-body">
          <app-badges [pilotId]="pilotId"></app-badges>
        </div>
      </div>
    </div>
    <div class="p-lg-1 p-md-0 mb-4">
      <app-equipments [pilotId]="pilotId"></app-equipments>
    </div>
    <div class="p-lg-1 p-md-0 mb-4">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-2">
          <h3>{{ 'pilotsService::Geofence' | abpLocalization }}</h3>
        </div>
        <flg-geofence
          (added)="handleGeofenceAdd($event)"
          (removed)="handleRemoveGeofence($event)"
          [maxSize]="804670"
          [options]="{
            mapTypeId: 'roadmap',
          }"
        ></flg-geofence>
        <div class="d-flex justify-content-end">
          <button (click)="handleSaveChanges()" mat-flat-button class="common-button" type="button">
            {{ 'pilotService::SaveChanges' | abpLocalization }}
          </button>
        </div>
      </div>
    </div>
  </div>
</abp-page>
