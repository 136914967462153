import { Component, Input, OnInit } from '@angular/core';
import { ABP, ListService, TrackByService, PagedResultDto } from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { GetOrderInput, OrdersDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { OrdersService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';

@Component({
  selector: 'app-pilot-profile-history',
  templateUrl: './pilot-profile-history.component.html',
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  styleUrls: ['./pilot-profile-history.component.scss']
})
export class PilotProfileHistoryComponent implements OnInit {
  @Input() pilotId: string;

  ordersHistory: PagedResultDto<OrdersDto> = {
    items: [],
    totalCount: 0,
  };

  clientMaxResultCount = 10;
  pageNumber = 0;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: OrdersService
  ) {}

  ngOnInit() {
    var filters = { isPaginated: false } as GetOrderInput;
    const getData = (query: ABP.PageQueryParams) =>
    this.service.getList({
      ...query,
      ...filters,
      filterText: query.filter,
    });

    const setData = (list: PagedResultDto<OrdersDto>) => (this.ordersHistory = list);

    this.list.hookToQuery(getData).subscribe(setData);
  }
}
