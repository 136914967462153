import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Injectable } from '@angular/core';
import {
  DirectoryContentDto,
  DirectoryDescriptorService,
  FileDescriptorService,
} from '@volo/abp.ng.file-management/proxy';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { UpdateStreamService } from './update-stream.service';

@Injectable()
export class DeleteService {
  constructor(
    private confirmationService: ConfirmationService,
    private directoryService: DirectoryDescriptorService,
    private fileService: FileDescriptorService,
    private updateStream: UpdateStreamService,
    private toaster: ToasterService,
  ) {}

  deleteFolder(content: DirectoryContentDto) {
    return this.delete(
      'FileManagement::DirectoryDeleteConfirmationMessage',
      content,
      this.directoryService,
    ).pipe(tap(_ => this.updateStream.patchStore({ deletedDirectory: content.id })));
  }

  deleteFile(content: DirectoryContentDto) {
    return this.delete(
      'FileManagement::FileDeleteConfirmationMessage',
      content,
      this.fileService,
    ).pipe(tap(_ => this.updateStream.refreshContent()));
  }

  private delete(
    message: string,
    content: DirectoryContentDto,
    service: DirectoryDescriptorService | FileDescriptorService,
  ) {
    return this.confirmationService.warn(message, 'FileManagement::AreYouSure').pipe(
      filter(status => status === Confirmation.Status.confirm),
      take(1),
      switchMap(_ => service.delete(content.id)),
      tap(_ => {
        // this.toaster.success('FileManagement::SuccessfullyDeleted');
      }),
    );
  }
}
