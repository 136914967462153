export class OrderFormContactModel {
  contactId: string = '';
  contactFromId: string = '';
  contactFromDescription: string = '';
  email: string = '';
  name: string = '';
  lastname: string = '';
  number: string = '';
  shareData: boolean = false;
  preferredContactMethodId: string = '';
  preferredContactMethodDescription: string = '';
  levelCommunicationId: string = '';
  levelCommunicationDescription: string = '';
  customerContactDescription: string = '';
  customerId: string = '';
  userId?: string = '';
}
