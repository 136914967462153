import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MissionFlowDto } from '../../models/mission-flow-dto';
import { MissionChangeLogService } from '../../../services/mission-change-log.service';
import { differenceInDays } from 'date-fns';
import { missionStatus } from 'projects/missions-service/src/lib/proxy/missions-service/shared';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit, OnChanges {
  @Input() mission: MissionFlowDto;
  @Input() missionId: string;
  @Input() statusName: string;
  days: string;
  showTable: boolean = true;
  showDays = false;

  constructor(private service: MissionChangeLogService) {}

  ngOnInit(): void {
    this.refreshData();
  }

  getEnumKeyByEnumValue(enumObj: any, enumValue: string): string | undefined {
    const keys = Object.keys(enumObj).filter(x => enumObj[x] === enumValue);
    return keys.length > 0 ? keys[0] : undefined;
  }

  refreshData() {
    const statusName = this.getEnumKeyByEnumValue(missionStatus, this.statusName);
    const statusesToHide: missionStatus[] = [
      missionStatus.Cancelled,
      missionStatus.DEAD,
      missionStatus.ClientInvoiced,
    ];

    if (!statusesToHide.includes(missionStatus[statusName as keyof typeof missionStatus])) {
      this.service.getChangesByMissionAsync(this.missionId).subscribe(res => {
        if (res?.length > 0) {
          const logData = res.find(
            r => JSON.parse(r.displayEventInformation)?.NewStatus == statusName,
          );
          if (logData) {
            const date = new Date(logData.date);
            const daysDifference = differenceInDays(new Date(), date);
            this.days = daysDifference.toString();

            this.showDays = true;
          }
        }
      });
    } else this.showDays = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.statusName || changes.mission) {
      this.refreshData();
      this.showTable = false;

      setTimeout(() => {
        this.showTable = true;
      }, 1000);
    }
  }
}
