import { mapEnumToOptions } from '@abp/ng.core';

export enum TimeframeFilterEnum {
  TodayAndEarlier = 0,
  Next7DaysAndEarlier = 1,
  Next14DaysAndEarlier = 2,
  Next28DaysAndEarlier = 3,
  CustomDateRange = 4,
}

export const TimeframeFilterEnumDisplayNames: Record<TimeframeFilterEnum, string> = {
  [TimeframeFilterEnum.TodayAndEarlier]: 'missionsService::TodayAndEarlierFilter',
  [TimeframeFilterEnum.Next7DaysAndEarlier]: 'missionsService::Next7DaysAndEarlierFilter',
  [TimeframeFilterEnum.Next14DaysAndEarlier]: 'missionsService::Next14DaysAndEarlierFilter',
  [TimeframeFilterEnum.Next28DaysAndEarlier]: 'missionsService::Next28DaysAndEarlierFilter',
  [TimeframeFilterEnum.CustomDateRange]: 'missionsService::CustomDateRange',
};

export const EnumTimeframeOptions = mapEnumToOptions(TimeframeFilterEnum);