import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HTTP_ERROR_HANDLER, ThemeSharedModule, ToasterService } from '@abp/ng.theme.shared';
import { CoreModule } from '@abp/ng.core';

import { AccountLayoutComponent } from './account-layout/account-layout.component';
import { LoginComponent } from './login/login.component';
import { MaterialSharedModule } from '../material-shared/material-shared.module';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

// @TODO Evaluate if we should left this.
// This handler overrides 403 errors so the app won't redirect to a separate screen.
export function handleHttpErrors(injector: Injector, httpError: HttpErrorResponse) {
  if (httpError.status === 403) {
    return;
  }

  return throwError(httpError);
}

@NgModule({
  providers: [{ provide: HTTP_ERROR_HANDLER, useValue: handleHttpErrors }],
  declarations: [
    AccountLayoutComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
  ],
  imports: [CommonModule, CoreModule, ThemeSharedModule, MaterialSharedModule],
  exports: [
    AccountLayoutComponent,
    LoginComponent,
    MaterialSharedModule,
    RegisterComponent,
    ForgotPasswordComponent,
  ],
})
export class AccountModule {}
