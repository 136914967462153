<h5 mat-dialog-title class="flg-modal flg-modal__title">
  {{ data.title || 'Add Deliverable Attributes' }}
</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container">
    <div class="row justify-content-center">
      <form #attributesForm="ngForm" class="p-0 m-0">
        <div *ngIf="deliverableAttributes.length > 0">
          <ng-container *ngFor="let dv of deliverableAttributes">
            <h6 class="mb-2">
              {{ dv.deliverableName }} <span *ngIf="dv.quantity > 1">x {{ dv.quantity }}</span>
            </h6>
            <mat-checkbox
              *ngIf="dv.quantity > 1"
              class="example-margin"
              [(ngModel)]="dv.sameConfiguration"
              [disabled]="readonlyMode"
              [name]="dv.productId"
            >
              {{ 'missionsService::SameValuesForAll' | abpLocalization }}
            </mat-checkbox>
            <div class="d-flex flex-row attribute-list mb-3" *ngIf="dv.sameConfiguration">
              <ng-container *ngFor="let field of dv.fields; let i = index" class="d-flex flex-row">
                <mat-form-field
                  class="pe-2 attribute"
                  *ngIf="field.typeCode === attributeTypes.Number"
                >
                  <mat-label>{{ field.placeholder }}</mat-label>
                  <input
                    matInput
                    [placeholder]="field.placeholder"
                    [(ngModel)]="field.numericValue"
                    pattern="^-?\d+(\.\d{1,2})?$"
                    [name]="field.fieldControlName"
                    digitOnly
                    type="number"
                    [maxlength]="field.maxTextLength || DEFAULT_MAX_LENGTH"
                    [required]="field.isRequired"
                    [disabled]="readonlyMode"
                  />
                  <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                    {{ 'missionsService::RequiredControl' | abpLocalization }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field
                  class="pe-2 attribute"
                  *ngIf="field.typeCode === attributeTypes.Text"
                >
                  <mat-label>{{ field.placeholder }}</mat-label>
                  <input
                    matInput
                    [placeholder]="field.placeholder"
                    [(ngModel)]="field.stringValue"
                    [name]="field.fieldControlName"
                    [maxlength]="field.maxTextLength || DEFAULT_MAX_LENGTH"
                    [required]="field.isRequired"
                    [disabled]="readonlyMode"
                  />
                  <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                    {{ 'missionsService::RequiredControl' | abpLocalization }}
                  </mat-error>
                </mat-form-field>
                <div
                  class="pe-2 attribute flex-column"
                  *ngIf="field.typeCode === attributeTypes.File"
                >
                  <label
                    class="label-order small-size"
                    [ngClass]="{ 'text-danger': !field.stringValue && field.isRequired }"
                  >
                    {{ field.placeholder }} {{ field.isRequired ? '*' : '' }}
                  </label>

                  <app-file-upload
                    *ngIf="!field.stringValue && !field.loadingFile"
                    [includeBorder]="false"
                    (filesSelected)="onFileSelected($event, field)"
                    (fileRemoved)="onFileRemoved(field)"
                    [allowMultiple]="false"
                    [showFilesAttached]="false"
                    [disabled]="readonlyMode"
                  >
                  </app-file-upload>

                  <div class="file-spinner" *ngIf="field.loadingFile"></div>

                  <div *ngIf="field.stringValue && !field.loadingFile">
                    <mat-chip-list>
                      <mat-chip
                        [removable]="!readonlyMode"
                        (removed)="onFileRemoved(field)"
                        (click)="downloadDocument(field)"
                        class="me-2 mt-2"
                        [ngClass]="{ disabled: readonlyMode }"
                        [title]="field.fileValue"
                      >
                        <span class="width-50">{{ field.fileValue }}</span>
                        <mat-icon matChipRemove *ngIf="!readonlyMode">cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                  <span
                    *ngIf="!field.stringValue && field.isRequired && !field.loadingFile"
                    class="text-danger small-size"
                    >Please select a file</span
                  >
                </div>
                <div
                  class="pe-2 attribute flex-column"
                  *ngIf="field.typeCode === attributeTypes.Kml"
                >
                  <label
                    class="label-order small-size"
                    [ngClass]="{ 'text-danger': !field.stringValue && field.isRequired }"
                  >
                    {{ field.placeholder }} {{ field.isRequired ? '*' : '' }}
                  </label>

                  <app-file-upload
                    *ngIf="!field.stringValue && !field.loadingFile"
                    [includeBorder]="false"
                    (filesSelected)="onFileSelected($event, field)"
                    (fileRemoved)="onFileRemoved(field)"
                    [allowMultiple]="false"
                    [showFilesAttached]="false"
                    [allowedExtensions]="'.kml'"
                    [disabled]="readonlyMode"
                  >
                  </app-file-upload>

                  <div class="file-spinner" *ngIf="field.loadingFile"></div>

                  <div *ngIf="field.stringValue && !field.loadingFile">
                    <mat-chip-list>
                      <mat-chip
                        [removable]="!readonlyMode"
                        (removed)="onFileRemoved(field)"
                        (click)="downloadDocument(field)"
                        class="me-2 mt-2"
                        [ngClass]="{ disabled: readonlyMode }"
                        [title]="field.fileValue"
                      >
                        <span class="width-50">{{ field.fileValue }}</span>
                        <mat-icon matChipRemove *ngIf="!readonlyMode">cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                  <span
                    *ngIf="!field.stringValue && field.isRequired && !field.loadingFile"
                    class="text-danger small-size"
                    >Please select a file</span
                  >
                </div>
                <mat-form-field
                  class="pe-2 attribute"
                  [floatLabel]="'always'"
                  *ngIf="field.typeCode === attributeTypes.Date"
                >
                  <mat-label>{{ field.placeholder }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker"
                    [(ngModel)]="field.stringValue"
                    [name]="field.fieldControlName"
                    placeholder="MM/DD/YYYY"
                    [required]="field.isRequired"
                    [disabled]="readonlyMode"
                  />
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>

                  <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                    {{ 'missionsService::RequiredControl' | abpLocalization }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field
                  class="pe-2 attribute"
                  *ngIf="field.typeCode === attributeTypes.List"
                >
                  <mat-label>{{ field.placeholder }}</mat-label>

                  <mat-select
                    [name]="field.fieldControlName"
                    [(ngModel)]="field.stringValue"
                    [required]="field.isRequired"
                    [disabled]="readonlyMode"
                  >
                    <mat-option
                      *ngFor="let value of field.listValues.split(',')"
                      [value]="value"
                      title="{{ value }}"
                    >
                      {{ value }}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                    {{ 'missionsService::RequiredControl' | abpLocalization }}
                  </mat-error>
                </mat-form-field>
                <div
                  class="pe-2 attribute align-items-center"
                  *ngIf="field.typeCode === attributeTypes.Bool"
                >
                  <label class="form-label" [htmlFor]="field.fieldControlName">{{
                    field.placeholder
                  }}</label>
                  <mat-checkbox
                    color="primary"
                    [(ngModel)]="field.boolValue"
                    [name]="field.fieldControlName"
                    [disabled]="readonlyMode"
                  >
                  </mat-checkbox>
                </div>
                <mat-form-field
                  class="pe-2 attribute"
                  *ngIf="field.typeCode === attributeTypes.LongText"
                >
                  <mat-label>{{ field.placeholder }}</mat-label>
                  <textarea
                    matInput
                    [(ngModel)]="field.stringValue"
                    class="textarea"
                    [name]="field.fieldControlName"
                    [placeholder]="field.placeholder"
                    [maxlength]="field.maxTextLength || DEFAULT_MAX_LENGTH"
                    [disabled]="readonlyMode"
                    [required]="field.isRequired"
                  ></textarea>
                  <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                    {{ 'missionsService::RequiredControl' | abpLocalization }}
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </div>
            <ng-container *ngIf="!dv.sameConfiguration">
              <div
                *ngFor="let rowAttr of dv.detailedAttributes; let i = index; let last = last"
                class="mt-2"
              >
                <p class="attribute-title">{{ dv.deliverableName }} {{ i + 1 }}</p>

                <div class="d-flex flex-row attribute-list mb-3">
                  <ng-container *ngFor="let field of rowAttr; let t = index">
                    <mat-form-field
                      class="pe-2 attribute"
                      *ngIf="field.typeCode === attributeTypes.Number"
                    >
                      <mat-label>{{ field.placeholder }}</mat-label>
                      <input
                        matInput
                        [placeholder]="field.placeholder"
                        [(ngModel)]="field.numericValue"
                        pattern="^-?\d+(\.\d{1,2})?$"
                        [name]="field.fieldControlName"
                        digitOnly
                        type="number"
                        [maxlength]="field.maxTextLength || DEFAULT_MAX_LENGTH"
                        [required]="field.isRequired"
                        [disabled]="readonlyMode"
                      />
                      <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                        {{ 'missionsService::RequiredControl' | abpLocalization }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field
                      class="pe-2 attribute"
                      *ngIf="field.typeCode === attributeTypes.Text"
                    >
                      <mat-label>{{ field.placeholder }}</mat-label>
                      <input
                        matInput
                        [placeholder]="field.placeholder"
                        [(ngModel)]="field.stringValue"
                        [name]="field.fieldControlName"
                        [maxlength]="field.maxTextLength || DEFAULT_MAX_LENGTH"
                        [required]="field.isRequired"
                        [disabled]="readonlyMode"
                      />
                      <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                        {{ 'missionsService::RequiredControl' | abpLocalization }}
                      </mat-error>
                    </mat-form-field>
                    <div
                      class="pe-2 attribute flex-column"
                      *ngIf="field.typeCode === attributeTypes.File"
                    >
                      <label
                        class="label-order small-size"
                        [ngClass]="{ 'text-danger': !field.stringValue && field.isRequired }"
                      >
                        {{ field.placeholder }} {{ field.isRequired ? '*' : '' }}
                      </label>

                      <app-file-upload
                        *ngIf="!field.stringValue && !field.loadingFile"
                        (filesSelected)="onFileSelected($event, field)"
                        (fileRemoved)="onFileRemoved(field)"
                        [allowMultiple]="false"
                        [showFilesAttached]="false"
                        [disabled]="readonlyMode"
                        [includeBorder]="false"
                      ></app-file-upload>

                      <div class="file-spinner" *ngIf="field.loadingFile"></div>

                      <div *ngIf="field.stringValue && !field.loadingFile">
                        <mat-chip-list>
                          <mat-chip
                            [removable]="!readonlyMode"
                            (removed)="onFileRemoved(field)"
                            (click)="downloadDocument(field)"
                            class="me-2 mt-2"
                            [ngClass]="{ disabled: readonlyMode }"
                            [title]="field.fileValue"
                          >
                            <span class="width-50">{{ field.fileValue }}</span>
                            <mat-icon matChipRemove *ngIf="!readonlyMode">cancel</mat-icon>
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                      <span
                        *ngIf="!field.stringValue && field.isRequired && !field.loadingFile"
                        class="text-danger small-size"
                        >{{ 'missionsService::RequiredFile' | abpLocalization }}</span
                      >
                    </div>
                    <div
                      class="pe-2 attribute flex-column"
                      *ngIf="field.typeCode === attributeTypes.Kml"
                    >
                      <label
                        class="label-order small-size"
                        [ngClass]="{ 'text-danger': !field.stringValue && field.isRequired }"
                      >
                        {{ field.placeholder }} {{ field.isRequired ? '*' : '' }}
                      </label>

                      <app-file-upload
                        *ngIf="!field.stringValue && !field.loadingFile"
                        (filesSelected)="onFileSelected($event, field)"
                        (fileRemoved)="onFileRemoved(field)"
                        [allowMultiple]="false"
                        [showFilesAttached]="false"
                        [disabled]="readonlyMode"
                        [allowedExtensions]="'.kml'"
                        [includeBorder]="false"
                      ></app-file-upload>

                      <div class="file-spinner" *ngIf="field.loadingFile"></div>

                      <div *ngIf="field.stringValue && !field.loadingFile">
                        <mat-chip-list>
                          <mat-chip
                            [removable]="!readonlyMode"
                            (removed)="onFileRemoved(field)"
                            (click)="downloadDocument(field)"
                            class="me-2 mt-2"
                            [ngClass]="{ disabled: readonlyMode }"
                            [title]="field.fileValue"
                          >
                            <span class="width-50">{{ field.fileValue }}</span>
                            <mat-icon matChipRemove *ngIf="!readonlyMode">cancel</mat-icon>
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                      <span
                        *ngIf="!field.stringValue && field.isRequired && !field.loadingFile"
                        class="text-danger small-size"
                        >{{ 'missionsService::RequiredFile' | abpLocalization }}</span
                      >
                    </div>
                    <mat-form-field
                      class="pe-2 attribute"
                      [floatLabel]="'always'"
                      *ngIf="field.typeCode === attributeTypes.Date"
                    >
                      <mat-label>{{ field.placeholder }}</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        [(ngModel)]="field.stringValue"
                        [name]="field.fieldControlName"
                        placeholder="MM/DD/YYYY"
                        [required]="field.isRequired"
                        [disabled]="readonlyMode"
                      />
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>

                      <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                        {{ 'missionsService::RequiredControl' | abpLocalization }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field
                      class="pe-2 attribute"
                      *ngIf="field.typeCode === attributeTypes.List"
                    >
                      <mat-label>{{ field.placeholder }}</mat-label>

                      <mat-select
                        [name]="field.fieldControlName"
                        [(ngModel)]="field.stringValue"
                        [required]="field.isRequired"
                        [disabled]="readonlyMode"
                      >
                        <mat-option
                          *ngFor="let value of field.listValues.split(',')"
                          [value]="value"
                          title="{{ value }}"
                        >
                          {{ value }}
                        </mat-option>
                      </mat-select>

                      <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                        {{ 'missionsService::RequiredControl' | abpLocalization }}
                      </mat-error>
                    </mat-form-field>
                    <div
                      class="pe-2 attribute align-items-center"
                      *ngIf="field.typeCode === attributeTypes.Bool"
                    >
                      <label class="form-label" [htmlFor]="field.Id">{{ field.placeholder }}</label>
                      <mat-checkbox
                        color="primary"
                        [(ngModel)]="field.boolValue"
                        [name]="field.fieldControlName"
                        [disabled]="readonlyMode"
                      >
                      </mat-checkbox>
                    </div>
                    <mat-form-field
                      class="pe-2 attribute"
                      *ngIf="field.typeCode === attributeTypes.LongText"
                    >
                      <mat-label>{{ field.placeholder }}</mat-label>
                      <textarea
                        matInput
                        [(ngModel)]="field.stringValue"
                        class="textarea"
                        [name]="field.fieldControlName"
                        [value]="field.defaultValue"
                        [placeholder]="field.placeholder"
                        [maxlength]="field.maxTextLength || DEFAULT_MAX_LENGTH"
                        [required]="field.isRequired"
                        [disabled]="readonlyMode"
                      >
    
                      ></textarea
                      >
                      <mat-error *ngIf="isControlInvalid(attributesForm, field.fieldControlName)">
                        {{ 'missionsService::RequiredControl' | abpLocalization }}
                      </mat-error>
                    </mat-form-field>
                  </ng-container>
                </div>
                <mat-divider class="mb-3" *ngIf="!last"></mat-divider>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end mt-3">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      Cancel
    </button>
    <button
      *ngIf="!readonlyMode"
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="saveDeliverableList()"
      [disabled]="!attributesForm.valid || thereAreMissingFiles()"
    >
      Add
    </button>
  </div>
</mat-dialog-actions>
