import { Component, Inject, OnInit } from '@angular/core';
import { PilotDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { IdentityUserService } from 'projects/identity/proxy/src/lib';

interface DialogData {
  id: string;
  userId?: string;
}

@Component({
  selector: 'app-pilot-info',
  templateUrl: './pilot-info.component.html',
  styleUrls: ['./pilot-info.component.scss'],
})
export class PilotInfoComponent implements OnInit {
  pilotId: string;
  pilot: PilotDto;
  userId?: string;

  constructor(
    public readonly service: PilotService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private identityService: IdentityUserService,
  ) {}

  ngOnInit() {
    this.pilotId = this.data.id;
    this.userId = this.data.userId;

    this.spinner.show();

    this.service
      .get(this.pilotId)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(result => {
        this.pilot = result;

        if (this.pilot.userId) {
          this.identityService.get(this.pilot.userId).subscribe(pilot => {
            if (pilot.extraProperties) {
              if (pilot.extraProperties.enableSMSReception) {
                this.pilot.enableSMSReception = pilot.extraProperties.enableSMSReception;
              }
            }
          });
        }
      });
  }
}
