<div class="d-flex justify-content-between mb-2">
  <h3>{{ 'pilotsService::Equipment' | abpLocalization }}</h3>
  <button
    *abpPermission="'pilotsService.PilotInventorie.Create && General.Common.Create'"
    mat-flat-button
    class="common-button"
    type="button"
    (click)="create()"
  >
    <mat-icon aria-hidden="false" fontIcon="add"></mat-icon>
    {{ 'pilotsService::AddEquipment' | abpLocalization }}
  </button>
</div>
<ngx-datatable
  [rows]="equipments.items"
  [count]="equipments.totalCount"
  [list]="list"
  [headerHeight]="50"
  [footerHeight]="55"
  [rowHeight]="45"
  [offset]="pageNumber"
  [limit]="clientMaxResultCount"
  [externalPaging]="true"
  default
  #dataTable
  class="flg-grid-table"
>
  <ngx-datatable-column name="{{ 'pilotsService::Type' | abpLocalization }}" prop="EquipmentType">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.equipmentType }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'pilotsService::Equipment' | abpLocalization }}"
    prop="EquipmentName"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.equipmentName }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'pilotsService::Model' | abpLocalization }}" prop="Model">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.model }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'pilotsService::FAARegistration' | abpLocalization }}"
    prop="FAARegistration"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.faaRegistration }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'pilotsService::Status' | abpLocalization }}" prop="Status">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <app-column-stylizer
        [columnValue]="row.state"
        [typeColumn]="'text'"
        [columnRules]="stateRules"
      ></app-column-stylizer>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [name]="'AbpUi::Actions' | abpLocalization"
    [maxWidth]="110"
    [width]="110"
    [sortable]="false"
  >
    <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
      <app-column-actions [columnActions]="columnActions(row)"></app-column-actions>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-curPage="curPage"
    >
      <app-table-paginator
        [(maxResultCount)]="list.maxResultCount"
        [(clientMaxResultCount)]="clientMaxResultCount"
        [rowCount]="rowCount"
        [pageSize]="pageSize"
        [(pageNumber)]="pageNumber"
        [curPage]="curPage"
        [dataTable]="dataTable"
      >
      </app-table-paginator>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<ng-template #modal>
  <flg-generic-modal>
    <ng-container modalTitle>
      {{
        (isEditing ? 'pilotsService::EditPilotEquipment' : 'pilotsService::NewPilotInventories')
          | abpLocalization
      }}
    </ng-container>

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form [formGroup]="form" id="pilot-inventories" skipValidation>
          <mat-form-field class="w-100">
            <mat-label> {{ 'pilotsService::EquimentId' | abpLocalization }} </mat-label>

            <input type="text" matInput formControlName="equimentId" [matAutocomplete]="auto" />

            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="formatEquipment.bind(this)"
              [hideSingleSelectionIndicator]="true"
            >
              <mat-option
                *ngFor="let equipment of filteredEquipment | async"
                [value]="equipment.id"
              >
                {{ equipment.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label> {{ 'pilotsService::FAARegistration' | abpLocalization }} </mat-label>

            <input matInput formControlName="fAARegistration" />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label> {{ 'pilotsService::Status' | abpLocalization }} </mat-label>

            <mat-select formControlName="state">
              <mat-option [value]="0"> {{ 'pilotsService::Disabled' | abpLocalization }} </mat-option>
              <mat-option [value]="1"> {{ 'pilotsService::Enabled' | abpLocalization }} </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        class="outline-button me-2"
        type="button"
        (click)="hideForm()"
        [disabled]="isModalBusy"
      >
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        (click)="submitForm()"
        [disabled]="form.invalid || isModalBusy"
      >
        {{ 'AbpUi::Save' | abpLocalization }}
      </button>
    </div>
  </flg-generic-modal>
</ng-template>
