import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  DeliverablesCreateDto,
  DeliverablesDto,
  DeliverablesExcelDownloadDto,
  DeliverablesUpdateDto,
  GetDeliverableInput,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { DeliverableField } from '../../relationals';

@Injectable({
  providedIn: 'root',
})
export class DeliverablesService {
  apiName = 'missionsService';

  create = (input: DeliverablesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverablesDto>(
      {
        method: 'POST',
        url: '/api/missions-service/deliverable',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/deliverable/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverablesDto>(
      {
        method: 'GET',
        url: `/api/missions-service/deliverable/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/deliverable/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: DeliverablesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/deliverable/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetDeliverableInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<DeliverablesDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/deliverable',
        params: {
          filterText: input.filterText,
          name: input.name,
          attributeId: input.attributeId,
          fileTypeId: input.fileTypeId,
          description: input.description,
          costMin: input.cost,
          costMax: input.cost,
          state: input.state,
          categoryId: input.categoryId,
          premium: input.premium,
          code: input.code,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: DeliverablesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverablesDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/deliverable/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getAttributes = (deliverableId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverableField[]>(
      {
        method: 'GET',
        url: `/api/missions-service/deliverable/get-attributes/${deliverableId}`,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
