<h5 mat-dialog-title class="flg-modal flg-modal__title">
  {{ data.title || 'Add Deliverable Attributes' }}
</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="site-information container p-0">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="site-info-group">
          <h3>Mission Name</h3>
          <p>{{ data.mission.missionName }}</p>
        </div>

        <div class="site-info-group">
          <h3>Mission Summary</h3>
          <div class="flg-editor-output" [innerHTML]="data.mission.missionSummary"></div>
        </div>

        <h2>Capture Date & Time</h2>

        <div class="site-info-group d-inline-block mtext-break w-33">
          <h3>Capture Date</h3>
          <p>{{ (data.mission.beginDateDT | date: 'shortDate') || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-33">
          <h3>Capture Time</h3>
          <p>{{ data.mission.time || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block w-33">
          <h3>Repeats</h3>
          <p>{{ data.mission.recurrence || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-33">
          <!-- This should be an enum. Hardcoding value from order-site.component -->
          <h3>Capture Date Must Be</h3>
          <p>{{ data.mission.captureDateMust || 'N/A' }}</p>
        </div>

        <!-- <div class="site-info-group d-inline-block w-33">
           This should be an enum. Hardcoding value from order-site.component
          <h3>Flexible Capture Time</h3>
          <p>{{ mission.Captur === '1' ? 'No' : 'Yes' }}</p>
        </div> -->

        <h2>Deliverables</h2>

        <div class="site-info-group">
          <h3>Deliverable Due Date</h3>
          <p>{{ (data.mission.deliverableDueDateDT | date: 'shortDate') || 'N/A' }}</p>
        </div>

        <!-- <div class="site-info-group">
          <h3>Capture Deliverables</h3>
          <p>{{ mission.deliveryAllSameTime ? 'All at the same time' : 'No' }}</p>
        </div> -->

        <div class="site-info-group">
          <h3>Deliverable Notes</h3>
          <div class="flg-editor-output" [innerHTML]="data.mission.deliverablesNote || 'N/A'"></div>
        </div>

        <div class="site-info-group">
          <h3>Data Uploading Instructions</h3>
          <div
            class="flg-editor-output"
            [innerHTML]="data.mission.dataUploadingInstructions || 'N/A'"
          ></div>
        </div>

        <h2>Site Contact Information</h2>

        <div class="site-info-group d-inline-block text-break w-33">
          <h3>Name</h3>
          <p>{{ data.mission.siteContactFirstName || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Last Name</h3>
          <p>{{ data.mission.siteContactLastName || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-33">
          <h3>Email</h3>
          <p>{{ data.mission.siteContactEmail || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Phone Number</h3>
          <p>{{ data.mission.siteContactPhone || 'N/A' }}</p>
        </div>

        <div class="site-info-group" *ngIf="data.mission.siteContactPreferredContact">
          <h3>Preferred Contact Method</h3>
          <p>{{ data.mission.siteContactPreferredContact }}</p>
        </div>

        <div class="site-info-group" *ngIf="data.mission.siteContactLevelofCoordination">
          <h3>Level Of Coordination</h3>
          <p>{{ data.mission.siteContactLevelofCoordination }}</p>
        </div>

        <h2>Location</h2>

        <div class="site-info-group">
          <h3>Address</h3>
          <p>{{ data.mission.locationStreet || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>Country</h3>
          <p>{{ data.mission.locationCountry || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>State</h3>
          <p>{{ data.mission.locationState || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>City</h3>
          <p>{{ data.mission.locationCity || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>Zip Code</h3>
          <p>{{ data.mission.locationZipCode || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Latitude</h3>
          <p>{{ data.mission.locationLatitude || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Longitude</h3>
          <p>{{ data.mission.locationLongitude || 'N/A' }}</p>
        </div>

        <div class="site-info-group">
          <h3>Airspace Classification</h3>
          <p>{{ data.mission.airspaceClassification || 'N/A' }}</p>
        </div>

        <flg-map
          [markers]="mapMarkers"
          [usesKML]="true"
          [options]="{
            center: {
              lat: parseFloat(data.mission.locationLatitude),
              lng: parseFloat(data.mission.locationLongitude),
            },
            disableDefaultUI: true,
            mapTypeId: 'satellite',
          }"
          [dimensions]="mapDimensions"
        ></flg-map>
      </div>
    </div>

    <!-- <div class="site-info-group" *ngIf="hasAttachmentsFiles()">
      <h2>Attachments</h2>
      <label for="attachDocumentation" class="label-order"
        >Attach reference documentation (Sample Images, Authorization forms, other)</label
      >
    </div>
    <ng-container *ngFor="let doc of mission.filesAttachments; let i = index">
      <div *ngIf="(i + 1) % 6 === 0 || i + 1 === mission.filesAttachments.length">
        <div class="d-flex w-100"></div>
      </div>
      <div class="btn btn-gray me-2 mt-2">
        {{ doc.name }}
      </div>
      <div
        *ngIf="(i + 1) % 6 === 0 || i + 1 === mission.filesAttachments.length"
        class="w-100"
      ></div>
    </ng-container> -->

    <div class="site-info-group mt-3">
      <h3>Additional Notes</h3>
      <div class="flg-editor-output" [innerHTML]="data.mission.additionalNotes || 'N/A'"></div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
    Close
  </button>
</mat-dialog-actions>
