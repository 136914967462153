import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { enumPortal } from '../models/enum/enum-portal.enum';
import { UserAllowedOnPortalDto } from '../models/access-portal/models';


@Injectable({
  providedIn: 'root',
})
export class AccessPortalService {
  private identityApiName = 'AbpIdentity';

  constructor(private restService: RestService) {}

  isAllowedPortal = (portal:enumPortal, userId?:string) =>
    this.restService.request<any, UserAllowedOnPortalDto>(
      {
        method: 'GET',
        url: '/identityservice/api/users/is-allowed-on-portal',
        params: {
          portal,
          userId,
        }
      },
      { apiName: this.identityApiName}
    );
}
