import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  DeliverablesTableDto,
  GetPackageDetailsInput,
  GetProductDeliverableInput,
  PackageTableDto,
  ProductDeliverablesCreateDto,
  ProductDeliverablesDto,
  ProductDeliverablesExcelDownloadDto,
  ProductDeliverablesUpdateDto,
} from '../../relationals/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { DeliverableAttributeUpdateDto } from 'projects/flyguys/src/app/models/products-deliverables/deliverable-attribute-update-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductDeliverablesService {
  apiName = 'missionsService';

  create = (input: ProductDeliverablesCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductDeliverablesDto>(
      {
        method: 'POST',
        url: '/api/missions-service/product-deliverable',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/product-deliverable/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductDeliverablesDto>(
      {
        method: 'GET',
        url: `/api/missions-service/product-deliverable/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/product-deliverable/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetProductDeliverableInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ProductDeliverablesDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/product-deliverable',
        params: {
          filterText: input.filterText,
          productId: input.productId,
          deliveribleId: input.deliveribleId,
          byDefault: input.byDefault,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (
    input: ProductDeliverablesExcelDownloadDto,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/product-deliverable/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: ProductDeliverablesUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductDeliverablesDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/product-deliverable/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getpackagesOrderRequest = (input: GetPackageDetailsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PackageTableDto[]>(
      {
        method: 'GET',
        url: '/api/missions-service/product-deliverable/get-packages-order-request',
        params: {
          customerId: input.customerId,
          industryId: input.industryId,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getproductsByIndustry = (input: GetPackageDetailsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DeliverablesTableDto[]>(
      {
        method: 'GET',
        url: '/api/missions-service/product-deliverable/get-products-by-industry',
        params: {
          industryId: input.industryId,
        },
      },
      { apiName: this.apiName, ...config }
    );

  updateAttributeValues = (newValues: Array<DeliverableAttributeUpdateDto>, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/missions-service/product-deliverable/attribute/values`,
        body: newValues,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
