import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import ordinal from 'ordinal';
import { recurrenceEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/recurrency.enum';
import {
  RecurrenceDTO,
  RecurrenceForm,
  RecurrenceMonthlyInterval,
} from './model/recurrence-form.model';
import { OrdersService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { OrderRequestTotalMissionsDTO } from '../dto/order-request-total-missions.dto';
import { OrderRequestMissionProjectDTO } from '../dto/order-request-mission-project.dto';
import { DatePipe } from '@angular/common';
import { Subscription, finalize } from 'rxjs';

export interface RecurrenceData {
  type: recurrenceEnum;
  captureDate: Date;
  previousRecurrence: RecurrenceDTO;
}

@Component({
  selector: 'app-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.scss'],
})
export class RecurrenceComponent {
  recurrenceType: recurrenceEnum;
  recurrence = recurrenceEnum;
  recurrenceTimeSpan = [
    {
      label: 'days',
      id: 1,
    },
    {
      label: 'weeks',
      id: 2,
    },
    {
      label: 'months',
      id: 3,
    },
  ];

  form: RecurrenceForm;
  startDate: Date;

  weeklyOptions: any[];

  busy: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RecurrenceData,
    public dialogRef: MatDialogRef<RecurrenceComponent>,
    public readonly orderService: OrdersService,
    private datePipe: DatePipe
  ) {
    this.form = new RecurrenceForm(data);
    this.recurrenceType = this.data.type;
    this.startDate = this.data.captureDate;

    if (data.type === recurrenceEnum.Monthly || data.type === recurrenceEnum.Bimonthly) {
      this.weeklyOptions = this.generateWeeklyOptions(
        data.captureDate,
        data.type === recurrenceEnum.Bimonthly
      );
    }
  }

  /**
   * Generates labels for the Intervals in the Weekly Recurrence
   * @param forDate Date
   * @param isBimonthly boolean
   * @returns { id: RecurrenceMonthlyInterval; label: string; }[]
   */
  generateWeeklyOptions(
    forDate: Date,
    isBimonthly: boolean = false
  ): {
    id: RecurrenceMonthlyInterval;
    label: string;
  }[] {
    const monthInterval = `Every ${isBimonthly ? 'other' : ''} month`;

    return [
      {
        id: RecurrenceMonthlyInterval.SpecificDay,
        label: `${monthInterval}, on the ${ordinal(forDate.getDate())}`,
      },
      {
        id: RecurrenceMonthlyInterval.FirstFriday,
        label: `${monthInterval}, on the first friday`,
      },
    ];
  }

  getTotalMissions() {
    let request = {
      projectName: '',
      recurrence: this.form.toDTO().type,
      repeatsOn: this.form.toDTO().repeatsOn,
      beginDate: this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(this.form.toDTO().endDate, 'yyyy-MM-dd'),
      repetitions: this.form.toDTO().repetitions,
    } as OrderRequestMissionProjectDTO;

    this.busy = true;

    const subsOrder = this.orderService
      .getTotalMissions(request)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe({
        next: data => {
          if (data?.totalMissions != '' && data?.totalMissions != '0') {
            this.form.setTotalMissions(Number(data?.totalMissions));
            this.dialogRef.close(this.form);
          }
        },
        error: error => {
          console.error(`Order Creation - Recurrence: ${error}`);
        },
      });

    this.subscriptions.push(subsOrder);
  }

  /**
   * Handles clicking on Done on the modal
   * @returns void
   */
  handleClickDone(): void {
    this.getTotalMissions();
  }
}
