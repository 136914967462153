<header
  class="header-area bg-white"
  stickyClass="sticky-box-shadow"
  ngStickyNav
  [class.component-dark-theme]="themeService.isDark()"
  [ngClass]="{ active: isToggled }"
>
  <div class="d-flex align-items-center justify-content-between">
    <div class="header-left-side d-flex align-items-center">
      <div class="burger-menu" (click)="toggle()" [ngClass]="{ active: isToggled }">
        <span class="material-symbols-outlined"> menu </span>
      </div>
    </div>
    <ul class="header-right-side d-flex align-items-center t-mt-0 t-mb-0">
      <li *abpPermission="'Chat.Messaging'">
        <button
          mat-icon-button
          class="tagus-menu-btn p-0 border-none rounded-circle position-relative"
          [class.notification-btn]="chatConfigService.unreadMessagesCount > 0"
          routerLink="/chat"
        >
          <span class="material-symbols-outlined"> chat_bubble </span>
        </button>
      </li>

      <ng-container *ngIf="isLogged()">
        <li>
          <button
            mat-icon-button
            [matMenuTriggerFor]="noteMenu"
            class="tagus-menu-btn p-0 border-none rounded-circle position-relative"
            [ngClass]="{ 'notification-btn': notes.length > 0 }"
          >
            <span class="material-symbols-outlined"> description </span>
          </button>

          <mat-menu
            class="notifications-popover mat-elevation-z2"
            #noteMenu="matMenu"
            xPosition="before"
          >
            <ng-template matMenuContent>
              <header class="notifications-popover notifications-popover--header">
                <h4 class="m-0 p-2">Notes Notifications</h4>
              </header>

              <ul class="notifications-popover notifications-popover--body">
                <ng-container *ngIf="notes.length > 0; else elseTemplate">
                  <li
                    *ngFor="let note of notes | slice: 0 : 5; let i = index"
                    [@fadeInAnimation]="'in'"
                    [routerLink]="[generateMissionDetailPathNote(note)]"
                  >
                    <div
                      class="notifications-popover notifications-popover--item notifications-popover--note"
                    >
                      <span class="material-symbols-outlined"> description </span>
                      <p>
                        <span
                          class="title"
                          matTooltip="Job ID: {{ note.jobId || 'N/A' }} - {{ note.name }}"
                          [matTooltipShowDelay]="2000"
                        >
                          Job ID: {{ note.jobId || 'N/A' }} - {{ note.name }}
                        </span>
                        <span class="body">{{ note.body }}</span>
                        <time>
                          {{
                            getDateInLocalTime(note.creationTime)
                              | date: getDateFormat(note.creationTime)
                          }}
                        </time>
                      </p>
                    </div>
                  </li>
                </ng-container>
                <ng-template #elseTemplate>
                  <li [@fadeInAnimation]="'in'">
                    <div class="notifications-popover notifications-popover--item">
                      <span class="material-symbols-outlined"> description </span>
                      <p>There are no notes notifications to show</p>
                    </div>
                  </li>
                </ng-template>
              </ul>

              <div class="notifications-popover notifications-popover--button">
                <button mat-flat-button class="no-outline-button" routerLink="/orders">
                  View All
                </button>
              </div>
            </ng-template>
          </mat-menu>
        </li>

        <li>
          <button
            mat-icon-button
            class="tagus-menu-btn p-0 border-none rounded-circle position-relative"
            *ngIf="notifications.length == 0"
          >
            <span class="material-symbols-outlined"> notifications </span>
          </button>
          <button
            mat-icon-button
            [matMenuTriggerFor]="notificationMenu"
            *ngIf="notifications.length > 0"
            class="tagus-menu-btn p-0 border-none rounded-circle position-relative notification-btn"
          >
            <span class="material-symbols-outlined"> notifications </span>
          </button>

          <mat-menu
            class="notifications-popover mat-elevation-z2"
            #notificationMenu="matMenu"
            xPosition="before"
          >
            <ng-template matMenuContent>
              <header class="notifications-popover notifications-popover--header">
                <h4 class="m-0 p-2">Notifications</h4>
              </header>

              <ul class="notifications-popover notifications-popover--body">
                <li
                  *ngFor="let notif of notifications | slice: 0 : 5; let i = index"
                  [@fadeInAnimation]="'in'"
                  [routerLink]="[generateMissionDetailPath(notif)]"
                >
                  <div class="notifications-popover notifications-popover--item">
                    <span class="material-symbols-outlined"> description </span>
                    <p>
                      {{ notif.message }}
                      <time> {{ notif.arguments.date | date }}</time>
                    </p>

                    <button
                      class="d-flex align-items-center bg-transparent border-none p-0"
                      (click)="handleOnRemoveNotification(i, $event)"
                    >
                      <span class="material-symbols-outlined"> close </span>
                    </button>
                  </div>
                </li>
              </ul>

              <div class="notifications-popover notifications-popover--button">
                <button mat-flat-button class="no-outline-button" routerLink="/notifications">
                  View All
                </button>
              </div>
            </ng-template>
          </mat-menu>
        </li>
      </ng-container>

      <li class="vr"></li>

      <li *ngIf="isLogged(); else notLoggedIn">
        <a [matMenuTriggerFor]="profileMenu">
          <span class="material-symbols-outlined me-1"> account_circle </span>
          <span class="username-tag">{{ currentUser.userName }}</span>
        </a>
        <mat-menu class="common-menu mat-elevation-z2" #profileMenu="matMenu" xPosition="before">
          <ul class="common-menu common-menu--body">
            <li (click)="logout()">
              <span class="material-symbols-outlined"> logout </span>
              Logout
            </li>
          </ul>
        </mat-menu>
      </li>

      <ng-template #notLoggedIn>
        <li>
          <a routerLink="/account">
            <mat-icon class="t-me-5">vpn_key</mat-icon>
            <span>Please Log In</span>
          </a>
        </li>
      </ng-template>
    </ul>
  </div>
</header>
