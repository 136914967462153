import { MissionSLADto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { CaptureMustBeEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum';

export class MissionDetailsForm {
  missionId: string = '';
  missionName: string = '';
  description: string = '';
  priorityId: string = '';
  orderPriority: string = '';
  industryId: string = '';
  orderIndustry: string = '';
  customerRequestedCaptureDate: string = '';
  customerRequestedCaptureTime: string = '';
  customerRequestedCaptureMustBe: CaptureMustBeEnum = 0;
  missionAssetOrder?: string = '';
  fixedCustomerRequestedCaptureDate: string = '';
  fixedCustomerRequestedCaptureTime: string = '';
  customerId: string = '';
  sla?: MissionSLADto[];
  manualAirspaceWaiverRequired?: boolean;
}
