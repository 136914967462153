<h5 mat-dialog-title class="flg-modal flg-modal__title">
  {{ modalConfig.title || 'Add New Contact ' }}
</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container p-0">
    <div class="row justify-content-center">
      <form [formGroup]="formOrderContact" skipValidation>
        <mat-form-field class="w-100">
          <mat-label>Contact From</mat-label>
          <mat-select formControlName="contactFromId">
            <mat-option *ngFor="let data of contactTypes.items" [value]="data.id">
              {{ data.description }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="formOrderContact.get('contactFromId').hasError('required')">
            Please enter a value
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100 mb-3">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" placeholder="Ex. email@domain.com" />
          <mat-error *ngIf="formOrderContact.get('email').hasError('email')">
            Please enter a proper email address
          </mat-error>
          <mat-error *ngIf="formOrderContact.get('email').hasError('emailExists')">
            This email already exists
          </mat-error>
          <mat-hint class="mt-1" *ngIf="showHintEditContactEmail">
            {{ 'customersService::OnlyEditContactsEmail' | abpLocalization }}
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="w-50 pe-2">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="name" />
        </mat-form-field>
        <mat-form-field class="w-50">
          <mat-label>Last name</mat-label>
          <input matInput placeholder="Last Name" formControlName="lastname" />
        </mat-form-field>
        <flg-phone-input
          [label]="'Phone Number'"
          [widthClass]="'w-100'"
          [control]="formOrderContact.get('number')"
        ></flg-phone-input>
        <div *ngIf="displayContactMethodAndLevelOfCoordination">
          <label for="preferredContactMethod" class="label-order w-100 mt-3 mb-3">
            Preferred contact method
          </label>

          <mat-radio-group formControlName="preferredContactMethodId" id="preferredContactMethod">
            <mat-radio-button
              class="pe-2"
              *ngFor="let contactMethod of contactMethods.items"
              [value]="contactMethod.id"
            >
              <span class="checkbox-label">{{ contactMethod.description }}</span>
            </mat-radio-button>
          </mat-radio-group>
          <label for="levelcoordination" class="label-order w-100 mt-3 mb-3"
            >Level of coordination</label
          >

          <mat-radio-group formControlName="levelCommunicationId" id="levelcoordination">
            <mat-radio-button
              class="pe-2"
              *ngFor="let levelCommunication of levelCommunications.items"
              [value]="levelCommunication.id"
            >
              <span class="checkbox-label">{{ levelCommunication.description }}</span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <!-- TODO: hidden this field for ticket 10071 - Share Data feature is missing, hide it if it's not going to be built for Mid Dec -->
        <!-- <mat-checkbox [(ngModel)]="ordercontactModel.shareData" formControlName="shareData"
          ><span class="checkbox-label">Share data with this contact</span></mat-checkbox
        > -->

        <flg-pending-controls
          [touched]="formOrderContact.touched"
          [controls]="getPendingControls()"
        ></flg-pending-controls>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      {{ modalConfig.actions.cancel || 'Cancel ' }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="saveContact()"
      [disabled]="!IsValid()"
      [class.disabled-button]="!IsValid()"
    >
      {{ 'missionsService::Save' | abpLocalization }}
    </button>
  </div>
</mat-dialog-actions>
