import { Component, Input, EventEmitter, Output } from '@angular/core';
import { OrderFormContactModel } from '../model/order-form-contact.model';

@Component({
  selector: 'app-order-contact-table',
  templateUrl: './order-contacts.component.html',
  styleUrls: ['./order-contacts.component.scss'],
})
export class OrderContactsTableComponent {
  @Input() disableActions = false;
  @Input() disableEdit = false;
  @Input() contacts: OrderFormContactModel[];
  @Input() columns: OrderFormContactModel[] = [];
  @Input() showLevelOfCoordinationColumn : boolean = true;
  @Input() showContactMethodColumn : boolean = true;
  
  @Output() requestSent = new EventEmitter();
  @Output() removecontact = new EventEmitter<OrderFormContactModel>();
  @Output() editcontact = new EventEmitter<OrderFormContactModel>();  

  editContact(row: OrderFormContactModel) {
    this.editcontact.emit(row);
  }

  removeContact(row: OrderFormContactModel) {
    this.removecontact.emit(row);
  }
}
