import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AttributeValidationDefinitionCreateDto,
  AttributeValidationDefinitionDto,
  AttributeValidationDefinitionUpdateDto,
  GetAttributeValidationDefinitionsInput,
} from '../../relationals/models';
import { CaptureDeliverableAttributeValidation } from '../../../../../../../flyguys/src/app/shared/deliverable-attributes/capture-deliverable-attribute-validation.interface';

@Injectable({
  providedIn: 'root',
})
export class AttributeValidationDefinitionService {
  apiName = 'missionsService';

  create = (input: AttributeValidationDefinitionCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttributeValidationDefinitionDto>(
      {
        method: 'POST',
        url: '/api/missions-service/attribute-validation-definitions',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/attribute-validation-definitions/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttributeValidationDefinitionDto>(
      {
        method: 'GET',
        url: `/api/missions-service/attribute-validation-definitions/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetAttributeValidationDefinitionsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AttributeValidationDefinitionDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/attribute-validation-definitions',
        params: {
          filterText: input.filterText,
          name: input.name,
          toleranceType: input.toleranceType,
          toleranceValueMin: input.toleranceValueMin,
          toleranceValueMax: input.toleranceValueMax,
          toleranceCode: input.toleranceCode,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (
    id: string,
    input: AttributeValidationDefinitionUpdateDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, AttributeValidationDefinitionDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/attribute-validation-definitions/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getAttributeValidationsPerDeliverable = (deliverableId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CaptureDeliverableAttributeValidation[]>(
      {
        method: 'GET',
        url: `/api/missions-service/attribute-validation-definitions/get-attribute-validations-per-deliverable/${deliverableId}`,
      },
      { apiName: this.apiName, ...config },
    );
  constructor(private restService: RestService) {}
}
