<!-- <div class="row mb-3">
  <div class="col">
    <abp-file-management-breadcrumb [missionFilter]="missionFilter" [navigatedFolders]="navigator.navigatedFolderPath$ | async"
      (breadcrumbClick)="navigator.goToFolder($event)"></abp-file-management-breadcrumb>
  </div>
</div>

<abp-file-management-folder-filter></abp-file-management-folder-filter> -->
<abp-file-management-folder-content
  (contentUpdate)="contentUpdate.emit($event)"
  [hideDirectoryView]="hideDirectoryView"
  [missionFilter]="missionFilter"
  [showOptions]="showOptions"
  [filesSelected]="filesSelected"
  (filesSelectedChange)="filesSelectedChange.emit($event)"
  (selectAllChange)="selectAllChange.emit($event)"
  [onlyViewDownload]="onlyViewDownload"
  [optionsFilter]="optionsFilter"
></abp-file-management-folder-content>
