<div class="vh-100 t-pt-50 t-pb-50">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="tagus-form ms-auto me-auto">
        <div class="title">
          <p class="d-block mt-3 lh-base">
            <a class="main-color" routerLink="/account/login">
              <i class="fa fa-long-arrow-left me-1" aria-hidden="true"></i>
              {{ 'AbpAccount::Login' | abpLocalization }}</a
            >
          </p>
          <div class="d-flex mb-3 align-items-center">
            <h2 class="t-mb-0 t-me-30">Forgot Your Password?</h2>
            <div>
              <img
                class="img-fluid"
                src="assets/images/flyguys-simple-logo-light.png"
                alt="logo-icon"
              />
            </div>
          </div>
        </div>
        <!-- @TODO Possible localization entries needed for the placeholders -->
        <form
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
          *ngIf="!isEmailSent; else emailSentTemplate"
          validateOnSubmit
        >
          <div class="bg-white border-radius p-3">
            <div class="tagus-form-group without-icon">
              <label class="d-block t-mb-12 fw-semibold gray-color">{{
                'AbpAccount::EmailAddress' | abpLocalization
              }}</label>
              <mat-form-field appearance="fill">
                <mat-label>Enter your email address</mat-label>
                <input matInput formControlName="email" autofocus skipValidation />
                <mat-error *ngIf="form.get('email').hasError('required')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div #recaptcha></div>
          <button mat-flat-button class="tagus d-block fw-semibold" [disabled]="inProgress">
            {{ 'AbpAccount::Submit' | abpLocalization }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #emailSentTemplate>
  <p>
    {{ 'AbpAccount::PasswordResetMailSentMessage' | abpLocalization }}
  </p>

  <a routerLink="/account/login" class="d-grid">
    <button class="d-block t-mt-2 t-mb-3 btn btn-primary">
      <i class="fa fa-long-arrow-left me-1" aria-hidden="true"></i>
      {{ 'AbpAccount::BackToLogin' | abpLocalization }}
    </button>
  </a>
</ng-template>
