<div class="form-container">
  <mat-icon
    class="close-modal-action"
    aria-hidden="false"
    aria-label="{{ 'pilotsService::CloseIcon' | abpLocalization }}"
    fontIcon="close"
    mat-dialog-close
    (click)="onClickClose()"
  ></mat-icon>
  <div class="groups row">
    <div class="col-sm-12 formTitle">
      <div class="label-container-main">
        <mat-label class="label-container-value">{{ 'pilotsService::BulkRevoke' | abpLocalization }}</mat-label>
      </div>
    </div>
    <div class="col-sm-4 left-side mission-details">
      <div class="title-container">
        <h3 class="left-side-title">
          {{ 'pilotsService::ReferenceInformation' | abpLocalization }}
        </h3>
      </div>
      <span *ngIf="modalData.selectedPilots.length > 1">
        {{ modalData.selectedPilots.length }} pilots selected.</span
      >
      <span *ngIf="modalData.selectedPilots.length == 1"
        >{{ modalData.selectedPilots.length }} pilot selected.</span
      >
    </div>
    <div class="col-sm-8 right-side">
      <div class="title-container">
        <h3 class="title">{{ 'pilotsService::RevokeBadge' | abpLocalization }}</h3>
      </div>

      <div class="badge-options-container">
        <mat-spinner *ngIf="loading" [diameter]="25"></mat-spinner>

        <section class="badge-options" *ngIf="!loading">
          <ng-container *ngIf="allowToSave; else noBadges">
            <h4>{{ 'pilotsService::BadgesInCommon' | abpLocalization }}</h4>
            <div class="badge-chips">
              <app-grid-badge
                *ngFor="let badge of displayedBadges"
                [badge]="badge"
                [removable]="true"
                (removeEvent)="removeBadge($event)"
              ></app-grid-badge>
            </div>
          </ng-container>
          <ng-template #noBadges>
            <h4>{{ 'pilotsService::NoBadgesInCommonMessage' | abpLocalization }}</h4>
          </ng-template>
        </section>
      </div>

      <mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
        <div class="d-flex justify-content-end">
          <button
            mat-flat-button
            class="outline-button me-2 badge-action-button"
            type="button"
            (click)="onClickClose()"
          >
            {{ 'pilotsService::Cancel' | abpLocalization }}
          </button>
          <button
            mat-flat-button
            color="primary"
            class="common-button badge-action-button"
            type="button"
            [disabled]="!allowToSave"
            (click)="onClickSave()"
          >
            {{ 'pilotsService::Save' | abpLocalization }}
          </button>
        </div>
      </mat-dialog-actions>
    </div>
  </div>
</div>
