import { FormControl, FormGroup, Validators } from '@angular/forms';
import ordinal from 'ordinal';
import { recurrenceEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/recurrency.enum';
import { RecurrenceData } from '../recurrence.component';
import { mapToDays } from '../../daypicker/daypicker.utils';

export interface RecurrenceDTO {
  type: recurrenceEnum;
  repeatsOn?: number[];
  endDate?: Date;
  repetitions?: number;
}

export enum RecurrenceCompletitionState {
  'On' = 1,
  'After' = 2,
}

export enum RecurrenceMonthlyInterval {
  'SpecificDay' = 1,
  'FirstFriday' = 2,
}

// Helps with the de-serialization of the form when restoring a previous state
const fieldMap = {
  [recurrenceEnum.Daily]: ['endDate', 'repeatsOn', 'completitionRadio', 'repetitions'],
  [recurrenceEnum.Weekly]: ['endDate', 'repeatsOn'],
  [recurrenceEnum.Monthly]: ['endDate', 'repeatsOn', 'weekInterval'],
  [recurrenceEnum.Biweekly]: ['endDate', 'repeatsOn'],
  [recurrenceEnum.Bimonthly]: ['endDate', 'repeatsOn', 'weekInterval'],
};

export class RecurrenceForm extends FormGroup {
  type: recurrenceEnum;
  captureDate: Date;
  totalMissions: Number;

  constructor(data: RecurrenceData) {
    super({});

    this.type = data.type;
    this.captureDate = data.captureDate;

    this.addControl('endDate', new FormControl<Date | null>(null, [Validators.required]));

    switch (this.type) {
      case recurrenceEnum.Daily: {
        this.addControl('repeatsOn', new FormControl<number | null>(null, [Validators.required]));

        // The default state of the Daily Recurrence Form is to have the Radio Selected on Date
        this.addControl(
          'completitionRadio',
          new FormControl<number>(RecurrenceCompletitionState.On)
        );

        this.addControl(
          'repetitions',
          new FormControl<number | null>({ value: null, disabled: true }, [Validators.required])
        );

        this.get('completitionRadio').valueChanges.subscribe({
          next: value => {
            if (value === 1) {
              this.get('endDate').enable();
              this.get('repetitions').disable();
            } else {
              this.get('endDate').disable();
              this.get('repetitions').enable();
            }
          },
        });

        break;
      }

      case recurrenceEnum.Biweekly:
      case recurrenceEnum.Weekly: {
        this.addControl('repeatsOn', new FormControl<number[]>([], [Validators.required]));
        break;
      }

      case recurrenceEnum.Bimonthly:
      case recurrenceEnum.Monthly: {
        const interval = this.captureDate
          ? RecurrenceMonthlyInterval.SpecificDay
          : RecurrenceMonthlyInterval.FirstFriday;
        this.addControl('weekInterval', new FormControl<number>(interval));
        break;
      }

      default: {
        console.error(`RecurrenceForm: Unknown recurrence used: ${this.type}`);
      }
    }

    // Load previous data if available and only if the type matches, otherwise state
    // will not match
    if (data.previousRecurrence && data.previousRecurrence.type === this.type) {
      const fields = fieldMap[this.type];

      fields.forEach((fieldName: string) =>
        this.get(fieldName)?.setValue(data.previousRecurrence[fieldName])
      );
    }
    this.totalMissions = 0;
  }

  /**
   * Serializes the form into an API-ready object
   * @returns RecurrenceDTO
   */
  toDTO(): RecurrenceDTO {
    const recurrence: RecurrenceDTO = {
      type: this.type,
    };

    switch (this.type) {
      case recurrenceEnum.Daily: {
        recurrence.repeatsOn = [this.get('repeatsOn').value];

        if (this.get('completitionRadio').value === 1) {
          recurrence.endDate = this.get('endDate').value;
        } else {
          recurrence.repetitions = this.get('repetitions').value;
        }
        break;
      }
      case recurrenceEnum.Biweekly:
      case recurrenceEnum.Weekly: {
        recurrence.repeatsOn = this.get('repeatsOn').value;
        recurrence.endDate = this.get('endDate').value;
        break;
      }
      case recurrenceEnum.Bimonthly:
      case recurrenceEnum.Monthly: {
        const selectedDate: Date = new Date(this.captureDate);

        // Pick the selected day of the capture date as an interval
        // type. Otherwise use the first friday option
        if (this.get('weekInterval').value === 1) {
          recurrence.repeatsOn = [selectedDate.getDate()];
        } else {
          recurrence.repeatsOn = [0];
        }

        recurrence.endDate = this.get('endDate').value;
        break;
      }
    }

    return recurrence;
  }

  /**
   * Builds a human-readable recurrence
   * @returns string
   */
  getRecurrence(): string {
    const dto = this.toDTO();
    const endDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(this.get('endDate').value);

    let interval: number | string;

    switch (dto.type) {
      case recurrenceEnum.Daily:
        let times: number;
        [interval] = dto.repeatsOn;

        if (dto.repetitions) {
          times = dto.repetitions;

          return `Every ${interval} Days for ${times} Times. Total Missions: ${this.totalMissions}`;
        }

        return `Every ${interval} Days until ${endDate}.Total Missions: ${this.totalMissions}`;

      case recurrenceEnum.Biweekly:
      case recurrenceEnum.Weekly:
        interval = mapToDays(dto.repeatsOn).join(', ');
        return `Every ${interval} until ${endDate}.Total Missions: ${this.totalMissions}`;

      case recurrenceEnum.Bimonthly:
      case recurrenceEnum.Monthly:
        const monthInterval =
          this.type === recurrenceEnum.Bimonthly ? 'Every other month' : 'Every month';
        [interval] = dto.repeatsOn;

        if (interval === 0) {
          return `${monthInterval}, on the first Friday until ${endDate}.Total Missions: ${this.totalMissions}`;
        }

        return `${monthInterval}, on the ${ordinal(interval)} until ${endDate}.Total Missions: ${
          this.totalMissions
        }`;
    }
  }

  setTotalMissions(value: number) {
    this.totalMissions = value;
  }
}
