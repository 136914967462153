import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetPortafolioContactInput,
  PortafolioContactsCreateDto,
  PortafolioContactsDto,
  PortafolioContactsExcelDownloadDto,
  PortafolioContactsUpdateDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class PortafolioContactsService {
  apiName = 'missionsService';

  create = (input: PortafolioContactsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PortafolioContactsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/portafolio-contact',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/portafolio-contact/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PortafolioContactsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/portafolio-contact/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/portafolio-contact/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: PortafolioContactsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/portafolio-contact/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetPortafolioContactInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PortafolioContactsDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/portafolio-contact',
        params: {
          filterText: input.filterText,
          portafolioId: input.portafolioId,
          contactId: input.contactId,
          state: input.state,
          projectManager: input.projectManager,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: PortafolioContactsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PortafolioContactsDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/portafolio-contact/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  updateProjectManager = (
    missionId: string,
    projectManagerId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, PortafolioContactsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/portafolio-contact/update-project-manager',
        body: {
          missionId: missionId,
          projectManagerContactId: projectManagerId,
        },
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
