<div
  *ngIf="linkUser"
  role="alert"
  class="alert alert-warning"
  [innerHTML]="'AbpAccount::LinkAccountWarning' | abpLocalization : '/account/login'"
></div>

<div class="vh-100 t-pt-50 t-pb-50">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="tagus-form ms-auto me-auto">
        <div class="title">
          <div class="d-flex mb-3 align-items-center justify-content-center">
            <img
              class="img-fluid"
              src="assets/images/flyguys-simple-logo-light.png"
              alt="logo-icon"
            />
          </div>
          <ng-container *ngIf="isSelfRegistrationEnabled">
            <p class="lh-base">
              Welcome! Please enter your credentials or
              <a
                routerLink="/account/register"
                queryParamsHandling="preserve"
                class="main-color fw-medium"
                >create a new account</a
              >
            </p>
          </ng-container>
        </div>
        <!-- @TODO Possible localization entries needed for the placeholders (Login/Password) -->
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="bg-white border-radius p-3">
            <div class="tagus-form-group without-icon mb-2">
              <label class="d-block t-mb-12 fw-semibold gray-color">{{
                'AbpAccount::UserNameOrEmailAddress' | abpLocalization
              }}</label>
              <mat-form-field appearance="fill">
                <mat-label>Email or Login</mat-label>
                <input matInput formControlName="username" autofocus skipValidation />
                <mat-error *ngIf="form.get('username').hasError('required')">
                  Please enter a valid email address or login
                </mat-error>
              </mat-form-field>
            </div>
            <div class="tagus-form-group without-icon">
              <label class="d-block t-mb-12 fw-semibold gray-color">{{
                'AbpAccount::Password' | abpLocalization
              }}</label>
              <mat-form-field appearance="fill">
                <mat-label>Your password</mat-label>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  formControlName="password"
                  skipValidation
                />
                <mat-error *ngIf="form.get('password').hasError('required')">
                  Please enter your password
                </mat-error>
                <button
                  mat-icon-button
                  matSuffix
                  type="button"
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
              </mat-form-field>

          <div class="d-flex info align-items-center justify-content-between">
            <mat-checkbox class="gray-color" formControlName="rememberMe">{{
              'AbpAccount::RememberMe' | abpLocalization
            }}</mat-checkbox>
                <a
                  routerLink="/account/forgot-password"
                  class="d-inline-block main-color fw-medium"
                  >{{ 'AbpAccount::ForgotPassword' | abpLocalization }}</a
                >
              </div>
            </div>
          </div>
          <div #recaptcha></div>
          <button mat-flat-button [disabled]="inProgress" class="tagus fw-semibold">
            {{ 'AbpAccount::Login' | abpLocalization }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
