import { ModuleWithProviders, NgModule } from '@angular/core';
import { CUSTOMERS_SERVICE_ROUTE_PROVIDERS } from './providers/route.provider';
import { BASICS_CUSTOMER_CONTACTS_ROUTE_PROVIDER } from './providers/customer-contacts-route.provider';
import { BASICS_CUSTOMERS_ROUTE_PROVIDER } from './providers/customers-route.provider';
import { RELATIONALS_CUSTOMER_ADDRESSES_ROUTE_PROVIDER } from './providers/customer-addresses-route.provider';
import { RELATIONALS_CUSTOMER_CONFIGURATIONS_ROUTE_PROVIDER } from './providers/customer-configurations-route.provider';
import { RELATIONALS_CUSTOMER_NOTIFICATIONS_ROUTE_PROVIDER } from './providers/customer-notifications-route.provider';
import { RELATIONALS_CUSTOMER_ATTRIBUTE_ROUTE_PROVIDER } from './providers/customer-attribute-route.provider';

@NgModule()
export class CustomersServiceConfigModule {
  static forRoot(): ModuleWithProviders<CustomersServiceConfigModule> {
    return {
      ngModule: CustomersServiceConfigModule,
      providers: [
        CUSTOMERS_SERVICE_ROUTE_PROVIDERS,
        BASICS_CUSTOMER_CONTACTS_ROUTE_PROVIDER,
        BASICS_CUSTOMERS_ROUTE_PROVIDER,
        RELATIONALS_CUSTOMER_ADDRESSES_ROUTE_PROVIDER,
        RELATIONALS_CUSTOMER_CONFIGURATIONS_ROUTE_PROVIDER,
        RELATIONALS_CUSTOMER_NOTIFICATIONS_ROUTE_PROVIDER,
        RELATIONALS_CUSTOMER_ATTRIBUTE_ROUTE_PROVIDER
      ],
    };
  }
}
