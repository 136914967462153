import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lib-calculation-due-dates',
  templateUrl: './calculation-due-dates.component.html',
  styleUrls: ['./calculation-due-dates.component.css'],
})
export class CalculationDueDatesComponent implements OnInit, OnChanges {
  @Input() missionStatus: string;
  @Input() dueDate: string;
  @Input() showTriangle: boolean = true;
  @Output() eventAction = new EventEmitter<any>();
  dayDueDates: number;
  showDays: boolean = true;
  readonly MINIMUM_YEAR: number = 2024;

  dayToClassMap: { [key: number]: string } = {
    0: 'purple',
    1: 'orange',
  };
  ngOnInit() {
    this.SetData();
  }

  SetData() {
    this.validateStatus();
    if (this.dueDate) {
      this.dayDueDates = this.calculateBusinessDaysDifference(this.dueDate);
    } else {
      this.showTriangle = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.missionStatus || changes.dueDate) this.SetData();
  }

  calculateBusinessDaysDifference(startDate: string): number {
    if (!this.isValidDate(startDate)) {
      return 0;
    }
    const today = new Date();
    const targetDate = new Date(startDate);

    // Set hours, minutes, seconds, and milliseconds to zero for both dates
    today.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);

    // If the dates are the same, return 0
    if (today.getTime() === targetDate.getTime()) {
      return 0;
    }

    let count = 0;
    let increment = today < targetDate ? 1 : -1;
    let currentDate = new Date(today);

    // Move the currentDate in the correct direction to start counting the difference
    while (currentDate.getTime() !== targetDate.getTime()) {
      currentDate.setDate(currentDate.getDate() + increment);

      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        // Skip weekends
        count += increment;
      }
    }

    return count;
  }

  isValidDate(date: any): boolean {
    const parsedDate = new Date(date);
    return (
      parsedDate instanceof Date &&
      !isNaN(parsedDate.getTime()) &&
      parsedDate.getTime() !== new Date('0001-01-01T00:00:00').getTime() &&
      parsedDate.getFullYear() >= this.MINIMUM_YEAR
    );
  }

  getIconClass(): string {
    if (this.dayDueDates < 0) {
      return 'red';
    } else if (this.dayDueDates == 0) {
      return 'orange';
    } else if (this.dayDueDates == 1) {
      return 'yellow';
    } else {
      this.showTriangle = false;
    }
  }

  execActionClick() {
    this.eventAction.emit();
  }

  validateStatus() {
    if (['11', '14', '22', '23'].includes(this.missionStatus.toString())) {
      this.showDays = false;
    }
  }
}
