import { BaseNode, TreeAdapter, TreeNode } from '@abp/ng.components/tree';
import { InternalStore } from '@abp/ng.core';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import {
  DirectoryDescriptorInfoDto,
  DirectoryDescriptorService,
  FilterMission,
} from '@volo/abp.ng.file-management/proxy';
import { merge, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FolderInfo } from '../models/common-types';
import { UpdateStreamService } from './update-stream.service';

export type TreeType = BaseNode & DirectoryDescriptorInfoDto;
export const ROOT_ID = 'ROOT_ID';
export const ROOT_NODE = {
  id: ROOT_ID,
  parentId: null,
  name: 'All Files',
  isRoot: true,
  hasChildren: true,
} as any;

export function mapRootIdToEmpty(id: string) {
  return id === ROOT_ID ? null : id;
}

@Injectable()
export class DirectoryTreeService implements OnDestroy, OnInit {
  missionFilter: FilterMission;

  private treeHolder: TreeAdapter<TreeType>;

  private store = new InternalStore<TreeNode<TreeType>[]>([]);
  directoryTree$ = this.store.sliceState(state => state);

  subscription = new Subscription();

  extendedKeys = [];

  currentSelectedNode$ = new Subject<DirectoryDescriptorInfoDto>();

  constructor(
    private directoryService: DirectoryDescriptorService,
    private updateStream: UpdateStreamService
  ) {
    this.setupListener();
  }

  ngOnInit(): void {
    this.directoryService.filterMission = this.missionFilter;
  }

  updateDirectories = (currentFolderId: string = null) => {
    this.collapseFolder(currentFolderId);
    const id = mapRootIdToEmpty(currentFolderId);

    if ((id == '' || !id) && this.missionFilter) {
      this.directoryService.getContent({ maxResultCount: 50 }, this.missionFilter).subscribe(f => {
        const rFolder = f.items?.[0];

        this.missionFilter.rootFolder = { id: rFolder?.id ?? '', name: rFolder?.name ?? '' };

        this.fetchDirectories(this.missionFilter.rootFolder.id).subscribe(items => {
          items = items.map(r => {
            r.parentId = null;

            return r;
          });
          this.updateTree(id, items);
        });
      });
    } else {
      this.fetchDirectories(id).subscribe(items => {
        this.updateTree(currentFolderId, items);
      });
    }
  };

  handleMove = ({ oldParentId, newParentId }) => {
    // first clear children of both node to avoid race condition
    this.treeHolder.handleUpdate({ key: oldParentId, children: [] });
    this.treeHolder.handleUpdate({ key: newParentId, children: [] });
    this.updateDirectories(oldParentId);
    this.updateDirectories(newParentId);
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setupListener() {
    this.subscription.add(
      merge(
        this.updateStream.currentDirectory$,
        this.updateStream.directoryRename$.pipe(map(folder => this.getParentOf(folder.id))),
        this.updateStream.directoryCreate$,
        this.updateStream.directoryDelete$.pipe(map(id => this.getParentOf(id)))
      ).subscribe(this.updateDirectories)
    );

    this.subscription.add(this.updateStream.directoryMove$.subscribe(this.handleMove));
  }

  findFullPathOf(folder: FolderInfo): FolderInfo[] {
    let currentId = folder?.id;
    const fullList = this.treeHolder.getList();
    const retVal = [];

    while (currentId) {
      const node = fullList.find(i => i.id === currentId);
      if (node && node.id !== ROOT_ID) {
        retVal.push(node);
      }
      currentId = node?.parentId;
    }

    return retVal
      .concat(ROOT_NODE)
      .map(item => ({ id: item.id, name: item.name }))
      .reverse();
  }

  getParentOf(id: string) {
    const node = this.treeHolder.getList().find(item => item.id === id);
    return node?.parentId || ROOT_ID;
  }

  getRootNode() {
    return ROOT_NODE;
  }

  collapseFolder(id: string) {
    const index = this.extendedKeys.indexOf(id);
    if (index > -1) {
      this.extendedKeys.splice(index, 1);
    }
  }

  announceNewSelectedNode(selected: DirectoryDescriptorInfoDto): void {
    this.currentSelectedNode$.next(selected);
  }

  private updateTree(currentFolderId: string, items: DirectoryDescriptorInfoDto[]) {
    if (items) {
      if (this.treeHolder && currentFolderId) {
        this.onTreeExistAndNavigatedToNode(currentFolderId, items as TreeType[]);
      } else {
        this.onTreeNotExistOrNavigatedToRoot(items as TreeType[]);
      }
      this.updateStore();
    }
  }

  private updateStore() {
    this.store.patch(this.treeHolder.getTree() as any);
  }

  private onTreeExistAndNavigatedToNode(parentId: string, list: TreeType[]) {
    this.treeHolder.handleUpdate({ key: parentId, children: list });
    this.extendedKeys = [...this.extendedKeys, parentId];
  }

  private onTreeNotExistOrNavigatedToRoot(result: TreeType[]) {
    this.extendedKeys = [ROOT_ID];
    this.treeHolder = new TreeAdapter([ROOT_NODE, ...result]);
  }

  private fetchDirectories(id: string) {
    return this.directoryService
      .getList(id)
      .pipe(map(result => this.bindInitialListToRoot(result.items)));
  }

  private bindInitialListToRoot(list: DirectoryDescriptorInfoDto[]) {
    return list.map(l => ({ ...l, parentId: l.parentId || ROOT_ID }));
  }
}
