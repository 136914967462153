<h5 mat-dialog-title class="flg-modal flg-modal__title">Edit Deliverable Aspects</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container p-0">
    <div class="row justify-content-center">
      <form [formGroup]="formDeliverableAspects">
        <div class="form-control-wrapper">
          <mat-form-field class="w-100" [floatLabel]="'always'">
            <mat-label>Deliverable Due Date</mat-label>
            <input
              matInput
              [matDatepicker]="dueDatePicker"
              formControlName="dueDate"
              placeholder="MM/DD/YYYY"
            />
            <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
            <mat-error *ngIf="formDeliverableAspects.get('dueDate').hasError('required')">
              Please enter a value
            </mat-error>
          </mat-form-field>
        </div>

        <!-- TODO: hide this from edit form  -->
        <!-- <div class="checkbox-section mt-1 mb-3">
          <mat-label class="mb-1">Capture Deliverables</mat-label>
          <mat-checkbox formControlName="allAtSameTime">All at the Same Time</mat-checkbox>
        </div> -->

        <flg-rich-text-input
          [control]="formDeliverableAspects.get('deliverableNotes')"
          placeholder=""
          label="Deliverable Notes"
          class="mb-3"
        ></flg-rich-text-input>

        <flg-rich-text-input
          [control]="formDeliverableAspects.get('uploadingInstructions')"
          placeholder=""
          label="Data Uploading Instructions"
          class="mb-3"
        ></flg-rich-text-input>

        <flg-rich-text-input
          [control]="formDeliverableAspects.get('additionalNotes')"
          placeholder=""
          label="Additional Notes"
          class="mb-3"
        ></flg-rich-text-input>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="saveDeliverableAspects()"
      [disabled]="!IsValid()"
      [class.disabled-button]="!IsValid()"
    >
      Save
    </button>
  </div>
</mat-dialog-actions>
