import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { enumState } from '../../../../../../customers-service/src/lib/proxy/customers-service/shared/enum-state.enum';
import { ABP, PagedResultDto } from '@abp/ng.core';
import {
  CountriesDto,
  CustomerVerticalDto,
  GetCountryInput,
  GetLevelInput,
  GetStateInput,
  LevelesDto,
  StatesDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import {
  CountriesService,
  LevelesService,
  StatesService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, first, map, Observable, of, Subscription } from 'rxjs';
import { CreateCustomer, CustomerType } from '../model/order-form-create-customer.model';
import { CustomersService } from '../../../../../../customers-service/src/lib/proxy/customers-service/controllers/basics';
import { switchMap } from 'rxjs/operators';
import { CustomerVerticalService } from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups/customer-vertical.service';
import { LocationForm } from '@flyguys/forms';
import { getPendingControls } from '@flyguys/components';

const requiredControlsNames = {
  personal: {
    customerVerticalId: 'Customer Vertical',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    primaryPhoneNumber: 'Primary Phone Number',
  },
  company: {
    customerVerticalId: 'Customer Vertical',
    companyName: 'Company Name',
  },
  companyMainContact: {
    firstName: 'Main Contact First Name',
    lastName: 'Main Contact Last Name',
    email: 'Main Contact Email',
    primaryPhoneNumber: 'Main Contact Phone Number',
  },
};

@Component({
  selector: 'app-new-customer-form',
  templateUrl: './new-customer-form.component.html',
  styleUrls: ['./new-customer-form.component.scss'],
})
export class NewCustomerFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  private subscriptions: Subscription[] = [];
  CustomerType = CustomerType;

  levels: PagedResultDto<LevelesDto> = {
    items: [],
    totalCount: 0,
  };

  dataCountries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };

  dataStates: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };

  customerVerticals: Array<CustomerVerticalDto>;

  constructor(
    public dialogRef: MatDialogRef<NewCustomerFormComponent>,
    public readonly levelService: LevelesService,
    public readonly countriesService: CountriesService,
    public readonly statesService: StatesService,
    public readonly customerService: CustomersService,
    private fb: FormBuilder,
    private customerVerticalService: CustomerVerticalService,
    private cd: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      customerType: [CustomerType.Personal],
      personal: this.fb.group({
        customerLevelId: [null],
        customerVerticalId: [null, Validators.required],
        firstName: [
          null,
          [Validators.required],
          [this.firstNameExistsValidator()],
          { updateOn: 'blur' },
        ],
        lastName: [
          null,
          [Validators.required],
          [this.lastNameExistsValidator()],
          { updateOn: 'blur' },
        ],
        email: [
          null,
          [Validators.required, Validators.email],
          [this.emailExistValidator()],
          { updateOn: 'blur' },
        ],
        primaryPhoneNumber: [null, Validators.required],
        idHubspot: [null, Validators.maxLength(100)],
      }),
      company: this.fb.group({
        customerLevelId: [null],
        customerVerticalId: [null, Validators.required],
        companyName: [
          null,
          [Validators.required],
          [this.companyNameExistsValidator()],
          { updateOn: 'blur' },
        ],
        legalName: [null],
        ein: [null],
        website: [null],
        msaCustomer: [false],
        mainContact: this.fb.group({
          firstName: [null, Validators.required],
          lastName: [null, Validators.required],
          email: [
            null,
            [Validators.required, Validators.email],
            [this.emailExistValidator()],
            { updateOn: 'blur' },
          ],
          primaryPhoneNumber: [null, Validators.required],
        }),
        idHubspot: [null, Validators.maxLength(100)],
      }),
      billingInformation: this.fb.group({
        billingFirstName: [null],
        billingLastName: [null],
        email: [null],
      }),
      location: new LocationForm(),
    });

    this.setupFormStateBasedOnCustomerType(CustomerType.Personal);
  }

  private setupFormStateBasedOnCustomerType(type: CustomerType): void {
    if (type === CustomerType.Personal) {
      this.form.get('personal')?.enable();
      this.form.get('company')?.disable();
    } else if (type === CustomerType.Company) {
      this.form.get('company')?.enable();
      this.form.get('personal')?.disable();
    }
  }

  ngOnInit(): void {
    this.getCustomerLevelValues();
    this.getCountries();
    this.getStates();
    this.getCustomerVerticalValues();
    this.handleCustomerTypeChange();
  }

  private getCustomerLevelValues(): void {
    const query = {} as ABP.PageQueryParams;
    const levelFilter = { state: enumState.Enabled } as GetLevelInput;

    this.levelService
      .getList({
        ...query,
        ...levelFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.levels = res;
      });
  }

  getCountries() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetCountryInput;
    const subsCountries = this.countriesService.getList(request).subscribe({
      next: (data: PagedResultDto<CountriesDto>) => {
        if (data?.totalCount !== 0) {
          this.dataCountries = data;
        }
      },
      error: err => console.log('Error on getting Countries: ' + JSON.stringify(err)),
    });
    this.subscriptions.push(subsCountries);
  }

  getStates() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetStateInput;
    const subsStates = this.statesService.getList(request).subscribe({
      next: (data: PagedResultDto<StatesDto>) => {
        if (data?.totalCount !== 0) {
          this.dataStates = data;
        }
      },
      error: err => console.log('Error on getting States: ' + JSON.stringify(err)),
    });

    this.subscriptions.push(subsStates);
  }

  saveAddCustomer() {
    if (this.form.invalid) return;

    const formValue = this.form.getRawValue();

    let newCustomerDto: CreateCustomer;

    if (formValue.customerType === CustomerType.Personal) {
      newCustomerDto = new CreateCustomer(
        CustomerType.Personal,
        {
          ...formValue.personal,
        },
        undefined, // No company information
        {
          ...formValue.billingInformation,
          ...formValue.location,
          zipCode: formValue.location.zip,
          countryName: this.getAddressName(this.dataCountries.items, formValue.location.countryId),
          stateName: this.getAddressName(this.dataStates.items, formValue.location.stateId),
        }
      );
    } else if (formValue.customerType === CustomerType.Company) {
      newCustomerDto = new CreateCustomer(
        CustomerType.Company,
        undefined, // No personal customer information
        {
          ...formValue.company,
        },
        {
          ...formValue.billingInformation,
          ...formValue.location,
          zipCode: formValue.location.zip,
          countryName: this.getAddressName(this.dataCountries.items, formValue.location.countryId),
          stateName: this.getAddressName(this.dataStates.items, formValue.location.stateId),
        }
      );
    }

    this.dialogRef.close(newCustomerDto);
  }

  getAddressName = (items: any[], id: string): string => {
    const item = items.find(i => i.id === id);
    return item ? item.description : '';
  };

  onClickClose(): void {
    this.dialogRef.close();
  }

  /**
   * Handles a change in the location
   * @param location Location | null
   */
  handleLocationChange(location: Location | null) {}

  private handleCustomerTypeChange(): void {
    const customerTypeControl = this.form.get('customerType');
    customerTypeControl?.valueChanges.subscribe(type => {
      this.setupFormStateBasedOnCustomerType(type);

      //Prevent Form stuck in satus Pending : Include all form controls that triggers a custom validator
      setTimeout(() => {
        this.form.get('company.companyName').updateValueAndValidity();
        this.form.get('personal.firstName').updateValueAndValidity();
        this.form.get('personal.lastName').updateValueAndValidity();
        this.form.get('personal.email').updateValueAndValidity();
        this.form.get('company.mainContact.email').updateValueAndValidity();
        this.form.get('personal')?.updateValueAndValidity();
        this.form.get('company')?.updateValueAndValidity();
        this.cd.detectChanges();
      }, 500);
    });
  }

  firstNameExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(name => {
          const lastName = this.form.get('personal.lastName')?.value;

          if (!name || !name.trim() || !lastName || !lastName.trim()) {
            return of(null);
          }
          const fullName = `${name.trim()} ${lastName || ''}`.trim();
          return this.customerService
            .checkNameExists(fullName)
            .pipe(map(exists => (exists ? { nameExists: true } : null)));
        }),
        first()
      );
    };
  }

  lastNameExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(name => {
          const firstName = this.form.get('personal.firstName')?.value;

          if (!name || !name.trim() || !firstName || !firstName.trim()) {
            return of(null);
          }
          const fullName = `${firstName || ''} ${name.trim()}`.trim();
          return this.customerService
            .checkNameExists(fullName)
            .pipe(map(exists => (exists ? { nameExists: true } : null)));
        }),
        first()
      );
    };
  }

  companyNameExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(name => {
          if (!name || !name.trim()) {
            return of(null);
          }
          return this.customerService
            .checkNameExists(name.trim())
            .pipe(map(exists => (exists ? { nameExists: true } : null)));
        }),
        first()
      );
    };
  }

  emailExistValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(email => {
          if (!email || !email.trim()) {
            return of(null);
          }
          return this.customerService
            .checkEmailExists(email.trim())
            .pipe(map(exists => (exists ? { emailExists: true } : null)));
        }),
        first()
      );
    };
  }

  private getCustomerVerticalValues(): void {
    this.customerVerticalService.getAll().subscribe(res => {
      this.customerVerticals = res;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Searches for controls that are required and not filled on the form
   * @returns pendingControl[]
   */
  getPendingControls() {
    const customerType: CustomerType = this.form.get('customerType').value;

    if (customerType === CustomerType.Personal) {
      return getPendingControls(this.form.get('personal'), requiredControlsNames.personal);
    } else {
      const company = getPendingControls(this.form.get('company'), requiredControlsNames.company);
      const companyMainContact = getPendingControls(
        this.form.get('company.mainContact'),
        requiredControlsNames.companyMainContact
      );

      return [...company, ...companyMainContact];
    }
  }
}
