import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ToggleService } from '../../common/header/toggle.service';
import { CustomizerSettingsService } from '../../tagus/customizer-settings/customizer-settings.service';

@Component({
  selector: 'app-application-layout',
  templateUrl: './application-layout.component.html',
  styleUrls: ['./application-layout.component.scss'],
})
export class ApplicationLayoutComponent implements AfterViewInit, OnDestroy {
  @ViewChild('appContainer') container: ElementRef;
  title = 'Fly Guys - Data from Above!';

  isToggled = false;

  observer;

  constructor(
    public router: Router,
    private toggleService: ToggleService,
    public themeService: CustomizerSettingsService,
    private zone: NgZone
  ) {
    this.toggleService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
  }

  // @TODO Future improvement, this could be debounced and also turned into a stream
  ngAfterViewInit(): void {
    this.observer = new ResizeObserver(entries => {
      this.zone.run(() => {
        const [entry] = entries;

        if (entry) {
          window.dispatchEvent(new Event('resize'));
        }
      });
    });

    this.observer.observe(this.container.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.observe(this.container.nativeElement);
  }
}
