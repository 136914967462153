import { ModuleWithProviders, NgModule } from '@angular/core';
import { PILOTS_SERVICE_ROUTE_PROVIDERS } from './providers/route.provider';
import { RELATIONALS_PILOT_FILES_ROUTE_PROVIDER } from './providers/pilot-files-route.provider';
import { RELATIONALS_PILOT_INVENTORIES_ROUTE_PROVIDER } from './providers/pilot-inventories-route.provider';
import { RELATIONALS_PILOT_NOTES_ROUTE_PROVIDER } from './providers/pilot-notes-route.provider';
import { RELATIONALS_PILOT_REPORTS_ROUTE_PROVIDER } from './providers/pilot-reports-route.provider';
import { RELATIONALS_PILOT_YOU_TUBE_VIDEOS_ROUTE_PROVIDER } from './providers/pilot-you-tube-videos-route.provider';
import { RELATIONALS_REPORT_COLUMNS_ROUTE_PROVIDER } from './providers/report-columns-route.provider';
import { RELATIONALS_REPORT_EXPORT_TYPES_ROUTE_PROVIDER } from './providers/report-export-types-route.provider';
import { RELATIONALS_REPORT_PARAMETERS_ROUTE_PROVIDER } from './providers/report-parameters-route.provider';
import { RELATIONALS_REPORTS_ROUTE_PROVIDER } from './providers/reports-route.provider';
import { BASICS_PILOTS_ROUTE_PROVIDER } from './providers/pilots-route.provider';
import { RELATIONALS_PILOT_BADGE_ROUTE_PROVIDER } from './providers/pilot-badge-route.provider';

@NgModule()
export class PilotsServiceConfigModule {
  static forRoot(): ModuleWithProviders<PilotsServiceConfigModule> {
    return {
      ngModule: PilotsServiceConfigModule,
      providers: [
        PILOTS_SERVICE_ROUTE_PROVIDERS,
        RELATIONALS_PILOT_FILES_ROUTE_PROVIDER,
        RELATIONALS_PILOT_INVENTORIES_ROUTE_PROVIDER,
        RELATIONALS_PILOT_NOTES_ROUTE_PROVIDER,
        RELATIONALS_PILOT_REPORTS_ROUTE_PROVIDER,
        RELATIONALS_PILOT_YOU_TUBE_VIDEOS_ROUTE_PROVIDER,
        RELATIONALS_REPORT_COLUMNS_ROUTE_PROVIDER,
        RELATIONALS_REPORT_EXPORT_TYPES_ROUTE_PROVIDER,
        RELATIONALS_REPORT_PARAMETERS_ROUTE_PROVIDER,
        RELATIONALS_REPORTS_ROUTE_PROVIDER,
        BASICS_PILOTS_ROUTE_PROVIDER,
        RELATIONALS_PILOT_BADGE_ROUTE_PROVIDER,
      ],
    };
  }
}
