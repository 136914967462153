import { RestService, Rest, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CustomerContactsDto } from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import type {
  MissionContactsPortfolioDataDto,
  MissionLocationContactsDataDto,
  MissionWithCustomersAndContactsDto,
  PortfolioSubclientDto,
} from '../../relationals/models';
import {ContactsDto} from "../../basics";

@Injectable({
  providedIn: 'root',
})
export class MissionClientsService {
  apiName = 'missionsService';

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionWithCustomersAndContactsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/get-clients-id/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getContacts = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionContactsPortfolioDataDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/get-portfolio-contacts/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getLocationContacts = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionLocationContactsDataDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/get-location-contacts/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  updateSubclientPortfolio = (input: PortfolioSubclientDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'PUT',
        url: `/api/missions-service/mission/update-subclient`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getBillingContact = (
    contactIds: string[],
    billingContactTypeId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, ContactsDto>(
      {
        method: 'POST',
        url: `/api/missions-service/contact/get-billing-contact`,
        body: { contactIds, billingContactTypeId },
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
