import { ApiInterceptor, EnvironmentService, LocalizationService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Uppy, UppyFile, UppyOptions } from '@uppy/core';
import * as Dashboard from '@uppy/dashboard';
import { FileDescriptorService } from '@volo/abp.ng.file-management/proxy';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { bufferTime, filter, map, take } from 'rxjs/operators';
import { UpdateStreamService } from './update-stream.service';
import { ChunkUploaderService } from './chunk-uploader.service';
import { FileUploadStatusModel } from '../models/file-upload/file-upload-status.model';
import { UploadFileProgressModel } from '../models/file-upload/upload-file-progress.model';
import { ChunkFileModel } from '../models/file-upload/chunk-file.model';
import { FileSizePipe } from '../../../../flyguys/src/app/pipes/file-size.pipe';
import { ParallelFileUploadService } from './parallel-file-upload.service';

export type ChosenFile = {
  name: string;
  size: number;
};

/* tslint:disable:no-string-literal */
@Injectable()
export class UploadService implements OnDestroy {
  uppy: Uppy;

  resource: { [key: string]: string };

  filesAlreadyChecked = false;

  subscriptions: Subscription[] = [];

  uploadStatus: Array<FileUploadStatusModel>;

  cancelControlUpload = new Subject<boolean>();

  currentProgress: number;
  cancelButtonConfigured: boolean;

  rootId: string;
  completeSubject = new Subject<boolean>();

  onBeforeUpload = (files: { [key: string]: UppyFile<{}, {}> }) => {
    if (this.filesAlreadyChecked) {
      return true;
    }
    this.checkFilesForUpload(files);
    return false;
  };

  cancelModal = () => {
    this.filesAlreadyChecked = false;
    this.cancelControlUpload.next(true);
    this.uppy.cancelAll();
    const dashboard = this.uppy.getPlugin('Dashboard') as Dashboard;
    if (dashboard.isModalOpen()) {
      dashboard.closeModal();
    }
    this.cancelProgressBar();
  };

  getPreInfo = (files: ChosenFile[]) => {
    this.callPreInfoWithFiles(files)
      .pipe(
        filter(result => result.length > 0),
        map(this.findAnyIfExistOrInvalidName),
      )
      .subscribe(result => {
        if (result.doesExist) {
          this.giveExistFileWarning();
        }

        if (result.hasInvalidName) {
          this.giveInvalidFileNameWarning(result.fileName);
        }
      });
  };

  constructor(
    private localization: LocalizationService,
    private fileService: FileDescriptorService,
    private confirmation: ConfirmationService,
    private updateStream: UpdateStreamService,
    private apiInterceptor: ApiInterceptor,
    private chunkUploaderService: ChunkUploaderService,
    private readonly _fileSizePipe: FileSizePipe,
    private _parallelFileUploadService: ParallelFileUploadService,
  ) {
    const sub = _parallelFileUploadService.UpdateFiles.subscribe(() => {
      updateStream.refreshContent();
    });

    const sub2 = _parallelFileUploadService.FolderCreated.subscribe(parentId => {
      this.updateStream.patchStore({ createdDirectory: parentId });
    });

    this.subscriptions.push(sub);
    this.subscriptions.push(sub2);
  }

  ngOnDestroy() {
    this.uppy.close();
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  initUppy(trigger, opts?: Partial<UppyOptions<any>>) {
    const headers = this.apiInterceptor.getAdditionalHeaders();
    this.subscriptions.push(
      this.localization
        .getResource$('FileManagement')
        .pipe(take(1))
        .subscribe(resource => {
          this.resource = resource;
          this.uppy = new Uppy({
            onBeforeUpload: this.onBeforeUpload,
          }).use(Dashboard, {
            trigger,
            inline: false,
            target: 'body',
            metaFields: [
              {
                id: 'name',
                name: resource['Name'],
                placeholder: resource['FileName'],
              },
            ],
            browserBackButtonClose: true,
            proudlyDisplayPoweredByUppy: false,
            onRequestCloseModal: this.cancelModal,
            locale: {
              strings: this.getTranslations(),
            },
          });

          if (opts) {
            this.setOptions(opts);
          }
        }),
    );

    this.subscriptions.push(
      fromEvent(this.uppy, 'file-added')
        .pipe(
          map(files => files[0]),
          bufferTime(500),
          filter(args => args.length > 0),
        )
        .subscribe(this.getPreInfo),
    );

    this.subscriptions.push(
      fromEvent(this.uppy, 'complete').subscribe(_ => {
        this.cancelModal();
        this.updateStream.refreshContent();
      }),
    );
    //return this.subscriptions;
    return this.completeSubject.asObservable();
  }

  setOptions(opts: Partial<UppyOptions<any>>) {
    this.uppy.setOptions(opts);
  }

  giveInvalidFileNameWarning(fileName: string) {
    this.uppy.info(
      this.resource['FileManagement:0002'].replace('{FileName}', fileName),
      'error',
      7000,
    );
  }

  giveExistFileWarning() {
    this.uppy.info(this.resource['FilesAlreadyExist'], 'warning', 7000);
  }

  private checkFilesForUpload(files) {
    this.cancelControlUpload.next(false);
    this.configureCancelButton();

    this.uploadStatus = [];

    this.callPreInfoWithFiles(Object.values(files)).subscribe(result => {
      const filesWithInvalidNames = result.filter(f => !f.hasValidName);
      if (filesWithInvalidNames.length) {
        const messageParam = filesWithInvalidNames.map(f => `'${f.fileName}'`).join(', ');
        this.confirmation.error('FileManagement::NotValidFileNames', '', {
          messageLocalizationParams: [messageParam],
          hideCancelBtn: true,
          yesText: 'AbpUi::Ok',
        });
      } else {
        const filesAlreadyExist = result.filter(f => f.doesExist);
        if (filesAlreadyExist.length) {
          const messageParam = filesAlreadyExist.map(f => `'${f.fileName}'`).join(', ');
          this.confirmation
            .warn('FileManagement::FilesWillBeOverrided', 'FileManagement::AreYouSure', {
              messageLocalizationParams: [messageParam],
            })
            .pipe(
              take(1),
              filter(status => status === Confirmation.Status.confirm),
            )
            .subscribe(_ => {
              this.currentProgress = 0;
              this.showProgressBar(1);

              this.filesAlreadyChecked = true;

              let fileList = Object.values(files);
              const directoryId = this.updateStream.currentDirectory || this.rootId;

              for (let file of fileList) {
                let fileStatus = new FileUploadStatusModel();
                fileStatus.fileName = (<any>file).name;
                this.uploadStatus.push(fileStatus);
                var uploadResult = this.chunkUploaderService.uploadFile(
                  (<any>file).data,
                  (<any>file)?.meta,
                  directoryId,
                  this.cancelControlUpload,
                );
                this.handleUploadResult(fileList.length, uploadResult);
              }
            });
        } else {
          this.currentProgress = 0;
          this.showProgressBar(1);

          this.filesAlreadyChecked = true;

          let fileList = Object.values(files);
          const directoryId = this.updateStream.currentDirectory || this.rootId;

          for (let file of fileList) {
            let fileStatus = new FileUploadStatusModel();
            fileStatus.fileName = (<any>file).name;
            this.uploadStatus.push(fileStatus);
            var uploadResult = this.chunkUploaderService.uploadFile(
              (<any>file).data,
              (<any>file)?.meta,
              directoryId,
              this.cancelControlUpload,
            );
            this.handleUploadResult(fileList.length, uploadResult);
          }
        }
      }
    });
  }

  private callPreInfoWithFiles(files) {
    const currentId = this.updateStream.currentDirectory || this.rootId;
    return this.fileService.getPreInfo(
      files.map(f => ({
        fileName: f.meta.name,
        size: f.size,
        folderId: currentId,
      })),
    );
  }

  private findAnyIfExistOrInvalidName(
    result: { doesExist: boolean; hasValidName: boolean; fileName: string }[],
  ) {
    return result.reduce(
      (cumul, curr) => ({
        doesExist: cumul.doesExist || curr.doesExist,
        hasInvalidName: cumul.hasInvalidName || !curr.hasValidName,
        fileName: curr.hasValidName ? cumul.fileName : curr.fileName,
      }),
      {
        doesExist: false,
        hasInvalidName: false,
        fileName: '',
      },
    );
  }

  private getTranslations() {
    return {
      closeModal: this.resource['CloseModal'],
      addMoreFiles: this.resource['AddMoreFiles'],
      addingMoreFiles: this.resource['AddingMoreFiles'],
      importFrom: this.resource['ImportFrom'],
      dashboardWindowTitle: this.resource['DashboardWindowTitle'],
      dashboardTitle: this.resource['DashboardTitle'],
      copyLinkToClipboardSuccess: this.resource['CopyLinkToClipboardSuccess'],
      copyLinkToClipboardFallback: this.resource['CopyLinkToClipboardFallback'],
      copyLink: this.resource['CopyLink'],
      fileSource: this.resource['FileSource'],
      done: this.resource['Done'],
      back: this.resource['Back'],
      removeFile: this.resource['RemoveFile'],
      editFile: this.resource['EditFile'],
      editing: this.resource['Editing'],
      edit: this.resource['Edit'],
      finishEditingFile: this.resource['FinishEditingFile'],
      saveChanges: this.resource['SaveChanges'],
      myDevice: this.resource['MyDevice'],
      dropPasteImport: this.resource['DropPasteImport'],
      dropPaste: this.resource['DropPaste'],
      dropHint: this.resource['DropHint'],
      browse: this.resource['Browse'],
      uploadComplete: this.resource['UploadComplete'],
      uploadPaused: this.resource['UploadPaused'],
      resumeUpload: this.resource['ResumeUpload'],
      pauseUpload: this.resource['PauseUpload'],
      retryUpload: this.resource['RetryUpload'],
      cancelUpload: this.resource['CancelUpload'],

      xFilesSelected: {
        0: this.resource['FileSelected'],
        1: this.resource['NFileSelected'],
      },
      uploadingXFiles: {
        0: this.resource['UploadingFile'],
        1: this.resource['NUploadingFile'],
      },
      processingXFiles: {
        0: this.resource['ProcessingFile'],
        1: this.resource['NProcessingFile'],
      },

      uploading: this.resource['Uploading'],
      complete: this.resource['Complete'],

      uploadFailed: this.resource['UploadFailed'],
      paused: this.resource['Paused'],
      retry: this.resource['Retry'],
      cancel: this.resource['Cancel'],

      filesUploadedOfTotal: {
        0: this.resource['FileUploadedOfTotal'],
        1: this.resource['NFileUploadedOfTotal'],
      },

      dataUploadedOfTotal: this.resource['DataUploadedOfTotal'],

      xTimeLeft: this.resource['XTimeLeft'],

      uploadXFiles: {
        0: this.resource['UploadFile'],
        1: this.resource['UploadXFiles'],
      },

      uploadXNewFiles: {
        0: this.resource['UploadNewFile'],
        1: this.resource['UploadXNewFile'],
      },
    };
  }

  private showProgressBar(status: number, totalFiles?: number, currentFile?: ChunkFileModel): void {
    this.currentProgress += status;
    let progressIndicators = document.getElementsByClassName('uppy-Dashboard-progressindicators');
    this.removeChildrenElements(progressIndicators[0], 'p');

    (<any>progressIndicators[0].firstChild).className = 'uppy-StatusBar is-uploading';
    (<any>progressIndicators[0].firstChild).firstChild.style = `width: ${Math.ceil(status)}%`;
    (<any>progressIndicators[0].firstChild).lastChild.style = `content-visibility: hidden;`;
    if (totalFiles && currentFile) {
      const chunksCompleted = currentFile.chunks?.filter(c => c.success) || [];
      const fileSize = currentFile.chunks?.reduce((a, b) => a + b.end - b.start, 0) || 0;
      const currentProgress = chunksCompleted.reduce((a, b) => a + b.end - b.start, 0) || 0;
      const p = document.createElement('p');
      p.className = 'ms-2';
      p.textContent = `Uploading ${currentFile.fileName}... ${this._fileSizePipe.transform(
        currentProgress,
      )} of ${this._fileSizePipe.transform(fileSize)} (${chunksCompleted.length}/${
        currentFile.totalChunks
      })`;
      progressIndicators[0].insertAdjacentElement('beforeend', p);
    }
  }

  private cancelProgressBar(): void {
    this.currentProgress = 0;
    let progressIndicators = document.getElementsByClassName('uppy-Dashboard-progressindicators');

    (<any>progressIndicators[0].firstChild).className = 'uppy-StatusBar is-waiting';
    (<any>progressIndicators[0].firstChild).firstChild.style = `width: ${0}%`;
    (<any>progressIndicators[0].firstChild).lastChild.style = `content-visibility: visible;`;
    this.removeChildrenElements(progressIndicators[0], 'p');
  }

  private removeChildrenElements(parentElement: Element, elementSelector: string): void {
    for (const pElement of Array.from(parentElement.querySelectorAll(elementSelector))) {
      pElement.remove();
    }
  }

  private configureCancelButton(): void {
    if (this.cancelButtonConfigured) return;

    let cancel = document.getElementsByClassName('uppy-DashboardContent-back');

    cancel[0].addEventListener('click', () => {
      this.cancelControlUpload.next(true);

      this.cancelProgressBar();

      this.filesAlreadyChecked = false;
    });

    this.cancelButtonConfigured = true;
  }

  private handleUploadResult(totalFiles: number, subject: Subject<UploadFileProgressModel>): void {
    subject.subscribe(
      progress => {
        if (progress == null) {
          this.completeSubject.next(false);
          return;
        }

        if (!progress.fileFinished) {
          this.showProgressBar(
            progress.currentProgress / totalFiles,
            totalFiles,
            progress.currentFileInfo,
          );
        }

        let file = this.uploadStatus.find(x => x.fileName == progress.fileFinished);

        if (!file) return;

        file.finished = true;

        let unfinished = this.uploadStatus.filter(x => !x.finished);

        if (!unfinished || unfinished.length == 0) {
          this.updateStream.refreshContent();
          this.filesAlreadyChecked = false;

          this.cancelModal();
        }
      },
      error => {
        console.error(error);
        this.completeSubject.next(false);
      },
    );
  }
}
/* tslint:enable:no-string-literal */
