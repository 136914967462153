<abp-page>
  <h1 *ngIf="!this.export">
    {{ 'missionsService::Portafolio' | abpLocalization }}
  </h1>
  <abp-page-toolbar-container class="col" *ngIf="!this.export">
    <div class="text-lg-end pt-2">
      <app-column-displayer
        [columnsStatus]="columnsStatus()"
        [toggler]="toggler"
        [showColumnPermission]="
          'missionsService.Portafolio.ShowColumn && General.Common.ShowColumn'
        "
      ></app-column-displayer>

      <button
        class="btn btn-primary btn-sm ms-2"
        type="button"
        [disabled]="isExportToExcelBusy"
        *abpPermission="'missionsService.Portafolio.Export && General.Common.Export'"
        (click)="exportToExcel()"
      >
        {{ 'missionsService::ExportToExcel' | abpLocalization }}
      </button>
      <button
        *abpPermission="'missionsService.Portafolio.Create && General.Common.Create'"
        class="btn btn-primary btn-sm ms-2"
        type="button"
        (click)="create()"
      >
        <i class="fa fa-plus me-1" aria-hidden="true"></i>
        {{ 'missionsService::NewPortafolios' | abpLocalization }}
      </button>
    </div>
  </abp-page-toolbar-container>

  <abp-advanced-entity-filters localizationSourceName="missionsService" [list]="list">
    <abp-advanced-entity-filters-form
      *abpPermission="'missionsService.Portafolio.Filters && General.Common.Filters'"
    >
      <form #filterForm (keyup.enter)="list.get()">
        <div class="row">
          <div
            class="col-12 col-sm-auto"
            *abpPermission="'missionsService.Portafolio.Filters.Name'"
          >
            <div class="mb-3">
              <label class="form-label" for="nameFilter">
                {{ 'missionsService::Name' | abpLocalization }}
              </label>

              <input
                id="nameFilter"
                class="form-control"
                [(ngModel)]="filters.name"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div
            class="col-12 col-sm-auto"
            *abpPermission="'missionsService.Portafolio.Filters.Customer'"
          >
            <div class="mb-3">
              <label class="form-label" for="customerIdFilter">
                {{ 'missionsService::CustomerId' | abpLocalization }}
              </label>

              <select
                class="form-control"
                id="customerIdFilter"
                [(ngModel)]="filters.customerId"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]=""></option>
                <option [ngValue]="customer.id" *ngFor="let customer of customers.items">
                  {{ customer.name | abpLocalization }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-sm-auto align-self-end mb-3">
            <div class="row">
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                  <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                </button>
              </div>
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-primary" (click)="list.get()">
                  <span>{{ 'Flyguys::ApplyFilter' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </abp-advanced-entity-filters-form>
  </abp-advanced-entity-filters>

  <div class="card">
    <div class="card-body">
      <ngx-datatable
        class="flg-grid-table"
        [rowHeight]="45"
        [headerHeight]="50"
        [footerHeight]="55"
        [rows]="data.items"
        [count]="data.totalCount"
        [list]="list"
        default
        [externalPaging]="true"
        [offset]="pageNumber"
        [limit]="clientMaxResultCount"
        (activate)="handleOnSelected($event)"
        class="flg-grid-table flg-grid-table--clickable"
        #dataTable
      >
        <ngx-datatable-column
          name="{{ 'missionsService::Name' | abpLocalization }}"
          prop="name"
          *ngIf="showedColumns.name"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.name }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Status' | abpLocalization }}"
          prop="status"
          [sortable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.portfolioStatus }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::CustomerId' | abpLocalization }}"
          prop="customerId"
          *ngIf="showedColumns.customer"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ customerDict[row.customerId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::TotalMissions' | abpLocalization }}"
          [sortable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.totalMissions }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'missionsService::Range' | abpLocalization }}"
          [sortable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.portfolioRange }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="'AbpUi::Actions' | abpLocalization"
          [maxWidth]="150"
          [width]="150"
          [sortable]="false"
          *ngIf="!this.export && showedColumns.actions"
        >
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <app-column-actions [columnActions]="columnActions(row)"></app-column-actions>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-curPage="curPage"
          >
            <app-table-paginator
              [(maxResultCount)]="list.maxResultCount"
              [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount"
              [pageSize]="pageSize"
              [(pageNumber)]="pageNumber"
              [curPage]="curPage"
              [dataTable]="dataTable"
              style="width: 100%"
            >
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</abp-page>

<abp-modal [busy]="isModalBusy" [options]="{ size: 'lg' }" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>
      {{ (selected ? 'AbpUi::Edit' : 'missionsService::NewPortafolios') | abpLocalization }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="form" id="portafolios" (ngSubmit)="submitForm()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="portafolios-name">
            {{ 'missionsService::Name' | abpLocalization }} *
          </label>

          <input
            id="portafolios-name"
            formControlName="name"
            class="form-control"
            maxlength="100"
            autofocus
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="portafolios-customer-id">
            {{ 'missionsService::CustomerId' | abpLocalization }} *
          </label>

          <select class="form-control" id="" portafolios-customer-id formControlName="customerId">
            <option [ngValue]="customer.id" *ngFor="let customer of customers.items">
              {{ customer.name | abpLocalization }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" for="portafolios-billing-customer">
            {{ 'missionsService::BillingCustomer' | abpLocalization }} *
          </label>

          <select
            class="form-control"
            id=""
            portafolios-customer-id
            formControlName="billingCustomer"
          >
            <option [ngValue]="customer.id" *ngFor="let customer of customers.items">
              {{ customer.name | abpLocalization }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" for="portafolios-state">
            {{ 'missionsService::State' | abpLocalization }} *
          </label>

          <select class="form-control" id="portafolios-state" formControlName="state">
            <option [ngValue]="state.value" *ngFor="let state of states">
              {{ state.key | abpLocalization }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="portafolios">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
