/**
 * Converts a string to AM/PM Time.
 *
 * @example formatTime('23:11') -> 11:11PM
 * @param time string
 * @param culture string (BCP 47 language tag)
 * @returns string
 */
export function formatTimeToAmPm(time: string, culture: string = 'en-US'): string {
  if (time) {
    return new Date(`1970-01-01T${time}Z`).toLocaleTimeString(culture, {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  return '';
}
