import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BulkUploadDetailsDto, BulkUploadsDto, GetBulkUploadDetailsInput } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { BulkUploadDetailsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { OrderBulkStates } from '../../columns/rules/lookup-rules';

@Component({
  selector: 'app-mission-bulk-upload-detail',
  templateUrl: './mission-bulk-upload-detail.component.html',
  styleUrls: ['./mission-bulk-upload-detail.component.scss'],
  providers: [ListService]
})
export class MissionBulkUploadDetailComponent implements OnInit {

  filters = { isPaginated: true } as GetBulkUploadDetailsInput;
  actualData: PagedResultDto<BulkUploadDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  orderBulkStates = OrderBulkStates;

  clientMaxResultCount = 10;
  pageNumber = 0;
  
  constructor(
    public dialogRef: MatDialogRef<MissionBulkUploadDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      bulkUpload: BulkUploadsDto;
      readonly: boolean;
      isEdition: boolean;
      fromMissionDetail?: boolean;
    },
    private readonly service:BulkUploadDetailsService,
    public readonly list: ListService,) {}
    
  onClickClose(): void {
    this.dialogRef.close(null);
  }
  
  ngOnInit(): void {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
        bulkUploadId: this.data.bulkUpload.id
      });

    const setData = (list: PagedResultDto<BulkUploadDetailsDto>) => (this.actualData = list);

    this.list.hookToQuery(getData).subscribe(setData);
  }
}
