<div class="vh-100 t-pt-50 t-pb-50" *ngIf="isSelfRegistrationEnabled">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="tagus-form ms-auto me-auto">
        <div class="title">
          <div class="d-flex mb-3 align-items-center">
            <h2 class="t-mb-0 t-me-30">Get Started</h2>
            <div>
              <img src="assets/images/flyguys-simple-logo-light.png" alt="logo-icon" />
            </div>
          </div>
          <p class="lh-base">
            {{ 'AbpAccount::AlreadyRegistered' | abpLocalization }}
            <a class="main-color fw-medium" routerLink="/account/login">{{
              'AbpAccount::Login' | abpLocalization
            }}</a>
          </p>
        </div>
        <!-- @TODO Possible localization entries needed for the placeholders -->
        <form [formGroup]="form" (ngSubmit)="onSubmit()" validateOnSubmit>
          <div class="bg-white border-radius p-3">
            <div class="tagus-form-group without-icon mb-2">
              <label class="d-block t-mb-12 fw-semibold gray-color">{{
                'AbpAccount::UserName' | abpLocalization
              }}</label>
              <mat-form-field appearance="fill">
                <mat-label>Pick an username</mat-label>
                <input matInput formControlName="username" autofocus skipValidation />
                <mat-error *ngIf="form.get('username').hasError('required')">
                  Please enter a valid username
                </mat-error>
              </mat-form-field>
            </div>
            <div class="tagus-form-group without-icon mb-2">
              <label class="d-block t-mb-12 fw-semibold gray-color">{{
                'AbpAccount::EmailAddress' | abpLocalization
              }}</label>
              <mat-form-field appearance="fill">
                <mat-label>Enter your email address</mat-label>
                <input matInput formControlName="email" skipValidation />
                <mat-error
                  *ngIf="
                    form.get('email').hasError('required') || form.get('email').hasError('email')
                  "
                >
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
            <div class="tagus-form-group without-icon">
              <label class="d-block t-mb-12 fw-semibold gray-color">{{
                'AbpAccount::Password' | abpLocalization
              }}</label>
              <mat-form-field appearance="fill">
                <mat-label>Choose your password</mat-label>
                <mat-error *ngIf="form.get('password').hasError('required')">
                  Please enter a valid password
                </mat-error>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  formControlName="password"
                  skipValidation
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="hide = !hide"
                  type="button"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <div #recaptcha></div>
          <button mat-flat-button class="tagus d-block fw-semibold" [disabled]="inProgress">
            {{ 'AbpAccount::Register' | abpLocalization }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
