<div class="d-flex justify-content-center align-items-center w-100 flex-wrap" style="height: 100vh;">
    <div class="text-center">
        <h1>{{'pilotsService::YouDoNotHavePermissionToAccessThisPortal' | abpLocalization}}</h1>
        <hr>
        <p class="mb-3">{{'pilotsService::YouWillBeRedirectedToLoginPageIn' | abpLocalization}} {{logoutTimeout}}
            {{'IdentityService::seconds' | abpLocalization}}</p>
        <div class="d-flex justify-content-center">
            <button class="btn btn-primary"
                (click)="logout()"
                style="margin: auto;">{{'pilotsService::ReturnNow' | abpLocalization}}</button>
        </div>
    </div>
</div>