import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { ePilotsServiceRouteNames } from '../enums/route-names';

export const RELATIONALS_REPORT_EXPORT_TYPES_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/pilots-service/report-export-type',
        parentName: ePilotsServiceRouteNames.PilotsService,
        name: 'pilotsService::Menu:ReportExportType',
        layout: eLayoutType.application,
        requiredPolicy: 'pilotsService.ReportExportType.ShowMenu',
        iconClass: 'fas fa-file-export',
      },
    ]);
  };
}
