import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eNotificationsServiceRouteNames } from '../enums/route-names';

export const BASICS_DEVICES_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/notifications-service/devices',
        parentName: eNotificationsServiceRouteNames.NotificationsService,
        name: 'Registered Devices',
        layout: eLayoutType.application,
        requiredPolicy: 'NotificationService.Devices.ShowMenu',
        iconClass: 'fas fa-desktop',
      },
    ]);
  };
}
