import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CustomerVerticalCreateDto, CustomerVerticalDto, CustomerVerticalExcelDownloadDto, CustomerVerticalUpdateDto, GetCustomerVerticalInput, GetIndustryInput, IndustriesCreateDto, IndustriesDto, IndustriesExcelDownloadDto, IndustriesUpdateDto } from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerVerticalService {
  apiName = 'CoreService';
  

  create = (input: CustomerVerticalCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerVerticalDto>({
      method: 'POST',
      url: '/api/core-service/customer-vertical',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/core-service/customer-vertical/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerVerticalDto>({
      method: 'GET',
      url: `/api/core-service/customer-vertical/${id}`,
    },
    { apiName: this.apiName,...config });

  getAll = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, Array<CustomerVerticalDto>>({
      method: 'GET',
      url: `/api/core-service/customer-vertical/all`,
    },
    { apiName: this.apiName,...config });

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/core-service/customer-vertical/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetCustomerVerticalInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerVerticalDto>>({
      method: 'GET',
      url: '/api/core-service/customer-vertical',
      params: { 
        filterText: input.filterText,
        description: input.description,
        state: input.state,
        code: input.code,
        isPaginated: input.isPaginated,
        sorting: input.sorting, 
        skipCount: input.skipCount,
        maxResultCount: input.maxResultCount 
      },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: CustomerVerticalExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/core-service/customer-vertical/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: CustomerVerticalUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerVerticalDto>({
      method: 'PUT',
      url: `/api/core-service/customer-vertical/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
