import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { OrderFormModel } from '../../model/order-form.model';
import { PagedResultDto } from '@abp/ng.core';
import {
  GetIndustryInput,
  IndustriesDto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { IndustriesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { enumState } from 'projects/pilots-service/src/lib/proxy/pilots-service/shared/enum-state.enum';
import {
  DeliverablesTableDto,
  GetPackageDetailsInput,
  PackageTableDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import { ProductDeliverablesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/relationals';
import { OrderFormPackageModel } from '../../model/order-form-package-model';
import { OrderFormDeliverableModel } from '../../model/order-form-deliverable.model';
import { OrderFormDetailsModel } from '../../model/order-form-detail.model';
import { CustomersDto } from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { getPendingControls } from '@flyguys/components';
import { DeliverableListComponent } from 'projects/flyguys/src/app/shared/deliverable-list/deliverables-list.component';

const requiredControlsNames = {
  industryid: 'Order Industry',
};

@Component({
  selector: 'app-third-step',
  templateUrl: './third-step.component.html',
  styleUrls: ['./third-step.component.scss'],
})
export class ThirdStepComponent implements OnInit, OnDestroy, DoCheck, OnChanges {
  // @TODO This should be its own form, not a generic one
  @Input() form: FormGroup;

  // @TODO This model is redundant as the previous form should handle
  // serialization
  @Input() model: OrderFormModel;
  @ViewChild('deliverableList') deliverableList: DeliverableListComponent;

  @Output() discardChange: EventEmitter<boolean> = new EventEmitter();

  private subscription = new Subscription();

  dataIndustries: PagedResultDto<IndustriesDto> = {
    items: [],
    totalCount: 0,
  };
  filterIndustries = {} as GetIndustryInput;

  filteredPackages: OrderFormPackageModel[] = [];
  currentCustomerId: string | null;

  constructor(
    public readonly industriesService: IndustriesService,
    public readonly customerService: CustomersService,
    public productDeliverablesService: ProductDeliverablesService
  ) {}

  ngDoCheck(): void {
    if (this.model.customerId !== this.currentCustomerId) {
      this.currentCustomerId = this.model.customerId;
      this.model.orderDetail = new OrderFormDetailsModel();
      if (this.deliverableList) {
        this.deliverableList.selected = [];
      }
      this.getIndustriesValues();
      this.loadCustomerData();
    }
  }

  ngOnInit() {
    if (
      !this.model.orderDetail?.packagesSelected?.length &&
      !this.model.orderDetail?.productsSelected?.length
    ) {
      this.model.orderDetail = new OrderFormDetailsModel();
    }

    this.getIndustriesValues();
    this.loadCustomerData();
    this.currentCustomerId = this.model.customerId;
  }

  ngOnChanges(changes: SimpleChanges) {
    // We will enter here when copying a mission
    if (changes.model && this.model.industryId) {
      this.currentCustomerId = this.model.customerId;

      // Calculate package subtotals and total when receiving copied order information
      this.calculatePackageSubtotals();
      // Handle copied mission products
      this.handleCopiedMissionProducts();
    }
  }

  handleCopiedMissionProducts() {
    // Load all products for the selected industry
    this.loadProductsByIndustry();

    // Select the products that were already selected in the copied mission
    this.selectCopiedProducts();

    this.model.buildDetails();
  }

  selectCopiedProducts() {
    const copiedProductIds = this.model.orderDetail.productsSelected.map(p => p.productId);

    this.model.productsbyIndustry.forEach(product => {
      if (copiedProductIds.includes(product.productId)) {
        const copiedProduct = this.model.orderDetail.productsSelected.find(
          p => p.productId === product.productId
        );
        product.selected = true;
        product.quantity = copiedProduct.quantity;
        product.subTotal = copiedProduct.subTotal;
        product.fields = copiedProduct.fields;
        product.detailedAttributes = copiedProduct.detailedAttributes;
      }
    });
  }

  loadCustomerData() {
    this.customerService.get(this.model.customerId).subscribe(
      (data: CustomersDto) => {
        if (data != null) {
          this.loadPackages();

          if (!data.industryId) return;

          this.model.industryId = data.industryId;
          this.model.industryDescription = data.industryName;
          this.loadProductsByIndustry();
        } else {
          console.error('cannot find customer for the contact provided');
        }
      },
      error => {
        console.error('Error on getting Customers : ' + JSON.stringify(error));
      }
    );
  }

  getIndustriesValues() {
    let request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
      state: enumState.Enabled,
    } as GetIndustryInput;
    const subsstates = this.industriesService.getList(request).subscribe(
      (data: PagedResultDto<IndustriesDto>) => {
        if (data?.totalCount != 0) {
          this.dataIndustries = data;
        }
      },
      error => {
        console.error('Error on getting Industries : ' + JSON.stringify(error));
      }
    );
    this.subscription.add(subsstates);
  }

  setDescriptionIndustry(id: string, items: any[], fieldtoSet: string, fieldName: string) {
    const item = items.find(x => x.id === id?.toString());
    this.model[fieldtoSet] = item?.[fieldName];
  }

  industrySelectionChange() {
    this.loadProductsByIndustry();
    this.filterPackagesByIndustry();

    if (this.model.orderDetail?.productsSelected?.length > 0) {
      this.model.orderDetail.productsSelected = [];
      this.model.buildDetails();
    }

    if (this.model.orderDetail && this.model.orderDetail.packagesSelected) {
      this.model.orderDetail.packagesSelected = this.model.orderDetail.packagesSelected.filter(
        x => !x.genericPackage
      );
      this.model.buildDetails();
    }
  }

  loadProductsByIndustry() {
    this.model.productsbyIndustry = [];
    let newInput = {} as GetPackageDetailsInput;
    newInput.industryId = this.model.industryId;

    const subsproducts = this.productDeliverablesService.getproductsByIndustry(newInput).subscribe(
      (data: DeliverablesTableDto[]) => {
        if (data?.length != 0) {
          for (let dl of data) {
            let deli: OrderFormDeliverableModel = {
              deliverableId: dl.deliverableId,
              deliverableName: dl.deliverableName,
              productId: dl.productId,
              productName: dl.productName,
              productDescription: dl.productDescription,
              packageId: dl.packageId,
              packageName: dl.packageName,
              standardPrice: dl.standardPrice,
              actualPrice: dl.actualPrice,
              quantity: 1,
              industryId: this.model.industryId,
              industryDescription: this.model.industryDescription,
              industryMultiplexor: dl.multiplier,
              fields: dl.fields.map(fd => ({
                id: fd.id,
                placeholder: fd.placeholder,
                numericValue: fd.numericValue,
                stringValue: fd.stringValue,
                boolValue: fd.boolValue,
                fileValue: fd.fileValue,
                defaultValue: fd.defaultValue,
                isRequired: fd.isRequired,
                listValues: fd.listValues,
                maxTextLength: fd.maxTextLength,
                typeDescription: fd.typeDescription,
                typeCode: fd.typeCode,
              })),
            };
            this.model.productsbyIndustry.push(deli);
          }
        }
      },
      error => {
        console.error('Error on getting products by Industry: ' + JSON.stringify(error));
      }
    );
    this.subscription.add(subsproducts);
  }

  getPackages(event: any) {
  }

  discard() {
    this.discardChange.emit(true);
  }

  isStepValid(): boolean {
    return (
      this.model.orderDetail.packagesSelected.length > 0 ||
      this.model.orderDetail.productsSelected.length > 0
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ModififyNames() {}

  private filterPackagesByIndustry(): void {
    this.filteredPackages = this.model.genericPackages.filter(
      x => !!x.industries?.find(t => t == this.model.industryId)
    );

    if (this.filteredPackages) {
      this.filteredPackages.forEach(pkg => {
        pkg.selected = false;
      });
    }
  }

  loadPackages() {
    this.model.packagesbyCustumer = [];
    let newInput = {} as GetPackageDetailsInput;
    newInput.customerId = this.model.customerId;
    const subsproducts = this.productDeliverablesService
      .getpackagesOrderRequest(newInput)
      .subscribe(
        (data: PackageTableDto[]) => {
          if (data?.length != 0) {
            for (let pk of data) {
              if (pk.deliverables?.length > 0) {
                this.model.packagesbyCustumer.push(this.buildPackageModel(pk));
              }
            }
          }
          this.loadGenericPackages();
        },
        error => {
          console.error('Error on getting packages Customer: ' + JSON.stringify(error));
        }
      );
    this.subscription.add(subsproducts);
  }

  loadGenericPackages() {
    this.model.genericPackages = [];
    let newInput = {} as GetPackageDetailsInput;
    newInput.customerId = null;
    const subsproducts = this.productDeliverablesService
      .getpackagesOrderRequest(newInput)
      .subscribe(
        (data: PackageTableDto[]) => {
          if (data?.length != 0) {
            for (let pk of data) {
              if (pk.deliverables?.length > 0) {
                this.model.genericPackages.push(this.buildPackageModel(pk));
              }
            }
            if (this.model.industryId) {
              this.filterPackagesByIndustry();
            }
          }
        },
        error => {
          console.error('Error on getting packages Products: ' + JSON.stringify(error));
        }
      );
    this.subscription.add(subsproducts);
  }

  buildPackageModel(pk: PackageTableDto) {
    let newpackage = new OrderFormPackageModel();
    newpackage.packageId = pk.packageId;
    newpackage.packageName = pk.packageName;
    newpackage.packagePrice = Math.abs(Number(pk.packagePrice.toFixed(2))).toString();
    newpackage.selected = false;
    newpackage.industries = pk.industries;
    for (let dl of pk.deliverables) {
      let deli: OrderFormDeliverableModel = {
        deliverableId: dl.deliverableId,
        deliverableName: dl.deliverableName,
        packageId: dl.packageId,
        packageName: dl.packageName,
        productId: dl.productId,
        productName: dl.productName,
        productDescription: dl.productDescription,
        industryId: null,
        industryDescription: null,
        industryMultiplexor: pk.packageMultiplier,
        standardPrice: dl.standardPrice,
        actualPrice: dl.actualPrice,
        quantity: dl.quantity,
        originalquantity: dl.quantity,
        subTotal: dl.subTotal,
        fields: dl.fields.map(fd => ({
          id: fd.id,
          placeholder: fd.placeholder,
          numericValue: fd.numericValue,
          stringValue: fd.stringValue,
          boolValue: fd.boolValue,
          fileValue: fd.fileValue,
          defaultValue: fd.defaultValue,
          isRequired: fd.isRequired,
          listValues: fd.listValues,
          maxTextLength: fd.maxTextLength,
          typeDescription: fd.typeDescription,
          typeCode: fd.typeCode,
        })),
      };
      newpackage.deliverables.push(deli);
    }
    return newpackage;
  }

  checkIndustryDescription() {
    if (!this.model.industryDescription) {
      this.setDescriptionIndustry(
        this.model.industryId,
        this.dataIndustries.items,
        'industryDescription',
        'name'
      );
    }
  }

  invalidPackageConfiguration(): boolean {
    let incompletePackages = !!this.model.orderDetail.packagesSelected.find(
      x => x.incompletePackage
    );

    let incompleteProducts = !!this.model.orderDetail.productsSelected.find(
      x => x.requiredConfigurationIncomplete
    );

    return incompletePackages || incompleteProducts;
  }

  calculatePackageSubtotals() {
    if (this.model.orderDetail && this.model.orderDetail.packagesSelected) {
      this.model.orderDetail.packagesSelected.forEach(packageselected => {
        packageselected.packageSubtotal =
          packageselected.quantity * Number(packageselected.packagePrice);
        packageselected.packageSubtotal = Math.abs(
          Number(packageselected.packageSubtotal.toFixed(2))
        );
      });
    }
  }

  /**
   * Searches for controls that are required and not filled on the form
   * @returns pendingControl[]
   */
  getPendingControls() {
    const packagesNeedConfiguration = !this.isStepValid() || this.invalidPackageConfiguration();
    const extraErrors = [];

    if (packagesNeedConfiguration) {
      extraErrors.push({
        name: 'Packages or Products need configuration',
        control: null,
      });
    }

    return [...getPendingControls(this.form, requiredControlsNames), ...extraErrors];
  }
}
