<div class="form-field-group">
  <div class="form-field-group-field">
    <div class="field-width">
      <div class="label-container">
        <div class="label-container-main">
          <mat-label class="label-container-title">{{ label }}:</mat-label>
          <mat-label class="label-container-value">{{ content }}</mat-label>
        </div>
      </div>
    </div>
  </div>
</div>
