<a href="javascript:void(0)" (click)="handleClick()" *ngIf="allowClick && !allowReproduce">
    <img [id]="id" [src]="imageUrl" [alt]="pictureTitle" [class]="customClass">
</a>
<img [id]="id" [src]="imageUrl" [alt]="pictureTitle" [class]="customClass" *ngIf="!allowClick">

<div class="video" *ngIf="allowClick && isThumbnail && allowReproduce" (click)="handleClick()"  [alt]="pictureTitle">
    <img [src]="imageUrl" [alt]="pictureTitle">
    <div class="play-button-outer">
      <div class="play-button"></div>
    </div>
</div>