<ngx-spinner
  bdColor="rgba(245,245,245,0.7)"
  size="default"
  color="#bc5a2e"
  type="ball-square-clockwise-spin"
  [fullScreen]="false"
  [zIndex]="111"
>
  <p style="color: #bc5a2e">Loading Orders...</p>
</ngx-spinner>

<mat-sidenav-container class="filters-container bg-white" [hasBackdrop]="false">
  <mat-sidenav #drawer mode="over" position="end" class="sidenav-border">
    <app-grid-filters
      *ngIf="filterConfig"
      [filterConfig]="filterConfig"
      (applyFilters)="onFiltersApplied($event)"
      (closeSidenav)="drawer.close()"
    >
    </app-grid-filters>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="d-flex">
      <h3>Missions</h3>
      <abp-page-toolbar-container class="col mt-3">
        <div class="text-lg-end">
          <button
            *ngIf="priority"
            class="btn btn-primary btn-sm"
            type="button"
            (click)="handleOnAddNewSite()"
          >
            <i class="fa fa-plus me-1" aria-hidden="true"></i>
            New Mission
          </button>
        </div>
      </abp-page-toolbar-container>
    </div>
    <div [class.sticky-header]="selectedActions && firstRow" class="white-background">
      <div
        class="d-flex align-items-center py-0 mb-3"
        *abpPermission="'missionsService.Mission.Filters && General.Common.Filters'"
      >
        <span class="fg-subtitle me-2"> I want to see Missions with: </span>

        <!-- Display applied filters -->
        <div class="d-flex align-items-center flex-wrap">
          <ng-container *ngFor="let condition of filterConfig.conditions; let isLast = last">
            <ng-container *ngIf="condition.existingValues.length">
              <app-filter-chip
                [label]="condition.columnDisplayName"
                [value]="getFilterDisplayValue(condition)"
                (removeEvent)="removeFilter(condition.column)"
              ></app-filter-chip>
              <span *ngIf="!isLast" class="comma-separator">, </span>
            </ng-container>
          </ng-container>
        </div>

        <span class="material-symbols-outlined me-2 cursor-pointer" (click)="drawer.toggle()">
          add_circle
        </span>

        <span
          class="d-inline-block main-color fw-medium cursor-pointer"
          (click)="removeAllFilters()"
          >Clear All</span
        >
      </div>
    </div>

    <div class="grid" *ngIf="data && data.items">
      <ngx-datatable
        [rows]="data.items"
        [headerHeight]="60"
        [footerHeight]="55"
        [rowHeight]="50"
        [offset]="pageNumber"
        [count]="data.totalCount"
        [list]="list"
        [limit]="clientMaxResultCount"
        [externalPaging]="true"
        [reorderable]="false"
        [swapColumns]="false"
        (page)="setPage($event)"
        (sort)="onSort($event)"
        [trackByProp]="'id'"
        default
        #dataTable
        class="flg-grid-table"
      >
        <ngx-datatable-column
          name="{{ 'missionsService::JobId' | abpLocalization }}"
          prop="jobId"
          [minWidth]="80"
          [width]="80"
          [resizeable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.jobId || '--' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Mission Name" prop="name" [resizeable]="true" [width]="200">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.name || '--' }}" [matTooltipShowDelay]="2000">{{
              row.name || '--'
            }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Status' | abpLocalization }}"
          prop="MissionStatus"
          [width]="230"
          [resizeable]="true"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span
              matTooltip="{{ missionStatus[row.missionStatus].value || '--' }}"
              [matTooltipShowDelay]="2000"
            >
              {{ missionStatus[row.missionStatus].value || '--' }}</span
            >
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::ProjectId' | abpLocalization }}"
          prop="projectId"
          [resizeable]="true"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.project || '--' }}" [matTooltipShowDelay]="2000">{{
              row.project || '--'
            }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Location" prop="location" [resizeable]="true" [width]="150">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.location }}" [matTooltipShowDelay]="2000">{{
              row.location
            }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Capture Date" prop="activeCaptureDate" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.fixedActiveCaptureDate || (row.activeCaptureDate | date: 'MM/dd/yyyy') }}
            {{ row.timeZone || '' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Capture Time" prop="activeCaptureTime" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.fixedActiveCaptureTime || row.activeCaptureTime }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::PilotId' | abpLocalization }}"
          prop="pilot"
          [resizeable]="true"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.pilotName || '--' }}" [matTooltipShowDelay]="2000">
              {{ row.pilotName || '--' }}</span
            >
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Deliverables Included"
          prop="deliverablesNames"
          [sortable]="false"
          [resizeable]="true"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span matTooltip="{{ row.deliverablesNames || '--' }}" [matTooltipShowDelay]="2000">
              {{ row.deliverablesNames || '--' }}</span
            >
          </ng-template>
        </ngx-datatable-column>

        <!-- Actions section -->
        <ngx-datatable-column
          name="Actions"
          [sortable]="false"
          [frozenRight]="true"
          [width]="220"
          [resizeable]="false"
          [headerClass]="'actions-column'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button
              class="btn btn-outline-primary btn-sm float-end d-flex max-35 p-mission-btn"
              (click)="handleOnOpenMission(row.id)"
            >
              <span class="material-symbols-outlined"> chevron_left </span>
              <span>Open Mission</span>
            </button>
          </ng-template>
        </ngx-datatable-column>
        <!-- End actions -->

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-curPage="curPage"
          >
            <app-table-paginator
              [(maxResultCount)]="list.maxResultCount"
              [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount"
              [pageSize]="pageSize"
              [(pageNumber)]="pageNumber"
              [curPage]="curPage"
              [dataTable]="dataTable"
              (clientMaxResultCountChange)="onPaginationChange($event)"
            >
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
