import { Component } from '@angular/core';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent {
  constructor(private localizationService: LocalizationService) {} 
}
