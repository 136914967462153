import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ConfigurationAttributeTypesCreateDto, ConfigurationAttributeTypesDto, ConfigurationAttributeTypesUpdateDto, GetConfigurationAttributeTypeInput,ConfigurationAttributeTypesExcelDownloadDto } from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { CacheBuster, Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { Subject } from 'rxjs';

const cacheBuster$ = new Subject<void>();
@Injectable({
  providedIn: 'root',
})
export class ConfigurationAttributeTypesService {
  apiName = 'CoreService';
  
  @CacheBuster({ cacheBusterNotifier: cacheBuster$, isInstant: true })
  create(input: ConfigurationAttributeTypesCreateDto, config?: Partial<Rest.Config>) {
    return this.restService.request<any, ConfigurationAttributeTypesDto>({
      method: 'POST',
      url: '/api/core-service/configuration-attribute-type',
      body: input,
    },
    { apiName: this.apiName,...config });
  }

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/core-service/configuration-attribute-type/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ConfigurationAttributeTypesDto>({
      method: 'GET',
      url: `/api/core-service/configuration-attribute-type/${id}`,
    },
    { apiName: this.apiName,...config });
  

  @Cacheable({ cacheBusterObserver: cacheBuster$, storageStrategy: LocalStorageStrategy })
  getList(input: GetConfigurationAttributeTypeInput, config?: Partial<Rest.Config>) {
    return this.restService.request<any, PagedResultDto<ConfigurationAttributeTypesDto>>({
      method: 'GET',
      url: '/api/core-service/configuration-attribute-type',
      params: { filterText: input.filterText, description: input.description, configurationTypeId: input.configurationTypeId, state: input.state, migrated: input.migrated, idOld: input.idOld, code: input.code, sorting: input.sorting, isPaginated: input.isPaginated, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  }
  

  @CacheBuster({ cacheBusterNotifier: cacheBuster$, isInstant: true })
  update(id: string, input: ConfigurationAttributeTypesUpdateDto, config?: Partial<Rest.Config>) {
    return this.restService.request<any, ConfigurationAttributeTypesDto>({
      method: 'PUT',
      url: `/api/core-service/configuration-attribute-type/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });
  }

    getListAsExcelFile = (input: ConfigurationAttributeTypesExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/core-service/configuration-attribute-type/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });

    getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/core-service/configuration-attribute-type/download-token',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
