import { FilterConfig } from "../../shared/grid-filters/models/filter-config.model";
import { FilterType } from "../../shared/grid-filters/models/filter-type.enum";

export const MissionTimelineFilterColumns: FilterConfig = {
  conditions: [
    {
      column: 'displayEventName',
      columnDisplayName: 'Event',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    }
  ],
};
