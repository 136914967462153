import { ABP, ConfigStateService, ListService, PagedResultDto, PermissionService, TrackByService, downloadBlob } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import type { GetOrderInput, GetPortafolioInput, OrdersDto, PortafoliosDto } from '../../../proxy/missions-service/basics/models';
import { OrdersService } from '../../../proxy/missions-service/controllers/basics/orders.service';
import { EnumStateOptions, enumState } from '../../../proxy/missions-service/shared';
import { GetIndustryInput, IndustriesDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { CustomersDto, GetCustomerInput } from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import { IndustriesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { PortafoliosService } from '../../../proxy/missions-service/controllers/basics/portafolios.service';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { ConfigurationAttributeTypesDto, ConfigurationTypesDto, GetConfigurationAttributeTypeInput, GetConfigurationTypeInput } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { ConfigurationAttributeTypesService, ConfigurationTypesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { ColumnAction } from 'projects/flyguys/src/app/components/columns/components/column-actions/column-actions.component';
import { ColumnStatus } from 'projects/flyguys/src/app/components/columns/components/column-displayer/column-displayer.component';
import { stateRules } from 'projects/flyguys/src/app/components/columns/rules/lookup-rules';
@Component({
  selector: 'lib-orders',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './orders.component.html',
  styles: [],
})
export class OrdersComponent implements OnInit {

  private readonly MAX_COUNT_ATTR = "MaxResultCount";
  
  data: PagedResultDto<OrdersDto> = {
    items: [],
    totalCount: 0,
  };

  industries: PagedResultDto<IndustriesDto> = {
    items: [],
    totalCount: 0,
  };

  portafolios: PagedResultDto<PortafoliosDto> = {
    items: [],
    totalCount: 0,
  };

  customers: PagedResultDto<CustomersDto> = {
    items: [],
    totalCount: 0,
  };

  filters = { isPaginated: true } as GetOrderInput;

  portafolioFilter = { state : enumState.Enabled, isPaginated: true} as GetPortafolioInput;

  form: FormGroup;

  isFiltersHidden = true;

  isModalBusy = false;

  isModalOpen = false;

  isExportToExcelBusy = false;

  selected?: OrdersDto;

  states = EnumStateOptions;

  portafolioDict: { [id: string] : string; } = {};

  customerDict: { [id: string] : string; } = {};

  MaxResultCount = 100;

  clientMaxResultCount = 10;
  pageNumber = 0;

  showedColumns = {
    actions: true,
    portfolio: true,
    requestingCustomer: true,
    billingCustomer: true,
    endCustomer: true,
    totalPrice: true,
    industry: true,
    state: true
  };

  stateRules = stateRules;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: OrdersService,
    public readonly industryService: IndustriesService,
    public readonly industriesList: ListService,
    public readonly portafolioService: PortafoliosService,
    public readonly portafolioList: ListService,
    public readonly customerService: CustomersService,
    public readonly customerList: ListService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationTypesList: ListService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly configurationAttributeTypesList: ListService,   
    private confirmation: ConfirmationService,
    private fb: FormBuilder,
    private permissionService: PermissionService,
    private stateService: ConfigStateService,
  ) {}

  ngOnInit() {
    
    this.getMaxCountAttribute();
    this.getOrderValues();
    this.getIndustryValues();
    this.getPortfolioValues();
    this.getCustomerValues();
  }

  clearFilters() {
    this.filters = { isPaginated: true } as GetOrderInput;
  }

  buildForm() {
    const {
      portfolioId,
      requestingCustomerId,
      billingCustomerId,
      endCustomerId,
      totalPrice,
      industryId,
      state,
    } = this.selected || {};

    this.form = this.fb.group({
      portfolioId: [portfolioId ?? null, [Validators.required]],
      requestingCustomerId: [requestingCustomerId ?? null, [Validators.required]],
      billingCustomerId: [billingCustomerId ?? null, [Validators.required]],
      endCustomerId: [endCustomerId ?? null, [Validators.required]],
      totalPrice: [totalPrice ?? null, [Validators.required]],
      industryId: [industryId ?? null, [Validators.required]],
      state: [state ?? '1', [Validators.required]],
    });
  }

  hideForm() {
    this.isModalOpen = false;
    this.form.reset();
  }

  showForm() {
    this.buildForm();
    this.isModalOpen = true;
  }

  submitForm() {
    if (this.form.invalid) return;

    const request = this.selected
      ? this.service.update(this.selected.id, this.form.value)
      : this.service.create(this.form.value);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm())
      )
      .subscribe(this.list.get);
  }

  create() {
    this.selected = undefined;
    this.showForm();
  }

  update(record: OrdersDto) {
    this.selected = record;
    this.showForm();
  }

  delete(record: OrdersDto) {
    this.confirmation
      .warn('missionsService::DeleteConfirmationMessage', 'missionsService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id))
      )
      .subscribe(this.list.get);
  }

  exportToExcel() {
    this.isExportToExcelBusy = true;
    this.service
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter })
        ),
        finalize(() => (this.isExportToExcelBusy = false))
      )
      .subscribe(result => {
        downloadBlob(result, 'Orders.xlsx');
      });
  }

  updatePortafolio(value) {
    this.portafolioFilter.name = value;
    this.getPortfolioValues();
  }

  private getOrderValues(): void {

    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });

    const setData = (list: PagedResultDto<OrdersDto>) => (this.data = list);

    this.list.hookToQuery(getData).subscribe(setData);
  }

  private getCustomerValues(): void {

    const query = { } as ABP.PageQueryParams;

    const customerFilter = { state : enumState.Enabled } as GetCustomerInput;

    this.customerService.getList({
        ...query,
        ...customerFilter,
        filterText: query.filter,
      })
      .subscribe(result => {
        this.customers = result;
        for(let dep of this.customers.items){
          this.customerDict[dep.id] = dep.name;
        } 
      });
  }

  private getIndustryValues(): void {

    const query = { } as ABP.PageQueryParams;

    const industryFilter = { state : enumState.Enabled } as GetIndustryInput;

    this.industryService.getList({
      ...query,
      ...industryFilter,
      filterText: query.filter,
    })
        .subscribe(result => {
          this.industries = result
        });

  }

  private getPortfolioValues(): void {

    const query = { } as ABP.PageQueryParams;

    this.portafolioFilter.maxResultCount = this.MaxResultCount;
    
    let showAssignedToAnyone = this.permissionService.getGrantedPolicy('missionsService.Portafolio.AssignedToAnyone');
    this.portafolioFilter.assignedToAnyone = false;

    if (showAssignedToAnyone)
    {
      this.portafolioFilter.assignedToAnyone = showAssignedToAnyone;
    }

    this.portafolioFilter.assignUserId = this.stateService.getDeep('currentUser.id');

    this.portafolioService.getList({
      ...query,
      ...this.portafolioFilter,
      filterText: query.filter,
    })
        .subscribe(result => {
          this.portafolios = result;
          for(let dep of this.portafolios.items){
            this.portafolioDict[dep.id] = dep.name;
          }
        });
  }

  private getMaxCountAttribute(): void {

    const query = { } as ABP.PageQueryParams;
    const configurationTypeFilter = { state : enumState.Enabled, code : this.MAX_COUNT_ATTR } as GetConfigurationTypeInput;

    this.configurationTypesService.getList({
      ...query,
      ...configurationTypeFilter,
      filterText: query.filter,
    })
        .pipe(
            switchMap(result => {

              let configuration = result.items.find( _ => true);
              let configurationAttributeTypeFilter = {configurationTypeId: configuration.id } as GetConfigurationAttributeTypeInput
              return this.configurationAttributeTypesService.getList({
                ...query,
                ...configurationAttributeTypeFilter,
                filterText: query.filter,
              })
            }))
        .subscribe((res) => {

          let attribute = res.items.find( _ => true );
          this.MaxResultCount = parseInt(attribute.description);
        });
  }

  columnActions(record: OrdersDto) {
    let columnActions:ColumnAction[] = [
      {
        actionIcon: 'bi bi-pencil',
        abpPermission: 'missionsService.Order.Edit && General.Common.Edit',
        action: {callAction: () => this.update(record)}
      },
      {
        actionIcon: 'bi bi-trash',
        abpPermission: 'missionsService.Order.Delete && General.Common.Delete',
        action: {callAction: () => this.delete(record)}
      },
    ];

    return columnActions;
  }

  toggler = (column:string, checked:boolean) => this.showedColumns[column] = checked;

  columnsStatus() {
    const columnsStatus:ColumnStatus[] = [
      {
        columnName: 'actions',
        softColumnName: 'Actions',
        defaultChecked: this.showedColumns.actions,
        abpPermission: 'missionsService.Order.ShowColumn.Actions && General.Common.ShowColumn.Actions'
      },
      {
        columnName: 'portfolio',
        softColumnName: 'Portfolio',
        defaultChecked: this.showedColumns.portfolio,
        abpPermission: 'missionsService.Order.ShowColumn.Portfolio'
      },
      {
        columnName: 'requestingCustomer',
        softColumnName: 'Requesting Customer',
        defaultChecked: this.showedColumns.requestingCustomer,
        abpPermission: 'missionsService.Order.ShowColumn.RequestingCustomer'
      },
      {
        columnName: 'billingCustomer',
        softColumnName: 'Billing Customer',
        defaultChecked: this.showedColumns.billingCustomer,
        abpPermission: 'missionsService.Order.ShowColumn.BillingCustomer'
      },
      {
        columnName: 'endCustomer',
        softColumnName: 'End Customer',
        defaultChecked: this.showedColumns.endCustomer,
        abpPermission: 'missionsService.Order.ShowColumn.EndCustomer'
      },
      {
        columnName: 'totalPrice',
        softColumnName: 'Total Price',
        defaultChecked: this.showedColumns.totalPrice,
        abpPermission: 'missionsService.Order.ShowColumn.TotalPrice'
      },
      {
        columnName: 'industry',
        softColumnName: 'Industry',
        defaultChecked: this.showedColumns.industry,
        abpPermission: 'missionsService.Order.ShowColumn.Industry'
      },
      {
        columnName: 'state',
        softColumnName: 'State',
        defaultChecked: this.showedColumns.state,
        abpPermission: 'missionsService.Order.ShowColumn.State && General.Common.ShowColumn.State'
      },
    ];

    return columnsStatus;
  }
}