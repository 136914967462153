import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttachmentsNotesFormModel } from '../models/attachments-notes-form-model';
import { FileDescriptorService, FileInfoDto } from '@volo/abp.ng.file-management/proxy';
import { LoadingOverlayService } from '../../../../services/loading/loading.service';
import { Subscription } from 'rxjs';
import { OrderFormSiteFileModel } from '../../../../components/orders/model/order-form-site-file.model';
import { OrderReferenceDocumentationDto } from '../../../models/order-reference-documentation-dto';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { downloadBlob } from '@abp/ng.core';
import { FileUploadComponent } from 'projects/flyguys/src/app/components/common/file-upload/file-upload.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageSucessComponent } from 'projects/flyguys/src/app/components/common/message/message.success.component';

@Component({
  selector: 'app-attachments-notes-modal',
  templateUrl: './attachments-notes-modal.component.html',
  styleUrls: ['./attachments-notes-modal.component.scss'],
})
export class AttachmentsNotesModalComponent implements OnInit, OnDestroy {
  formAttachmentsNotes: FormGroup;
  private subscriptions: Subscription[] = [];
  attachmentsSelected: OrderReferenceDocumentationDto[] = [];
  filesSelected: File[] = [];
  @ViewChild('fileUploadComponent') fileUploadComponent: FileUploadComponent;
  maxFileSizeFiles: number = 200 * 1024 * 1024;
  fileUploadInProgress: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AttachmentsNotesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AttachmentsNotesFormModel,
    public readonly fileDescriptorService: FileDescriptorService,
    public loadingService: LoadingOverlayService,
    private fb: FormBuilder,
    public dialogService: MatDialog,
  ) {}

  ngOnInit(): void {
    this.formAttachmentsNotes = this.fb.group({
      additionalNotes: [this.data.additionalNotes],
    });
    this.attachmentsSelected = [];
    this.data.filesAttachments.forEach((fileModel: OrderReferenceDocumentationDto) => {
      this.attachmentsSelected.push(fileModel);
    });
  }

  onClickClose(): void {
    this.markFilesAsSelected();
    const updatedAttachmentsNotes: AttachmentsNotesFormModel = this.formAttachmentsNotes.value;
    updatedAttachmentsNotes.missionId = this.data.missionId;
    updatedAttachmentsNotes.filesAttachments = this.data.filesAttachments;
    updatedAttachmentsNotes.saveData = false;
    this.dialogRef.close(updatedAttachmentsNotes);
  }

  saveAttachmentsNotes(): void {
    if (this.formAttachmentsNotes.valid) {
      const updatedAttachmentsNotes: AttachmentsNotesFormModel = this.formAttachmentsNotes.value;
      updatedAttachmentsNotes.missionId = this.data.missionId;
      updatedAttachmentsNotes.filesAttachments = this.data.filesAttachments;
      updatedAttachmentsNotes.saveData = true;
      this.dialogRef.close(updatedAttachmentsNotes);
    }
  }

  async onfilesSelected(files: File[] | null) {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > this.maxFileSizeFiles) {
        this.dialogService.open(MessageSucessComponent, {
          data: {
            title: 'Maximum File exceeded',
            message: `File ${files[i].name} exceeds the maximum allowed size (${this.formatFileSize(
              this.maxFileSizeFiles,
            )}).`,
          },
          disableClose: true,
          width: '475px',
        });
        const indexFile = files.indexOf(files[i]);
        if (indexFile >= 0) {
          files.splice(indexFile, 1);
        }
      }
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size <= this.maxFileSizeFiles) {
        formData.append('fileList', files[i]);
      }
    }

    if (formData.has('fileList')) {
      this.loadingService.showOverlay();
      this.fileUploadInProgress = true;
      const subFiles = this.fileDescriptorService
        .uploadAttachMentsFolder(
          'Order_Missions',
          'Mission_Files_' + this.data.missionId.toLowerCase(),
          formData,
        )
        .subscribe(
          (data: FileInfoDto[]) => {
            if (data?.length != 0) {
              for (let i = 0; i < data.length; i++) {
                if (data[i].fileAttachmentUrl.trim() != '') {
                  const fileExists = this.attachmentsSelected.some(
                    selectedFile =>
                      selectedFile.fileId === data[i].id && selectedFile.fileName === data[i].name,
                  );

                  if (!fileExists) {
                    const newFileModel: OrderReferenceDocumentationDto = {
                      fileId: data[i].id,
                      filePath: data[i].fileAttachmentUrl,
                      fileName: data[i].name,
                      selected: true,
                      uploaded: true,
                    };
                    this.attachmentsSelected.push(newFileModel);
                    this.data.filesAttachments.push(newFileModel);
                  }
                }
              }
              this.loadingService.hideOverlay();
              this.fileUploadInProgress = false;
            }
          },
          error => {
            this.loadingService.hideOverlay();
            this.fileUploadInProgress = false;
            console.log('Error on Upload Files: ' + JSON.stringify(error));
          },
        );
      this.subscriptions.push(subFiles);
    }
  }

  private formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }

  onDocumentRemoved(file: OrderReferenceDocumentationDto) {
    this.data.filesAttachments.forEach((fileModel: OrderReferenceDocumentationDto) => {
      if (fileModel.fileId == file.fileId) {
        if (!fileModel.uploaded) {
          fileModel.selected = false;
        } else {
          const indextoRemove = this.data.filesAttachments.indexOf(fileModel);
          if (indextoRemove >= 0) {
            this.data.filesAttachments.splice(indextoRemove, 1);
          }
        }
      }
    });

    const index = this.attachmentsSelected.indexOf(file);
    if (index >= 0) {
      this.attachmentsSelected.splice(index, 1);
    }
    const fileExists = this.filesSelected.find(selectedFile => selectedFile.name === file.fileName);
    this.fileUploadComponent.removeFile(fileExists);
  }

  downloadDocument(file: OrderReferenceDocumentationDto) {
    this.loadingService.showOverlay();

    this.fileDescriptorService
      .getDownloadToken(file.fileId)
      .pipe(
        switchMap(({ token }) => this.fileDescriptorService.downloadFile(file.fileId, token)),
        finalize(() => this.loadingService.hideOverlay()),
      )
      .subscribe(result => {
        downloadBlob(result, file.fileName);
      });
  }

  markFilesAsSelected() {
    this.data.filesAttachments.forEach((fileModel: OrderReferenceDocumentationDto) => {
      fileModel.selected = true;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
