<h5 mat-dialog-title class="flg-modal flg-modal__title">
  {{ data.title || 'Add Packages/Products' }}
</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container">
    <div class="row justify-content-center">
      <form [formGroup]="formPackagesProducts">
        <div class="deliverables-subtitle">
          Select the package that best suits your needs, then if you wish, you can include some
          add-ons
        </div>
        <div *ngIf="orderModel.packagesbyCustumer.length > 0">
          <h6 class="mt-3 mb-3">
            <span class="deliverables-filtertitle">Frequently Requested Packages</span>
          </h6>

          <mat-divider class="mt-2 mb-3"></mat-divider>

          <div class="d-flex w-100 mt-3 flex-wrap">
            <div *ngFor="let pk of orderModel.packagesbyCustumer; let i = index">
              <div class="w-100 mt-2 mb-4 pe-2 mt-3">
                <app-package [package]="pk" [model]="orderModel"></app-package>
              </div>
              <ng-container
                *ngIf="(i + 1) % 4 === 0 || i + 1 === orderModel.packagesbyCustumer.length"
              >
                <div class="w-100"></div>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="this.orderModel.genericPackages.length > 0">
          <h6 class="mt-3">
            <span class="deliverables-filtertitle">Generic Packages</span>
          </h6>

          <mat-divider></mat-divider>

          <div class="d-flex w-100 flex-wrap">
            <div
              class="mt-2 mb-4 pe-2 mt-3"
              *ngFor="let gpk of this.orderModel.genericPackages; let i = index"
            >
              <app-package [package]="gpk" [model]="this.orderModel"></app-package>
            </div>
          </div>
        </div>

        <ng-container *ngIf="orderModel.productsbyIndustry.length > 0">
          <h6 class="mt-2">
            <span class="deliverables-filtertitle">Add Products</span>
          </h6>

          <mat-divider class="mt-2 mb-3"></mat-divider>

          <app-deliverable-list
            *ngIf="orderModel.productsbyIndustry.length > 0"
            [model]="orderModel"
          ></app-deliverable-list>
        </ng-container>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end mt-3">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="savePackagesProducts()"
      [disabled]="!isStepValid() || invalidPackageConfiguration()"
    >
      Add
    </button>
  </div>
</mat-dialog-actions>
