<div class="d-flex justify-content-between mb-2">
  <h3>{{ 'pilotsService::Notes' | abpLocalization }}</h3>
  <button
    *abpPermission="'pilotsService.PilotNote.Create && General.Common.Create'"
    mat-flat-button
    class="common-button"
    type="button"
    (click)="create()"
  >
    <mat-icon aria-hidden="false" fontIcon="add"></mat-icon>
    {{ 'pilotsService::AddNotes' | abpLocalization }}
  </button>
</div>
<abp-advanced-entity-filters localizationSourceName="pilotsService" [list]="list">
  <abp-advanced-entity-filters-form>
    <form #filterForm (keyup.enter)="list.get()">
      <label class="form-label" for="categoryIdFilter">
        {{ 'pilotsService::Category' | abpLocalization }}
      </label>
      <select
        class="form-control"
        id="categoryIdFilter"
        [(ngModel)]="filters.categoryId"
        [ngModelOptions]="{ standalone: true }"
        (change)="list.get()"
      >
        <option [ngValue]=""></option>
        <option [ngValue]="category.id" *ngFor="let category of categories.items">
          {{ category.description | abpLocalization }}
        </option>
      </select>
    </form>
  </abp-advanced-entity-filters-form>
</abp-advanced-entity-filters>

<div class="card-note" *ngFor="let note of notes.items">
  <p class="subject">{{ note.subject }}</p>
  <p class="date">
    {{ note.creationTime | date : 'shortTime' }} | {{ note.creationTime | date : 'shortDate' }}
  </p>
  <p class="category">{{ note.descriptionCategory }}</p>

  <p class="body">{{ note.body }}</p>
  <p class="submited-attention">
    {{ 'pilotsService::SubmittedBy' | abpLocalization }}: {{ userDict[note.creatorId] }}
  </p>
  <p class="submited-attention">
    {{ 'pilotsService::AttentionTo' | abpLocalization }}: {{ note.descriptionPilot }}
  </p>
</div>

<ng-template #modal>
  <flg-generic-modal>
    <ng-container modalTitle>
      {{ 'pilotsService::NewNote' | abpLocalization }}
    </ng-container>

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form [formGroup]="form" id="notes" skipValidation>
          <mat-form-field class="w-100">
            <mat-label for="notes-subject">
              {{ 'pilotsService::Subject' | abpLocalization }}
            </mat-label>

            <input id="notes-subject" formControlName="subject" maxlength="100" matInput />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label for="notes-category-id">
              {{ 'pilotsService::Category' | abpLocalization }}
            </mat-label>

            <mat-select id="notes-category-id" formControlName="categoryId">
              <mat-option [value]="category.id" *ngFor="let category of categories.items">
                {{ category.description | abpLocalization }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label for="notes-body">
              {{ 'pilotsService::Body' | abpLocalization }}
            </mat-label>

            <textarea id="notes-body" formControlName="body" rows="5" matInput></textarea>
          </mat-form-field>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        class="outline-button me-2"
        type="button"
        (click)="hideForm()"
        [disabled]="isModalBusy"
      >
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        (click)="submitForm()"
        [disabled]="form.invalid || isModalBusy"
      >
        {{ 'AbpUi::Save' | abpLocalization }}
      </button>
    </div>
  </flg-generic-modal>
</ng-template>
