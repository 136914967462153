import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { TeamOrdersCreateDto, TeamOrdersDto, TeamOrdersExcelDownloadDto, TeamOrdersUpdateDto, GetTeamOrderInput } from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class TeamOrdersService {
  apiName = 'missionsService';
  

  create = (input: TeamOrdersCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TeamOrdersDto>({
      method: 'POST',
      url: '/api/missions-service/teamorder',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/teamorder/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TeamOrdersDto>({
      method: 'GET',
      url: `/api/missions-service/teamorder/${id}`,
    },
    { apiName: this.apiName,...config });
  
getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/teamorder/download-token',
    },
    { apiName: this.apiName,...config });

getListAsExcelFile = (input: TeamOrdersExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/teamorder/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, roleId: input.roleId,order: input.order, roleDescription: input.roleDescription, state: input.state },
    },
    { apiName: this.apiName,...config });


  getList = (input: GetTeamOrderInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<TeamOrdersDto>>({
      method: 'GET',
      url: '/api/missions-service/teamorder',
      params: { filterText: input.filterText, roleId: input.roleId, order: input.order, roleDescription: input.roleDescription, state: input.state, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: TeamOrdersUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TeamOrdersDto>({
      method: 'PUT',
      url: `/api/missions-service/teamorder/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
