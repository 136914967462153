<h5 *ngIf="!data.isBulk" mat-dialog-title class="flg-modal flg-modal__title">Mission Name: {{ missionName }}</h5>
<h5 *ngIf="data.isBulk" mat-dialog-title class="flg-modal flg-modal__title">Bulk Actions: Assign Pilot</h5>
<mat-dialog-content class="flg-modal">
    <div class="row">
        <div *ngIf="!data.isBulk" class="column col-sm-4">
          <h3  class="referenceInformation">Reference Information</h3>
          <div class="label-container">
            <div class="label-container-main">
              <label class="label-container-title">{{ captureDateLabel }}</label>
            </div>
            <div class="label-container-value" *ngIf="captureDate">
              {{ captureDate === "" ? '-' : captureDate }}
            </div>
          </div>
      
          <div class="label-container">
            <div class="label-container-main">
              <label class="label-container-title">{{ captureTimeLabel }}</label>
            </div>
            <div class="label-container-value" *ngIf="captureTime">
              {{ captureTime === "" ? '-' : captureTime }}
            </div>
          </div>
      
          <div class="label-container">
            <div class="label-container-main">
              <label class="label-container-title">{{ customerNameLabel }}</label>
            </div>
            <div class="label-container-value" *ngIf="customerName">
              {{ customerName === "" ? '-' : customerName }}
            </div>
          </div>
      
          <div class="label-container">
            <div class="label-container-main">
              <label class="label-container-title">{{ pilotLabel }}</label>
            </div>
            <div class="label-container-value" *ngIf="pilot">
              {{ pilot === '' ? '-' : pilot }}
            </div>
          </div>
      
          <div class="label-container">
            <div class="label-container-main">
              <label class="label-container-title">{{ locationLabel }}</label>
            </div>
            <div class="label-container-value" *ngIf="location">
              {{ location === '' ? '-' : location }}
            </div>
          </div>
        </div>

        <div *ngIf="data.isBulk" class="column col-sm-4">
          <h3  class="referenceInformation">Reference Information</h3>
          <div class="label-container">
            <div class="label-container-main">
              <label class="label-container-title">Total Missions to Modify:</label>
            </div>
            <div class="label-container-value">
              {{ data.missionsInfo.length }}
            </div>
          </div>
        </div>

        <div class="column col-sm-8">
          <form [formGroup]="AssignPilotForm">
            <mat-form-field class="w-100 list-pilot-field">
              <mat-label>Pilot</mat-label>
              <input
                class="info-input"
                type="text"
                matInput
                formControlName="pilotName"
                [matAutocomplete]="autoPilot"
               
              />
              <mat-autocomplete
                #autoPilot="matAutocomplete"
                (optionSelected)="selectPilot($event)"
                [autoActiveFirstOption]="false"
                [displayWith]="displayPilotName"
              >
                <mat-option class="search-opt" *ngFor="let option of filterDataBySearch" [value]="option">
                  <div class="options-container">
                    <span class="option-name">{{ option.name }}</span>
                    <div class="option-details">
                      <div class="option-item option-item-email">{{ option.email }}</div>
                      <div class="option-item">{{ option.phone }}</div>
                      <div class="option-item">{{ option.company ?? "" }}</div>
                    </div>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
    
            <mat-form-field class="w-100">
              <mat-label>Payment</mat-label>
              <input
                matInput
                [errorStateMatcher]="matcher"
                placeholder="Payment"
                formControlName="payment"
                type="number"
                min="0"
                class="info-input"
              />
              <mat-error *ngIf="AssignPilotForm.get('payment')?.hasError('invalidName')">
                {{ 'FileManagement::FileNotValidExtensionMessage' | abpLocalization }}
              </mat-error>
            </mat-form-field>
    
            <div *ngIf="displayPilotDetails" class="pilot-details">
              <h3 *ngIf="selectedPilot?.value" class="titleInformation">Contact information for the selected pilot</h3>
              <div *ngIf="selectedPilot?.value" class="pilot-details-container">
                <span class="title">{{ selectedPilot?.name }}</span>
                <div class="items">
                  <span><span class="material-symbols-outlined">mail</span><label>{{ selectedPilot?.email }}</label></span>
                  <span><span class="material-symbols-outlined">call</span><label>{{ selectedPilot?.phone }}</label></span>             
                </div>
              </div>
            </div>
            <div *ngIf="!displayPilotDetails" class="pilot-details"></div>
            <div class="d-flex justify-content-end">
              <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">Cancel</button>
              <button mat-flat-button color="primary" class="common-button" type="submit" [disabled]="!IsValid()" [class.disabled-button]="!IsValid()" (click)="onClickSave()">
                {{ 'missionsService::Save' | abpLocalization }}
              </button>
            </div>
          </form>
        </div>
    </div>

</mat-dialog-content>