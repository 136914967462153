import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eCoreServiceRouteNames } from '../enums/route-names';

export const LOOKUPS_INDUSTRIES_TYPE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/core-service/industries-types',
        parentName: eCoreServiceRouteNames.CoreService,
        name: 'CoreService::Menu:IndustriesTypes',
        layout: eLayoutType.application,
        requiredPolicy: 'CoreService.IndustriesTypes.ShowMenu',
        iconClass: 'fas fa-vector-square'
      },
    ]);
  };
}
