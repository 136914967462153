<div class="flg-grid-table-actions flg-grid-table-actions--plain">
  <div class="secondary-actions secondary-actions--alone">
    <ng-container *ngFor="let columnAction of columnActions; trackBy: track">
      <div *ngIf="columnAction.visible !== undefined ? columnAction.visible : true">
        <button
          *abpPermission="columnAction.abpPermission"
          (click)="columnAction.action.callAction()"
          class="btn single-action"
        >
          <i [ngClass]="columnAction.actionIcon"></i>
        </button>
      </div>
    </ng-container>
  </div>
</div>
