import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NotesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/notes.service';
import { ConfigStateService, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { MissionNoteDataDto } from '../../models/mission-note-data-dto';
import { FilterConfig } from '../../../shared/grid-filters/models/filter-config.model';
import { MissionNoteFilterColumns } from '../../models/mission-note-filter-columns';
import { MissionsNoteFilters } from '../../models/missions-note-filters';
import {
  CategoriesService,
  DepartamentsService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { Subscription, combineLatest, filter, switchMap } from 'rxjs';
import {
  CategoriesDto,
  DepartamentsDto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { FilterCondition } from '../../../shared/grid-filters/models/filter-condition.model';
import { FilterType } from '../../../shared/grid-filters/models/filter-type.enum';
import { UserNoteDto } from '../../models/user-note-dto';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { enumWebBackgroundNotificationSubKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-subkey.enum';

@Component({
  selector: 'app-mission-notes',
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy {
  public readonly AVG_ITEM_SIZE = 170;
  private readonly MAX_NUMBER_USERS = 1000;
  private readonly TRIGGER_MORE_ITEM_NUMBER = 10;

  @Input()
  public missionId: string;

  data: PagedResultDto<MissionNoteDataDto> = {
    items: [],
    totalCount: 0,
  };

  @Output()
  noteDeleted = new EventEmitter();

  // Filters Section
  noteFilters: MissionsNoteFilters = { pageSize: 15, skipCount: 0 };
  filterConfig: FilterConfig = MissionNoteFilterColumns;

  categories: Array<CategoriesDto>;
  departments: Array<DepartamentsDto>;
  users: Array<UserNoteDto>;
  disableLoadMore: boolean;
  currentUserId: string;
  usersForNote: any;
  loadingMore: boolean;
  lastScollMovement: number;

  people: Array<number> = [];

  subscription = new Subscription();
  refreshingNotes = false;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    private service: NotesService,
    private categoryService: CategoriesService,
    private departamentService: DepartamentsService,
    private stateService: ConfigStateService,
    private usersService: IdentityUserService,
    private confirmation: ConfirmationService,
    private notificationBroadcastService: NotificationBroadcastService
  ) {}

  ngOnInit() {
    this.currentUserId = this.stateService.getDeep('currentUser.id');

    this.loadFilterInformation();

    this.loadUserListForNotes();

    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionStatus) {
          if (
            notif.itemId &&
            notif.extraArgument.missionStatus &&
            notif.extraArgument.missionStatusCode &&
            this.missionId == notif.itemId
          ) {
            this.callApiWithFilters();
          }
        }

        if (
          notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalNoteAddedToMission
        ) {
          if (
            notif.itemId &&
            notif.notificationSubKey == enumWebBackgroundNotificationSubKey.EventForNoteList &&
            this.missionId == notif.itemId
          ) {
            this.callApiWithFilters();
            this.refreshNoteList();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  refreshList() {
    this.refreshingNotes = true;
    this.noteFilters.skipCount = 0;
    this.lastScollMovement = 0;
    this.service.filter(this.missionId, this.noteFilters).subscribe(res => {
      if (!res.items) return;

      this.fillNoteProperties(res.items);

      res.items = res.items.map(r => {
        r.body = r.body.replace(/\n/g,'<br/>');

        return r;
      });

      this.data = res;

      this.refreshingNotes = false;
    });
  }

  getMoreNotes() {
    this.noteFilters.skipCount = this.noteFilters.skipCount + 1;

    this.loadingMore = true;

    this.service.filter(this.missionId, this.noteFilters).subscribe(res => {
      if (!res.items) return;

      this.fillNoteProperties(res.items);

      this.data.items = this.data.items.concat(res.items);

      this.data.items = this.data.items.map(r => {
        r.body = r.body.replace(/\n/g,'<br/>');

        return r;
      });

      this.disableLoadMore = this.data.totalCount <= this.data.items.length + 1;

      this.loadingMore = false;
    });
  }

  scrollHandler(event) {
    let scrollingDown = event > this.lastScollMovement;

    if (!scrollingDown) {
      this.lastScollMovement = event;
      return;
    }

    if (event == this.TRIGGER_MORE_ITEM_NUMBER * (this.noteFilters.skipCount + 1))
      this.getMoreNotes();
  }

  getFilterDisplayValue(condition: FilterCondition): string {
    return condition.existingValues.map(value => this.getDescription(condition, value)).join(', ');
  }

  getDescription(condition: FilterCondition, value: string): string {
    if (condition.type === FilterType.Dropdown) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    }

    return value;
  }

  onFiltersApplied(updatedFilters: FilterConfig) {
    updatedFilters.conditions.forEach(updatedCondition => {
      this.noteFilters[updatedCondition.column] = updatedCondition.existingValues;
    });

    this.callApiWithFilters();
  }

  removeAllFilters() {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => ({
        ...condition,
        existingValues: [],
      })),
    };

    for (let condition of this.filterConfig.conditions) {
      this.noteFilters[condition.column] = [];
    }

    this.noteFilters.filterText = '';

    this.callApiWithFilters();
  }

  removeFilter(column: string) {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => {
        if (condition.column === column) {
          return { ...condition, existingValues: [] };
        }
        return condition;
      }),
    };

    // Clear the specific filter
    this.noteFilters[column] = [];

    this.callApiWithFilters();
  }

  callApiWithFilters(resetPage: boolean = true) {
    if (resetPage) {
      this.lastScollMovement = 0;
    }
    this.noteFilters.skipCount = 0;

    this.service.filter(this.missionId, this.noteFilters).subscribe(res => {
      if (res?.items)
        res.items = res.items.map(r => {
          r.body = r.body.replace(/\n/g,'<br/>');

          return r;
        });
      this.data = res;

      this.fillNoteProperties(this.data.items);
    });
  }

  public handleOnRemove(idNote: string, index: number): void {
    this.confirmation
      .warn(
        'missionsService::DeleteNoteConfirmationMessage',
        'missionsService::DeleteNoteConfirmationTitle',
        {
          messageLocalizationParams: [],
        }
      )
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.removeFromMission(this.missionId, idNote))
      )
      .subscribe(() => {
        this.data.items.splice(index, 1);
        this.refreshList();
        this.noteDeleted.emit();
      });
  }

  private refreshNoteList() {
    this.refreshingNotes = true;

    setTimeout(() => {
      this.refreshingNotes = false;
    }, 1000);
  }

  private loadUserListForNotes(): void {
    this.usersService.getList({ maxResultCount: this.MAX_NUMBER_USERS }).subscribe(r => {
      this.usersForNote = r.items?.map(q => {
        return { id: q.id, value: `${q.name || ''} ${q.surname || ''}` };
      });
    });
  }

  private updateCategoryFilter(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'categoryId');
    if (condition) {
      condition.options = this.categories.map(item => ({
        id: item.id,
        description: item.description,
      }));
    }
  }

  private updateDepartmentFilter(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'atentionTo');
    if (condition) {
      condition.options = this.departments.map(item => ({
        id: item.id,
        description: item.description,
      }));
    }
  }

  private updateSubmittedByFilter(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'submittedBy');
    if (condition) {
      condition.options = this.users.map(item => ({ id: item.id, description: item.name }));
    }
  }

  private loadFilterInformation(): void {
    combineLatest([
      this.categoryService.getAll(),
      this.departamentService.getAll(),
      this.service.getUsersByMission(this.missionId),
      this.service.filter(this.missionId, this.noteFilters),
    ]).subscribe({
      next: ([categories, departaments, users, notes]) => {
        this.categories = categories;
        this.departments = departaments;
        this.users = users;

        this.fillNoteProperties(notes.items);

        this.updateCategoryFilter();

        this.updateDepartmentFilter();

        this.updateSubmittedByFilter();

        notes.items = notes.items.map(r => {
          r.body = r.body.replace(/\n/g,'<br/>');

          return r;
        });

        this.data = notes;
        this.disableLoadMore = this.data.totalCount <= this.data.items.length + 1;
      },
      error: err => {
        console.error(`Mission List, error while fetching data: ${err}`);
      },
    });
  }

  private fillNoteProperties(notes: Array<MissionNoteDataDto>): void {
    notes.forEach(note => {
      let category = this.categories.find(x => x.id == note.categoryId);

      let department = this.departments.find(x => x.id == note.departamentId);

      if (note.creatorId) {
        let creator = this.users.find(x => x.id == note.creatorId);
        note.creatorName = creator?.name || '-';
      }

      note.departamentDescription = department?.description || '-';
      note.categoryDescription = category?.description || '-';
    });
  }
}
