<h5 mat-dialog-title class="flg-modal flg-modal__title">Validation Results</h5>
<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container">
    <div class="row justify-content-center">
      <strong class="mb-2">
        Overall Status:
        <span class="w-600">{{ getStatusString(getOverallStatus()) }}</span>
      </strong>

      <ng-container *ngIf="data.isSingleFile">
        <div class="results-table">
          <div class="table-header">
            <div class="header-cell">File Name</div>
            <div class="header-cell">Tolerance Code</div>
            <div class="header-cell">Status</div>
            <div class="header-cell">Expected</div>
            <div class="header-cell">Actual</div>
            <div class="header-cell">Tolerance</div>
          </div>
          <ng-container *ngFor="let file of filteredResults">
            <ng-container *ngFor="let result of file.validationResults">
              <div class="table-row">
                <div class="cell">{{ file.fileName || '' }}</div>
                <div class="cell">{{ result.toleranceCode }}</div>
                <div class="cell">{{ getStatusString(result.result) }}</div>
                <div class="cell">{{ result.fieldValue }}</div>
                <div class="cell">{{ result.fileValue || 'N/A' }}</div>
                <div class="cell">{{ getToleranceDisplay(result) }}</div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!data.isSingleFile">
        <mat-label class="validations-modal-subtitle mt-2 mb-2">Deliverable Results</mat-label>
        <div class="results-table">
          <div class="table-header">
            <div class="header-cell">Tolerance Code</div>
            <div class="header-cell">Status</div>
            <div class="header-cell">Expected</div>
            <div class="header-cell">Actual</div>
            <div class="header-cell">Tolerance</div>
          </div>
          <ng-container *ngFor="let result of deliverableResults">
            <div class="table-row">
              <div class="cell">{{ result.toleranceCode }}</div>
              <div class="cell">{{ getStatusString(result.result) }}</div>
              <div class="cell">{{ result.fieldValue }}</div>
              <div class="cell">{{ result.fileValue || 'N/A' }}</div>
              <div class="cell">{{ getToleranceDisplay(result) }}</div>
            </div>
          </ng-container>
        </div>

        <mat-label class="validations-modal-subtitle mt-3 mb-2">File Results</mat-label>
        <div class="results-table">
          <div class="table-header">
            <div class="header-cell">File Name</div>
            <div class="header-cell">Tolerance Code</div>
            <div class="header-cell">Status</div>
            <div class="header-cell">Expected</div>
            <div class="header-cell">Actual</div>
            <div class="header-cell">Tolerance</div>
          </div>
          <ng-container *ngFor="let file of fileResults">
            <ng-container *ngFor="let result of file.validationResults">
              <div class="table-row">
                <div class="cell">{{ file.fileName || '' }}</div>
                <div class="cell">{{ result.toleranceCode }}</div>
                <div class="cell">{{ getStatusString(result.result) }}</div>
                <div class="cell">{{ result.fieldValue }}</div>
                <div class="cell">{{ result.fileValue || 'N/A' }}</div>
                <div class="cell">{{ getToleranceDisplay(result) }}</div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end mt-3">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClose()">
      Close
    </button>
  </div>
</mat-dialog-actions>
