import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BulkUploadsCreateDto, BulkUploadsDto, BulkUploadsExcelDownloadDto, BulkUploadsUpdateDto, GetBulkUploadsInput, BulkUploadsCreateBUDto, BulkUploadsBUDto} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class BulkUploadsService {
  apiName = 'missionsService';
  

  create = (input: BulkUploadsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BulkUploadsDto>({
      method: 'POST',
      url: '/api/missions-service/bulkUploads',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/bulkUploads/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BulkUploadsDto>({
      method: 'GET',
      url: `/api/missions-service/bulkUploads/${id}`,
    },
    { apiName: this.apiName,...config });

    getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/bulkUploads/download-token',
    },
    { apiName: this.apiName,...config });

  getList = (input: GetBulkUploadsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<BulkUploadsDto>>({
      method: 'GET',
      url: '/api/missions-service/bulkUploads',
      params: { filterText: input.filterText, fileId: input.fileId, bulkUploadStatus: input.bulkUploadStatus, fileName: input.fileName, customerId: input.customerId, priorityId: input.priorityId, industryId: input.industryId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });

    getListAsExcelFile = (input: BulkUploadsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/bulkUploads/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, fileId: input.fileId, bulkUploadStatus: input.bulkUploadStatus, fileName: input.fileName},
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: BulkUploadsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BulkUploadsDto>({
      method: 'PUT',
      url: `/api/missions-service/bulkUploads/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

    createBU = (input: BulkUploadsCreateBUDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BulkUploadsDto>({
      method: 'POST',
      url: '/api/missions-service/bulkUploads/create-Bulk-Upload',
      body: input,
    },
    { apiName: this.apiName,...config });

    getListBU = (input: GetBulkUploadsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<BulkUploadsBUDto>>({
      method: 'GET',
      url: '/api/missions-service/bulkUploads/get-Bulk-Upload',
      params: { filterText: input.filterText, fileId: input.fileId, bulkUploadStatus: input.bulkUploadStatus, fileName: input.fileName, id: input.id, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, isPaginated: input.isPaginated },
    },
    { apiName: this.apiName,...config });

    createBUFile = (input: BulkUploadsCreateBUDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BulkUploadsBUDto>({
      method: 'POST',
      url: '/api/missions-service/bulkUploads/create-Bulk-Upload-File',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
