import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetLocationInput,
  LocationsCreateDto,
  LocationsDto,
  LocationsExcelDownloadDto,
  LocationsUpdateDto,
  LocationWithAddressDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  apiName = 'missionsService';

  create = (input: LocationsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/location',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/location/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/location/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/location/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: LocationsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/location/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetLocationInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LocationsDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/location',
        params: {
          filterText: input.filterText,
          gpsCoordinates: input.gpsCoordinates,
          geoFenceRadio: input.geoFenceRadio,
          siteContact: input.siteContact,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: LocationsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationsDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/location/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getLocationsWithAddress = (
    locationIds: string[],
    customerId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, LocationWithAddressDto[]>(
      {
        method: 'POST',
        url: `/api/missions-service/location/get-with-address`,
        body: {
          locationIds: locationIds,
          customerId: customerId,
        },
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
