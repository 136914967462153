import { mapEnumToOptions } from '@abp/ng.core';

export enum AutomaticQaStatus {
  Pass = 0,
  Fail = 1,
  Manual = 2,
  NotTested = 3,
}

export const AutomaticQaStatusOptions = mapEnumToOptions(AutomaticQaStatus);
