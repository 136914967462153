import { DBConfig } from 'ngx-indexed-db';

export const actionFrameworkdbConfig: DBConfig = {
  name: 'actionsDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'cacheStore',
      storeConfig: {
        keyPath: 'key',
        autoIncrement: false,
      },
      storeSchema: [{ name: 'value', keypath: 'value', options: { unique: false, key: 'string' } }],
    },
  ],
};
