import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, Subscription, finalize, map, retry, takeWhile, timer } from 'rxjs';
import { concat } from 'rxjs';
import { ChunkFileModel } from '../models/file-upload/chunk-file.model';
import { ChunkModel } from '../models/file-upload/chunk-model';
import { ChunkUploadResultModel } from '../models/file-upload/chunk-upload-result.model';
import { ChunkCompleteModel } from '../models/file-upload/chunk-complete.model';
import { EnvironmentService } from '@abp/ng.core';
import { UploadFileProgressModel } from '../models/file-upload/upload-file-progress.model';
import { FileDescriptorDto } from '@volo/abp.ng.file-management/proxy';

@Injectable({
  providedIn: 'root',
})
export class ChunkUploaderService {
  private readonly MAX_CHUNK_SIZE = 50000000;

  apiName = 'FileManagement';

  get apiUrl() {
    return this.environment.getApiUrl(this.apiName);
  }

  get chunkSize(): number {
    const { apis } = this.environment.getEnvironment();
    return Number(apis[this.apiName]?.uploadChunkSizeInBytes || this.MAX_CHUNK_SIZE);
  }

  constructor(private http: HttpClient, private environment: EnvironmentService) {}

  uploadFile(
    file: File,
    metaData: any,
    directoryId: string = null,
    cancelControlSubject: Subject<boolean> = null
  ): Subject<UploadFileProgressModel> {
    let subjectResult = new Subject<UploadFileProgressModel>();

    const chunkFileModel = this.splitFileInChunks(this.chunkSize, file, metaData);

    this.http.get(`${this.apiUrl}/api/file-management/file-descriptor/file/id`).subscribe({
      next: (res: any) => {
        chunkFileModel.blobName = res.id;
        subjectResult.next({
          fileFinished: undefined,
          fileId: undefined,
          currentProgress: 1,
          currentFileInfo: chunkFileModel,
        });
        this.processChunks(chunkFileModel, file, directoryId, subjectResult, cancelControlSubject);
      },
      error: err => {
        console.log(err);
      },
    });

    return subjectResult;
  }

  private splitFileInChunks(maxChunkSize: number, file: File, metaData: any): ChunkFileModel {
    const totalChunks = Math.ceil(file.size / maxChunkSize);

    let chunkFileModel = new ChunkFileModel();
    chunkFileModel.totalChunks = totalChunks;
    chunkFileModel.fileName = metaData?.name ?? file.name;
    chunkFileModel.chunks = [];

    for (let i = 0; i < totalChunks; i++) {
      const start = i * maxChunkSize;
      const end = Math.min(start + maxChunkSize, file.size);

      let chunkModel = new ChunkModel();
      chunkModel.end = end;
      chunkModel.start = start;
      chunkModel.chunkNumber = i;

      chunkFileModel.chunks.push(chunkModel);
    }

    return chunkFileModel;
  }

  private processChunks(
    chunkFileModel: ChunkFileModel,
    file: File,
    directoryId: string,
    subjectResult: Subject<UploadFileProgressModel>,
    cancelControlSubject: Subject<boolean> = null
  ): void {
    const cancelSubscription = new Subscription();
    const obs: Array<Observable<any>> = [];
    let uploadCanceled: boolean = false;

    if (cancelControlSubject) {
      cancelSubscription.add(
        cancelControlSubject.subscribe(cancel => {
          uploadCanceled = cancel;
        })
      );
    }

    for (var chk of chunkFileModel.chunks) {
      if (chk.success) continue;

      const chunk = file.slice(chk.start, chk.end);
      const formData = new FormData();

      formData.append('file', chunk, chunkFileModel.fileName ?? file.name);

      const headers = new HttpHeaders()
        .set('Content-Range', `${chk.start}-${chk.end - 1}`)
        .set('Chunk-Number', `${chk.chunkNumber}`)
        .set('File-Size', `${file.size}`)
        .set('Blob-Name', `${chunkFileModel.blobName ? chunkFileModel.blobName : ''}`);

      obs.push(
        this.http.post(`${this.apiUrl}/api/file-management/file-descriptor/chunkUpload`, formData, {
          headers,
        })
      );
    }

    concat(...obs)
      .pipe(
        finalize(() => {
          if (uploadCanceled) return;

          let failedChunk = chunkFileModel.chunks.find(x => !x.success);

          if (failedChunk) {
            cancelSubscription.unsubscribe();
            this.processChunks(
              chunkFileModel,
              file,
              directoryId,
              subjectResult,
              cancelControlSubject
            );
            return;
          }

          this.completeChunksUpload(chunkFileModel, file, directoryId, subjectResult);
        }),
        map((value: ChunkUploadResultModel) => {
          if (!value.chunkIdentifier || !value.chunkNumber) {
            console.error('Chunk identifier not found:\n', value);
            throw new Error('Chunk identifier not found');
          }
          return value;
        }),
        takeWhile(_ => {
          return uploadCanceled == false;
        })
      )
      .subscribe({
        next: (res: ChunkUploadResultModel) => {
          let uploaded = chunkFileModel.chunks.find(x => x.chunkNumber == res.chunkNumber);

          if (!uploaded) return;

          uploaded.identifier = res.chunkIdentifier;
          uploaded.success = true;

          if (!chunkFileModel.blobName) chunkFileModel.blobName = res.blobName;

          let progress = this.calculateProgress(
            chunkFileModel.totalChunks,
            Number(res.chunkNumber)
          );

          subjectResult.next({
            fileFinished: undefined,
            fileId: undefined,
            currentProgress: progress,
            currentFileInfo: chunkFileModel,
          });

          if (res.thumbnail) chunkFileModel.thumbnail = res.thumbnail;
        },
        error: err => {
          subjectResult.error(err);
          console.log(err);
        },
      });
  }

  private calculateProgress(totalChunks: number, chunkNumber: number): number {
    return ((chunkNumber + 1) * 100) / totalChunks;
  }

  private completeChunksUpload(
    chunkFileModel: ChunkFileModel,
    file: File,
    directoryId: string,
    subjectResult: Subject<UploadFileProgressModel>
  ): void {
    let completeUpload: ChunkCompleteModel = {
      fileName: chunkFileModel.fileName ?? file.name,
      chunkIds: chunkFileModel.chunks.map(x => x.identifier),
      contentLenght: file.size,
      thumbnailUrl: chunkFileModel.thumbnail,
      directoryId: directoryId,
      blobName: chunkFileModel.blobName,
    };

    this.http
      .post(`${this.apiUrl}/api/file-management/file-descriptor/completeUpload`, completeUpload)
      .subscribe({
        next: (res: FileDescriptorDto) => {
          subjectResult.next({
            fileFinished: file.name,
            fileId: res.id,
            currentProgress: 100,
            currentFileInfo: chunkFileModel,
          });
        },
        error: err => {
          subjectResult.next(null);
          //subjectResult.error(err);
          console.error('Unable to complete upload:\n', err);
        },
      });
  }
}
