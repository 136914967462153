import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eNotificationsServiceRouteNames } from '../enums/route-names';

export const BASICS_WEB_NOTIFICATION_CONTENTS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/notifications-service/web-notification',
        parentName: eNotificationsServiceRouteNames.NotificationsService,
        name: 'Web Notifications',
        layout: eLayoutType.application,
        iconClass: 'fas fa-regular fa-bell',
        requiredPolicy: 'NotificationService.WebNotification.ShowMenu',
      },
    ]);
  };
}
