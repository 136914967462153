import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ActionOrderConfiguredDto, ActionOrderCreateDto, ActionOrderDto, ActionOrderExcelDownloadDto, ActionOrderUpdateDto, GetActionOrdersInput } from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class ActionOrderService {
  apiName = 'CoreService';
  

  create = (input: ActionOrderCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionOrderDto>({
      method: 'POST',
      url: '/api/core-service/action-orders',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/core-service/action-orders/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionOrderDto>({
      method: 'GET',
      url: `/api/core-service/action-orders/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/core-service/action-orders/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetActionOrdersInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ActionOrderDto>>({
      method: 'GET',
      url: '/api/core-service/action-orders',
      params: { filterText: input.filterText, statusId: input.statusId, statusName: input.statusName, actionId: input.actionId, actionName: input.actionName, roleId: input.roleId, roleName: input.roleName, orderMin: input.orderMin, orderMax: input.orderMax, isPaginated: input.isPaginated, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: ActionOrderExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/core-service/action-orders/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: ActionOrderUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionOrderDto>({
      method: 'PUT',
      url: `/api/core-service/action-orders/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  filterByRoles = (input: Array<string>, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionOrderConfiguredDto[]>({
      method: 'POST',
      url: '/api/core-service/action-orders/filter/role',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
