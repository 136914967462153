import { Component, Input, OnInit } from '@angular/core';
import { QuestionV2Service } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/questionV2.service';
import { ToasterService } from '@abp/ng.theme.shared';
import { QuestionnaireLogicManager } from '../../questionnaire-logic.service';
import { LocalizationService } from '@abp/ng.core';
import { QuestionTypesV2Service } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/question-typesV2.service';
import { AnswerV2Service } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/answerV2.service';
import { AnswerGroupV2Service } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/answerGroupV2.service';
import { QuestionnaireV2Service } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/questionnaireV2.service';
import { AnswerV2Dto, QuestionnaireV2Dto, QuestionTypeV2Dto, QuestionV2Dto } from 'projects/core-service/src/lib/proxy/core-service/lookups/models';
import { PilotDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';

@Component({
  selector: 'lib-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit {
  constructor(
    public questionsService: QuestionV2Service,
    public questionTypesService: QuestionTypesV2Service,
    public answersService: AnswerV2Service,
    public answerGroupService: AnswerGroupV2Service,
    public questionnaireService: QuestionnaireV2Service,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    public readonly pilotService: PilotService
  ) { }

  private questionnaireLogicManager = new QuestionnaireLogicManager();

  @Input() UserId: string;
  @Input() DataLink: string;
  @Input() QuestionnaireName: string;

  @Input() DisplayInList: boolean;

  @Input() OnSubmited: () => void;

  dataLoaded = false;
  showSubmitButton = false;

  questionnaire: QuestionnaireV2Dto;
  questionTypes: QuestionTypeV2Dto[] = [];
  questions: QuestionV2Dto[] = [];
  answers: AnswerV2Dto[] = [];

  editMode = false;

  alertText = '';

  infoPilot: PilotDto;

  PilotPreferredContactStyleQuestionCode: string = 'PilotPreferredContactStyle';

  ngOnInit(): void {
    this.loadQuestionnaire();

    this.alertText = this.localizationService.instant({
      key: 'CoreService::PleaseProvideAnAnswerForAllTheMandatoryFields',
      defaultValue: 'Please provide an answer for all the mandatory fields',
    });

    this.questionnaireLogicManager.noAnsweredText = this.localizationService.instant({
      key: 'CoreService::NoAnswered',
      defaultValue: 'No answered',
    });

    this.pilotService
    .get(this.UserId)
    .subscribe(res => {
      this.infoPilot = res;
    });
  }

  loadQuestionnaire() {
    this.questionnaireService.getByName({ filterText: this.QuestionnaireName }).subscribe(res => {
      if (res.items.length > 0) {
        this.questionnaire = res.items[0];
        this.loadAnswers();
      }
    });
  }

  loadAnswers() {
    this.answerGroupService
      .GetAnswerGroup({
        userId: this.UserId,
        dataLink: this.DataLink,
        questionnaireId: this.questionnaire.id,
      })
      .subscribe(
        res => {
          this.answersService.GetAnswers({ answerGroupId: res.id }).subscribe(answers => {
            this.answers = answers.items;
            this.loadQuestionTypes();
          });
        },
        () => {
          this.loadQuestionTypes();
        }
      );
  }

  loadQuestionTypes() {
    this.questionTypesService.getList({ filterText: '' }).subscribe(res => {
      this.questionTypes = res.items;
      this.loadQuestions();
    });
  }

  loadQuestions() {
    this.questionsService
      .getList({ filterText: '', questionnaireId: this.questionnaire.id })
      .subscribe(result => {
        this.questions = result.items;
        this.createViewModel();
      });
  }

  createViewModel() {
    this.questionnaireLogicManager.setData(
      this.questionnaire,
      this.questionTypes,
      this.questions,
      this.answers
    );

    if (!this.questionnaireLogicManager.alreadyAnswered && this.DisplayInList) {
      this.enableEditMode();
    }

    this.dataLoaded = true;
  }

  next() {
    if (!this.questionnaireLogicManager.canNavigateToNext()) {
      this.toaster.info(this.alertText);
      return;
    }

    this.questionnaireLogicManager.nextQuestion();
  }

  previous() {
    this.questionnaireLogicManager.previousQuestion();
  }

  submit(showAlert = true) {
    if (!this.questionnaireLogicManager.IsValid() && showAlert) {
      this.toaster.info(this.alertText);
      return false;
    }

    const _questionnaireAnswersDto = this.questionnaireLogicManager.getAnswers(this.UserId, this.DataLink);

    if (_questionnaireAnswersDto != null){
      const _questionV2DTO = this.questions.find(q => q.code == this.PilotPreferredContactStyleQuestionCode && q.title !== '');

      if (_questionV2DTO != null) {
        _questionnaireAnswersDto.answers.forEach(element => {
          if(element.questionId == _questionV2DTO.id){
            this.infoPilot.preferredContactStyle = element.answer;

            const requestPilot = this.pilotService.update(
              this.UserId,
              this.infoPilot
            );
    
            requestPilot
              .subscribe();     
          }
        });
      }
    }

    if (this.questionnaireLogicManager.alreadyAnswered) {
      this.answersService
        .UpdateAnswers(this.questionnaireLogicManager.getAnswers(this.UserId, this.DataLink))
        .subscribe(res => {
          this.OnSubmited?.();

          this.questionnaireLogicManager.setData(
            this.questionnaire,
            this.questionTypes,
            this.questions,
            res.items
          );

          this.disableEditMode();
          return true;
        });
    } else {
      this.answersService
        .PostAnswers(this.questionnaireLogicManager.getAnswers(this.UserId, this.DataLink))
        .subscribe(res => {
          this.OnSubmited?.();

          this.questionnaireLogicManager.setData(
            this.questionnaire,
            this.questionTypes,
            this.questions,
            res.items
          );

          this.disableEditMode();
          return true;
        });
    }
  }

  clamp(min: number, max: number, value: number) {
    return value < min ? min : value > max ? max : value;
  }

  enableEditMode() {
    this.editMode = true;
  }

  disableEditMode() {
    this.editMode = false;
    this.questionnaireLogicManager.resetAnswers();
  }
}
