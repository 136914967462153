import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PilotBadgeService } from '../../../../../../pilots-service/src/lib/proxy/pilots-service/controllers/relationals';
import { BadgePilotData } from '../assign-badge-modal/models/badge-pilot-data';
import { Badge } from '../../../components/common/badge/display/grid-badge/grid-badge.component';
import { ProgressionLevel } from '../assign-badge-modal/models/badge-progression-enum';
import { BadgesService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BadgeSelectionDto } from '../assign-badge-modal/models/badge-selection-dto';

@Component({
  selector: 'app-revoke-badge-modal',
  templateUrl: './revoke-badge-modal.component.html',
  styleUrls: ['./revoke-badge-modal.component.scss'],
})
export class RevokeBadgeModalComponent implements OnInit {
  pilotBadgeData: BadgePilotData;
  form: FormGroup;
  loading = false;
  displayedBadges: Badge[] = [];
  removedBadgeIds: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<RevokeBadgeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BadgePilotData,
    private formBuilder: FormBuilder,
    private pilotBadgeService: PilotBadgeService,
    private badgesService: BadgesService
  ) {
    this.pilotBadgeData = data;
    this.form = this.formBuilder.group({
      searchInput: [''],
    });
  }

  ngOnInit() {
    this.loading = true;
    this.pilotBadgeService.getSelectedBadgesForPilot(this.pilotBadgeData.pilotId).subscribe({
      next: selectedBadges => {
        if (selectedBadges.length > 0) {
          this.fetchAndFilterActiveBadges(selectedBadges).subscribe({
            next: activeBadges => {
              this.displayedBadges = activeBadges;
              this.loading = false;
            },
            error: error => {
              console.log('Error fetching active badges:', error);
              this.loading = false;
            },
          });
        } else {
          this.loading = false;
        }
      },
      error: error => {
        console.log('Error fetching selected badges:', error);
        this.loading = false;
      },
    });
  }

  fetchAndFilterActiveBadges(selectedBadges: BadgeSelectionDto[]): Observable<Badge[]> {
    const badgeObservables = selectedBadges.map(badge =>
      this.badgesService.get(badge.badgeId).pipe(
        map(badgeDto => ({
          badgeSelection: badge,
          isActive: badgeDto.isActive,
        }))
      )
    );

    return forkJoin(badgeObservables).pipe(
      map(results =>
        results
          .filter(result => result.isActive)
          .map(result => this.createBadgeObject(result.badgeSelection))
      )
    );
  }

  createBadgeObject(badge: BadgeSelectionDto): Badge {
    let badgeText = badge.badgeName;
    if (badge.progressionLevel !== undefined && badge.progressionLevel !== null) {
      badgeText += ` - ${ProgressionLevel[badge.progressionLevel]}`;
    }
    return {
      text: badgeText,
      color: '#0252ED',
      background: '#0252ED1A',
      id: badge.badgeId,
    };
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  onClickSave(): void {
    if (this.removedBadgeIds.length > 0) {
      this.dialogRef.close(this.removedBadgeIds);
    } else {
      this.dialogRef.close();
    }
  }

  removeBadge(badgeId: string): void {
    this.removedBadgeIds.push(badgeId);
    this.displayedBadges = this.displayedBadges.filter(badge => badge.id !== badgeId);
  }
}
