import { mapEnumToOptions } from '@abp/ng.core';

export enum CaptureMustBeEnum {
  OnSelectedDate = 0,
  AnytimeBefore = 1
}

export const captureMustBeEnumDisplayNames: Record<CaptureMustBeEnum, string> = {
  [CaptureMustBeEnum.OnSelectedDate]: 'On Selected Date',
  [CaptureMustBeEnum.AnytimeBefore]: 'Anytime Before'
};

export const CaptureMustBeEnumOptions = mapEnumToOptions(CaptureMustBeEnum);
