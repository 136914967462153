import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetLocationContactInput,
  LocationContactsCreateDto,
  LocationContactsDto,
  LocationContactsExcelDownloadDto,
  LocationContactsUpdateDto,
  SiteContactsDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class LocationContactsService {
  apiName = 'missionsService';

  create = (input: LocationContactsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationContactsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/location-contact',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/location-contact/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationContactsDto>(
      {
        method: 'GET',
        url: `/api/missions-service/location-contact/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/location-contact/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: LocationContactsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/location-contact/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetLocationContactInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LocationContactsDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/location-contact',
        params: {
          filterText: input.filterText,
          locationId: input.locationId,
          contactId: input.contactId,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getSiteContactsList = (customerId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<SiteContactsDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/location-contact/get-customer-site-contacts/${customerId}`,
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: LocationContactsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationContactsDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/location-contact/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
