<div class="file-tree-container">
  <ng-container *ngIf="directories && !updatingPermissions">
    <abp-tree
      [(nodes)]="directories"
      [(expandedKeys)]="service.extendedKeys"
      [draggable]="true"
      (dropOver)="onDrop($event)"
      (selectedNodeChange)="onNodeClick($event)"
      (nzExpandChange)="onExpandedKeysChange($event)"
      [selectedNode]="selectedNode"
    >
      <ng-template abp-tree-expanded-icon-template let-node let-origin="origin">
        <mat-icon inline *ngIf="!node.isExpanded && origin?.entity?.hasChildren" fill="1">add_box</mat-icon>
        
        <mat-icon inline *ngIf="node.isExpanded && node.children.length" fill="1">indeterminate_check_box</mat-icon>
      </ng-template>

      
      <ng-template abp-tree-node-template let-node>
        <!-- <i class="fa fa-folder text-primary me-2" aria-hidden="true"></i> -->
        <div class="d-flex align-items-center">
          <mat-icon class="material-symbols-rounded mt-1 me-2 folder-icon">folder</mat-icon>
          <ng-container *ngIf="node.origin.entity.isRoot && !hideMainRootFolder; else leafNode">
            {{ 'FileManagement::AllFiles' | abpLocalization }}
          </ng-container>
        <ng-template #leafNode>{{ node.title }}</ng-template>
        
    </div>
      </ng-template>

      <ng-container *abpPermission="contextMenuPolicy">
        <ng-template let-node #menu>
            <ng-container *ngIf="node.origin.entity.id === rootId" >
              <li
                class="pointer"
                ngbDropdownItem
                (click)="refresh()"
              >
                Refresh
              </li>
            </ng-container>
            <ng-container *ngIf="node.origin.entity.id !== rootId && canCreateFolder(node?.origin?.entity)" >
              <li
                *abpPermission="createPolicy"
                class="pointer"
                ngbDropdownItem
                (click)="onCreate(node.origin.entity)"
              >
                {{ 'FileManagement::CreateFolder' | abpLocalization }}
              </li>
            </ng-container>
            <ng-container *ngIf="node.origin.entity.id !== rootId && canRenameFolder(node?.origin?.entity)">
              <li
                *abpPermission="updatePolicy"
                class="pointer"
                ngbDropdownItem
                (click)="onRename(node.origin.entity)"
              >
                {{ 'FileManagement::Rename' | abpLocalization }}
              </li>
            </ng-container>
            <ng-container *ngIf="node.origin.entity.id !== rootId  && canDeleteFolder(node?.origin?.entity)">
              <li
                *abpPermission="deletePolicy"
                class="pointer"
                ngbDropdownItem
                (click)="onDelete(node.origin.entity)"
              >
                {{ 'FileManagement::Delete' | abpLocalization }}
              </li>
            </ng-container>
            <ng-container *ngIf="!displayFolderActions(this.node?.origin?.entity)">
              <li ngbDropdownItem>
                {{ 'FileManagement::NoActions' | abpLocalization }}
              </li>
            </ng-container>
          <!-- <ng-container *ngIf="isCurrentAdmin">
            <li
              *abpPermission="deletePolicy"
              class="pointer"
              ngbDropdownItem
              (click)="onChangePermissions(node.origin.entity)">
              {{ 'FileManagement::Set Permissions' | abpLocalization }}
            </li>
          </ng-container> -->
        </ng-template>
      </ng-container>
    </abp-tree>
  </ng-container>
</div>

<abp-create-folder-modal
  [(visible)]="createModalVisible"
  [parentId]="createModalParentId"
  [missionId]="missionFilter?.missionId"
  [deliverableId]="missionFilter?.deliverableId"
  [captureId]="missionFilter?.captureId"
  [orderDetailId]="missionFilter?.orderDetailId"
></abp-create-folder-modal>

<abp-rename-modal
  [(visible)]="renameModalVisible"
  [(contentToRename)]="contentToRename"
></abp-rename-modal>

<abp-move-file-modal
  [(visible)]="moveModalVisible"
  (contentSaved)="afterFolderMoved()"
  [fileToMove]="folderToMove"
  [oldParentId]="parentOfFolderToMove"
>
</abp-move-file-modal>

<folder-permissions-modal
  [(visible)]="permissionModalVisible"
  (onCloseModal)="handleOnClosePermissionsModal()"
  (onSaveModal)="handleOnSavePermissionsModal($event)"
  [roles]="roles"
  [folder]="folderPermissionSelected"
>
</folder-permissions-modal>
