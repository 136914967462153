import { Component } from '@angular/core';
import { CustomizerSettingsService } from '../../tagus/customizer-settings/customizer-settings.service';
import packageJson from '../../../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  
  public appVersion: string = packageJson.version;
  public nowYear = new Date().getFullYear();
  
  constructor(public themeService: CustomizerSettingsService) {}

  toggleCardBorderTheme() {
    this.themeService.toggleCardBorderTheme();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleCardBorderRadiusTheme() {
    this.themeService.toggleCardBorderRadiusTheme();
  }
}
