import { Component, Input, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FormHelpers } from 'projects/flyguys/src/app/form-helpers';
import { OrderFormDeliverableModel } from '../../components/orders/model/order-form-deliverable.model';

@Component({
  selector: 'app-deliverable',
  templateUrl: './deliverable.component.html',
  styleUrls: ['./deliverable.component.scss'],
})
export class DeliverableComponent implements OnInit {
  @Input() deliverable: OrderFormDeliverableModel;
  @Input() fieldsDisabled: boolean = false;
  formDelirable: FormGroup;

  constructor() {
    this.formDelirable = FormHelpers.buildValidatorsDeliverables();
  }

  ngOnInit() {
    this.addFieldsValidators();
  }

  addFieldsValidators() {
    for (const field of this.deliverable.fields) {
      this.formDelirable.addControl(
        field.id,
        new FormControl('', { validators: Validators.compose([]) })
      );
    }
  }
}
