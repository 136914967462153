import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AccessPortalService } from '../services/access-portal.service';
import { enumPortal } from '../models/enum/enum-portal.enum';
import { UserAllowedOnPortalDto } from '../models/access-portal/models';
import { AuthService } from '@abp/ng.core';

@Injectable({
  providedIn: 'root',
})
export class AccessPortalGuard {
  constructor(
    public readonly accessPortalService: AccessPortalService,
    private router: Router,
    private authService: AuthService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    var userAuth: UserAllowedOnPortalDto = await this.accessPortalService
      .isAllowedPortal(enumPortal.Operations)
      .toPromise();

    if (userAuth.isAllowed && this.authService.isAuthenticated) {
      return true;
    }

    this.router.navigate(['/access-denied']);
    return false;
  }
}
