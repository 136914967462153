<abp-file-management-modal title="preview" [fileName]="fileName" [parent]="self" [static]="true">
  <media-player 
    *ngIf="videoUrl && visible"
    reproductorId="previewVideo"
    [videoSource]="videoUrl"
    videoName="previewVideo"
    [width]="width"
    [height]="height"
    [autoplay]="true">
  </media-player>
</abp-file-management-modal>
