import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard, AuthGuard } from '@abp/ng.core';
import { FileManagementComponent } from './file-management.component';
import { FileManagementInitializer } from './file-management.initializer';
import { FileManagementGuard } from './guards/file-management.guard';
import { FileManagementPreviewComponent } from './file-management-preview/file-management-preview.component';

const routes: Routes = [
  {
    path: 'files',
    pathMatch: 'full',
    canActivate: [
      AuthGuard,
      PermissionGuard,
      FileManagementInitializer,
      FileManagementGuard,
    ],
    component: FileManagementComponent,
  },
  {
    path: 'preview',
    pathMatch: 'full',
    canActivate: [
      AuthGuard,
      PermissionGuard,
      FileManagementInitializer,
      FileManagementGuard,
    ],
    component: FileManagementPreviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FileManagementRoutingModule {}
