<h5 mat-dialog-title class="flg-modal flg-modal__title">
  {{ (data.title || 'missionsService::MissionDetail') | abpLocalization }}
  </h5>
  
  <mat-dialog-content class="flg-modal flg-modal__body">
    <ngx-datatable
          [rows]="actualData.items"
          [offset]="pageNumber"
          [count]="actualData.totalCount"
          [list]="list"
          [headerHeight]="55"
          [rowHeight]="50"
          [limit]="clientMaxResultCount"
          [externalPaging]="true"
          default
          #dataTable
        >

        <ngx-datatable-column
          name="{{'missionsService::JobId' | abpLocalization}}"
          prop="jobId"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
          [frozenLeft]="true"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.jobId }}              
          </ng-template>
        </ngx-datatable-column> 

        <ngx-datatable-column
          name="{{'missionsService::BulkUploadStatus' | abpLocalization}}"  
          prop="bulkUploadStatus"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
          [frozenLeft]="true"
        >

          <ng-template let-row="row" ngx-datatable-cell-template>
          <app-column-stylizer [columnRules]="orderBulkStates" [typeColumn]="'text'" [columnValue]="row.bulkUploadStatus"></app-column-stylizer>
          </ng-template>
        </ngx-datatable-column>    

        <ngx-datatable-column
          name="{{'missionsService::MissionName' | abpLocalization}}"
          prop="missionName"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.missionName }}
          </ng-template>
        
        
        </ngx-datatable-column>
  
        <ngx-datatable-column
        name="{{'missionsService::MissionSummary' | abpLocalization}}"
        prop="missionSummary"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.missionSummary }}
          </ng-template>


        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::ProjectName' | abpLocalization}}"
          prop="projectName"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.projectName }}
          </ng-template>
        </ngx-datatable-column>     
          
        <ngx-datatable-column
          name="{{'missionsService::Recurrence' | abpLocalization}}"
          prop="recurrence"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.recurenceId }}
          </ng-template>
        </ngx-datatable-column>       
                  
        <ngx-datatable-column
          name="{{'missionsService::BeginDate' | abpLocalization}}"
          prop="beginDate"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.beginDate }}
          </ng-template>
        </ngx-datatable-column> 
    
        <ngx-datatable-column
          name="{{'missionsService::EndDate' | abpLocalization}}"
          prop="endDate"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.endDate }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::Repetitions' | abpLocalization}}"
          prop="repetitions"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.repetitions }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::Time' | abpLocalization}}"
          prop="time"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.time }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::CaptureDateMustBe' | abpLocalization}}"
          prop="CaptureDateMust"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.CaptureDateMust }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::DeliverableDueDate' | abpLocalization}}"
          prop="deliverableDueDate"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.deliverableDueDate }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::DeliverablesNote' | abpLocalization}}"
          prop="deliverablesNote"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.deliverablesNote }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::AdditionalNotes' | abpLocalization}}"
          prop="additionalNotes"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.additionalNotes }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::SiteName' | abpLocalization}}"
          prop="siteName"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.siteName }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::LocationStreet' | abpLocalization}}"
          prop="locationStreet"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.locationStreet }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::LocationCountry' | abpLocalization}}"
          prop="locationCountry"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.locationCountry }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::LocationState' | abpLocalization}}"
          prop="locationState"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.locationState }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::LocationCity' | abpLocalization}}"
          prop="locationCity"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.locationCity }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::LocationZipCode' | abpLocalization}}"
          prop="locationZipCode"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.locationZipCode }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::LocationLatitude' | abpLocalization}}"
          prop="locationLatitude"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.locationLatitude }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::LocationLongitude' | abpLocalization}}"
          prop="locationLongitude"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.locationLongitude }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::AirspaceClassification' | abpLocalization}}"
          prop="airspaceClassification"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.airspaceClassification }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::SiteContactFrom' | abpLocalization}}"
          prop="siteContactFrom"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.siteContactFrom }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::SiteContactEmail' | abpLocalization}}"
          prop="siteContactEmail"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.siteContactEmail }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::SiteContactName' | abpLocalization}}"
          prop="siteContactName"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.siteContactFirstName + ' ' + row.siteContactLastName }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::SiteContactPhone' | abpLocalization}}"
          prop="siteContactPhone"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.siteContactPhone }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::SiteContactLevelOfCoordination' | abpLocalization}}"
          prop="siteContactLevelOfCoordination"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.siteContactLevelOfCoordination }}
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column
          name="{{'missionsService::SiteContactPreferredContact' | abpLocalization}}"
          prop="siteContactPreferredContact"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.siteContactPreferredContact }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{'missionsService::UploadFailDescription' | abpLocalization}}"
          prop="uploadFailDescription"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.uploadFailDescription }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage">
            <app-table-paginator [(maxResultCount)]="list.maxResultCount" [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount" [pageSize]="pageSize" [(pageNumber)]="pageNumber" [curPage]="curPage" [dataTable]="dataTable" [customPageSize]="true"
              style="width: 100%;">
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable> 
  </mat-dialog-content>
  
  <mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      Close
    </button>
  </mat-dialog-actions>
