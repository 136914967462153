<h5 mat-dialog-title class="flg-modal flg-modal__title">
    {{ data.title || 'Error detail' }}
  </h5>
  
  <mat-dialog-content class="flg-modal flg-modal__body">
    <p [innerText]='data.detail.uploadFailDescription'></p>
  </mat-dialog-content>
  
  <mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      Close
    </button>
  </mat-dialog-actions>
