import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialSharedModule } from '../../material-shared/material-shared.module';

import { OrdersRoutingModule } from './orders-routing.module';

import { NewOrderFormComponent } from './new-order-form/new-order-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrdersListComponent } from './orders-list/orders-list.component';

import { OrdersMissionsComponent } from './orders-misssions/orders-missions.component';
import { OrdersProjectsComponent } from './projects/orders-projects.component';
import { OrdersPortfolioComponent } from './orders-portfolio/orders-portfolio.component';
import { PageModule } from '@abp/ng.components/page';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { CoreModule } from '@abp/ng.core';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { MissionsModule } from 'projects/missions-service/src/lib/basics/missions/missions.module';
import { ProjectsModule } from 'projects/missions-service/src/lib/basics/projects/projects.module';
import { PortafoliosModule } from 'projects/missions-service/src/lib/basics/portafolios/portafolios.module';
import { PilotSourcingModule } from '../../pilot-sourcing/pilot-sourcing.module';
import { OrderContactsTableComponent } from './order-contacts/order-contacts.component';
import { SharedModule } from '../../shared/shared.module';
import { OrderContactComponent } from './order-contact/order-contact.component';
import { OrderSiteComponent } from './new-order-form/order-site/order-site.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MissionOverviewComponent } from './mission-overview/mission-overview.component';
import { FirstStepComponent } from './steps/first-step/first-step.component';
import { SecondStepComponent } from './steps/second-step/second-step.component';
import { ThirdStepComponent } from './steps/third-step/third-step.component';
import { FourthStepComponent } from './steps/fourth-step/fourth-step.component';
import { RecurrenceComponent } from './recurrence/recurrence.component';
import { DaypickerComponent } from './daypicker/daypicker.component';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';

import { NewCustomerFormComponent } from './new-customer-form/new-customer-form.component';
import { OrderDetailsComponent } from './new-order-form/order-details/order-details.component';
import { OrderDetailsListComponent } from './new-order-form/order-details-list/order-details-list.component';
import { EditCustomerDetailsComponent } from './edit-customer-details-component/edit-customer-details-component.component';
import { ColumnsModule } from '../columns/columns.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MissionBulkOverviewComponent } from './mission-bulk-overview/mission-bulk-overview.component';
import { MissionBulkUploadComponent } from './mission-bulk-upload/mission-bulk-upload.component';
import { MissionBulkUploadDetailComponent } from './mission-bulk-upload-detail/mission-bulk-upload-detail.component';
import { TablePageModule } from '../table-page/table-page.module';
import { NewSiteFormComponent } from './new-site/new-site-form.component';
import { BulkUploadDetailErrorsComponent } from './bulk-upload-detail-errors/bulk-upload-detail-errors.component';
import { OrderSlaTableComponent } from './order-sla/order-sla.component';

@NgModule({
  declarations: [
    OrdersListComponent,
    OrdersMissionsComponent,
    OrdersProjectsComponent,
    OrdersPortfolioComponent,
    NewOrderFormComponent,
    OrderContactsTableComponent,
    OrderContactComponent,
    OrderSiteComponent,
    MissionOverviewComponent,
    FirstStepComponent,
    SecondStepComponent,
    ThirdStepComponent,
    FourthStepComponent,
    RecurrenceComponent,
    DaypickerComponent,
    NewCustomerFormComponent,
    OrderDetailsComponent,
    OrderDetailsListComponent,
    EditCustomerDetailsComponent,
    MissionBulkOverviewComponent,
    MissionBulkUploadComponent,
    MissionBulkUploadDetailComponent,
    NewSiteFormComponent,
    BulkUploadDetailErrorsComponent,
    OrderSlaTableComponent,
  ],
  imports: [
    CoreModule,
    CommercialUiModule,
    OrdersRoutingModule,
    CommonModule,
    MaterialSharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxValidateCoreModule,
    PageModule,
    MissionsModule,
    ProjectsModule,
    PortafoliosModule,
    PilotSourcingModule,
    SharedModule,
    DigitOnlyModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    ColumnsModule,
    MatProgressBarModule,
    TablePageModule,
  ],
  exports: [
    OrdersListComponent,
    OrdersMissionsComponent,
    OrdersProjectsComponent,
    OrdersPortfolioComponent,
    OrderContactsTableComponent,
    OrderContactComponent,
    NewSiteFormComponent,
    OrderSlaTableComponent,
  ],
})
export class OrdersModule {}
