<div class="d-flex align-items-center justify-content-between">
  <h5 mat-dialog-title>{{ 'pilotsService::PilotProfile' | abpLocalization }}</h5>

  <button mat-flat-button mat-dialog-close>
    <span class="material-symbols-outlined"> close </span>
  </button>
</div>

<mat-dialog-content>
  <ngx-spinner
    bdColor="rgba(245,245,245,0.7)"
    size="default"
    color="#bc5a2e"
    type="ball-square-clockwise-spin"
    [fullScreen]="false"
    [zIndex]="111"
  >
    <p style="color: #bc5a2e">{{ 'pilotsService::LoadingProfile' | abpLocalization }}</p>
  </ngx-spinner>
  <div class="row">
    <div class="col-12 col-md-4 col-lg-3 col">
      <div class="p-lg-3 p-md-0 mb-4">
        <mat-card>
          <app-pilot-info-card [pilot]="pilot" [userId]="userId"></app-pilot-info-card>
        </mat-card>
      </div>
    </div>
    <div class="col-12 col-md-8 col-lg-9 col">
      <div class="p-lg-3 p-md-0">
        <!-- <app-records [pilot]="pilot"></app-records> -->
        <mat-card class="mb-25">
          <mat-card-content>
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab label="{{ 'pilotsService::ProfileOverview' | abpLocalization }}"
                ><br />
                <app-pilot-profile-overview [pilotId]="pilotId"></app-pilot-profile-overview
              ></mat-tab>
              <mat-tab label="{{ 'pilotsService::Questionnaire' | abpLocalization }}"
                ><br />
                <app-pilot-profile-questionnaire
                  [pilotId]="pilotId"
                ></app-pilot-profile-questionnaire
              ></mat-tab>
              <mat-tab label="{{ 'pilotsService::MyDocuments' | abpLocalization }}"
                ><br />
                <app-pilot-profile-documents [pilotId]="pilotId"></app-pilot-profile-documents
              ></mat-tab>
            </mat-tab-group>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</mat-dialog-content>
