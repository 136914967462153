<h5 mat-dialog-title class="flg-modal flg-modal__title">
  {{ 'missionsService::AssignedHistory' | abpLocalization }}
</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container p-0">
    <div class="row justify-content-center">
      <ngx-datatable
        [rows]="missionAssignments"
        [scrollbarH]="true"
        [footerHeight]="false"
        [headerHeight]="55"
        [rowHeight]="50"
        class="material flg-simple-table"
        default
      >
        <ngx-datatable-column
          [name]="'missionsService::Role' | abpLocalization"
          prop="role"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.role }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'missionsService::Name' | abpLocalization"
          prop="userName"
          [resizeable]="true"
          [sortable]="false"
          [headerClass]="'flg-simple-table__header'"
          [cellClass]="'flg-simple-table__cell'"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.user }}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      {{ modalConfig.actions.cancel || 'Cancel ' }}
    </button>
  </div>
</mat-dialog-actions>
