import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { FlyguysMapComponent } from '@flyguys/map';

@Injectable({
  providedIn: 'root',
})
export class MapVisibilityService {
  state$: ReplaySubject<boolean>;
  mapInstance$: Subject<FlyguysMapComponent>;

  constructor() {
    this.state$ = new ReplaySubject();
    this.state$.next(false);
    this.mapInstance$ = new Subject();
  }

  set(visible: boolean) {
    this.state$.next(visible);
  }

  setMapInstance(map: FlyguysMapComponent) {
    this.mapInstance$.next(map);
  }
}
