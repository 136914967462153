import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GetIndustryInput,
  GetPriorityInput,
  IndustriesDto,
  PrioritiesDto,
  SLADto,
} from '../../../../../../../core-service/src/lib/proxy/core-service/lookups';
import {
  IndustriesService,
  PrioritiesService,
} from '../../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import { forkJoin, Subscription } from 'rxjs';
import { PagedResultDto } from '@abp/ng.core';
import { MissionDetailsForm } from '../models/mission-details-form.model';
import {
  CaptureMustBeEnum,
  captureMustBeEnumDisplayNames,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum';
import { DatePipe } from '@angular/common';
import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { MissionSLADto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';

@Component({
  selector: 'app-mission-details-modal',
  templateUrl: './mission-details-modal.component.html',
  styleUrls: ['./mission-details-modal.component.scss'],
})
export class MissionDetailsModalComponent implements OnInit, OnDestroy {
  formMissionDetails: FormGroup;

  SLAOptions: PagedResultDto<SLADto> = {
    items: [],
    totalCount: 0,
  };
  filterPriorities = {} as GetPriorityInput;

  dataIndustries: PagedResultDto<IndustriesDto> = {
    items: [],
    totalCount: 0,
  };
  filterIndustries = { isPaginated: false } as GetIndustryInput;

  private subscriptions = new Subscription();
  slaGenerated: MissionSLADto[];
  slaSelected: SLADto;
  minDate: Date;

  dataCaptureMust = Object.keys(CaptureMustBeEnum)
    .filter(key => typeof CaptureMustBeEnum[key] === 'number')
    .map(key => ({
      value: CaptureMustBeEnum[key],
      description: captureMustBeEnumDisplayNames[CaptureMustBeEnum[key]],
    }));

  constructor(
    public dialogRef: MatDialogRef<MissionDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MissionDetailsForm,
    public readonly industriesService: IndustriesService,
    public readonly priorityService: PrioritiesService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private slaService: SlaService,
  ) {
    this.minDate = new Date();

    this.formMissionDetails = this.fb.group({
      missionName: [
        data.missionName || '',
        [Validators.required, this.notOnlyWhitespace(), Validators.maxLength(385)],
      ],
      description: [data.description || '', [Validators.required, this.notOnlyWhitespace()]],
      priorityId: [data.priorityId || '', Validators.required],
      industryId: [data.industryId || '', Validators.required],
      captureDate: [this.getDateFromString(data.fixedCustomerRequestedCaptureDate) || ''],
      captureTime: [data.fixedCustomerRequestedCaptureTime || ''],
      captureDateMustId: [data.customerRequestedCaptureMustBe ?? undefined],
      missionAssetOrder: [data.missionAssetOrder || ''],
      manualAirspaceWaiverRequired: [data.manualAirspaceWaiverRequired],
    });
  }

  ngOnInit(): void {
    const sub = forkJoin([
      this.slaService.get(this.data.customerId),
      this.industriesService.getList(this.filterIndustries),
    ]).subscribe({
      next: ([slas, industries]) => {
        this.SLAOptions = slas;
        this.dataIndustries = industries;
        if (this.SLAOptions.totalCount > 0) {
          this.slaSelected = this.SLAOptions.items.find(d => d.id == this.data.priorityId);
          if (this.slaSelected.isCustom) {
            this.slaService.getMissionSLAByMissionId(this.data.missionId).subscribe(d => {
              this.slaGenerated = d.items;
            });
          }
        }
      },
      error: error => console.log(`Error on Mission Details Modal component: ${error}`),
    });
    this.subscriptions.add(sub);
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  saveMissionDetails(): void {
    if (this.formMissionDetails.valid) {
      const formValue = this.formMissionDetails.getRawValue();

      const priorityItem = this.SLAOptions.items.find(x => x.id === formValue.priorityId);
      const industryItem = this.dataIndustries.items.find(x => x.id === formValue.industryId);

      const updatedMissionDetails: MissionDetailsForm = {
        missionId: this.data.missionId,
        missionName: formValue.missionName,
        description: formValue.description,
        priorityId: formValue.priorityId,
        orderPriority: priorityItem?.name,
        industryId: formValue.industryId,
        orderIndustry: industryItem?.name,
        customerRequestedCaptureDate: this.datePipe.transform(
          formValue.captureDate,
          'MM/dd/yyyy',
          'UTC',
        ),
        customerRequestedCaptureMustBe: formValue.captureDateMustId,
        customerRequestedCaptureTime: formValue.captureTime,
        fixedCustomerRequestedCaptureDate: this.datePipe.transform(
          formValue.captureDate,
          'MM/dd/yyyy',
        ),
        fixedCustomerRequestedCaptureTime: this.getFixedHourFormat(formValue.captureTime),
        missionAssetOrder: formValue.missionAssetOrder,
        customerId: this.data.missionId,
        sla: this.slaGenerated,
        manualAirspaceWaiverRequired: formValue.manualAirspaceWaiverRequired,
      };

      this.dialogRef.close(updatedMissionDetails);
    }
  }

  notOnlyWhitespace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getDateFromString(formatedDate: string): Date {
    if (!formatedDate) return undefined;

    const segments = formatedDate.split('/');

    const year = parseInt(segments[2], 10);
    const day = parseInt(segments[1], 10);
    const month = parseInt(segments[0], 10) - 1;

    return new Date(year, month, day);
  }

  private getFixedHourFormat(fixedHour: string) {
    if (
      !fixedHour ||
      (!fixedHour.toLowerCase().includes('am') && !fixedHour.toLowerCase().includes('pm'))
    )
      return fixedHour;

    const [time, period] = fixedHour.split(' ');
    const [hour, minute] = time.split(':');
    let formattedHour = parseInt(hour);

    if (period === 'PM') {
      formattedHour += 12;
    }

    return `${formattedHour}:${minute}`;
  }

  changeSla(sla) {
    this.slaSelected = this.SLAOptions.items.find(s => s.id == sla);
    this.getCalculateSla();
  }

  getCalculateSla() {
    if (this.slaSelected.isCustom) {
      this.slaService
        .calculateSLAByMissionId(this.data.missionId, this.slaSelected.id)
        .subscribe(d => {
          if (d.totalCount > 0) this.slaGenerated = d.items;
        });
    } else {
      this.slaGenerated = [];
    }
  }
}
