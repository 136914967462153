import { ListService, PagedResultDto } from '@abp/ng.core';
import { EXTENSIONS_IDENTIFIER } from '@abp/ng.theme.shared/extensions';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import type {
  DirectoryContentDto,
  DirectoryContentRequestInput,
  FileSelected,
  FilterMission,
  SelectedAllDto,
} from '@volo/abp.ng.file-management/proxy';
import { DirectoryDescriptorService } from '@volo/abp.ng.file-management/proxy';
import { FileDescriptorService } from '@volo/abp.ng.file-management/proxy';
import { merge, Subscription, Observable, concat, finalize } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { eFileManagementComponents } from '../../enums/components';
import { DeleteService } from '../../services/delete.service';
import { DownloadService } from '../../services/download.service';
import { NavigatorService } from '../../services/navigator.service';
import { UpdateStreamService } from '../../services/update-stream.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { FilePreviewModel } from '../../models/file-preview.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DirectoryTreeService } from '../../services';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { OptionsFilter } from '../../models/folder-permissions/optionsFilter.model';
import { DecimalPipe } from '@angular/common';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginationSortingAndGlobalSearch } from 'projects/flyguys/src/app/shared/grid-filters/models/pagination-sorting-and-global-search';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'abp-file-management-folder-content',
  templateUrl: './file-management-folder-content.component.html',
  styleUrls: ['./file-management-folder-content.component.scss'],
  providers: [
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eFileManagementComponents.FolderContent,
    },
    DecimalPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileManagementFolderContentComponent implements OnInit, OnDestroy, OnChanges {
  @Output() contentUpdate = new EventEmitter<DirectoryContentDto[]>();
  @Output() filesSelectedChange = new EventEmitter<FileSelected[]>();
  @Output() selectAllChange = new EventEmitter<SelectedAllDto>();

  @Input() filesSelected: FileSelected[];
  @Input() showOptions = false;
  @Input() hideDirectoryView: boolean;
  @Input() missionFilter: FilterMission = undefined;
  @Input() optionsFilter: OptionsFilter = null;
  @Input() onlyViewDownload: boolean;

  @ViewChild('tabgroup', { static: true }) tabGroup: MatTabGroup;
  @ViewChild('container') container: ElementRef;
  @ViewChild('mediaPreviewContainer') mediaPreviewContainer: ElementRef;
  contentToRename: DirectoryContentDto;
  renameModalOpen = false;
  private readonly loadingDelay: number = 3000;

  public readonly TAB_MAIN_FILES_MANAGER: number = 0;
  public readonly TAB_DIRECTORY_CONTENT: number = 1;
  public readonly TAB_CONTENT_PREVIEW: number = 2;
  activeTab = this.TAB_MAIN_FILES_MANAGER;

  previewModalOpen = false;
  previewUrl: string;
  previewId: string;
  fileName: string;

  videoPreviewModalOpen = false;
  videoPreviewUrl: string;

  pdfPreviewModalOpen = false;
  pdfPreviewUrl: SafeResourceUrl;

  fileToMove: DirectoryContentDto;
  moveModalOpen = false;
  parentOfFileToMove: string;
  directoryToLoad: DirectoryContentDto;
  currentPage = 0;
  totalItems = 0;
  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearch = {
    skipCount: 0,
    maxResultCount: 100,
  };
  public filePreviewItems: Array<FilePreviewModel>;

  private currentContentForViewer: DirectoryContentDto[];
  isLoading = false;
  firstTime = true;
  changeOfDirectory = false;
  onScrollingUpdatingFiles = false;
  contentUpdated = false;

  private ROOT_FOLDER_ID: string = 'ROOT_ID';

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const container =
      this.activeTab == this.TAB_DIRECTORY_CONTENT
        ? this.container?.nativeElement
        : this.mediaPreviewContainer?.nativeElement;
    const scrollableHeight = container?.scrollHeight ?? 0;

    const scrollY = window.scrollY;
    const remainingHeight = scrollableHeight - scrollY;

    // Define a threshold, for example, load more data when 100px of content is remaining
    const threshold = 500;

    if (
      remainingHeight > 0 &&
      remainingHeight < threshold &&
      !this.isLoading &&
      this.currentList.length < this.totalItems
    ) {
      this.currentPage =
        Math.floor(this.currentList.length / this.paginationSortingAndGlobalSearch.maxResultCount) -
        1;
      this.currentPage++;
      this.isLoading = true;
      this.onScrollingUpdatingFiles = true;
      this.changeOfDirectory = false;
      this.contentUpdated = false;
      this.list.get();
    }
  }
  private updateContent = (data: PagedResultDto<DirectoryContentDto>) => {
    this.totalItems = data.totalCount;

    if (this.firstTime) {
      this.currentList = data.items;
      this.firstTime = false;
    } else if (this.isLoading && this.onScrollingUpdatingFiles) {
      if (this.currentList?.length == 0 || this.currentList[0]?.id !== data?.items[0]?.id) {
        this.currentList.push(...data.items);
      }
      this.isLoading = false;
      this.onScrollingUpdatingFiles = false;
      this.contentUpdated = true;

      if (this.selectAll) this.markItemsAsSelected(data.items);
    } else if (!this.firstTime && !this.isLoading && !this.onScrollingUpdatingFiles) {
      if (
        data.totalCount > 0 &&
        this.currentList.length < data.totalCount &&
        !this.changeOfDirectory &&
        !this.contentUpdated
      ) {
        if (this.currentList?.length == 0 || this.currentList[0]?.id !== data?.items[0]?.id) {
          this.currentList.push(...data.items);
        } else {
          this.currentList = data.items;
        }
      } else if (this.changeOfDirectory || this.currentList.length > data.totalCount) {
        this.currentList = data.items;
      }
    }

    if (data.totalCount === 0) {
      this.currentList = new Array();
    }

    this.contentUpdate.emit(this.currentList);
    this.cdr.detectChanges();
  };

  currentList: DirectoryContentDto[] = new Array();
  content$ = this.list
    .hookToQuery(query =>
      this.service.getContent({
        ...query,
        maxResultCount: this.paginationSortingAndGlobalSearch.maxResultCount,
        id: this.updateStream.currentDirectory || this.missionFilter?.rootFolder?.id,
        skipCount: this.currentPage * this.paginationSortingAndGlobalSearch.maxResultCount,
      }),
    )
    .pipe(tap(this.updateContent));

  subscription = new Subscription();
  currentOpenFolder: DirectoryContentDto;

  folderSelectedAll: SelectedAllDto;
  selectAll: boolean;

  messageOnRemoving: string = 'Removing Selected Files/Folders';

  constructor(
    public readonly list: ListService<DirectoryContentRequestInput>,
    public readonly service: DirectoryDescriptorService,
    private deleteService: DeleteService,
    private downloadService: DownloadService,
    private navigator: NavigatorService,
    private updateStream: UpdateStreamService,
    private domSanitizer: DomSanitizer,
    public directoryTreeService: DirectoryTreeService,
    private cdr: ChangeDetectorRef,
    private decimalPipe: DecimalPipe,
    private confirmation: ConfirmationService,
    private fileService: FileDescriptorService,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
  ) {
    if (this.missionFilter) {
      this.switchActiveTab(this.TAB_DIRECTORY_CONTENT);
    }
  }

  markItemsAsSelected(items: DirectoryContentDto[]) {
    if (!items) return;

    for (const item of items) {
      const selected = this.filesSelected.find(x => x.item.id == item.id);

      if (selected) continue;

      const selectedItem: FileSelected = { isSelected: true, item: item };

      this.filesSelected.push(selectedItem);

      this.addFiletoDownload(selectedItem);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.missionFilter) {
      if (this.missionFilter) {
        this.switchActiveTab(this.TAB_DIRECTORY_CONTENT);
      }
    }
  }

  ngOnInit() {
    this.paginationSortingAndGlobalSearch.maxResultCount = this.missionFilter?.rootFolder?.id
      ? 100
      : 10;

    if (!this.currentOpenFolder) {
      this.currentOpenFolder = <any>this.missionFilter?.rootFolder;
    }

    this.currentPage = 0;
    this.subscription = merge(
      this.updateStream.contentRefresh$,
      this.updateStream.directoryDelete$.pipe(filter(this.isDirectoryPresent)),
      this.updateStream.directoryRename$.pipe(
        map(directory => directory.id),
        filter(this.isDirectoryPresent),
      ),
    ).subscribe(() => {
      if (!this.isLoading) this.list.get();
    });

    this.subscription.add(
      this.content$.subscribe(res => {
        this.currentContentForViewer = this.currentList;
        this.setContentForPreview();

        if (this.directoryToLoad) {
          const folderId = this.directoryToLoad.id;
          this.directoryToLoad = undefined;
          this.directoryTreeService.updateDirectories(folderId);
        }

        if (this.currentList && this.currentList.length > 0) {
          this.currentOpenFolder = <DirectoryContentDto>{
            id: this.currentList[0].parentId,
          };
        }

        this.cdr.detectChanges();
      }),
    );

    this.subscription.add(
      this.directoryTreeService.currentSelectedNode$.subscribe(selectedNode => {
        this.currentOpenFolder = <any>selectedNode;

        if (
          this.currentOpenFolder?.id == this.ROOT_FOLDER_ID &&
          this.currentList &&
          this.currentList.length > 0
        ) {
          this.currentOpenFolder = <DirectoryContentDto>{
            id: this.currentList[0].parentId,
          };
        }

        this.cdr.detectChanges();
      }),
    );

    this.subscription.add(
      this.service.navigationFromBreadcrumb$.subscribe(() => {
        this.resetSelectAll();
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openFolder(record: DirectoryContentDto) {
    if (!record.canRead) return;

    this.currentOpenFolder = record;
    this.selectFilesToDownload(record.id);

    this.directoryTreeService.announceNewSelectedNode(<any>this.currentOpenFolder);

    this.navigator.goToFolder({ id: record.id, name: record.name });
    this.changeOfDirectory = true;
    this.currentPage = 0;
  }

  renameFolder(record: DirectoryContentDto) {
    this.directoryToLoad = record;
    this.openRenameModal(record);
  }

  deleteFolder(record: DirectoryContentDto) {
    this.deleteService.deleteFolder(record).subscribe();
  }

  downloadFile(record: DirectoryContentDto) {
    this.downloadService.downloadFile(record).subscribe();
  }

  renameFile(record: DirectoryContentDto) {
    this.openRenameModal(record);
  }

  deleteFile(record: DirectoryContentDto) {
    this.messageOnRemoving = `Removing ${record.isDirectory ? 'Folder' : 'File'}`;
    this.spinner.show();
    this.deleteService.deleteFile(record).subscribe(
      response => {
        this.filesSelected = this.filesSelected.filter(x => x.item.id != record.id);
        this.currentList = this.currentList.filter(x => x.id != record.id);
        this.currentContentForViewer = this.currentList;
        this.setContentForPreview();
        this.cdr.detectChanges();
        this.contentUpdate.emit(this.currentList);
        this.spinner.hide();
        this.snackBar.open('File removed.', 'Close', {
          duration: this.loadingDelay,
        });
      },
      err => {
        this.spinner.hide();
        this.snackBar.open('An error occured deleting the file', 'Close', {
          duration: this.loadingDelay,
        });
      },
    );
  }

  moveFile(record: DirectoryContentDto) {
    this.fileToMove = record;
    this.moveModalOpen = true;
  }

  moveFolder(record: DirectoryContentDto) {
    this.fileToMove = record;
    this.parentOfFileToMove = this.navigator.getCurrentFolderId();
    this.moveModalOpen = true;
  }

  onContentSaved() {
    delete this.contentToRename;
    delete this.fileToMove;
    delete this.parentOfFileToMove;
  }

  public handleOnPictureClicked(filePreviewItem: FilePreviewModel, itemId: string) {
    const pdfFileExtension = 'pdf';

    if (filePreviewItem.imageUrl) {
      this.previewUrl = filePreviewItem.imageUrl;
      this.previewModalOpen = true;
      this.previewId = itemId;
      this.fileName = filePreviewItem.fileName;
      return;
    } else if (filePreviewItem.videoUrl) {
      this.videoPreviewUrl = filePreviewItem.videoUrl;
      this.fileName = filePreviewItem.fileName;
      this.videoPreviewModalOpen = true;
      return;
    } else if (filePreviewItem.GetFileExtension() == pdfFileExtension) {
      this.previewId = itemId;
      this.pdfPreviewModalOpen = true;
      this.fileName = filePreviewItem.fileName;
      this.pdfPreviewUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
        filePreviewItem.fileUrl + '#toolbar=0',
      );
      return;
    }
  }

  public onTabChange(event: MatTabChangeEvent) {
    this.switchActiveTab(event.index);

    if (event.index == this.TAB_DIRECTORY_CONTENT) {
      this.setContentForPreview();
    }
  }

  public handleOnCloseModalVideo() {
    this.videoPreviewUrl = undefined;
    this.videoPreviewModalOpen = false;
    this.fileName = '';
  }

  public handleOnVisibleChange(value: boolean): void {
    if (value) return;

    this.videoPreviewUrl = undefined;
    this.videoPreviewModalOpen = false;
    this.fileName = '';
  }

  canRenameFolder(item: DirectoryContentDto): boolean {
    return item.canDelete && item.canWrite && !this.onlyViewDownload;
  }

  canMoveFolder(item: DirectoryContentDto): boolean {
    return item.canDelete && item.canWrite && !this.onlyViewDownload;
  }

  canDeleteFolder(item: DirectoryContentDto): boolean {
    return item.canDelete && !this.onlyViewDownload;
  }

  displayIconAction(item: DirectoryContentDto): boolean {
    if (item.isDirectory) {
      return this.canRenameFolder(item) || this.canDeleteFolder(item) || this.canMoveFolder(item);
    }

    return false;
  }

  private setContentForPreview() {
    this.filePreviewItems = this.currentContentForViewer
      .map(
        x =>
          new FilePreviewModel(
            x.name,
            x.imagePreviewUrl,
            x.videoPreviewUrl,
            x.fileContentPreviewUrl,
            x.thumbnailUrl,
            x,
          ),
      )
      .filter(x => x.previewSupported);
  }

  private openRenameModal(withContent: DirectoryContentDto) {
    this.renameModalOpen = true;
    this.contentToRename = withContent;
  }

  private isDirectoryPresent = (id: string) => {
    return this.currentList.some(item => item.id === id);
  };

  isCheckBoxSelected(item: DirectoryContentDto): boolean {
    return this.filesSelected.some(x => x.item.id === item.id);
  }

  isMediaPreviewCheckBoxSelected(item: FilePreviewModel): boolean {
    return this.filesSelected.some(x => x.item.id === item.directoryContent.id);
  }

  onPreviewCheckboxChange(event: MatCheckboxChange, item: FilePreviewModel) {
    const file = this.currentList.filter(x => x.id === item.directoryContent.id);

    if (file) {
      this.onCheckboxChange(event, file[0]);
    }
  }

  onSelectAll(event: MatCheckboxChange, items: DirectoryContentDto[]) {
    if (!event.checked) {
      this.filesSelected = [];
      this.folderSelectedAll = { isSelected: false, folderId: '', folderName: '' };
      this.filesSelectedChange.emit(this.filesSelected);
      this.selectAllChange.emit(this.folderSelectedAll);
      return;
    }

    if (!this.filesSelected) this.filesSelected = [];

    for (const item of items) {
      const selected = this.filesSelected.find(x => x.item.id == item.id);

      if (selected) continue;

      const selectedItem: FileSelected = { isSelected: event.checked, item: item };

      this.filesSelected.push(selectedItem);

      this.addFiletoDownload(selectedItem);
    }

    this.folderSelectedAll = {
      isSelected: true,
      folderId: this.currentOpenFolder.id,
      folderName: this.currentOpenFolder.name,
    };
    this.filesSelectedChange.emit(this.filesSelected);
    this.selectAllChange.emit(this.folderSelectedAll);
  }

  public resetSelectAll() {
    if (this.selectAll) {
      this.filesSelected = [];
      this.selectAll = false;
      this.folderSelectedAll = { isSelected: false, folderId: '', folderName: '' };
      this.filesSelectedChange.emit(this.filesSelected);
      this.selectAllChange.emit(this.folderSelectedAll);
    }
  }

  onCheckboxChange(event: MatCheckboxChange, item: DirectoryContentDto) {
    let data: FileSelected = { isSelected: event.checked, item: item };
    if (data.isSelected) {
      this.addFiletoDownload(data);
    } else {
      if (this.selectAll) this.selectAll = false;
      this.folderSelectedAll = { isSelected: false, folderId: '', folderName: '' };

      this.selectAllChange.emit(this.folderSelectedAll);

      if (data.item.isDirectory) {
        this.filesSelected = this.removeFolderFromDownload(this.filesSelected, data.item.id);
        this.filesSelected = this.removeParentFoldersFromDownload(
          this.filesSelected,
          data.item.parentId,
        );
      } else {
        let index = this.filesSelected.findIndex(x => x.item.id === data.item.id);
        if (index !== -1) {
          let fileDeleted = this.filesSelected.splice(index, 1);
          this.filesSelected = this.removeParentFoldersFromDownload(
            this.filesSelected,
            fileDeleted[0].item.parentId,
          );
        }
      }
    }
    this.filesSelectedChange.emit(this.filesSelected);
  }

  public handleOnRemoveSelection(): void {
    let message = '';

    if (this.selectAll) {
      this.confirmation
        .warn('FileManagement::DeleteForSelectAllConfirmationMessage', 'CoreService::AreYouSure', {
          messageLocalizationParams: [this.folderSelectedAll.folderName ?? 'Root'],
        })
        .subscribe(res => {
          if (res != Confirmation.Status.confirm) return;

          this.messageOnRemoving = 'Removing Selected Files/Folders';
          this.spinner.show();

          this.service
            .deleteContent(this.folderSelectedAll.folderId)
            .pipe(
              finalize(() => {
                this.spinner.hide();
                this.snackBar.open('Files removed.', 'Close', {
                  duration: this.loadingDelay,
                });
              }),
            )
            .subscribe({
              next: () => {
                this.filesSelected = [];
                this.currentList = [];
                this.currentContentForViewer = this.currentList;
                this.currentPage = 0;
                this.setContentForPreview();
                this.cdr.detectChanges();
                this.contentUpdate.emit(this.currentList);
                this.selectAll = false;
                this.folderSelectedAll = { isSelected: false, folderId: '', folderName: '' };
                this.filesSelectedChange.emit(this.filesSelected);
                this.selectAllChange.emit(this.folderSelectedAll);
              },
              error: err => {
                this.spinner.hide();
                console.log(err);
              },
            });
        });

      return;
    }

    let files = this.filesSelected.filter(x => !x.item.isDirectory);
    let folders = this.filesSelected.filter(x => x.item.isDirectory);

    if (files.length > 0) {
      message = files.length > 1 ? `${files.length} files` : `${files.length} file`;
    }

    if (folders.length > 0) {
      if (message) message += ' and ';

      message += folders.length > 1 ? `${folders.length} folders` : `${folders.length} folder`;
    }

    this.confirmation
      .warn('FileManagement::DeleteSelectionConfirmationMessage', 'CoreService::AreYouSure', {
        messageLocalizationParams: [message],
      })
      .subscribe(res => {
        if (res != Confirmation.Status.confirm) return;
        this.messageOnRemoving = 'Removing Selected Files/Folders';
        this.spinner.show();
        const obs: Array<Observable<any>> = [];

        if (files) {
          for (let file of files) {
            obs.push(this.fileService.delete(file.item.id));
          }
        }

        if (folders) {
          for (let folder of folders) {
            obs.push(this.service.delete(folder.item.id));
          }
        }

        concat(...obs)
          .pipe(
            finalize(() => {
              this.spinner.hide();
              this.snackBar.open('Files removed.', 'Close', {
                duration: this.loadingDelay,
              });
            }),
          )
          .subscribe({
            next: (idDeleted: string) => {
              this.filesSelected = this.filesSelected.filter(x => x.item.id != idDeleted);

              if (this.selectAll) {
                this.selectAll = false;
                this.folderSelectedAll = { isSelected: false, folderId: '', folderName: '' };
                this.selectAllChange.emit(this.folderSelectedAll);
              }

              this.currentList = this.currentList.filter(x => x.id != idDeleted);
              this.currentContentForViewer = this.currentList;
              this.setContentForPreview();
              this.cdr.detectChanges();
              this.contentUpdate.emit(this.currentList);
              if (this.selectAll) this.selectAll = false;
            },
            error: err => {
              this.spinner.hide();
              console.log(err);
            },
          });
      });
  }

  private selectFilesToDownload(parentFolderId: string) {
    let folderSelected = this.filesSelected.find(x => x.item.id === parentFolderId);
    if (folderSelected === undefined) {
      return;
    }
    let input: DirectoryContentRequestInput = {
      filter: null,
      id: folderSelected.item.id,
      skipCount: 0,
      maxResultCount: this.missionFilter?.rootFolder?.id ? 100 : 10,
    };
    this.service.getContent(input).subscribe({
      next: response => {
        response.items.forEach(x => {
          this.addFiletoDownload({ isSelected: true, item: x });
        });
      },
      error: error => console.error('Unable to get folder content:\n', error),
    });
  }

  private addFiletoDownload(newFile: FileSelected) {
    if (newFile.isSelected) {
      let file = this.filesSelected.find(x => x.item.id === newFile.item.id);
      if (file) {
        return;
      }
      if (newFile.item.isDirectory) {
        this.filesSelected = this.removeFolderFromDownload(this.filesSelected, newFile.item.id);
      }
      this.filesSelected.push(newFile);
    }
  }

  private removeFolderFromDownload(files: FileSelected[], folderId: string): FileSelected[] {
    files.forEach(x => {
      if (x.item.id === folderId) {
        x.isSelected = false;
      }
      if (x.item.parentId === folderId) {
        x.isSelected = false;
        if (x.item.isDirectory) {
          this.removeFolderFromDownload(files, x.item.id);
        }
      }
    });
    return files.filter(x => x.isSelected);
  }

  private removeParentFoldersFromDownload(files: FileSelected[], folderId: string): FileSelected[] {
    let folderIndex = files.findIndex(x => x.item.id === folderId);
    if (folderIndex === -1) {
      return files;
    }
    let parentFolder = files.splice(folderIndex, 1);
    if (!(parentFolder[0].item.isDirectory && parentFolder[0].item.parentId)) {
      return files;
    }
    return this.removeParentFoldersFromDownload(files, parentFolder[0].item.parentId);
  }

  public getItemSize(size: number): string {
    const _100MBInByte = 102400;
    const _1GBInByte = 1073741824;
    const _1TBInByte = 1099511627776;

    const toKB = 1024;
    const toMB = 1048576;

    if (size < _100MBInByte) return this.decimalPipe.transform(size / toKB, '1.0-1') + ' KB';

    if (size < _1GBInByte) return this.decimalPipe.transform(size / toMB, '1.1-1') + ' MB';

    if (size < _1TBInByte) return this.decimalPipe.transform(size / _1GBInByte, '1.1-1') + ' GB';

    return this.decimalPipe.transform(size / _1TBInByte, '1.1-1') + ' TB';
  }

  public switchActiveTab(newActiveTab: number) {
    this.activeTab = newActiveTab;
    this.resetSelectAll();
  }
}
