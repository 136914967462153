import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { MissionFlowDto } from '../../models/mission-flow-dto';
import { ActionOrdered } from '../../models/action-ordered-dto';
import { missionStatusOptions } from 'projects/missions-service/src/lib/proxy/missions-service/shared/mission-status.enum';
import { ProductsDeliverablesService } from '../../../services/products-deliverables.service';
import { DeliverableDto } from '../../../models/products-deliverables/deliverable-dto';
import { ConfigStateService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { Observable, Subject, Subscription, tap } from 'rxjs';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { MissionStatus } from '../../models/mission-status.enum';
import { OrderResumeDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals/models';
import { OAuthService } from 'angular-oauth2-oidc';
import { MatMenuTrigger } from '@angular/material/menu';
import { CaptureWithDeliverablesDto } from '../../models/capture-with-deliverables-dto';
import { enumWebBackgroundNotificationSubKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-subkey.enum';
import { PilotSourcingCommunicationService } from '../../pilot-sourcing-communication-service';
import { AssignedDto, MissionsDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { MatDialog } from '@angular/material/dialog';
import { MissionAssignmentsModalComponent } from 'projects/missions-service/src/lib/basics/mission-assignments-modal/mission-assignments-modal.component';
import { MissionsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { CaptureDeliverableStatusEnum } from '../../../models/products-deliverables/capture-deliverable-status-enum';
import * as shape from 'd3-shape';
import { StatusAndAssignedPermissions } from '../../../shared/grid-filters/models/status-and-assigned-permissions';
import { PaginationSortingAndGlobalSearch } from '../../../shared/grid-filters/models/pagination-sorting-and-global-search';
import { MissionsGridFilters } from 'projects/missions-service/src/lib/basics/missions/components/models/missions-grid-filters';
import { FlyguysMapDimension, FlyguysMapMarker } from '@flyguys/map';
import { ThemeSharedModule } from '@abp/ng.theme.shared';

// @TODO Tech debt https://dev.azure.com/newtonvision/FLYGUYS/_workitems/edit/9691
// We're missing a router implementation here. Tabs cannot be used as link

export const single: SingleSeries = [
  {
    name: 'Completed',
    value: 15,
    extra: {
      code: 'cm'
    }
  },
  {
    name: 'In Progress',
    value: 25,
    extra: {
      code: 'ip'
    }
  },
  {
    name: 'On Hold',
    value: 2,
    extra: {
      code: 'hl'
    }
  },
];

export interface Series {
  name: StringOrNumberOrDate;
  series: DataItem[];
}

export interface MultiSeries extends Array<Series> {}

export type StringOrNumberOrDate = string | number | Date;

export enum ScaleType {
  Time = 'time',
  Linear = 'linear',
  Ordinal = 'ordinal',
  Quantile = 'quantile'
}

export interface DataItem {
  name: StringOrNumberOrDate;
  value: number;
  extra?: any;
  min?: number;
  max?: number;
  label?: string;
}

/**
 * Formats a label given a date, number or string.
 *
 * @export
 */
export function formatLabel(label: any): string {
  if (label instanceof Date) {
    label = label.toLocaleDateString();
  } else {
    label = label.toLocaleString();
  }

  return label;
}

/**
 * Escapes a label.
 *
 * @export
 */
export function escapeLabel(label: any): string {
  return label.toLocaleString().replace(/[&'`"<>]/g, match => {
    return {
      '&': '&amp;',
      // tslint:disable-next-line: quotemark
      "'": '&#x27;',
      '`': '&#x60;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;'
    }[match];
  });
}

export interface SingleSeries extends Array<DataItem> {}



const ELEMENT_DATA: any[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

@Component({
  selector: 'app-mission-ngx-charts',
  templateUrl: './mission-poc-ngx-charts.component.html',
  styleUrls: ['./mission-poc-ngx-charts.component.scss'],
})
export class MissionPocNgxChartsComponent implements OnInit, OnDestroy {

  public mapMarkers: FlyguysMapMarker[] = [];
  public mapDimensions: FlyguysMapDimension = {
    height: '400px', // default size in case the calculation fails
    width: '500px',
  };

  mapTypeId:google.maps.MapTypeId.TERRAIN;

  single = single;

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = ELEMENT_DATA;

  view: [number, number] = [700, 350]; // Width and height of the chart

  data = [
    {
      name: 'Customer Request',
      value: 35,
      extra: 'CR'
    },
    {
      name: 'Pilot Success',
      value: 30,
      extra: 'PS'
    },
    {
      name: 'Mission Coordinator',
      value: 40,
      extra: 'MC'
    },
    {
      name: 'Pilot',
      value: 15,
      extra: 'P'
    },
    {
      name: 'QA/QC',
      value: 6,
      extra: 'QA'
    },
    {
      name: 'Sales',
      value: 25,
      extra: 'S'
    }
  ];

  colorScheme = {
    domain: ['#BC5A2E', '#503016', '#38332E', '#445F4F', '#626955', '#235656'],
  };

  performanceScheme = {
    domain: ['#626955', '#A1A599', '#C0C3BB'],
  };

  burndownScheme = {
    domain: ['#626955', '#BC5A2E'],
  };

  xAxisLabel = 'Categories';
  yAxisLabel = 'Values';

  viewBurndown: [number, number] = [500, 300];

  range: boolean = false;
  schemeType = ScaleType.Ordinal;

  curves = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear
  };

  curve = this.curves.Natural;

  dataBurndownChart: any = [
    {
      "name": "Remaining",
      "series": [
        {
          "value": 100,
          "name": "100 Days",
          "min": 95,
          "max": 100
        },
        {
          "value": 95,
          "name": "60 Days",
          "min": 60,
          "max": 80
        },
        {
          "value": 80,
          "name": "40 Days",
          "min": 40,
          "max": 60
        },
        {
          "value": 45,
          "name": "20 Days",
          "min": 15,
          "max": 35
        },
        {
          "value": 10,
          "name": "1 Days",
          "min": 0,
          "max": 1
        }
      ]
    },
    {
      "name": "Ideal Trend",
      "series": [
        {
          "value": 100,
          "name": "100 Days",
          "min": 95,
          "max": 100
        },
        {
          "value": 75,
          "name": "60 Days",
          "min": 60,
          "max": 80
        },
        {
          "value": 50,
          "name": "40 Days",
          "min": 40,
          "max": 60
        },
        {
          "value": 25,
          "name": "20 Days",
          "min": 15,
          "max": 35
        },
        {
          "value": 0,
          "name": "1 Days",
          "min": 0,
          "max": 1
        }
      ]
    }
  ];


  filter: string;
  riskSelected: string;

  backToOrder(){
    this.routing.navigate(['pilot-sourcing/missions/' + this.missionId]);
  }

  charSelected(event: any) {

    this.filter = event.extra;
    this.mData.items = this.allMissions.filter(x => x.statusCode == event.extra && (!this.riskSelected || x['risk'] == this.riskSelected));
  }

  filterByRisk(risk: string) {

    this.riskSelected = risk;
    this.mData.items = this.allMissions.filter(x => x['risk'] == risk && (!this.filter || x.statusCode == this.filter));
  }

  cleanFilters() {
    this.filter = '';

    if (this.riskSelected)
      this.filterByRisk(this.riskSelected);
    else
      this.mData.items = this.allMissions;
  }

  cleanRisk() {
    this.riskSelected = '';

    if (this.filter)
      this.charSelected({ extra: this.filter});
    else
      this.mData.items = this.allMissions;
  }

  charActivate(event: any) {
    // console.log("Activate", event);
  }

  charDeactivate(event: any) {
    // console.log("Deactivate", event);
  }


  valueFormatting(value: number): string {
    return `${Math.round(value).toLocaleString()} €`;
  }

  
  pieTooltipText({ data }) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);

    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">${val}</span>
    `;
  }


  /**
   * DECLARE HERE VALID ACTIONS IN THE ORDER GRID.
   */
  private readonly ALLOWED_ORDER_DETAIL_ACTIONS = [
    'accept_cancellation',
    'accept_mission',
    'add_note',
    'assign_MC',
    'assign_qaqc',
    'cancel_mission',
    'check_in',
    'check_in_mc',
    'complete_upload',
    'confirm_flight',
    'confirm_mission',
    'logistic_coordination',
    'edit_mission',
    'pause_mission',
    'processing_complete',
    'rate_experience',
    'ready_for_sourcing',
    'reject_mission',
    'request_data',
    'request_data_qaqc',
    'request_deliverables',
    'request_reassignment',
    'request_refly',
    'reschedule_mission',
    'resource_pilot',
    'resubmit_mission',
    'resume_mission',
    'source_pilot',
    'submit_mc_pilot_rating',
    'submit_qaqc_rating',
    'submit_to_qaqc',
    'update_pilot_rating',
    'upload_data',
    'mark_as_dead',
    'data_upload_complete',
    'missionCoordinator_awaitingFlight',
    'reassign_pilot_success',
    'reassign_mc',
    'reassign_qaqc',
    'QA_QC_Complete',
    'reschedule:request_refly',
    'mc_resource',
    'mark_mission_as_flown',
    'reschedule_mission_success',
    'assign_sales',
    'no_code',
    'client_invoiced',
    'mc-review-to-logistic-coordination',
  ];

  missionId: string;
  missionData: MissionFlowDto;
  actions: ActionOrdered[] = [];
  public readonly missionStatus = missionStatusOptions;
  missionStatusId: string = '';

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  private isProgrammaticChange = false;
  private displayPilotSourcingTable = false;
  private displayCaptures = true;
  public orderResumeAdditional: any;
  public firstAction: ActionOrdered;
  public reloadingNotes: boolean = false;
  deliverables: DeliverableDto[] = [];
  deliverablesname: string;
  currentUser: any;
  selectedCaptureId: string;
  selectedCapture: CaptureWithDeliverablesDto;
  users: any;

  emptyGuid: string = '00000000-0000-0000-0000-000000000000';

  subscription = new Subscription();

  iconActions: ActionOrdered[];
  listActions: ActionOrdered[] = new Array();
  actionsToLoad: string[];
  extendedMissionDto: OrderResumeDto;
  currentToken: string;

  noteTabActive: boolean;
  timelineTabActive: boolean;

  captureDateLocal: string;
  captureDateLocalTime: string;

  @ViewChild('actionsMenu') actionsMenu: MatMenuTrigger;

  reloadCapturesActions: Subject<any> = new Subject();

  pilotSourcingCommunicationSubscription: Subscription;
  updateDeliverable: number;
  constructor(
    private routing: Router,
    private route: ActivatedRoute,
    private missionService: MissionFlowService,
    private cdRef: ChangeDetectorRef,
    private productsDeliverablesService: ProductsDeliverablesService,
    private stateService: ConfigStateService,
    private usersService: IdentityUserService,
    private notificationBroadcastService: NotificationBroadcastService,
    private oAuthService: OAuthService,
    private localizationService: LocalizationService,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
    private missionsService: MissionsService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.pilotSourcingCommunicationSubscription.unsubscribe();
  }

  ngOnInit() {
    this.missionId = this.route.snapshot.paramMap.get('missionId');
    this.currentUser = this.stateService.getDeep('currentUser');
    this.currentToken = this.oAuthService.getAccessToken();

    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        const missionId = params.get('missionId');
        if (missionId && missionId !== this.missionId) {
          this.missionId = this.route.snapshot.paramMap.get('missionId');
          this.initMissionFlow();
        }
      },
    });

    this.initMissionFlow();

    this.pilotSourcingCommunicationSubscription =
      this.pilotSourcingCommunicationService.reloadFunctionCalled$.subscribe((obj: any) => {
        if (obj?.name) {
          this.missionData.customerName = obj.name;
          this.extendedMissionDto.customerName = obj.name;
        }

        if (obj?.reloadComplementarioInfo) this.getInfoMission();

        if (obj?.missionName) this.missionData.missionName = obj.missionName;
      });

    this.usersService.getList({ maxResultCount: 1000 }).subscribe(r => {
      this.users = r.items?.map(q => {
        return { id: q.id, value: `${q.name || ''} ${q.surname || ''}` };
      });
    });

    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionStatus) {
          if (
            notif.itemId &&
            (notif.extraArgument.missionStatus || notif.extraArgument.missionStatus == 0) &&
            notif.extraArgument.missionStatusCode &&
            this.missionId == notif.itemId
          ) {
            this.getInfoMission();
            this.loadMissionInformation();
            this.reloadCapturesActions.next(true);
          }
        }

        if (
          notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalNoteAddedToMission
        ) {
          if (
            notif.itemId &&
            notif.notificationSubKey == enumWebBackgroundNotificationSubKey.EventForNoteList &&
            this.missionId == notif.itemId
          ) {
            this.reloadAction();
          }
        }

        if (
          notif.notificationKey ==
          enumWebBackgroundNotificationKey.EventGlobalForMissionAssignationUpdated
        ) {
          if (notif.extraArgument.salesUser)
            this.missionData.salesName = notif.extraArgument.salesUser;
        }
      })
    );

    this.getMissionValues().subscribe({
      next: (missionValues: PagedResultDto<MissionsDto>) => {
        this.setMissionValues(missionValues);
      },
      error: error => {
        console.log(error);
      },
    });
  }

  mData: PagedResultDto<MissionsDto> = {
    items: [],
    totalCount: 0,
  };

  allMissions: Array<MissionsDto>;

  
  private setMissionValues(list: PagedResultDto<MissionsDto>) {
    this.mData = list;

    for(let i = 0; i < this.mData.items.length; i++) {

      if (i >= 0 && i < 3) {
        this.mData.items[i].statusCode = 'CR';
        this.mData.items[i]['risk'] = 'medium';
      }

      if (i >= 3 && i < 7) {
        this.mData.items[i].statusCode = 'PS';
        this.mData.items[i]['risk'] = 'high';
      }

      
      if (i >= 7 && i < 11) {
        this.mData.items[i].statusCode = 'MC';
        this.mData.items[i]['risk'] = 'low';
      }

      
      if (i >= 11 && i < 13) {
        this.mData.items[i].statusCode = 'P';
        this.mData.items[i]['risk'] = 'medium';
      }

      
      if (i >= 13 && i < 14) {
        this.mData.items[i].statusCode = 'QA';
        this.mData.items[i]['risk'] = 'high';
      }

      
      if (i >= 14) {
        this.mData.items[i].statusCode = 'S';
        this.mData.items[i]['risk'] = 'low';
      }

    }
    
    this.allMissions = [... this.mData.items];

    // this.mapCurrentStatusEnumValues(this.data.items);
    // this.configureActionInMissions(this.data.items);
  }

  statusAndAssignedPermissions: StatusAndAssignedPermissions = {
    assignedToAnyone: true,
    inAnyStatus: false,
    assignedToMe: false,
    inTheTeam: false,
    allRecords: true,
  };

  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearch = {
    skipCount: 0,
    maxResultCount: 15,
  };

  missionGridFilters: MissionsGridFilters = {};

  private getMissionValues(): Observable<PagedResultDto<MissionsDto>> {
    let showAssignedToAnyone = true;
    let showInAnyStatus = true;

    if (showAssignedToAnyone) {
      this.statusAndAssignedPermissions.assignedToAnyone = showAssignedToAnyone;
    }

    if (showInAnyStatus) {
      this.statusAndAssignedPermissions.inAnyStatus = showInAnyStatus;
    }

    return this.missionsService.getMissionsGridData(
      this.missionGridFilters,
      this.statusAndAssignedPermissions,
      this.paginationSortingAndGlobalSearch
    );
  }

  showAssigned(): void {
    var missionAssignments: AssignedDto[] = [];

    this.missionsService.getMissionAssigned(this.missionId).subscribe({
      next: res => {
        res.forEach(element => {
          if (missionAssignments.findIndex(ma => ma.role == element.role) == -1)
            missionAssignments.push(element);
        });

        this.dialog.open(MissionAssignmentsModalComponent, {
          disableClose: true,
          panelClass: 'modal-base',
          width: '900px',
          data: {
            missionAssignments,
            title: '',
            actions: {},
          },
        });
      },
      error: error => {
        console.log('Error,', error);
      },
    });
  }

  initMissionFlow() {
    this.getInfoMission();

    this.loadMissionInformation();

    this.getMissionDeliverables();
  }

  reloadAction() {
    setTimeout(() => {
      this.reloadNotes();
    }, 500);
  }

  reloadNotes() {
    this.reloadingNotes = true;
    this.cdRef.detectChanges();
    this.reloadingNotes = false;
    this.cdRef.detectChanges();
  }

  displayCharts = false;

  async onTabChange(event: MatTabChangeEvent) {
    if (event.tab.textLabel === 'Captures' && !this.isProgrammaticChange) {
      this.displayPilotSourcingTable = false;
    } else {
      this.isProgrammaticChange = false;
    }


    this.displayCharts = event.tab.textLabel === 'Charts';

    this.noteTabActive =
      event.tab.textLabel == this.localizationService.instant(`missionsService::NotesTab`);
    this.timelineTabActive =
      event.tab.textLabel == this.localizationService.instant(`missionsService::TimelineTab`);
  }

  async navigateToSourcing() {
    try {
      this.isProgrammaticChange = true;
      this.displayPilotSourcingTable = true;
      this.tabGroup.selectedIndex = 3;
    } catch (error) {
      console.log('Navigation error:', error);
    }
  }

  handleCaptureId(capture: CaptureWithDeliverablesDto) {
    this.selectedCaptureId = capture.captureId;
    this.selectedCapture = capture;
    this.displayPilotSourcingTable = true;
  }

  async handleActionClick(action: ActionOrdered): Promise<void> {
    this.getInfoMission();
    if (!action.isActionFramework) {
      switch (action.code) {
        default:
          alert('Work in Progress...');
          break;
      }
    }
  }

  getIconNameByActionCode(actionCode: string): string {
    const iconMap: { [key: string]: string } = {
      SP: 'record_voice_over',
      AN: 'add_notes',
      RSMI: 'event_repeat',
    };

    return iconMap[actionCode] || 'arrow_circle_right';
  }

  getInfoMission() {
    this.missionService.getOrderResume(this.missionId).subscribe({
      next: response => {
        if (response.missionCaptureDetails?.currentCaptureDate) {
          this.captureDateLocal = response.missionCaptureDetails?.fixedCurrentCaptureDate;
          this.captureDateLocalTime = response.missionCaptureDetails?.fixedCurrentCaptureTime;
        }

        this.extendedMissionDto = response;

        if (response.customerName && this.missionData)
          this.missionData.customerName = response.customerName;
      },
      error: error => {
        console.log(error);
      },
    });
  }

  formatTime(time: string): string {
    if (!time) return '--:--';

    const [hours, minutes] = time.split(':');
    const formattedHours = parseInt(hours, 10) > 12 ? parseInt(hours, 10) - 12 : hours;
    const amOrPm = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${minutes} ${amOrPm}`;
  }

  getMissionDeliverables() {
    this.productsDeliverablesService.getDeliverablesByMission(this.missionId).subscribe({
      next: response => {
        this.deliverables = response;
        const uniqueDeliverables = [
          ...new Set(response.map(deliverable => deliverable.deliverableName)),
        ];
        this.deliverablesname = uniqueDeliverables.join(', ');
      },
      error: error => console.log(error),
    });
  }

  handleCaptureBreadcrumb() {
    this.selectedCaptureId = null;
    this.selectedCapture = null;
    this.displayPilotSourcingTable = false;
  }

  private loadMissionInformation(): void {
    this.missionService.getMissionData(this.missionId).subscribe({
      next: response => {
        this.missionData = response;

        this.loadActionInformation();
      },
      error: err => console.log(err),
    });
  }

  private mapActions(actions: Array<ActionOrdered>): void {
    // this.firstAction = actions.find(
    //   action =>
    //     action.order == this.PRIMARY_ACTION_ORDER &&
    //     this.ALLOWED_ORDER_DETAIL_ACTIONS.includes(action.code)
    // );

    // let filteredActions = actions.filter(
    //   action =>
    //     action.order != this.PRIMARY_ACTION_ORDER &&
    //     action.isActionFramework &&
    //     this.ALLOWED_ORDER_DETAIL_ACTIONS.includes(action.code)
    // );

    // let secActionIndex = filteredActions.findIndex(x => x.code == this.FIRST_SECONDARY_ACTION_CODE);

    // if (secActionIndex > -1) {
    //   let secAction = filteredActions[secActionIndex];
    //   filteredActions.splice(secActionIndex, 1);
    //   filteredActions.unshift(secAction);
    // }

    // filteredActions = filteredActions.filter(
    //   (action, index, self) => index === self.findIndex(a => a.code === action.code)
    // );

    // this.iconActions = filteredActions.slice(0, this.ICON_ROW_LENGTH);
    // this.actionsToLoad = filteredActions.filter(r => r.isActionFramework).concat(this.firstAction).map(r => r.code);
    // this.listActions = filteredActions.slice(this.ICON_ROW_LENGTH);
  }

  /**
   * Calls directly to the action clicked on the ... menu
   * @param action: any
   */
  handleAction(action: any) {
    action.target.querySelector('a').click();
    this.actionsMenu.closeMenu();
  }

  private loadActionInformation(): void {
    this.missionService.getActionsPermissions(this.missionData.missionStatusCode).subscribe({
      next: response => {
        // Filter by the allowed general actions
        this.actions = response;

        this.missionStatusId = this.missionData.missionStatusId;
        // Sort the actions by order to arrange the buttons. And get the first action for the main button
        this.actions.sort((a, b) => a.order - b.order);

        this.mapActions(this.actions);

        // Don't show the captures tab when in "Customer Request" or in "Ready for Sourcing"
        this.displayCaptures =
          this.missionData.missionStatusCode !== MissionStatus.CustomerRequest &&
          this.missionData.missionStatusCode !== MissionStatus.ReadyForSourcing;
      },
      error: err => console.log(err),
    });
  }

  public getActionData(actionCode: string) {
    switch (actionCode) {
      case 'check_in_mc':
        return {
          ...this.missionData,
          deliverable: this.deliverablesname,
          missionCoordinator: this.missionData.salesName,
          title: this.missionData.missionName,
          pilot: this.extendedMissionDto.pilotName || '-',
          creatorId: this.currentUser.id,
          captureDate: this.captureDateLocal || '-',
          captureTime: this.captureDateLocalTime || '-',
        };
      case 'request_refly':
      case 'request_data': {
        return {
          id: this.missionData.missionId,
          title: this.missionData.missionName || '-',
          captureDate: this.captureDateLocal || '-',
          customer: this.missionData.customerName || '-',
          missionSummary: this.extendedMissionDto.missionSummary || '-',
          pilot: this.extendedMissionDto.pilotName || '-',
          location: this.extendedMissionDto.missionOrderDetails.locationAddress || '-',
          originAuthToken: this.currentToken,
          lastScopeOfWork: this.missionData.lastScopeOfWork,
          creatorId: this.currentUser.id,
          captureTime: this.captureDateLocalTime || '-',
        };
      }
      case 'reassign_mc':
        return {
          id: this.missionData.missionId,
          reassignTitle: this.missionData.missionName,
          captureDate: this.captureDateLocal || '-',
          customer: this.missionData.customerName || '-',
          reassignSummary: this.extendedMissionDto.missionSummary,
          pilot: this.extendedMissionDto.pilotName || '-',
          location: this.extendedMissionDto.missionOrderDetails.locationAddress || '-',
          pilotSuccess: this.missionData.pilotSuccessName || '-',
          creatorId: this.currentUser.id,
          captureTime: this.captureDateLocalTime || '-',
        };

      case this.QaQcCompeteActionCode:
        return {
          ...this.extendedMissionDto,
          id: this.extendedMissionDto.missionName,
          missionId: this.missionId,
          creatorId: this.currentUser.id,
          pilot: this.extendedMissionDto.pilotName || '-',
          pilotSuccess: this.missionData.pilotSuccessName || '-',
          salesName: this.missionData.salesName || '-',
          customer: this.missionData.customerName || '-',
          customerName: this.missionData.customerName || '-',
          qaqcManagerId: `${this.missionData.qaqcUserId}/${this.missionData.qaqcUsername}/${this.missionData.qaqcRoleId}`,
          missionCoordinator: this.missionData.missionCoordinatorName ?? '-',
          title: this.missionData.missionName || '-',
          captureDate: this.captureDateLocal || '-',
          captureTime: this.captureDateLocalTime || '-',
          deliverable: this.deliverablesname || '-',
          qaqcName: this.missionData.qaqcName || '-',
          confirmationTitle: 'Warning!',
          confirmationDescription:
            'At least one or more deliverables are not accepted, are you sure you want to mark QA QC Complete?',
        };

      default: {
        return {
          ...this.extendedMissionDto,
          id: this.extendedMissionDto.missionName,
          missionId: this.missionId,
          creatorId: this.currentUser.id,
          pilot: this.extendedMissionDto.pilotName || '-',
          pilotSuccess: this.missionData.pilotSuccessName || '-',
          salesName: this.missionData.salesName || '-',
          customer: this.missionData.customerName || '-',
          customerName: this.missionData.customerName || '-',
          qaqcManagerId: `${this.missionData.qaqcUserId}/${this.missionData.qaqcUsername}/${this.missionData.qaqcRoleId}`,
          missionCoordinator: this.missionData.missionCoordinatorName ?? '-',
          title: this.missionData.missionName || '-',
          captureDate: this.captureDateLocal || '-',
          captureTime: this.captureDateLocalTime || '-',
          deliverable: this.deliverablesname || '-',
          qaqcName: this.missionData.qaqcName || '-',
        };
      }
    }
  }

  public showNextStepHelper(): boolean {
    return (
      this.firstAction &&
      (this.firstAction?.description?.length > 0 || this.firstAction?.helperText?.length > 0)
    );
  }

  QaQcCompeteActionCode: string = 'QA_QC_Complete';

  checkIfThrowsConfirmationAction(code: string): boolean {
    if (code === this.QaQcCompeteActionCode) {
      // Check if all deliverables are either accepted or rejected
      for (let deliverable of this.deliverables as DeliverableDto[]) {
        let status: number = parseInt(deliverable.status);

        if (isNaN(status) || status === CaptureDeliverableStatusEnum.NotReviewed) {
          return true;
        }
      }

      return false;
    }

    return false;
  }
}
