import { AfterViewInit, Component } from '@angular/core';

import { SubscriptionService, eLayoutType } from '@abp/ng.core';
import { LayoutService } from '../../services/layout.service';
import { CustomizerSettingsService } from '../../components/tagus/customizer-settings/customizer-settings.service';

@Component({
  selector: 'app-account-layout',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.scss'],
  providers: [LayoutService, SubscriptionService],
})
export class AccountLayoutComponent implements AfterViewInit {
  // required for dynamic component
  static type = eLayoutType.account;

  authWrapperKey = 'Account.AuthWrapperComponent';

  constructor(public service: LayoutService, public themeService: CustomizerSettingsService) {}

  ngAfterViewInit() {
    this.service.subscribeWindowSize();
  }
}
