<h5 mat-dialog-title class="flg-modal flg-modal__title">Edit Location</h5>

<mat-dialog-content class="flg-modal flg-modal__body overflow-scroll">
  <div class="container p-0">
    <div class="row justify-content-center">
      <form [formGroup]="formLocationModal" skipValidation>
        <p class="text-muted">
          Select an existing site or create a new one by typing its name. Take into account changing
          the site for this mission will reset the site contacts.
        </p>

        <mat-form-field class="w-100">
          <mat-label>Site name</mat-label>
          <input
            type="text"
            placeholder="Search for Site name"
            matInput
            formControlName="siteName"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onLocationSelected($event)">
            <mat-option *ngFor="let location of filteredLocations" [value]="location">
              <span class="location-name">{{ location.siteName }}</span>
              <span class="location-address">{{ location.streetAddress }}</span>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="formLocationModal.get('siteName').hasError('required')">
            Please enter a value
          </mat-error>
        </mat-form-field>

        <flg-location-form
          [displaySelector]="true"
          [countries]="countries?.items"
          [states]="states?.items"
          [form]="formLocationModal.get('location')"
          [displaySelector]="true"
          (locationChange)="handleLocationChange($event)"
        ></flg-location-form>

        <mat-form-field class="w-100">
          <mat-label>Airspace Classification</mat-label>
          <mat-select name="airspaceclassification" formControlName="airspaceClassificationId">
            <mat-option
              *ngFor="let air of dataAirspace.items"
              [value]="air.id"
              title="{{ air.description }}"
              [disabled]="air.id === emptyGuid"
            >
              {{ air.description }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formLocationModal.get('airspaceClassificationId').hasError('required')">
            Please enter a value
          </mat-error>
        </mat-form-field>

        <div class="w-100">
          <app-file-upload
            (filesSelected)="onkmlSelected($event)"
            (fileRemoved)="onkmlRemoved($event)"
            [selectedFiles]="this.kmlFiles"
            [allowMultiple]="false"
            [allowedExtensions]="'.kml'"
          ></app-file-upload>
        </div>

        <div class="mt-3 mb-3 w-100">
          <flg-map
            [markers]="mapMarkers"
            [usesDarkMode]="false"
            [usesKML]="hasKml"
            [options]="{
              disableDefaultUI: true,
              mapTypeId: 'satellite'
            }"
            [dimensions]="{
              height: '400px',
              width: '100%'
            }"
            (markerData)="handleMarkerClick($event)"
          ></flg-map>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end t-mt-8 t-mb-8">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="saveUpdatedLocation()"
      [disabled]="!IsValid()"
      [class.disabled-button]="!IsValid()"
    >
      Save
    </button>
  </div>
</mat-dialog-actions>
