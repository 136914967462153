import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BadgePilotData } from './models/badge-pilot-data';
import { PagedResultDto } from '@abp/ng.core';
import {
  BadgeCategoryDto,
  type GetBadgeCategoriesInput,
} from '../../../../../../missions-service/src/lib/proxy/missions-service/relationals';
import { BadgeCategoryService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals/badge-category.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BadgesService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics';
import { BadgesDto } from '../../../../../../missions-service/src/lib/proxy/missions-service/basics';
import { BadgeSelectionDto } from './models/badge-selection-dto';
import { ProgressionLevel } from './models/badge-progression-enum';
import { forkJoin, Observable } from 'rxjs';
import { PilotBadgeService } from '../../../../../../pilots-service/src/lib/proxy/pilots-service/controllers/relationals';

@Component({
  selector: 'app-assign-badge-modal',
  templateUrl: './assign-badge-modal.component.html',
  styleUrls: ['./assign-badge-modal.component.scss'],
})
export class AssignBadgeModalComponent implements OnInit {
  pilotBadgeData: BadgePilotData;
  categoriesMaxResultCount: 1000;
  lstBadgeCategories: PagedResultDto<BadgeCategoryDto> = {
    items: [],
    totalCount: 0,
  };
  selectedBadgeCategoryId: string;
  allBadgeSelectionList: BadgeSelectionDto[] = [];
  displayedBadgeSelectionList: BadgeSelectionDto[] = [];
  userSelections: BadgeSelectionDto[] = [];
  initialUserSelections: BadgeSelectionDto[] = [];

  form: FormGroup;
  loading = false;
  noBadgesFound: boolean = false;
  hasChanges: boolean = false;

  protected readonly ProgressionLevel = ProgressionLevel;
  progressionLevels = Object.values(ProgressionLevel).filter(value => typeof value === 'number');

  constructor(
    public dialogRef: MatDialogRef<AssignBadgeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BadgePilotData,
    private formBuilder: FormBuilder,
    private badgeCategoryService: BadgeCategoryService,
    private badgeService: BadgesService,
    private pilotBadgeService: PilotBadgeService
  ) {
    this.pilotBadgeData = data;
    this.form = this.formBuilder.group({
      searchInput: [''],
    });
  }

  ngOnInit() {
    this.loading = true;
    forkJoin({
      categories: this.fetchBadgeCategories(),
      selectedBadges: this.pilotBadgeService.getSelectedBadgesForPilot(this.pilotBadgeData.pilotId),
    }).subscribe({
      next: ({ categories, selectedBadges }) => {
        this.lstBadgeCategories = categories;
        this.sortBadgeCategories();
        this.userSelections = selectedBadges.map(badge => ({
          ...badge,
          isPreSelected: true,
        }));
        this.initialUserSelections = JSON.parse(JSON.stringify(this.userSelections));
        if (this.lstBadgeCategories.items.length > 0) {
          this.selectedBadgeCategoryId = this.lstBadgeCategories.items[0].id;
          this.displayBadgesFromCategory(this.selectedBadgeCategoryId);
        }
      },
      error: error => {
        console.log(error);
        this.loading = false;
      },
    });
  }

  fetchBadgeCategories(): Observable<PagedResultDto<BadgeCategoryDto>> {
    const input: GetBadgeCategoriesInput = {
      maxResultCount: this.categoriesMaxResultCount,
    };
    return this.badgeCategoryService.getList(input);
  }

  sortBadgeCategories(): void {
    this.lstBadgeCategories.items.sort((a, b) => {
      const orderA = a.order || 0;
      const orderB = b.order || 0;
      return orderA - orderB;
    });
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  onBadgeSearch() {
    const searchValue = this.form.get('searchInput').value.toLowerCase();
    this.filterDisplayedBadges(searchValue);
  }

  filterDisplayedBadges(searchValue: string) {
    this.displayedBadgeSelectionList = this.allBadgeSelectionList.filter(
      badge =>
        badge.categoryId === this.selectedBadgeCategoryId &&
        (!searchValue || badge.badgeName.toLowerCase().includes(searchValue.toLowerCase()))
    );
    this.applyUserSelections();
    this.noBadgesFound = this.displayedBadgeSelectionList.length === 0;
  }

  handleBadgeCategorySelect(id: string) {
    if (id) {
      this.selectedBadgeCategoryId = id;
      this.form.get('searchInput').setValue('');
      this.displayBadgesFromCategory(id);
    }
  }

  displayBadgesFromCategory(id: string) {
    this.loading = true;
    this.badgeService.getBadgesFromCategoryId(id).subscribe({
      next: (result: BadgesDto[]) => {
        const newBadges = result
          .filter(badge => badge.isActive)
          .map(badge => ({
            badgeId: badge.id,
            badgeName: badge.name,
            pilotId: this.pilotBadgeData.pilotId,
            pilotName: this.pilotBadgeData.pilotName,
            hasProgression: badge.hasProgression,
            selected: false,
            isPreSelected: false,
            progressionLevel: badge.hasProgression ? ProgressionLevel.Interested : undefined,
            categoryId: id,
          }));

        // Merge new badges with existing ones, preserving selections
        this.allBadgeSelectionList = this.mergeBadgeLists(this.allBadgeSelectionList, newBadges);
        this.filterDisplayedBadges(this.form.get('searchInput').value);
        this.loading = false;
      },
      error: error => {
        console.log(error);
        this.loading = false;
        this.noBadgesFound = true;
      },
    });
  }

  mergeBadgeLists(
    existingList: BadgeSelectionDto[],
    newList: BadgeSelectionDto[]
  ): BadgeSelectionDto[] {
    const mergedList = [...existingList];
    newList.forEach(newBadge => {
      const existingBadge = mergedList.find(badge => badge.badgeId === newBadge.badgeId);
      if (existingBadge) {
        existingBadge.badgeName = newBadge.badgeName;
        existingBadge.hasProgression = newBadge.hasProgression;
        existingBadge.categoryId = newBadge.categoryId;
      } else {
        mergedList.push(newBadge);
      }
    });
    return mergedList;
  }

  onClickAssign(): void {
    this.dialogRef.close(this.userSelections);
  }

  onBadgeSelectionChange(badge: BadgeSelectionDto): void {
    const index = this.userSelections.findIndex(b => b.badgeId === badge.badgeId);
    if (index !== -1) {
      if (badge.selected || badge.isPreSelected) {
        this.userSelections[index] = { ...badge };
      } else {
        this.userSelections.splice(index, 1);
      }
    } else if (badge.selected) {
      this.userSelections.push({ ...badge });
    }

    const allIndex = this.allBadgeSelectionList.findIndex(b => b.badgeId === badge.badgeId);
    if (allIndex !== -1) {
      this.allBadgeSelectionList[allIndex] = { ...badge };
    }

    this.checkForChanges();
  }

  applyUserSelections(): void {
    this.displayedBadgeSelectionList.forEach(badge => {
      const userSelection = this.userSelections.find(b => b.badgeId === badge.badgeId);
      if (userSelection) {
        badge.selected = true;
        badge.progressionLevel = userSelection.progressionLevel;
        badge.isPreSelected = userSelection.isPreSelected;
      } else {
        badge.selected = false;
        badge.progressionLevel = badge.hasProgression ? ProgressionLevel.Interested : undefined;
        badge.isPreSelected = false;
      }
    });
  }

  checkForChanges(): void {
    const currentSelections = JSON.stringify(this.userSelections);
    const initialSelections = JSON.stringify(this.initialUserSelections);
    this.hasChanges = currentSelections !== initialSelections;
  }
}
