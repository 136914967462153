<div class="header d-flex justify-content-between align-items-center">
    <h3 class="title mb-0">
      <span (click)="goBack()" role="button">Deliverables</span>
      <span class="fw-bold" *ngIf="selectedDeliverable">/ {{ selectedDeliverable }}</span>
    </h3>
    <button
      *ngIf="allowDeliverableAddModifyAction"
      class="btn btn-primary button-text"
      (click)="addDeliverable()"
    >
      <span class="material-symbols-rounded">add</span> Add / Modify
    </button>
  </div>
  
  <ngx-datatable
    class="flg-grid-table"
    *ngIf="!selectedDeliverable"
    [rows]="deliverables"
    [columns]="deliverableColumns"
    [scrollbarH]="true"
    [headerHeight]="45"
    [footerHeight]="55"
    [rowHeight]="45"
    default
  >
    <ngx-datatable-column name="Deliverable" prop="deliverableName">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.deliverableName }}
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column name="Product" prop="product">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.productName }}
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column name="Package" prop="package">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.packageName }}
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column name="Actions">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="flg-grid-table-actions">
          <button
            *ngIf="allowDeliverableTableShowFilesAction"
            type="button"
            class="btn"
            (click)="viewFiles(row)"
          >
            <span class="material-symbols-rounded">folder</span>
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <app-deliverable-files
    *ngIf="folderMissions"
    [missionFilter]="folderMissions"
    [showMapIcon]="false"
  ></app-deliverable-files>
  