import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  CustomerContactsCreateDto,
  CustomerContactsDto,
  CustomerContactsExcelDownloadDto,
  CustomerContactsUpdateDto,
  GetCustomerContactInput,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerContactsService {
  apiName = 'customersService';

  create = (input: CustomerContactsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerContactsDto>(
      {
        method: 'POST',
        url: '/api/customers-service/customer-contact',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/customers-service/customer-contact/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerContactsDto>(
      {
        method: 'GET',
        url: `/api/customers-service/customer-contact/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/customers-service/customer-contact/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetCustomerContactInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerContactsDto>>(
      {
        method: 'GET',
        url: '/api/customers-service/customer-contact',
        params: {
          filterText: input.filterText,
          customerId: input.customerId,
          contactId: input.contactId,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: CustomerContactsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/customers-service/customer-contact/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: CustomerContactsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerContactsDto>(
      {
        method: 'PUT',
        url: `/api/customers-service/customer-contact/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getCustomerContacts = (customerId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerContactsDto>>(
      {
        method: 'GET',
        url: `/api/customers-service/customer-contact/get-customer-contacts/${customerId}`,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
