export interface Day {
  id: number;
  name: string;
  longName: string;
  selected: boolean;
}

const weekdays: Day[] = [
  {
    id: 0,
    name: 'S',
    longName: 'Sunday',
    selected: false,
  },
  {
    id: 1,
    name: 'M',
    longName: 'Monday',
    selected: false,
  },
  {
    id: 2,
    name: 'T',
    longName: 'Tuesday',
    selected: false,
  },
  {
    id: 3,
    name: 'W',
    longName: 'Wednesday',
    selected: false,
  },
  {
    id: 4,
    name: 'T',
    longName: 'Thursday',
    selected: false,
  },
  {
    id: 5,
    name: 'F',
    longName: 'Friday',
    selected: false,
  },
  {
    id: 6,
    name: 'S',
    longName: 'Saturday',
    selected: false,
  },
];

/**
 * Creates a copy of the weekdays model
 * @returns Day[]
 */
export function buildWeekdayModel(): Day[] {
  return [...weekdays];
}

/**
 * Creates a list of days using the model
 * @param days number[]
 * @returns string[]
 */
export function mapToDays(days: number[]): string[] {
  return weekdays.filter((d: Day) => days.includes(d.id)).map((d: Day) => d.longName);
}
