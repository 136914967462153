import { DirectoryContentRequestInput, DirectoryDescriptorService } from '@volo/abp.ng.file-management/proxy';
import { FilePreviewModel } from '../models/file-preview.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'file-management-preview-app',
  templateUrl: './file-management-preview.component.html',
  providers: [
  ],
})
export class FileManagementPreviewComponent implements OnInit {
  
  public filePreviewItems: Array<FilePreviewModel>;
  private readonly LIMIT_CONTENT = 100;

  constructor(private service: DirectoryDescriptorService) {}

  public ngOnInit(): void {

    let request = {
      filter: '', 
      id: '', 
      sorting: '', 
      skipCount: 0, 
      maxResultCount: this.LIMIT_CONTENT
    } as DirectoryContentRequestInput;

    this.service.getContent(request).subscribe(response => {
      this.filePreviewItems = response.items.map(x => new FilePreviewModel(x.name, x.imagePreviewUrl, x.videoPreviewUrl , x.fileContentPreviewUrl, x.thumbnailUrl, x)).filter(x=> x.previewSupported);
    })
  }

  public handleOnRequestFullScreen(id) {

    const divObj = document.getElementById(id);

    if (divObj.requestFullscreen) {
        divObj.requestFullscreen();
      }
  }
}
