<h5 mat-dialog-title class="flg-modal flg-modal__title" *ngIf="!isEdition">
  {{ 'missionsService::CreateView' | abpLocalization }}
</h5>
<h5 mat-dialog-title class="flg-modal flg-modal__title" *ngIf="isEdition">
  {{ 'missionsService::EditView' | abpLocalization }}
</h5>
<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container p-0">
    <div class="row justify-content-center">
      <form [formGroup]="newViewForm">
        <mat-form-field class="w-100">
          <mat-label>{{ 'missionsService::ViewName' | abpLocalization }}</mat-label>
          <input matInput formControlName="viewName" />
          <mat-error *ngIf="newViewForm.get('viewName').hasError('required')">
            Please enter a view name
          </mat-error>
        </mat-form-field>
        <div class="d-flex align-items-center py-0 mb-3 mt-2" *ngIf="isAdminUser">
          <div id="labels" class="">
            <label>Public view:</label>
          </div>
          <div class="">
            <mat-slide-toggle matInput labelPosition="before" formControlName="general">
            </mat-slide-toggle>
          </div>
          <span class="material-symbols-outlined ms-2" *ngIf="!newViewForm.get('general').value">
            person
          </span>
          <span class="material-symbols-outlined ms-2" *ngIf="newViewForm.get('general').value">
            group
          </span>
          <i class="ms-1 visibility-info" *ngIf="!newViewForm.get('general').value">
            Visible only for you
          </i>
          <i class="ms-1 visibility-info" *ngIf="newViewForm.get('general').value">
            Visible for any user
          </i>
        </div>
        <div
          class="d-flex align-items-center py-0 mb-3"
          *ngIf="currentFilters.statusAndAssignedPermissions"
        >
          <div id="labels" class="">
            <label>Base view:</label>
          </div>
          <div class="d-flex align-items-center flex-wrap ms-1">
            <app-filter-chip
              class="me-1"
              [label]="'By Default'"
              [value]="getBaseViewSelected(currentFilters.statusAndAssignedPermissions)"
              (removeEvent)="removeBaseViewSelected(currentFilters.statusAndAssignedPermissions)"
              [canRemove]="!isEdition && !currentFilters.statusAndAssignedPermissions.allRecords"
              [showLabel]="currentFilters.statusAndAssignedPermissions.allRecords"
            ></app-filter-chip>
          </div>
        </div>
        <div class="d-flex align-items-center py-0 mb-3">
          <div id="labels" class="">
            <label>Filters applied:</label>
          </div>
          <strong *ngIf="areEmptyFilters()" class="ms-1">-</strong>
          <div class="d-flex align-items-center flex-wrap ms-1" *ngIf="!areEmptyFilters()">
            <ng-container *ngFor="let filter of currentFilters | keyvalue; let isLast = last">
              <ng-container
                *ngIf="
                  isCondition(filter.key) && isArray(currentFilters[filter.key]) && filter.value
                "
              >
                <app-filter-chip
                  class="me-1"
                  *ngFor="let item of filter.value; let i = index"
                  [label]="getDisplayName(filter.key)"
                  [value]="getDisplayValue(filter.key, item)"
                  (removeEvent)="removeCondition(filter.key, i)"
                  [canRemove]="!isEdition"
                ></app-filter-chip>
              </ng-container>
              <ng-container
                *ngIf="
                  isCondition(filter.key) && !isArray(currentFilters[filter.key]) && filter.value
                "
              >
                <app-filter-chip
                  class="me-1"
                  [label]="getDisplayName(filter.key)"
                  [value]="filter.value"
                  (removeEvent)="removeSingleCondition(filter.key)"
                  [canRemove]="!isEdition"
                ></app-filter-chip>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="d-flex align-items-center py-0 mb-3" *ngIf="currentFilters.sorting">
          <div id="labels" class="">
            <label>Sorting criteria:</label>
          </div>
          <div class="d-flex align-items-center flex-wrap">
            <app-filter-chip
              class="me-1"
              [label]="getSortingDisplayName(currentFilters.sorting)"
              [value]="getSortingCriteria(currentFilters.sorting)"
              (removeEvent)="removeSingleCondition('sorting')"
              [canRemove]="!isEdition"
            ></app-filter-chip>
          </div>
        </div>
        <div class="d-flex align-items-center py-0 mb-3" *ngIf="isEdition">
          <div id="labels" class="">
            <label>Column list:</label>
          </div>
          <div class="d-flex align-items-center flex-wrap ms-1">
            <app-filter-chip
              *ngFor="let column of currentFilters.columns"
              class="me-1"
              [label]="'missionsService::' + column | abpLocalization"
              [value]="'missionsService::' + column | abpLocalization"
              [canRemove]="!isEdition"
              [showLabel]="false"
            ></app-filter-chip>
          </div>
        </div>

        <ng-container *ngIf="!isEdition">
          <hr />
          <strong
            >Drag from the left column and drop in the right column the items you want to have in
            the new view</strong
          >
          <div class="d-flex py-0 my-4 w-100" cdkDropListGroup>
            <div class="column-container">
              <label class="mb-2">Available Columns</label>
              <ng-scrollbar visibility="hover" style="height: 500px" [visibility]="'always'">
                <div
                  cdkDropList
                  [cdkDropListData]="availableColumns"
                  class="drag-list"
                  (cdkDropListDropped)="drop($event)"
                >
                  <div
                    class="drag-box no-extra-space"
                    cdkDrag
                    *ngFor="let item of availableColumns"
                    [matTooltip]="'Drag to the right to add'"
                  >
                    {{ 'missionsService::' + item | abpLocalization }}
                  </div>
                </div>
              </ng-scrollbar>
            </div>
            <div class="drag-indicator text-center">
              <span class="material-symbols-outlined"> drag_indicator </span>
            </div>

            <div class="column-container">
              <label class="mb-2 main-color">New View</label>
              <ng-scrollbar visibility="hover" style="height: 500px" [visibility]="'always'">
                <div
                  cdkDropList
                  [cdkDropListData]="selectedColumns"
                  class="drag-list"
                  (cdkDropListDropped)="drop($event)"
                >
                  <div
                    class="drag-box"
                    [class.isLast]="isLast"
                    cdkDrag
                    *ngFor="let item of selectedColumns; let isLast = last"
                    [matTooltip]="'Drag to the left to remove'"
                  >
                    {{ 'missionsService::' + item | abpLocalization }}
                  </div>
                </div>
              </ng-scrollbar>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <div class="d-flex me-3">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="handleOnSave()"
      [disabled]="!isValid()"
      [class.disabled-button]="!isValid()"
    >
      Save
    </button>
  </div>
</mat-dialog-actions>
