import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { LocationContactsForm } from '../../models/site-location-contacts-form.model';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { MissionClientsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/relationals';
import { MissionLocationContactsDataDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import {
  ContactMethodsDto,
  ContactTypesDto,
  GetContactMethodInput,
  GetLevelCommunicationInput,
  LevelCommunicationsDto,
  enumState,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import {
  ContactMethodsService,
  ContactTypesService,
  LevelCommunicationsService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { ColumnAction } from 'projects/flyguys/src/app/components/columns/components/column-actions/column-actions.component';
import {
  ContactsDto,
  MissionLocationContactCreateDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { ContactsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { ConfirmDialogComponent } from 'projects/flyguys/src/app/components/common/confirm-dialog/confirm.dialog.component';
import { MissionLocationContactService } from '../../../../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals/mission-location-contact.service';
import { CustomerContactsDto } from '../../../../../../../../customers-service/src/lib/proxy/customers-service/basics';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {
  CustomerContactsService,
  CustomersService,
} from '../../../../../../../../customers-service/src/lib/proxy/customers-service/controllers/basics';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterService } from '@abp/ng.theme.shared';
import { getPendingControls } from '@flyguys/components';

const requiredControlsNames = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  state: 'State',
};

@Component({
  selector: 'app-site-location-contacts',
  templateUrl: './site-location-contacts.component.html',
  styleUrls: ['./site-location-contacts.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
})
export class SiteLocationContactsComponent implements OnInit, OnDestroy {
  formLocationContactModal: FormGroup;
  searchTerm: string = '';

  model: LocationContactsForm;

  data: PagedResultDto<MissionLocationContactsDataDto> = {
    items: [],
    totalCount: 0,
  };

  levelCommunication: PagedResultDto<LevelCommunicationsDto> = {
    items: [],
    totalCount: 0,
  };

  contactMethod: PagedResultDto<ContactMethodsDto> = {
    items: [],
    totalCount: 0,
  };

  contactType: PagedResultDto<ContactTypesDto> = {
    items: [],
    totalCount: 0,
  };

  selected?: MissionLocationContactsDataDto;

  isModalBusy: boolean = false;

  isModalOpen = false;

  spinnerMessage: string;
  readonly loadingMessage: string = 'Loading Contacts...';
  readonly savingMessage: string = 'Saving Contact...';

  selectedContactMethodId?: string;
  selectedlevelCommunicationId?: string;

  private subs: Subscription;

  showHintEditContactEmail: boolean = false;

  private emailWhileEditing: string = '';

  constructor(
    public dialogRef: MatDialogRef<SiteLocationContactsComponent>,
    @Inject(MAT_DIALOG_DATA) public dataMat: LocationContactsForm,
    public readonly list: ListService,
    private missionClientService: MissionClientsService,
    public readonly levelCommunicationService: LevelCommunicationsService,
    public readonly contactMethodService: ContactMethodsService,
    public readonly contactTypeService: ContactTypesService,
    public readonly missionLocationContactService: MissionLocationContactService,
    public readonly customerContactsService: CustomerContactsService,
    private contactsService: ContactsService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private toaster: ToasterService,
    public readonly customerService: CustomersService
  ) {
    this.model = dataMat;

    this.subs = new Subscription();

    this.subs.add(
      this.list.isLoading$.subscribe({
        next: (isLoading: boolean) => {
          this.spinnerMessage = this.loadingMessage;

          if (isLoading) {
            this.spinner.show();
          } else {
            this.spinner.hide();
          }
        },
        error: _ => this.spinner.hide(),
      })
    );

    this.formLocationContactModal = this.fb.group({
      contactFromId: [null],
      firstName: [null, [Validators.required, Validators.maxLength(100)]],
      lastName: [null, [Validators.required, Validators.maxLength(100)]],
      phone: [null],
      email: [null, [Validators.email], [this.emailExistValidator()]],
      levelCommunicationId: null,
      contactMethodId: null,
      state: ['1', [Validators.required]],
      shareData: [false],
    });
  }

  ngOnInit() {
    this.getMissionLocationContacts();
    this.getContactMethodValues();
    this.getLevelCommunicationValues();
    this.getContactTypeValues();
    this.getContacts();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getMissionLocationContacts() {
    const getData = (query: ABP.PageQueryParams) =>
      this.missionClientService.getLocationContacts(this.model.missionId);

    const setDataTmp = (list: PagedResultDto<MissionLocationContactsDataDto>) => this.setData(list);

    this.list.hookToQuery(getData).subscribe(res => {
      setDataTmp(res);
    });
  }

  private setData(list: PagedResultDto<MissionLocationContactsDataDto>) {
    this.data = list;

    this.data.items.forEach(x => {
      let levelCommunication = this.levelCommunication.items.find(
        levelCommunication => levelCommunication.id == x.levelCommunication
      )?.description;

      let contactMethod = this.contactMethod.items.find(
        contactMethod => contactMethod.id == x.contactMethod
      )?.description;

      x.contactMethodDescription = contactMethod;
      x.levelCommunicationDescription = levelCommunication;
    });
  }

  columnActions(record: MissionLocationContactsDataDto) {
    let columnActions: ColumnAction[] = [
      {
        actionIcon: 'bi bi-pencil',
        abpPermission: 'missionsService.Contact.Edit && General.Common.Edit',
        action: { callAction: () => this.updateContact(record) },
      },
      {
        actionIcon: 'bi bi-trash',
        abpPermission: 'missionsService.Contact.Delete && General.Common.Delete',
        action: { callAction: () => this.deleteContact(record) },
      },
    ];

    return columnActions;
  }

  buildForm() {
    const {
      firstName,
      phone,
      email,
      contactMethod,
      shareData,
      levelCommunication,
      lastName,
      contactTypeId,
    } = this.selected || {};

    this.selectedContactMethodId = contactMethod ?? undefined;
    this.selectedlevelCommunicationId = levelCommunication ?? undefined;

    this.formLocationContactModal.reset();
    this.formLocationContactModal.updateValueAndValidity();

    this.formLocationContactModal.setValue({
      contactFromId: contactTypeId ?? null,
      firstName: firstName ?? null,
      lastName: lastName ?? null,
      phone: phone ?? null,
      email: email ?? null,
      levelCommunicationId: this.selectedlevelCommunicationId ?? null,
      contactMethodId: this.selectedContactMethodId ?? null,
      state: '1',
      shareData: shareData ?? false,
    });

    if (this.selected?.userId) {
      this.formLocationContactModal.get('email').disable();
    } else {
      this.formLocationContactModal.get('email').enable();
    }
  }

  showForm() {
    this.buildForm();
    this.isModalOpen = true;
  }

  hideForm() {
    this.emailWhileEditing = '';
    this.isModalOpen = false;
    this.formLocationContactModal.reset();
  }

  submitForm() {
    const formValue = this.formLocationContactModal.getRawValue();

    formValue.levelCommunicationDescription = this.levelCommunication.items.find(
      levelCommunication => levelCommunication.id == formValue.levelCommunicationId
    )?.description;

    formValue.contactMethodDescription = this.contactMethod.items.find(
      contactMethod => contactMethod.id == formValue.contactMethodId
    )?.description;

    formValue.contactTypeDescription = this.contactType.items.find(
      contactType => contactType.id == formValue.contactTypeId
    )?.description;

    const request = this.selected
      ? this.contactsService.update(this.selected.contactId, formValue)
      : this.contactsService.create(formValue);

    this.isModalBusy = true;
    this.spinnerMessage = this.savingMessage;
    this.spinner.show();

    request.pipe(tap(contact => this.finishContactOperation(contact))).subscribe(this.list.get);
  }

  finishContactOperation(contact: ContactsDto) {
    this.isModalOpen = false;
    this.selectedContactMethodId = undefined;
    this.selectedlevelCommunicationId = undefined;
    this.formLocationContactModal.reset();
    if (!this.selected) {
      let customerContactDto: CustomerContactsDto = {
        contactId: contact.id,
        customerId: this.model.customerId,
        state: enumState.Enabled,
      };

      const request = this.customerContactsService.create(customerContactDto);

      request
        .pipe(
          finalize(() => {
            this.isModalBusy = false;
            this.spinner.hide();
          })
        )
        .subscribe({
          next: (response: CustomerContactsDto) => {
            let missionLocationContactCreateDto: MissionLocationContactCreateDto = {
              contactId: contact.id,
              locationId: this.model.locationId,
              missionId: this.model.missionId,
            };

            this.missionLocationContactService.create(missionLocationContactCreateDto).subscribe({
              next: () => {
                this.getMissionLocationContacts();
                this.getContacts();
                this.toaster.success(
                  `Contact: '${contact.firstName} ${contact.lastName}' added successfully to mission location.`
                );
              },
              error: err => {
                console.error('Error adding contact to mission location:', err);
                this.toaster.error('There was an error adding this contact to mission location.');
              },
            });
          },
          error: error => console.error('Error creating customer contact:', error),
        });
    } else {
      this.isModalBusy = false;
      this.spinner.hide();
    }
  }

  create() {
    this.selected = undefined;
    this.showHintEditContactEmail = false;
    this.showForm();
  }

  updateContact(record: MissionLocationContactsDataDto) {
    this.emailWhileEditing = record.email;
    this.selected = record;
    this.showHintEditContactEmail = true;
    this.showForm();
  }

  deleteContact(record: MissionLocationContactsDataDto) {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete this contact?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      width: '400px',
    });

    this.subs.add(
      ref.afterClosed().subscribe({
        next: (state: boolean) => {
          if (state === true) {
            this.spinner.show();

            this.missionLocationContactService
              .delete(record.missionLocationContactId)
              .pipe(finalize(() => this.spinner.hide()))
              .subscribe({
                next: () => {
                  this.list.get();
                },
                error: error => {
                  console.error('Error deleting mission location contact:', error);
                  this.spinner.hide();
                },
              });
          }
        },
      })
    );
  }

  private getLevelCommunicationValues(): void {
    const query = {} as ABP.PageQueryParams;
    const levelCommunicationFilter = { state: enumState.Enabled } as GetLevelCommunicationInput;

    this.levelCommunicationService
      .getList({
        ...query,
        ...levelCommunicationFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.levelCommunication = res;
      });
  }

  private getContactMethodValues(): void {
    const query = {} as ABP.PageQueryParams;
    const contactMethodFilter = { state: enumState.Enabled } as GetContactMethodInput;

    this.contactMethodService
      .getList({
        ...query,
        ...contactMethodFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contactMethod = res;
      });
  }

  private getContactTypeValues(): void {
    const query = {} as ABP.PageQueryParams;
    const contactTypeFilter = { state: enumState.Enabled } as GetContactMethodInput;

    this.contactTypeService
      .getList({
        ...query,
        ...contactTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contactType = res;
      });
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.isModalBusy = false;
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  filteredContacts: ContactsDto[] = [];
  contacts: ContactsDto[] = [];

  private getContacts() {
    this.customerContactsService.getCustomerContacts(this.model.customerId).subscribe({
      next: (response: PagedResultDto<CustomerContactsDto>) => {
        const customerContactsList = response.items;

        const contactIds = customerContactsList.map(contact => contact.contactId);

        this.contactsService.getContactsByListOfIds(contactIds).subscribe({
          next: (data: ContactsDto[]) => (this.contacts = data),
          error: err => console.error('Error fetching contacts:', err),
        });
      },
      error: err => console.log(err),
    });

    this.filteredContacts = [...this.contacts];
  }

  filterContacts(searchValue: string): void {
    if (typeof searchValue === 'string') {
      this.filteredContacts = this.contacts.filter(
        contact =>
          contact.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          contact.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
          (contact.phone && contact.phone.toLowerCase().includes(searchValue.toLowerCase())) ||
          (contact.email && contact.email.toLowerCase().includes(searchValue.toLowerCase())) ||
          (((contact.firstName === null ? '' : contact.firstName).toLowerCase() + ' ' + (contact.lastName === null ? '' : contact.lastName).toLowerCase()).includes(searchValue.toLowerCase()))
      );
    }
  }

  onContactSelected(event: MatAutocompleteSelectedEvent) {
    const selectedContact: ContactsDto = event.option.value;

    // We do this in order to force the UI to refresh and clear the selected value
    setTimeout(() => {
      this.searchTerm = '';
    });

    // Check if the contact already exists in the siteContacts
    if (this.data.items.some(contact => contact.contactId === selectedContact.id)) {
      this.snackBar.open('This contact is already added.', 'OK', { duration: 3000 });
      return;
    }

    let missionLocationContactCreateDto: MissionLocationContactCreateDto = {
      contactId: selectedContact.id,
      locationId: this.model.locationId,
      missionId: this.model.missionId,
    };

    this.missionLocationContactService.create(missionLocationContactCreateDto).subscribe({
      next: () => {
        this.getMissionLocationContacts();
        this.toaster.success(
          `Contact: '${selectedContact.firstName} ${selectedContact.lastName}' added successfully.`
        );
      },
      error: err => {
        console.error('Error adding contact to site:', err);
        this.toaster.error('There was an error adding this contact.');
      },
    });
  }

  openContactsAutocompletePanel(autocompleteTrigger: MatAutocompleteTrigger) {
    if (!autocompleteTrigger.panelOpen) {
      setTimeout(() => {
        this.filteredContacts = [...this.contacts];
        autocompleteTrigger.openPanel();
      }, 500);
    }
  }

  emailExistValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // Ignore validation if email is being edited and it's the same
      if (!control.value || control.value === this.emailWhileEditing) {
        return of(null);
      }

      return this.customerService
        .checkEmailExists(control.value.trim())
        .pipe(map(exists => (exists ? { emailExists: true } : null)));
    };
  }

  getPendingControls() {
    return getPendingControls(this.formLocationContactModal, requiredControlsNames);
  }
}
