<abp-page
  [title]="'FileManagement::Menu:FileManagement' | abpLocalization"
  [toolbar]="currentContent"
>
  <div class="card">
    <div class="card-body z-index-0">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 col">
          <div class="p-lg-3 p-md-0 mb-4">
            <abp-file-management-buttons></abp-file-management-buttons>
            <abp-file-management-directory-tree></abp-file-management-directory-tree>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-9 col">
          <div class="p-lg-3 p-md-0">
            <abp-file-management-folder-panel
              (contentUpdate)="currentContent = $event"
            ></abp-file-management-folder-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</abp-page>
