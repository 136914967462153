import { Injectable } from '@angular/core';
import { Rest, RestService } from '@abp/ng.core';
import { MissionChangeLogDto } from '../pilot-sourcing/models/mission-change-log-dto';

@Injectable({
  providedIn: 'root',
})
export class MissionChangeLogService {
  apiName = 'missionsService';

  constructor(private restService: RestService) {}

  getChangesByMissionAsync = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Array<MissionChangeLogDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-logs/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );
}
