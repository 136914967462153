<div class="container">
  <div class="row justify-content-center">
    <div id="abp-modal-header" class="modal-header abp-modal-header ng-star-inserted w-100">
      <h2 class="pt-2 pb-2 ps-3 pe-3 mb-0">
        {{ selectedContact.name }} {{ selectedContact.surname }}
      </h2>
      <button
        id="abp-modal-close-button"
        type="button"
        aria-label="Close"
        class="btn-sm btn-close d-flex justify-content-end align-items-center m-2 btn-close-site"
        (click)="onClickClose()"
      ></button>
    </div>
    <mat-dialog-content class="">
      <div class="col-12 col-md-12 col-lg-12 d-flex align-items-stretch">
        <div class="card w-100">
          <div *ngIf="selectedContact.userId" class="card-body p-1 p-md-2 p-lg-3">
            <ng-scrollbar visibility="hover">
              <div #chatBox id="chat-conversation-wrapper" class="chat-box mb-2">
                <div class="chat-box-container" id="chat-conversation">
                  <ng-container
                    *ngFor="
                      let message of selectedContactMessages;
                      let i = index;
                      trackBy: trackByMessageDate
                    "
                  >
                    <div
                      *ngIf="selectedContactMessages.length - unreadMessageCount === i"
                      class="row justify-content-center unread-message-count-badge-wrapper"
                    >
                      <h3>
                        <span class="badge badge-info">
                          <ng-container *ngIf="unreadMessageCount === 1; else moreThanOne">
                            {{ 'Chat::YouHaveAnUnreadMessage' | abpLocalization }}
                          </ng-container>

                          <ng-template #moreThanOne>
                            {{
                              'Chat::YouHave{0}UnreadMessages'
                                | abpLocalization : '' + unreadMessageCount
                            }}
                          </ng-template>
                        </span>
                      </h3>
                    </div>
                    <div
                      *ngIf="{ isSender: message.side === chatMessageSide.Sender } as data"
                      class="media w-75 mw-65 w-lp-auto mb-2"
                      [class.ms-auto]="data.isSender"
                    >
                      <div class="media-body position-relative">
                        <div
                          class="media-container py-2 px-2 py-lg-2 px-lg-3 mb-1 {{
                            data.isSender ? 'media-container__sender' : 'media-container__reciever'
                          }}"
                        >
                          <p
                            class="message-text text-small mb-0 lh-base {{
                              data.isSender ? 'text-white' : 'text-dark'
                            }}"
                            [innerHTML]="message.message"
                          ></p>
                        </div>
                        <p class="message-date m-0 {{ data.isSender ? 'right' : 'left' }}">
                          {{
                            getDateInLocalTime(message.messageDate)
                              | date : getDateFormat(message.messageDate)
                          }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-scrollbar>

            <!-- Typing area -->
            <div *ngIf="selectedContact?.userId" id="send-message-form" class="bg-light m-0">
              <div class="ps-3 pe-3 pt-3">
                <textarea
                  id="chat-message-box"
                  name="message"
                  type="text"
                  [placeholder]="'Chat::TypeMessage' | abpLocalization"
                  class="form-control rounded bg-white"
                  [(ngModel)]="message"
                  (keydown.enter)="sendWithEnter($event)"
                ></textarea>
              </div>
              <div class="d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center form-check mt-2">
                  <input
                    type="checkbox"
                    class="form-check-input border shadow-sm me-1"
                    id="send-on-enter"
                    [(ngModel)]="sendOnEnter"
                  />
                  <label class="form-check-label" for="send-on-enter">{{
                    'Chat::SendOnEnter' | abpLocalization
                  }}</label>
                </div>

                <button
                  [disabled]="!message"
                  (click)="send()"
                  id="send-message-button"
                  type="button"
                  class="btn btn-primary px-3"
                >
                  {{ 'Chat::Send' | abpLocalization }}
                  <i
                    *ngIf="!loading; else loadingIcon"
                    class="fa fa-paper-plane ms-2"
                    aria-hidden="true"
                  ></i>

                  <ng-template #loadingIcon>
                    <i class="fas fa-spinner fa-spin ms-2" aria-hidden="true"></i>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="!selectedContact.userId" class="card-body pt-5 pb-5 text-center">
            <i class="fa fa-commenting-o fs-2" aria-hidden="true"></i>
            <p class="pb-3">{{ 'Chat::NoMessageYet' | abpLocalization }}</p>
            <p>{{ 'Chat::NoMessageYetMessage' | abpLocalization }}</p>
            <button (click)="startConversation()" type="button" class="btn btn-primary">
              {{ 'Chat::StartConversation' | abpLocalization }}
            </button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</div>
