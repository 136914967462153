<div
  *ngIf="orderDetails.packagesSelected.length > 0 || orderDetails.productsSelected?.length > 0"
  class="order-details"
>
  <h6>Deliverables on this Order</h6>

  <ul class="order-details order-details__packages">
    <li *ngFor="let pk of orderDetails.packagesSelected">
      <p class="order-details order-details__item">
        <span class="order-details order-details__item--name"> {{ pk.packageName }}</span>
        <span *ngIf="pk.quantity > 1">x {{ pk.quantity }}</span>
        <span>$ {{ pk.packageSubtotal | number : '1.2-2' }}</span>
      </p>

      <ul class="order-details order-details__packages order-details__packages--deliverables">
        <li *ngFor="let dv of pk.deliverables">
          <p class="order-details order-details__item order-details__item--nested">
            <span class="order-details order-details__item--nested order-details__item--name">{{
              dv.productName
            }}</span>
            <span>x {{ dv.quantity }}</span>
          </p>
        </li>
      </ul>
    </li>
  </ul>

  <div *ngIf="orderDetails.productsSelected.length > 0" class="mt-3">
    <ul class="order-details order-details__packages">
      <li>
        <p class="order-details order-details__item order-details__item--larger">
          <span class="order-details order-details__item--name"> Add-ons</span>
        </p>
      </li>
      <li *ngFor="let ad of orderDetails.productsSelected">
        <p class="order-details order-details__item order-details__item--nested">
          <span class="order-details order-details__item--name"> {{ ad.productName }}</span>
          <span *ngIf="ad.quantity > 1">x {{ ad.quantity }}</span>
          <span>$ {{ ad.subTotal | number : '1.2-2' }}</span>
        </p>
      </li>
    </ul>
  </div>

  <div class="mt-3">
    <ul class="order-details order-details__packages">
      <li>
        <p class="order-details order-details__item order-details__item--larger">
          <span class="order-details order-details__item--name"> Total:</span>
          <span>$ {{ orderDetails.total | number : '1.2-2' }}</span>
        </p>
      </li>
    </ul>
  </div>
</div>
