<div id="folder-root">
  <div class="d-flex align-items-center">
    <div class="me-1 clickable" *ngIf="root.Child?.length > 0; else space">
      <i aria-hidden="true" class="far"
        [class.fa-plus-square]="!root.displayChildren"
        [class.fa-minus-square]="root.displayChildren"
        (click)="toggleFolder(root)"
      >
      </i>
    </div>
    <div class="align-middle">{{root.FolderName}}</div>
    <div *ngIf="editable">
      <span class="material-symbols-outlined clickable" mat-button [matMenuTriggerFor]="menu">arrow_drop_down</span>
      <mat-menu #menu="matMenu">
        <input type="button" (click)="openInput(root)" class="inputOption" value="{{'missionsService::CreateFolder' | abpLocalization}}" mat-menu-item>
        <input type="button" (click)="deleteFolder(root)" class="inputOption" value="{{'missionsService::CleanFolder' | abpLocalization}}" mat-menu-item>
      </mat-menu>
    </div>
  </div>
  <div class="parent-container" *ngIf="root.Child">
    <ng-container *ngTemplateOutlet="Recursion; context:{ parentFolder: root }"></ng-container>
  </div>
</div>

<ng-template #Recursion let-parentFolder="parentFolder">
  <div class="ps-2">
    <div class="d-flex align-items-center" *ngIf="parentFolder.addingFolder">
      <div class="input-group input-group-sm">
        <input type="text" class="form-control" [(ngModel)]="folderName">
      </div>
      <div class="ms-1 clickable">
        <i class="fa fa-check" aria-hidden="true" (click)="addFolder(parentFolder, folderName)"></i>
      </div>
      <div class="ms-1 clickable">
        <i class="fa fa-times" aria-hidden="true" (click)="closeInput(parentFolder)"></i>
      </div>
    </div>
    <ng-container *ngIf="parentFolder.displayChildren">
      <div *ngFor="let folder of parentFolder.Child">
        <div class="d-flex align-items-center">
          <div class="me-1 clickable" *ngIf="folder.Child?.length > 0 ; else space">
            <i aria-hidden="true" class="far"
              [class.fa-plus-square]="!folder.displayChildren"
              [class.fa-minus-square]="folder.displayChildren"
              (click)="toggleFolder(folder)"
            >
            </i>
          </div>
          <div class="align-middle">{{folder.FolderName}}</div>
          <div *ngIf="editable">
            <span class="material-symbols-outlined clickable" mat-button [matMenuTriggerFor]="menu">arrow_drop_down</span>
            <mat-menu #menu="matMenu">
              <input type="button" (click)="openInput(folder)" class="inputOption" value="{{'missionsService::CreateFolder' | abpLocalization}}" mat-menu-item>
              <input type="button" (click)="deleteFolder(folder, parentFolder)" class="inputOption" value="{{'missionsService::DeleteFolder' | abpLocalization}}" mat-menu-item>
            </mat-menu>
          </div>
        </div>
        <div class="parent-container" *ngIf="folder.Child">
          <ng-container *ngTemplateOutlet="Recursion; context:{ parentFolder: folder }"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #space>
  <div class="me-1">
    <i class="far fa-square" aria-hidden="true"></i>
  </div>
</ng-template>