import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MissionFlowService } from 'projects/flyguys/src/app/services/mission-flow.service';
import { combineLatest, filter, interval, Observable, switchMap } from 'rxjs';
import { TransferFilesPreStartResponseDto } from 'projects/flyguys/src/app/pilot-sourcing/models/transfer-files-pre-start-response-dto';
import { TransferFilesStatusResponseDto } from 'projects/flyguys/src/app/pilot-sourcing/models/transfer-files-status-response-dto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { S3FileDto } from 'projects/flyguys/src/app/pilot-sourcing/models/s3-files-dto';

@Component({
  selector: 'lib-transfer-files-status-modal',
  templateUrl: './transfer-files-status-modal.component.html',
  styleUrls: ['./transfer-files-status-modal.component.scss'],
})
export class TransferFilesStatusModalComponent implements OnInit, OnDestroy {
  private readonly loadingDelay: number = 3000;
  private readonly intervalRefresh: number = 30000;

  missionId: string;
  correlationId: string;
  transferFilesPreStartResponse: TransferFilesPreStartResponseDto;
  transferFilesStatusResponse: TransferFilesStatusResponseDto;

  showFileList: boolean;
  fileList: PagedResultDto<S3FileDto>;

  pageNumber = 0;
  progressValue = 0;
  interval: number;
  clientMaxResultCount = 10;

  constructor(
    public dialogRef: MatDialogRef<TransferFilesStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { missionId: string; correlationId: string },
    private missionFlowService: MissionFlowService,
    private snackBar: MatSnackBar,
    private confirmation: ConfirmationService,
    public readonly list: ListService,
  ) {
    this.missionId = data.missionId;
    this.correlationId = data.correlationId;
  }

  ngOnDestroy(): void {
    if (this.interval) clearInterval(this.interval);
  }

  ngOnInit() {
    this.loadTransferStatus();
  }

  loadTransferStatus(): void {
    combineLatest([this.getPreTransferFilesPreStart(), this.getTransferFilesStatus()]).subscribe({
      next: ([preTransferResponse, transferStatusResponse]) => {
        this.transferFilesPreStartResponse = preTransferResponse;

        this.transferFilesStatusResponse = transferStatusResponse;

        if (this.transferFilesStatusResponse.inQueue) {
          if (this.transferFilesStatusResponse.inProgress) {
            this.progressValue = this.calculateProgressValue(
              this.transferFilesStatusResponse.totalData,
              this.transferFilesStatusResponse.totalDataAlreadyTransferred,
            );
          }

          this.autoRefreshProgress();
        }
      },
      error: err => console.error('Unable to get transfer files information:\n', err),
    });
  }

  handleOnTransferFiles(): void {
    combineLatest([this.getPreTransferFilesPreStart(), this.getTransferFilesStatus()]).subscribe({
      next: ([preTransferResponse, transferStatusResponse]) => {
        if (preTransferResponse.totalFiles <= 0) {
          this.snackBar.open('There are no files to transfer in the external application.', 'OK', {
            duration: this.loadingDelay,
          });

          return;
        }

        if (transferStatusResponse.inProgress) {
          this.snackBar.open('File transfer process is already in progress.', 'OK', {
            duration: this.loadingDelay,
          });

          this.loadTransferStatus();

          return;
        }

        this.dialogRef.close(preTransferResponse);
      },
      error: err => console.error('Unable to get transfer files information:\n', err),
    });
  }

  handleOnViewTransferredFiles(show: boolean, pageSize?: number, pageNumber?: number) {
    this.showFileList = show;

    if (!show) return;

    if (!this.correlationId) {
      this.snackBar.open('Correlation id for this mission is invalid.', 'OK', {
        duration: this.loadingDelay,
      });

      return;
    }

    let data = {
      correlationId: this.correlationId || 'ECB2A475-62CF-B652-D489-3A110821D09F',
      pageSize: pageSize || 20,
      pageNumber: pageNumber || 1,
    };

    this.missionFlowService.listS3Items(data).subscribe({
      next: res => {
        this.fileList = res;
      },
      error: err => console.log(err),
    });
  }

  onPaginationChange(event: any) {
    this.handleOnViewTransferredFiles(true, event, 1);
  }

  setPage(pageInfo: any) {
    this.handleOnViewTransferredFiles(true, pageInfo.pageSize, pageInfo.offset + 1);
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  formatBytes(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

    return `${formattedSize} ${sizes[i]}`;
  }

  private autoRefreshProgress() {
    this.interval = window.setInterval(() => {
      this.getTransferFilesStatus().subscribe({
        next: res => {
          this.transferFilesStatusResponse = res;

          if (this.transferFilesStatusResponse.inProgress) {
            this.progressValue = this.calculateProgressValue(
              this.transferFilesStatusResponse.totalData,
              this.transferFilesStatusResponse.totalDataAlreadyTransferred,
            );
          }

          if (
            this.transferFilesStatusResponse.isFinished &&
            this.transferFilesStatusResponse.finishDate
          ) {
            clearInterval(this.interval);
          }
        },
        error: err => console.error('Unable to get transfer files information:\n', err),
      });
    }, this.intervalRefresh);
  }

  private getPreTransferFilesPreStart(): Observable<TransferFilesPreStartResponseDto> {
    return this.missionFlowService.preTransferMissionFiles(this.missionId);
  }

  private getTransferFilesStatus(): Observable<TransferFilesStatusResponseDto> {
    return this.missionFlowService.getTransferFilesStatus(this.missionId);
  }

  private calculateProgressValue(total: number, current: number): number {
    if (!current || !total) return 0;

    return Math.round((current * 100) / total);
  }
}
