<header
  class="header-area bg-white"
  stickyClass="sticky-box-shadow"
  ngStickyNav
  [class.component-dark-theme]="themeService.isDark()"
  [ngClass]="{ active: isToggled }"
>
  <div class="d-flex align-items-center justify-content-between">
    <div class="header-left-side d-flex align-items-center">
      <div class="burger-menu" (click)="toggle()" [ngClass]="{ active: isToggled }">
        <span class="material-symbols-outlined"> menu </span>
      </div>
    </div>
    <ul class="header-right-side d-flex align-items-center t-mt-0 t-mb-0">
      <li *abpPermission="'Chat.Messaging'">
        <a routerLink="/chat" routerLinkActive="active">
          <mat-icon class="t-me-5" color="{{ chatConfigService.unreadMessagesCount ? 'warn' : '' }}"
            >message</mat-icon
          >
          <span *ngIf="chatConfigService.unreadMessagesCount" class="badge badge-primary">{{
            chatConfigService.unreadMessagesCount
          }}</span>
        </a>
      </li>
      <li *ngIf="isLogged()">
        <button
          mat-icon-button
          class="btn p-0 border-none rounded-circle position-relative"
          *ngIf="notifications.length == 0"
        >
          <i class="flaticon-bell-2"></i>
        </button>
        <button
          mat-icon-button
          [matMenuTriggerFor]="notificationMenu"
          *ngIf="notifications.length > 0"
          class="btn p-0 border-none rounded-circle position-relative notification-btn"
        >
          <i class="flaticon-bell-2"></i>
        </button>

        <mat-menu
          class="notifications-popover mat-elevation-z2"
          #notificationMenu="matMenu"
          xPosition="before"
        >
          <ng-template matMenuContent>
            <header class="notifications-popover notifications-popover--header">
              <h4 class="m-0 p-2">Notifications</h4>
            </header>

            <ul class="notifications-popover notifications-popover--body">
              <li
                *ngFor="let notif of notifications | slice: 0 : 5; let i = index"
                [@fadeInAnimation]="'in'"
                [routerLink]="[generateMissionDetailPath(notif)]"
              >
                <div class="notifications-popover notifications-popover--item">
                  <span class="material-symbols-outlined"> text_snippet </span>
                  <p>
                    {{ notif.message }}
                    <time>
                      <span class="material-symbols-outlined"> schedule </span>
                      {{ notif.arguments.date | date }}</time
                    >
                  </p>

                  <button
                    class="d-flex align-items-center bg-transparent border-none p-0"
                    (click)="handleOnRemoveNotification(i, $event)"
                  >
                    <span class="material-symbols-outlined"> close </span>
                  </button>
                </div>
              </li>
            </ul>

            <div class="notifications-popover notifications-popover--button">
              <button mat-flat-button class="no-outline-button" routerLink="/notifications">
                View All >
              </button>
            </div>
          </ng-template>
        </mat-menu>
      </li>

      <li *ngIf="isLogged(); else notLoggedIn">
        <a [matMenuTriggerFor]="profileMenu">
          <mat-icon class="t-me-5">account_circle</mat-icon>
          <span class="fw-semibold">{{ currentUser.userName }}</span>
        </a>
        <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
          <ul class="mb-0">
            <li class="cursor-pointer">
              <a (click)="logout()"> <i class="ri-shut-down-line"></i> Logout </a>
            </li>
          </ul>
        </mat-menu>
      </li>

      <ng-template #notLoggedIn>
        <li>
          <a routerLink="/account">
            <mat-icon class="t-me-5">vpn_key</mat-icon>
            <span>Please Log In</span>
          </a>
        </li>
      </ng-template>
    </ul>
  </div>
</header>
