export class ChatImageDto {
  id: string;
  src: string;
  name: string;

  constructor(initialValues: Partial<ChatImageDto> = {}) {
    if (initialValues) {
      for (const key in initialValues) {
        if (initialValues.hasOwnProperty(key)) {
          this[key] = initialValues[key];
        }
      }
    }
  }
}
