import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-left-side-item',
  templateUrl: './left-side-item.component.html',
  styleUrls: ['./left-side-item.component.scss'],
})
export class LeftSideItemComponent {
  @Input() label: string;
  @Input() content: string | number;
}
