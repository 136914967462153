<div *ngIf="showDays">
  <h3>{{ 'missionsService::Metrics' | abpLocalization }}</h3>

  <div class="d-flex">
    <app-info-card-with-action
      [icon]="'calendar_today'"
      [title]="'Time on current Status'"
      class="w-50 me-2 fixed-height"
      [actionIconVisible]="false"
    >
      <div>
        <app-subtitle-description [subtitle]="statusName" [description]="days + ' days'">
        </app-subtitle-description>
      </div>
    </app-info-card-with-action>
  </div>
</div>

<h3>{{ 'missionsService::Milestones' | abpLocalization }}</h3>
<lib-show-sla-milestones
  *ngIf="showTable"
  [missionId]="missionId"
  [fromMetrics]="true"
></lib-show-sla-milestones>
