import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FormGroupDirective } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject, finalize, takeUntil, tap } from 'rxjs';

import { NgxSpinnerService } from 'ngx-spinner';
import { PagedResultDto } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';

import { NotesCreateDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { NotesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { MissionNoteDataDto } from '../../../pilot-sourcing/models/mission-note-data-dto';
import {
  CategoriesDto,
  DepartamentsDto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';

import { FlyguysGridNoteForm } from './grid-note.form';

@Component({
  selector: 'flg-grid-notes',
  templateUrl: './grid-notes.component.html',
  styleUrls: ['./grid-notes.component.scss'],
})
export class FlyguysGridNotesComponent implements OnInit, OnDestroy {
  @ViewChild('notesModal') modal: TemplateRef<any>;
  @ViewChild(MatExpansionPanel) newNotePanel: MatExpansionPanel;
  @ViewChildren(MatSelect) lists: QueryList<MatSelect>;

  @Input() users: any[];
  @Input() userId: string;
  @Input() missionId: string;
  @Input() missionName: string;
  @Input() categories: CategoriesDto[];
  @Input() departments: DepartamentsDto[];
  @Input() gridView: boolean = true;

  buttonIsLoading = true;
  errorOnLoad = false;

  form: FlyguysGridNoteForm = new FlyguysGridNoteForm();

  private notes: MissionNoteDataDto[] = [];
  private destroy$ = new Subject();

  constructor(
    private notesService: NotesService,
    private toaster: ToasterService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    // Load initial notes payload to have its count available
    this.notesService
      .filter(this.missionId, { pageSize: 50, skipCount: 0 })
      .pipe(
        tap(() => (this.buttonIsLoading = true)),
        finalize(() => {
          this.buttonIsLoading = false;
          this.setAuthor(this.notes);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (notes: PagedResultDto<MissionNoteDataDto>) => {
          this.notes = notes.items;
        },
        error: _ => (this.errorOnLoad = true),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Handles the cancel action on the new note form
   * @param formDirective: FormGroupDirective
   */
  handleCancel(formDirective: FormGroupDirective): void {
    this.newNotePanel.close();
    this.form.reset();
    formDirective.resetForm();
  }

  /**
   * Handles the save action on the new note form
   * @param formDirective: FormGroupDirective
   */
  handleSave(formDirective: FormGroupDirective): void {
    const formObject = this.form.asJSON();

    const note: NotesCreateDto = {
      ...formObject,
      missionId: this.missionId,
      creatorId: this.userId,
      departamentId: formObject?.departmentId,
      state: 1,
    };

    this.spinner.show();

    this.notesService
      .create(note)
      .pipe(
        finalize(() => this.spinner.hide()),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (note: MissionNoteDataDto) => {
          this.toaster.success('Note created');

          this.setAuthor([note]);

          if (this.notes.length) {
            this.notes.unshift(note);
          } else {
            this.notes.push(note);
          }

          this.handleCancel(formDirective);
        },
        error: _ => this.toaster.error('Error while creating Note'),
      });
  }

  /**
   * Opens the notes modal
   */
  openNotes(): void {
    this.dialog.open(this.modal, {
      width: '900px',
      minHeight: '700px',
      disableClose: true,
      panelClass: 'notes-modal',
    });
  }

  /**
   * Handles actions after closing the expansion panel
   */
  handlePanelClose() {
    this.lists.forEach((list: MatSelect) => list.close());
  }

  /**
   * Gets the description for a DepartamentsDto
   * @param id string
   * @returns string
   */
  getDepartment(id: string): string {
    return this.departments.find((d: DepartamentsDto) => d.id === id)?.description || '';
  }

  /**
   * Gets the possible visibility roles
   * @param props { allowCustomer: boolean, allowPilot: boolean }
   * @returns string
   */
  getVisibility(props: { allowCustomer: boolean; allowPilot: boolean }): string {
    const visibleTo = [props.allowCustomer ? 'Customer' : null, props.allowPilot ? 'Pilot' : null];
    return visibleTo.filter(Boolean).join(', ');
  }

  /**
   * Sets the missing author name on the notes object
   * @param notes MissionNoteDataDto[]
   */
  private setAuthor(notes: MissionNoteDataDto[]) {
    notes.forEach(note => {
      if (note.creatorId) {
        const creator = this.users.find(x => x.id == note.creatorId);
        note.creatorName = creator?.value || '-';
      }
    });
  }
}
