import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetPaymentStatusesInput,
  PaymentStatusCreateDto,
  PaymentStatusDto,
  PaymentStatusExcelDownloadDto,
  PaymentStatusUpdateDto,
} from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class PaymentStatusService {
  apiName = 'CoreService';

  create = (input: PaymentStatusCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentStatusDto>(
      {
        method: 'POST',
        url: '/api/core-service/payment-statuses',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/core-service/payment-statuses/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentStatusDto>(
      {
        method: 'GET',
        url: `/api/core-service/payment-statuses/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/core-service/payment-statuses/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetPaymentStatusesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PaymentStatusDto>>(
      {
        method: 'GET',
        url: '/api/core-service/payment-statuses',
        params: {
          filterText: input.filterText,
          description: input.description,
          code: input.code,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: PaymentStatusExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/core-service/payment-statuses/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: PaymentStatusUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentStatusDto>(
      {
        method: 'PUT',
        url: `/api/core-service/payment-statuses/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
