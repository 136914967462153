import { FilterConfig } from '../../shared/grid-filters/models/filter-config.model';
import { FilterType } from '../../shared/grid-filters/models/filter-type.enum';

export const PilotFinderSourcingFilterColumns: FilterConfig = {
  conditions: [
    {
      column: 'name',
      columnDisplayName: 'Pilot Name',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'availabilityId',
      columnDisplayName: 'Availability',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'isPreferred',
      columnDisplayName: 'Preferred Pilot',
      type: FilterType.Boolean,
      existingValues: [],
    },
    {
      column: 'equipmentId',
      columnDisplayName: 'Equipment',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'addressCountry',
      columnDisplayName: 'Country',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'addressState',
      columnDisplayName: 'State',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'addressCity',
      columnDisplayName: 'City',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'address',
      columnDisplayName: 'Street Address',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'addressZipCode',
      columnDisplayName: 'Zip Code',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'preferredContactStyle',
      columnDisplayName: 'Preferred Contact Style',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'pilotBadgesId',
      columnDisplayName: 'Badges',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'sensorsType',
      columnDisplayName: 'Sensor Type',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    }
  ],
};
