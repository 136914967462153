import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OrderRequestModel } from '../../../pilot-sourcing/models/order-request-model';
import { LocationForm } from '../../../pilot-sourcing/components/order-request/models/location-form.model';

@Injectable({
  providedIn: 'root',
})
export class RefreshOrderCreatedService {
  private refreshOrderCreatedSubject = new Subject<void>();
  refreshOrderCreated$ = this.refreshOrderCreatedSubject.asObservable();

  private newChangeLocationRequest = new Subject<LocationForm>();
  newChangeLocation$ = this.newChangeLocationRequest.asObservable();

  private newSlaMilestonesRequest = new Subject<any>();
  newSlaMilestones$ = this.newSlaMilestonesRequest.asObservable();

  private newSlaProfile = new Subject<any>();
  newSlaProfile$ = this.newSlaProfile.asObservable();

  triggerOrderCreated() {
    this.refreshOrderCreatedSubject.next();
  }

  triggernewSlaMilestonesRequest(newData: any) {
    this.newChangeLocationRequest.next(newData);
  }

  triggernewChangeLocationRequest(location: LocationForm) {
    this.newChangeLocationRequest.next(location);
  }

  triggernewSlaProfile(newData: any) {
    this.newSlaProfile.next(newData);
  }
}
