import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface Badge {
  text: string;
  color: string;
  background: string;
  id?: string;
}

@Component({
  selector: 'app-grid-badge',
  templateUrl: './grid-badge.component.html',
  styleUrls: ['./grid-badge.component.scss'],
})
export class GridBadgeComponent {
  @Input() badge: Badge;
  @Input() removable = false;
  @Input() shortenText = false;
  @Output() removeEvent = new EventEmitter<string>();

  get displayText(): string {
    if (this.shortenText && this.badge.text.length > 13) {
      return this.badge.text.slice(0, 10) + '...';
    } else if (this.badge.text.length > 30) {
      return this.badge.text.slice(0, 27) + '...';
    }

    return this.badge.text;
  }

  remove() {
    if (this.removable && this.badge.id) {
      this.removeEvent.emit(this.badge.id);
    }
  }
}
