<div class="container-fluid" *ngIf="missionFilter">
  <ngx-spinner
    bdColor="rgba(245,245,245,0.7)"
    size="default"
    color="#bc5a2e"
    type="ball-square-clockwise-spin"
    [fullScreen]="false"
    [zIndex]="111"
  >
    <p style="color: #bc5a2e">Loading Folders...</p>
  </ngx-spinner>

  <ngx-spinner
    bdColor="rgba(245,245,245,0.7)"
    size="default"
    color="#bc5a2e"
    type="ball-square-clockwise-spin"
    [fullScreen]="false"
    [zIndex]="111"
    [name]="'capture'"
  >
    <p style="color: #bc5a2e">Removing capture...</p>
  </ngx-spinner>
  <div class="row">
    <div class="align-items-center">
      <div class="d-flex flex-wrap justify-content-between align-items-center mt-1">
        <div class="d-flex gap-1 justify-content-start align-items-center montserrat bold">
          <span (click)="goBack()" role="button" class="fs-14">{{
            'missionsService::Deliverables' | abpLocalization
          }}</span>
          <span>/</span>
          <span class="fs-14">
            {{ deliverable.deliverableName }} -
            {{ deliverable.productName || deliverable.packageName }}</span
          >
          <span>/</span>
          <abp-file-management-breadcrumb
            [missionFilter]="missionFilter"
            [navigatedFolders]="navigator.navigatedFolderPath$ | async"
            (breadcrumbClick)="navigateToFolder($event)"
          ></abp-file-management-breadcrumb>
        </div>
        <abp-file-management-file-upload
          *ngIf="missionFilter.rootFolder"
          [rootId]="missionFilter.rootFolder.id"
        ></abp-file-management-file-upload>
      </div>
      <div *ngIf="deliverableAspectsInstructions" class="ms-3">
        <h3>Uploading Data Instructions</h3>
        {{ deliverableAspectsInstructions }}
      </div>
      <div class="d-flex flex-wrap mt-2">
        <div class="mt-4 flex-grow-1" style="max-width: 100%">
          <p class="scroll-attributes">
            <ng-container *ngFor="let attr of attributeList">
              <span>{{ attr.attributeName }}</span
              >:
              <strong class="me-3">
                <span *ngIf="!attr.fileLink">{{ attr.value }}</span>
                <a
                  *ngIf="attr.fileLink"
                  class="text-primary"
                  [download]="attr.fileName"
                  href="javascript:void(0)"
                  (click)="downloadFromBlobStorage(attr.fileLink, attr.fileName)"
                  >{{ attr.fileName }}</a
                >
              </strong>
            </ng-container>
          </p>
        </div>
        <div class="d-flex flex-wrap">
          <button
            *ngIf="showMapIcon"
            type="button"
            class="d-flex justify-content-center align-items-center attribute-btn"
            (click)="toggleMap()"
          >
            <span *ngIf="!displayMap" class="material-symbols-rounded me-2">map</span>
            <p class="p-0 m-0" *ngIf="!displayMap">Show map</p>

            <span *ngIf="displayMap" class="material-symbols-rounded me-2">folder</span>
            <p class="p-0 m-0" *ngIf="displayMap">Show folder</p>
          </button>

          <button
            *ngIf="isDeliverableStatusAwaiting(deliverable?.status)"
            type="button"
            class="d-flex justify-content-center align-items-center attribute-btn"
            (click)="showMoveModal()"
            title="Move files"
          >
            <span class="material-symbols-rounded me-2"> drive_file_move </span>
            Move Files
          </button>

          <abp-file-management-buttons
            *ngIf="missionFilter.rootFolder"
            [rootId]="missionFilter.rootFolder.id"
            [missionId]="missionId"
            [captureId]="captureId"
            [deliverableId]="deliverableId"
            [missionFilter]="missionFilter"
            [onlyViewDownload]="onlyViewDownload"
          >
          </abp-file-management-buttons>

          <button
            type="button"
            class="d-flex justify-content-center align-items-center attribute-btn me-2"
            (click)="downloadSelectedFiles()"
            title="Download files"
          >
            <span class="material-symbols-rounded me-2"> download </span>
            Download Files
          </button>

          <mat-form-field [floatLabel]="'always'" *ngIf="captureList" class="mt-1">
            <mat-label>Capture Version</mat-label>
            <mat-select
              [(ngModel)]="missionFilter.captureId"
              [placeholder]="'Select a Capture'"
              (selectionChange)="onChangeCapture(missionFilter.captureId)"
            >
              <mat-option *ngFor="let item of captureList" [value]="item.id">
                Capture {{ item.captureNumber }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="color: black">
    <h4>
      <strong id="note-title">{{ note?.subject }}</strong>
    </h4>
    <p id="note-body">{{ note?.body }}</p>
  </div>

  <div class="row" *ngIf="!displayMap">
    <div class="col-12 col-md-4 col-lg-3 col" style="display: none">
      <div class="mt-3 mb-4">
        <!-- <abp-file-management-buttons
          *ngIf="missionFilter.rootFolder && isEditable"
          [rootId]="missionFilter.rootFolder.id"
          [missionId]="missionId"
          [captureId]="captureId"
          [deliverableId]="deliverableId"
        >
        </abp-file-management-buttons> -->
        <abp-file-management-directory-tree
          *ngIf="contentReady"
          [isEditable]="isEditable"
          [validateCapture]="true"
          [missionFilter]="missionFilter"
          [hideMainRootFolder]="true"
          [onlyViewDownload]="onlyViewDownload"
        >
        </abp-file-management-directory-tree>
      </div>
    </div>
    <div>
      <div class="row capture-info-row" *ngIf="selectedCapture">
        <div class="col">
          Status:
          <span
            [ngClass]="getDeliverableStatusClass(selectedCapture.captureDeliverableStatus)"
            class="me-2"
          >
            {{ getDeliverableStatusText(selectedCapture.captureDeliverableStatus) }}
          </span>

          <lib-action-form
            *ngIf="
              canReject(selectedCapture.captureDeliverableStatus) && selectedCapture && deliverable
            "
            [row]="{
              missionId: selectedCapture.missionId,
              deliverableId: deliverable.deliverableId,
              orderDetailId: deliverable.orderDetailId,
              title: missionName,
              altitude: alt_value || '-',
              gimbal: gr_value || '-',
              overlap: ovlp_value || '-',
              originAuthToken: currentToken,
              creatorId: currentUserId,
              selectedCapture: selectedCapture.id,
            }"
            [gridAction]="action"
            [isMobile]="false"
            [config]="{ modalWidth: '900px', showCloseOnModal: true }"
            class="single-action single-action--wide"
          >
            <button type="button" class="table-outline-button ms-1" [disabled]="disableActions">
              Reject
            </button>
          </lib-action-form>
          <lib-action-form
            *ngIf="
              canAccept(selectedCapture.captureDeliverableStatus) && selectedCapture && deliverable
            "
            [row]="{
              title: acceptTitle,
              question: acceptQuestion,
              missionId: selectedCapture.missionId,
              deliverableId: deliverable.deliverableId,
              orderDetailId: deliverable.orderDetailId,
              creatorId: currentUserId,
              selectedCapture: selectedCapture.id,
            }"
            [gridAction]="acceptAction"
            [isMobile]="false"
            [config]="{ modalWidth: '900px', showCloseOnModal: true }"
            class="single-action single-action--wide"
          >
            <button
              type="button"
              class="table-outline-button table-outline-button--inverted ms-1"
              [disabled]="disableActions"
            >
              Accept
            </button>
          </lib-action-form>
          <button
            type="button"
            class="table-outline-button table-outline-button--inverted ms-1"
            (click)="updateDeliverableToAwait()"
            [disabled]="disableActions"
            *ngIf="canReset(selectedCapture.captureDeliverableStatus)"
          >
            Awaiting
          </button>
        </div>
        <div class="col mt-1">
          Capture Date: {{ selectedCapture?.fixedDate || (captureDate + 'Z' | date: 'MM/dd/yyyy') }}
        </div>
        <div class="col mt-1">
          Capture Time: {{ selectedCapture?.fixedTime || (captureDate + 'Z' | date: 'hh:mm a') }}
        </div>
        <div class="col mt-1">Pilot Name: {{ selectedCapture.capturePilot || '-' }}</div>
      </div>
      <div class="row"><br /></div>
      <div
        class="row capture-info-row"
        *ngIf="selectedCapture && selectedCapture.captureDeliverableStatus == rejectedCaptureStatus"
      >
        <div class="col-12">
          <strong>QA/QC Rejection Note:</strong>
          <p class="mt-1">{{ selectedCapture?.captureDeliverableNote }}</p>
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="mt-3">
        <abp-file-management-folder-panel
          *ngIf="contentReady"
          [optionsFilter]="optionsFilter"
          (contentUpdate)="currentContent = $event"
          [hideDirectoryView]="true"
          [filesSelected]="filesSelected"
          (filesSelectedChange)="updateFilesToDownload($event)"
          (selectAllChange)="handleOnSelectAllChange($event)"
          [missionFilter]="missionFilter"
          [showOptions]="true"
          [onlyViewDownload]="onlyViewDownload"
        ></abp-file-management-folder-panel>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="displayMap" [class.d-none]="loadingMap">
    <div class="col-lg-12 col-md-12 mt-3 mb-3">
      <mat-card class="mb-25 tagus-card">
        <mat-card-content>
          <flg-map
            [usesDarkMode]="false"
            [usesKML]="true"
            [dimensions]="{
              height: '500px',
              width: '100%',
            }"
            [contextMenuItems]="contextMenuItems"
          ></flg-map>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <abp-modal [(visible)]="isMoveFolderModalOpen" [busy]="isMoveFolderModalBusy">
    <ng-template #abpHeader>
      <h3>Move Folder/Files</h3>
    </ng-template>

    <ng-template #abpBody>
      <h4>
        <span
          class="move-modal-path"
          *ngFor="let path of modalPath; index as i"
          (click)="navigateToPath(i)"
        >
          <span *ngIf="i === 0">
            <b>{{ path?.path }} > </b>
          </span>
          <span *ngIf="i !== 0"> {{ path?.path }} > </span>
        </span>
      </h4>
      <ul class="mt-3 list-group list-group-flush">
        <li
          *ngFor="let item of moveModalHistory[moveModalHistory.length - 1]"
          class="list-group-item"
          (click)="goToContent(item)"
        >
          <div>
            <img
              *ngIf="item.isDirectory"
              src="assets/images/logo/folder-logo.png"
              class="img-fluid"
            />
            <span class="ms-2">
              {{ item.deliverableName ?? item.name }}
            </span>
          </div>
        </li>
      </ul>
    </ng-template>

    <ng-template #abpFooter>
      <button type="button" class="btn btn-secondary" abpClose>Close</button>
      <button (click)="moveSelectedItems()" type="button" class="btn btn-secondary">Move</button>
    </ng-template>
  </abp-modal>

  <app-responsive-grid
    class="d-none"
    gridName="RejectDeliverable"
    (action)="setAction($event)"
  ></app-responsive-grid>

  <app-responsive-grid
    class="d-none"
    gridName="AcceptDeliverable"
    (action)="setAcceptAction($event)"
  ></app-responsive-grid>
</div>
