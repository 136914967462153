<h5 mat-dialog-title class="flg-modal flg-modal__title">Validation Results</h5>
<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container">
    <div class="row justify-content-center">
      <p>
        Overall Status:
        <span class="w-600">{{ getStatusString(getOverallStatus()) }}</span>
      </p>
      <div class="results-table">
        <div class="table-header">
          <div class="header-cell">File Name</div>
          <div class="header-cell">Tolerance Code</div>
          <div class="header-cell">Status</div>
          <div class="header-cell">Expected</div>
          <div class="header-cell">Actual</div>
          <div class="header-cell">Tolerance</div>
        </div>
        <ng-container *ngFor="let file of data">
          <ng-container *ngFor="let result of file.validationResults; let first = first">
            <div class="table-row">
              <div class="cell">{{ first ? file.fileName : '' }}</div>
              <div class="cell">{{ result.toleranceCode }}</div>
              <div class="cell">{{ getStatusString(result.result) }}</div>
              <div class="cell">{{ result.fieldValue }}</div>
              <div class="cell">{{ result.fileValue || 'N/A' }}</div>
              <div class="cell">{{ result.toleranceValue }}</div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end mt-3">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClose()">
      Close
    </button>
  </div>
</mat-dialog-actions>
