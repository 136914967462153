import { EntityProp, EntityPropList, ePropType } from "@abp/ng.theme.shared/extensions";
import { AuditLogDto } from "@volo/abp.ng.audit-logging/proxy";
import { IdentityEntityPropContributors, eIdentityComponents } from "@volo/abp.ng.identity";
import { IdentityRoleDto } from "@volo/abp.ng.identity/proxy";
import { AuditLoggingEntityPropContributors, eAuditLoggingComponents } from "projects/audit-logging/src/public-api";

const codeProp = new EntityProp<IdentityRoleDto>({
  type: ePropType.String,
  name: 'code',
  displayName: 'IdentityService::Code',
  columnWidth: 250,
  isExtra: true,
});

const portalProp = new EntityProp<IdentityRoleDto>({
  type: ePropType.String,
  name: 'portal',
  displayName: 'IdentityService::Portal',
  columnWidth: 250,
  isExtra: true,
});




export function namePropContributor(propList: EntityPropList<IdentityRoleDto>) {
  propList.addTail(codeProp);
  propList.addTail(portalProp);
}


export const identityEntityPropContributors: IdentityEntityPropContributors = {
  [eIdentityComponents.Roles]: [namePropContributor]
};




const impersonateProp = new EntityProp<AuditLogDto>({
  type: ePropType.String,
  name: 'impersonatorUserName',
  displayName: 'Impersonate user',
  columnWidth: 250,
  isExtra: true,
});


export function namePropAuditLogDto(propList: EntityPropList<AuditLogDto>) {
  propList.addAfter(
    impersonateProp,
    'userName',
    (value, name) => value.name === name,
  );
}


export const auditLoggingEntityPropContributors: AuditLoggingEntityPropContributors = {
  [eAuditLoggingComponents.AuditLogs]: [namePropAuditLogDto],
};