<abp-file-upload-progress-viewer
  class="file-progress-viewer"
  [ngClass]="{ 'menu-active': isToggled }"
>
</abp-file-upload-progress-viewer>
<div [class.dark-theme]="themeService.isDark()">
  <app-sidebar></app-sidebar>
  <div
    class="main-content d-flex flex-column"
    [ngClass]="{ active: isToggled }"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
  >
    <app-header></app-header>
    <div #appContainer class="px-3 pt-3">
      <router-outlet></router-outlet>
    </div>
    <div class="flex-grow-1"></div>
    <!-- @TODO commented out for UAT release, check [10535](https://dev.azure.com/newtonvision/FLYGUYS/_workitems/edit/10535)
    <app-customizer-settings></app-customizer-settings> -->
    <app-footer></app-footer>
  </div>
</div>
