import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PilotSourcingCommunicationService {
  private reloadFunctionSubject = new Subject<any>();

  reloadFunctionCalled$ = this.reloadFunctionSubject.asObservable();

  callReloadFunction(obj?:any) {
    this.reloadFunctionSubject.next(obj);
  }
}