import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fg-chip',
  templateUrl: './fg-chip.component.html',
  styleUrls: ['./fg-chip.component.scss'],
})
export class FgChipComponent {
  @Input() text: string;
}
