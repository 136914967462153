<div class="grid-badge">
  <p
    [style.color]="badge.color"
    [style.background]="badge.background"
    matTooltip="{{ badge.text }}"
  >
    {{ displayText }}
    <span *ngIf="removable" class="material-symbols-rounded" (click)="remove()">cancel</span>
  </p>
</div>
