<flg-generic-modal>
  <ng-container modalTitle>
    {{ 'missionsService::SiteContactInformation' | abpLocalization }}
    <span *ngIf="!isModalOpen">
      <button class="btn p-0" (click)="onClickClose()">
        <mat-icon aria-hidden="false" aria-label="Close" fontIcon="close"></mat-icon>
      </button>
    </span>
  </ng-container>

  <div class="container p-0" modalBody>
    <ngx-spinner
      bdColor="rgba(245,245,245,0.7)"
      size="default"
      color="#bc5a2e"
      type="ball-square-clockwise-spin"
      [fullScreen]="false"
      [zIndex]="111"
    >
      <p style="color: #bc5a2e">{{ spinnerMessage }}</p>
    </ngx-spinner>

    <div class="row justify-content-center">
      <div *ngIf="!isModalOpen && !isModalBusy">
        <label class="label-order d-block mb-2">
          Search for the site contact you want to add or create a new one in case your search is not
          successful
        </label>

        <div class="row align-items-center">
          <div class="col">
            <mat-form-field class="w-100 me-2">
              <input
                #autoTrigger="matAutocompleteTrigger"
                type="text"
                placeholder="Search Contact"
                matInput
                [matAutocomplete]="autoContact"
                (focus)="openContactsAutocompletePanel(autoTrigger)"
                [(ngModel)]="searchTerm"
                (ngModelChange)="filterContacts($event)"
              />
              <mat-autocomplete
                #autoContact="matAutocomplete"
                (optionSelected)="onContactSelected($event)"
                class="site-autocomplete"
              >
                <mat-option *ngFor="let contact of filteredContacts" [value]="contact">
                  <div class="contact-info-container">
                    <span class="location-name">
                      {{contact.firstName.trim() + ' ' + contact.lastName.trim()}}
                    </span>
                    <div class="contact-details">
                      <div class="location-address">{{ contact.email }}</div>
                      <div class="location-address">{{ contact.phone }}</div>
                    </div>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-auto h-56px">
            <button
              mat-button
              class="common-button"
              type="button"
              color="primary"
              (click)="create()"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="Create New Contact"
                fontIcon="add"
              ></mat-icon>
              {{ 'missionsService::NewContacts' | abpLocalization }}
            </button>
          </div>
        </div>

        <ngx-datatable
          class="flg-grid-table"
          [headerHeight]="45"
          [footerHeight]="55"
          [rowHeight]="45"
          [rows]="data.items"
          [count]="data.totalCount"
          [columnMode]="'force'"
          [trackByProp]="'id'"
          default
          #dataTable
        >
          <ngx-datatable-column name="{{ 'missionsService::Name' | abpLocalization }}">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.firstName }} {{ row.lastName }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::Email' | abpLocalization }}"
            prop="email"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.email }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::Phone' | abpLocalization }}"
            prop="phone"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.phone }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Level of Coordination" prop="phone" [width]="250">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.levelCommunicationDescription }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Preferred Contact Method" prop="phone" [width]="250">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.contactMethodDescription }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [name]="'AbpUi::Actions' | abpLocalization" [sortable]="false">
            <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
              <app-column-actions [columnActions]="columnActions(row)"></app-column-actions>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
      <ng-container div *ngIf="isModalOpen">
        <h6 class="mb-3">
          {{
            (selected ? 'missionsService::EditContact' : 'missionsService::NewContacts')
              | abpLocalization
          }}
        </h6>

        <form
          [formGroup]="formLocationContactModal"
          id="contacts"
          (ngSubmit)="submitForm()"
          skipValidation
        >
          <div class="mb-2">
            <mat-form-field class="w-100">
              <mat-label>Contact From</mat-label>
              <mat-select formControlName="contactFromId">
                <mat-option *ngFor="let data of contactType.items" [value]="data.id">
                  {{ data.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="mb-2">
            <mat-form-field class="w-100">
              <mat-label>{{ 'missionsService::Email' | abpLocalization }}</mat-label>
              <input matInput formControlName="email" maxlength="50" />
              <mat-hint *ngIf="showHintEditContactEmail">{{
                'customersService::OnlyEditContactsEmail' | abpLocalization
              }}</mat-hint>
              <mat-error *ngIf="formLocationContactModal.get('email').hasError('email')">
                Please enter a proper email address
              </mat-error>
              <mat-error *ngIf="formLocationContactModal.get('email').hasError('emailExists')">
                This email already exists
              </mat-error>
            </mat-form-field>
          </div>

          <div class="d-flex">
            <mat-form-field class="w-100 pe-2">
              <mat-label> {{ 'missionsService::FirstName' | abpLocalization }}</mat-label>
              <input matInput formControlName="firstName" maxlength="100" />
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>{{ 'missionsService::LastName' | abpLocalization }}</mat-label>
              <input matInput formControlName="lastName" maxlength="100" />
            </mat-form-field>
          </div>

          <flg-phone-input
            [control]="formLocationContactModal.get('phone')"
            [label]="'Phone'"
            [widthClass]="'w-100'"
          ></flg-phone-input>

          <div class="mb-2">
            <label for="preferredContactMethod" class="w-100 mt-1 mb-2"
              >Preferred contact method</label
            >
            <mat-radio-group
              name="contactPreferenceRadio"
              aria-labelledby="preferredContactMethod"
              id="preferredContactMethod"
              formControlName="contactMethodId"
            >
              <mat-radio-button
                class="pe-2"
                *ngFor="let contactMethod of contactMethod.items"
                [value]="contactMethod.id"
              >
                <span class="checkbox-label">{{ contactMethod.description }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="mb-3">
            <label for="levelcoordination" class="w-100 mt-1 mb-2">Level of coordination</label>

            <mat-radio-group
              name="levelOfCoordinationRadio"
              aria-labelledby="levelcoordination"
              id="levelcoordination"
              formControlName="levelCommunicationId"
            >
              <mat-radio-button
                class="pe-2"
                *ngFor="let levelCommunication of levelCommunication.items"
                [value]="levelCommunication.id"
              >
                <span class="checkbox-label">{{ levelCommunication.description }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <flg-pending-controls
            [touched]="true"
            [controls]="getPendingControls()"
          ></flg-pending-controls>
        </form>
      </ng-container>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-3" *ngIf="isModalOpen" modalActions>
    <button mat-flat-button type="button" class="outline-button me-2" (click)="hideForm()">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="submitForm()"
      [disabled]="!formLocationContactModal.valid"
    >
      {{ 'AbpUi::Save' | abpLocalization }}
    </button>
  </div>
</flg-generic-modal>
