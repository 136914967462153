<abp-file-management-modal title="Move" [parent]="self">
  <span class="d-block p-2">{{
    "FileManagement::MoveToUnder" | abpLocalization
  }}</span>
  <abp-file-management-breadcrumb
    [navigatedFolders]="service.breadcrumbs$ | async"
    rootBreadcrumbName="FileManagement::Root"
    (breadcrumbClick)="onBreadcrumbClick($event)"
  ></abp-file-management-breadcrumb>

  <ngx-datatable
    [rows]="directoryContent$ | async"
    default
    [messages]="{ emptyMessage: 'FileManagement::MoveHere' | abpLocalization }"
  >
    <ngx-datatable-column
      [sortable]="true"
      [name]="'FileManagement::Name' | abpLocalization"
    >
      <ng-template let-row="row" let-i="index" ngx-datatable-cell-template>
        <div (click)="service.goTo(row)" class="pointer">
          <i class="fa fa-folder text-primary me-1" aria-hidden="true"></i> {{ row.name }}
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</abp-file-management-modal>
