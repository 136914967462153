import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PagedResultDto } from '@abp/ng.core';
import {
  BadgeCategoryDto,
  type GetBadgeCategoriesInput,
} from '../../../../../../missions-service/src/lib/proxy/missions-service/relationals';
import { BadgeCategoryService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals/badge-category.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BadgesService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics';
import { BadgesDto } from '../../../../../../missions-service/src/lib/proxy/missions-service/basics';
import { BadgeSelectionDto } from './models/badge-selection-dto';
import { ProgressionLevel } from './models/badge-progression-enum';
import { forkJoin, Observable } from 'rxjs';
import { PilotBadgeDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/relationals';
import { BulkAssignBadgeModalData } from './models/bulk-assign-badge-modal-data';

@Component({
  selector: 'app-assign-badge-modal',
  templateUrl: './bulk-assign-badge-modal.component.html',
  styleUrls: ['./bulk-assign-badge-modal.component.scss'],
})
export class BulkAssignBadgeModalComponent implements OnInit {
  modalData: BulkAssignBadgeModalData;
  categoriesMaxResultCount: 1000;
  lstBadgeCategories: PagedResultDto<BadgeCategoryDto> = {
    items: [],
    totalCount: 0,
  };
  selectedBadgeCategoryId: string;
  allBadgeSelectionList: BadgeSelectionDto[] = [];
  displayedBadgeSelectionList: BadgeSelectionDto[] = [];
  userSelections: BadgeSelectionDto[] = [];

  form: FormGroup;
  loading = false;
  noBadgesFound: boolean = false;

  protected readonly ProgressionLevel = ProgressionLevel;
  progressionLevels = Object.values(ProgressionLevel).filter(value => typeof value === 'number');

  constructor(
    public dialogRef: MatDialogRef<BulkAssignBadgeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkAssignBadgeModalData,
    private formBuilder: FormBuilder,
    private badgeCategoryService: BadgeCategoryService,
    private badgeService: BadgesService
  ) {
    this.modalData = data;

    this.form = this.formBuilder.group({
      searchInput: [''],
    });
  }

  ngOnInit() {
    this.loading = true;
    forkJoin({
      categories: this.fetchBadgeCategories(),
    }).subscribe({
      next: ({ categories }) => {
        this.lstBadgeCategories = categories;
        this.sortBadgeCategories();
        if (this.lstBadgeCategories.items.length > 0) {
          this.selectedBadgeCategoryId = this.lstBadgeCategories.items[0].id;
          this.displayBadgesFromCategory(this.selectedBadgeCategoryId);
        }
      },
      error: error => {
        console.log(error);
        this.loading = false;
      },
    });
  }

  hasWarning(badge: BadgeSelectionDto, level: ProgressionLevel, levelSelected: number): boolean {
    if (level != levelSelected) return false;

    return !!(<PilotBadgeDto[]>this.modalData.currentBadges).find(
      x => x.badgeId == badge.badgeId && x.progressionLevel > level
    );
  }

  getWarningMessage(
    badge: BadgeSelectionDto,
    level: ProgressionLevel,
    levelSelected: number
  ): string {
    if (level != levelSelected) return '';

    let pilots = (<PilotBadgeDto[]>this.modalData.currentBadges).filter(
      x => x.badgeId == badge.badgeId && x.progressionLevel > level
    );

    return `In the selection, there ${pilots.length > 1 ? 'are' : 'is'} ${pilots.length} ${
      pilots.length > 1 ? 'pilots' : 'pilot'
    } with a higher level`;
  }

  fetchBadgeCategories(): Observable<PagedResultDto<BadgeCategoryDto>> {
    const input: GetBadgeCategoriesInput = {
      maxResultCount: this.categoriesMaxResultCount,
    };
    return this.badgeCategoryService.getList(input);
  }

  sortBadgeCategories(): void {
    this.lstBadgeCategories.items.sort((a, b) => {
      const orderA = a.order || 0;
      const orderB = b.order || 0;
      return orderA - orderB;
    });
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  onBadgeSearch() {
    const searchValue = this.form.get('searchInput').value.toLowerCase();
    this.filterDisplayedBadges(searchValue);
  }

  filterDisplayedBadges(searchValue: string) {
    this.displayedBadgeSelectionList = this.allBadgeSelectionList.filter(
      badge =>
        badge.categoryId === this.selectedBadgeCategoryId &&
        (!searchValue || badge.badgeName.toLowerCase().includes(searchValue.toLowerCase()))
    );
    this.applyUserSelections();
    this.noBadgesFound = this.displayedBadgeSelectionList.length === 0;
  }

  handleBadgeCategorySelect(id: string) {
    if (id) {
      this.selectedBadgeCategoryId = id;
      this.form.get('searchInput').setValue('');
      this.displayBadgesFromCategory(id);
    }
  }

  displayBadgesFromCategory(id: string) {
    this.loading = true;
    this.badgeService.getBadgesFromCategoryId(id).subscribe({
      next: (result: BadgesDto[]) => {
        const newBadges = result.map(badge => ({
          badgeId: badge.id,
          badgeName: badge.name,
          pilotId: '',
          pilotName: '',
          hasProgression: badge.hasProgression,
          selected: false,
          progressionLevel: badge.hasProgression ? ProgressionLevel.Interested : undefined,
          categoryId: id,
        }));

        // Merge new badges with existing ones, preserving selections
        this.allBadgeSelectionList = this.mergeBadgeLists(this.allBadgeSelectionList, newBadges);
        this.filterDisplayedBadges(this.form.get('searchInput').value);
        this.loading = false;
      },
      error: error => {
        console.log(error);
        this.loading = false;
        this.noBadgesFound = true;
      },
    });
  }

  mergeBadgeLists(
    existingList: BadgeSelectionDto[],
    newList: BadgeSelectionDto[]
  ): BadgeSelectionDto[] {
    const mergedList = [...existingList];
    newList.forEach(newBadge => {
      const existingBadge = mergedList.find(badge => badge.badgeId === newBadge.badgeId);
      if (existingBadge) {
        existingBadge.badgeName = newBadge.badgeName;
        existingBadge.hasProgression = newBadge.hasProgression;
        existingBadge.categoryId = newBadge.categoryId;
      } else {
        mergedList.push(newBadge);
      }
    });
    return mergedList;
  }

  onClickAssign(): void {
    this.dialogRef.close(this.userSelections);
  }

  onBadgeSelectionChange(badge: BadgeSelectionDto): void {
    const index = this.userSelections.findIndex(b => b.badgeId === badge.badgeId);
    if (index !== -1) {
      if (badge.selected) {
        this.userSelections[index] = { ...badge };
      } else {
        this.userSelections.splice(index, 1);
      }
    } else if (badge.selected) {
      this.userSelections.push({ ...badge });
    }

    const allIndex = this.allBadgeSelectionList.findIndex(b => b.badgeId === badge.badgeId);
    if (allIndex !== -1) {
      this.allBadgeSelectionList[allIndex] = { ...badge };
    }
  }

  applyUserSelections(): void {
    this.displayedBadgeSelectionList.forEach(badge => {
      const userSelection = this.userSelections.find(b => b.badgeId === badge.badgeId);
      if (userSelection) {
        badge.selected = true;
        badge.progressionLevel = userSelection.progressionLevel;
      } else {
        badge.selected = false;
        badge.progressionLevel = badge.hasProgression ? ProgressionLevel.Interested : undefined;
      }
    });
  }
}
