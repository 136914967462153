import { FilterConfig } from "../../shared/grid-filters/models/filter-config.model";
import { FilterType } from "../../shared/grid-filters/models/filter-type.enum";

export const MissionNoteFilterColumns: FilterConfig = {
  conditions: [
    {
      column: 'categoryId',
      columnDisplayName: 'Category',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'atentionTo',
      columnDisplayName: 'Attention To',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'submittedBy',
      columnDisplayName: 'Submitted By',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    }
  ],
};
