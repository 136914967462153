import { Component, Inject, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderFormModel } from '../../../../components/orders/model/order-form.model';
import { Subscription } from 'rxjs';
import {
  MissionDeliverablesService,
  MissionsService,
} from '../../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics';
import { MissionsDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { OrderFormDetailsModel } from 'projects/flyguys/src/app/components/orders/model/order-form-detail.model';
import { OrderFormPackageModel } from 'projects/flyguys/src/app/components/orders/model/order-form-package-model';
import { OrderFormDeliverableModel } from 'projects/flyguys/src/app/components/orders/model/order-form-deliverable.model';
import { DeliverableDto } from 'projects/flyguys/src/app/models/products-deliverables/deliverable-dto';
import { FormHelpers } from 'projects/flyguys/src/app/form-helpers';
import { LoadingOverlayService } from '../../../../services/loading/loading.service';

@Component({
  selector: 'app-mission-deliverables-modal',
  templateUrl: './mission-deliverables-modal.component.html',
  styleUrls: ['./mission-deliverables-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MissionDeliverablesModalComponent implements OnInit, OnDestroy {
  missionId: string;
  formPackagesProducts: FormGroup;
  orderModel: OrderFormModel;
  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<MissionDeliverablesModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      missionId: string;
      packagesbyCustumer: OrderFormPackageModel[];
      genericPackages: OrderFormPackageModel[];
      productsbyIndustry: OrderFormDeliverableModel[];
    },
    private fb: FormBuilder,
    public loadingService: LoadingOverlayService
  ) {
    this.missionId = data.missionId;
    this.formPackagesProducts = this.fb.group({});
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  savePackagesProducts(): void {
    this.dialogRef.close(this.orderModel.orderDetail);
  }

  ngOnInit() {
    this.orderModel = new OrderFormModel();
    this.orderModel.packagesbyCustumer = this.data.packagesbyCustumer;
    this.orderModel.genericPackages = this.data.genericPackages;
    this.orderModel.productsbyIndustry = this.data.productsbyIndustry;
    this.orderModel.orderDetail = new OrderFormDetailsModel();
  }

  isStepValid(): boolean {
    return (
      this.formPackagesProducts.status !== FormHelpers.ValidationStatus.INVALID &&
      (this.orderModel.orderDetail.packagesSelected.length > 0 ||
        this.orderModel.orderDetail.productsSelected.length > 0)
    );
  }

  invalidPackageConfiguration(): boolean {

    let incompletePackages = !!this.orderModel.orderDetail.packagesSelected.find(x => x.incompletePackage);

    let incompleteProducts = !!this.orderModel.orderDetail.productsSelected.find(x => x.requiredConfigurationIncomplete);

    return incompletePackages || incompleteProducts;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
