import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-distance-modal',
  templateUrl: './distance-modal.component.html',
  styleUrls: ['./distance-modal.component.scss']
})
export class DistanceModalComponent {
  constructor(
    public dialogRef: MatDialogRef<DistanceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      localizableEntityName: string,
      name: string,
      distance: google.maps.Distance,
      duration: google.maps.Duration
    }
  ) {}
}
