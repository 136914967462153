import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'pdf-viewer',
  templateUrl: './pdf-viewer.component.html'
})
export class PdfViewerComponent {

    @Input()
    public fileUrl: SafeResourceUrl;
    
    @Input()
    public id: string;

    @Input()
    public fileName: string;

    @Input()
    public customClass: string;

    @Input()
    public allowClick: boolean = false;

    @Output() 
    public onPictureClicked = new EventEmitter<void>();

    constructor() { }

    public handleClick() {
        this.onPictureClicked.emit();
    }
}