<h3>{{ 'pilotsService::OrderHistory' | abpLocalization }}</h3>
<ngx-datatable
  class="flg-grid-table"
  [rowHeight]="45"
  [headerHeight]="50"
  [footerHeight]="55"
  [rows]="ordersHistory.items"
  [count]="ordersHistory.totalCount"
  [list]="list"
  [offset]="pageNumber"
  [limit]="clientMaxResultCount"
  [externalPaging]="true"
  #dataTable
  class="flg-grid-table"
>
  <ngx-datatable-column name="{{ 'pilotsService::Type' | abpLocalization }}" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.portfolioId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'pilotsService::Name' | abpLocalization }}" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.requestingCustomerId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'pilotsService::Customer' | abpLocalization }}" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.billingCustomerId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'pilotsService::Location' | abpLocalization }}" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.billingCustomerId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'pilotsService::NMissions' | abpLocalization }}" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.billingCustomerId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'pilotsService::LastCaptureDate' | abpLocalization }}" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.billingCustomerId }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'pilotsService::CumulativeRating' | abpLocalization }}"
    prop="name"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.billingCustomerId }}
    </ng-template>
    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-curPage="curPage"
      >
        <app-table-paginator
          [(maxResultCount)]="list.maxResultCount"
          [(clientMaxResultCount)]="clientMaxResultCount"
          [rowCount]="rowCount"
          [pageSize]="pageSize"
          [(pageNumber)]="pageNumber"
          [curPage]="curPage"
          [dataTable]="dataTable"
        >
        </app-table-paginator>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable-column>
</ngx-datatable>
