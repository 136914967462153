<abp-file-management-modal title="Preview Pdf  {{ fileName }}" [parent]="self">
  <pdf-viewer
    id="preview"
    [fileUrl]="fileUrl"
    [fileName]="fileName"
    allowClick="true"
    (onPictureClicked)="handleOnPictureClicked()"
    customClass="mt-15"
  >
  </pdf-viewer>
</abp-file-management-modal>
