<div
  class="card d-flex p-3 align-items-start align-self-stretch rounded custom-card"
  [ngStyle]="{ height: height }"
>
  <div
    class="icon-container d-flex justify-content-center align-items-center rounded-circle flex-shrink-0"
  >
    <span class="material-symbols-outlined">{{ icon }}</span>
  </div>
  <div class="gap"></div>
  <div class="d-flex w-100">
    <div class="right-content">
      <div class="top-row t-mb-8">
        <div class="title">{{ title }}</div>
        <span
          *ngIf="actionIconVisible"
          class="material-symbols-outlined cursor-pointer"
          (click)="onActionClick()"
          >{{ actionIcon }}</span
        >
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
