<div class="mt-3">
  <mat-sidenav-container class="filters-container">
    <mat-sidenav #drawer mode="over" position="end" class="sidenav-border">
      <app-grid-filters
        [filterConfig]="filterConfig"
        [filterTitle]="'Filter Notes'"
        (applyFilters)="onFiltersApplied($event)"
        (closeSidenav)="drawer.close()"
      >
      </app-grid-filters>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="demo-card-container filter-section">
        <div class="row mb-2">
          <div class="col-md-10 col-lg-10">
            <h3>{{ 'missionsService::NotesTitle' | abpLocalization }}</h3>
          </div>
          <div class="col-md-2 col-lg-2 d-flex justify-content-end mt-2">
            <lib-management-actions
              *ngIf="!refreshingNotes"
              gridName="Missions"
              [rowData]="{ missionId: missionId, creatorId: currentUserId }"
              uniqueAction="add_note"
              (parentEvent)="refreshList()"
              modalClass="notes-modal"
              loadingHtml='<span class="material-symbols-rounded mr-8px">add_notes</span>'
              [additionalData]="usersForNote"
            >
              <button mat-stroked-button class="outline-button" type="button" color="primary">
                <mat-icon fontIcon="add"></mat-icon>
                {{ 'missionsService::NewNotes' | abpLocalization }}
              </button>
            </lib-management-actions>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="input-group">
              <input
                type="search"
                class="form-control"
                placeholder="Search"
                [(ngModel)]="noteFilters.filterText"
                (search)="callApiWithFilters()"
                (keyup.enter)="callApiWithFilters()"
              />
              <button class="btn btn-primary" (click)="callApiWithFilters()">
                <i aria-hidden="true" class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card">
            <div class="card-body d-flex align-items-center">
              <span class="fg-subtitle me-2">
                {{ 'missionsService::ShowMeNotes' | abpLocalization }}
              </span>
              <div class="d-flex align-items-center">
                <ng-container *ngFor="let condition of filterConfig.conditions; let isLast = last">
                  <ng-container *ngIf="condition.existingValues.length">
                    <app-filter-chip
                      class="me-2"
                      [label]="condition.columnDisplayName"
                      [value]="getFilterDisplayValue(condition)"
                      (removeEvent)="removeFilter(condition.column)"
                    ></app-filter-chip>
                  </ng-container>
                </ng-container>
              </div>

              <span class="material-symbols-outlined me-2 cursor-pointer" (click)="drawer.toggle()">
                add_circle
              </span>

              <span
                class="d-inline-block main-color fw-medium cursor-pointer"
                (click)="removeAllFilters()"
                >{{ 'missionsService::ClearFilterNotes' | abpLocalization }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="demo-card-container" *ngIf="this.data.items.length > 0">
        <cdk-virtual-scroll-viewport
          [itemSize]="AVG_ITEM_SIZE"
          (scrolledIndexChange)="scrollHandler($event)"
        >
          <div class="card border rounded" *ngFor="let item of this.data.items; let index = index">
            <div class="card-body">
              <div class="row">
                <div class="col-md-11 col-lg-11">
                  <span class="h4 d-inline title">{{ item.subject }}</span>
                  &nbsp;&nbsp;
                  <span class="soft d-inline">{{ item.creationTime + 'Z' | date : 'hh:mm' }}</span>
                  &nbsp;&nbsp;
                  <span class="soft d-inline"> | </span>
                  &nbsp;&nbsp;
                  <span class="soft d-inline">{{ item.creationTime  + 'Z'| date : 'MM/dd/yyyy' }}</span>
                  &nbsp;&nbsp;
                </div>
                <div class="col-md-1 col-lg-1">
                  <span
                    class="material-symbols-outlined cursor-pointer ng-star-inserted float-end"
                    (click)="handleOnRemove(item.id, index)"
                    *ngIf="item.creatorId == currentUserId"
                  >
                    delete
                  </span>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col">
                  <span class="soft d-inline" [innerHTML]="item.body"></span>
                </div>
              </div>
              <div class="row" style="margin-top: 20px">
                <div class="col">
                  <span class="soft d-inline"
                    >{{ 'missionsService::SubmittedBy' | abpLocalization }}
                  </span>
                  &nbsp;&nbsp;
                  <span class="soft d-inline"> {{ item.creatorName }} </span>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col">
                  <span class="soft d-inline">{{
                    'missionsService::Category' | abpLocalization
                  }}</span>
                  &nbsp;&nbsp;
                  <span class="soft d-inline"> {{ item.categoryDescription }} </span>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col">
                  <span class="soft d-inline">{{
                    'missionsService::AttentionTo' | abpLocalization
                  }}</span>
                  &nbsp;&nbsp;
                  <span class="soft d-inline"> {{ item.departamentDescription }} </span>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col">
                  <span class="soft d-inline"
                    >{{ 'missionsService::WhoCanView' | abpLocalization }}:</span
                  >
                </div>
              </div>

              <div class="row mt-1">
                <div class="col">
                  <span class="d-flex align-items-center"
                    ><mat-icon class="mat-18" color="primary">{{
                      item.extraProperties?.allowCustomer ? 'check_box' : 'check_box_outline_blank'
                    }}</mat-icon>
                    {{ 'missionsService::Customer' | abpLocalization }}
                  </span>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col">
                  <span class="d-flex align-items-center"
                    ><mat-icon class="mat-18" color="primary">{{
                      item.extraProperties?.allowPilot ? 'check_box' : 'check_box_outline_blank'
                    }}</mat-icon>
                    {{ 'missionsService::Pilot' | abpLocalization }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex demo-card-container align-items-center">
            <div class="col-12 text-center">
              <button
                class="btn btn-link"
                [hidden]="disableLoadMore || loadingMore"
                (click)="getMoreNotes()"
              >
                {{ 'missionsService::LoadMoreNotes' | abpLocalization }}
              </button>
              <span *ngIf="loadingMore">{{
                'missionsService::LoadingMoreNotes' | abpLocalization
              }}</span>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
      <div
        class="d-flex demo-card-container align-items-center"
        *ngIf="this.data.items.length === 0"
      >
        <h4>{{ 'missionsService::NoNotesFound' | abpLocalization }}</h4>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
