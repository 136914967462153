import { WebNotificationType } from "./web-notification-type.enum";

export class WebNotificationArgumentModel {
    notificationId: string;
    missionId?: string;
    webNotificationId: string;
    date: Date;
    type: WebNotificationType;
    title?: string;
    typeToast?: string;
}
