<mat-card style="text-align: center">
  <img
    class="main-image"
    src="https://earthobservatory.nasa.gov/ContentFeature/CitiesAtNight/images/ISS016-E-026150_denver.jpg"
  />
  <div class="avatar-container">
    <img class="avatar" [src]="pictureProfile" />
  </div>

  <mat-card-title class="raleway fs-16 bold text-break">
    {{ pilot?.firstName + ' ' + pilot?.lastName }}
  </mat-card-title>
  <mat-card-subtitle class="fs-12">
    {{ 'pilotsService::Pilot' | abpLocalization }}
  </mat-card-subtitle>
  <mat-card-subtitle class="preferredPilot" *ngIf="pilot?.isPreferred">
    <p>
      <i class="fas fa-check-circle"> </i>
      <span class="text-break">{{ 'pilotsService::PreferredPilot' | abpLocalization }}</span>
    </p>
  </mat-card-subtitle>

  <mat-card-content class="mt-4" style="text-align: left">
    <div class="d-flex justify-content-between">
      <h3 class="fs-14 text-break">{{ 'pilotsService::PilotInformation' | abpLocalization }}</h3>
      <app-column-actions [columnActions]="columnActions()"></app-column-actions>
    </div>
    <label class="fs-12 text-break" for="pilot-card-email">
      {{ 'pilotsService::Email' | abpLocalization }}
    </label>
    <p class="fs-14 text-break" id="pilot-card-email">{{ pilot?.email }}</p>

    <label class="fs-12 text-break" for="pilot-card-phone">
      {{ 'pilotsService::PrimaryPhone' | abpLocalization }}
    </label>
    <p class="fs-14 text-break" id="pilot-card-phone">{{ pilot?.phone }}</p>

    <label class="fs-12 text-break" for="pilot-card-phone">
      {{ 'pilotsService::SecondaryPhone' | abpLocalization }}
    </label>
    <p class="fs-14 text-break" id="pilot-card-phone">{{ pilot?.secondaryPhone }}</p>

    <label class="fs-12 text-break" for="pilot-card-address">
      {{ 'pilotsService::PrimaryAddress' | abpLocalization }}
    </label>
    <p class="fs-14 text-break" id="pilot-card-address">{{ pilot?.address }}</p>

    <label class="fs-12 text-break" for="pilot-card-availabilityId">
      {{ 'pilotsService::Availability' | abpLocalization }}
    </label>
    <p class="fs-14 text-break" id="pilot-card-availabilityId">
      {{ availabilitiesDict[pilot?.availabilityId] }}
    </p>

    <label class="fs-12 text-break" for="pilot-card-phone">
      {{ 'pilotsService::FAA107' | abpLocalization }}
    </label>
    <p class="fs-14 text-break" id="pilot-card-phone">{{ pilot?.part107Number }}</p>

    <label class="fs-12 text-break" for="pilot-card-phone">
      {{ 'pilotsService::RegistrationDate' | abpLocalization }}
    </label>
    <p class="fs-14 text-break" id="pilot-registration-date">
      {{
        userLoginInformation?.createdDate
          ? (userLoginInformation?.createdDate | date: 'MM/dd/yyyy')
          : '-'
      }}
    </p>

    <label class="fs-12 text-break" for="pilot-card-phone">
      {{ 'pilotsService::LastLoginDate' | abpLocalization }}
    </label>
    <p class="fs-14 text-break" id="pilot-last-login">
      {{
        userLoginInformation?.lastLoginDate
          ? (userLoginInformation?.lastLoginDate | date: 'MM/dd/yyyy')
          : '-'
      }}
    </p>

    <label class="fs-12" for="pilot-enableSMSReception">
      {{ 'IdentityService::EnableSMSReception' | abpLocalization }}
    </label>
    <p class="fs-14" id="pilot-enableSMSReception">
      {{ pilot.enableSMSReception == null ? false : pilot.enableSMSReception }}
    </p>
  </mat-card-content>
</mat-card>

<ng-template #modal>
  <flg-generic-modal>
    <ng-container modalTitle>
      {{ 'pilotsService::EditPilotInformation' | abpLocalization }}
    </ng-container>

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form [formGroup]="form" id="pilot" skipValidation>
          <div class="d-flex">
            <mat-form-field class="w-100 pe-2">
              <mat-label for="pilot-first-name">
                {{ 'pilotsService::FirstName' | abpLocalization }}
              </mat-label>

              <input id="pilot-first-name" formControlName="firstName" maxlength="100" matInput />
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label for="pilot-last-name">
                {{ 'pilotsService::LastName' | abpLocalization }}
              </mat-label>

              <input id="pilot-last-name" formControlName="lastName" maxlength="100" matInput />
            </mat-form-field>
          </div>

          <mat-form-field class="w-100">
            <mat-label for="pilot-email">
              {{ 'pilotsService::Email' | abpLocalization }}
            </mat-label>

            <input id="pilot-email" formControlName="email" maxlength="100" matInput />

            <mat-error *ngIf="form.get('email').hasError('required')">
              Please enter a valid Email
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('emailExists')">
              This email already exists
            </mat-error>
          </mat-form-field>

          <mat-form-field class="w-100 mb-2">
            <mat-label for="pilot-FAA107">
              {{ 'pilotsService::FAA' | abpLocalization }}
            </mat-label>

            <input id="pilot-FAA107" formControlName="part107Number" maxlength="7" matInput />
            <mat-error *ngIf="form.get('part107Number').hasError('pattern')">
              {{ 'Flyguys::FAAPatternValidation' | abpLocalization }}
            </mat-error>
            <mat-error *ngIf="form.get('part107Number').hasError('faaExists')">
              {{ 'pilotsService::FaaExists' | abpLocalization }}
            </mat-error>
          </mat-form-field>

          <div class="d-flex">
            <div class="w-100 pe-2">
              <flg-phone-input
                [control]="form.get('phone')"
                [widthClass]="'w-100'"
                [label]="'pilotsService::PrimaryPhoneNumber' | abpLocalization"
              ></flg-phone-input>
            </div>

            <div class="w-100">
              <flg-phone-input
                [control]="form.get('secondaryPhone')"
                [widthClass]="'w-100'"
                [label]="'pilotsService::SecondaryPhoneNumber' | abpLocalization"
              ></flg-phone-input>
            </div>
          </div>

          <div class="w-100">
            <mat-checkbox formControlName="enableSMSReception" color="primary"></mat-checkbox>
            <label class="form-label" for="enableSMSReception">{{
              'IdentityService::EnableSMSReception' | abpLocalization
            }}</label>
          </div>

          <div class="w-100" class="checkDescription">
            <span>
              {{ 'IdentityService::DescriptionEnableSMSReception' | abpLocalization }}
            </span>
          </div>

          <flg-location-form
            [countries]="dataCountries?.items"
            [states]="dataStates?.items"
            [form]="form.get('location')"
            [useLatLng]="false"
            (locationChange)="handleLocationChange($event)"
            #locationform
          ></flg-location-form>

          <mat-form-field class="w-100">
            <mat-label for="pilot-availability-id">
              {{ 'pilotsService::Availability' | abpLocalization }}
            </mat-label>

            <mat-select id="pilot-availability-id" formControlName="availabilityId">
              <mat-option
                [value]="availability.id"
                *ngFor="let availability of availabilities.items"
              >
                {{ availability.description | abpLocalization }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="d-flex w-100">
            <mat-checkbox formControlName="isPreferred">{{
              'pilotsService::PreferredPilot' | abpLocalization
            }}</mat-checkbox>
          </div>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        class="outline-button me-2"
        type="button"
        (click)="hideForm()"
        [disabled]="isModalBusy"
      >
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        (click)="submitForm()"
        [disabled]="form.invalid || isModalBusy"
      >
        {{ 'AbpUi::Save' | abpLocalization }}
      </button>
    </div>
  </flg-generic-modal>
</ng-template>
