import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapReloadService {
  private reloadSubject = new Subject<void>();

  reloadMap(): void {
    this.reloadSubject.next();
  }

  get reloadObservable() {
    return this.reloadSubject.asObservable();
  }
}
