import { Component, Inject, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderFormDeliverableModel } from '../../components/orders/model/order-form-deliverable.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormHelpers } from 'projects/flyguys/src/app/form-helpers';
import { PagedResultDto } from '@abp/ng.core';
import { DeliverablesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import {
  DeliverablesDto,
  GetDeliverableInput,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { Subscription } from 'rxjs';
import { DeliverableField } from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import { OrderFormDeliverableFieldModel } from '../../components/orders/model/order-form-deliverable-field.model';

@Component({
  selector: 'app-new-deliverable',
  templateUrl: './new-deliverable.component.html',
  styleUrls: ['./new-deliverable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewDeliverableComponent implements OnInit, OnDestroy {
  newdeliverable: OrderFormDeliverableModel;
  formNewDelirable: FormGroup;
  deliveryAttributes: DeliverableField[] = [];

  filters = { isPaginated: false } as GetDeliverableInput;
  dataDeliverable: PagedResultDto<DeliverablesDto> = {
    items: [],
    totalCount: 0,
  };

  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<NewDeliverableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderFormDeliverableModel,
    public readonly deliverableService: DeliverablesService
  ) {
    this.newdeliverable = data;
    this.formNewDelirable = FormHelpers.buildValidatorsDeliverables(true);
  }

  ngOnInit() {
    this.getDeliverables();
  }

  setDescriptionFromId(id: string, items: any[], fieldtoSet: string, fieldName: string) {
    if (id?.trim() != '') {
      const item = items.find(x => x.id === id);
      this.newdeliverable[fieldtoSet] = item?.[fieldName];
      this.getAttributes(id);
    }
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  saveDeliverable(): void {
    this.fieldsToModel();
    this.dialogRef.close(this.newdeliverable);
  }

  fieldsToModel(): void {
    this.newdeliverable.fields = [];
    for (const field of this.deliveryAttributes) {
      let fielddeli = new OrderFormDeliverableFieldModel();
      fielddeli.id = field.id;
      fielddeli.placeholder = field.placeholder;
      fielddeli.numericValue = field.numericValue;
      fielddeli.stringValue = field.stringValue;
      this.newdeliverable.fields.push(fielddeli);
    }

  }

  getDeliverables() {
    const subsdeliverables = this.deliverableService.getList(this.filters).subscribe(
      (data: PagedResultDto<DeliverablesDto>) => {
        if (data?.totalCount != 0) {
          this.dataDeliverable = data;
        }
      },
      error => {
        console.log('Error on getting deliverables: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.add(subsdeliverables);
  }

  getAttributes(deliverableId: string) {
    this.removeFieldsValidators();
    this.deliveryAttributes = [];
    const subsAttributes = this.deliverableService.getAttributes(deliverableId).subscribe(
      (data: DeliverableField[]) => {
        if (data?.length != 0) {
          this.deliveryAttributes = data;
          this.addFieldsValidators();
        }
      },
      error => {
        console.log('Error on getting deliverables: ' + JSON.stringify(error));
      }
    );
    this.subscriptions.add(subsAttributes);
  }

  removeFieldsValidators() {
    for (const field of this.deliveryAttributes) {
      this.formNewDelirable.removeControl(field.id);
    }
  }

  addFieldsValidators() {
    for (const field of this.deliveryAttributes) {
      this.formNewDelirable.addControl(
        field.id,
        new FormControl('', { validators: Validators.compose([Validators.required]) })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
