import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ABPInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        if (req.url.includes("localhost")) {
            return next.handle(req);
        }
        let newUrl = this.buildUrl(req);
        let dupReq = req.clone({ url: newUrl });

        return next.handle(dupReq);
    }

    buildUrl(req: HttpRequest<any>): string {
        let url = req.url;
        let service = this.getService(url);

        if (!service || service.length === 0) {
            return url;
        }

        let newUrl = "";
        let index = url.indexOf("/api");
        let prefix = url.substring(0, index);
        let sufix = url.substring(index, url.length);
        newUrl = prefix + "/" + service + sufix;

        return newUrl;
    }

    getService(reqUrl: string): string {
        let splitedUrl = reqUrl.split("/");
        let getServiceRoute = splitedUrl[4];

        if (!getServiceRoute || getServiceRoute.length === 0 || splitedUrl[3] != 'api') {
            return "";
        }

        let service = "";
        this.routesMap.forEach((value, key) => {
            if (value.includes(getServiceRoute)) {
                service = key;
            }
        });

        return service;
    }


    routesMap = new Map<string, string[]>([
        ['admonservice', ["abp", "audit-logging", "feature-management", "gdpr", "language-management", "lepton-theme-management", "permission-management", "setting-management", "text-template-management"]],
        ['identityservice', ["identity", "account-admin", "openiddict"]],
        ['saasservice', ["saas", "payment", "payment-admin"]],
    ]);
}