import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import {
  PilotExperienceLevelesService,
  AvailabilityService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { PilotBadgeService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/relationals';
import { ToasterService } from '@abp/ng.theme.shared';
import { firstValueFrom } from 'rxjs';
import { PilotBadgeDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/relationals';
import { Badge } from '../../../../components/common/badge/display/grid-badge/grid-badge.component';
import { AssignBadgeModalComponent } from '../../assign-badge-modal/assign-badge-modal.component';
import { BadgeSelectionDto } from '../../assign-badge-modal/models/badge-selection-dto';
import { BadgePilotData } from '../../assign-badge-modal/models/badge-pilot-data';
import { HttpErrorResponse } from '@angular/common/http';
import { ProgressionLevel } from '../../assign-badge-modal/models/badge-progression-enum';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
})
export class BadgesComponent implements OnInit {
  @Input() pilotId: string;
  displayedBadges: Badge[] = [];
  loading = false;

  constructor(
    private dialog: MatDialog,
    private pilotService: PilotService,
    private pilotExperienceLevelService: PilotExperienceLevelesService,
    private availabilityService: AvailabilityService,
    private pilotBadgeService: PilotBadgeService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.loadBadges();
  }

  loadBadges() {
    this.loading = true;
    this.pilotBadgeService.getSelectedBadgesForPilot(this.pilotId).subscribe({
      next: selectedBadges => {
        this.displayedBadges = selectedBadges.map(badge => {
          let badgeText = badge.badgeName;
          if (badge.progressionLevel !== undefined && badge.progressionLevel !== null) {
            badgeText += ` - ${ProgressionLevel[badge.progressionLevel]}`;
          }
          return {
            text: badgeText,
            color: '#0252ED',
            background: '#0252ED1A',
            id: badge.badgeId,
          };
        });
        this.loading = false;
      },
      error: error => {
        console.error('Error loading badges:', error);
        this.loading = false;
      },
    });
  }

  async openAssignModal() {
    try {
      const badgePilotData = await this.fetchPilotDetails(this.pilotId);

      const dialogRef = this.dialog.open(AssignBadgeModalComponent, {
        disableClose: true,
        width: '900px',
        panelClass: 'custom-modalbox',
        data: badgePilotData,
      });

      dialogRef.afterClosed().subscribe({
        next: (selectedBadges: BadgeSelectionDto[]) => {
          if (selectedBadges && selectedBadges.length > 0) {
            this.pilotBadgeService.assignMultipleBadges(selectedBadges).subscribe({
              next: (assignedBadges: PilotBadgeDto[]) => {
                this.loadBadges();
                const badgeCount = assignedBadges.length;

                this.toasterService.success(`Badge assignments have been successfully updated.`);
              },
              error: () =>
                this.toasterService.error(
                  'An error occurred while assigning the badges to the pilot.'
                ),
            });
          } else {
            this.toasterService.info('No badges were selected for assignment.');
          }
        },
        error: error => console.error('Error in dialog:', error),
      });
    } catch (error) {
      this.toasterService.error(
        'An error occurred while fetching pilot details. Please try again.'
      );
    }
  }

  private async fetchPilotDetails(pilotId: string): Promise<BadgePilotData> {
    try {
      const pilotDetails = await firstValueFrom(this.pilotService.get(pilotId));
      const experienceLevel = await this.fetchExperienceLevel(
        pilotDetails.pilotExperienceLevelId,
        pilotId
      );
      const availability = await this.fetchAvailability(pilotDetails.availabilityId, pilotId);
      return {
        pilotId: pilotId,
        pilotName: `${pilotDetails.firstName} ${pilotDetails.lastName}`,
        pilotExperience: experienceLevel,
        pilotStatus: pilotDetails.infoChecked ? 'Active' : 'Inactive',
        availability: availability,
      };
    } catch (error) {
      console.error('Error fetching pilot details:', error);
      throw new Error('Failed to fetch pilot details');
    }
  }

  private async fetchExperienceLevel(experienceLevelId: string, pilotId: string): Promise<string> {
    try {
      const expLevel = await firstValueFrom(
        this.pilotExperienceLevelService.get(experienceLevelId)
      );
      return expLevel.description;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        return '-';
      } else {
        throw error;
      }
    }
  }
  private async fetchAvailability(availabilityId: string | null, pilotId: string): Promise<string> {
    if (!availabilityId) {
      return '-';
    }
    try {
      const availabilityData = await firstValueFrom(this.availabilityService.get(availabilityId));
      return availabilityData.description;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        return '-';
      } else {
        throw error;
      }
    }
  }
}
