import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eCoreServiceRouteNames } from '../enums/route-names';

export const LOOKUPS_PAYMENT_STATUS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/core-service/payment-statuses',
        parentName: eCoreServiceRouteNames.CoreService,
        name: 'CoreService::Menu:PaymentStatuses',
        layout: eLayoutType.application,
        requiredPolicy: 'CoreService.PaymentStatuses',
        iconClass: 'fas fa-receipt',
      },
    ]);
  };
}
