import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../tagus/customizer-settings/customizer-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigateToMissionDetailsDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { RefreshOrderCreatedService } from '../services/refresh-order-created.service';

@Component({
  selector: 'orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
})
export class OrdersListComponent implements OnInit {
  private readonly PORTFOLIO_TAB_INDEX = 1;

  tabSelected: number;

  missionOpened: string;
  goToPilotSourcingGrid: boolean = false;
  currentCaptureId: string;

  createMission: boolean = false;

  constructor(
    public themeService: CustomizerSettingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private refreshOrderCreatedService: RefreshOrderCreatedService,
  ) {}

  ngOnInit(): void {
    let currentUrl = this.activatedRoute.snapshot.url.join().split(',');

    if (currentUrl.includes('portfolio')) this.tabSelected = this.PORTFOLIO_TAB_INDEX;
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }

  handleOnGoBack(missionCreated?: boolean): void {
    if (missionCreated && missionCreated === true) {
      this.refreshOrderCreatedService.triggerOrderCreated();
    }

    this.missionOpened = undefined;
    this.createMission = false;
    this.cdRef.detectChanges();
  }

  onOpenMissions(missionNavigationDetails: NavigateToMissionDetailsDto): void {
    this.missionOpened = missionNavigationDetails.missionId;
    this.goToPilotSourcingGrid =
      missionNavigationDetails.navigateToCapturePilotSourcingTab ?? false;
    this.currentCaptureId = missionNavigationDetails.currentCaptureId;
  }

  createNewOrder() {
    this.createMission = true;
    this.cdRef.detectChanges();
  }

  hideMainView(): boolean {
    return !(this.missionOpened === undefined && !this.createMission);
  }
}
