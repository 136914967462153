import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExifDataResultsDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import { AutomaticQaStatus } from '../../../models/automatic-qa-status.enum';

@Component({
  selector: 'app-validations-results-modal',
  templateUrl: './validations-results-modal.component.html',
  styleUrls: ['./validations-results-modal.component.scss'],
})
export class ValidationsResultsModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ValidationsResultsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExifDataResultsDto[],
  ) {
    console.log('received data: ', data);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  getStatusClass(status: AutomaticQaStatus): string {
    switch (status) {
      case AutomaticQaStatus.Pass:
        return 'status-pass';
      case AutomaticQaStatus.Fail:
        return 'status-fail';
      case AutomaticQaStatus.NotTested:
        return 'status-not-tested';
      default:
        return '';
    }
  }

  getOverallStatus(): AutomaticQaStatus {
    if (this.data.some(item => item.overallValidationResult === AutomaticQaStatus.Fail)) {
      return AutomaticQaStatus.Fail;
    } else if (this.data.every(item => item.overallValidationResult === AutomaticQaStatus.Pass)) {
      return AutomaticQaStatus.Pass;
    } else {
      return AutomaticQaStatus.NotTested;
    }
  }

  getStatusString(status: AutomaticQaStatus): string {
    switch (status) {
      case AutomaticQaStatus.Pass:
        return 'Pass';
      case AutomaticQaStatus.Fail:
        return 'Fail';
      case AutomaticQaStatus.NotTested:
        return 'Not Tested';
      default:
        return 'Unknown';
    }
  }
}
