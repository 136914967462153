import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PagedResultDto } from '@abp/ng.core';
import {
  CountriesDto,
  CustomerVerticalDto,
  LevelesDto,
  StatesDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CustomerType } from '../model/order-form-create-customer.model';
import { OrderFormSiteModel } from '../model/order-form-site.model';
import { MapVisibilityService } from '../services/map-visibility.service';
import { OrderSiteComponent } from '../new-order-form/order-site/order-site.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-new-site-form',
  templateUrl: './new-site-form.component.html',
  styleUrls: ['./new-site-form.component.scss'],
})
export class NewSiteFormComponent implements OnInit, OnDestroy {
  site: OrderFormSiteModel;
  contactsUpdated: boolean;
  portfolioId: string;
  customerId: string;
  priorityId: string;

  @ViewChild('orderMission') orderMission: OrderSiteComponent;

  form: FormGroup;
  private subscriptions: Subscription[] = [];
  CustomerType = CustomerType;

  levels: PagedResultDto<LevelesDto> = {
    items: [],
    totalCount: 0,
  };

  dataCountries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };

  dataStates: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };

  customerVerticals: Array<CustomerVerticalDto>;

  constructor(
    public dialogRef: MatDialogRef<NewSiteFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      siteModel: OrderFormSiteModel;
      portfolioId: string;
      priorityId: string;
    },
    public dialogService: MatDialog,
    public readonly mapVisibilityService: MapVisibilityService,
    private datePipe: DatePipe,
  ) {
    this.site = data.siteModel;
    this.portfolioId = data.portfolioId;
    this.priorityId = data.priorityId;
  }

  ngOnInit(): void {
    this.mapVisibilityService.set(true);
  }

  onContactsUpdatedChange() {}

  handleRemoveSite(site: any) {}

  isSiteValid(): boolean {
    if (!this.orderMission) {
      return false;
    }

    return !this.orderMission.getPendingControls()?.length;
  }

  saveAddCustomer() {
    const orderMissionsDto = this.orderMission.buildOrderRequestForPortfolioDto(this.portfolioId);

    const date: Date = new Date(
      `${orderMissionsDto.capture.date} ${orderMissionsDto.capture.time || '00:00'}`,
    );
    orderMissionsDto.capture.date = this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC');
    orderMissionsDto.capture.time = orderMissionsDto.capture.time
      ? this.datePipe.transform(date, 'HH:mm', 'UTC')
      : null;
    this.dialogRef.close(orderMissionsDto);
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
