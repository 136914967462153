export interface PersonalCustomer {
  customerLevelId: string;
  customerVerticalId: string;
  firstName: string;
  lastName: string;
  email: string;
  primaryPhoneNumber: string;
  idHubspot?: string
}

export interface CompanyCustomer {
  customerLevelId: string;
  customerVerticalId: string;
  companyName: string;
  legalName: string;
  ein: string;
  website: string;
  msaCustomer: boolean;
  mainContact: {
    firstName: string;
    lastName: string;
    email: string;
    primaryPhoneNumber: string;
  };
  idHubspot?: string
}

export interface BillingInformation {
  billingFirstName: string;
  billingLastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  countryId: string;
  countryName: string;
  stateId: string;
  stateName: string;
  city: string;
  zipCode: string;
  billingContactId: string;
  addressId: string;
}

export class CreateCustomer {
  customerType: CustomerType;
  personal?: PersonalCustomer;
  company?: CompanyCustomer;
  mainContactId?: string;
  billingInformation: BillingInformation;
  idHubspot?: string;

  constructor(
    customerType: CustomerType,
    personal: PersonalCustomer | undefined,
    company: CompanyCustomer | undefined,
    billingInformation: BillingInformation,
    idHubspot?: string
  ) {
    this.customerType = customerType;
    this.personal = personal;
    this.company = company;
    this.billingInformation = billingInformation;
    this.idHubspot = idHubspot;
  }
}

export enum CustomerType {
  Personal,
  Company,
}
