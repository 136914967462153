import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { OrderFormContactModel } from '../model/order-form-contact.model';
import { MissionSLADto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';

import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { DatePipe } from '@angular/common';
import { format } from 'date-fns';
@Component({
  selector: 'app-order-custom-sla',
  templateUrl: './order-custom-sla.component.html',
  styleUrls: ['./order-custom-sla.component.scss'],
})
export class OrderCustomSlaTableComponent implements OnInit {
  @Input() slas: MissionSLADto[];
  @Output() changeCaptureDate: EventEmitter<any> = new EventEmitter<any>();

  public finalStep = 'Final Customer Delivery Due Date';

  constructor(private slaService: SlaService) {}

  ngOnInit() {}

  handleSelectDate(event: any, milestone: any, i: number): void {
    const formattedDate = format(event.value, 'yyyy-MM-dd');
    milestone.dueDate = formattedDate + 'T00:00:00';
    if (milestone.isMatchWithCaptureDate) {
      this.changeCaptureDate.emit(milestone.dueDate);
    }

    this.calculateDays(i);
    this.slaService.customSlaChange.next(this.slas);
  }

  getFormattedDate(date: Date): string {
    if (!date) return '';
    return date.toLocaleDateString('en-CA');
  }

  calculateDays(i: number) {
    const currentMilestone = this.slas[i];
    if (i < this.slas.length - 1) {
      const nextMilestone = this.slas[i + 1];
      if (nextMilestone) {
        const dueDate = new Date(currentMilestone.dueDate);
        const startDate = this.addDays(dueDate, 1);
        nextMilestone.startDate = format(startDate, 'yyyy-MM-dd') + 'T00:00:00';
        nextMilestone.days = this.calculateBusinessDaysDifference(
          nextMilestone.startDate,
          nextMilestone.dueDate,
        );
      }
    }
    currentMilestone.days = this.calculateBusinessDaysDifference(
      currentMilestone.startDate,
      currentMilestone.dueDate,
    );
  }

  calculateBusinessDaysDifference(start: Date, dueDate: Date): number {
    if (!this.isValidDate(start) || !this.isValidDate(dueDate)) {
      return 0;
    }
    const startDate = new Date(start);
    const targetDate = new Date(dueDate);

    // Set hours, minutes, seconds, and milliseconds to zero for both dates
    startDate.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);

    // If the dates are the same, return 0
    if (startDate.getTime() === targetDate.getTime()) {
      return 1;
    }

    let count = 0;
    let increment = startDate < targetDate ? 1 : -1;
    let currentDate = new Date(startDate);

    // Move the currentDate in the correct direction to start counting the difference
    while (currentDate.getTime() !== targetDate.getTime()) {
      currentDate.setDate(currentDate.getDate() + increment);

      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        // Skip weekends
        count += increment;
      }
    }

    return count + 1;
  }

  isValidDate(date: any): boolean {
    const parsedDate = new Date(date);
    return (
      parsedDate instanceof Date &&
      !isNaN(parsedDate.getTime()) &&
      parsedDate.getTime() !== new Date('0001-01-01T00:00:00').getTime()
    );
  }

  addDays(date: Date, days: number): Date {
    let result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }
}
