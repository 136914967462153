import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatImageDto } from '../../models';

@Component({
  selector: 'abp-chat-image-viewer',
  templateUrl: './chat-image-viewer.component.html',
  styleUrls: ['./chat-image-viewer.component.css'],
})
export class ChatImageViewerComponent {
  protected readonly parseFloat = parseFloat;

  constructor(
    public dialogRef: MatDialogRef<ChatImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      detail: ChatImageDto;
    },
  ) {}

  onClickClose(): void {
    this.dialogRef.close(null);
  }
}
