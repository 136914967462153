<form [formGroup]="formDelirable">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-11">
        <h3 class="mt-4 mb-4">
          {{ this.deliverable.deliverableName }}
          <ng-container *ngIf="this.deliverable.productId !== null">
            Package ({{ this.deliverable.productName }})
          </ng-container>
        </h3>
        <div class="d-flex justify-content-end mt-3">
          <ng-container *ngFor="let field of deliverable.fields; let i = index">
            <div *ngIf="(i + 1) % 3 === 0 || i + 1 === deliverable.fields.length">
              <div class="d-flex w-100"></div>
            </div>
            <div class="w-33 pe-2">
              <mat-form-field class="w-100">
                <mat-label>{{ field.placeholder }}*</mat-label>
                <input
                  matInput
                  [placeholder]="field.placeholder"
                  [(ngModel)]="field.stringValue"
                  [formControlName]="field.Id"
                  pattern="[0-9]*"
                  digitOnly
                  [disabled]="fieldsDisabled"
                />
              </mat-form-field>
            </div>
            <div
              *ngIf="(i + 1) % 3 === 0 || i + 1 === deliverable.fields.length"
              class="w-100"
            ></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>
