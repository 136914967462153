import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';

@Component({
  selector: 'image-preview-modal',
  templateUrl: './image-preview-modal.component.html',
})
export class ImagePreviewModalComponent extends BaseModalComponent implements OnInit {

  @Input() 
  imageUrl: string;

  @Input() 
  fileName: string;

  @Input() 
  showSaveButton: boolean;

  @Input() 
  imageId: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  public handleOnPictureClicked()
  {
    let element = document.getElementById('preview');

    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
  }
}
