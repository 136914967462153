import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AirSpaceClassificationsCreateDto, AirSpaceClassificationsDto, AirSpaceClassificationsExcelDownloadDto, AirSpaceClassificationsUpdateDto, GetAirSpaceClassificationInput } from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { map } from 'rxjs';
import { enumState } from '../../lookups';

@Injectable({
  providedIn: 'root',
})
export class AirSpaceClassificationsService {
  apiName = 'CoreService';
  emptyGuid: string = '00000000-0000-0000-0000-000000000000';
  notSelectedItemAirspace: AirSpaceClassificationsDto = {
    id: this.emptyGuid,
    description: '[Not Selected]',
    state: enumState.Enabled,
    migrated: false
  };
  create = (input: AirSpaceClassificationsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AirSpaceClassificationsDto>({
      method: 'POST',
      url: '/api/core-service/air-space-classification',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/core-service/air-space-classification/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AirSpaceClassificationsDto>({
      method: 'GET',
      url: `/api/core-service/air-space-classification/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/core-service/air-space-classification/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetAirSpaceClassificationInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AirSpaceClassificationsDto>>({
      method: 'GET',
      url: '/api/core-service/air-space-classification',
      params: { filterText: input.filterText, description: input.description, state: input.state, migrated: input.migrated, idOld: input.idOld, pk_AIRSPACE_CLASS_ID: input.pk_AIRSPACE_CLASS_ID, code: input.code, sorting: input.sorting, isPaginated: input.isPaginated, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: AirSpaceClassificationsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/core-service/air-space-classification/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: AirSpaceClassificationsUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AirSpaceClassificationsDto>({
      method: 'PUT',
      url: `/api/core-service/air-space-classification/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
