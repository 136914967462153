import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { PackageDto } from '../../../models/products-deliverables/package-dto';
import { DeliverableDto } from '../../../models/products-deliverables/deliverable-dto';
import { ActivatedRoute } from '@angular/router';
import { ProductsDeliverablesService } from '../../../services/products-deliverables.service';
import { Subscription } from 'rxjs';
import { FilterMission } from '@volo/abp.ng.file-management/proxy';
import { OrderFormDeliverableModel } from '../../../components/orders/model/order-form-deliverable.model';
import { NewDeliverableComponent } from '../../../shared/new-deliverable/new-deliverable.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderRequestDeliverableDTO } from '../../../components/orders/dto/order-request-deliverable.dto';
import { OrderRequestDeliverableFieldDTO } from '../../../components/orders/dto/order-request-deliverable-field.dto';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { MissionDeliverablesService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics';
import { MissionsDto } from '../../../../../../missions-service/src/lib/proxy/missions-service/basics';
import { ActionOrderConfiguredDto } from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import { ConfigStateService } from '@abp/ng.core';
import { ActionOrderService } from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';

@Component({
  selector: 'app-files-data',
  templateUrl: './files-data.component.html',
  styleUrls: ['./files-data.component.scss'],
})
export class FilesDataComponent {
  @Input() missionStatusId: string = '';

  missionId: string;
  deliverables: DeliverableDto[] = [];
  packages: PackageDto[] = [];
  private subscriptions: Subscription[] = [];
  folderMissions: FilterMission;
  selectedDeliverable: string;

  // Actions visibility variables
  currentActionConfiguration: Array<ActionOrderConfiguredDto>;
  currentUserRoles: Array<string>;
  public readonly DELIVERABLE_ADD_MODIFY_ACTION = 'deliverable_add_modify';
  public readonly DELIVERABLE_TABLE_SHOW_FILES_ACTION = 'deliverable_table_show_files';
  public readonly DELIVERABLE_TABLE_VIEW_DETAILS_ACTION = 'deliverable_table_view_details';
  public readonly DELIVERABLE_TABLE_DELETE_ACTION = 'deliverable_table_delete';
  allowDeliverableAddModifyAction: boolean = false;
  allowDeliverableTableShowFilesAction: boolean = false;
  allowDeliverableTableViewDetailsAction: boolean = false;
  allowDeliverableTableDeleteAction: boolean = false;
  // Actions visibility variables

  constructor(
    private route: ActivatedRoute,
    private productsDeliverablesService: ProductsDeliverablesService,
    private missionFlowService: MissionFlowService,
    private missionDeliverablesService: MissionDeliverablesService,
    private confirmation: ConfirmationService,
    private dialogService: MatDialog,
    private snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private stateService: ConfigStateService,
    private readonly actionOrderService: ActionOrderService
  ) {}

  deliverableColumns = [
    { name: 'Deliverable', prop: 'deliverableName' },
    { name: 'Product', prop: 'product' },
    { name: 'Package', prop: 'package' },
    { name: 'Actions' },
    { name: 'DeliverableId', prop: 'deliverableId' },
  ];

  ngOnInit() {
    this.missionId = this.route.snapshot.paramMap.get('missionId');

    this.fetchDeliverables();

    this.subscriptions.push(
      this.productsDeliverablesService.getPackagesByMission(this.missionId).subscribe({
        next: response => {
          this.packages = response;
        },
        error: error => {
          console.log(error);
        },
      })
    );

    this.getActionConfiguration();
  }

  private fetchDeliverables(): void {
    const getDeliverablesSubscription = this.productsDeliverablesService
      .getDeliverablesByMission(this.missionId)
      .subscribe({
        next: response => (this.deliverables = response),
        error: error => console.log(error),
      });

    this.subscriptions.push(getDeliverablesSubscription);
  }

  private getActionConfiguration(): void {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    this.actionOrderService.filterByRoles(this.currentUserRoles).subscribe(res => {
      this.currentActionConfiguration = res;
      this.configureActionsVisibility();
    });
  }

  navigateView(row) {
  }

  openInfo(row) {
  }

  delete(row) {
    if (this.deliverables.length <= 1) {
      this.snackBar.open('A mission must have at least one deliverable.', 'OK', {
        duration: 3000,
      });
    } else {
      this.confirmation
        .warn('missionsService::DeleteConfirmationMessage', 'missionsService::AreYouSure', {
          messageLocalizationParams: [],
        })
        .subscribe(status => {
          if (status === Confirmation.Status.confirm) {
            this.missionDeliverablesService.delete(row.missionDeliverableId).subscribe({
              next: () => this.fetchDeliverables(),
              error: err => console.log(err),
            });
          }
        });
    }
  }

  addDeliverable(): void {
    const newDeliverable = new OrderFormDeliverableModel();
    const dialogRef = this.dialogService.open(NewDeliverableComponent, {
      data: newDeliverable,
      disableClose: true,
      minWidth: '500px',
    });

    const dialogSub = dialogRef.afterClosed().subscribe((data: OrderFormDeliverableModel) => {
      if (data) {
        // Check if the deliverableId already exists in this mission
        const deliverableExists = this.deliverables.some(
          existingDeliverable => existingDeliverable.deliverableId === data.deliverableId
        );

        if (deliverableExists) {
          this.snackBar.open('The deliverable is already added.', 'OK', {
            duration: 3000,
          });
        } else {
          const deliverableDto = this.buildDeliverableDto(data);
          this.missionFlowService
            .addDeliverableToMission(deliverableDto, this.missionId)
            .subscribe({
              next: response => this.fetchDeliverables(),
              error: err => console.log(err),
            });
        }
      }
    });

    this.subscriptions.push(dialogSub);
  }

  buildDeliverableDto(deliverable: OrderFormDeliverableModel): OrderRequestDeliverableDTO {
    let newDeliverable: OrderRequestDeliverableDTO = {
      deliverableId: deliverable.deliverableId,
      fields: [],
    };
    for (const f of deliverable.fields) {
      const newfield: OrderRequestDeliverableFieldDTO = {
        fieldId: f.id,
        fieldName: f.placeholder,
        fieldNumericValue: f.stringValue,
        fieldStringValue: f.stringValue,
      };
      newDeliverable.fields.push(newfield);
    }
    return newDeliverable;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  goBack() {
    this.selectedDeliverable = undefined;
    this.folderMissions = undefined;
    this.cdRef.detectChanges();
  }
  viewFiles(viewFile: DeliverableDto) {
    this.folderMissions = undefined;
    this.cdRef.detectChanges();

    this.folderMissions = {
      captureId: viewFile.lastCaptureId,
      deliverableId: viewFile.deliverableId,
      orderDetailId: viewFile.orderDetailId,
      missionId: this.missionId,
    };

    this.selectedDeliverable = viewFile.deliverableName;

    this.cdRef.detectChanges();
  }

  private configureActionsVisibility(): void {
    if (!this.missionStatusId) return;

    this.allowDeliverableTableShowFilesAction = !!this.currentActionConfiguration.find(
      t => t.code == this.DELIVERABLE_TABLE_SHOW_FILES_ACTION && t.statusId == this.missionStatusId
    );
  }
}
