import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';

@Component({
  selector: 'video-preview-modal',
  templateUrl: './video-preview-modal.component.html',
})
export class VideoPreviewModalComponent extends BaseModalComponent implements OnInit {
  
  @Input() 
  videoUrl: string;

  @Input() 
  fileName: string;

  @Input()
  public width: number = 700;

  @Input()
  public height: number = 350;

  @Output()
  public onCloseModal = new EventEmitter<void>;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  public closeModal() {
    this.onCloseModal.emit();
  }
}
