import { mapEnumToOptions } from '@abp/ng.core';

export enum recurrenceEnum {
  NoRepeat = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Biweekly = 4,
  Bimonthly = 5,
}

export const recurrenceEnumDisplayNames: Record<recurrenceEnum, string> = {
  [recurrenceEnum.NoRepeat]: 'Does Not Repeat',
  [recurrenceEnum.Daily]: 'Daily',
  [recurrenceEnum.Weekly]: 'Weekly',
  [recurrenceEnum.Biweekly]: 'Biweekly',
  [recurrenceEnum.Monthly]: 'Monthly',
  [recurrenceEnum.Bimonthly]: 'Bimonthly',
};

export const EnumRecurreceOptions = mapEnumToOptions(recurrenceEnum);
