<div>
  <div
    class="d-flex page-size align-items-center mt-1"
    *ngIf="customPageSize && customizing; else defaulPageSize"
  >
    <label for="customPageSize" class="me-1">
      {{ 'CoreService::CustomPageSize' | abpLocalization }}
    </label>

    <input
      id="customPageSize"
      class="page-size-input"
      type="number"
      #pageSizeInput
      [(ngModel)]="customSizeSelected"
      (keyup.enter)="customPageSizeSelected()"
      min="1"
      max="{{ maxCustomPageSize }}"
      value="{{ defaultCustomSize }}"
    />
    <button class="btn btn-sm btn-primary ms-1" (click)="customPageSizeSelected()">
      <i class="fas fa-check fa-xs"></i>
    </button>
    <button class="btn btn-sm btn-primary ms-1" (click)="toggleCustom()">
      <i class="fas fa-times fa-xs"></i>
    </button>

    <span class="ms-1 text-danger" *ngIf="customSizeSelected > maxCustomPageSize"
      >*{{ 'CoreService::MaxSize' | abpLocalization }}: {{ maxCustomPageSize }}</span
    >
  </div>

  <ng-template #defaulPageSize>
    <div class="d-flex page-size align-items-center">
      <label for="pageSize" class="me-1">
        {{ 'CoreService::PageSize' | abpLocalization }}
      </label>

      <select
        id="pageSize"
        [(ngModel)]="clientMaxResultCount"
        [ngModelOptions]="{ standalone: true }"
        (change)="pageSizeSelected()"
      >
        <option [ngValue]="value" *ngFor="let value of valuesMaxResultCount">
          {{ value }}
        </option>
      </select>

      <button class="btn btn-sm btn-primary ms-1" *ngIf="customPageSize" (click)="toggleCustom()">
        <i class="fas fa-pencil-alt fa-xs"></i>
      </button>
    </div>
  </ng-template>
</div>

<datatable-pager
  #pager
  class="d-flex justify-content-center align-items-center"
  [pagerLeftArrowIcon]="'datatable-icon-left'"
  [pagerRightArrowIcon]="'datatable-icon-right'"
  [pagerPreviousIcon]="'datatable-icon-prev'"
  [pagerNextIcon]="'datatable-icon-skip'"
  [page]="curPage"
  [size]="pageSize"
  [count]="rowCount"
  (change)="dataTable.onFooterPage($event)"
>
</datatable-pager>

<div class="d-flex number-of-pages justify-content-end" *ngIf="rowCount > 0">
  Entries {{ getRange() }} | {{ rowCount?.toLocaleString() }} total
</div>
