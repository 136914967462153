import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderEditingService {
  private isEditingSubject = new Subject<boolean>();
  isEditing$ = this.isEditingSubject.asObservable();

  startEditing() {
    this.isEditingSubject.next(true);
  }

  finishEditing() {
    this.isEditingSubject.next(false);
  }
}
