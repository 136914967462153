import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetPilotsInput, PilotCreateDto, PilotDto, PilotExcelDownloadDto, PilotUpdateDto, PilotWithNavigationPropertiesDto, ValidateExistingFaaDto } from '../../basics/models';
import type { DownloadTokenResultDto,LookupRequestDto,LookupUserDto } from '../../shared/models';
import { PilotPreferredLocationsDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';


@Injectable({
  providedIn: 'root',
})
export class PilotService {
  apiName = 'pilotsService';
  

  create = (input: PilotCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>({
      method: 'POST',
      url: '/api/pilots-service/pilots',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/pilots-service/pilots/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>({
      method: 'GET',
      url: `/api/pilots-service/pilots/${id}`,
    },
    { apiName: this.apiName,...config });
  
getWithNavigationProperties = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotWithNavigationPropertiesDto>({
      method: 'GET',
      url: `/api/pilots-service/pilots/pilot-user/${id}`,
    },
    { apiName: this.apiName,...config });


  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/pilots-service/pilots/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetPilotsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotDto>>({
      method: 'GET',
      url: '/api/pilots-service/pilots',
      params: { filterText: input.filterText, firstName: input.firstName, lastName: input.lastName, pilotExperienceLevelId: input.pilotExperienceLevelId, pilotStateId: input.pilotStateId, state: input.state, rating: input.rating, availabilityId: input.availabilityId, upcomingMissions: input.upcomingMissions, missionCountMin: input.missionCount, perfectMissionPercentage: input.perfectMissionPercentage, address: input.address, phone: input.phone, secondaryPhone: input.secondaryPhone, email: input.email, part107Number: input.part107Number, migrated: input.migrated, infoChecked: input.infoChecked, confirmedInfo: input.confirmedInfo, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  
  getListWithNavigationProperties = (input: GetPilotsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PilotWithNavigationPropertiesDto>>({
      method: 'GET',
      url: '/api/pilots-service/pilots/pilots-user',
      params: { filterText: input.filterText, firstName: input.firstName, lastName: input.lastName, pilotExperienceLevelId: input.pilotExperienceLevelId, pilotStateId: input.pilotStateId, state: input.state, rating: input.rating, availabilityId: input.availabilityId, upcomingMissions: input.upcomingMissions, missionCountMin: input.missionCount, perfectMissionPercentage: input.perfectMissionPercentage, address: input.address, phone: input.phone, secondaryPhone: input.secondaryPhone, email: input.email, part107Number: input.part107Number, migrated: input.migrated, infoChecked: input.infoChecked, confirmedInfo: input.confirmedInfo, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });

  getListAsExcelFile = (input: PilotExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/pilots-service/pilots/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: PilotUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>({
      method: 'PUT',
      url: `/api/pilots-service/pilots/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}

  getUserLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
  this.restService.request<any, PagedResultDto<LookupUserDto<string>>>({
    method: 'GET',
    url: '/api/pilots-service/pilots/user-lookup',
    params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
  },
  { apiName: this.apiName,...config });

  getPilotByUserId = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotDto>({
      method: 'GET',
      url: `/api/pilots-service/pilots/user-pilot/${userId}`
    },
    { apiName: this.apiName,...config });

  getPilotPreferredLocations = (pilotId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PilotPreferredLocationsDto[]>(
      {
        method: 'GET',
        url: `/api/pilots-service/pilots/${pilotId}/preferredLocations`,
      },
      {
        apiName: this.apiName,
        ...config,
      }
    );

  validateExistingFaa = (input: ValidateExistingFaaDto, config?: Partial<Rest.Config>)=>
    this.restService.request<any,boolean>(
    {
      method: 'GET',
      url: `/api/pilots-service/pilots/validate-faa?faa=${input.faa}&id=${input.id}`,
      body: input
    },
    {
      apiName: this.apiName,
      ...config,
    }
  ) 
}
