<div class="card">
  <div class="grid-wrapper">
    <mat-card class="p-2">
      <div class="d-flex h-100 gap-2">
        <div class="d-flex align-items-center justify-content-center">
          <mat-icon
            class="m-0 p-0 material-symbols-outlined"
            mat-card-avatar
            fontIcon="hotel_class"
          ></mat-icon>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <p class="open-sans fs-14">{{ 'pilotsService::CumulativeRating' | abpLocalization }}</p>
          <p class="m-0 raleway fs-26">{{ pilot.rating }}</p>
        </div>
      </div>
    </mat-card>

    <mat-card class="p-2">
      <div class="d-flex h-100 gap-2">
        <div class="d-flex align-items-center justify-content-center">
          <span class="icon m-0 p-0 material-symbols-outlined rounded-circle">
            social_leaderboard
          </span>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <p class="open-sans fs-14">{{ 'pilotsService::PerfectMissions' | abpLocalization }}</p>
          <p class="m-0 raleway fs-26">{{ pilot.perfectMissionPercentage }}</p>
        </div>
      </div>
    </mat-card>

    <mat-card class="p-2">
      <div class="d-flex h-100 gap-2">
        <div class="d-flex align-items-center justify-content-center">
          <mat-icon
            class="m-0 p-0 material-symbols-outlined"
            mat-card-avatar
            fontIcon="cameraswitch"
          ></mat-icon>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <p class="open-sans fs-14">{{ 'pilotsService::CompletedMissions' | abpLocalization }}</p>
          <p class="m-0 raleway fs-26">{{ pilot.missionCount }}</p>
        </div>
      </div>
    </mat-card>

    <!-- <mat-card class="p-2">
            <div class="d-flex h-100 gap-3">
                <div class="d-flex align-items-center justify-content-center">
                    <mat-icon class="m-0 p-0" mat-card-avatar fontIcon="stars"></mat-icon>
                </div> 
                <div class="d-flex flex-column justify-content-center">
                    <p class="open-sans fs-14">{{ 'pilotsService::Badges' | abpLocalization }}</p>
                    <div class="d-flex align-items-center justify-content-start gap-2">
                        <ng-container *ngFor="let badge of badges.items">
                            <img class="badge-icon" alt="{{badge.badgeName}}" src="{{badge.image}}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </mat-card> -->
  </div>
</div>
