import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ABP, ListService, PagedResultDto, EnvironmentService } from '@abp/ng.core';
import { eFileManagementPolicyNames } from '@volo/abp.ng.file-management/config';
import { finalize, tap } from 'rxjs/operators';
import {
  CreateDirectoryInput,
  DirectoryContentRequestInput,
  DirectoryDescriptorService,
  FileDescriptorDto,
  FileDescriptorService,
} from 'projects/file-management/proxy/src/lib';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import { ColumnAction } from '../../../components/columns/components/column-actions/column-actions.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilePreviewModel } from 'projects/file-management/src/lib/models/file-preview.model';

import { UploadService } from 'projects/file-management/src/lib/services/upload.service';
import { PilotFilesService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/relationals';
import {
  GetPilotFileInput,
  PilotFilesCreateDto,
} from 'projects/pilots-service/src/lib/proxy/pilots-service/relationals';
import { enumState } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../components/common/confirm-dialog/confirm.dialog.component';
import { PilotSourcingService } from '../../../services/pilot-sourcing.service';
import { PilotDocument } from '../../models/pilot-sourcing-dto';

@Component({
  selector: 'app-pilot-profile-documents',
  templateUrl: './pilot-profile-documents.component.html',
  providers: [ListService, UploadService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  styleUrls: ['./pilot-profile-documents.component.scss'],
})
export class PilotProfileDocumentsComponent implements OnInit {
  @ViewChild('fileModal') modal: TemplateRef<any>;
  @ViewChild('editFileModal') editFileModal: TemplateRef<any>;
  @ViewChild('removeFileModal') removeFileModal: TemplateRef<any>;
  @Input() pilotId: string;

  files: PagedResultDto<FileDescriptorDto> = {
    items: [],
    totalCount: 0,
  };

  public filePreviewItems: Array<FilePreviewModel> = [];

  filePreview: boolean = true;

  uploadFile: File;

  userDict: { [id: string]: string } = {};

  isModalCreateBusy = false;

  isModalCreateOpen = false;

  isModalEditBusy = false;

  isModalEditOpen = false;

  form: FormGroup;

  directoryId: string;

  fileCreatePermission = eFileManagementPolicyNames.FileDescriptorCreate;
  buttonId = 'upload-files-btn';
  apiName = 'pilotsService';

  constructor(
    public readonly list: ListService,
    public readonly directoryService: DirectoryDescriptorService,
    public readonly fileDescriptorService: FileDescriptorService,
    public readonly identityService: IdentityUserService,
    public readonly pilotFilesService: PilotFilesService,
    private fb: FormBuilder,
    private environment: EnvironmentService,
    private readonly _pilotSourcingService: PilotSourcingService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.createFolderIfNoExists();
    this.setDirectoryRootValues();

    this.identityService.getList({ maxResultCount: 10 }).subscribe(res => {
      for (let user of res.items) {
        this.userDict[user.id] = user.name;
      }
    });

    this.buildForm();
  }

  private createFolderIfNoExists() {
    var directory = { name: this.pilotId } as CreateDirectoryInput;
    this.directoryService.createIfNotExists(directory).subscribe(res => {
      this.directoryId = res.id;
    });
  }

  private setDirectoryRootValues(): void {
    var filters = {
      isPaginated: true,
      pilotId: this.pilotId,
      state: enumState.Enabled,
    } as GetPilotFileInput;
    const getData = (query: ABP.PageQueryParams) => {
      return this._pilotSourcingService.getFiles({
        ...query,
        ...filters,
      });
    };

    const setData = (list: PagedResultDto<PilotDocument>) => {
      this.files.items = list.items;
      this.files.totalCount = list.totalCount;
      this.filePreviewItems = [];

      const filterDirectory = { id: this.directoryId } as DirectoryContentRequestInput;
      this.directoryService.getContent(filterDirectory).subscribe(res => {
        this.filePreviewItems = [];
        res.items.forEach(element => {
          let file = new FilePreviewModel(
            element.name,
            element.imagePreviewUrl,
            element.videoPreviewUrl,
            element.fileContentPreviewUrl,
            element.thumbnailUrl,
            element,
          );
          if (file.previewSupported) {
            this.filePreviewItems.push(file);
          }
        });
      });
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  columnActions(record: PilotDocument) {
    let columnActions: ColumnAction[] = [
      {
        actionIcon: 'bi bi-pencil',
        abpPermission: eFileManagementPolicyNames.DirectoryDescriptorUpdate,
        action: { callAction: () => this.update(record) },
      },
      {
        actionIcon: 'bi bi-trash',
        abpPermission: eFileManagementPolicyNames.DirectoryDescriptorDelete,
        action: { callAction: () => this.delete(record) },
      },
      {
        actionIcon: 'bi bi-download',
        abpPermission: eFileManagementPolicyNames.DirectoryDescriptor,
        action: { callAction: () => this.download(record) },
      },
    ];

    return columnActions;
  }

  update(record: PilotDocument) {
    this.showEditForm(record);
  }

  delete(record: PilotDocument) {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete this file?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      width: '400px',
    });

    ref.afterClosed().subscribe({
      next: (shouldDelete: boolean) => {
        if (shouldDelete) {
          this.fileDescriptorService.delete(record.fileId).subscribe(() => {
            const filters = {
              isPaginated: true,
              pilotId: this.pilotId,
              fileId: record.fileId,
            } as GetPilotFileInput;

            this.pilotFilesService.getList(filters).subscribe(res => {
              for (let item of res.items) {
                this.pilotFilesService.delete(item.fileId).subscribe(this.list.get);
              }
            });
          });
        }
      },
    });
  }

  download(record: PilotDocument) {
    return this.fileDescriptorService.getDownloadToken(record.fileId).subscribe(res => {
      window.open(
        `${this.environment.getApiUrl(
          'FileManagement',
        )}/api/file-management/file-descriptor/download/${record.fileId}?token=${res.token}`,
        '_self',
      );
    });
  }

  hideForm() {
    this.dialog.closeAll();
    this.form.reset();
  }

  showForm() {
    this.dialog.open(this.modal, {
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });

    this.isModalCreateOpen = true;
  }

  buildForm() {
    this.form = this.fb.group({
      file: [null, [Validators.required]],
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.uploadFile = event.target.files[0];
    }
  }

  private generateFormData(file: File | null) {
    if (!file) {
      return null;
    }
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }

  submitCreateForm() {
    if (this.form.invalid) return;
    var formData = this.generateFormData(this.uploadFile);
    const request = this.fileDescriptorService.uploadFile(
      this.directoryId,
      this.uploadFile.name,
      null,
      formData,
    );

    this.isModalCreateBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalCreateBusy = false)),
        tap(() => this.hideForm()),
      )
      .subscribe(res => {
        var createPilotFile = {
          pilotId: this.pilotId,
          fileId: res.id,
          state: enumState.Enabled,
        } as PilotFilesCreateDto;
        this.pilotFilesService.create(createPilotFile).subscribe(this.list.get);
      });
  }

  hideEditForm() {
    this.dialog.closeAll();
    this.form.reset();
  }

  showEditForm(record: PilotDocument) {
    this.buildEditForm(record);
    this.dialog.open(this.editFileModal, {
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });
  }

  buildEditForm(record: PilotDocument) {
    this.form = this.fb.group({
      name: [record.name, [Validators.required]],
      id: [record.fileId, [Validators.required]],
      concurrencyStamp: [record.concurrencyStamp, [Validators.required]],
    });
  }

  submitEditForm() {
    if (this.form.invalid) return;
    const request = this.fileDescriptorService.rename(this.form.value.id, this.form.value);

    this.isModalEditBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalEditBusy = false)),
        tap(() => this.hideEditForm()),
      )
      .subscribe(this.list.get);
  }

  changeMode() {
    this.filePreview = !this.filePreview;
  }
}
