<h5 mat-dialog-title class="flg-modal flg-modal__title">Edit Attachments</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container p-0">
    <div class="row justify-content-center">
      <form [formGroup]="formAttachmentsNotes" skipValidation>
        <div class="w-100 mb-3">
          <mat-label class="d-block"
            >Attach reference documentation (Sample Images, Authorization forms, other)</mat-label
          >

          <div class="mt-2 mb-1">
            <app-file-upload
              (filesSelected)="onfilesSelected($event)"
              [allowMultiple]="true"
              [showFilesAttached]="false"
              [selectedFiles]="filesSelected"
              #fileUploadComponent
            ></app-file-upload>
          </div>
          <div *ngIf="attachmentsSelected.length > 0" class="mt-2">
            <mat-chip-list>
              <mat-chip
                *ngFor="let file of attachmentsSelected"
                [removable]="true"
                (removed)="onDocumentRemoved(file)"
                (click)="downloadDocument(file)"
                class="me-2 mt-2"
              >
                {{ file.fileName }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
  <div class="d-flex justify-content-end">
    <button mat-flat-button class="outline-button me-2" type="button" (click)="onClickClose()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="common-button"
      type="button"
      (click)="saveAttachmentsNotes()"
      [disabled]="!formAttachmentsNotes.valid || this.fileUploadInProgress == true"
      [class.disabled-button]="!formAttachmentsNotes.valid || this.fileUploadInProgress == true"
    >
      Save
    </button>
  </div>
</mat-dialog-actions>
