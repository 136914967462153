<abp-page [title]="'FileManagement::Menu:FileManagement' | abpLocalization">
  <div class="row gallery-list">
    <div class="col-sm-4 col-md-4" *ngFor="let item of filePreviewItems; let i = index">
      <mat-card class="mat-mdc-card mdc-card mb-5 tagus-card">
        <mat-card-content class="mat-mdc-card-content">
          <h4 class="mt-15">{{ item.fileName }}</h4>
          <span class="badge text-soft-danger" *ngIf="item.videoUrl && item.videoInProgress"
            >Encoding...</span
          >
          <image-viewer
            *ngIf="item.imageUrl || (item.videoUrl && item.videoInProgress)"
            [id]="'img' + i"
            [imageUrl]="item.imageUrl || 'assets/images/media/reproductor.jpg'"
            [pictureTitle]="item.fileName"
            [allowClick]="item.imageUrl"
            (onPictureClicked)="handleOnRequestFullScreen('img' + i)"
            customClass="mt-15"
          >
          </image-viewer>
          <media-player
            *ngIf="item.videoUrl && !item.videoInProgress"
            [reproductorId]="'repro' + i"
            [videoSource]="item.videoUrl"
            [videoName]="'video' + i"
            [width]="450"
            [height]="250"
            [autoplay]="false"
          >
          </media-player>
        </mat-card-content> </mat-card
      >&nbsp;
    </div>
  </div>
</abp-page>
