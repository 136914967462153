<div [hidden]="missionSelected">
  <mat-card class="p-4 mb-4" *ngIf="portfolioData">
    <div class="d-flex align-items-center mb-3">
      <app-fg-breadcrumb
        [breadcrumbs]="[
          { text: 'Orders', link: '/orders/portfolio' },
          { text: 'Porfolio', link: '/orders/portfolio' },
          { text: portfolioData.name },
        ]"
      ></app-fg-breadcrumb>
      <div class="d-flex">
        <app-fg-chip
          class="ml-4 no-shrink"
          text="{{ portfolioData?.portfolioStatus }}"
        ></app-fg-chip>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="d-flex align-items-center">
        <app-mission-info-badge
          [icon]="'person_raised_hand'"
          [subtitle]="'Requesting Customer:'"
          [description]="portfolioData?.customerName"
        >
        </app-mission-info-badge>
        <app-mission-info-badge
          [icon]="'calendar_today'"
          [subtitle]="'Portfolio Date Range:'"
          [description]="portfolioData?.portfolioRange"
        >
        </app-mission-info-badge>
      </div>
    </div>

    <div>
      <mat-tab-group
        #tabGroup
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        (selectedTabChange)="onTabChange($event)"
      >
        <mat-tab label="Dashboard">
          <ng-template matTabContent>
            <app-porfolio-dashbaord
              [portfolioId]="portfolioId"
              (onMissionSelected)="handleOnMissionSelected($event)"
            >
            </app-porfolio-dashbaord>
          </ng-template>
        </mat-tab>
        <mat-tab label="Missions">
          <ng-template matTabContent>
            <app-portfolio-missions
              [portfolioId]="portfolioId"
              [customerId]="portfolioData.customerId"
              (onMissionSelected)="handleOnMissionSelected($event)"
            >
            </app-portfolio-missions>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card>
</div>

<div *ngIf="missionSelected">
  <app-mission-flow
    [missionId]="missionSelected"
    [displayFromPortfolio]="true"
    (onGoBack)="handleOnGoBack()"
  >
  </app-mission-flow>
</div>
