import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  missionStatus,
  missionStatusOptions,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/mission-status.enum';
import { ABP, ConfigStateService, ListService, PagedResultDto } from '@abp/ng.core';
import { Observable, Subscription, combineLatest, switchMap } from 'rxjs';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import {
  MissionsDto,
  PortfolioStatusesDto,
  enumState,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  MissionsService,
  PortafoliosService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import * as shape from 'd3-shape';
import { MissionsGridFilters } from 'projects/missions-service/src/lib/basics/missions/components/models/missions-grid-filters';
import {
  ConfigurationAttributeTypesDto,
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
  GetStatusesInput,
  MissionStatusRoleLookup,
  StatusDto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import {
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
  MissionStatusRoleService,
  StatusService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { StatusMissionOrderService } from '../../../proxy/missions-service/controllers/basics/status-mission-order.service';
import { FilterConfig } from 'projects/flyguys/src/app/shared/grid-filters/models/filter-config.model';
import { PortfolioMissionsFilterColumns } from '../../missions/components/models/portfolio-missions-filter-columns';
import { NgxSpinnerService } from 'ngx-spinner';
import { StatusAndAssignedPermissions } from 'projects/flyguys/src/app/shared/grid-filters/models/status-and-assigned-permissions';
import { PaginationSortingAndGlobalSearch } from 'projects/flyguys/src/app/shared/grid-filters/models/pagination-sorting-and-global-search';
import { enumMissionStatusMapping } from '../../../proxy/missions-service/basics/enum-mission-status-mapping.enum';
import { NotificationBroadcastService } from 'projects/flyguys/src/app/services/NotificationBroadcast.service';
import { ScaleType, SingleSeries, escapeLabel, formatLabel } from '../models/chart.model';
import { DataItem } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-porfolio-dashbaord',
  templateUrl: './portfolio-dashboard.component.html',
  styleUrls: ['./portfolio-dashboard.component.scss'],
})
export class PortfolioDashboardComponent implements OnInit, OnDestroy {
  private readonly MAX_COUNT_ATTR = 'MaxResultCount';
  private readonly PAGE_SIZE_ATTR = 'PageSize';

  @Input() portfolioId: string;

  @Output() onMissionSelected = new EventEmitter<string>();

  currentUserRoles: Array<string>;
  valuesMaxResultCount = [];
  maxResultCount = 100;
  pageNumber = 0;
  currentStatuses: Array<StatusDto>;
  filterConfig: FilterConfig = PortfolioMissionsFilterColumns;
  mData: PagedResultDto<MissionsDto> = {
    items: [],
    totalCount: 0,
  };
  clientMaxResultCount = 10;

  barChartData: Array<any> = [];
  missionGridFilters: MissionsGridFilters = {};

  statusfilter: string;
  textStatusFiltered: string;

  missionIdList: Array<string>;
  subscription = new Subscription();

  public readonly missionStatus = missionStatusOptions;

  statusAndAssignedPermissions: StatusAndAssignedPermissions = {
    assignedToAnyone: true,
    inAnyStatus: false,
    assignedToMe: false,
    inTheTeam: false,
    allRecords: true,
  };

  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearch = {
    skipCount: 0,
    maxResultCount: this.maxResultCount,
  };

  portfolioPerformanceData: SingleSeries = [];

  view: [number, number] = [700, 350]; // Width and height of the chart

  colorScheme = {
    domain: ['#BC5A2E', '#503016', '#38332E', '#445F4F', '#626955', '#235656'],
  };

  performanceScheme = {
    domain: ['#626955', '#A1A599', '#C0C3BB'],
  };

  xAxisLabel = 'Categories';
  yAxisLabel = 'Values';

  range: boolean = false;
  schemeType = ScaleType.Ordinal;
  viewBurndown: [number, number] = [500, 300];

  constructor(
    private notificationBroadcastService: NotificationBroadcastService,
    private stateService: ConfigStateService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    private readonly statusService: StatusService,
    private readonly missionStatusRoleService: MissionStatusRoleService,
    private readonly statusMissionOrderService: StatusMissionOrderService,
    private spinner: NgxSpinnerService,
    public readonly service: MissionsService,
    public readonly list: ListService,
    public readonly portfolioService: PortafoliosService,
  ) {}

  ngOnInit() {
    combineLatest([
      this.getPageAttributeValues(),
      this.getMaxCountAttribute(),
      this.getStatuses(),
      this.getMissionStatusRoleConfiguration(),
      this.getPortfolioStatuses(),
      this.getPorfolioMissionIds(this.portfolioId),
    ]).subscribe({
      next: ([
        pageAttributes,
        maxCountAttribute,
        statuses,
        missionStatusRole,
        portfolioStatuses,
        missionList,
      ]) => {
        this.setPageAttributes(pageAttributes);
        this.setMaxCountAttribute(maxCountAttribute);

        this.currentStatuses = statuses.items;

        this.updateMissionStatusOptions();

        this.missionGridFilters.MissionStatusRoles = missionStatusRole;

        this.callApiWithFilters();

        this.buildPortfolioStatuses(portfolioStatuses);

        this.buildPortfolioPerformance(portfolioStatuses);

        this.missionIdList = missionList;

        this.spinner.hide();
      },
      error: err => {
        console.error(`Mission List, error while fetching data: ${err}`);
        this.spinner.hide();
      },
    });

    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionStatus) {
          if (
            notif.itemId &&
            (notif.extraArgument.missionStatus || notif.extraArgument.missionStatus == 0) &&
            notif.extraArgument.missionStatusCode &&
            this.missionIdList.find(x => x == notif.itemId)
          ) {
            this.callApiWithFilters();
            this.refreshPortfolioStatuses();
          }
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  barSelected(event: any) {
    this.statusfilter = event.extra;
    this.textStatusFiltered = event.label;

    this.pageNumber = 0;
    this.paginationSortingAndGlobalSearch.skipCount = 0;

    this.callApiWithFilters([this.statusfilter]);
  }

  segmentSelected(event: any) {
    this.textStatusFiltered = event.label;

    let statusesSelected = event.extra;

    this.pageNumber = 0;

    this.paginationSortingAndGlobalSearch.skipCount = 0;

    this.callApiWithFilters(statusesSelected);
  }

  cleanFilters() {
    this.statusfilter = undefined;
    this.textStatusFiltered = undefined;

    this.missionGridFilters.missionStatusId = [];

    this.callApiWithFilters();
  }

  pieTooltipText({ data }) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);

    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">${val}</span>
    `;
  }

  onPaginationChange(event: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: 0,
      maxResultCount: event,
    };

    this.callApiWithFilters();
  }

  onSort(sortInfo: any) {
    if (sortInfo.sorts && sortInfo.sorts.length > 0) {
      const sort = sortInfo.sorts[0];
      const sortingCriteria = `${sort.prop} ${sort.dir}`;

      this.paginationSortingAndGlobalSearch.sorting = sortingCriteria;

      this.callApiWithFilters();
    }
  }

  setPage(pageInfo: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: pageInfo.offset * pageInfo.pageSize,
      maxResultCount: pageInfo.pageSize,
    };

    this.callApiWithFilters();
  }

  public handleOnOpenMission(missionId: string): void {
    this.onMissionSelected.emit(missionId);
  }

  private refreshPortfolioStatuses() {
    this.portfolioService.getStatuses(this.portfolioId).subscribe({
      next: resp => {
        this.buildPortfolioStatuses(resp);
      },
      error: () => console.error('error getting portfolio data'),
    });
  }

  private buildPortfolioStatuses(statuses: Array<PortfolioStatusesDto>): void {
    this.barChartData = statuses.map(x => {
      return {
        name: x.description,
        value: x.count,
        extra: x.status,
      };
    });
  }

  private getMissionEnumIndexValue(enumValue: string): number {
    const enumValues = Object.values(missionStatus);
    return enumValues.findIndex(x => x == enumValue);
  }

  private buildPortfolioPerformance(statuses: Array<PortfolioStatusesDto>): void {
    let completeStatuses = [
      this.getMissionEnumIndexValue(missionStatus.Cancelled),
      this.getMissionEnumIndexValue(missionStatus.ClientInvoiced),
      this.getMissionEnumIndexValue(missionStatus.DEAD),
    ];

    let onHoldStatuses = [this.getMissionEnumIndexValue(missionStatus.OnHold)];
    const completedKey = 'Completed';
    const onHoldKey = 'On Hold';
    const inProgressKey = 'In Progress';

    for (let x of statuses) {
      // completed
      if (completeStatuses.find(t => t.toString() == x.status)) {
        let completedItem = this.portfolioPerformanceData.find(t => t.name == completedKey);

        if (completedItem) {
          completedItem.value += x.count;
        } else {
          this.portfolioPerformanceData.push(<DataItem>{
            name: completedKey,
            value: x.count,
            extra: completeStatuses,
          });
        }
      } else if (onHoldStatuses.find(t => t.toString() == x.status)) {
        // onhold

        let onHoldItem = this.portfolioPerformanceData.find(t => t.name == onHoldKey);

        if (onHoldItem) {
          onHoldItem.value += x.count;
        } else {
          this.portfolioPerformanceData.push(<DataItem>{
            name: onHoldKey,
            value: x.count,
            extra: onHoldStatuses,
          });
        }
      } else {
        let inProgressItem = this.portfolioPerformanceData.find(t => t.name == inProgressKey);

        if (inProgressItem) {
          inProgressItem.value += x.count;
        } else {
          this.portfolioPerformanceData.push(<DataItem>{
            name: inProgressKey,
            value: x.count,
            extra: [
              this.getMissionEnumIndexValue(missionStatus.AwaitingFligth),
              this.getMissionEnumIndexValue(missionStatus.AwaitingMCAssignment),
              this.getMissionEnumIndexValue(missionStatus.AwaitingQAQCAssignment),
              this.getMissionEnumIndexValue(missionStatus.CustomerRequest),
              this.getMissionEnumIndexValue(missionStatus.DataProccessing),
              this.getMissionEnumIndexValue(missionStatus.DataUploading),
              this.getMissionEnumIndexValue(missionStatus.LogisticsCoordination),
              this.getMissionEnumIndexValue(missionStatus.PilotAssigned),
              this.getMissionEnumIndexValue(missionStatus.PilotCheckedIn),
              this.getMissionEnumIndexValue(missionStatus.PilotOnSite),
              this.getMissionEnumIndexValue(missionStatus.PilotPending),
              this.getMissionEnumIndexValue(missionStatus.PilotSourcing),
              this.getMissionEnumIndexValue(missionStatus.QAQC),
              this.getMissionEnumIndexValue(missionStatus.QAQCApproved),
              this.getMissionEnumIndexValue(missionStatus.QAQCNotApproved),
              this.getMissionEnumIndexValue(missionStatus.QAQCPending),
              this.getMissionEnumIndexValue(missionStatus.ReadyForSourcing),
              this.getMissionEnumIndexValue(missionStatus.ReadyToInvoice),
              this.getMissionEnumIndexValue(missionStatus.RequestRefly),
              this.getMissionEnumIndexValue(missionStatus.UnderMCReview),
              this.getMissionEnumIndexValue(missionStatus.AirspacePendingPilotRequest),
              this.getMissionEnumIndexValue(missionStatus.AirspaceRequested),
              this.getMissionEnumIndexValue(missionStatus.AirspaceApproved),
            ],
          });
        }
      }
    }
  }

  private getMissionValues(portfolioId: string): Observable<PagedResultDto<MissionsDto>> {
    return this.service.getPortfolioMissionsData(
      portfolioId,
      this.missionGridFilters,
      this.statusAndAssignedPermissions,
      this.paginationSortingAndGlobalSearch,
    );
  }

  private getPorfolioMissionIds(portfolioId: string): Observable<Array<string>> {
    return this.portfolioService.getMissionIds(portfolioId);
  }

  private callApiWithFilters(statusFilter?: Array<string>) {
    this.spinner.show();

    if (statusFilter && statusFilter.length) {
      this.missionGridFilters.missionStatusId = statusFilter;
    }

    this.getMissionValues(this.portfolioId).subscribe({
      next: (missionValues: PagedResultDto<MissionsDto>) => {
        this.setMissionValues(missionValues);
        this.spinner.hide();
      },
      error: error => {
        console.log(error);
        this.spinner.hide();
      },
    });
  }

  private updateMissionStatusOptions(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'missionStatusId');
    const conditionNoStatus = this.filterConfig.conditions.find(
      c => c.column === 'noMissionStatusId',
    );

    if (condition) {
      this.statusMissionOrderService
        .getList({ maxResultCount: 1000, sorting: 'order' })
        .subscribe(res => {
          condition.options = res.items.map(status => {
            return { id: status.statusEnum.toString(), description: status.statusDescription };
          });

          if (conditionNoStatus) conditionNoStatus.options = condition.options;
        });
    }
  }

  private setMaxCountAttribute(res: PagedResultDto<ConfigurationAttributeTypesDto>) {
    let attribute = res.items.find(_ => true);
    this.maxResultCount = parseInt(attribute.description);
  }

  private setPageAttributes(res: PagedResultDto<ConfigurationAttributeTypesDto>) {
    let attribute = res.items.find(_ => true);
    this.valuesMaxResultCount = attribute.description.split(',').map(val => parseInt(val, 10));
  }

  private getMissionStatusRoleConfiguration(): Observable<MissionStatusRoleLookup[]> {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    return this.missionStatusRoleService.filterByRoles(this.currentUserRoles);
  }

  private getStatuses(): Observable<PagedResultDto<StatusDto>> {
    return this.statusService.getList(<GetStatusesInput>{});
  }

  private getPortfolioStatuses(): Observable<Array<PortfolioStatusesDto>> {
    return this.portfolioService.getStatuses(this.portfolioId);
  }

  private getPageAttributeValues(): Observable<PagedResultDto<ConfigurationAttributeTypesDto>> {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.PAGE_SIZE_ATTR,
    } as GetConfigurationTypeInput;

    return this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      );
  }

  private getMaxCountAttribute(): Observable<PagedResultDto<ConfigurationAttributeTypesDto>> {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_COUNT_ATTR,
    } as GetConfigurationTypeInput;

    return this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      );
  }

  private setMissionValues(list: PagedResultDto<MissionsDto>) {
    this.mData = list;
    this.maxResultCount = list.totalCount;
    this.mapCurrentStatusEnumValues(this.mData.items);
  }

  private mapCurrentStatusEnumValues(missions: MissionsDto[]): void {
    missions.forEach(x => {
      x.statusCode = enumMissionStatusMapping[x.missionStatus];

      if (!x.statusCode) return;

      x.statusId = this.currentStatuses.find(s => s.code == x.statusCode).id;
    });
  }
}
