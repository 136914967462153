import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetOrderInput,
  OrdersCreateDto,
  OrdersDto,
  OrdersExcelDownloadDto,
  OrdersUpdateDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { OrderRequestDTO } from 'projects/flyguys/src/app/components/orders/dto/order-request.dto';
import { OrderRequestMissionProjectDTO } from 'projects/flyguys/src/app/components/orders/dto/order-request-mission-project.dto';
import { OrderRequestTotalMissionsDTO } from 'projects/flyguys/src/app/components/orders/dto/order-request-total-missions.dto';
import { OrderSummary } from '../../relationals/models';
import { MissionBulkDTO } from 'projects/flyguys/src/app/components/orders/dto/order-bulk-dto';
import { of } from 'rxjs';
import { PortfolioMissionDTO } from 'projects/flyguys/src/app/components/orders/dto/portfolio-mission.dto';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  apiName = 'missionsService';

  create = (input: OrdersCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'POST',
        url: '/api/missions-service/order',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/order/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'GET',
        url: `/api/missions-service/order/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getOrderSummaryByMission = (id: string, config?: Partial<Rest.Config>) =>
  this.restService.request<any, OrderSummary>(
    {
      method: 'GET',
      url: `/api/missions-service/order/mission/${id}/summary`,
    },
    { apiName: this.apiName, ...config }
  );

  updateOrderSummaryDetail = (input: any, config?: Partial<Rest.Config>) =>
  this.restService.request<any, OrderSummary>(
    {
      method: 'PUT',
      url: `/api/missions-service/order/mission/detail`,
      body: input,
    },
    { apiName: this.apiName, ...config }
  );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/order/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: OrdersExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/order/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetOrderInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<OrdersDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/order',
        params: {
          filterText: input.filterText,
          portfolioId: input.portfolioId,
          requestingCustomerId: input.requestingCustomerId,
          billingCustomerId: input.billingCustomerId,
          endCustomerId: input.endCustomerId,
          totalPriceMin: input.totalPriceMin,
          totalPriceMax: input.totalPriceMax,
          industryId: input.industryId,
          state: input.state,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: OrdersUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/order/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  createOrderRequest = (input: OrderRequestDTO, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'POST',
        url: '/api/missions-service/order/create-mission',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  createPortfolioMission = (input: PortfolioMissionDTO, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'POST',
        url: '/api/missions-service/order/create-portfolio-mission',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

    createOrderRequestBU = (input: OrderRequestDTO, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrdersDto>(
      {
        method: 'POST',
        url: '/api/missions-service/order/create-mission-BulkUpload',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getTotalMissions = (input: OrderRequestMissionProjectDTO, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderRequestTotalMissionsDTO>(
      {
        method: 'POST',
        url: '/api/missions-service/order/total-missions-to-create',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  ordersfromBulk = (excelFileId:string, input:MissionBulkDTO[], config?: Partial<Rest.Config>) => 
    // this.restService.request<any, string>(
    //   {
    //     method: 'POST',
    //     url: `/api/missions-service/order/orders-import/${excelFileId}`,
    //     body: input,
    //   },
    //   { apiName: this.apiName, ...config }
    // );
    of("123abc456");

  bulkOrdersStatus = (orderImportId, config?: Partial<Rest.Config>) => 
    // this.restService.request<any, OrderBulkDTO[]>(
    //   {
    //     method: 'GET',
    //     url: `/api/missions-service/order/${orderImportId}`
    //   },
    //   { apiName: this.apiName, ...config }
    // );
    of([
      {
        MissionName: "Mission 1",
        Status: 1
      },
      {
        MissionName: "Mission 2",
        Status: 1
      },
      {
        MissionName: "Mission 3",
        Status: 1
      },
      {
        MissionName: "Mission 4",
        Status: 2
      },
      {
        MissionName: "Mission 5",
        Status: 2
      }
    ]);

  constructor(private restService: RestService) {}
}
