<div class="card w-100 mb-3">
  <div class="card-body p-1 p-md-2 p-lg-3">
    <h2>Messages</h2>
    <div class="mb-2 rounded-2">
      <div class="row">
        <div class="col">
          <input
            id="contacts-filter"
            type="search"
            class="form-control form-control"
            autocomplete="off"
            spellcheck="false"
            [(ngModel)]="filter"
            [placeholder]="'Chat::SearchInContacts' | abpLocalization"
            [attr.aria-label]="'Chat::SearchInContacts' | abpLocalization"
            (input.debounce)="get()"
          />
        </div>
      </div>
    </div>

    <div class="messages-box m-0 p-0">
      <div class="list-group rounded-3" id="contact-list">
        <ng-container
          *ngFor="let contact of contacts; let i = index; trackBy: trackByUserId"
          [ngTemplateOutlet]="contactTemplate"
          [ngTemplateOutletContext]="{ $implicit: contact, index: i }"
        >
        </ng-container>
        <div
          *ngIf="otherContacts.length"
          class="list-group-item px-2 py-1 bg-light text-muted text-center font-size-sm text-uppercase border-start-0 border-end-0"
        >
          <small>{{ 'Chat::OtherContacts' | abpLocalization }}</small>
        </div>
        <ng-container
          *ngFor="let contact of otherContacts; let i = index; trackBy: trackByUserId"
          [ngTemplateOutlet]="contactTemplate"
          [ngTemplateOutletContext]="{ $implicit: contact, index: i }"
        >
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #contactTemplate let-contact let-i="index">
  <a
    (click)="selectContact(contact)"
    class="chat-contact list-group-item list-group-item-action cursor-pointer px-2 border-2 rounded-2 mb-1 mt-0 {{
      selectedContact.userId === contact.userId ? 'active' : ''
    }}"
  >
    <div class="media">
      <abp-conversation-avatar [contact]="contact"></abp-conversation-avatar>
      <div class="media-body ms-2" *ngIf="!validateImage(separateMessages(contact.lastMessage))">
        <div class="d-flex align-items-center justify-content-between mb-0">
          <h4 class="mb-1 mt-1">
            <small *ngIf="contact.unreadMessageCount" class="badge badge-success me-1">{{
              contact.unreadMessageCount
            }}</small
            >{{ getContactName(contact) }}
          </h4>
          <small class="last-message-date">{{ getDateInLocalTime(contact.lastMessageDate) }}</small>
        </div>
        <p class="mb-0 small last-message">
          {{
            separateMessages(contact.lastMessage)?.length > 24
              ? (separateMessages(contact.lastMessage) | slice: 0 : 23) + '...'
              : separateMessages(contact.lastMessage)
          }}
        </p>
      </div>
      <div class="media-body ms-2" *ngIf="validateImage(separateMessages(contact.lastMessage))">
        <div class="d-flex align-items-center justify-content-between mb-0">
          <h4 class="mb-1 mt-1">
            <small *ngIf="contact.unreadMessageCount" class="badge badge-success me-1">{{
              contact.unreadMessageCount
            }}</small
            >{{ getContactName(contact) }}
          </h4>
          <small class="last-message-date">{{ getDateInLocalTime(contact.lastMessageDate) }}</small>
        </div>
        <img
          [id]="getImageProp(separateMessages(contact.lastMessage), 'id')"
          [src]="getImageProp(separateMessages(contact.lastMessage), 'src')"
          [alt]="getImageProp(separateMessages(contact.lastMessage), 'name')"
          class="chat-img-conversations-contact"
          (click)="viewErrorzoomImage(separateMessages(contact.lastMessage))"
        />
      </div>
    </div>
  </a>
</ng-template>
