import type {
  CreateFileInputWithStream,
  DownloadTokenResultDto,
  FileDescriptorDto,
  FileUploadPreInfoDto,
  FileUploadPreInfoRequest,
  MoveFileInput,
  RenameFileInput,
  ZipDownloadResult,
} from './models';
import { EnvironmentService, RestService } from '@abp/ng.core';
import type { ListResultDto, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IRemoteStreamContent } from '../../abp/content/models';
import { DeliverableDto } from '../../../../../../../flyguys/src/app/models/products-deliverables/deliverable-dto';

@Injectable({
  providedIn: 'root',
})
export class FileDescriptorService {
  apiName = 'FileManagement';

  create = (directoryId: string, inputWithStream: CreateFileInputWithStream) =>
    this.restService.request<any, FileDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/file-descriptor/upload',
        params: {
          directoryId,
          name: inputWithStream.name,
          extraProperties: inputWithStream.extraProperties,
        },
      },
      { apiName: this.apiName },
    );

  uploadFile = (directoryId: string, name: string, extraProperties, formData: FormData) =>
    this.restService.request<any, FileDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/file-descriptor/upload',
        params: { directoryId, name, extraProperties },
        body: formData,
      },
      { apiName: this.apiName },
    );

  uploadAttachMentsFolder = (folderName: string, subfolderName: string, formData: FormData) =>
    this.restService.request<any, FileDescriptorDto[]>(
      {
        method: 'POST',
        url: '/api/file-management/file-descriptor/upload-attachments-folder',
        params: { folderName, subfolderName },
        body: formData,
      },
      { apiName: this.apiName },
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/file-management/file-descriptor/${id}`,
      },
      { apiName: this.apiName },
    );

  download = (id: string, token: string) =>
    this.restService.request<any, IRemoteStreamContent>(
      {
        method: 'GET',
        url: `/api/file-management/file-descriptor/download/${id}`,
        params: { token },
      },
      { apiName: this.apiName },
    );

  downloadFile = (id: string, token: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: `/api/file-management/file-descriptor/download/${id}`,
        params: { token },
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string) =>
    this.restService.request<any, FileDescriptorDto>(
      {
        method: 'GET',
        url: `/api/file-management/file-descriptor/${id}`,
      },
      { apiName: this.apiName },
    );

  getContent = (id: string) =>
    this.restService.request<any, number[]>(
      {
        method: 'GET',
        url: '/api/file-management/file-descriptor/content',
        params: { id },
      },
      { apiName: this.apiName },
    );

  getDownloadToken = (id: string) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: `/api/file-management/file-descriptor/download/${id}/token`,
      },
      { apiName: this.apiName },
    );

  getList = (directoryId: string) =>
    this.restService.request<any, ListResultDto<FileDescriptorDto>>(
      {
        method: 'GET',
        url: '/api/file-management/file-descriptor',
        params: { directoryId },
      },
      { apiName: this.apiName },
    );

  getPreInfo = (input: FileUploadPreInfoRequest[]) =>
    this.restService.request<any, FileUploadPreInfoDto[]>(
      {
        method: 'POST',
        url: '/api/file-management/file-descriptor/pre-upload-info',
        body: input,
      },
      { apiName: this.apiName },
    );

  move = (input: MoveFileInput) =>
    this.restService.request<any, FileDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/file-descriptor/move',
        body: input,
      },
      { apiName: this.apiName },
    );

  rename = (id: string, input: RenameFileInput) =>
    this.restService.request<any, FileDescriptorDto>(
      {
        method: 'POST',
        url: `/api/file-management/file-descriptor/${id}`,
        body: input,
      },
      { apiName: this.apiName },
    );

  getDownloadTokenZip = () =>
    this.restService.request<any, ZipDownloadResult>(
      {
        method: 'GET',
        url: '/api/file-management/file-descriptor/downloads/token',
      },
      {
        apiName: this.apiName,
      },
    );

  downloadZipFileUrl(
    url: string,
    token: string,
    filesIds: string,
    missionId: string,
    deliverable?: DeliverableDto,
  ) {
    const fileName = this.createZipFileName(deliverable);
    let downloadUrl = `${url}/api/file-management/file-descriptor/downloads/zip?token=${token}&filesIds=${filesIds}&missionId=${missionId}`;
    window.open(downloadUrl, '_self');
    if (fileName && fileName != null) {
      downloadUrl += `&fileName=${fileName}`;
    }
    window.open(downloadUrl, '_self');
  }

  private createZipFileName(deliverable: DeliverableDto): string | null {
    if (!deliverable) {
      return null;
    }
    let fileName = '';
    if (deliverable.deliverableName) {
      fileName += deliverable.deliverableName;
    }
    if (deliverable.packageName) {
      fileName += `__${deliverable.packageName}`;
    }
    if (deliverable.productName) {
      fileName += `__${deliverable.productName}`;
    }
    return fileName;
  }

  validateContent = (filesIds: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/file-management/file-descriptor/validate-exist-files?filesIds=${filesIds}`,
      },
      { apiName: this.apiName },
    );

  getFilesExifData = (rootFolderId: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/file-management/file-descriptor/get-files-exif-data/${rootFolderId}`,
      },
      { apiName: this.apiName },
    );

  constructor(
    private restService: RestService,
    private readonly environment: EnvironmentService,
  ) {}
}
