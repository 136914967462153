<abp-page>
    <div class="d-flex mb-4 align-items-center sticky-header">
      <h1 class="mb-0">{{ 'missionsService::BulkUploadRequests' | abpLocalization }}</h1>
    </div>
  
    <abp-advanced-entity-filters localizationSourceName="CoreService" [list]="list">
      <abp-advanced-entity-filters-form>
        <form #filterForm (keyup.enter)="list.get()">
          <div class="row">
            <div class="col-12 col-sm-auto">
              <div class="mb-3">
                <label class="form-label" for="fileNameFilter">
                  {{ 'missionsService::FileName' | abpLocalization }}
                </label>
  
                <input
                  id="fileNameFilter"
                  class="form-control"
                  [(ngModel)]="filters.fileName"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>


            <div class="col-12 col-sm-auto">
              <div class="mb-3">
                <label class="form-label" for="customerIdFilter">
                  {{ 'missionsService::Customer' | abpLocalization }}
                </label>
  
                <abp-lookup-input
                  cid="customerIdFilter"
                  displayNameProp="name"
                  lookupNameProp="name"
                  [getFn]="customerService.getList"
                  [(ngModel)]="filters.customerId"
                  [ngModelOptions]="{ standalone: true }"
                ></abp-lookup-input>
              </div>
            </div>
  
            <div class="col-12 col-sm-auto">
              <div class="mb-3">
                <label class="form-label" for="priorityIdFilter">
                  {{ 'missionsService::Priority' | abpLocalization }}
                </label>
  
                <select
                  class="form-control"
                  id="priorityIdFilter"
                  [(ngModel)]="filters.priorityId"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="list.get()"
                >
                  <option [ngValue]=""></option>
                  <option
                    [ngValue]="priority.id"
                    *ngFor="let priority of priorities.items"
                  >
                    {{ priority.description | abpLocalization }}
                  </option>
                </select>
              </div>
            </div>
  
            <div class="col-12 col-sm-auto">
              <div class="mb-3">
                <label class="form-label" for="industryIdFilter">
                  {{ 'missionsService::Industry' | abpLocalization }}
                </label>
  
                <select
                  class="form-control"
                  id="industryIdFilter"
                  [(ngModel)]="filters.industryId"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="list.get()"
                >
                  <option [ngValue]=""></option>
                  <option
                    [ngValue]="industry.id"
                    *ngFor="let industry of industries.items"
                  >
                    {{ industry.name | abpLocalization }}
                  </option>
                </select>
              </div>
            </div>
  
            <div class="col-12 col-sm-auto">
              <div class="mb-3">
                <label class="form-label" for="bulkUploadStatus">
                  {{ 'missionsService::Status' | abpLocalization }}
                </label>
  
                <select
                  id="bulkUploadStatus"
                  class="form-select form-control"
                  [(ngModel)]="filters.bulkUploadStatus"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="list.get()"
                >
                  <option [ngValue]=""></option>
                  <option [ngValue]="status.value" *ngFor="let status of statuses">
                    {{ 'missionsService::Enum'+status.key | abpLocalization }}
                  </option>
                </select>
              </div>
            </div>
  
            <div class="col-12 col-sm-auto align-self-end mb-3">
              <div class="row">
                <div class="col-6 col-sm-auto d-grid">
                  <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                    <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                  </button>
                </div>
                <div class="col-6 col-sm-auto d-grid">
                  <button type="button" class="btn btn-primary" (click)="list.get()">
                    <span>{{ 'Flyguys::ApplyFilter' | abpLocalization }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
  
    <div class="card">
      <div class="card-body">
        <ngx-datatable
        [rows]="data.items"
        [offset]="pageNumber"
        [count]="data.totalCount"
        [list]="list"
        [limit]="clientMaxResultCount"
        [externalPaging]="true"
        default
        #dataTable
      >
        <ngx-datatable-column
          name="{{ 'missionsService::FileName' | abpLocalization }}"
          prop="fileName"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.fileName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Customer' | abpLocalization }}"
          prop="customerName"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.customerName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Priority' | abpLocalization }}"
          prop="priorityName"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.priorityName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Industry' | abpLocalization }}"
          prop="industryName"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.industryName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Status' | abpLocalization }}"
          prop="bulkUploadStatus"
          *ngIf="showedColumns.bulkUploadStatus"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <app-column-stylizer
              [columnValue]="row.bulkUploadStatus"
              [typeColumn]="'text'"
              [columnRules]="orderBulkStates"
            ></app-column-stylizer>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::RequestedAt' | abpLocalization }}"
          prop="creationTime"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.creationTime | date : 'short' }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'missionsService::Preview' | abpLocalization"
          [maxWidth]="150"
          [width]="150"
          [sortable]="false"
        >
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <app-column-actions [columnActions]="columnActions(row)"></app-column-actions>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-curPage="curPage"
          >
            <app-table-paginator
              [(maxResultCount)]="list.maxResultCount"
              [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount"
              [pageSize]="pageSize"
              [(pageNumber)]="pageNumber"
              [curPage]="curPage"
              [dataTable]="dataTable"
              [customPageSize]="true"
              style="width: 100%"
            >
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</abp-page>
