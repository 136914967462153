<div class="d-flex justify-content-between mb-2">
  <h3>{{ 'pilotsService::Documents' | abpLocalization }}</h3>
  <div>
    <button type="button" class="btn btn-link" (click)="changeMode()">
      <span *ngIf="!filePreview" class="material-symbols-rounded"> list </span>
      <span *ngIf="filePreview" class="material-symbols-rounded"> apps </span>
    </button>

    <button type="button" mat-flat-button class="common-button" (click)="showForm()">
      <mat-icon aria-hidden="false" fontIcon="upload"></mat-icon>
      {{ 'pilotsService::UploadFiles' | abpLocalization }}
    </button>
  </div>
</div>
<div>
  <div *ngIf="filePreview">
    <ngx-datatable
      class="flg-grid-table"
      [rowHeight]="45"
      [headerHeight]="50"
      [footerHeight]="55"
      [rows]="files.items"
      [count]="files.totalCount"
      [list]="list"
      [trackByProp]="'id'"
      default
    >
      <ngx-datatable-column name="{{ 'pilotsService::FileName' | abpLocalization }}" prop="name">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.name }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'pilotsService::Uploadedby' | abpLocalization }}"
        prop="creatorId"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ userDict[row.creatorId] }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'pilotsService::FileSize' | abpLocalization }}" prop="size">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.size | fileSize }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'pilotsService::LastModificationTime' | abpLocalization }}"
        prop="lastModificationTime"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.lastModificationTime | date : 'shortDate' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [name]="'AbpUi::Actions' | abpLocalization"
        [maxWidth]="150"
        [width]="150"
        [sortable]="false"
        [frozenRight]="true"
      >
        <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
          <app-column-actions [columnActions]="columnActions(row)"></app-column-actions>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <div *ngIf="!filePreview">
    <div class="row">
      <mat-card
        class="mat-mdc-card mdc-card mb-5 tagus-card col-sm-3 col-md-3"
        *ngFor="let item of filePreviewItems; let i = index"
      >
        <mat-card-content class="mat-mdc-card-content">
          <h4 class="mt-15">{{ item.fileName }}</h4>
          <span class="badge text-soft-danger" *ngIf="item.videoInProgress">{{
            'pilotsService::Encoding' | abpLocalization
          }}</span>
          <image-viewer
            *ngIf="item.HasPreviewURL()"
            [id]="'img' + i"
            [allowReproduce]="!!item.videoUrl"
            [imageUrl]="item.GetMediaThumbnail()"
            [isThumbnail]="!!item.thumbnailUrl"
            [pictureTitle]="item.fileName"
            [allowClick]="item.IsPreviewReady()"
            (onPictureClicked)="handleOnPictureClicked(item, 'img' + i)"
            customClass="mt-15"
          >
          </image-viewer>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #editFileModal>
  <flg-generic-modal>
    <ng-container modalTitle>
      {{ 'pilotsService::Rename' | abpLocalization }}
    </ng-container>
    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form [formGroup]="form">
          <mat-form-field class="w-100">
            <mat-label for="file-name">
              {{ 'pilotsService::Name' | abpLocalization }}
            </mat-label>
            <input id="file-name" formControlName="name" matInput />
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        class="outline-button me-2"
        type="button"
        (click)="hideForm()"
        [disabled]="isModalEditBusy"
      >
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        (click)="submitEditForm()"
        [disabled]="form.invalid || isModalEditBusy"
      >
        {{ 'AbpUi::Save' | abpLocalization }}
      </button>
    </div>
  </flg-generic-modal>
</ng-template>

<ng-template #fileModal>
  <flg-generic-modal>
    <ng-container modalTitle>
      {{ 'pilotsService::NewFile' | abpLocalization }}
    </ng-container>
    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form [formGroup]="form">
          <!-- @TODO Angular Material doesn't support file attributes on inputs, find a replacement -->
          <input
            id="file-file"
            formControlName="file"
            type="file"
            (change)="onFileChange($event)"
          />
        </form>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        class="outline-button me-2"
        type="button"
        (click)="hideForm()"
        [disabled]="isModalCreateBusy"
      >
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        (click)="submitCreateForm()"
        [disabled]="form.invalid || isModalCreateBusy"
      >
        {{ 'AbpUi::Save' | abpLocalization }}
      </button>
    </div>
  </flg-generic-modal>
</ng-template>
