<div class="form-container">
  <mat-icon
    class="close-modal-action"
    aria-hidden="false"
    aria-label="{{ 'pilotsService::CloseIcon' | abpLocalization }}"
    fontIcon="close"
    mat-dialog-close
    (click)="onClickClose()"
  ></mat-icon>
  <div class="groups row">
    <div class="col-sm-12 formTitle">
      <div class="label-container-main">
        <mat-label class="label-container-value">{{ 'pilotsService::BulkAssignment' | abpLocalization }}</mat-label>
      </div>
    </div>
    <div class="col-sm-4 left-side mission-details">
      <div class="title-container">
        <h3 class="left-side-title">
          {{ 'pilotsService::ReferenceInformation' | abpLocalization }}
        </h3>
      </div>
      <span *ngIf="modalData.selectedPilots.length > 1">
        {{ modalData.selectedPilots.length }} pilots selected.</span
      >
      <span *ngIf="modalData.selectedPilots.length == 1"
        >{{ modalData.selectedPilots.length }} pilot selected.</span
      >
    </div>
    <div class="col-sm-8 right-side">
      <div class="title-container">
        <h3 class="title">{{ 'pilotsService::AssignBadge' | abpLocalization }}</h3>
      </div>

      <form [formGroup]="form">
        <div class="d-flex mb-3 pt-1">
          <mat-form-field class="d-flex w-100 pe-2" subscriptSizing="dynamic">
            <mat-label>{{ 'pilotsService::SearchByBadgeName' | abpLocalization }}</mat-label>
            <input
              matInput
              placeholder="{{ 'pilotsService::Search' | abpLocalization }}"
              formControlName="searchInput"
              (input)="onBadgeSearch()"
            />
            <mat-icon matSuffix class="cursor-pointer blue-color" (click)="onBadgeSearch()"
              >search</mat-icon
            >
          </mat-form-field>
          <mat-form-field class="d-flex mission-visibility" subscriptSizing="dynamic">
            <mat-label>{{ 'pilotsService::Category' | abpLocalization }}</mat-label>
            <mat-select [(value)]="selectedBadgeCategoryId">
              <mat-option
                *ngFor="let category of lstBadgeCategories.items"
                [value]="category.id"
                title="{{ category.name }}"
                (click)="handleBadgeCategorySelect(category.id)"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>

      <div class="badge-options-container">
        <mat-spinner *ngIf="loading" [diameter]="25"></mat-spinner>

        <section class="badge-options" *ngIf="!loading">
          <div *ngIf="noBadgesFound" class="no-badges-message">
            {{ 'pilotsService::NoBadgesFoundMessage' | abpLocalization }}
          </div>

          <div class="badge-option" *ngFor="let badge of displayedBadgeSelectionList">
            <mat-checkbox
              [(ngModel)]="badge.selected"
              [value]="badge.badgeId"
              (ngModelChange)="onBadgeSelectionChange(badge)"
            >
              {{ badge.badgeName }}
            </mat-checkbox>
            <div *ngIf="badge.hasProgression && badge.selected">
              <mat-radio-group
                [(ngModel)]="badge.progressionLevel"
                (ngModelChange)="onBadgeSelectionChange(badge)"
              >
                <div *ngFor="let level of progressionLevels" class="ml-20px">
                  <mat-radio-button [value]="level">
                    {{ ProgressionLevel[level] }}
                  </mat-radio-button>
                  <small
                    class="ms-4 text-danger"
                    *ngIf="hasWarning(badge, level, badge.progressionLevel)"
                  >
                    {{ getWarningMessage(badge, level, badge.progressionLevel) }}
                  </small>
                </div>
              </mat-radio-group>
            </div>
          </div>
        </section>
      </div>

      <mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
        <div class="d-flex justify-content-end">
          <button
            mat-flat-button
            class="outline-button me-2 badge-action-button"
            type="button"
            (click)="onClickClose()"
          >
            {{ 'pilotsService::Cancel' | abpLocalization }}
          </button>
          <button
            mat-flat-button
            color="primary"
            class="common-button badge-action-button"
            type="button"
            (click)="onClickAssign()"
          >
            {{ 'pilotsService::Assign' | abpLocalization }}
          </button>
        </div>
      </mat-dialog-actions>
    </div>
  </div>
</div>
