import { CaptureMustBeEnum } from "projects/missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum";
import { recurrenceEnum } from "projects/missions-service/src/lib/proxy/missions-service/shared/recurrency.enum";

export class AssignPilotForm {
    missionId: string = '';
    jobId?: string = '';
    missionName: string = '';
    captureDate?: string = '';
    captureTime?: string = '';
    customerName?: string = '';
    captureId?: string = '';
    pilot?: string = '';
    location?: string = '';
    repeats?: recurrenceEnum = recurrenceEnum.NoRepeat;
    captureDateMust?: CaptureMustBeEnum = CaptureMustBeEnum.OnSelectedDate;
    client?: boolean = false;
    additionalContacts?: boolean = false;
    orderRequest?: boolean = false;
    orderPriority?: boolean = false;
    assetOrderNumber?: boolean = false;
    missionSummary?: boolean = false;
    siteInformation?: boolean = false;
    deliverableDueDate?: boolean = false;
    productsPackages?: boolean = false;
  }

export class AssignPilotData{
  missionsInfo: AssignPilotForm[];
  isBulk?: boolean = false;
}