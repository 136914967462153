import { Component, Input, OnInit } from '@angular/core';
import { OrderFormDeliverableModel } from '../../model/order-form-deliverable.model';
import { MatDialog } from '@angular/material/dialog';
import { DeliverableAttributesComponent } from '../../../../shared/deliverable-attributes/deliverable-attributes.component';
import { OrderDetailSummaryModel, OrderFormModel } from '../../model/order-form.model';
import { DeliverableAttributesListComponent } from 'projects/flyguys/src/app/shared/deliverable-attributes-list/deliverable-attributes-list.component';
import { ItemSummaryTypeEnum } from '../../model/item-summary-type.enum';

@Component({
  selector: 'app-orders-detail-table',
  templateUrl: './order-details-list.component.html',
  styleUrls: ['./order-details-list.component.scss'],
})
export class OrderDetailsListComponent implements OnInit {
  @Input() orderDeliverableDetail: OrderFormDeliverableModel[] = [];
  @Input() orderTotal: number = 0;
  columns: OrderFormDeliverableModel[] = [];

  @Input() model: OrderFormModel;
  @Input() showSummaryTable: boolean;

  constructor(private dialogService: MatDialog) {}

  ngOnInit(): void {}

  viewAttributes(row: OrderFormDeliverableModel): void {
    const dialogData = {
      title: 'View Deliverable Attributes',
      deliverableData: row,
      readonly: true,
    };
    const dialogRef = this.dialogService.open(DeliverableAttributesComponent, {
      data: dialogData,
      disableClose: true,
      width: '1150px',
    });
  }

  viewAttributesSummary(row: OrderDetailSummaryModel): void {
    if (row.type == ItemSummaryTypeEnum.Package) {
      const dialogData = {
        title: 'View Deliverable Attributes',
        deliverableList: row.deliverables,
        quantity: row.quantity,
        isEdition: true,
        readonly: true,
      };

      this.dialogService.open(DeliverableAttributesListComponent, {
        data: dialogData,
        disableClose: true,
      });
    } else {
      const dialogData = {
        title: 'View Deliverable Attributes',
        deliverableData: row.deliverables[0],
        isEdition: true,
        readonly: true,
      };

      const dialogRef = this.dialogService.open(DeliverableAttributesComponent, {
        data: dialogData,
        disableClose: true,
        width: '1150px',
      });
    }
  }
}
