<div class="container">
  <div class="row">
    <div mat-dialog-content>
      <ng-container [ngSwitch]="recurrenceType">
        <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="daily"></ng-container>
        <ng-container
          *ngSwitchCase="2"
          [ngTemplateOutlet]="weekly"
          [ngTemplateOutletContext]="{ frequency: 'Weekly' }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="3"
          [ngTemplateOutlet]="monthly"
          [ngTemplateOutletContext]="{ frequency: 'Monthly' }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="4"
          [ngTemplateOutlet]="weekly"
          [ngTemplateOutletContext]="{ frequency: 'Biweekly' }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="5"
          [ngTemplateOutlet]="monthly"
          [ngTemplateOutletContext]="{ frequency: 'Bimonthly' }"
        ></ng-container>
      </ng-container>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end">
      <button mat-button class="plain-button" mat-button mat-dialog-close [disabled]="busy">
        Cancel
      </button>
      <button
        mat-button
        class="plain-button plain-button--main"
        [disabled]="form.invalid || busy"
        (click)="handleClickDone()"
      >
        Done
      </button>
    </div>
  </div>
</div>

<ng-template #daily>
  <form [formGroup]="form" skipValidation>
    <h3>Repeat Daily</h3>

    <div class="d-flex mt-3 align-items-center">
      <mat-form-field class="day-count me-1">
        <mat-label>Repeat Every</mat-label>
        <input matInput type="number" min="1" formControlName="repeatsOn" />
      </mat-form-field>

      <p>Days</p>
    </div>

    <p>Finish</p>

    <div class="d-flex align-items-baseline">
      <mat-radio-group formControlName="completitionRadio">
        <mat-radio-button class="d-inline-block radio mb-4" [value]="1">On</mat-radio-button>
        <mat-radio-button class="d-inline-block radio" [value]="2">After</mat-radio-button>
      </mat-radio-group>

      <div>
        <mat-form-field class="ms-1 datepicker">
          <mat-label>MM/DD/YYYY</mat-label>
          <input
            matInput
            [min]="startDate"
            [matDatepicker]="picker"
            placeholder="MM/DD/YYYY"
            formControlName="endDate"
          />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="ms-1 datepicker">
          <mat-label>Repetitions</mat-label>
          <input matInput type="number" formControlName="repetitions" min="1" />
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #weekly let-frequency="frequency">
  <form [formGroup]="form" skipValidation>
    <h3>Repeat {{ frequency }}</h3>

    <div class="mt-3">
      <p class="d-block">Repeats on *</p>
      <app-daypicker formControlName="repeatsOn"></app-daypicker>
    </div>

    <p>Finish</p>

    <div>
      <mat-form-field class="ms-1 datepicker w-100">
        <mat-label>MM/DD/YYYY</mat-label>
        <input
          matInput
          [min]="startDate"
          [matDatepicker]="picker"
          placeholder="MM/DD/YYYY"
          formControlName="endDate"
        />
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</ng-template>

<ng-template #monthly let-frequency="frequency">
  <form [formGroup]="form" skipValidation>
    <h3>Repeat {{ frequency }}</h3>

    <div class="mt-3">
      <mat-form-field class="w-100">
        <mat-select formControlName="weekInterval">
          <mat-option *ngFor="let option of weeklyOptions" [value]="option.id">{{
            option.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <p>Finish</p>

    <mat-form-field class="w-100">
      <mat-label>MM/DD/YYYY</mat-label>
      <input
        matInput
        [min]="startDate"
        [matDatepicker]="picker"
        placeholder="MM/DD/YYYY"
        formControlName="endDate"
      />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>
</ng-template>
