import { Component, Input } from '@angular/core';

interface Breadcrumb {
  text: string;
  link?: string;
}

@Component({
  selector: 'app-fg-breadcrumb',
  templateUrl: './fg-breadcrumb.component.html',
  styleUrls: ['./fg-breadcrumb.component.scss'],
})
export class FgBreadcrumbComponent {
  @Input() breadcrumbs: Breadcrumb[] = [];
}
