import { AfterViewInit, Directive, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { uuid } from '@abp/ng.core';

enum ColumnPersistanceAttribute {
  width = 'width',
  order = 'order',
  sort = 'sort',
}

type ColumnPersistance = {
  prop: string;
  attribute: ColumnPersistanceAttribute;
  value: string;
};

type PlainObject = { [key: string]: string };

@Directive({
  selector: 'ngx-datatable[column-state-persistance]',
  standalone: true,
  exportAs: 'ngxDatatableColumnStatePersistance',
})
export class NgxDatatableColumnStatePersistance implements AfterViewInit, OnInit, OnDestroy {
  @Input() tableName: string = '';
  private subs: Subscription = new Subscription();
  private readonly table = inject(DatatableComponent);
  private readonly prefix = 'ngx-persistance';
  private viewId: string = '';
  private props: string[] = [];
  private tableKey: string;
  private data: PlainObject = {
    props: '',
  };

  ngOnInit(): void {
    if (this.tableName === '') {
      console.warn('NgxDatatableColumnPersistance: Table name not defined, generating a new one');
      const [name] = uuid().split('-');
      this.tableName = name;
    }
  }

  ngAfterViewInit(): void {
    this.subs.add(
      this.table.resize.subscribe({
        next: ({ column, newValue }) =>
          this.save(
            this.generateKeyName(column.prop, ColumnPersistanceAttribute.width),
            String(newValue)
          ),
      })
    );

    this.subs.add(
      this.table.sort.subscribe({
        next: ({ column, newValue }) => {
          this.clearPreviousSort();

          this.save(this.generateKeyName(column.prop, ColumnPersistanceAttribute.sort), newValue);
        },
      })
    );

    this.subs.add(
      this.table.reorder.subscribe({
        next: ({ column, newValue }) => {
          const currentPosition = this.props.findIndex((p: string) => p === column.prop);

          [this.props[currentPosition], this.props[newValue]] = [
            this.props[newValue],
            this.props[currentPosition],
          ];

          this.save(
            this.generateKeyName('all', ColumnPersistanceAttribute.order),
            JSON.stringify(this.props)
          );
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Sets column states by retrieving possible values from storage
   * This method should be called last after API calls
   */
  recalculate(): void {
    // Clear previous sorting criteria
    this.table.sorts = [];
    // Use an array as a order index for all columns
    this.props = this.table._internalColumns.map((c: TableColumn) => String(c.prop));

    if (this.usingSameProps()) {
      // Set stored values into the table
      this.setValues();

      // Force ngx-datatable to redraw all previously set changes
      this.table.recalculate();
    } else {
      console.warn(
        'NgxDatatableColumnPersistance: Columns are out of date. Clearing previous settings'
      );

      // For simplicity's sake is easier to clear the stored values if the table differs
      localStorage.removeItem(this.tableKey);
    }
  }

  /**
   * Returns a string with the current sorting
   * @returns string
   */
  getSorting(): string {
    let sorting: string = '';

    for (let [key, value] of Object.entries(this.data)) {
      if (key.includes(ColumnPersistanceAttribute.sort)) {
        const [prop] = key.split('-');
        sorting = `${prop} ${value}`;
        break;
      }
    }

    return sorting;
  }

  /**
   * Loads persistance settings if available.
   * This method must be called first to use this directive
   * @param viewId
   */
  load(viewId: string = 'all') {
    this.viewId = viewId;

    this.tableKey = this.generateTableKeyName(this.tableName, this.viewId);

    this.data = JSON.parse(localStorage.getItem(this.tableKey)) || { props: '' };
  }

  /**
   * Indicates that the current used props are the same as the ones loaded from storage
   * @returns boolean
   */
  private usingSameProps(): boolean {
    const hasProps = this.data.props.length > 0;
    let isIt = true;

    if (hasProps) {
      const props = this.data.props.split(', ');

      if (this.table._internalColumns.length === props.length) {
        for (let column of this.table._internalColumns) {
          if (!props.includes(String(column.prop))) {
            isIt = false;
            break;
          }
        }
      } else {
        isIt = false;
      }
    }

    return isIt;
  }

  /**
   * Saves a column/table configuration into localstorage
   *
   * @param prop string
   * @param value string
   */
  private save(prop: string, value: string) {
    this.data[prop] = value;
    this.data.props = this.table._internalColumns.map((c: TableColumn) => c.prop).join(', ');

    const dataToPersist = JSON.stringify(this.data);

    localStorage.setItem(this.tableKey, dataToPersist);
  }

  /**
   * Clears previously stored sort as the API only supports sorting
   * against one column
   */
  private clearPreviousSort() {
    for (let [key, _] of Object.entries(this.data)) {
      if (key.includes(ColumnPersistanceAttribute.sort)) {
        delete this.data[key];

        return;
      }
    }
  }

  /**
   * Sets the previously loaded values into their columns
   */
  private setValues() {
    for (let [key, value] of Object.entries(this.data)) {
      const [prop, attribute] = key.split('-');

      this.set({ prop, attribute: ColumnPersistanceAttribute[attribute], value });
    }
  }

  /**
   * Sets retrieved column values into the instance of ngx-datatable
   * @param column ColumnPersistance
   */
  private set(column: ColumnPersistance) {
    switch (column.attribute) {
      case ColumnPersistanceAttribute.width: {
        const retrieved = this.getColumn(column.prop);
        const value = Number(column.value);

        retrieved.width = value;
        retrieved.$$oldWidth = value;

        break;
      }
      case ColumnPersistanceAttribute.order: {
        const retrieved: string[] = JSON.parse(column.value);
        const newOrder: TableColumn[] = [];

        for (let i = 0; i < retrieved.length; i++) {
          const prop = retrieved[i];
          const column = this.table._internalColumns.find((c: TableColumn) => c.prop === prop);

          newOrder.push(column);
        }

        this.table._internalColumns = newOrder;
        break;
      }
      case ColumnPersistanceAttribute.sort: {
        this.table.sorts.push({
          prop: column.prop,
          dir: column.value,
        });

        break;
      }
    }
  }

  /**
   * Generates an unique identifier for the modified column/table
   *
   * @param prop string
   * @param attribute ColumnPersistanceAttribute
   * @returns string
   */
  private generateKeyName(prop: string, attribute: ColumnPersistanceAttribute): string {
    return `${prop}-${attribute}`;
  }

  /**
   * Finds a column using its prop name
   * @param prop string
   * @returns TableColumn
   */
  private getColumn(prop: string): TableColumn {
    return this.table._internalColumns.find(c => c.prop === prop);
  }

  /**
   * Generates a storage name
   * @param tableName string
   * @param viewName string
   * @returns string
   */
  private generateTableKeyName(tableName: string, viewName: string): string {
    return `${this.prefix}-${tableName}-${viewName}`;
  }
}
