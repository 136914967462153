import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { VideoEventModel } from '../../models/media-player/video-event.model';
import { LogLevel, MediaPlayer, MediaPlayerClass } from 'dashjs';

@Component({
  selector: 'media-player',
  templateUrl: './media-player.component.html'
})
export class MediaPlayerComponent implements OnInit, OnDestroy {
    @Input()
    public videoSource: string;
    @Input()
    public videoName: string;
    @Input() 
    public videoType: string = 'application/vnd.ms-sstr+xml';
    @Input()
    public playerId: string = 'flyGuysReproductor';
    @Input()
    public width: number = 600;
    @Input()
    public height: number = 400;
    @Input()
    public autoplay: boolean = true;
    @Output() 
    public onVideoStarted = new EventEmitter<VideoEventModel>();
    @Output() 
    public onVideoEnded = new EventEmitter<VideoEventModel>();
    @ViewChild("videoPlayer")
    private videoPlayer: ElementRef;
    private dashPlayer: MediaPlayerClass;
    isMSStream: boolean = false;

    constructor() { }

    ngAfterViewInit(): void {
      if(this.isMSStream) {
        this.isMSStream = true;
        let azPlayer: any = amp(this.videoPlayer.nativeElement, this.getConfigurationPlayer());
        azPlayer.src([{
            src: this.videoSource,
            type: this.videoType
        }]);
        azPlayer.addEventListener('ended', () => {
            this.onVideoEnded.emit( <VideoEventModel> { 
                playerId: this.playerId,
                videoName: this.videoName });
        });
        azPlayer.addEventListener('play', () => {
            this.onVideoStarted.emit( <VideoEventModel> { 
                playerId: this.playerId,
                videoName: this.videoName });
        });
      }else{
        this.isMSStream = false;
        this.dashPlayer = MediaPlayer().create();
        this.dashPlayer.initialize(this.videoPlayer.nativeElement, this.videoSource, this.autoplay);
        this.dashPlayer.updateSettings({
          debug: {
            logLevel: LogLevel.LOG_LEVEL_DEBUG
          }
        });
      }
    }

    ngOnInit(): void {
      this.isMSStream = this.videoSource.endsWith(".ism/manifest") || this.videoSource.endsWith(".ism");
    }

    ngOnDestroy(): void {
      let myPlayer = amp(this.playerId);
      myPlayer.dispose();
      if(this.dashPlayer) {
          this.dashPlayer.destroy();
      }
    }

    private getConfigurationPlayer(): any {
        return {
            autoplay: this.autoplay,
            controls: true,
            width: this.width,
            format: 'auto',
            height: this.height,
            id: this.playerId,
            logo: { enabled: false },
            tech: 'auto',
            protection: 'none',
            aes: false,
            playready: false,
            widevine: false,
            heuristicprofile: 'hybrid',
            muted: false,
            language: 'en',
            disableurlrewriter: false,
            cea708captions: false,
            wallClockDisplayEnabled: false,
            useLocalTimeZone: false,
            wallClockDisplayTimezone: 0,
            controlBar12Hour: true
        };
    }
}

