import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BulkUploadDetailsDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';

@Component({
  selector: 'app-bulk-upload-detail-errors',
  templateUrl: './bulk-upload-detail-errors.component.html',
  styleUrls: ['./bulk-upload-detail-errors.component.scss']
})
export class BulkUploadDetailErrorsComponent {

  protected readonly parseFloat = parseFloat;

  constructor(
    public dialogRef: MatDialogRef<BulkUploadDetailErrorsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string,
      detail: BulkUploadDetailsDto
    }) {}

    onClickClose(): void {
      this.dialogRef.close(null);
    }
}