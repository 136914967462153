import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-info-card-with-action',
  templateUrl: './info-card-with-action.component.html',
  styleUrls: ['./info-card-with-action.component.scss'],
})
export class InfoCardWithActionComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() actionIcon: string = 'edit';
  @Input() actionIconVisible: boolean = true;
  @Input() height: string = '';

  @Output() actionClicked: EventEmitter<void> = new EventEmitter<void>();

  onActionClick(): void {
    this.actionClicked.emit();
  }
}
