import { Component, Input, Inject } from '@angular/core';
import { MissionBulkDTO } from '../dto/order-bulk-dto';
import { FlyguysMapDimension, FlyguysMapMarker } from '@flyguys/map';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BulkUploadDetailsDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';

@Component({
  selector: 'app-mission-bulk-overview',
  templateUrl: './mission-bulk-overview.component.html',
  styleUrls: ['./mission-bulk-overview.component.scss']
})
export class MissionBulkOverviewComponent {
  public mapMarkers: FlyguysMapMarker[] = [];
  public mapDimensions: FlyguysMapDimension = {
    height: '500px', // default size in case the calculation fails
    width: '500px',
  };

  protected readonly parseFloat = parseFloat;

  constructor(
    public dialogRef: MatDialogRef<MissionBulkOverviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      mission: BulkUploadDetailsDto;
      readonly: boolean;
      isEdition: boolean;
      fromMissionDetail?: boolean;
    },) {}

    onClickClose(): void {
      this.dialogRef.close(null);
    }
}
