import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridFiltersComponent } from './grid-filters/grid-filters.component';
import { FilterChipComponent } from './filter-chip/filter-chip.component';
import { MaterialSharedModule } from '../../material-shared/material-shared.module';
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [GridFiltersComponent, FilterChipComponent],
  imports: [CommonModule, MaterialSharedModule, FormsModule],
  exports: [GridFiltersComponent, FilterChipComponent],
})
export class GridFiltersModule {}
