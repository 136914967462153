import { ColumnColoringRules } from "../components/column-stylizer/column-stylizer.component";

export const stateRules:ColumnColoringRules[] = [
	{
		columnValue: 1,
		softValue: 'Enabled',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(230, 255, 230)',
			'color': 'green',
			'border-radius': '0.2rem'
		},
	},
	{
		columnValue: 0,
		softValue: 'Disabled',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(255, 230, 230)',
			'color': 'crimson',
			'border-radius': '0.2rem'
		},
	},
	{
		columnValue: 'default',
		softValue: 'Undefined',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'lightgray',
			'color': '#333',
			'border-radius': '0.2rem'
		},
	},
];

export const migratedRules:ColumnColoringRules[] = [
	{
		columnValue: 1,
		containerClass: 'text-success',
		valueClass: 'fa fa-check',
	},
	{
		columnValue: 0,
		containerClass: 'text-danger',
		valueClass: 'fa fa-times',
	},
	{
		columnValue: 'default',
		valueClass: 'fa fa-times',
	},
];

export const assignedRules:ColumnColoringRules[] = [
	{
		columnValue: 1,
		containerClass: 'text-success',
		valueClass: 'fa fa-check',
	},
	{
		columnValue: 0,
		containerClass: 'text-danger',
		valueClass: 'fa fa-times',
	},
	{
		columnValue: 'default',
		valueClass: 'fa fa-times',
	},
];

export const PilotStateRules:ColumnColoringRules[] = [
	{
		columnValue: 'Active',
		softValue: 'Active',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(230, 255, 230)',
			'color': 'green',
			'border-radius': '0.2rem'
		},
	},
	{
		columnValue: 'InActive',
		softValue: 'Inactive',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(255, 230, 230)',
			'color': 'crimson',
			'border-radius': '0.2rem'
		},
	},
	{
		columnValue: 'default',
		softValue: 'Undefined',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'lightgray',
			'color': '#333',
			'border-radius': '0.2rem'
		},
	},
];

export const RatingRules:ColumnColoringRules[] = [];


export const contactTypeRules:ColumnColoringRules[] = [
	{
		columnValue: 0,
		softValue: 'SubClient',
		valueClass: 'datatable-body-cell-label'
	},
	{
		columnValue: 1,
		softValue: 'Company',
		valueClass: 'datatable-body-cell-label'
	},
	{
		columnValue: 2,
		softValue: 'External',
		valueClass: 'datatable-body-cell-label'
	},
	{
		columnValue: 'default',
		softValue: 'Undefined',
		valueClass: 'datatable-body-cell-label'
	},
];

export const OrderBulkStates:ColumnColoringRules[] = [
	{
		columnValue: 0,
		softValue: 'Pending',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(255, 240, 230)',
			'color': 'darkorange',
			'border-radius': '0.2rem'
		},
	},
	{
		columnValue: 1,
		softValue: 'In Validation',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(255, 240, 230)',
			'color': 'darkorange',
			'border-radius': '0.2rem'
		},
	},	
	{
		columnValue: 3,
		softValue: 'Data Validation Passed',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(230, 250, 255)',
			'color': 'deepskyblue',
			'border-radius': '0.2rem'
		},
	},
	{
		columnValue: 2,
		softValue: 'Data Validation Failed',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(255, 230, 230)',
			'color': 'crimson',
			'border-radius': '0.2rem'
		},
	},
	{
		columnValue: 4,
		softValue: 'Completed',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(230, 250, 255)',
			'color': 'green',
			'border-radius': '0.2rem'
		},
	},	
	{
		columnValue: 5,
		softValue: 'Processing',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(255, 240, 230)',
			'color': 'yelow',
			'border-radius': '0.2rem'
		},
	},
	{
		columnValue: 6,
		softValue: 'Execution Queue',
		valueClass: 'badge',
		valueStyle: {
			'background-color': 'rgb(255, 240, 230)',
			'color': 'red',
			'border-radius': '0.2rem'
		},
	}	
];