import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { OrderFormContactModel } from '../model/order-form-contact.model';
import { MissionSLADto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';

@Component({
  selector: 'app-order-sla-table',
  templateUrl: './order-sla.component.html',
  styleUrls: ['./order-sla.component.scss'],
})
export class OrderSlaTableComponent implements OnInit, OnChanges {
  @Input() slas: MissionSLADto[];
  listMilestones: MissionSLADto[];
  public finalStep = 'Final Customer Delivery Due Date';

  constructor() {}

  ngOnInit() {
    this.calculateFinalDueDate(this.slas);
  }

  ngOnChanges() {
    this.calculateFinalDueDate(this.slas);
  }

  calculateFinalDueDate(slas: Array<MissionSLADto>) {
    if (slas?.length > 0) {
      const listMilestones = JSON.parse(JSON.stringify(slas));
      const milestoneFinal: MissionSLADto = JSON.parse(
        JSON.stringify(slas.sort((a, b) => a.order - b.order)[slas.length - 1]),
      );
      milestoneFinal.name = this.finalStep;
      milestoneFinal.days = slas.reduce((sum, mission) => sum + mission.days, 0);
      milestoneFinal.order = milestoneFinal.order + 1;
      listMilestones.push(milestoneFinal);
      this.listMilestones = listMilestones.sort((a, b) => a.order - b.order);
    }
  }
}
