import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetValuesCustomerAttributesInput, ValuesCustomerAttributeCreateDto, ValuesCustomerAttributeDto, ValuesCustomerAttributeExcelDownloadDto, ValuesCustomerAttributeUpdateDto, ValuesCustomerAttributeWithNavigationPropertiesDto } from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class ValuesCustomerAttributeService {
  apiName = 'missionsService';
  

  create = (input: ValuesCustomerAttributeCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ValuesCustomerAttributeDto>({
      method: 'POST',
      url: '/api/missions-service/values-customer-attributes',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/missions-service/values-customer-attributes/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ValuesCustomerAttributeDto>({
      method: 'GET',
      url: `/api/missions-service/values-customer-attributes/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/missions-service/values-customer-attributes/download-token',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetValuesCustomerAttributesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ValuesCustomerAttributeWithNavigationPropertiesDto>>({
      method: 'GET',
      url: '/api/missions-service/values-customer-attributes',
      params: { filterText: input.filterText, missionDescription: input.missionDescription, customerAttributeId: input.customerAttributeId, customerAttributeDescription: input.customerAttributeDescription, value: input.value, missionsId: input.missionsId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: ValuesCustomerAttributeExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/missions-service/values-customer-attributes/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, name: input.name },
    },
    { apiName: this.apiName,...config });
  

  getMissionsLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/missions-service/values-customer-attributes/missions-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getWithNavigationProperties = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ValuesCustomerAttributeWithNavigationPropertiesDto>({
      method: 'GET',
      url: `/api/missions-service/values-customer-attributes/with-navigation-properties/${id}`,
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: ValuesCustomerAttributeUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ValuesCustomerAttributeDto>({
      method: 'PUT',
      url: `/api/missions-service/values-customer-attributes/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
