<abp-file-management-modal [title]="'Folder: ' + folder.folderName" [parent]="self">
  <div class="checkbox-container" *ngFor="let item of roles">
    <label class="role-list-permission">{{item.name}}:</label>&nbsp;
    <mat-checkbox [(ngModel)]="item.read">
      {{ "FileManagement::Read" | abpLocalization }}
    </mat-checkbox>
    <mat-checkbox  [(ngModel)]="item.write">
      {{ "FileManagement::Write" | abpLocalization }}
    </mat-checkbox>
    <mat-checkbox   [(ngModel)]="item.delete">
      {{ "FileManagement::Delete" | abpLocalization }}
    </mat-checkbox>
  </div>
</abp-file-management-modal>
