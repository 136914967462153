import { eIdentityComponents, IdentityCreateFormPropContributors } from '@volo/abp.ng.identity';
import { IdentityRoleDto, IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { ePropType, FormProp, FormPropList } from '@abp/ng.theme.shared/extensions';
import { Validators } from '@angular/forms';
import { enumPortal, enumPortalOptions } from '../../models/enum/enum-portal.enum';
import { of } from 'rxjs';

const codeProp = new FormProp<IdentityRoleDto>({
  type: ePropType.String,
  name: 'code',
  displayName: 'IdentityService::Code',
  validators: () => [Validators.maxLength(100)],
  isExtra: true,
});

const portalProp = new FormProp<IdentityRoleDto>({
  type: ePropType.Enum,
  defaultValue: enumPortal.All,
  name: 'portal',
  displayName: 'IdentityService::Portal',
  validators: () => [Validators.required],
  isExtra: true,
  options: () => of(enumPortalOptions),
});

const foldersSupervisorProp = new FormProp<IdentityRoleDto>({
  type: ePropType.Boolean,
  name: 'foldersSupervisor',
  displayName: 'IdentityService::FoldersSupervisor',
  validators: () => [Validators.required],
  isExtra: true,
});

const enableSMSReceptionProp = new FormProp<IdentityUserDto>({
  type: ePropType.Boolean,
  name: 'enableSMSReception',
  displayName: 'IdentityService::EnableSMSReception',
  validators: () => [Validators.required],
  isExtra: true,
});

export function rolePropContributor(propList: FormPropList<IdentityRoleDto>) {
  propList.addTail(codeProp);
  propList.addTail(portalProp);
  propList.addTail(foldersSupervisorProp);
}

export function userPropContributor(propList: FormPropList<IdentityUserDto>) {
  propList.addTail(enableSMSReceptionProp);
}

export const identityCreateFormPropContributors: IdentityCreateFormPropContributors = {
  // enum indicates the page to add contributors to
  [eIdentityComponents.Roles]: [
    rolePropContributor,
    // You can add more contributors here
  ],
  [eIdentityComponents.Users]: [
    userPropContributor,
    // You can add more contributors here
  ],
};

export const identityEditFormPropContributors = identityCreateFormPropContributors;
