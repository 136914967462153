import { NgModule } from '@angular/core';

import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';

import { NgxValidateCoreModule } from '@ngx-validate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { MaterialSharedModule } from '../material-shared/material-shared.module';

import { AgGridModule } from 'ag-grid-angular';
import { FgChipComponent } from './fg-chip/fg-chip.component';
import { FgBreadcrumbComponent } from './fg-breadcrumb/fg-breadcrumb.component';
import { FgSearchBarComponent } from './fg-search-bar/fg-search-bar.component';
import { FormsModule } from '@angular/forms';

import { FlyguysMapModule } from '@flyguys/map';
import { FlyguysFormsModule } from '@flyguys/forms';
import { FlyguysModalsModule } from '@flyguys/modals';
import { FlyguysGeofenceModule } from '@flyguys/geofence';
import { FlyguysServicesModule } from '@flyguys/services';
import { FlyguysComponentsModule } from '@flyguys/components';

import { FlyguysGridNotesComponent } from '../components/common/grid-notes/grid-notes.component';

import { InfoCardWithActionComponent } from './info-card-with-action/info-card-with-action.component';
import { SubtitleDescriptionComponent } from './subtitle-description/subtitle-description.component';
import { LoadingComponent } from '../components/common/loading/loading.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { FileUploadComponent } from '../components/common/file-upload/file-upload.component';
import { GridFiltersModule } from './grid-filters/grid-filters.module';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DeliverableListComponent } from './deliverable-list/deliverables-list.component';
import { DeliverableAttributesComponent } from './deliverable-attributes/deliverable-attributes.component';
import { PackageComponent } from './package/package.component';
import { DeliverableComponent } from './deliverable/deliverable.component';
import { NewDeliverableComponent } from './new-deliverable/new-deliverable.component';
import { DeliverableAttributesListComponent } from './deliverable-attributes-list/deliverable-attributes-list.component';
import { NgxDatatableColumnStatePersistance } from '../directives/ngx-datatable-column-state-persistance/ngx-datatable-column-state-persistance.directive';
import { NgxDatatableActionWidthFix } from '../directives/ngx-datatable-action-width/ngx-datatable-action-width.directive';
import { FeatureToggleModule } from 'ngx-feature-toggle';

import { environment } from '../../environments/environment';
import { OrderCustomSlaTableComponent } from '../components/orders/order-custom-sla/order-custom-sla.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    FgChipComponent,
    FgBreadcrumbComponent,
    FgSearchBarComponent,
    InfoCardWithActionComponent,
    SubtitleDescriptionComponent,
    LoadingComponent,
    FileUploadComponent,
    SearchFilterComponent,
    DeliverableListComponent,
    DeliverableAttributesComponent,
    PackageComponent,
    DeliverableComponent,
    NewDeliverableComponent,
    DeliverableAttributesListComponent,
    OrderCustomSlaTableComponent,
    FlyguysGridNotesComponent,
  ],
  imports: [
    PipesModule,
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonXModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgScrollbarModule,
    MaterialSharedModule,
    AgGridModule,
    FormsModule,
    FlyguysMapModule,
    FlyguysServicesModule.forRoot({
      google: {
        apiKey: environment.googleMaps.apiKey,
      },
    }),
    FlyguysGeofenceModule,
    FlyguysModalsModule,
    FlyguysComponentsModule,
    FlyguysFormsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-square-clockwise-spin' }),
    GridFiltersModule,
    ScrollingModule,
    NgxDatatableColumnStatePersistance,
    NgxDatatableActionWidthFix,
    FeatureToggleModule,
  ],
  exports: [
    PipesModule,
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonXModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgScrollbarModule,
    MaterialSharedModule,
    AgGridModule,
    FgChipComponent,
    NgxSpinnerModule,
    FgBreadcrumbComponent,
    FgSearchBarComponent,
    FlyguysMapModule,
    FlyguysGeofenceModule,
    FlyguysModalsModule,
    InfoCardWithActionComponent,
    SubtitleDescriptionComponent,
    FileUploadComponent,
    GridFiltersModule,
    ScrollingModule,
    SearchFilterComponent,
    DeliverableListComponent,
    DeliverableAttributesComponent,
    PackageComponent,
    DeliverableComponent,
    NewDeliverableComponent,
    DeliverableAttributesListComponent,
    FlyguysFormsModule,
    FlyguysComponentsModule,
    NgxDatatableColumnStatePersistance,
    NgxDatatableActionWidthFix,
    OrderCustomSlaTableComponent,
    FeatureToggleModule,
    FlyguysGridNotesComponent,
  ],
  providers: [],
})
export class SharedModule {}
