<mat-card class="card">
  <div class="card-body">
    <lib-questionnaire
      [UserId]="pilotId"
      DataLink="wizard"
      QuestionnaireName="Pilot Questionnaire"
      DisplayInList="true"
    >
      <h1 header>{{ 'pilotsService::Questionnaire' | abpLocalization }}</h1>
    </lib-questionnaire>
  </div>
</mat-card>
