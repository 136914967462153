<ngx-datatable *ngIf="!showSummaryTable"
  [rows]="orderDeliverableDetail"
  [columns]="columns"
  [scrollbarH]="true"
  [footerHeight]="false"
  [headerHeight]="55"
  [rowHeight]="50"
  [footerHeight]="50"
  class="material flg-simple-table"
  default
>
  <ngx-datatable-column
    name="Deliverable"
    prop="deliverableName"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.deliverableName }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Product"
    prop="productName"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.productName || 'N/A' }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Package"
    prop="packageName"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell--email'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.packageName || 'N/A' }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Industry"
    prop="industryDescription"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.industryDescription || 'N/A' }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Quantity"
    prop="quantity"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.quantity }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Unit Price"
    prop="actualPrice"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      ${{ row.actualPrice | number : '1.2-2' }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Total"
    prop="subTotal"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      $ {{ row.subTotal | number : '1.2-2' }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Attributes"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button mat-icon-button (click)="viewAttributes(row)">
        <mat-icon>visibility_outline</mat-icon>
      </button>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template>
      <div class="row-total">
        <div class="cell-total">
          <div class="box">
            <div class="typography-total">
              <div class="body-2">Total</div>
            </div>
          </div>
        </div>
        <div class="cell-total-3">
          <div class="box">
            <div class="typography">
              <div class="subtitle-2">${{ orderTotal | number : '1.2-2' }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<ngx-datatable *ngIf="showSummaryTable"
  [rows]="model.orderDetailSummaryModel"
  [columns]="columns"
  [scrollbarH]="true"
  [footerHeight]="false"
  [headerHeight]="55"
  [rowHeight]="50"
  [footerHeight]="50"
  class="material flg-simple-table"
  default
>
  <ngx-datatable-column
    name="Item"
    prop="itemName"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.itemName }}
    </ng-template>
  
  
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Type"
    prop="typeDescription"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.typeDescription || 'N/A' }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Quantity"
    prop="quantity"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.quantity }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Unit Price"
    prop="price"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      ${{ row.price | number : '1.2-2' }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Total"
    prop="total"
    [resizeable]="true"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      $ {{ row.total | number : '1.2-2' }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Attributes"
    [sortable]="false"
    [headerClass]="'flg-simple-table__header'"
    [cellClass]="'flg-simple-table__cell'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button mat-icon-button (click)="viewAttributesSummary(row)">
        <mat-icon>visibility_outline</mat-icon>
      </button>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template>
      <div class="row-total">
        <div class="cell-total">
          <div class="box">
            <div class="typography-total">
              <div class="body-2">Total</div>
            </div>
          </div>
        </div>
        <div class="cell-total-3">
          <div class="box">
            <div class="typography">
              <div class="subtitle-2">${{ orderTotal | number : '1.2-2' }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
