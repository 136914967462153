import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eMissionsServiceRouteNames } from '../enums/route-names';

export const RELATIONALS_CUSTOM_GRID_VIEWS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/missions-service/custom-grid-views',
        parentName: eMissionsServiceRouteNames.MissionsService,
        name: 'missionsService::Menu:CustomGridViews',
        layout: eLayoutType.application,
        requiredPolicy: 'missionsService.GridViews.ShowMenu',
        iconClass: 'fas fa-grip-vertical',
      },
    ]);
  };
}
