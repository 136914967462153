import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { ePilotsServiceRouteNames } from '../enums/route-names';

export const RELATIONALS_PILOT_BADGE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/pilots-service/pilot-badges',
        parentName: ePilotsServiceRouteNames.PilotsService,
        name: 'pilotsService::Menu:PilotBadges',
        layout: eLayoutType.application,
        requiredPolicy: 'pilotsService.PilotBadges.ShowMenu',
        iconClass: 'fas fa-certificate'
      },
    ]);
  };
}
