import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { pilotData } from '@flyguys/map';

@Component({
  selector: 'app-infoPilotModal-modal',
  templateUrl: './info-pilot-modal.component.html',
  styleUrls: ['./info-pilot-modal.component.scss'],
})
export class InfoPilotModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: pilotData) {}
}
