import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-column-actions',
  templateUrl: './column-actions.component.html',
  styleUrls: ['./column-actions.component.scss'],
})
export class ColumnActionsComponent {
  @Input() columnActions: ColumnAction[];

  track(_: number, action: ColumnAction): string {
    return action.actionIcon;
  }
}

export interface ColumnAction {
  actionIcon: string;
  abpPermission: string;
  action: parentAction;
  visible?: boolean;
}

interface parentAction {
  callAction: Function;
}
