export class OrderFormDeliverableFieldModel {
  id: string = '';
  placeholder: string = '';
  numericValue: Number = 0;
  stringValue: string = '';
  boolValue: boolean = false;
  fileValue: string = '';
  defaultValue: string = '';
  isRequired: boolean = false;
  listValues: string = '';
  maxTextLength: Number = 0;
  typeDescription: string = '';
  typeCode: string = '';
  fieldControlName?: string;
  loadingFile?: boolean;
  attributeId?: string;
  captureDeliverableId?: string;
}
