import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PagedResultDto } from '@abp/ng.core';
import { MissionsDto, PortafoliosDto, PortfolioDetailedDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { PortafoliosService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';

@Component({
  selector: 'app-portfolio-detail',
  templateUrl: './portfolio-detail.component.html',
  styleUrls: ['./portfolio-detail.component.scss'],
})
export class PortfolioDetailComponent implements OnInit, OnDestroy {

  portfolioId: string;
  portfolioData: PortfolioDetailedDto;
  missionSelected: string;

  mData: PagedResultDto<MissionsDto> = {
    items: [],
    totalCount: 0,
  };

  constructor(
    private routing: Router,
    private route: ActivatedRoute,
    private service: PortafoliosService,
    public readonly customerService: CustomersService,
  ) {}

  ngOnInit() {

    this.portfolioId = this.route.snapshot.paramMap.get('portfolioId');

    this.loadPortfolio(this.portfolioId);

  }

  handleOnGoBack(): void {
    this.missionSelected = undefined;
  }

  handleOnMissionSelected(missionId: string): void {

    this.missionSelected = missionId;
  }

  backToPortfolioList(){
    this.routing.navigate(['pilot-sourcing/missions/' + this.portfolioId]);
  }

  onTabChange(event: any) {
  }

  ngOnDestroy(): void {
  }

  private loadPortfolio(id: string): void {

    this.service.getDetailed(id).subscribe({
      next: (data: PortafoliosDto) => {
        
        this.portfolioData = data;
      },
      error: err => console.error(err)
    });
  }
}
