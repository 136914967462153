import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eMissionsServiceRouteNames } from '../enums/route-names';

export const BASICS_PROJECTS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/missions-service/project',
        parentName: eMissionsServiceRouteNames.MissionsService,
        name: 'missionsService::Menu:Project',
        layout: eLayoutType.application,
        requiredPolicy: 'missionsService.Project.ShowMenu',
        iconClass: 'fa fa-project-diagram'
      },
    ]);
  };
}
