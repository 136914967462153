import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BackgroundNotificationModel } from '../models/notifications/background-notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationBroadcastService {

  public backgroundNotification$ = new Subject<BackgroundNotificationModel>();

}
