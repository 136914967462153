import { RecurrenceDTO } from '../recurrence/model/recurrence-form.model';
import { OrderFormSiteFileModel } from './order-form-site-file.model';
import { OrderFormContactModel } from './order-form-contact.model';
import {
  CaptureMustBeEnum,
  captureMustBeEnumDisplayNames,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum';
import { MissionSLADto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
export class OrderFormSiteModel {
  number: string = '';
  missionName: string = '';
  missionSummary: string = '';
  captureDate: Date = null;
  captureTime?: string = null;
  frequencyId: string = '0';
  frequencyDescription: string = '';
  recurrence?: RecurrenceDTO;
  captureDateMustId: string = CaptureMustBeEnum.AnytimeBefore.toString();
  captureDateMustDescription: string =
    captureMustBeEnumDisplayNames[CaptureMustBeEnum.AnytimeBefore];
  flexibleCaptureId: string = '';
  flexibleCaptureDescription: string = '';
  recurrenceDescription: string = '';
  deliveryDate: string = '';
  deliveryAllSameTime: Boolean = true;
  deliveryNotes: string = '';
  uploadingDataInstruction: string = '';
  siteContacts: OrderFormContactModel[] = [];
  locLocationId: string = '';
  locSiteName: string = '';
  locAddress: string = '';
  locCountryId: string = '';
  locCountryDescription: string = '';
  locStateId: string = '';
  locStateDescription: string = '';
  locCity: string = '';
  locZipCode: string = '';
  loclatitude: string = '';
  loclongitude: string = '';
  geoFenceRadius: string = '';
  projectName: string = '';
  locAirSpaceClasfId: string = '';
  locAirSpaceClasfDescription: string = '[Not Selected]';
  aditionalNotes: string = '';
  kmlFiles: OrderFormSiteFileModel[] = [];
  filesAttachments: OrderFormSiteFileModel[] = [];
  orderFolderId: string = '';
  missionFolderId: string = '';
  sla: MissionSLADto[];
  manualAirspaceWaiverRequired: boolean = false;
}
