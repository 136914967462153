import { OrderFormDeliverableFieldModel } from './order-form-deliverable-field.model';
export class OrderFormDeliverableModel {
  deliverableId: string = '';
  productId: string = null;
  productName: string = null;
  productDescription: string = null;
  deliverableName: string = '';
  packageId: string = null;
  packageName: string = null;
  quantity?: number = null;
  originalquantity?: number = null;
  standardPrice?: number = null;
  industryId?: string = null;
  industryDescription?: string = null;
  industryMultiplexor?: number = null;
  actualPrice?: number = null;
  subTotal?: number = null;
  fields?: OrderFormDeliverableFieldModel[] = [];
  selected?: Boolean = false;
  sameConfiguration?: boolean = false;
  detailedAttributes?: Array<OrderFormDeliverableFieldModel[]> = [];
  incompleteConfiguration?: boolean;
  requiredConfigurationIncomplete?: boolean;
  allControlsBuilt?: boolean;
  singleControlsBuilt?: boolean;
  editingPrice?: boolean = false;
}
