import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Day, buildWeekdayModel } from './daypicker.utils';

@Component({
  selector: 'app-daypicker',
  templateUrl: './daypicker.component.html',
  styleUrls: ['./daypicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DaypickerComponent,
    },
  ],
})
export class DaypickerComponent implements ControlValueAccessor {
  private selection: number[] = [];
  readonly weekdays: Day[] = buildWeekdayModel();

  onChange = (days: number[]) => {};

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  onTouched = () => {};

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(days: number[]): void {
    this.selection = days;

    this.weekdays.forEach((d: Day) => {
      d.selected = this.selection.includes(d.id);
    });
  }

  /**
   * Marks a day in selected in the internal state
   * @param day Day
   * @returns void
   */
  select(day: Day): void {
    day.selected = !day.selected;

    this.selection = this.weekdays.filter((d: Day) => d.selected).map((f: Day) => f.id);

    this.onChange(this.selection);
  }
}
