import { mapEnumToOptions } from '@abp/ng.core';

export enum enumBulkUpload{
    Pending = 0,
    InValidation = 1,
    DataValidationFailed = 2,
    DataValidationPassed = 3,
    Completed = 4,
    Processing = 5,
    ExecutionQueue = 6
}

export const enumStateOptions = mapEnumToOptions(enumBulkUpload);