import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePaginatorComponent } from './components/table-paginator/table-paginator.component';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@abp/ng.core';
import { TreeStructureComponent } from './components/tree-structure/tree-structure.component';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [
    TablePaginatorComponent,
    TreeStructureComponent
  ],
  imports: [
    CommonModule,
    ThemeSharedModule,
    MatMenuModule,
    FormsModule,
    CoreModule
  ],
  exports: [TablePaginatorComponent, TreeStructureComponent]
})
export class TablePageModule { }
