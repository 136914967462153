<div class="d-flex">
  <div
    class="card d-flex p-3 align-items-start align-self-stretch rounded custom-card mt-2 me-1 flex-column"
    style="width: 62% !important"
  >
    <div class="mb-4">
      <h3 class="p-10">Missions by Status</h3>
    </div>
    <div>
      <ngx-charts-bar-vertical
        *ngIf="barChartData.length"
        [view]="view"
        [scheme]="colorScheme"
        [results]="barChartData"
        [gradient]="true"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [legend]="false"
        [showYAxisLabel]="true"
        [yAxisLabel]="'Missions'"
        [barPadding]="90"
        (select)="barSelected($event)"
      >
      </ngx-charts-bar-vertical>
    </div>
  </div>

  <div
    class="card d-flex p-3 align-items-start align-self-stretch rounded custom-card mt-2 w-50 ms-1"
    style="width: 38% !important"
  >
    <div class="w-100">
      <div class="mb-1">
        <h3 class="p-10">Portfolio Performance</h3>
      </div>
      <div>
        <ngx-charts-pie-chart
          *ngIf="portfolioPerformanceData.length"
          class="chart-container"
          [view]="viewBurndown"
          [scheme]="performanceScheme"
          [results]="portfolioPerformanceData"
          [animations]="true"
          [legend]="true"
          [legendTitle]="'References'"
          [legendPosition]="'below'"
          [explodeSlices]="false"
          [labels]="false"
          [doughnut]="true"
          [arcWidth]="0.25"
          [gradient]="true"
          [tooltipDisabled]="false"
          [tooltipText]="pieTooltipText"
          (select)="segmentSelected($event)"
        >
        </ngx-charts-pie-chart>
      </div>
    </div>
  </div>
</div>
<div class="">
  <div class="">
    <div
      class="card d-flex align-items-start align-self-stretch rounded custom-card mt-2 me-1 flex-column"
      style="width: 100%"
    >
      <div class="mb-3 d-flex" style="width: 100%">
        <h3 class="p-10">Missions</h3>
        <div class="chip me-1" title="status" *ngIf="textStatusFiltered">
          <span class="chip-text chip-label">Status:&nbsp;</span>
          <span class="chip-text chip-value criteria">
            {{ textStatusFiltered }}
          </span>
          <span class="material-symbols-rounded" (click)="cleanFilters()"> cancel </span>
        </div>
        <div class="chip" title="Risk" *ngIf="false">
          <span class="chip-text chip-label">Risk:&nbsp;</span>
          <span class="chip-text chip-value criteria" *ngIf="riskSelected == 'high'"> High </span>
          <span class="chip-text chip-value criteria" *ngIf="riskSelected == 'medium'">
            Medium
          </span>
          <span class="chip-text chip-value criteria" *ngIf="riskSelected == 'low'"> Low </span>
          <span class="material-symbols-rounded" (click)="cleanRisk()"> cancel </span>
        </div>
        <div class="d-flex" style="margin-left: auto; margin-top: 20px" *ngIf="false">
          <div class="d-flex me-2" (click)="filterByRisk('low')">
            <div style="margin-top: 2px">
              <hr class="alert-style green" />
            </div>
            &nbsp;
            <span>Low</span>
          </div>
          <div class="d-flex me-2" (click)="filterByRisk('medium')">
            <div>
              <hr class="alert-style orange" />
            </div>
            &nbsp;
            <span>Medium</span>
          </div>
          <div class="d-flex me-2" (click)="filterByRisk('high')">
            <div>
              <hr class="alert-style red" />
            </div>
            &nbsp;
            <span>High</span>
          </div>
        </div>
      </div>
      <div style="width: 100%" class="">
        <div class="grid" *ngIf="mData && mData.items">
          <ngx-datatable
            [rows]="mData.items"
            [headerHeight]="60"
            [footerHeight]="55"
            [rowHeight]="50"
            [offset]="pageNumber"
            [count]="mData.totalCount"
            [list]="list"
            [limit]="clientMaxResultCount"
            [externalPaging]="true"
            [reorderable]="false"
            [swapColumns]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
            [trackByProp]="'id'"
            default
            #dataTable
            class="flg-grid-table"
          >
            <ngx-datatable-column
              name="{{ 'missionsService::JobId' | abpLocalization }}"
              prop="jobId"
              [minWidth]="80"
              [width]="80"
              [resizeable]="false"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.jobId || '--' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mission Name" prop="name" [resizeable]="true" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span matTooltip="{{ row.name || '--' }}" [matTooltipShowDelay]="2000">{{
                  row.name || '--'
                }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'missionsService::Status' | abpLocalization }}"
              prop="MissionStatus"
              [width]="230"
              [resizeable]="true"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <app-fg-chip
                  class="ml-4 no-shrink"
                  text="{{ missionStatus[row.missionStatus].value }}"
                ></app-fg-chip>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'missionsService::ProjectId' | abpLocalization }}"
              prop="projectId"
              [resizeable]="true"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span matTooltip="{{ row.project || '--' }}" [matTooltipShowDelay]="2000">{{
                  row.project || '--'
                }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Location" prop="location" [resizeable]="true" [width]="150">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span matTooltip="{{ row.location }}" [matTooltipShowDelay]="2000">{{
                  row.location
                }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Capture Date" prop="activeCaptureDate" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.fixedActiveCaptureDate || (row.activeCaptureDate | date: 'MM/dd/yyyy') }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Capture Time" prop="activeCaptureTime" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.fixedActiveCaptureTime || row.activeCaptureTime || '--:--' }}
                {{ row.timeZone || '' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'missionsService::PilotId' | abpLocalization }}"
              prop="pilot"
              [resizeable]="true"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span matTooltip="{{ row.pilotName || '--' }}" [matTooltipShowDelay]="2000">
                  {{ row.pilotName || '--' }}</span
                >
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="Deliverables Included"
              prop="deliverablesNames"
              [sortable]="false"
              [resizeable]="true"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span matTooltip="{{ row.deliverablesNames || '--' }}" [matTooltipShowDelay]="2000">
                  {{ row.deliverablesNames || '--' }}</span
                >
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="Actions"
              [sortable]="false"
              [frozenRight]="true"
              [width]="220"
              [resizeable]="false"
              [headerClass]="'actions-column'"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <button
                  class="btn btn-outline-primary btn-sm float-end d-flex max-35 p-mission-btn"
                  (click)="handleOnOpenMission(row.id)"
                >
                  <span class="material-symbols-outlined"> chevron_left </span>
                  <span>Open Mission</span>
                </button>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-curPage="curPage"
              >
                <app-table-paginator
                  [(maxResultCount)]="list.maxResultCount"
                  [(clientMaxResultCount)]="clientMaxResultCount"
                  [rowCount]="rowCount"
                  [pageSize]="pageSize"
                  [(pageNumber)]="pageNumber"
                  [curPage]="curPage"
                  [dataTable]="dataTable"
                  (clientMaxResultCountChange)="onPaginationChange($event)"
                >
                </app-table-paginator>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
