import { Component, Inject, Input } from '@angular/core';
import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { MissionChangeLogService } from 'projects/flyguys/src/app/services/mission-change-log.service';
import { StatusMissionOrderService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/status-mission-order.service';
import { SLAMilestonesDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { enumMissionStatusMapping } from 'projects/missions-service/src/lib/proxy/missions-service/basics/enum-mission-status-mapping.enum';
import {
  MissionSLADto,
  StatusMissionOrderDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';

@Component({
  selector: 'lib-show-sla-milestones',
  templateUrl: './show-sla-milestones.component.html',
  styleUrls: ['./show-sla-milestones.component.css'],
})
export class ShowSlaMilestonesComponent {
  public slas: Array<MissionSLADto>;
  public finalStep = 'Final Customer Delivery Due Date';
  milestones: SLAMilestonesDto[];

  @Input() missionId: string;
  @Input() fromMetrics: boolean = false;
  slaName: string;
  statuses: StatusMissionOrderDto[];

  constructor(
    private readonly slaService: SlaService,
    private readonly log: MissionChangeLogService,
    private readonly statusMissionOrderService: StatusMissionOrderService,
  ) {}

  ngOnInit() {
    if (this.missionId) {
      this.Initialize(this.missionId, '');
    }
  }

  getLogs() {
    this.log.getChangesByMissionAsync(this.missionId).subscribe(res => {
      if (res?.length > 0) {
        res;
        this.slas = this.slas.map(r => {
          const statusId = this.milestones.find(q => q.id == r.slaMilestoneId)?.statusId;
          if (statusId || statusId == 0) {
            const status = enumMissionStatusMapping[statusId];
            const statusKey = this.statuses.find(s => s.statusEnum == String(statusId))?.code;

            if (status) {
              const date = res.find(
                r => JSON.parse(r.displayEventInformation)?.NewStatus == statusKey,
              )?.date;

              if (date) r.achievementDate = new Date(date);
            }
          }

          return r;
        });
      }
    });
  }

  getSlaInformation() {
    this.slaService.getList({ maxResultCount: 10 }).subscribe(r => {
      this.milestones = r.items.find(r => r.id == this.slas[0].slaId)?.slaMilestones;
      this.getLogs();
    });
  }

  Initialize(missionId: string, slaName: string) {
    this.slaName = slaName;
    this.slaService.getMissionSLAByMissionId(missionId).subscribe(d => {
      this.slas = d.items;
      this.slas[0].statusId;
      this.calculateFinalDueDate(this.slas);

      if (this.fromMetrics) this.getStatuses();
    });
  }

  getStatuses() {
    this.statusMissionOrderService.getList({ maxResultCount: 100 }).subscribe(r => {
      this.statuses = r.items;
      this.getSlaInformation();
    });
  }

  calculateFinalDueDate(slas: Array<MissionSLADto>) {
    if (slas.length > 0) {
      const milestoneFinal: MissionSLADto = JSON.parse(
        JSON.stringify(slas.sort((a, b) => a.order - b.order)[slas.length - 1]),
      );
      milestoneFinal.name = this.finalStep;
      milestoneFinal.days = slas.reduce((sum, mission) => sum + mission.days, 0);
      milestoneFinal.order = milestoneFinal.order + 1;
      this.slas.push(milestoneFinal);
      this.slas = this.slas.sort((a, b) => a.order - b.order);
    }
  }
}
