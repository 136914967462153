import { Component, Input, EventEmitter, Output, Inject, OnInit } from '@angular/core';
import {
  AssignedDto
} from '../../proxy/missions-service/basics/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterService } from '@abp/ng.theme.shared';
import { LocalizationService} from '@abp/ng.core';

@Component({
  selector: 'lib-mission-assignments-modal',
  templateUrl: './mission-assignments-modal.component.html',
  styleUrls: ['./mission-assignments-modal.component.css']
})
export class MissionAssignmentsModalComponent implements OnInit {
  missionAssignments:AssignedDto[] = [];

  constructor(
    public dialogRef: MatDialogRef<MissionAssignmentsModalComponent>,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public modalConfig: any
  ) {
    this.missionAssignments = modalConfig.missionAssignments;
  } 

  onClickClose(): void {
    this.dialogRef.close(null);
  }  

  ngOnInit() {
    if (this.missionAssignments.length == 0){
      //this.toaster.warn(this.localizationService.instant("missionsService::NoDataFound"));
    }
  }
}
