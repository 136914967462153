export class FolderSelectedModel {

    id: string;
    folderName: string;

    constructor(folderName, id) {
        this.folderName = folderName;
        this.id = id;
    }
}
