<h3>{{ 'missionsService::Financials' | abpLocalization }}</h3>

<div class="col-12 row">
    <div class="d-flex justify-content-end" *ngIf="financialDataDto">
        <div class="d-flex">
          <p>Total Price: <b>${{financialDataDto.totalPrice | number: '1.2-2'}}</b></p>
          <p class="ms-3">Total Cost: <b>${{financialDataDto.totalCost | number: '1.2-2'}}</b></p>
          <p class="ms-3">Margin %: <b>{{financialDataDto.margin | number: '1.2-2'}}</b></p>
        </div>
      </div>
</div>

<br/>

<lib-mission-financial-revenue 
  [mission]="mission"
  [missionId]="missionId">
</lib-mission-financial-revenue>

<lib-mission-financial-expense
  [missionId]="missionId">
</lib-mission-financial-expense>
