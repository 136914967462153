import { EntityAction, EntityActionList } from "@abp/ng.theme.shared/extensions";
import { Injectable } from "@angular/core";
import { IdentityEntityActionContributors, eIdentityComponents } from "@volo/abp.ng.identity";
import { IdentityUserDto } from "@volo/abp.ng.identity/proxy";
import { ImpersonateService } from "projects/missions-service/src/lib/proxy/missions-service/controllers/relationals/impersonate.service";


@Injectable({
    providedIn: 'root',
})
export class ImpersonateActionService {
    apiName = 'AbpAccountPublic';
    action = 'Impersonation'
    client = 'Angular'

    constructor(private impersonateService: ImpersonateService) { }

    logInPilot = new EntityAction<IdentityUserDto>({
        text: 'Log in as a Pilot',
        action: (data) => {
            document.cookie = `cookie_access_token=${localStorage.getItem('access_token')};domain=flyguys.com;path=/`;
            document.cookie = `cookie_id_user=${data.record.id};domain=flyguys.com;path=/`;
            const redirect = window.location.protocol + "//" + window.location.host.replace('operations', 'pilots').replace('4200', '4201') + '/impersonate';
            window.open(redirect, '_blank')
        },
        visible: (data) => {
            if (data.record.roleNames.toString().toLowerCase().includes('pilot')) {
                return true;
            }
            return false;
        }

    });


    logInCustomer = new EntityAction<IdentityUserDto>({
        text: 'Log in as a Customer',
        action: (data) => {
            // document.cookie = `cookie_access_token=${localStorage.getItem('access_token')};domain=.localhost;path=/`;
            // document.cookie = `cookie_id_user=${id};domain=.localhost;path=/`;
            document.cookie = `cookie_access_token=${localStorage.getItem('access_token')};domain=flyguys.com;path=/`;
            document.cookie = `cookie_id_user=${data.record.id};domain=flyguys.com;path=/`;
            const redirect = window.location.protocol + "//" + window.location.host.replace('operations', 'customers').replace('4200', '4202') + '/impersonate';
            window.open(redirect, '_blank')
        },
        visible: (data) => {
            if (data.record.roleNames.toString().toLowerCase().includes('customer')) {
                return true;
            }
            return false;
        }

    });



    alertUserNameContributor = (actionList: EntityActionList<IdentityUserDto>) => {
        actionList.addTail(this.logInPilot);
        actionList.addTail(this.logInCustomer);
    }

    public identityEntityActionContributors: IdentityEntityActionContributors = {
        [eIdentityComponents.Users]: [
            this.alertUserNameContributor,
        ],
    };
}