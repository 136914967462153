import { NgModule, NgModuleFactory, ModuleWithProviders } from '@angular/core';
import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { MissionsServiceRoutingModule } from './missions-service-routing.module';
import { NgxActionFrameworkModule } from '@newtonvision/ngx-action-framework';
import { environment } from 'projects/flyguys/src/environments/environment';

import { MaterialSharedModule } from 'projects/flyguys/src/app/material-shared/material-shared.module';
import { MissionAssignmentsModalComponent } from './basics/mission-assignments-modal/mission-assignments-modal.component';

@NgModule({
  declarations: [
    MissionAssignmentsModalComponent
  ],
  imports: [
    CoreModule,
    ThemeSharedModule,
    MissionsServiceRoutingModule,
    NgxActionFrameworkModule.forRootDynamicEnv({
      api: environment.apis.ActionFramework.url,
    }),
    MaterialSharedModule
  ],
  exports: [
    MissionAssignmentsModalComponent
  ],
})
export class MissionsServiceModule {
  static forChild(): ModuleWithProviders<MissionsServiceModule> {
    return {
      ngModule: MissionsServiceModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<MissionsServiceModule> {
    return new LazyModuleFactory(MissionsServiceModule.forChild());
  }
}
